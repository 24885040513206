import { Injectable } from '@angular/core';
import { ModalService } from '../reusable-modal/modal.service';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { CompanyStates, UserStates } from '@interfaces/session.interface';
import { CompleteProfileComponent } from '../shared-components/components/complete-profile/complete-profile.component';
import { getRolSelect } from '../pages/session/state/session.selectors';
import { CompleteCompanyProfileComponent } from '../shared-components/components/complete-company-profile/complete-company-profile.component';

@Injectable({
  providedIn: 'root'
})

// Servicio para manejar y lanzar acciones segun el estado cuenta de un usuario o una empresa
export class ManageUserCompanyStatesService {

  public userStates!: UserStates;
  public companyStates!: CompanyStates;
  private unsubscribe$ = new Subject<boolean>();

  constructor(private store: Store, private modalService: ModalService) {
    //console.trace();
    this.store.select(getRolSelect)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((rolSelected) => {

          // console.log('rolSelected',rolSelected);


          if(!rolSelected) return;

          const { rol } = rolSelected;

          if(rol === 'user' || rol === 'doctor') {
            this.userStates = rolSelected?.data.userStates;
            this.checkUserStates();
          }

          if(rol === 'provider') {

            this.companyStates = rolSelected?.data.companyStates;
            this.checkCompanyStates();
          }

    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  checkUserStates() {

    if(this.userStates?.isGoogleProfileCompleted === 'incomplete') {
      this.modalService.openModal(CompleteProfileComponent, { header: false, modalClass: 'modal-dialog', tittle: 'Completar perfil', dataKeyboard: false, static: "static" });
    }
  }

  checkCompanyStates():boolean {

    if(!this.companyStates) return false;
    const { registerState } = this.companyStates;

    if(registerState.state === 'complete') return true;
    this.modalService.openModal(CompleteCompanyProfileComponent, { header: true, modalClass: 'modal-dialog', tittle: 'Completar perfil', dataKeyboard: false }, registerState);
    return false;
  }
}
