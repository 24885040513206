import { Component ,Input, OnInit, Output,ViewChild,TemplateRef,EventEmitter} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Studies } from '@interfaces/information.interface';
import { ApplicationService, StatesNgrxService, SweetAlertService } from '@services';
import { UserService } from 'src/app/roles/services/user.service';


@Component({
  selector: 'app-studies-list',
  templateUrl: './studies-list.component.html',
  styleUrls: ['./studies-list.component.scss'],
})
export class StudiesListComponent implements OnInit {
  @Input() studies: Studies[] = [];

  @Output() studiesEmmiter: EventEmitter<Studies[]> = new EventEmitter<Studies[]>();
  @Output() regresarEmmiter: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('studiesList') studiesList!: TemplateRef<any>;
  @ViewChild('studyEdit') studyEdit!: TemplateRef<any>;

  public currentView!: TemplateRef<any>;

  public studiesSelected?: Studies;

  public studiesForm!: FormGroup;
  public token = '';

  types = [
    { value: 'curso', name: 'Curso' },
    { value: 'tecnico', name: 'Técnico' },
    { value: 'tecnologico', name: 'Tecnológico' },
    { value: 'profesional', name: 'Profesional' },
    { value: 'diplomado', name: 'Diplomado' },
    { value: 'seminario', name: 'Seminario' },
    { value: 'especializacion', name: 'Especialización' },
    { value: 'maestria', name: 'Maestría' },
    { value: 'doctorado', name: 'Doctorado' },
  ];

  constructor(
    private swalService: SweetAlertService, 
    private userService:UserService,
    private statesNgrxService:StatesNgrxService) {}

  ngOnInit(): void {
    setTimeout(() => {      
      this.currentView = this.studiesList;
    }, 200);
    // //console.log(this.studies);
  }

  changeTab(tab: string) {
    switch (tab) {
      case 'studiesList':
        this.currentView = this.studiesList;
        break;
      case 'studyEdit':
        this.currentView = this.studyEdit;
        break;
    }
  }

  /**
   * Selecciona un studies y emite un evento para indicar que no se debe regresar.
   * @param {study} studies - study seleccionado.
   */
  selectStudy(study: Studies): void {
    // //console.log(study);

    this.inicializarStudiesForm(study);

    setTimeout(() => {
      this.regresarEmmiter.emit(false);
      this.currentView = this.studyEdit;
      this.studiesSelected = study;
    }, 100);
  }

  /**
   * Limpia la selección de Study y emite un evento para indicar que se debe regresar.
   */
  regresarStudies(): void {
    this.studiesSelected = undefined;
    this.regresarEmmiter.emit(true);
    this.currentView = this.studiesList;
  }  

  async iniciarStudiesAgregateForm() {
    this.studiesForm = new FormGroup({
      studyName: new FormControl<string | null>(null, Validators.required),
      university: new FormControl<string | null>(null, Validators.required),
      type: new FormControl<string | null>(null, Validators.required),
      municipality: new FormControl<string | null>(null, Validators.required),
      dateGrade: new FormControl<Date | null>(null, Validators.required),
    });

    this.currentView = this.studyEdit;
  }

  async inicializarStudiesForm(studies: Studies) {  
    //console.log(studies);    
     
    this.studiesForm = new FormGroup({
      studyName: new FormControl<string | null>(studies.studyName || null,Validators.required),
      university: new FormControl<string | null>(studies.university || null,Validators.required),
      type: new FormControl<string | null>(studies.type || null,Validators.required),
      municipality: new FormControl<string | null>(studies.municipality || null,Validators.required),
      dateGrade: new FormControl(this.getDate(studies.dateGrade),Validators.required),
    });
  }

  getDate(date: string): string | null {
    return date ? date.slice(0, 10) : null;
  }

  hacerScroll(id: string) {
    var elementos = document.querySelectorAll(`#${id}`);
    const margenTop = 120;

    if (elementos.length > 0) {
      var elementoObjetivo = elementos[0];
      var posicion = elementoObjetivo.getBoundingClientRect();

      window.scrollTo({
        top: window.pageYOffset + posicion.top - margenTop,
        behavior: 'smooth',
      });
    }
  }


  async agregateStudies(form: FormGroup) {
    if (form.valid) {
      const studyAgregate: Studies = {
        // _idUser: this.idData,
        studyName: this.studiesForm.value.studyName,
        university: this.studiesForm.value.university,
        municipality: this.studiesForm.value.municipality,
        dateGrade: this.studiesForm.value.dateGrade,
        type: this.studiesForm.value.type,
      };

      const result = await Swal.fire({
        title: 'Estás seguro?',
        text: `Se va a Agregar ${studyAgregate.studyName} a tu lista de Estudios`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, agregar!',
        cancelButtonText: 'Cancelar',
      });

      if (result.isConfirmed) {
        this.userService.createStudy(studyAgregate).then(resp=>{
          if (resp.ok) {
            this.statesNgrxService.getDataGlobalUser().subscribe((resp:any)=>{                   
              if (!resp) {
                return //console.log('Error');                
              }        
              this.statesNgrxService.updateGlobalUser();
              this.currentView = this.studiesList;  
            });
            setTimeout(() => {
              this.hacerScroll('pills-tab');
              this.studiesEmmiter.emit(this.studies);
            }, 200);   
          }
          
        }).catch(err=>{
          //console.log(err);          
        });
      }
    } else {
      this.studiesForm.markAllAsTouched();
      const requiredFields = [
        'studyName',
        'university',
        'type',
        'municipality',
        'dateGrade',
      ];

      const swalResult = await this.swalService.lauchSwalAsync(
        '',
        'Debes registrar los campos en color rojo, ya que son obligatorios',
        'warning'
      );
      if (swalResult) {
        setTimeout(() => {
          const scrollTo = requiredFields.find(
            (field) => this.studiesForm.controls[field].invalid
          );
          if (scrollTo) {
            this.hacerScroll(
              `idInput${scrollTo.charAt(0).toUpperCase() + scrollTo.slice(1)}`
            );
          }
        }, 300);
      }
    }
  }

  async updateStudies(form: FormGroup) {
    if(this.studiesSelected){
      
    if (form.valid) {
      const studyEdit: Studies = {
        studyId: this.studiesSelected._id,
        studyName: this.studiesForm.value.studyName,
        university: this.studiesForm.value.university,
        municipality: this.studiesForm.value.municipality,
        dateGrade: this.studiesForm.value.dateGrade,
        type: this.studiesForm.value.type,
      };

      const result = await Swal.fire({
        title: 'Estás seguro?',
        text: `Se va a actualizar el Estudio ${this.studiesSelected.studyName}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, actualizar!',
        cancelButtonText: 'Cancelar',
      });

      if (result.isConfirmed) {
        this.userService.updateStudy(studyEdit).then(resp=>{
          if (resp.ok) {
            this.statesNgrxService.getDataGlobalUser().subscribe((resp:any)=>{                   
              if (!resp) {
                return //console.log('Error');                
              }        
              this.statesNgrxService.updateGlobalUser();
              this.currentView = this.studiesList;  
            });
            setTimeout(() => {
              this.studiesEmmiter.emit(this.studies);
              this.hacerScroll('pills-tab');
            }, 200);   
          }
          
        }).catch(err=>{
          //console.log(err);          
        })
      }
    } else {
      this.studiesForm.markAllAsTouched();
      const requiredFields = [
        'studyName',
        'university',
        'type',
        'municipality',
        'dateGrade',
      ];

      const swalResult = await this.swalService.lauchSwalAsync(
        '',
        'Debes registrar los campos en color rojo, ya que son obligatorios',
        'warning'
      );
      if (swalResult) {
        setTimeout(() => {
          const scrollTo = requiredFields.find(
            (field) => this.studiesForm.controls[field].invalid
          );
          if (scrollTo) {
            this.hacerScroll(
              `idInput${scrollTo.charAt(0).toUpperCase() + scrollTo.slice(1)}`
            );
          }
        }, 300);
      }
    }

    }
  }

  deleteStudy(study: Studies): void {    
    Swal.fire({
      title: 'Estás seguro?',
      text: `Se va a eliminar ${study.studyName} de tu lista de Estudios`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar Estudios!',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        // this.studies = this.studies.filter(
        //   (objeto) => objeto._id !== study._id
        // );

        const _id= study._id ||'';

        
        this.userService.deleteStudy(_id)
        .then(resp=>{
          if (resp.ok) {
            this.statesNgrxService.getDataGlobalUser().subscribe((resp:any)=>{                   
              if (!resp) {
                return //console.log('Error');                
              }        
              this.statesNgrxService.updateGlobalUser(); 
            });
            setTimeout(() => {
              this.studiesEmmiter.emit(this.studies);
              this.hacerScroll('pills-tab');
            }, 200);              
          }
        })
        .catch(err=>{
          //console.log(err);          
        })
      }
    });
  }
 
}