
<div class="card text-center">
  <div class="card-body">
    <img class="card-img-top img-fluid"
    [src]="cardOptions.imageRoute" alt="Avatar" class="rounded-circle" style="width: 150px" loading="lazy">
    <h4 class="card-title text-center pt-3 font-bold">
      {{ cardOptions.tittle }}
    </h4>
    <p class="mb-0 mt-2 text-justify">
      {{ cardOptions.description }}
    </p><br>
    <custom-buttons (click)="buttonClicked.emit(true)" class="full-width-button" text="{{ cardOptions.buttonName }}" icon="{{cardOptions.icons}}"
      textColor="#fff" type="button" [title]="''">
    </custom-buttons>
  </div>
</div>
