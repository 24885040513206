import { Directive, ElementRef, HostListener, Input, Renderer2, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[customTooltip]'
})
export class CustomTooltipDirective {
  @Input() componentToRender: any;
  @Input() appTooltip: string = '';

  private viewCRef!: ViewContainerRef

  private tooltipElement: HTMLElement | null = null;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  init() {
    this.el.nativeElement.addEventListener('mouseenter', () => this.showTooltip());
    this.el.nativeElement.addEventListener('mouseleave', () => this.hideTooltip());
  }

  private showTooltip() {
    if (!this.tooltipElement) {
      this.tooltipElement = document.createElement('div')
      this.tooltipElement.className = 'custom__tooltip'
      this.tooltipElement.textContent = this.appTooltip
      this.renderer.appendChild(this.el.nativeElement, this.tooltipElement)
    }

    if (this.componentToRender) {
      this.viewCRef.createComponent(this.componentToRender)
    }
  }

  private hideTooltip() {
    if (this.tooltipElement) {
      this.renderer.removeChild(this.el.nativeElement, this.tooltipElement)
      this.tooltipElement = null
    }

    if (this.componentToRender) {
      this.viewCRef.clear()
    }
  }
}
