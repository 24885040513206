import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, Input, Output, Renderer2, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { addDays, format, isBefore, parse, startOfDay, } from 'date-fns';
import { AnimationOptions } from 'ngx-lottie';
import { es } from 'date-fns/locale';

import { CustomTooltipDirective } from './directives/custom-tooltip.directive'
import { TaskInfoComponent } from './task-info/task-info.component';
import { DayInfoComponent } from './day-info/day-info.component';

import { HolidaysService } from '@services';

@Component({
  selector: 'custom-calendar-extended',
  templateUrl: './custom-calendar-extended.component.html',
  styleUrls: ['./custom-calendar-extended.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomCalendarExtendedComponent {
  @ViewChild('infoDayRef', { read: ViewContainerRef }) private infoDayRef!: ViewContainerRef;
  @ViewChild('tooltipRef', { read: ViewContainerRef }) private tooltipRef!: ViewContainerRef;


  @ViewChild('currentMonthRef', { read: ElementRef }) private currentMonthRef!: ElementRef;
  @ViewChild('nowDateRef', { read: ElementRef }) private nowDateRef!: ElementRef;

  @Input() blockNextDays: boolean = false;
  @Input() blockPrevDays: boolean = true;
  @Input() showNow: boolean = true;
  @Input() hourly: boolean = false;
  @Input() range: boolean = false;
  @Input() inputData: any
  @Input() set calendardata(_calendardata: any[]) {
    if (_calendardata) {
      this.loader = true
      this.tmpDataAppoinments = _calendardata
      setTimeout(() => this.renderCalendar(), 2000);
    }
  }

  @Output() date = new EventEmitter();
  @Output() payloadData = new EventEmitter();
  @Output() errorNoData = new EventEmitter();

  @HostBinding('style.width') private width = '284px'
  @HostBinding('style.height') @Input() height: string = '620px';

  private optionsDate: any = { weekday: "long", month: "long", day: "numeric" };
  private tmpDataAppoinments!: any[]
  private dateNow = new Date();
  private initialized = false;

  private firstDayIndexCurrentMonth: any
  private lastDayIndexCurrentMonth: any
  private lastDayCurrentMonth: any
  private lastDayPreviousMonth: any
  private nextDays: any

  options: AnimationOptions = { path: 'assets/lottie-animations/calendar_edited.json' };
  months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  weekDays = [
    {
      "name": "Domingo",
      "abbreviations": {
        "simply": "Dom",
        "letter": "D"
      }
    },
    {
      "name": "Lunes",
      "abbreviations": {
        "simply": "Lun",
        "letter": "L"
      }
    },
    {
      "name": "Martes",
      "abbreviations": {
        "simply": "Mar",
        "letter": "M"
      }
    },
    {
      "name": "Miércoles",
      "abbreviations": {
        "simply": "Mié",
        "letter": "M"
      }
    },
    {
      "name": "Jueves",
      "abbreviations": {
        "simply": "Jue",
        "letter": "J"
      }
    },
    {
      "name": "Viernes",
      "abbreviations": {
        "simply": "Vie",
        "letter": "V"
      }
    },
    {
      "name": "Sábado",
      "abbreviations": {
        "simply": "Sáb",
        "letter": "S"
      }
    }
  ]

  dateSelected: any = {};
  loader: boolean = false
  fullSize: boolean = false
  dailyOnClick: boolean = true
  holidayList: Record<string, any> = {}

  constructor(
    private _el: ElementRef,
    private _holidayservice: HolidaysService,
    private _render: Renderer2,
    private _cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.holidayList = this._holidayservice.getHolidaysByYear(this.dateNow.getFullYear());
  }

  public prevMonth() {
    this.dateNow.setMonth(this.dateNow.getMonth() - 1);
    this.renderCalendar();
  }

  public nextMonth() {
    this.dateNow.setMonth(this.dateNow.getMonth() + 1);
    this.renderCalendar();
  }

  showTaskDayInfo(event: Event, appoinments: any[]) {
    let target = event.target as HTMLElement
    let dateDaydata = target.getAttribute('data-date')

    appoinments.sort((a, b) => b.hour.localeCompare(a.hour))

    if (!dateDaydata) return

    this.infoDayRef.clear()
    let _infoDayRef = this.infoDayRef.createComponent(DayInfoComponent)
    _infoDayRef.instance.dataInfo = appoinments
    _infoDayRef.instance.dateInfo = format(new Date(`${dateDaydata}T06:00:00.000Z`), "EEE, dd 'de' MMMM", { locale: es })
    _infoDayRef.instance.closeTaskInfo.subscribe(ev => {
      _infoDayRef.destroy()
    })
  }

  private renderCalendar() {
    let el = <HTMLElement>this._el.nativeElement;
    let monthTitle = <HTMLElement>this.currentMonthRef.nativeElement;
    let fullDateTitle = <HTMLElement>this.nowDateRef.nativeElement;

    this.lastDayCurrentMonth = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth() + 1, 0).getDate();
    this.lastDayPreviousMonth = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), 0).getDate();
    this.firstDayIndexCurrentMonth = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(),).getDay();
    this.lastDayIndexCurrentMonth = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth() + 1,).getDay();
    this.nextDays = 7 - this.lastDayIndexCurrentMonth;

    monthTitle.innerHTML = `${this.months[this.dateNow.getMonth()]} ${this.dateNow.getFullYear()}`;

    if (this.showNow) fullDateTitle.innerHTML = new Date().toLocaleDateString("es-ES", this.optionsDate);

    let monthDays = el.querySelector(".days");
    let _days = this.generateDays();

    if (monthDays) {
      monthDays.innerHTML = ''
      monthDays && _days && _days.forEach(dayEl => monthDays?.appendChild(dayEl))
    }

    if (_days.length > 35) this.fullSize = true

    this.loader = false
    this._cdr.detectChanges();
  }

  private generateDays(): HTMLElement[] {
    let dateRef = startOfDay(this.dateNow)
    let nowDate = startOfDay(new Date())
    let tmpDays: HTMLElement[] = []

    let [month, day] = format(dateRef, 'dd/MM/yyyy').split('/').slice(1);
    let tmpPartialDate = `${month}/${day}`;
    let tmpNowDayRef = new Date().getDate()
    let tmpNowMonthRef = new Date().getMonth()

    // Previous month days
    for (let dayLastMonth = this.firstDayIndexCurrentMonth; dayLastMonth > 0; dayLastMonth--) {
      let lastMonthDay = this.lastDayPreviousMonth - dayLastMonth + 1
      let lastMonthTmp = Number(tmpPartialDate.split('/')[0]) == 1 ? 12 : Number(tmpPartialDate.split('/')[0]) - 1;
      let lasMonthPartialDate = `${lastMonthTmp < 10 ? "0" + lastMonthTmp : lastMonthTmp}/${tmpPartialDate.split('/')[1]}`
      let dateKey = `${lastMonthDay < 10 ? "0" + lastMonthDay : lastMonthDay}/${lasMonthPartialDate}`
      let isHoliday = this.holidayList[dateKey]
      let dateItemDay = parse(`${dateKey}`, 'dd/MM/yyyy', new Date())
      let attributes: any = { title: isHoliday ? isHoliday.name : undefined, "data-date": dateItemDay.toISOString().split('T')[0] }

      attributes.disabled = this.blockPrevDays && isBefore(dateItemDay, nowDate)
      tmpDays.push(this.createRenderElement({ type: 'div', classes: ['day', 'previous'], content: this.lastDayPreviousMonth - dayLastMonth + 1, attributes }));
    }

    const appoinmentsList = this.getAppoinmentsMonth()

    // Current month days
    for (let indexDay = 1; indexDay <= this.lastDayCurrentMonth; indexDay++) {
      let dateKey = `${indexDay < 10 ? "0" + indexDay : indexDay}/${tmpPartialDate}`
      let appoinments = appoinmentsList[dateKey]
      let isHoliday = this.holidayList[dateKey]
      let tmpClasses = ['day']
      let dateItemDay = parse(`${dateKey}`, 'dd/MM/yyyy', new Date(), { locale: es })
      let attributes: any = { title: isHoliday ? isHoliday.name : undefined, "data-date": dateItemDay.toISOString().split('T')[0] }

      attributes.disabled = this.blockPrevDays && isBefore(dateItemDay, nowDate)

      indexDay === tmpNowDayRef && tmpNowMonthRef === this.dateNow.getMonth() && tmpClasses.push('today')
      isHoliday && tmpClasses.push('holiday')

      let parametersDayElement: IRenderElementOptions = { type: 'div', classes: tmpClasses, content: indexDay, attributes }

      if (appoinments?.length) parametersDayElement.clickAction = (event: Event) => this.showTaskDayInfo(event, appoinments)

      let elementDay = this.createRenderElement(parametersDayElement)

      if (appoinments) {
        const taskSContainer = this.createRenderElement({ type: 'span', classes: ['task__list'] })

        let cancelledAppoinments = []
        let activeAppoinments = []

        appoinments.forEach(appoinment => {
          const { patient: { names, lastNames }, status } = appoinment
          status.type === 'cancelled' && cancelledAppoinments.push(appoinment)
          status.type === 'active' && activeAppoinments.push(appoinment)
        })

        // const appoinmentClasses = ['fa', 'fa-stethoscope', 'task__list__item']
        const appoinmentClasses = ['task__list__item']

        let appoinmentsIconInfoActive = this.createRenderElement({
          type: 'i',
          classes: appoinmentClasses,
          content: `+${activeAppoinments.length}`,
          directives: [{ directiveClass: CustomTooltipDirective, entries: `${activeAppoinments.length} citas activas` }]
        })

        let appoinmentsIconInfoCancelled = this.createRenderElement({
          type: 'i',
          classes: [...appoinmentClasses, 'canceled'],
          content: `+${cancelledAppoinments.length}`,
          directives: [{ directiveClass: CustomTooltipDirective, entries: `${cancelledAppoinments.length} citas canceladas` }]
        })

        activeAppoinments.length && taskSContainer.appendChild(appoinmentsIconInfoActive)
        cancelledAppoinments.length && taskSContainer.appendChild(appoinmentsIconInfoCancelled)

        elementDay.appendChild(taskSContainer)
      }

      tmpDays.push(elementDay)
    }

    // Next month days
    for (let dayNextMonth = 1; dayNextMonth <= this.nextDays; dayNextMonth++) {
      let nextMonthDay = dayNextMonth
      let nextMonth = Number(tmpPartialDate.split('/')[0])
      let lasMonthPartialDate = `${nextMonth < 12 ? nextMonth + 1 : nextMonth}/${tmpPartialDate.split('/')[1]}`
      let dateKey = `${nextMonthDay < 10 ? "0" + nextMonthDay : nextMonthDay}/${lasMonthPartialDate}`
      let isHoliday = this.holidayList[dateKey]
      let dateItemDay = parse(`${dateKey}`, 'dd/MM/yyyy', new Date())
      let attributes: any = { title: isHoliday ? isHoliday.name : undefined, "data-date": dateItemDay.toISOString().split('T')[0] }
      attributes.disabled = this.blockPrevDays && isBefore(dateItemDay, addDays(nowDate, -1))

      tmpDays.push(this.createRenderElement({ type: 'div', classes: ['day', 'next'], content: dayNextMonth, attributes }));
    }

    return tmpDays;
  }

  private getAppoinmentsMonth() {
    const appoinments: Record<string, any[]> = {}
    const currentMonth = this.dateNow.getMonth() + 1
    const currentYear = this.dateNow.getFullYear()

    this.tmpDataAppoinments && this.tmpDataAppoinments.length && this.tmpDataAppoinments.filter(appoinment => {
      const [year, month, day] = appoinment.appointmentDate.split('T')[0].split('-').map(Number);
      if (currentMonth === month && currentYear === year) {
        const keyDate = `${day < 10 ? "0" + day : day}/${month < 10 ? "0" + month : month}/${year}`;
        appoinments[keyDate] = [...appoinments[keyDate] || [], appoinment];
      }
    })

    return appoinments
  }


  private createRenderElement(options: IRenderElementOptions): HTMLElement {
    const { type, classes, content, clickAction, attributes, directives } = options

    let tmpElement = this._render.createElement(type)
    if (content) {
      let tmpTextElement = this._render.createText(content)
      this._render.appendChild(tmpElement, tmpTextElement)
    }
    classes?.length && classes.forEach(cls => this._render.addClass(tmpElement, cls))
    // attrType && attrValue && this._render.setAttribute(tmpElement, attrType, attrValue)
    if (attributes) {
      for (let attrName in attributes) {
        if (attributes.hasOwnProperty(attrName)) {
          let attrValue = attributes[attrName];
          attrValue && this._render.setAttribute(tmpElement, attrName, attrValue);
        }
      }
    }

    directives && directives.forEach(({ directiveClass, entries }) => {
      let tmpDirective = new directiveClass()
      tmpDirective.el = new ElementRef(tmpElement)
      // tmpDirective.componentToRender = DialogPopupComponent
      tmpDirective.appTooltip = entries
      tmpDirective.renderer = this._render
      tmpDirective.viewCRef = this.tooltipRef
      tmpDirective.init()
    });

    clickAction && this._render.listen(tmpElement, 'click', (event: Event) => clickAction(event))
    return tmpElement
  }
}

interface IRenderElementOptions {
  // attrType?: string;
  // attrValue?: string;
  attributes?: any;
  directives?: IDirectiveOpts[];
  classes?: string[];
  clickAction?: Function;
  content?: any;
  type: string;
}

interface IDirectiveOpts {
  directiveClass: any,
  entries?: string | any[]
}
