<span [id]="idInput" class="input__group">
  <div class="box__label">
    <label class="input__label" for="email" [style.color]="labelColor" [style.fontSize]="labelFontSize" >{{label}}<strong *ngIf="required"> *</strong></label>
    <i class="fa fa-question icon__helper" [title]="helper" *ngIf="helper">
      <!-- <span class="tooltip__text">{{ helper }}</span> -->
    </i>
  </div>
  <div class="box__input">
    <input #inputRef class="input__form" type="email" name="email" [placeholder]="placeholder" autocomplete="off" [formControl]="control" [ngClass]="{invalid: initData == '' && control.touched && control.invalid}">
    <i class="fa fa-close icon__clear" *ngIf="!control.disabled" [ngClass]="{visible: inputRef.value != ''}" (click)="control.setValue('')" title="Limpiar"></i>
    <span class="loader__icon" *ngIf="validable && validating"></span>
    <span class="fa fa-check valid__check__icon input__icon" *ngIf="validable && validEmail && errors.length==0"></span>
  </div>
  <div class="box__error" [ngClass]="{visible:errors.length}">
    <ul>
      <li class="box__error__item" *ngFor="let error of errors"><strong>* </strong>{{error}}</li>
    </ul>
  </div>
</span>