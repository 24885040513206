import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InfoNoticiasRoutingModule } from './info-noticias-routing.module';
import { InfoUserComponent } from './components/info-user/info-user.component';
import { InfoDoctorComponent } from './components/info-doctor/info-doctor.component';
import { LandingPageModule } from "../landing-page/landing-page.module";


@NgModule({
    declarations: [
        InfoUserComponent,
        InfoDoctorComponent
    ],
    imports: [
        CommonModule,
        InfoNoticiasRoutingModule,
        LandingPageModule
    ]
})
export class InfoNoticiasModule { }
