import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../shared/services/settings.service';
import { RolSelectorComponent } from './rol-selector/rol-selector.component';
import { ModalOptions } from '../interfaces/modal.reusable.interface';
import { Store } from '@ngrx/store';
import { getRolSelect } from './session/state/session.selectors';
// declare function customInitFunctions(): any;

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styles: [],
})

export class PagesComponent implements OnInit {

  modalContent = RolSelectorComponent;
  options: ModalOptions = {
    modalClass: 'modal-dialog modal-lg',
    modalClassContent:'modal-content',
    modalClassBody:'modal-body',
    tittle: 'Modal Selector',
    dataKeyboard: false,
    static: 'static',
    header:true,
    footer:true
  };
  
  rolSelect: any;

  // private settingsSercive: SettingsService
  constructor(private settingsSercive: SettingsService,
              private store: Store) {}

  //./assets/css/colors/default-dark.css
  ngOnInit(): void {
    this.store.select(getRolSelect).subscribe((rolSelect) => (this.rolSelect = rolSelect));
  }

  // openModal() {
  //   this.modalService.openModal();
  // }
}
