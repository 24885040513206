import { ReduxBranches } from "@interfaces/shared-components.interface";
import { createReducer, on } from "@ngrx/store";
import { setBranches, loadBranches, selectBranch } from "./branches.actions";

export const branchesStateFeatureKey = 'branchesState';

export interface BranchesState {
    branches: ReduxBranches[] | [],
    companyId: string,
    branchSelected: ReduxBranches | null
}

export const initialBranchesState: BranchesState = {
    branches: [],
    companyId: '',
    branchSelected: null,
};

export const branchesReducer = createReducer( 
    initialBranchesState,
    on(setBranches, (state, { branches }) => {

        return {
            ...state,
            branches
        };
    }),
    on(loadBranches,(state, { companyId }) => {
        
        return {
          ...state,
          branches: [],
          companyId
        };
    }),
    on(selectBranch, (state, { branchSelected }) => {

        localStorage.setItem('branch', branchSelected.branchId);

        return {
            ... state,
            branchSelected
        }
    })
);