<div class="filters__calendar">
  <div class="container-title">
    <h3 class="animated fadeIn">Empresa&nbsp;</h3>
    <h3 class="title-desktop animated fadeIn">/ Agenda</h3>
  </div>

  <app-branch-select-text (itemEmitter)="changeBranch($event)" titleItem="!Click para seleccionar otra sucursal!" id="branchSelector"></app-branch-select-text>

  <form [formGroup]="form" id="filtersSchedule">
    <form-select #filterServiceRef id="filterService" controlName="filterService" label="Servicios" placeholder="Seleccione el servicio" [dataList]="filterServiceList" idField="subServiceId" valueField="subserviceName"></form-select>
    <form-select #filterDoctorRef id="filterDoctor" controlName="filterDoctor" label="Médico" placeholder="Seleccione médico" [dataList]="filterDoctorList" [disabled]="!filterServiceRef.control.value" idField="doctorId" valueField="fullName" [required]="false"></form-select>
    <form-select #filterTypeRef id="filterType" controlName="filterType" label="Tipo citas" placeholder="Seleccione tipo de citas" [dataList]="filterTypeList" [disabled]="!filterServiceRef.control.value" [required]="false"></form-select>
  </form>
</div>

<custom-calendar-extended #calendarRef [calendardata]="scheduleList" id="schedulesCalendar"></custom-calendar-extended>
