<div class="main-wrapper">
  <app-header [rolSelect]="rolSelect"></app-header>
  <app-sidebar [rolSelect]="rolSelect"></app-sidebar>

  <!-- Page wrapper  -->
  <!-- ============================================================== -->
  <div class="page-wrapper">
    <!-- ============================================================== -->
    <!-- Container fluid  -->
    <!-- ============================================================== -->
    <div class="container-fluid">
      <!-- Breadcrumbs -->
      <!-- <app-breadcrumbs></app-breadcrumbs> -->
      <!-- Mi sistema de rutas -->
      <router-outlet></router-outlet>

      <!--
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">

            </div>
          </div>
        </div>
      </div> -->
    </div>

    <!-- ============================================================== -->
    <!-- footer -->
    <!-- ============================================================== -->
    <footer class="footer">© 2023 Designed with love by Grupo Prevenir Express ❤️ </footer>
    <!-- ============================================================== -->
    <!-- End footer -->
    <!-- ============================================================== -->
  </div>
</div>


<!-- ============================================================== -->
<!-- Modal Rol Selector -->
<!-- ============================================================== -->
<app-reusable-modal></app-reusable-modal>
