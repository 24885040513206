import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['../../form-styles.scss'],
  styles: [':host { width: 100%; }; select option[disabled] { background: #cfcfcf  !important; color:#0006 !important; font-weight: 300 }']
})

export class FormSelectComponent {
  @Input() controlName!: string;
  @Input() placeholder: string = 'Default select placeholder';
  @Input() helper: string | undefined;
  @Input() label: string = 'Default select label';
  @Input() labelColor!: string;
  @Input() labelFontSize: string = '1rem'
  @Input() required: boolean = true;
  @Input() dataList: any[] = [];
  @Input() idField: string = 'id';
  @Input() valueField: string = 'label';
  @Input() idInput: string = 'idInput';
  @Input() labelVisible: boolean = true;

  @Input() set disabled(_disabled: boolean) {
    _disabled ? this.control.disable() : this.control.enable()
  };
  // @Output() selectedTextChange = new EventEmitter<string>(); // Emisor para el texto seleccionado

  errors: any[] = [];
  value: any = null;
  selectedText: string = '';  // Almacenar el texto visible seleccionado
  constructor(private controlContainer: ControlContainer) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dataList'] && changes['dataList'].currentValue) {
      this.dataList = changes['dataList'].currentValue.map((item: any) => ({ ...item, cantidad: 0 }));
    }
  }

  onBlur() {
    this.control.markAsDirty()
    this.validateErrors()
  }

  get control(): FormControl {
    const parentFormGroup = this.controlContainer as FormGroupDirective;
    return parentFormGroup.control.get(this.controlName) as FormControl;
  }

  get controlInvalid(): boolean {
    return this.control?.invalid || false;
  }

  get controlTouched(): boolean {
    return this.control?.touched || false;
  }

  get errorKeys(): string[] {
    return this.control?.errors ? Object.keys(this.control.errors) : [];
  }

  validateErrors() {
    this.errors = [];

    if (!this.control.errors || !this.control.dirty) return

    this.control.errors['required'] && this.errors.push(`Debes seleccionar una opción`)
    this.control.errors['minlength'] && this.errors.push(`El campo nó debe tener menos de ${this.control.errors['minlength'].requiredLength} caracteres`)
    this.control.errors['maxlength'] && this.errors.push(`El campo nó debe tener mas de ${this.control.errors['maxlength'].requiredLength} caracteres`)
    this.control.errors['email'] && this.errors.push(`El correo electrónico debe ser una dirección válida`)
    this.control.errors['pattern'] && this.errors.push(`El campo no corresponde a un patrón válido`)
  }

  // onSelectChange(value: any, text: string) {
  //   this.control.setValue(value);
  //   this.selectedTextChange.emit(text);  // Emitir el texto visible
  // }
}
