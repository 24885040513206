<div *ngIf="!appointmentsSelected" class="row animated fadeIn" style="margin-top: -22px;">

  <div *ngIf="user" class="col-md-4 col-xs-12 mt-2">

    <div class="citas_usuario_container">
      <app-tabs [tabsInfo]="tabs" [classInput]="'fz_15 mb-4'" (switch)="switch($event)">
      </app-tabs>

      <ng-container [ngTemplateOutlet]="currentView"></ng-container>

      <ng-template #userView>
        <!-- <div class="citas_usuario_title">Datos del Usuario</div> -->
        <div *ngIf="user" class="user-profile">
          <img [src]="user.avatar|getImage" alt="Avatar" class="user-avatar" style="height: 120px; width: 120px;" />
          <div class="user-fullname">{{user.names | noData}} {{user.lastNames |noData }}</div>
          <div *ngIf="user.birthDate" class="user-birthdate">Fecha de Nacimiento:
            {{userFechaNacimiento}}</div>
          <div *ngIf="user.birthDate" class="user-birthdate">Edad actual:
            {{userEdad}}</div>
        </div>
      </ng-template>
      <ng-template #petsView>
        <app-custom-message *ngIf="pets?.length==0" [bodyMessage]="'No hay mascotas registradas'"></app-custom-message>
        <div *ngIf="pets" class="pets_list">
          <div *ngIf="pets.length>0">
            <div *ngFor="let pet of pets">
              <div class="pet_container">
                <img [src]="pet.avatar|getImage" alt="Avatar" class="user-avatar" style="height: 50px; width: 50px;" />
                <div class="pet-fullname ml-2">{{pet.petName | noData}}, {{pet.species}} {{'('+pet.breed+')' | noData}}
                </div>
              </div>

              <div class="separator_dot fz_14 mt-1">
                <span *ngIf="pet.birth_date" class="text-muted">{{ pet.birth_date| ageString:endDate:'years' }}</span>
                <span *ngIf="pet.gender" class="text-muted">{{pet.gender | noData:'Sin registrar' |
                  translateString:traduccionesPets}}</span>
                <span *ngIf="pet.color" class="text-muted">{{pet.color}}</span>
              </div>
              <hr>

            </div>
          </div>
        </div>
      </ng-template>

    </div>
  </div>

  <div [class.col-md-12]="!user" class="col-md-8 col-xs-12 mt-2">
    <div class="citas_usuario_container">
      <div class="animated fadeIn">

        <div class="citas_usuario_title" *ngIf="inputViewTitle">
          {{appointments.length}} Cita<span *ngIf="appointments.length!=1">s</span> activa<span
            *ngIf="appointments.length!=1">s</span>
        </div>
        <div class="table-responsive table_height table-sm no-wrap mt-4">
          <div class="table-container">
            <!-- <app-tables
              [headers]="['Paciente', 'Profesional', 'Sucursales', 'Direccion', 'Fecha', 'Hora', 'Servicio',]"
              [data]="tableData">
            </app-tables> -->
            <table *ngIf="appointments.length > 0 || appointmentsPets.length > 0"
              class="table vm no-th-brd pro-of-month ">
              <thead class="thead-light">
                <tr>
                  <th *ngIf="inputColumns.pet" colspan="2">Mascota</th>
                  <th *ngIf="inputColumns.profesional && !inputColumns.pet" colspan="2">Profesional</th>
                  <th *ngIf="inputColumns.profesional && inputColumns.pet">Profesional</th>
                  <th *ngIf="inputColumns.sucursales">Sucursales</th>
                  <th *ngIf="inputColumns.direccion">Direccion</th>
                  <th *ngIf="inputColumns.fecha">Fecha</th>
                  <th *ngIf="inputColumns.servicio">Servicio</th>
                  <th *ngIf="inputColumns.estados">Estado</th>
                  <th *ngIf="inputColumns.opciones">Opciones</th>
                </tr>
              </thead>
              <tbody id="appointments-scroll-container" class="table-body">
                <ng-container *ngFor="let appointment of appointmentsPaginator">
                  <tr class="item_hover fw_500" (click)="appointmentEmitter.emit(appointment)">
                    <td *ngIf="inputColumns.pet"><span class="round img_branch"><img
                          [src]="appointment.patient.avatar|getImage" alt="user" width="50"></span>
                    </td>
                    <td *ngIf="inputColumns.pet">
                      <div class="sucursal_name">
                        {{ appointment.patient.petName }}
                      </div><small class="text-muted">{{ appointment.patient.species }}</small>
                    </td>
                    <td *ngIf="inputColumns.profesional  && !inputColumns.pet"><span class="round img_branch"><img
                          [src]="appointment.doctor.avatar|getImage" alt="user" width="50"></span>
                    </td>
                    <td *ngIf="inputColumns.profesional">
                      <div class="sucursal_name">{{ appointment?.doctor.names }} {{ appointment?.doctor.lastNames }}
                      </div>
                    </td>
                    <td *ngIf="inputColumns.sucursales">
                      <div class="sucursal_name">
                        {{ appointment.branch.name }}
                      </div><small class="text-muted">{{ appointment.branch.municipality.cityName }}</small>
                    </td>
                    <td *ngIf="inputColumns.direccion">
                      <div class="sucursal_name">
                        {{ appointment.branch.address }}
                      </div><small class="text-muted">{{ appointment.branch.neighbor }}</small>
                    </td>
                    <td *ngIf="inputColumns.fecha">
                      <div class="sucursal_name">
                        {{ appointment?.appointmentDate | noData:'No registrada' | date: 'yyyy-MM-dd':'+0500' }}
                        {{ appointment?.hour | noData | hourFormat}}</div>
                    </td>
                    <td *ngIf="inputColumns.servicio">
                      <div class="sucursal_name">{{ appointment.subservice.subserviceName }}</div><small
                        class="text-muted">{{ appointment.office }}</small>
                    </td>
                    <td *ngIf="inputColumns.estados">
                      <div class="sucursal_name">
                        <span *ngIf="appointment.status.type=='success'"
                          class="label label_active fz_14 fw_500">Atentida</span>
                        <span *ngIf="appointment.status.type=='inactive'"
                          class="label label_inactive fz_14 fw_500">Inactiva</span>
                        <span *ngIf="appointment.status.type=='cancelled'"
                          class="label label_danger fz_14 fw_500">Cancelada</span>
                        <span *ngIf="appointment.status.type=='in_progress'" class="label label_active fz_14 fw_500">En
                          curso</span>
                      </div>
                    </td>
                    <td *ngIf="inputColumns.opciones">
                      <div class="actions">
                        <i class="mdi mdi-check-circle info" title="Confirmar cita"
                          (click)="appointmentActivate(appointment)">
                        </i>
                        <i class="mdi mdi-pencil-circle update" title="Reprogramar cita"
                          (click)="rescheduleAppointmentActivate(appointment)">
                        </i>
                        <i class="mdi mdi-close-circle delete" title="! Click para seleccionar otra sucursal !"
                          (click)="appointmentCancel(appointment)">
                        </i>
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>

        <ng-container *ngIf="switchPerfil == 'citasActivasMascotas'; else elseTemplate">
          <app-paginator-custom [dataList]="appointmentsPets" [itemName]="'Citas'" [numItems]="numItems"
            [numOptions]="[(numItems*2),(numItems*3),(numItems*4),(numItems*5)]" (pageChange)="pageChange($event)"
            [scrollAnchorId]="'appointments-scroll-container'">
          </app-paginator-custom>
        </ng-container>
        <ng-template #elseTemplate>
          <app-paginator-custom [dataList]="appointments" [itemName]="'Citas'" [numItems]="numItems"
            [numOptions]="[(numItems*2),(numItems*3),(numItems*4),(numItems*5)]" (pageChange)="pageChange($event)"
            [scrollAnchorId]="'appointments-scroll-container'">
          </app-paginator-custom>
        </ng-template>


      </div>

    </div>
  </div>
</div>

<div *ngIf="appointmentsSelected" style="margin-top: -22px;">
  <div class="animated fadeIn">
    <div class="citas_usuario_title mb-4">Reprogramar Cita</div>

    <div id="idReprogramarCita" class="row">
      <div class="col-md-6 col-xs-12">
        <form *ngIf="activeSubService.length>0" class="mt-2 animated fadeIn" id="idCitaUser" [formGroup]="citasForm">

          <div class="row">

            <div class="col-12">
              <div class="form-group">
                <form-select [id]="'idInputServicio'" helper="Selecciona un servicio de la lista" label="Servicio"
                  placeholder="Seleccione..." [dataList]="activeSubService" [controlName]="'servicio'"
                  [idField]="'subServiceId'" [valueField]="'subServiceName'">
                </form-select>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <form-select [id]="'idInputMedico'" helper="Selecciona un medico de la lista" label="Medico"
                  placeholder="Seleccione..." [dataList]="doctors" [controlName]="'medico'" [idField]="'doctorId'"
                  [valueField]="'doctorNames'">
                </form-select>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <form-input [id]="'idInputMotivo'" label="Motivo de Consulta"
                  placeholder="Ejm: dolor persistente en mi ojo izquierdo" [controlName]="'motivo'">
                </form-input>
              </div>
            </div>
          </div>
        </form>
        <br>
        <div class="image-checkbox-wrapper">
          <input type="checkbox" [checked]="ordenDescuento" [id]="'basic_checkbox_orden'"
            class="material-inputs chk-col-teal" (change)="onCheckboxChange($event)">
          <label [for]="'basic_checkbox_orden'">Enviar Orden de descuento por whatsapp al paciente.</label>
        </div>

        <div class="col-12">
          <div class="row animated fadeIn">
            <div class="col-md-6 col-xs-12 mt-4 mb-4">
              <button class="btn btn-success btn-block" (click)="appointmentUpdate()">Confirmar cambios</button>

              <!-- <custom-buttons class="btn btn-success btn-block" (click)="appointmentUpdate()" text="Confirmar cambios"
                textColor="#fff" [title]="''">
              </custom-buttons> -->
            </div>
            <div class="col-md-6 col-xs-12 mt-4 mb-4">
              <button class="btn btn-danger btn-block" (click)="cancelAppointmentActivate()">Cancelar</button>

              <!-- <custom-buttons class="btn btn-danger btn-block" (click)="cancelAppointmentActivate()" text="Cancelar"
                textColor="#fff" [title]="''">
              </custom-buttons> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xs-12">

        <div class="fw_500 mt-4 mb-4">Cita para el, <span *ngIf="citaText.length>0"
            class="cita_fondo animated fadeIn">{{citaText}}</span></div>

        <div class="animated fadeIn">
          <custom-calendar [inputData]="inputData" [endPointSchedules]="endPointCalendar" [height]="'410px'"
            (payloadData)="calendarEvent($event)" (errorNoData)="errorNoData()">
          </custom-calendar>
        </div>

      </div>

    </div>

  </div>

  <div *ngIf="ordenDescuento">
    <!-- <app-form-section [number]="pasosInput + ''" [sectionName]="'Orden de Descuento'"></app-form-section> -->

    <div class="visual-orden-descuento mt-4">
      <ng-container
        *ngIf="(appointmentType === 'Veterinary' || switchPerfil === 'citasActivasMascotas'); else nonVetTemplate">
        <app-pet-ticket *ngIf="appointmentType === 'Veterinary'" [infoPatient]="infoPatient" [infoPet]="infoPet"
          [infoAppointment]="infoAppointment"></app-pet-ticket>
      </ng-container>
      <ng-template #nonVetTemplate>
        <app-patient-ticket *ngIf="appointmentType !== 'Veterinary'" [infoPatient]="infoPatient"
          [infoAppointment]="infoAppointment"></app-patient-ticket>
      </ng-template>
      <canvas #canvasEl style="display:none;"></canvas>
    </div>
  </div>

</div>
