import { AbstractControl, ControlContainer, FormControl, FormGroupDirective, ValidatorFn, Validators } from '@angular/forms';
import { AfterViewInit, Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'form-date',
  templateUrl: './form-date.component.html',
  styleUrls: ['../../form-styles.scss', './form-date.component.scss']
})
export class FormDateComponent implements AfterViewInit {
  @ViewChild('dateInputRef') dateInputRef!: ElementRef;

  @Input() placeholder: string = 'Default select placeholder';
  @Input() label: string = 'Default select label';
  @Input() helper: string | undefined;
  @Input() required: boolean = true;
  @Input() min?: Date = undefined;
  @Input() max?: Date = undefined;
  @Input() minInput?: String = '0';
  @Input() maxInput?: String = '0';
  @Input() minNow: boolean = false;
  @Input() maxNow: boolean = false;
  @Input() controlName!: string;
  @Input() idInput: string = 'idInput';
  @Input() customErrors: Record<string, string> = {};
  @Input() labelColor!: string;
  @Input() labelFontSize: string = '1rem'

  private now = new Date();

  errors: any[] = [];
  value: any = null;

  constructor(private controlContainer: ControlContainer, private render: Renderer2) { }

  ngAfterViewInit(): void {
    let existingValidators = this.control.validator ? [this.control.validator] : []; // Get the existing validators of the control

    existingValidators.push(this.customDateValidator.bind(this)); // Add the custom validator to the existing validator array

    let combinedValidators: ValidatorFn | null = Validators.compose(existingValidators); // Combine the validators into a single ValidatorFn using Validators.compose

    // Assign the combined validators to the FormControl
    this.control.setValidators(combinedValidators);
    this.control.updateValueAndValidity();

    // Subscribe to control state changes to display error messages
    this.control.statusChanges.subscribe(() => {
      this.errors = [];
      if (!this.control.errors || !this.control.dirty) return;

      if (this.customErrors) {
        let hasErrors = Object.keys(this.customErrors).filter(err => Object(this.control.errors).hasOwnProperty(err))
        hasErrors.forEach(error => this.control.errors && this.control.errors[error] && this.errors.push(this.customErrors[error]))
      }

      if (this.control.errors['required']) {
        this.errors.push(`La fecha es requerida`);
      }
      if (this.control.errors['invalidDate']) {
        this.errors.push(`Fecha inválida`);
      }
      if (this.control.errors['minNow']) {
        this.errors.push(`La fecha seleccionada debe ser igual o posterior a la fecha actual`);
      }
      if (this.control.errors['maxNow']) {
        this.errors.push(`La fecha seleccionada debe ser igual o anterior a la fecha actual`);
      }
      if (this.control.errors['minDate']) {
        this.errors.push(`La fecha seleccionada debe ser igual o posterior a ${this.min?.toLocaleDateString('es')}`);
      }
      if (this.control.errors['maxDate']) {
        this.errors.push(`La fecha seleccionada debe ser igual o anterior a ${this.max?.toLocaleDateString('es')}`);
      }
    });
  }

  setInternalValidators() {
    let inputRef = this.dateInputRef?.nativeElement as HTMLInputElement

    this.minNow && this.render.setAttribute(inputRef, 'min', this.now.toLocaleDateString() || '');
    this.maxNow && this.render.setAttribute(inputRef, 'max', this.now.toLocaleDateString() || '');
    this.min && this.render.setAttribute(inputRef, 'min', this.min.toLocaleDateString() || '');
    this.max && this.render.setAttribute(inputRef, 'max', this.max.toLocaleDateString() || '');
  }

  get control(): FormControl {
    let parentFormGroup = this.controlContainer as FormGroupDirective;
    return parentFormGroup.control.get(this.controlName) as FormControl;
  }

  get controlInvalid(): boolean {
    return this.control?.invalid || false;
  }

  get controlTouched(): boolean {
    return this.control?.touched || false;
  }

  get errorKeys(): string[] {
    return this.control?.errors ? Object.keys(this.control.errors) : [];
  }

  /* Property to check if the control is required */
  get isRequired(): boolean {
    return this.required && this.control?.errors?.['required'];
  }

  /* Property to check the control for errors */
  get hasErrors(): boolean {
    return !!this.control && this.control.invalid && this.control.dirty;
  }

  /* Property to check if the control has been touched */
  get isTouched(): boolean {
    return !!this.control && this.control.touched;
  }

  /* Custom validator to validate the date */
  private customDateValidator(control: AbstractControl): { [key: string]: any } | null {
    let selectedDate = new Date(control.value);

    if (control.value === null || control.value === undefined || control.value === '') {
      return null; // Validation is not applied if the field is empty
    }

    if (isNaN(selectedDate.getTime())) {
      return { invalidDate: true }; // return when invalid date
    }    

    if (this.minNow && selectedDate <= this.now) {
      return { minNow: true }; // The selected date must be equal to or later than the current date
    }

    if (this.maxNow && selectedDate > this.now) {
      return { maxNow: true }; // The selected date must be equal to or earlier than the current date
    }

    if (this.min && selectedDate < this.min) {
      return { minDate: { requiredDate: this.min.toISOString() } }; // The selected date must be equal to or later than the minimum date allowed
    }

    if (this.max && selectedDate > this.max) {
      return { maxDate: { requiredDate: this.max.toISOString() } }; // The selected date must be equal to or earlier than the maximum date allowed
    }

    return null; // return null when is valid
  }
}
