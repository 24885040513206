import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-not-found-data',
  templateUrl: './not-found-data.component.html',
  styleUrls: ['./not-found-data.component.scss'],
})

/**
 * Componente NotFoundDataComponent
 *
 * Este componente se utiliza para mostrar un mensaje cuando no se encuentran datos disponibles.
 * Utiliza animaciones Lottie para mostrar gráficos animados que representan la ausencia de datos,
 * personalizable en función del tipo de mensaje que se necesita mostrar.
 *
 * @Input type: string
 *    Define el tipo de mensaje que se va a mostrar, útil para distinguir entre diferentes escenarios.
 *    Por ejemplo, puede representar un contexto de citas médicas, beneficiarios, mascotas, etc.
 *
 * @Input message: string
 *    Mensaje de texto que se mostrará en el componente cuando no se encuentren datos.
 *
 * @Input button: boolean
 *    Indica si se debe mostrar o no un botón en el componente. Si es true, el botón será visible;
 *    si es false, no se mostrará.
 *
 * @Output buttonClicked: EventEmitter<void>
 *    Evento que se emite cuando el usuario hace clic en el botón, permitiendo realizar acciones específicas.
 *
 */

export class NotFoundDataComponent {
  @Input() type: string = '';
  @Input() message: string = '';
  @Input() button: boolean = false;
  @Input() textButton: string = '';
  @Output() buttonClicked = new EventEmitter<void>();

  options: AnimationOptions = { path: '' };

  private animationMap: { [key: string]: AnimationOptions } = {
    'notAppointment': { path: 'assets/lottie-animations/notAppointment.json' },
    'notFamily': { path: 'assets/lottie-animations/family.json' },
    'notFound': { path: 'assets/lottie-animations/notFoundPerson.json' },
  };

  ngOnInit(): void {
    this.options = this.animationMap[this.type];
  }

}
