<span [id]="idInput" class="input__group">
  <div class="box__label">
    <label class="input__label" [style.color]="labelColor" [style.fontSize]="labelFontSize" for="input_component">{{label}}<strong *ngIf="required"> *</strong></label>
    <i class="fa fa-question icon__helper" [title]="helper" *ngIf="helper"></i>
  </div>
  <div class="box__input date">
    <input #dateInputRef type="date" class="input__form" [min]="minInput" value="12/24/2023" [max]="maxInput" [formControl]="control" [ngClass]="{invalid: control.touched && control.invalid}">
  </div>
  <div class="box__error" [ngClass]="{ visible: errors.length }">
    <ul>
      <li class="box__error__item" *ngFor="let error of errors">
        <strong>* </strong>{{ error }}
      </li>
    </ul>
  </div>
</span>
