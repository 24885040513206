import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { isLoggedInGuard } from './auth/guards/is-logged-in.guard';
import { loggedRedirectionGuard } from './auth/guards/logged-redirection.guard';
import { TermsAndConditionsComponent } from './utils/terms-and-conditions/terms-and-conditions.component';
import { FamilyGroupsRequestComponent } from './utils/family-groups-request/family-groups-request.component';
import { ComfirmTempComponent } from './utils/comfirm-temp/comfirm-temp.component';
import { AppointmentInProgressComponent } from './utils/appointment-in-progress/appointment-in-progress.component';
import { CancelTempComponent } from './utils/cancel-temp/cancel-temp.component';

const routes: Routes = [
  { path: '', canActivate: [loggedRedirectionGuard], loadChildren: () => import('./landing-page/landing-page.module').then(m => m.LandingPageModule)},
  { path: 'auth', canActivate: [loggedRedirectionGuard], loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  { path: 'info', loadChildren: () => import('./info-noticias/info-noticias.module').then(m => m.InfoNoticiasModule ) },
  { path: 'directory', loadChildren: () => import('./directory/directory.module').then(m => m.DirectoryModule ) },
  { path: 'terms', component: TermsAndConditionsComponent },
  { path: 'family-group-request/:hash', component: FamilyGroupsRequestComponent },
  { path: 'verificar/:hash/gracias-cita-agendada', component: ComfirmTempComponent},
  { path: 'verificar/cancelar/:hash', component: CancelTempComponent},
  { path: 'appointment-in-progress', component: AppointmentInProgressComponent},
  { path: '', canActivate: [isLoggedInGuard], loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule ) },
  { path: '**', loadChildren: () => import('./landing-page/landing-page.module').then(m => m.LandingPageModule) },
];

@NgModule({
  // imports: [RouterModule.forRoot(routes, {useHash: true, preloadingStrategy: NoPreloading})],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
}) 

export class AppRoutingModule {}
