import { Injectable } from '@angular/core';
// import { SocialAuthService, GoogleLoginProvider } from "@abacritt/angularx-social-login";
import { SocialAuthService } from "@abacritt/angularx-social-login";
import { GoogleLoginProvider } from "@abacritt/angularx-social-login";

@Injectable({
  providedIn: 'root'
})
export class GoogleService  {

  constructor(private authService: SocialAuthService) {
  }

  getAccessToken(): void {
    // this.authService.getAccessToken(GoogleLoginProvider.PROVIDER_ID).then(accessToken => this.accessToken = accessToken);
  }

  getUser() {
    return this.authService.authState;
  }

  resetToken(): void{
    this.authService.refreshAccessToken(GoogleLoginProvider.PROVIDER_ID);
  }

  singOut() {
    this.authService.signOut();
  }

}
