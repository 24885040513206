import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { InformationService, SocketsService, SweetAlertService } from '@services';
import { LandingPageModule } from "../../landing-page/landing-page.module";
import { LottieModule } from 'ngx-lottie';
import { ButtonsComponent } from "../../shared-components/buttons/buttons.component";
import { catchError, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-comfirm-temp',
  standalone: true,
  imports: [CommonModule, LandingPageModule, LottieModule, ButtonsComponent],
  templateUrl: './comfirm-temp.component.html',
  styleUrls: ['./comfirm-temp.component.scss']
})
export class ComfirmTempComponent implements OnInit {

  hash!: string;
  requestData: any;
  // isloading: boolean = false;
  errorMessage: string = '';

  nameUser!: string;
  hour!: string;
  appointmentDate!: string;
  status!: string;
  patientType!: string;

  imgVerify: string = 'assets/images/verified.png'



  constructor(private routeParam: ActivatedRoute,
    private router: Router,
    private informationService: InformationService,
    private swalService: SweetAlertService,
    private socket: SocketsService,

  ) { }


  ngOnInit(): void {

      //Suscribirme a los params para obtener el hash:
      this.routeParam.paramMap.subscribe(params => {
        const hash = params.get('hash');
        this.getRequestData(hash);
      })
    
  }





  getRequestData(hash: any) {
    this.informationService.getRequestTemp(hash).pipe(
      catchError((error: HttpErrorResponse) => {
        // Manejo del error
        // console.error('Error al obtener los datos:', error);
        let status = error.status;
        // let messageError = error.error.message;

        // CASO cita ya verificada o inexistente
        if (error.error.ok === false && status === 401) {
          this.swalService.lauchSwal(
            'Error de confirmacion',
            'Esta no cita no existe o anteriormente ya fue confirmada',
            'error'
          );
          this.router.navigate(['']);
        }

        // CASO 2 cita existente el mismo dia
        if (error.error.ok === false && status === 400) {
          this.swalService.lauchSwal(
            'Error de confirmacion',
            'Este usuario ya tiene una cita programada con el mismo dia y servicio',
            'error'
          );
          this.router.navigate(['']);
        }
        // this.isloading = false;
        //  console.log('message error', this.errorMessage);


        // Retornar un observable vacío o un valor por defecto
        return of(null);
      })
    ).subscribe((resp: any) => {
      this.hash = hash;
      this.requestData = resp;
      // this.isloading = !this.requestData;
      console.log('respuesta', this.requestData);
    
      if (this.requestData?.body?.patientType) {
        // Tipo de paciente (persona / mascotas)
        this.patientType = this.requestData?.body?.patientType;
        console.log('tipo', this.patientType);
    
        console.log('status:', this.requestData.body.status);
        console.log('ok:', this.requestData.ok);
    
        // HUMANS
        // CASO 1
        if (this.requestData.ok === true && this.requestData.body.status === 'appointment' && this.patientType === 'members') {
          this.status = this.requestData.body.status;
          this.nameUser = this.requestData.body.nombres;
          this.hour = this.formatHourToAMPM(this.requestData.body.hour);
          this.appointmentDate = this.formatearFechaISO(this.requestData.body.appointmentDate);
          console.log('Caso 1 HUMANS');

          this.socket.emit('verifyTemporalAppointments', {
            branchId: this.requestData.body.branchId
          });  
        }
    
        // CASO 2
        if (this.requestData.ok === true && this.requestData.body.status === 'user_exists' && this.patientType === 'members') {
          this.status = this.requestData.body.status;
          this.nameUser = this.requestData.body.nombres;
          this.hour = this.formatHourToAMPM(this.requestData.body.hour);
          this.appointmentDate = this.formatearFechaISO(this.requestData.body.appointmentDate);
          console.log('Caso 2 HUMANS');
          
          this.socket.emit('verifyTemporalAppointments', {
            branchId: this.requestData.body.branchId
          });  
        }
    
        // CASO 3
        if (this.requestData.ok === true && this.requestData.body.status === 'verify' && this.patientType === 'members') {
          this.status = this.requestData.body.status;
          this.nameUser = this.requestData.body.nombres;
          this.hour = this.formatHourToAMPM(this.requestData.body.hour);
          this.appointmentDate = this.formatearFechaISO(this.requestData.body.appointmentDate);
          console.log('Caso 3 HUMANS');

          this.socket.emit('verifyTemporalAppointments', {
            branchId: this.requestData.body.branchId
          });  
        }
    
        // PETS
        // CASO 1
        if (this.requestData.ok === true && this.requestData.body.status === 'appointment' && this.patientType === 'pets') {
          console.log('caso 1 pets');
          this.status = this.requestData.body.status;
          this.nameUser = this.requestData.body.nombres;
          this.hour = this.formatHourToAMPM(this.requestData.body.hour);
          this.appointmentDate = this.formatearFechaISO(this.requestData.body.appointmentDate);

          this.socket.emit('verifyTemporalAppointments', {
            branchId: this.requestData.body.branchId
          });  
        }
    
        // CASO 2
        if (this.requestData.ok === true && this.requestData.body.status === 'user_exists' && this.patientType === 'pets') {
          console.log('caso 2 pets');
          this.status = this.requestData.body.status;
          this.nameUser = this.requestData.body.nombres;
          this.hour = this.formatHourToAMPM(this.requestData.body.hour);
          this.appointmentDate = this.formatearFechaISO(this.requestData.body.appointmentDate);

          this.socket.emit('verifyTemporalAppointments', {
            branchId: this.requestData.body.branchId
          });  
        }
    
        // CASO 3
        if (this.requestData.ok === true && this.requestData.body.status === 'verify' && this.patientType === 'pets') {
          console.log('caso 3 pets');
          this.status = this.requestData.body.status;
          this.nameUser = this.requestData.body.nombres;
          this.hour = this.formatHourToAMPM(this.requestData.body.hour);
          this.appointmentDate = this.formatearFechaISO(this.requestData.body.appointmentDate);

          this.socket.emit('verifyTemporalAppointments', {
            branchId: this.requestData.body.branchId
          });  
        }
      }
    
      console.log('error en las validaciones');
    });
    
  }

  // Obtener la Hora en formato 12 horas
  formatHourToAMPM(hourString: string): string {
    const [hour, minutes] = hourString.split(':').map(Number); // Convierte a números
    const isPM = hour >= 12;
    const formattedHour = hour % 12 || 12; // Convierte a formato de 12 horas, usando 12 si es mediodía o medianoche
    const amPmSuffix = isPM ? 'PM' : 'AM';

    return `${formattedHour}:${minutes
      .toString()
      .padStart(2, '0')} ${amPmSuffix}`;
  }

  // Formatear la fecha de nacimiento sin librerias :)
  formatearFechaISO(fechaNacimiento: string) {
    // Extrae los componentes de la fecha
    const [fecha] = fechaNacimiento.split('T');
    const [año, mes, día] = fecha.split('-');

    // Construye la fecha en formato 'yyyy-mm-dd'
    const fechaFormateada = `${día}-${mes}-${año}`;

    return fechaFormateada;
  }

  goHome() {
    this.router.navigate(['']);
  }

}
