<h4 class="days__title">{{ init.day }}</h4>
<!--
  <button (click)="closeComponent()" class="button-cerrar"> x </button>
-->
<div class="hours" *ngIf="!loadingHours; else loading">
  <ng-container *ngIf="hoursData?.length; else noData">
    <ng-container *ngFor="let _hour of hoursData">
        <div class="hours__item" (click)="confirmHour(_hour)">
          <span class="hours__item__label" [attr._hour]="_hour.hour" [ngClass]="{disabled:!_hour.available}">{{
            _hour.hour|formatHour }}</span>
          <!-- <span class="hours__item__next" [attr.subhour]="subhour.hour" [ngClass]="{disabled:!subhour.available}"
            (click)="confirmHour(subhour)">Siguiente</span> -->
          <!-- <span class="hours__item__label">{{ subhour.hour|formatHour }}</span>
          <span class="hours__item__next">Siguiente</span> -->
        </div>

      <!-- <div class="hours__item">
        <div class="main__hours" (click)="selectHour(_hour, $event)">
          <button class="hours__item__label">{{ _hour.initialHour|formatHour }}</button>
        </div>
        <div class="sub__hours">
          <div class="sub__hours__item" *ngFor="let subhour of _hour.hours" (click)="subhour.available?selectSchedule($event):null" [attr.subhour]="subhour.hour">
            <span class="sub__hours__item__label" [attr.subhour]="subhour.hour" [ngClass]="{disabled:!subhour.available}">{{ subhour.hour|formatHour }}</span>
            <span class="sub__hours__item__next" [attr.subhour]="subhour.hour" [ngClass]="{disabled:!subhour.available}" (click)="confirmHour(subhour)">Siguiente</span>
          </div>
        </div>
      </div> -->
    </ng-container>
  </ng-container>
  <ng-template #noData>
    <label class="nodata__schedule">
      <i class="fa fa-warning nodata__schedule__icon"></i>
      <span>Nó hay horarios disponibles para la fecha seleccionada</span>
    </label>
  </ng-template>
</div>
<button (click)="closeComponent()" class="button-cerrar"> Cerrar </button>

<!-- <custom-buttons class="button-cerrar animated fadeIn" text="Cerrar" textColor="#fff" [title]="''"
  (click)="closeComponent()">
</custom-buttons> -->

<ng-template #loading>
  <ng-lottie [options]="options"></ng-lottie>
</ng-template>
