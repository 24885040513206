import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { LandingPageRoutingModule } from './landing-page-routing.module';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { LandingHeaderComponent } from './components/landing-header/landing-header.component';
import { LandingContentComponent } from './components/landing-content/landing-content.component';
import { LandingFooterComponent } from './components/landing-footer/landing-footer.component';

import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { CustomFormModule } from '../custom-forms/custom-form.module';
import { ButtonsComponent } from '../shared-components/buttons/buttons.component';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    LandingPageComponent,
    LandingHeaderComponent,
    LandingContentComponent,
    LandingFooterComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    LandingPageRoutingModule,
    LottieModule.forRoot({ player: playerFactory }),
    CustomFormModule,
    ReactiveFormsModule,
    FormsModule,
    ButtonsComponent
  ],
  exports: [
    LandingPageComponent,
    LandingHeaderComponent,
    LandingContentComponent,
    LandingFooterComponent,
  ],
})
export class LandingPageModule {}
