<div class="container_image animated zoomIn fast">
  <div class="card_image bg_2" [class.card_image_height]="minHeight">
    <div *ngIf="viewHeader" class="card_imagen_header">
      <button type="button" class="btn btn-circle btn_back_2 " (click)="regresar()" title="Regresar"><i
          class="ti ti-arrow-left"></i> </button>

      <div class="title_header fw_500">Recortar Imagen</div>
      <button type="button" class="btn btn-circle btn_back_2 " (click)="regresar()" title="Cancelar"><i
          class="ti ti-close"></i> </button>
    </div>


    <!-- crop anterior -->
    <div *ngIf="profile" class="crop_cover">
      <image-cropper class="image-cropper-cover" format="png" [className]="'crop_image'" [imageFile]="fileComponent"
        [resetCropOnAspectRatioChange]="true" [maintainAspectRatio]="true" [aspectRatio]="aspectRatio"
        (imageCropped)="imageCropped($event)">
      </image-cropper>
      <div class="container_prev_cover">
        <!-- <div class="container_prev">
                    <img  class="oscurecer" [src]="croppedImage" />
                    <img  class="cropper_img" [src]="croppedImage" />
                </div>       -->
        <!-- <button *ngIf="postFile" type="button" class="btn btn_computador" (click)="sendFile()">Subir imagen</button> -->

        <custom-buttons
          *ngIf="postFile"
          text="Subir imagen"
          textColor="#fff"
          width="300px"
          type="button"
          [title]="''"
          (click)="sendFile()"
          icon="fa fa-check">
        </custom-buttons>
        <!-- <button *ngIf="!postFile" type="button" class="btn btn_computador" (click)="cropperEmmit()">Aceptar
          cambios</button> -->
        <custom-buttons
          *ngIf="!postFile"
          text="Recortar y aceptar cambios"
          textColor="#fff"
          width="300px"
          type="button"
          [title]="''"
          (click)="cropperEmmit()"
          icon="fa fa-check">
        </custom-buttons>
      </div>
    </div>


    <div *ngIf="!profile" class="crop_cover">
      <image-cropper class="image-cropper-cover" format="png" [imageFile]="fileComponent" [maintainAspectRatio]="true"
        [aspectRatio]="aspectRatio" (imageCropped)="imageCropped($event)">
      </image-cropper>
      <div class="container_prev_cover">
        <!-- <button *ngIf="postFile" type="button" class="btn btn_computador" (click)="sendFile()">Subir imagen</button> -->

        <custom-buttons
          *ngIf="postFile"
          text="Subir imagen"
          textColor="#fff"
          width="300px"
          type="button"
          [title]="''"
          (click)="sendFile()"
          icon="fa fa-check">
        </custom-buttons>

        <!-- <button *ngIf="!postFile" type="button" class="btn btn_computador" (click)="cropperEmmit()">Aceptar
          cambios</button> -->

        <custom-buttons
          *ngIf="!postFile"
          text="Recortar y aceptar cambios"
          textColor="#fff"
          width="300px"
          type="button"
          [title]="''"
          (click)="cropperEmmit()"
          icon="fa fa-check">
        </custom-buttons>
      </div>
    </div>


    <!-- crop anterior -->

  </div>
</div>
