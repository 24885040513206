import { Injectable } from '@angular/core';
import { SessionRole, UserRole, loginData } from 'src/app/interfaces/auth.interface';
import { RolSession } from 'src/app/interfaces/session.interface';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor() { }

  rolGenerator(body: loginData, rolSession: RolSession): SessionRole {

    let { rol, _idData } = rolSession;
    let roles = body.roles;
    let position: number;
 
    // Copia superficial del array roles
    roles = roles.slice();

    if(rol === 'user' || rol === 'advisor') {

      const data = { ...body.dataSocialUser, userStates: body.userStates };

      // Si es un usuario google que no ha completado su perfil
      if(data?.userStates?.isGoogleProfileCompleted === 'incomplete') {
        let rolSelect:UserRole = { ...roles[0], data };
        return { rolSelect, roles: [], menu: [] };
      }

      position =  roles.findIndex( rolList => rolList.rol === rol );

      const userRol = roles[position];
      const { name, avatar } = userRol; 

      let userRole: UserRole = {
          rol,
          _idData,
          name,
          data,
          avatar
      }

      roles.splice(position, 1);
      return { rolSelect: userRole, roles, menu: data.menus.menuWeb };

    } 
    
    
    else if(rol === 'doctor') {

      position =  roles.findIndex( rol => rol.rol === 'doctor');
      let userRol = roles[position]; 
      const { name, avatar } = userRol; 
      let data: any = userRol.data;

      let userRole = {
        _idData,
        rol,
        name,
        avatar,
        data
      };

      roles.splice(position, 1);
      return { rolSelect: userRole, roles, menu: data.menus.menuWeb };

    } else {

      // Provider
      position =  roles.findIndex( rol => (rol.rol === 'provider' && rol._idData === _idData ));

      let userRol = roles[position];

      const { name, avatar,  } = userRol; 
      let data: any = userRol.data;

      // get provider type
      let typeOfProviver: 'Laboratory' | 'Services' | 'Imaging' | 'Veterinary' | null = 'Services';
      
      for (const provider of data.providerType) {
        if (provider.providerBy === "healt_provider") {
          const firstService = provider.services.find((service: any) => service === "Services" || service === "Veterinary");
          if (firstService) {
            typeOfProviver = firstService;
          }
        } 
      }

      let userRole = {
        _idData,
        rol,
        name,
        avatar,
        data
      };

      roles.splice(position, 1);
      localStorage.setItem('session', JSON.stringify(rolSession) );
      return { rolSelect: userRole, roles, menu: data.menus.menuWeb, typeOfProviver };
    } 
  }

}
