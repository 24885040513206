import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FamilyGroup } from '@interfaces/information.interface';
import { Tab } from '@interfaces/shared-components.interface';
import { ModalService } from '@services';

@Component({
  selector: 'app-beneficiaries-family',
  templateUrl: './beneficiaries-family.component.html',
  styleUrls: ['./beneficiaries-family.component.scss']
})
export class BeneficiariesFamilyComponent implements OnInit {

  @Input() data?: any;
  @Input() groupSelected?:FamilyGroup;
  @Input() options=false;
  @Output() myEvent = new EventEmitter<boolean>();
    
  tabs:Tab[]=[
    {tabName:'Beneficiarios',tabSwitch:'beneficiariesComponent',tabClass:'active'},
    {tabName:'Mascotas',tabSwitch:'petsComponent',tabClass:''},
  ];

  public switchPerfil='beneficiariesComponent';


  constructor ( public modalService:ModalService){}
  ngOnInit(): void {
    if (this.data) {
      if (this.data.groupSelected) {
        this.groupSelected = this.data.groupSelected;   
      } 
      if (this.data.options) {
        this.options = this.data.options;   
      }       
    }
  }

  switch(tab:string){
    this.switchPerfil=tab;
  }


  cerrarBeneficiaries(){
    this.myEvent.emit(true);
    this.modalService.closeModal();
  }

}
