import { Component,Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent {

  @Input() porcentajePerfil = 0;

  get getPorcentajeWidth(): string {
    return `width: ${this.porcentajePerfil}%`;
  }

  get getPorcentajeColor():string{
    if (this.porcentajePerfil >= 100) {
      return 'bg-success';      
    } else if(this.porcentajePerfil < 100 && this.porcentajePerfil >= 80){
      return 'bg-info'; 
    } else if(this.porcentajePerfil < 80 && this.porcentajePerfil >= 50){
      return 'bg-warning';       
    } else{
      return 'bg-danger';     
    }
  }

}
