<!-- patient-ticket.component.html -->
<div class="ticket-container">
  <div class="container">
    <div class="custom-row">
      <img src="assets/images/logo_black_font.png" alt="Logo" class="image" />
      <div class="custom-margin-15">
        <div class="custom-inner-row">
          <p class="custom-value date-title">{{ infoAppointment?.fechaCita | date: 'yyyy-MM-dd' }}</p>
        </div>
        <div class="custom-inner-row">
          <p class="custom-subTitle date-title">Fecha</p>
        </div>
      </div>
    </div>

    <div class="pl-2 pr-2">
      <div class="row">
        <div class="rect">
          <div class="col-8 p-0">
            <p class="custom-subTitle size-select-medium">Orden de descuento No.</p>
          </div>
          <div class="col-4 p-0">
            <p class="idAppointment">{{ infoAppointment?.idCita }}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="infoUser">
          <!-- <div class="circle top-left"></div>
          <div class="circle top-right"></div>
          <div class="circle bottom-left"></div>
          <div class="circle bottom-right"></div> -->
          <div class="mt-2 mb-2">
            <div class="custom-inner-row">
              <p class="size-select date-title">{{ infoPatient?.nombres }} {{ infoPatient?.apellidos }}</p>
            </div>
            <div class="custom-inner-row">
              <p class="custom-subTitle date-title size-select">Nombres</p>
            </div>
          </div>

          <div class="mt-2 mb-2">
            <div class="custom-inner-row">
              <p class="size-select date-title">{{ infoPatient?.numeroDocumento }}</p>
            </div>
            <div class="custom-inner-row">
              <p class="custom-subTitle date-title size-select">Identificación</p>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="rect-generic">
          <p class="custom-subTitle size-select spacing">Información Cita</p>
        </div>
      </div>

      <div class="row">
        <div class="infoAppointment">
          <!-- <div class="circle top-left"></div>
          <div class="circle top-right"></div>
          <div class="circle bottom-left"></div>
          <div class="circle bottom-right"></div> -->
          <div class="margin-15 pl-2">
            <div class="mt-2 mb-2">
              <div class="row">
                <p class="size-select date-title">{{ infoAppointment?.doctor }}</p>
              </div>
              <div class="row">
                <p class="subTitle date-title size-select">Doctor</p>
              </div>
            </div>
            <div class="mt-2 mb-2">
              <div class="row">
                <p class="size-select date-title">{{ infoAppointment?.entity }}</p>
              </div>
              <div class="row">
                <p class="subTitle date-title size-select">Entidad</p>
              </div>
            </div>
            <div class="mt-2 mb-2 ml-0 pl-0 col-7">
              <div class="row">
                <p class="size-select date-title">{{ infoAppointment?.address }}</p>
              </div>
              <div class="row">
                <p class="subTitle date-title size-select">Dirección</p>
              </div>
            </div>
            <div class="row between mt-2">
              <div class="col-7 pl-0 position-end">
                <p class="size-select date-title">{{ infoAppointment?.subservice }}</p>
              </div>
              <div class="col-5 pl-0 position-end">
                <p class="size-select date-title">{{ infoAppointment?.cellphone || 'No registra' }}</p>
              </div>
            </div>
            <div class="row between">
              <div class="col-7 pl-0">
                <p class="subTitle date-title size-select">Servicio</p>
              </div>
              <div class="col-5 pl-0">
                <p class="subTitle date-title size-select">Teléfono</p>
              </div>
            </div>
            <div class="row between mt-2">
              <div class="col-7 pl-0">
                <p class="size-select date-title">{{ infoAppointment?.fechaCita | date: 'yyyy-MM-dd' }}</p>
              </div>
              <div class="col-5 pl-0">
                <p class="size-select date-title">{{ formatTime(infoAppointment?.hour) }}</p>
              </div>
            </div>
            <div class="row between">
              <div class="col-7 pl-0">
                <p class="subTitle date-title size-select">Fecha</p>
              </div>
              <div class="col-5 pl-0">
                <p class="subTitle date-title size-select">Hora</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="justify-content-evenly">
        <div class="price mt-2 mb-2 ml-0 mr-0 p-0">
          <div class="precioStack">
            <p class="pricetitle">Precio</p>
            <p class="pricevalue">{{ infoAppointment?.price | currencyFormat }}</p>
          </div>
        </div>
        <div class="discounted-price mt-2 mb-2 ml-2 mr-0 p-0">
          <div class="precioStack">
            <p class="pricetitle">Precio final</p>
            <p class="pricevalue">{{ infoAppointment?.discountedPrice | currencyFormat }}</p>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
