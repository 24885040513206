import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyFormat'
})

export class CurrencyFormatPipe implements PipeTransform {
  transform(value: number): string {
    if (isNaN(value)) {
      return '';
    }

    const parts = value.toFixed(2).split('.');
    const formattedValue = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ',' + parts[1];

    return `$${formattedValue}`;
  }
}