import { Component } from '@angular/core';

@Component({
  selector: 'app-auth-component',
  templateUrl: './auth-component.html',
  styleUrls: ['./auth-component.scss']
})
export class AuthComponent {

  public imagesList = [
    { src : '../assets/images/background/backgroung-auth1.png' },
    { src : '../assets/images/background/backgroung-auth2.png' },
    { src : '../assets/images/background/backgroung-auth3.png' },
    { src : '../assets/images/background/backgroung-auth4.png' },
    { src : '../assets/images/background/backgroung-auth5.png' },
    { src : '../assets/images/background/backgroung-auth6.png' }
  ];

}
