import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'form-input-symbol',
  templateUrl: './form-input-symbol.component.html',
  styleUrls: ['../../form-styles.scss', './form-input-symbol.component.scss']
})
export class FormInputSymbolComponent {
  @ViewChild('priceInputRef', { static: true }) priceInputRef!: ElementRef;

  @Input() customErrors: Record<string, string> = {};
  @Input() helper: string | undefined = undefined;
  @Input() placeholder: string = '$100.000';
  @Input() label: string = 'Default label';
  @Input() idInput: string = 'idInput';
  @Input() porcent: boolean = false;
  @Input() required: boolean = true;
  @Input() disablebInput: boolean = false;
  @Input() price: boolean = false
  @Input() controlName!: string;
  @Input() type: string = 'text';

  @Output() limpiarEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  public errors: any[] = [];
  public priceValue: any = ''

  constructor(private controlContainer: ControlContainer, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.control.valueChanges.subscribe(rs => {
      if (!rs) return

      let tmpValue = this.control.value
      let formatedValue = `${Number(tmpValue.replace(/,/g, '.')).toLocaleString('es-ES')}`;
      formatedValue = this.porcent ? `${formatedValue}%` : `$${formatedValue}`
      this.priceValue = formatedValue
      !this.control.dirty && setTimeout(() => this.control.markAsDirty(), 1);

      this.cdr.detectChanges()
    })
  }

  ngAfterViewInit(): void {
    this.control.updateValueAndValidity();

    this.control.statusChanges.subscribe(() => {
      this.errors = [];
      if (!this.control.errors || !this.control.dirty) return

      if (this.customErrors) {
        let hasErrors = Object.keys(this.customErrors).filter(err => Object(this.control.errors).hasOwnProperty(err))
        hasErrors.forEach(error => this.control.errors && this.control.errors[error] && this.errors.push(this.customErrors[error]))
      }

      this.control.errors['required'] && this.errors.push(`El campo es requerido`)
      this.control.errors['numeric'] && this.errors.push(`El campo solo acepta números. No incluya letras o símbolos`)
    });
  }

  get control(): FormControl {
    const parentFormGroup = this.controlContainer as FormGroupDirective;
    return parentFormGroup.control.get(this.controlName) as FormControl;
  }

  get controlInvalid(): boolean {
    return this.control?.invalid || false;
  }

  get controlTouched(): boolean {
    return this.control?.touched || false;
  }

  get errorKeys(): string[] {
    return this.control?.errors ? Object.keys(this.control.errors) : [];
  }

  setPrice(event: Event) {
    let value = ((event as InputEvent).target as HTMLInputElement)?.value || ''    
    !this.control.dirty && this.control.markAsDirty()
    this.control.setErrors(null)
    this.errors = []

    

    if (value == '' || value == '$' || value == '%') {
      ((event as InputEvent).target as HTMLInputElement).value = this.porcent ? `0%` : `$0`;
      this.priceValue = this.porcent ? `0%` : `$0`
      this.control.setValue(0)
      this.control.updateValueAndValidity()
      this.cdr.detectChanges()
      return
    }

    let regexWords = /[a-z]/g

    if (regexWords.test(value)) {
      this.control.setErrors({ numeric: true });
      ((event as InputEvent).target as HTMLInputElement).value = this.porcent ? `0%` : `$0`
      this.priceValue = this.porcent ? `0%` : `$0`
      this.control.setValue(0)
      this.control.updateValueAndValidity()
      this.cdr.detectChanges()
      return
    }

    let integerValue = value.split(',')[0]
    let decimalsValue = value.split(',')[1]

    let tmpValue = `${integerValue.replace(/[^0-9,]/g, '')}`

    let withDecimals = (parseFloat(`${tmpValue.replace(/,/g, '.')}.${decimalsValue}`).toFixed(2))//.replace()
    let decimals = withDecimals.split(',')[1]

    let output = `${Number(withDecimals.split(',')[0].replace(/,/g, '.')).toLocaleString('es-ES')}${decimals ? `,${decimals}` : ''}`
    output = this.porcent ? `${output}%` : `$${output}`;

    this.priceValue = output;
    this.control.setValue(output.replace(/[^0-9,]/g, ''));

    setTimeout(() => this.cdr.detectChanges(), 10);
  }

  limpiarValue(){
    this.control.setValue('');
    this.limpiarEmitter.emit(true);
    
  }

}