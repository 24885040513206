import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SweetAlertService } from '../../../services/sweet-alert.service';
import { TipoProveedor } from '../../../interfaces/information.interface';

@Component({
  selector: 'app-tipo-empresa',
  templateUrl: './tipo-empresa.component.html',
  styleUrls: ['./tipo-empresa.component.scss'],
})
export class TipoEmpresaComponent {

  @Input() tipoClass = ''
  @Input() idInput = '';

  @Output() tiposProveedorEmitter: EventEmitter<TipoProveedor[]> = new EventEmitter<TipoProveedor[]>();

  listaTipoProveedor: TipoProveedor[] = [
    { codigo: 1, proveedorDe: 'healt_provider', tipo: 'Services', name: 'Servicios de Salud'},
    { codigo: 1, proveedorDe: 'healt_provider', tipo: 'Veterinary', name: 'Veterinaria'},
    // { codigo: 2, proveedorDe: 'healt_provider', tipo: 'Laboratory', name: 'Laboratorio'},
    // { codigo: 3, proveedorDe: 'healt_provider', tipo: 'Imaging',name: 'Imagenología'}
  ];
  tipoProveedorSeleccionados: TipoProveedor[] = [];

  constructor(private swalService: SweetAlertService){
    
  }

  validarCodigosUnicos(objeto: TipoProveedor, seleccionados: TipoProveedor[]): TipoProveedor | undefined {
    for (const seleccionado of seleccionados) {
      if (seleccionado.codigo === objeto.codigo) {
        return seleccionado;
      }
    }
    return undefined;
  }
  
  agregarSeleccionado(tipoProveedor: TipoProveedor) {
    const repetido = this.validarCodigosUnicos(tipoProveedor, this.tipoProveedorSeleccionados);
    var inputSelectTipo = document.getElementById(this.idInput);
  
    if (repetido) {
      this.swalService.lauchSwal('', `No puedes agregar "${tipoProveedor.name}" porque ya tienes agregado "${repetido.name}", Puedes agregar otros tipos como Laboratorio e Imagenologia.`, 'warning');
    } else {
      if (inputSelectTipo) {
        inputSelectTipo.classList.remove('invalid');
      }

      this.tipoProveedorSeleccionados.push(tipoProveedor);
      this.tiposProveedorEmitter.emit(this.tipoProveedorSeleccionados);
    }
  }

  
  eliminarSeleccionado(tipo: TipoProveedor) {

    const index = this.tipoProveedorSeleccionados.findIndex(
      (item) => item.tipo === tipo.tipo
    );
    if (index !== -1) {
      this.tipoProveedorSeleccionados.splice(index, 1);
    }
    this.tiposProveedorEmitter.emit(this.tipoProveedorSeleccionados);

  }


}
