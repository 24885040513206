<span [id]="idInput" class="input__group">
  <div *ngIf="labelView" class="box__label">
    <label class="input__label" [style.color]="labelColor" for="input_component">{{label}}<strong *ngIf="required"> *</strong></label>
    <i class="fa fa-question icon__helper" [title]="helper" *ngIf="helper"></i>
  </div>
  <div class="box__input">
    <input #inputRef class="input__form"   [ngStyle]="{'border-radius': borderRadiusInput}" [class.shadow_none]="shadowNone" [class.padding_adjust]="paddingAdjust" [value]="value" type="text" name="input_component" autocomplete="off" [placeholder]="placeholder" [ngClass]="{invalid: control.touched && control.invalid}" (keyup)="onSearch($event)" [disabled]="control.disabled">
    <input #hidenInputRef [formControl]="control" type="hidden">
    <i class="fa fa-close icon__clear" [ngClass]="{visible: inputRef.value != ''}" (click)="clearSearch(true)" title="Limpiar" *ngIf=" !control.disabled"></i>
  </div>
  <div class="list__selector" [ngClass]="{active: resultList.length}">
    <ul #selectorListRef>
      <!-- <option *ngFor="let dataItem of dataList" [value]="dataItem[idField]">{{ dataItem[valueField] }}</option> -->
      <li class="list__selector__item" *ngFor="let item of resultList" [attr.data-field-id]="item[idField]" (click)="selectItem(item)">{{ item[valueField] }}</li>
    </ul>
  </div>
  <div class="box__error" [ngClass]="{visible:errors.length}">
    <ul>
      <li class="box__error__item" *ngFor="let error of errors"><strong>* </strong>{{error}}</li>
    </ul>
  </div>
</span>
