<!-- ============================================================== -->
<!-- header  -->
<!-- ============================================================== -->
<header class="topheader nav-darky" id="top">
  <div class="fix-width">
    <nav class=" navbar navbar-expand-md navbar-dark">
      <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
        data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <!-- Logo will be here -->
      <a class="navbar-brand" href="index.html">
        <img class="img-logo" [src]="iconPath" alt="logo" />
      </a>
      <!-- This is the navigation menu -->
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav ml-auto stylish-nav">

          <!-- Enlaces externos opcionales -->
          <div *ngIf="linkNull === false" class="li-externos">
            <li class="nav-item" *ngFor="let link of externalLinks">
              <a class="nav-link text-white font-medium" href="javascript:void(0)"
                (click)="onLinkClick(link.targetId)">{{ link.name }}</a>
            </li>
          </div>

          <!-- ======= LINKS PROFESIONAL DE LA SALUD-->
          <!--
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle text-white font-medium" href="#" id="navbarDropdownMenuLink"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">¿Eres profesional de la salud?</a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <a class="dropdown-item" routerLink="/info/doctor">Profesional de Salud</a>
                <a class="dropdown-item" routerLink="/info/user">Usuario</a>
              </div>
            </li>

          -->

          <li class="nav-item" *ngIf="!login" >
            <a class="nav-link  text-white font-medium" routerLink="/auth/register">Registrarme</a>
            <!-- <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <a class="dropdown-item" routerLink="/auth/register/doctor"
                >Registrar profesional de salud</a
              >
              <a class="dropdown-item" routerLink="/auth/register/user"
                >Registrar usuario</a
              >
            </div> -->
          </li>


          <li class="nav-item" *ngIf="!login">
            <a style="margin-top: 1px; margin-left: 20px; border-radius: 15px; width: 200px" class="nav-link-login btn btn_default font-medium text-white"
              routerLink="/auth/login" title="Inicia sesion con un usuario registrado">
              Iniciar sesión <i style="left: 3px;" class="fa fa-sign-in"></i>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</header>
<!-- ============================================================== -->
<!-- header  -->
<!-- ============================================================== -->
