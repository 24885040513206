import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InfoUserComponent } from './components/info-user/info-user.component';
import { InfoDoctorComponent } from './components/info-doctor/info-doctor.component';

const routes: Routes = [
  { path: 'user', component: InfoUserComponent },
  { path: 'doctor', component: InfoDoctorComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InfoNoticiasRoutingModule { }
