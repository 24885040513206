import { Injectable } from '@angular/core';
import {  ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class NoAccessAppointment  {
  
  constructor(private router: Router) { }
  
  canDeactivate(component: unknown,currentRoute: ActivatedRouteSnapshot,currentState: RouterStateSnapshot,nextState?: RouterStateSnapshot):boolean{
    
    //console.log('componente', component);
    //console.log('currentRoute', currentRoute );
    //console.log('currentState', currentState);
    //console.log('nextState', nextState);
    
    
    if (nextState?.url.includes('/user/detalles-servicio')) {
        this.router.navigate(['/user/home']);
        return false;
    }

    if(nextState?.url.includes('/directory-details')){
        this.router.navigate(['/user/home']);
        return false;
    }
    
    return true;


  }

}