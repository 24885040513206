<!-- 
  <div class="bg-dark">
    <div style="padding-bottom: 35px;"></div>
  
    <div style="position: absolute;width: 100%; height:90%; opacity: 0.3;"></div>
    <div class=" text-center ">
      <h1 style="position: relative; " class="text-white ">Términos y condiciones</h1>
      <hr class="col-lg-4 " style="background-color: rgb(255, 255, 255);">
    </div>
  
    <div style="margin-bottom: 40px;"></div>
  
    <div class="contenido">
  
      <div class="row">
        <div class=col-md-1></div>
        <div class="col-md-10 text-white">
  
  
          <p>Para recibir descuentos debe estar inscrito y sus datos diligenciados al 100%, los descuentos deberá ser
            redimido a nuestros terceros para confirmar día fecha y hora de su requerimiento. No tenemos límite de
            descuentos por persona. El
            descuento se enviará vía e-mail, WhatsApp, mensaje de texto o en su defecto se le llamara personalmente para
            conocimiento del solicitante. Para preguntas o reclamos (PQRS) se debe ingresar a www.prevenirexpress.com en
            espacio PQRS o
            al correo clientes@prevenirexpress.com, se debe enviar un e-mail ha solicitado su requerimiento o comunicarse
            a los teléfonos (2) 7290038 o WhatsApp 310 4351937, 3172645522, o al correo admin@prevenirexpress.com. </p>
  
          <p>GRUPO PREVENIR EXPRESS Sas. Es un enlace entre el usuario y el tercero para que apliquen los descuentos del
            servicio y NO se hace responsable de incumplimientos, malos procedimientos pero estará muy atento a las
            sugerencia quejas y reclamos
            para dar un buen servicio.</p>
  
          <small>*Los descuentos los aplica la entidad jurídica o natural de acuerdo a sus condiciones y
            restricciones.</small>
          <small>*Aplican condiciones y restricciones a todos los descuentos.</small><br><br><br>
  
          <div class="text-center ">
            <h1 style="position: relative; " class="text-white ">Tratamiento de datos, privacidad y confidencialidad:</h1>
            <hr class="col-lg-11 " style="background-color: rgb(255, 255, 255);">
          </div>
          <br />
  
          <div id="accordion">
            <div class=" border rounded blurTerminos">
              <div class="card-header " id="headingOne">
                <h5 class="mb-0 text-center text-center">
                  <button class="btn btn-link text-white " data-toggle="collapse" data-target="#collapseOne"
                    aria-expanded="false" aria-controls="collapseOne">
                    1. TRATAMIENTO DE DATOS PERSONALES
                  </button>
                </h5>
              </div>
  
              <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                <div class="card-body blurTerminos text-white">
                  <p>GRUPO PREVENIR EXPRESS sas. (En adelante, “GRUPO PREVENIR o GP"), identificada con NIT 900690163-6,
                    ubicada en la carrera 29 No 17 – 89 segundo piso, Pasto Nariño, con número de teléfonos (2) 7290038
                    Wpp 310 4351937, 3172645522,
                    E-mail admin@prevenirexpress.com, es el titular del tratamiento de los datos personales de las
                    personas naturales usuarias del sitio http://www.prevenirexpress.com. Debido a la actividad comercial
                    que desarrolla GP,
                    y dando cumplimiento al Decreto 1377 de 2013, les informamos a los titulares de los datos personales
                    que GP lleva a cabo los siguientes tipos de Tratamiento sobre sus datos personales. </p>
                  <p>1. Protegiendo: Sus derechos frente al Tratamiento de sus datos personales. GP está comprometido con
                    proteger su privacidad y garantizar sus derechos al habeas data para que Usted pueda conocer,
                    actualizar y rectificar
                    los datos personales que administre GRUPO PREVENIR. Esta Política de Tratamiento de Datos es referente
                    al uso que GRUPO PREVENIR ESPRESS sas. Le dará a los datos personales que reposen en sus registros de
                    conformidad
                    con lo establecido en la ley 1581 de 2012 y el Decreto 1377 de 2013. </p>
                  <p>1.1 FINALIDAD DE LA BASE DE DATOS. La base de datos tiene como finalidades principales informarle
                    sobre los servicios que prestamos en primera persona y/o con terceros, para fines comerciales,
                    administrativos y publicitarios
                    y contacto frente a los titulares de los mismos. </p>
                  <p>1.2 DATOS PERSONALES. La información objeto de tratamiento por parte de GRUPO PREVENIR EXPRESS sas.
                    En adelante en este documento “Datos personales”, es aquella que suministran los Titulares, cuando
                    acceden a sus bienes
                    y/o servicios, o con ocasión de los mismos, tales como: nombre, apellidos, identificación, edad, sexo,
                    teléfono, dirección física y electrónica, país, ciudad y demás datos sensibles pero necesarios que le
                    sean solicitados
                    en el proceso de registro, los cuales en ningún caso serán manipulados ni entregado a terceros sin su
                    autorización como lo dispone la ley. GRUPO PREVENIR ESPRESS sas. Se reserva el derecho de modificar
                    esta política
                    sin previo aviso. Los cambios realizados no afectarán las obligaciones ni seguridad exigida por la
                    normatividad vigente. Los cambios realizados podrán ser anunciados a los usuarios a través de la
                    página web y/o a través
                    de correo electrónico, sin embargo usted debe comprobar nuestro sitio web para ver los cambios
                    recientes. Dado el carácter vinculante de los términos y condiciones, es responsabilidad única del
                    Usuario revisar los términos
                    vigentes en cada momento. La presente política se publica el marzo del 2019. </p>
  
                </div>
              </div>
            </div>
            <br>
            <div class="border rounded blurTerminos">
              <div class="card-header " id="headingTwo">
                <h5 class="mb-0 text-center text-center">
                  <button class="btn btn-link collapsed text-white" data-toggle="collapse" data-target="#collapseTwo"
                    aria-expanded="false" aria-controls="collapseTwo">
                    2. INFORMACIÓN CONTENIDA EN EL PORTAL WEB
                  </button>
                </h5>
              </div>
              <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                <div class="card-body blurTerminos text-white">
                  <p>2.1 Propiedad del contenido del Portal – Copyright El portal www.prevenirexpress.com (en adelante el
                    “Portal”) es de propiedad de GRUPO PREVENIR EXPRESS sas. (En adelante, “GRUPO PREVENIR o GP”). Y su
                    App (En adelante “GRUPO
                    PREVENIR o DESCUENTOS MÉDICOS”) El acceso, participación y uso del Portal está regido por los Términos
                    y Condiciones y las Políticas de Tratamiento de la Información que se incluyen a continuación y se
                    entienden conocidos
                    y aceptados por el usuario del Portal. Se entenderá como usuario aquella persona que acceda, participe
                    o use el Portal (en adelante, el “Usuario”) y/o el Tercero quien es la entidad Jurídica o Natural
                    quien presta sus
                    actividades comerciales a través de estos portales ofertando sus productos o servicios con un
                    descuento y trato Preferencial al Usuario (En adelante el “usuario”), ello implica su adhesión plena e
                    incondicional a estos
                    términos y condiciones y las Políticas de Tratamiento de la Información aquí contempladas. Si en
                    cualquier momento el Usuario y/o el tercero no estuviera de acuerdo total o parcialmente con estos
                    Términos y Condiciones
                    y las Políticas de Tratamiento de la Información, deberá abstenerse inmediatamente de usar este Portal
                    en cualquiera de sus partes o secciones, en caso contrario cualquier uso de los mismos presumirá que
                    el Usuario
                    acepta sin reserva los Términos y Condiciones de uso y las Políticas de Tratamiento de la Información
                    bajo su total responsabilidad. </p>
                  <p>Esta página de Internet y su App son de propiedad del GRUPO PREVENIR EXPRESS sas. Quien es titular de
                    los derechos de autor y de propiedad intelectual del contenido textual, auditivo y gráfico de este
                    Portal y ostenta los
                    derechos de explotación de éstos a través de acuerdos con terceros, quienes conceden el uso de los
                    mismos. La aceptación de los presentes Términos y Condiciones y las Políticas de Tratamiento de la
                    Información no implica
                    en ningún caso la adquisición por parte del Usuario y/o el tercero de los derechos de propiedad
                    intelectual o industrial relacionados con el Portal o con su contenido. GRUPO PREVENIR no concede
                    licencia o autorización
                    de uso alguna sobre el Portal y su contenido, por lo tanto está prohibida su reproducción total o
                    parcial, su traducción, inclusión, transmisión, almacenamiento o acceso a través de medios analógicos,
                    digitales o de
                    cualquier otro sistema o tecnología creada o por crearse, sin autorización previa y escrita de GRUPO
                    PREVENIR. Todo nombre, marca, logo, texto, gráfico, dibujo, fotografía, video, sonido y en general
                    todo contenido
                    de este Portal está protegido por derechos de propiedad intelectual así como por cualquier otro
                    derecho de propiedad conforme lo permitan las leyes aplicables. La titularidad de tales derechos recae
                    en cabeza de GRUPO
                    PREVENIR o de terceros que por mandato legal o autorización expresa han conferido a GRUPO PREVENIR el
                    derecho al uso o explotación de los mismos. </p>
                  <p>Teniendo en cuenta la posibilidad del Usuario de incluir en el Portal: videos, fotos y/ o imágenes
                    y/o textos y/u otro tipo de material de titularidad del Usuario o bien por éste de titularidad de un
                    tercero, se deja expresamente
                    establecido que al incluirlo en el Portal se considerará que el Usuario o el tercero concede a GRUPO
                    PREVENIR en forma permanente, una licencia de uso gratuita, No exclusiva, para la reproducción,
                    adaptación, compilación,
                    almacenamiento y distribución de los contenidos por él suministrados a través de esta página de
                    Internet y/o su App sin derecho a percibir contraprestación, remuneración, retribución o reclamo
                    posterior alguno, cualquiera
                    sea su tipo o índole. GRUPO PREVENIR podrá a su vez hacer cualquier tipo de uso de dicho material, en
                    virtud de la licencia de uso así concedida. En tal sentido el Usuario reconoce que GRUPO PREVENIR
                    podrá realizar
                    la reproducción y sincronización del contenido publicado, en cualquier formato, conocido o por
                    conocerse. Así mismo, la comunicación pública o distribución de dicho contenido se podrá realizar a
                    través de cualquier
                    medio existente o por desarrollarse, incluyendo, pero sin limitarse a: Internet, descargas móviles,
                    descargas desde la App del GRUPO PREVENIR, televisión, abierta o cerrada, Home Video y DVD.
                    Consecuentemente, GRUPO
                    PREVENIR queda en plena libertad de reproducir, distribuir, fijar, comunicar públicamente,
                    transformar, comercializar y realizar cualquier negociación plena y sin ninguna limitación en relación
                    con los derechos patrimoniales
                    sobre cualquier contenido cargado o publicado por algún Usuario del Portal. </p>
                  <p>2.2 El Usuario garantiza que los contenidos suministrados son de su autoría, que no está violando
                    derechos de autor de terceras personas y que mantendrá indemne a GRUPO PREVENIR y a sus sub
                    licenciatarios frente a cualquier
                    reclamación que se presente con ocasión de su uso. Así mismo, el Usuario se obliga a no cargar ningún
                    contenido que contenga material cuya utilización por GRUPO PREVENIR le pueda generar algún tipo de
                    perjuicio a éste
                    último. </p>
                  <p>2.3 El acceso a el Portal por parte del Usuario tiene carácter libre; no obstante algunos de los
                    servicios y contenidos ofrecidos por GRUPO PREVENIR o por terceros a través del Portal tales como:
                    Señal en vivo, videos y
                    audios por demanda para entretenimiento, humor, cultura, actualidad, opinión, entre otros, pueden
                    encontrarse sujetos a pago, así como al registro y autenticación del Usuario y/o terceros en la forma
                    que se determine
                    en los correspondientes Términos y Condiciones. Queda prohibida la contratación de productos o
                    servicios a través del Portal por parte de menores de edad, debiendo éstos obtener debidamente y con
                    anterioridad, el consentimiento
                    de sus padres, tutores o representantes legales, los cuales serán considerados como responsables de
                    los actos que lleven a cabo los menores a su cargo, conforme a la normatividad vigente. </p>
                  <p>2.4 Por el hecho de ingresar al Portal y para garantizar el buen y adecuado uso del mismo, el Usuario
                    reconoce en cabeza de GRUPO PREVENIR: (i) El derecho de actualizar, modificar o eliminar en cualquier
                    tiempo y por cualquier
                    razón sin previo aviso, tanto la información contenida en éste portal Web, así como estos términos y
                    condiciones, pudiendo incluso limitar o no permitir el acceso a dicha información; (ii) El derecho de
                    negar el registro
                    a cualquier persona, en cualquier momento y por cualquier razón. (iii) El derecho de incluir o no en
                    el Portal el material recibido de los usuarios a su criterio. En el caso de incluirlo, podrá mantener
                    en los Portales
                    dicho material por el lapso que considere pertinente o modificarlo. (iv) Remover, sin que sea
                    obligatorio, contenidos que a juicio de GRUPO PREVENIR sean ilegales, ofensivos, difamatorios o que de
                    cualquier otra forma
                    violen éstos Términos y Condiciones. Así mismo, podrán ser retirados los contenidos que violen
                    derechos intelectuales, ya sean de GRUPO PREVENIR o de un tercero, a solicitud de éste. (v) Utilizar
                    la información personal
                    y/o contenidos suministrados por los Usuarios y/o terceos de acuerdo con los Términos y Condiciones
                    del Portal y las Políticas de Tratamiento de la Información. </p>
  
                </div>
              </div>
            </div>
            <br>
            <div class="border rounded blurTerminos">
              <div class="card-header cardTitulo" id="headingThree">
                <h5 class="mb-0 text-center">
                  <button class="btn btn-link collapsed text-white" data-toggle="collapse" data-target="#collapseThree"
                    aria-expanded="false" aria-controls="collapseThree">
                    3. COOKIES
                  </button>
                </h5>
              </div>
              <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                <div class="card-body blurTerminos text-white">
                  <p>El usuario del Sitio Web de GRUPO PREVENIR y su App conoce y acepta que GRUPO PREVENIR podrá utilizar
                    Cookies. Las Cookies son pequeños archivos que se instalan en el disco rígido, con una duración
                    limitada en el tiempo
                    que ayudan a personalizar los servicios (las 'Cookies'). También ofrecemos ciertas funcionalidades que
                    sólo están disponibles mediante el empleo de Cookies. Las Cookies se utilizan con el fin de conocer
                    los intereses,
                    el comportamiento y la demografía de quienes visitan o son visitantes de nuestro Sitio web o la App y
                    de esa forma, comprender mejor sus necesidades e intereses y darles un mejor servicio o proveerle
                    información relacionada.
                    También usaremos la información obtenida por intermedio de las Cookies para analizar las páginas
                    navegadas por el visitante o usuario, las búsquedas realizadas, mejorar nuestras iniciativas
                    comerciales y promocionales,
                    mostrar publicidad o promociones, banners de interés, noticias sobre GRUPO PREVENIR, perfeccionar
                    nuestra oferta de contenidos y artículos, personalizar dichos contenidos, presentación y servicios.
                    Adicionalmente GRUPO
                    PREVENIR utiliza las Cookies para que el usuario no tenga que introducir su clave tan frecuentemente
                    durante una sesión de navegación, también para contabilidad y para corroborar las registraciones, la
                    actividad del
                    usuario y otros conceptos y acuerdos comerciales, siempre teniendo como objetivo de la instalación de
                    las Cookies, el beneficio del usuario que la recibe, y no será usado con otros fines ajenos a GRUPO
                    PREVENIR. Se
                    establece que la instalación, permanencia y existencia de las Cookies en el computador del usuario o
                    del visitante depende de su exclusiva voluntad y puede ser eliminada de su computador cuando así lo
                    desee. Para saber
                    cómo quitar las Cookies del sistema es necesario revisar la sección Ayuda (Help) del navegador.
                    También, se pueden encontrar Cookies u otros sistemas similares instalados por terceros en ciertas
                    páginas de nuestro Sitio
                    Web. GRUPO PREVENIR no controla el uso de Cookies por terceros.</p>
                </div>
              </div>
            </div>
            <br>
            <div class="border rounded blurTerminos">
              <div class="card-header cardTitulo" id="headingFourt">
                <h5 class="mb-0 text-center">
                  <button class="btn btn-link collapsed text-white" data-toggle="collapse" data-target="#collapseFourt"
                    aria-expanded="false" aria-controls="collapseFourt">
                    4. E-MAILS
                  </button>
                </h5>
              </div>
              <div id="collapseFourt" class="collapse" aria-labelledby="headingFourt" data-parent="#accordion">
                <div class="card-body blurTerminos text-white">
                  <p>Nuestra Política, respecto del envío de emails, tiene finalidades principales de nuestro negocio:
                    E-mails con recordatorios de los servicios que ofrecemos (especialmente de las campañas que aún no
                    haya esgrimido o no haya
                    utilizado en un tiempo considerable). En cada uno de los e-mails siempre ofreceremos la posibilidad de
                    dejar de recibir e-mails en el futuro, enviándonos un E-mail. </p>
                </div>
              </div>
            </div>
            <br>
            <div class="border rounded blurTerminos">
              <div class="card-header cardTitulo" id="headingFive">
                <h5 class="mb-0 text-center">
                  <button class="btn btn-link collapsed text-white" data-toggle="collapse" data-target="#collapseFive"
                    aria-expanded="false" aria-controls="collapseFive">
                    5. SEGURIDAD Y ALMACENAMIENTO
                  </button>
                </h5>
              </div>
              <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                <div class="card-body blurTerminos text-white">
                  <p>Empleamos diversas técnicas de seguridad para proteger datos de accesos no autorizados. Sin embargo,
                    es necesario tener en cuenta que la seguridad perfecta no existe en Internet. Por ello, GRUPO PREVENIR
                    no se hace responsable
                    por interceptaciones ilegales o violación de sus sistemas o base de datos por parte de personas no
                    autorizadas. GRUPO PREVENIR, tampoco se hace responsable por la indebida utilización de la información
                    obtenida por
                    esos medios. </p>
                </div>
              </div>
            </div>
            <br>
            <div class="border rounded blurTerminos">
              <div class="card-header cardTitulo" id="headingSix">
                <h5 class="mb-0 text-center">
                  <button class="btn btn-link collapsed text-white" data-toggle="collapse" data-target="#collapseSix"
                    aria-expanded="false" aria-controls="collapseSix">
                    6. TRANSFERENCIAS COMERCIALES O CAMBIOS CORPORATIVOS
                  </button>
                </h5>
              </div>
              <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordion">
                <div class="card-body blurTerminos text-white">
                  <p>GRUPO PREVENIR se reserva el derecho a divulgar, transferir o a licenciar parte o toda la información
                    relacionada con el Sitio, incluyendo Información Personal: a un propietario posterior, co-propietario
                    u operador de uno
                    o más de los Sitios o cualquier porción u operación relacionada con una parte de uno o más Sitios, o
                    en conexión con una integración corporativa, consolidación o reestructuración, la venta de
                    sustancialmente todas nuestras
                    acciones y/o activos, u otros cambios corporativos, incluyendo, sin limitación, durante el curso de
                    cualquier proceso de debida diligencia. </p>
                </div>
              </div>
            </div>
            <br>
            <div class="border rounded blurTerminos">
              <div class="card-header cardTitulo" id="headingSeven">
                <h5 class="mb-0 text-center">
                  <button class="btn btn-link collapsed text-white" data-toggle="collapse" data-target="#collapseSeven"
                    aria-expanded="false" aria-controls="collapseSeven">
                    7. REGISTRO Y PARTICIPACIÓN DEL USUARIO.
                  </button>
                </h5>
              </div>
              <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordion">
                <div class="card-body blurTerminos text-white">
                  <p>Para realizar determinadas actividades dentro del Portal y/o hacer uso de algunos servicios de los
                    mismos, el Usuario y/o el tercero deberán registrarse directamente en el Portal o App que GRUPO
                    PREVENIR ha desarrollado
                    y almacenará los datos personales del Usuario en su base de datos de acuerdo con lo establecido en las
                    Políticas de Tratamiento de la Información. Por el hecho de ingresar al Portal y para garantizar el
                    buen y adecuado
                    uso del mismo, el Usuario deberá cumplir con lo siguiente: (i) Observar el Código de Ética, los
                    Términos y Condiciones y la las Políticas de Tratamiento de la Información, así como cualquier otra
                    condición establecida
                    en el Portal; (ii) Ser responsable por cualquier actividad que se lleve a cabo bajo su registro;
                    (iv)Ser responsable de la seguridad de su contraseña en el uso de la App ; (v) No abusar, acosar,
                    amenazar o intimidar
                    a otros usuarios de los Portales asociados y/o relacionados con el portal ya sea a través de los
                    chats, foros, blogs o cualquier otro espacio de participación; (vi) No usar ésta página como medio
                    para desarrollar actividades
                    ilegales o no autorizadas tanto en Colombia, como en cualquier otro país; (vii) Ser el único
                    responsable por su conducta y por el contenido de textos, gráficos, fotos, videos o cualquier otro
                    tipo de información de
                    la cual haga uso o incluya en el Portal; (viii) Utilizar el Portal única y exclusivamente para uso
                    personal. Cualquier uso para beneficio corporativo, colectivo o comercial está prohibido; (ix)
                    Abstenerse de enviar
                    correo electrónico no deseado (SPAM) a otros Usuarios de esta web, así como también le está prohibido
                    transmitir virus o cualquier código de naturaleza destructiva.; (xi) Abstenerse de intentar acceder y
                    en su caso,
                    utilizar las cuentas de de otros Usuarios y modificar o manipular mensajes ; (xii) No hacerse pasar
                    por otra persona o entidad, realizar manifestaciones falsas o proporcionar información falsa acerca de
                    él, su edad
                    o su relación con cualquier persona o entidad; (xiii) No cargar, publicar, transmitir, compartir,
                    almacenar o facilitar contenido que constituya, incite o proporcione instrucciones para cometer
                    delitos, que infrinja
                    los derechos de un tercero, dé lugar a responsabilidades o vulnere cualquier ley de ámbito local,
                    estatal, nacional o internacional; (xiiii) Abstenerse de cargar, publicar, transmitir, compartir,
                    almacenar o facilitar
                    contenido que, a juicio exclusivo de GRUPO PREVENIR, sea cuestionable o que restrinja o impida el uso
                    adecuado del Portal por otras personas, o que pueda exponer a GRUPO PREVENIR o a sus Usuarios a daños
                    o responsabilidades
                    de cualquier tipo. Mientras en el Portal estén prohibidas estas conductas, GRUPO PREVENIR no será
                    responsable por su cumplimiento y el Usuario será responsable por todo concepto. </p>
  
                  <p class="subtitulo">7.1 EL PORTAL, CONTIENE LINKS O VÍNCULOS A WEB SITES DE TERCEROS QUE REMITEN A
                    OTRAS PÁGINAS EN LA WEB.</p>
  
                  <p>Los links a otras páginas de Internet, distintas de aquellas de las cuales GRUPO PREVENIR es
                    propietaria, son ofrecidos como un servicio a los usuarios. GRUPO PREVENIR no estuvo involucrada en su
                    producción y por lo tanto
                    no es responsable por su contenido. Si el Usuario decide acceder a través de los links o vínculos a
                    los web sites de terceros, lo hace bajo su propio riesgo. Al acezar a los enlaces o hipervínculos que
                    remiten a sitios
                    y portales de terceros en Internet el Usuario abandona el Portal y en consecuencia se somete a las
                    normas de uso y de privacidad de los sitios y portales que entre a visitar, GRUPO PREVENIR no
                    comprueba ni verifica
                    la exactitud, adecuación o exhaustividad de tales sitios de terceros, aplicaciones, software o
                    contenido de terceros, y no se hace responsable de los sitios de terceros a los que se acceda a través
                    de nuestro Portal
                    ni de las aplicaciones, software o contenido de terceros que se publiquen en los Portales. Por lo
                    tanto GRUPO PREVENIR no es responsable por la disponibilidad y contenido de dichos sitios y el Usuario
                    deberá dirigirse
                    directamente al administrador de dicho sitio y usar los mismos de acuerdo con los términos de uso
                    respectivos que regule cada uno de ellos. La inclusión del enlace o hipervínculo en el Portal no
                    implica que exista relación
                    alguna entre GRUPO PREVENIR y el operador o propietario de los mismos.</p>
  
                  <p class="subtitulo">7.2 SERVICIOS OFRECIDOS POR TERCEROS.</p>
  
                  <p>7.3 El registro al Portal podrá darse por terminado por el Usuario en cualquier momento enviando un
                    e-mail a: admin@prevenirexpress.com o eliminándolo directamente de su sitio ingresando a sus datos y
                    en la parte de abajo
                    dice eliminar registro “el usuario puede eliminar e inscribirse cuantas veces lo vea necesario, así
                    como eliminar y descargar la aplicación las veces que el desee”. </p>
                  <p>7.4 El Usuario y/o el tercero deberá cumplir los Términos y Condiciones del Portal, así como toda
                    condición adicional que se establezca en el Portal. </p>
                  <p>7.5 GRUPO PREVENIR no garantiza la disponibilidad y continuidad del funcionamiento del Portal. Cuando
                    ello sea razonablemente posible, GRUPO PREVENIR no advertirá previamente las interrupciones en el
                    funcionamiento del
                    Portal. GRUPO PREVENIR tampoco garantiza la utilidad del Portal para la realización de alguna
                    actividad en particular, ni su infalibilidad y, en particular, aunque no de modo exclusivo, que los
                    Usuarios y/o terceros
                    puedan efectivamente utilizar el Portal, acceder a las distintas páginas web o secciones que forman el
                    Portal y su App. </p>
                  <p>7.5 GRUPO PREVENIR excluye cualquier responsabilidad por los daños y perjuicios de toda naturaleza
                    que puedan deberse a la falta de disponibilidad o de continuidad del funcionamiento del Portal, a la
                    defraudación de la
                    utilidad que los Usuarios y/o terceros hubieren podido atribuir al Portal y a los servicios, a la
                    falibilidad del Portal, y en particular, aunque no de modo exclusivo, a las fallas en el acceso a las
                    distintas páginas
                    web o secciones del Portal. </p>
                  <p>7.6 GRUPO PREVENIR no controla ni garantiza, y por lo tanto no se hace responsable por, la ausencia
                    de virus ni de otros elementos en los contenidos del Portal que puedan producir alteraciones en el
                    sistema informático
                    (software y hardware) del Usuario o en los documentos electrónicos y ficheros almacenados en el
                    sistema informático del Usuario.</p>
                  <p>7.7 Es interés de GRUPO PREVENIR ser una fuente de información con contenido de entretenimiento, de
                    actualidad y de interés para los Usuarios y/o terceros. No obstante, no puede garantizar que dicho
                    contenido esté exento
                    de errores o imprecisiones, en cuyo caso podrán solicitarse las aclaraciones o correcciones que sean
                    del caso GRUPO PREVENIR tampoco puede garantizar que el contenido del Portal sea suficiente y/o útil
                    para el Usuario
                    y/o el tercero. </p>
                  <p>7.8 GRUPO PREVENIR no garantiza y por lo tanto no es responsable de, la licitud, fiabilidad,
                    exactitud, exhaustividad, actualidad y utilidad de las columnas de opinión, y/o las opiniones o
                    contenidos de los Usuarios incluidos
                    en los Portales. </p>
                  <p>7.9 Limitación de la responsabilidad; En ningún caso será GRUPO PREVENIR, sus directivos, empleados o
                    agentes, responsables frente al Usuario u otros terceros por cualquier tipo de daño, incluyendo
                    pérdidas de beneficios
                    o datos derivados de la utilización del Portal o sus servicios, las aplicaciones de la plataforma, el
                    contenido del Portal u otros materiales a los que se haya accedido a través del Portal o descargados
                    de éste, aun
                    cuando GRUPO PREVENIR sea consciente o haya sido advertido de la posibilidad de dichos daños. </p>
                  <p>7.10 Estos Términos y Condiciones han sido dispuestos de conformidad con las leyes colombianas.
                    Cualquier acción o reclamación deberá hacerse ante los Tribunales en Colombia. </p>
                </div>
              </div>
            </div>
            <br>
            <div class="border rounded blurTerminos">
              <div class="card-header cardTitulo" id="headingEight">
                <h5 class="mb-0 text-center">
                  <button class="btn btn-link collapsed text-white" data-toggle="collapse" data-target="#collapseEight"
                    aria-expanded="false" aria-controls="collapseEight">
                    8. FINALIDADES Y USO QUE HACEMOS DE LA INFORMACIÓN.
                  </button>
                </h5>
              </div>
              <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordion">
                <div class="card-body blurTerminos text-white">
                  <p>Para hacer más efectivas y seguras las transacciones que se lleven a cabo en el descuento. Para la
                    administración del Sitio Web en el que el usuario entra para explorar las ofertas y promociones
                    proporcionadas por cada
                    proveedor. Para cumplir a cabalidad con los servicios celebrados con los clientes y usuarios, de
                    acuerdo con sus finalidades para brindar las ofertas, promociones, servicios o productos de GRUPO
                    PREVENIR o de terceros.
                    Para complementar la información y en general adelantar las actividades necesarias para gestionar las
                    solicitudes, quejas y reclamos presentados por los usuarios o proveedor meédico de GRUPO PREVENIR, por
                    terceros y
                    direccionarlas a las áreas responsables de emitir las respuestas correspondientes. Enviar información
                    y ofertas comerciales de productos de GRUPO PREVENIR, así como realizar actividades de mercadeo. Esto,
                    como finalidad
                    principal en el tratamiento de datos personales por parte de GRUPO PREVENIR y objeto social,
                    consistentes en la difusión efectiva de las ofertas que realiza, dado su carácter temporal. La
                    personalización de clientes
                    con el fin de hacerlos partícipes de los diferentes programas de beneficios e informaciones
                    promocionales. Elaborar estudios de mercado, estadísticas, encuestas, análisis de tendencias del
                    mercado, encuestas de satisfacción
                    sobre los servicios prestados por GRUPO PREVENIR. Para la transmisión de datos personales a terceros
                    con los cuales se hayan celebrado contratos con este objeto, para fines comerciales, administrativos
                    y/o operativos.
                    Gestionar toda la información necesaria para el cumplimiento de las obligaciones tributarias y de
                    registros comerciales, corporativos y contables de GRUPO PREVENIR. Para identificar a los usuarios
                    cuando ingresen al
                    Sitio Web. Para ofrecer a los clientes servicios y funcionalidades que se adecuan a los gustos e
                    intereses personalizados. Para proceder a la facturación y cobro de los servicios de compra directa.
                    Para enviar información
                    o mensajes sobre los nuevos productos y/o servicios, mostrar la publicidad o promoción del momento,
                    banners, noticias sobre GRUPO PREVENIR y toda otra información que creamos conveniente. Compartir los
                    datos con empresas
                    de servicios o empresas de “Outsourcing” que contribuyan a mejorar o a facilitar las operaciones a
                    través de GRUPO PREVENIR, dentro de las que se incluyen, medios de pago, seguros o intermediarios de
                    la gestión de pagos.
                    GRUPO PREVENIR velará porque las políticas de los terceros tengan estándares similares a los de la
                    presente Política, mediante la firma de acuerdos, convenios y/o contratos. Para suministrar los datos
                    a las entidades
                    que intervengan en la resolución de conflictos y que tengan competencia para ello. </p>
                  <p>8.1 Información de identificación personal (IIP) como nombres y apellidos, teléfono, dirección y otro
                    tipo de información que le permita a GRUPO PREVENIR identificar a los usuarios. También se solicitará
                    información personal
                    para comprar o separar alguno de los servicios que el proveedor médico ofrezca con sus respectivo
                    descuentos, a saber: (i) nombres y apellidos; (ii) dirección; (iii) dirección de correo electrónico;
                    (iv) fecha de nacimiento;
                    (v) número telefónico; y (vi) datos relacionados con el medio de pago. </p>
                  <p>8.2 Dirección IP (Internet Protocolo) con el fin de diagnosticar problemas o inconvenientes con
                    nuestro servidor, así como para administrar su Sitio Web. Una dirección de IP es un número que se le
                    asigna a su computadora
                    cuando usa internet. Su dirección de IP también es utilizada para ayudar a identificarle dentro de una
                    sesión particular y para recolectar información demográfica general. Correo electrónico para la
                    utilización del
                    Sitio Web, la vinculación a GRUPO PREVENIR. </p>
                </div>
              </div>
            </div>
            <br>
            <div class="border rounded blurTerminos">
              <div class="card-header cardTitulo" id="headingNine">
                <h5 class="mb-0 text-center">
                  <button class="btn btn-link collapsed text-white" data-toggle="collapse" data-target="#collapseNine"
                    aria-expanded="false" aria-controls="collapseNine">
                    9. PROMOCIONES, CONCURSOS Y EVENTOS
                  </button>
                </h5>
              </div>
              <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordion">
                <div class="card-body blurTerminos text-white">
                  <p>Las promociones, concursos, sorteos y eventos que se divulguen en el Portal estarán sujetas a las
                    reglas y condiciones que en cada oportunidad se establezca por parte de GRUPO PREVENIR, siendo
                    necesario como requisito mínimo
                    para acceder a tales oportunidades o beneficios comerciales, que el Usuario se encuentre debidamente
                    registrado como usuario de la App o de los Portales asociados. GRUPO PREVENIR no se responsabiliza por
                    cualquier tipo
                    de daño -incluyendo moral, físico, material, ni de cualquier otra índole- que pudiera invocarse como
                    relacionado con la recepción por parte del Usuario registrado de cualquier tipo de obsequios y/o
                    regalos remitidos
                    por GRUPO PREVENIR o portales asociados. Así mismo, GRUPO PREVENIR no será responsable por las
                    consecuencias que pudieren causar el ingreso al Portal y/o la asistencia en cualquier evento y/o
                    reunión divulgado por medio
                    de los Portales Asociados o de terceros. El Usuario reconoce que GRUPO PREVENIR No asume
                    responsabilidad alguna que corresponda a un anunciante y/o el proveedor de los servicios que se
                    ofrezcan en el Portal y/o en la
                    App ni en los Portales asociados, siendo entendido que GRUPO PREVENIR no se responsabiliza por la
                    calidad ni la entrega de los productos o prestación de servicios que se publican en este sitio. Por
                    tal motivo no será
                    responsable por cualquier problema, queja o reclamo de los usuarios por cuestiones atinentes a dichos
                    premios, productos y/o servicios. Cada promoción, concurso o evento que se promueva o realice a través
                    del Portal,
                    estará sujeto a las reglas que para el mismo se indiquen, por lo que la participación en los mismos
                    deberá atenerse a lo que en cada sitio web y caso se señale. </p>
                </div>
              </div>
            </div>
            <br>
            <div class="border rounded blurTerminos">
              <div class="card-header cardTitulo" id="headingTen">
                <h5 class="mb-0 text-center">
                  <button class="btn btn-link collapsed text-white" data-toggle="collapse" data-target="#collapseTen"
                    aria-expanded="false" aria-controls="collapseTen">
                    10. FOROS, BLOGS, CHATS, COMENTARIOS Y OTROS ESPACIOS DE PARTICIPACIÓN
                  </button>
                </h5>
              </div>
              <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordion">
                <div class="card-body blurTerminos text-white">
                  <p>El Usuario reconoce que su participación en cualquier foro, chat, comentario, blog, red social y/o
                    cualquier otro espacio de participación del Portal, será bajo su exclusiva responsabilidad, por lo
                    cual deberá ser mayor
                    de edad, y que de igual forma, las opiniones y/o acciones y/o comportamiento de otros Usuarios en
                    tales espacios son responsabilidad exclusiva de quienes las emiten o realizan, por lo cual GRUPO
                    PREVENIR no se hace
                    responsable ni garantiza la calidad o idoneidad de tales conductas u opiniones, ni por las
                    consecuencias que ellas pudieren acarrear a favor y/ o en contra de otros Usuarios o de terceros.
                    Particularmente los usuarios
                    deberán tener en cuenta las recomendaciones que se incluyan en el Portal encaminadas a propiciar una
                    adecuada convivencia y participación en tales espacios de participación. El diseño, manejo, finalidad
                    y características
                    de los diferentes espacios de participación del Portal es discrecional de GRUPO PREVENIR, quién podrá
                    en cualquier momento cambiarlos y/o eliminarlos, y/o determinar la cantidad de participantes admitidos
                    en cada uno
                    de ellos.</p>
                </div>
              </div>
            </div>
            <br>
            <div class="border rounded blurTerminos">
              <div class="card-header cardTitulo" id="headingEleven">
                <h5 class="mb-0 text-center">
                  <button class="btn btn-link collapsed text-white" data-toggle="collapse" data-target="#collapseEleven"
                    aria-expanded="false" aria-controls="collapseEleven">
                    11. GUÍA DE PRINCIPIOS Y RECOMENDACIONES PARA UNA SANA PARTICIPACIÓN EN <br> LAS ACTIVIDADES Y
                    SERVICIOS DE LOS PORTALES.
                  </button>
                </h5>
              </div>
              <div id="collapseEleven" class="collapse" aria-labelledby="headingEleven" data-parent="#accordion">
                <div class="card-body blurTerminos text-white">
                  <p>La presente guía debe ser leída en forma detenida y periódica por parte de los Usuarios interesados
                    en participar en las actividades y servicios del Portal. La participación en redes sociales, foros,
                    chats, comentarios
                    y otros espacios similares de participación dentro del Portal (en adelante los “Espacios”) implican la
                    aceptación y conocimiento por parte del Usuario de los Términos y Condiciones del Portal, así como el
                    compromiso
                    irrevocable de cada Usuario de respetar dichos Términos y Condiciones, siendo entendido y aceptado que
                    eximen a GRUPO PREVENIR y mantendrán indemne a GRUPO PREVENIR de cualquier responsabilidad que se
                    derive del incumplimiento
                    a tal compromiso, lo cual incluye daños y perjuicios causados a otros Usuarios y/o cualquier tercero
                    afectado. Si un Usuario no está conforme o de acuerdo con los presentes Términos y Condiciones del
                    Portal, GRUPO PREVENIR
                    le sugiere no participar en él y/o en los Espacios. El Usuario entiende que a través del Portal se
                    invita y promueve una activa y libre participación, comunicación y expresión por parte de los
                    Usuarios, hasta donde
                    la Constitución y la ley lo permiten. De igual forma el Usuario entiende y acepta que es de todo
                    interés de GRUPO PREVENIR y de la comunidad en general, que el Portal sean un medio amigable, pacífico
                    y sano de convivencia
                    y participación, por lo que tanto GRUPO PREVENIR como la comunidad de Usuarios de los espacios esperan
                    de cada Usuario el comportamiento y conducta que permita lograr tal propósito, a lo cual se compromete
                    cada Usuario
                    con el solo acceso al Portal y/o la App. Cada Usuario acepta y faculta expresa e irrevocablemente a
                    GRUPO PREVENIR para revisar los comentarios u opiniones vertidos en los Espacios y/o suprimir los que
                    no se adecuen
                    al Código de Ética plasmado en estos Términos y Condiciones del Portal, así como a interrumpir la
                    comunicación en caso que lo considere conveniente por tales motivos. De igual forma GRUPO PREVENIR se
                    reserva el derecho
                    de ejercer tal facultad cuando así lo estime conveniente, a su discreción, sin que por tal razón sea
                    factible imputar responsabilidad alguna a GRUPO PREVENIR por el no ejercicio de la facultad y/o por la
                    existencia,
                    ingreso, participación de Usuarios no deseables y/o de comentarios u opiniones que no atienden estas
                    recomendaciones. Teniendo en cuenta que los comentarios y opiniones vertidas en las redes sociales,
                    foros, comentarios
                    y Blogs no provendrán de GRUPO PREVENIR sino de Usuarios y terceros absolutamente ajenos, GRUPO
                    PREVENIR no se responsabiliza por los mismos, así como tampoco presta conformidad ni discrepa con
                    ellos, siendo entendido
                    que emanan exclusivamente de su autor, y quedan bajo su completa responsabilidad. GRUPO PREVENIR
                    entiende que cada uno de los comentarios, mensajes, opiniones, información o similares que se viertan
                    en los Espacios
                    son de autoría exclusiva de quienes los ingresen. Asimismo, queda absolutamente prohibido ingresar
                    comentarios, mensajes, opiniones, información, o similares, de contenido difamatorio, obsceno,
                    abusivo, racista, discriminatorio
                    a raza, color, religión, posición geográfica etc. Contrario a la buena moral y las buenas costumbres,
                    discriminatorio, ofensivo, obsceno, intimidatorio, calumnioso, inapropiado, ilegal, violatorio de
                    derechos de terceros
                    de cualquier índole, incluidos los derechos de los menores de edad, que cause daños y/o perjuicios, o
                    impida o limite el derecho propio o ajeno a usar los Espacios y demás capítulos del sitio, constituya
                    un delito o
                    apología a un delito y/o incite a la violencia y/o a la comisión de delitos. Tampoco está permitido
                    publicitar productos y/o servicios de cualquier tipo dentro de los Espacios, ni utilizar o enviar
                    virus, o desviarse
                    de los temas propuestos en los foros. El supuesto de que este tipo de comentarios, mensajes,
                    opiniones, información, o similares, ingrese en los Espacios, los Usuarios, aceptan en forma expresa e
                    incondicionada que
                    GRUPO PREVENIR , sus empleados, proveedores, o anunciantes, NO serán responsables en modo alguno por
                    las consecuencias de cualquier tipo y alcance que los mismos pudieran generar, frente a cualquier
                    tercero, ya sea
                    en virtud de su inclusión dentro de los Espacios o por cualquier causa relacionada directa o
                    indirectamente con el uso de los mismos. Asimismo, GRUPO PREVENIR, sus empleados, proveedores o
                    anunciantes, NO serán responsables
                    de modo alguno en el supuesto que los comentarios, información, mensajes, opiniones, o similares, se
                    vean afectados, eliminados, alterados, o modificados de alguna manera. Los Usuarios se abstendrán de
                    iniciar cualquier
                    acción o reclamación contra GRUPO PREVENIR relacionada con, o derivada de, la información, contenido,
                    opinión o comentario proveniente de otro Usuario y/o de un tercero ajeno a GRUPO PREVENIR, siendo
                    aceptado por los
                    Usuarios que tales acciones o reclamaciones solo podrán ser iniciadas contra su directo responsable,
                    para lo cual deberán promover las acciones legales pertinentes, bajo los procedimientos legalmente
                    establecidos para
                    el efecto. GP podrá abstenerse de suministrar la información disponible sobre el presunto infractor
                    cuando a su juicio tal información esté protegida por la confidencialidad ofrecida a los Usuarios, en
                    cuyo caso, el
                    suministro estará condicionado a la solicitud de autoridad competente. GRUPO PREVENIR, a su sólo
                    juicio, se reserva el derecho de excluir de los Espacios, a aquellos Usuarios que no se atengan a las
                    presentes reglas
                    o que no respeten los principios básicos de sana convivencia y el respeto. Así como también de
                    interrumpir y/o eliminar y/o excluir, total o parcialmente, en todos los casos, todo aquel mensaje,
                    opinión, información
                    o similares que no se adecuen o resulten violatorios de las reglas y/o principios antes indicados.
                    GRUPO PREVENIR asume que cada usuario se obliga a cumplir y respetar las presentes reglas y que asume
                    todo tipo de consecuencias
                    que su indebido uso pudiere ocasionar. Ello tanto frente a GP como frente a cualquier tercero. Los
                    Usuarios deberán denunciar cualquier violación a los Términos y Condiciones del Portal por parte de
                    otros Usuarios,
                    de la que tenga conocimiento, para lo cual remitirá un e–mail a admin@prevenirexpress.com para que
                    GRUPO PREVENIR tome las medidas que estén a su alcance respecto del Portal.</p>
                </div>
              </div>
            </div>
            <br>
            <div class="border rounded blurTerminos">
              <div class="card-header cardTitulo" id="headingTwelve">
                <h5 class="mb-0 text-center">
                  <button class="btn btn-link collapsed text-white" data-toggle="collapse" data-target="#collapseTwelve"
                    aria-expanded="false" aria-controls="collapseTwelve">
                    12. CÓDIGO DE ÉTICA
                  </button>
                </h5>
              </div>
              <div id="collapseTwelve" class="collapse" aria-labelledby="headingTwelve" data-parent="#accordion">
                <div class="card-body blurTerminos text-white">
                  <p>Guía de comportamiento en espacios de participación en el Portal. Al ingresar en los espacios de
                    participación de GP en su sitio web oficial, el Usuario se compromete a respetar y cumplir las
                    siguientes normas: (i) Interactuar
                    en forma respetuosa y cortés. Las críticas y opiniones son bienvenidas siempre y cuando se hagan con
                    total respeto; (ii) No publicar contenido que atente contra la integridad de otras personas, incluidos
                    los menores
                    de edad; (iii) No abusar, acosar, amenazar o intimidar a otros usuarios ya sea a través de los
                    comentarios en los Espacios, chats, foros, blogs o cualquier otro espacio de participación; (iv) Usar
                    lenguaje adecuado
                    y cortés y de ninguna manera usar lenguaje ofensivo; (v) Ser responsable por cualquier actividad que
                    se lleve a cabo bajo su registro; (vi) No usar los Espacios de participación de las páginas de redes
                    sociales oficiales
                    de GP como medio para desarrollar actividades ilegales o no autorizadas tanto en Colombia, como en
                    cualquier otro país; (vii) No usar los espacios de GRUPO PREVENIR como medio para desarrollar
                    actividades comerciales
                    SIN AUTORIZACION; (viii) No usar los espacios de participación de las redes sociales de GP para
                    difundir spam (se llama spam o mensaje basura a los mensajes no solicitados, no deseados o de
                    remitente no conocido, habitualmente
                    de tipo publicitario, generalmente enviados en grandes cantidades que perjudican de alguna o varias
                    maneras al receptor); (ix) No usar los espacios de participación de las redes sociales de GRUPO
                    PREVENIR para crear,
                    almacenar, o transmitir material que infrinja los derechos de autor, la marca o demás derechos de
                    propiedad intelectual; (x) No usar la información contenida en las redes sociales de GRUPO PREVENIR
                    para revender, modificar
                    o redistribuir cualquiera de los servicios proporcionados por GRUPO PREVENIR, nuestros contratistas o
                    licenciatarios, sin nuestro consentimiento previo y por escrito; (xi) No publicar o difundir
                    contenidos o propaganda
                    de carácter racista, xenófoba, pornográfica, de apología del terrorismo o atentatoria contra los
                    derechos humanos; </p>
                </div>
              </div>
            </div>
            <br>
            <div class="border rounded blurTerminos">
              <div class="card-header cardTitulo" id="headingThirdteen">
                <h5 class="mb-0 text-center">
                  <button class="btn btn-link collapsed text-white" data-toggle="collapse"
                    data-target="#collapseThirdteen" aria-expanded="false" aria-controls="collapseThirdteen">
                    13. TERRITORIO
                  </button>
                </h5>
              </div>
              <div id="collapseThirdteen" class="collapse" aria-labelledby="headingThirdteen" data-parent="#accordion">
                <div class="card-body blurTerminos text-white">
                  <p>La actividad será válida en todo el territorio nacional (Colombia). </p>
                </div>
              </div>
            </div>
            <br>
            <div class="border rounded blurTerminos">
              <div class="card-header cardTitulo" id="headingFourteen">
                <h5 class="mb-0 text-center">
                  <button class="btn btn-link collapsed text-white" data-toggle="collapse" data-target="#collapseFourteen"
                    aria-expanded="false" aria-controls="collapseFourteen">
                    14. DESCARGA APLICACIÓN MÓVIL
                  </button>
                </h5>
              </div>
              <div id="collapseFourteen" class="collapse" aria-labelledby="headingFourteen" data-parent="#accordion">
                <div class="card-body blurTerminos text-white">
                  <p>Podrán participar todos los usuarios con dispositivos móviles y su descarga será gratuita.</p>
                  <p>14.1. Descargar la aplicación móvil “PREVENIR EXPRESS” se puede probar gratis registrarse con
                    Facebook o ingresar Nombre, Correo electrónico y clave para poder acceder a la App. </p>
                </div>
              </div>
            </div>
            <br>
            <div class="border rounded blurTerminos">
              <div class="card-header cardTitulo" id="headingFiveteen">
                <h5 class="mb-0 text-center">
                  <button class="btn btn-link collapsed text-white" data-toggle="collapse" data-target="#collapseFiveteen"
                    aria-expanded="false" aria-controls="collapseFiveteen">
                    15. PUBLICIDAD: EL GRUPO PREVENIR EXPRESS SAS.
  
                  </button>
                </h5>
              </div>
              <div id="collapseFiveteen" class="collapse" aria-labelledby="headingFiveteen" data-parent="#accordion">
                <div class="card-body blurTerminos text-white">
                  <p>Será responsable de la publicidad de la Actividad, la cual puede realizarse en toda la variedad de
                    formatos publicitarios existentes y por existir así como en todos los medios que determine el
                    Organizador a su propia discreción
                    en contracción directa y por escrito de lo contrario no será responsable de otras publicaciones. </p>
                </div>
              </div>
            </div>
            <br>
            <div class="border rounded blurTerminos">
              <div class="card-header cardTitulo" id="headingSixteen">
                <h5 class="mb-0 text-center">
                  <button class="btn btn-link collapsed text-white" data-toggle="collapse" data-target="#collapseSixteen"
                    aria-expanded="false" aria-controls="collapseSixteen">
                    16. SERVICIO AL CLIENTE
  
                  </button>
                </h5>
              </div>
              <div id="collapseSixteen" class="collapse" aria-labelledby="headingSixteen" data-parent="#accordion">
                <div class="card-body blurTerminos text-white">
                  <p>Para atender cualquier duda, consulta, queja, pregunta, reclamación o solicitud de cualquier tipo de
                    información relacionada con sus datos personales, particularmente, para ejercer sus derechos a
                    conocer, actualizar, rectificar
                    y suprimir el dato o revocar la autorización otorgada, GRUPO PREVENIR ha dispuesto a
                    admin@prevenirexpress.com, como encargado.</p>
                  <p>16.1. El deber de actualización de datos por parte del titular El titular de los datos tiene la
                    obligación de mantener actualizados los datos personales que haya suministrado a GRUPO PREVENIR. Hasta
                    el máximo permitido
                    por la Ley, GRUPO PREVENIR no se hace responsable por la falta de actualización de datos personales
                    por parte del titular de los mismos. 16.2. CONSULTAS: GRUPO PREVENIR dispone de mecanismos para que el
                    titular, sus
                    causahabientes, sus representantes y/o apoderados, aquellos que por estipulación a favor de otro o
                    para otro estén legitimados, los representantes de menores de edad titulares, formulen CONSULTAS
                    respecto de cuáles
                    son los datos personales del titular que reposan en las bases de datos de GRUPO PREVENIR. Estos
                    mecanismos podrán ser físicos como a través del correo electrónico de pqrs@prevenirexpress.com o del
                    contáctenos de nuestra
                    web encargados de recepcionar las peticiones, quejas y reclamos. Cualquiera que sea el medio, GRUPO
                    PREVENIR guardará prueba de la consulta y su respuesta. 16.3 Antes de proceder, el responsable de
                    atender la consulta
                    verificará: La identidad del titular del dato personal o su representante. Para ello, exigirá la
                    cédula de ciudadanía o documento de identificación original del titular y los poderes especiales o
                    generales según sea
                    el caso. 16.4 La autorización o contrato con terceros que dieron origen al Tratamiento del dato
                    personal del titular, por parte de GRUPO PREVENIR. Si el solicitante tuviere capacidad para formular
                    la consulta, el responsable
                    de atenderla recopilará toda la información sobre el titular que esté contenida en el registro
                    individual de esa persona o que esté vinculada con la identificación del titular dentro de las bases
                    de datos de GRUPO PREVENIR.
                    16.5 El responsable de atender la consulta dará respuesta al solicitante siempre y cuando éste último
                    tuviere derecho a ello por ser el titular del dato personal, su causahabiente, su representante y/o
                    apoderado, aquellos
                    que por estipulación a favor de otro o para otro estén legitimados, o el responsable legal en el caso
                    de menores de edad. Esta respuesta será enviada dentro de los diez (10) días hábiles contados a partir
                    de la fecha
                    en la que la solicitud fue recibida por GP. Esta respuesta será obligatoria aún en los casos en que se
                    considere que el solicitante no tiene capacidad para realizar la consulta, en cuyo caso así se le
                    informará al solicitante
                    y se dará opción de que demuestre el interés y capacidad aportando documentación adicional. En caso de
                    que la solicitud no pueda ser atendida a los diez (15) hábiles, se contactará al solicitante para
                    comunicarle los
                    motivos por los cuales el estado de su solicitud se encuentra en trámite y señalando la fecha en la
                    que se atenderá la consulta, la cual en ningún caso podrá superar los cinco (5) días hábiles
                    siguientes al vencimiento
                    del primer término. Para ello se utilizará el mismo medio o uno similar a aquel mediante el cual fue
                    presentada la consulta. La respuesta definitiva a todas las solicitudes no puede tardar más de quince
                    (15) días hábiles
                    desde la fecha en la que la solicitud inicial fue recibida por GP. </p>
                </div>
              </div>
            </div>
            <br>
            <div class="border rounded blurTerminos">
              <div class="card-header cardTitulo" id="headingSeventeen">
                <h5 class="mb-0 text-center">
                  <button class="btn btn-link collapsed text-white" data-toggle="collapse"
                    data-target="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">
                    17. RECLAMOS: GRUPO PREVENIR
  
                  </button>
                </h5>
              </div>
              <div id="collapseSeventeen" class="collapse" aria-labelledby="headingSeventeen" data-parent="#accordion">
                <div class="card-body blurTerminos text-white">
                  <p>Dispondrá de mecanismos para que el titular, sus causahabientes, sus representantes y/o apoderados,
                    aquellos que por estipulación a favor de otro o para otro estén legitimados, o los representantes de
                    menores de edad titulares,
                    puedan formular RECLAMOS respecto de (i) datos personales Tratados por GP que deben ser objeto de
                    corrección, actualización o supresión, o (ii) el presunto incumplimiento de los deberes legales de
                    GRUPO PREVENIR. Estos
                    mecanismos podrán ser físicos como electrónicos, tales como como correo electrónico. Cualquiera que
                    sea el medio, GRUPO PREVENIR GP deberá guardar prueba de la consulta y su respuesta.</p>
                  <p>17.1 El RECLAMO deberá ser presentado por el titular, sus causahabientes; las personas facultadas
                    para representar a niños, niñas o adolecentes, cuando estos sean titulares; sus representantes y/o
                    apoderado; aquellos que
                    por estipulación a favor de otro o para otro estén legitimados, o sus representantes, 17.2 Deberá
                    dirigirse a legal@prevenirexpress.com Deberá contener una descripción de los hechos que dan lugar al
                    reclamo y el objetivo
                    perseguido (actualización, corrección o supresión, o cumplimiento de deberes).Deberá indicar la
                    dirección y datos de contacto e identificación del reclamante. Deberá acompañarse por toda la
                    documentación que el reclamante
                    quiera hacer valer. 17.3 Antes de proceder, el responsable de atender el reclamo verificará: La
                    identidad del titular del dato personal o su representante. Para ello, puede exigir la cédula de
                    ciudadanía o documento
                    de identificación original del Titular, y los poderes especiales o generales según sea el caso. La
                    autorización o contrato con terceros que dieron origen al Tratamiento, por parte de la GP, del dato
                    personal del titular.
                    Si el reclamo o la documentación adicional están incompletos, GP requerirá al reclamante por una sola
                    vez dentro de los cinco (5) días siguientes a la recepción del reclamo para que subsane las fallas. Si
                    el reclamante
                    no presenta la documentación e información requerida dentro de los dos (2) meses siguientes a la fecha
                    del reclamo inicial, se entenderá que ha desistido del reclamo. Si por cualquier hecho la persona que
                    recibe el
                    reclamo al interior de GP no es competente para resolverlo, dará traslado a legal@prevenirexpress.com
                    dentro de los dos (2) días hábiles siguientes a haber recibido el reclamo, e informará de dicha
                    remisión al reclamante.
                    Una vez recibido el reclamo con la documentación completa, se incluirá en la base de datos de GP donde
                    reposen los datos del titular sujetos a reclamo una leyenda que diga “reclamo en trámite” y el motivo
                    del mismo,
                    en un término no mayor a dos (2) días hábiles. Esta leyenda deberá mantenerse hasta que el reclamo sea
                    decidido. 17.3 El término máximo para atender el reclamo será de quince (15) días hábiles contados a
                    partir del
                    día siguiente a la fecha de su recibo. Cuando no fuere posible atender el reclamo dentro de dicho
                    término, se informará al interesado los motivos de la demora y la fecha en que se atenderá su reclamo,
                    la cual en ningún
                    caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término. </p>
                </div>
              </div>
            </div>
            <br>
            <div class="border rounded blurTerminos">
              <div class="card-header cardTitulo" id="headingEighteen">
                <h5 class="mb-0 text-center">
                  <button class="btn btn-link collapsed text-white" data-toggle="collapse" data-target="#collapseEighteen"
                    aria-expanded="false" aria-controls="collapseEighteen">
                    18. VIGENCIA
  
                  </button>
                </h5>
              </div>
              <div id="collapseEighteen" class="collapse" aria-labelledby="headingEighteen" data-parent="#accordion">
                <div class="card-body blurTerminos text-white">
                  <p>Esta Política empezará a regir desde la fecha (septiembre 2016). Los datos personales que sean
                    almacenados, utilizados o transmitidos permanecerán en la base de datos de GRUPO PREVENIR GP, durante
                    el tiempo que sea necesario
                    para las finalidades mencionadas en esta Política, para las cuales fueron recolectados. </p>
                  <p>8.2 El usuario puede presentar ante la Superintendencia de Industria y Comercio quejas por
                    infracciones a la Ley 1581 de 2012 y al Decreto 1377 de 2013.Revocar su autorización y/o solicitar la
                    supresión de sus datos personales
                    de las bases de datos e GRUPO PREVENIR GP, siempre y cuando no exista un deber legal (cuando el
                    usuario ha realizado compras directas existe una obligación legal y tributaria de dejar el registro de
                    las compras realizadas,
                    por lo cual esta información debe permanecer en el registro de ventas de GRUPO PREVENIR GP, lo
                    anterior con fundamento al artículo 60 C.C) o una obligación de carácter contractual en cabeza del
                    titular con GRUPO PREVENIR
                    GP, en virtud de la cual el titular no tenga el derecho de solicitar la supresión de sus datos
                    personales o revocar su autorización para el Tratamiento de los mismos. Si no hay un deber legal o
                    contractual y GRUPO PREVENIR
                    GP no ha suprimido los datos personales del titular de sus bases de datos o no ha revocado la
                    autorización de quien está legitimado para revocarla dentro del término legal para ello, el titular
                    podrá acudir a la Superintendencia
                    de Industria y Comercio para que exija la revocación de la autorización y/o la supresión de los datos
                    personales. Solicitar acceso y acceder en forma gratuita a sus datos personales que hayan sido objeto
                    de Tratamiento.
                    Esta Política garantiza que cualquier información que nos provea será mantenida de manera privada y
                    segura. Para dar fe de esto, en este documento proveemos los detalles de qué información recabamos y
                    de qué manera
                    la utilizamos. Nunca recolectaremos información sin su consentimiento explícito, previo e
                    informado18.3 Quien diligencia el formulario de registro acepta y autoriza el tratamiento de sus datos
                    personales y que los mismos
                    se incorporen a las bases de datos de GRUPO PREVENIR GP, sus filiales o subsidiarias de acuerdo con lo
                    estipulado en las Políticas de Tratamiento de la Información. </p>
                </div>
              </div>
            </div>
            <br>
            <div class="border rounded blurTerminos">
              <div class="card-header cardTitulo" id="headingNineteen">
                <h5 class="mb-0 text-center">
                  <button class="btn btn-link collapsed text-white" data-toggle="collapse" data-target="#collapseNineteen"
                    aria-expanded="false" aria-controls="collapseNineteen">
                    19. ACEPTACIÓN DE LOS TÉRMINOS Y CONDICIONES
  
                  </button>
                </h5>
              </div>
              <div id="collapseNineteen" class="collapse" aria-labelledby="headingNineteen" data-parent="#accordion">
                <div class="card-body blurTerminos text-white">
                  <p>La participación en esta Actividad, mediante el registro en la App de GRUPO PREVENIR GP y
                    específicamente la recepción de los PREVI-descuentos significará la plena aceptación, de parte de los
                    Participantes, de estos Términos
                    y Condiciones, la cual se formalizará en la declaración de aceptación.</p>
                  <p>Estos Términos y Condiciones se encontrarán disponibles para consulta en el sitio web
                    www.prevenirexpress.com </p>
                </div>
              </div>
            </div>
            <br>
            <div class="border rounded blurTerminos">
              <div class="card-header cardTitulo" id="headingTwenty">
                <h5 class="mb-0 text-center">
                  <button class="btn btn-link collapsed text-white" data-toggle="collapse" data-target="#collapseTwenty"
                    aria-expanded="false" aria-controls="collapseTwenty">
                    20. LEY APLICABLE
  
                  </button>
                </h5>
              </div>
              <div id="collapseTwenty" class="collapse" aria-labelledby="headingTwenty" data-parent="#accordion">
                <div class="card-body blurTerminos text-white">
                  <p>Estos términos y condiciones, así como las Políticas de Privacidad y Manejo e Información de uso se
                    regirán e interpretarán de acuerdo con las leyes de la república de Colombia. </p>
                </div>
              </div>
            </div>
            <br>
            <br>
          </div>
  
  
  
        </div>
      </div>
    </div>
  </div>
-->
<main class="container-main">
  <section class="term-and-conditions">
    <h2 class="mt-5">

    <ng-container *ngIf="showButton">
      <custom-buttons (click)="emitClicked()" class="" text="" textColor="#fff" type="button" icon="mdi mdi-arrow-left" [title]="''" textSize="20px"></custom-buttons>
    </ng-container>


      Terminos & Condiciones
    </h2>
    <p class="px-5">
      Para recibir descuentos debe estar inscrito y sus datos diligenciados al 100%, los descuentos deberá ser redimido a
      nuestros terceros para confirmar día fecha y hora de su requerimiento. No tenemos límite de descuentos por persona.
      El descuento se enviará vía e-mail, WhatsApp, mensaje de texto o en su defecto se le llamara personalmente para
      conocimiento del solicitante. Para preguntas o reclamos (PQRS) se debe ingresar a www.prevenirexpress.com en espacio
      PQRS o al correo clientes@prevenirexpress.com, se debe enviar un e-mail ha solicitado su requerimiento o comunicarse
      a los teléfonos (2) 7290038 o WhatsApp 310 4351937, 3172645522, o al correo admin@prevenirexpress.com.
  
      GRUPO PREVENIR EXPRESS Sas. Es un enlace entre el usuario y el tercero para que apliquen los descuentos del servicio
      y NO se hace responsable de incumplimientos, malos procedimientos pero estará muy atento a las sugerencia quejas y
      reclamos para dar un buen servicio.
    </p>
    <small class="px-5">*Los descuentos los aplica la entidad jurídica o natural de acuerdo a sus condiciones y
      restricciones.
    </small>
    <small class="px-5">*Aplican condiciones y restricciones a todos los descuentos.</small>
  </section>
  
  <section class="container-tab">
    <h2>
      Tratamiento de datos, privacidad y confidencialidad
    </h2>
    <app-tab-vertical-component [tabs]="tabsVertical"></app-tab-vertical-component>
  </section>
</main>