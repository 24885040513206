import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UpdateUserData } from '@interfaces/user.interface';
import { SweetAlertService, ApplicationService, ModalService, InformationService, UserService } from '@services';
import { Store } from '@ngrx/store';
import { logout } from 'src/app/auth/state/auth.actions';
import { userData } from 'src/app/auth/state/auth.selectors';
import { Subscription } from 'rxjs/internal/Subscription';
import { AuthService } from 'src/app/auth/services/auth.service';
import { GenerateVerificationCode } from '@interfaces/auth.interface';
import { ApiRes } from '@interfaces/information.interface';
import { MergeAccounts } from '@interfaces/session.interface';

@Component({
  selector: 'app-complete-profile',
  templateUrl: './complete-profile.component.html',
  styleUrls: ['./complete-profile.component.scss'],
})
export class CompleteProfileComponent implements OnInit {

  public completeProfileForm!: FormGroup;
  public userVerified: any;
  public userDataSubscription!: Subscription;
  public minDate:any;
  public maxDate:any;
  public recoverAccount: { message: boolean, form: 'complete_profile' | 'send_code' | 'recover_account' | 'terms' } = { message: false, form: 'complete_profile' };
  public userData: { googleEmail: '', secondEmail: '', userId: '', temporalAccountId: '' } = { googleEmail: '', secondEmail: '', userId: '', temporalAccountId: '' };

  constructor(
    public appService: ApplicationService,
    private swalService: SweetAlertService,
    private modalService: ModalService,
    private infoService: InformationService,
    private userService: UserService,
    private store: Store,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.getInfoUser();
    this.minDate = this.appService.dateOperations('subtract', 'years', 100);
    this.maxDate = this.appService.dateOperations('subtract', 'years', 18);
  }

  ngOnDestroy(): void {
    this.unsubscribeUserData();
  }

  unsubscribeUserData() {
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }
  }

  getInfoUser() {
    this.userDataSubscription = this.store.select(userData).subscribe( (userData) => {

      if(!userData) return;

      const { dataSocialUser: { nombres, apellidos }, correo: googleEmail, _id: temporalAccountId } = userData;
      this.userData = { ...this.userData, googleEmail, temporalAccountId }

      this.initForm(nombres, apellidos);
    });
  }

  // Inicializar el formulario
  initForm(names: string, lastNames: string) {

    this.completeProfileForm = new FormGroup({
      name: new FormControl(names || '', [Validators.required, Validators.minLength(2), Validators.maxLength(20)]),
      surNames: new FormControl(lastNames || '', [Validators.required, Validators.minLength(2), Validators.maxLength(20)]),
      dateOfBirth: new FormControl('', [Validators.required]),
      documentNumber: new FormControl('', [
        Validators.required,
        Validators.pattern(/\d{6,9}/),
      ]),
      phoneNumber: new FormControl('', [Validators.required]),
      terms: new FormControl(false)
    });

    //this.getInfoUser();
  }

  // onSubmit
  submitForm() {

    const {
      name, 
      surNames, 
      dateOfBirth, 
      document, 
      type, 
      phoneNumber, 
      dialCode,
      terms
    } = this.getFormValues();

    if (!name || !surNames || !dateOfBirth || !document || !type || !phoneNumber || !dialCode) {

      this.swalService.lauchSwal(
        '',
        'Por favor verifica que los datos esten correctos.',
        'warning'
      );
      this.completeProfileForm.markAllAsTouched();
      return;
    }

    if(!terms) return this.swalService.lauchSwal('', 'Por favor acepta los terminos y condiciones antes de continuar.', 'warning');


    // Verificar si el  numero tiene whatsapp o no
    this.infoService
      .verifyWhatsAppNumber(phoneNumber)
      .subscribe((existe: boolean) => {

        const cellPhones = [
          {
            phone: phoneNumber,
            movil: true,
            llamadas: true,
            whatsapp: existe,
            prefix: dialCode,
          },
        ];

        // Interfaz de la data que se va a actualizar
        const updateData: UpdateUserData = {
          tipoDocumento: type,
          numeroDocumento: document,
          cellPhones,
          dataSocialUser: {
            fechaNacimiento: dateOfBirth,
            nombres: name,
            apellidos: surNames
          },
        };

        this.sendData(updateData);
      });
  }

  // Metodo para Actualizar los datos del user logeado desde google.
  sendData(userData: UpdateUserData) {
    // ejecutar el servicio de actualizar data del user con google
    this.userService.updateUserData(userData).subscribe((res) => {

      if(res.ok) return this.unsubscribeUserData();

      if(!res.ok && res?.body?.documentNumber) {
        this.recoverAccount.message = true;
      }

    });
    
  }

  // Cerrar session si no quiere actualizar los datos
  logout() {
    this.modalService.closeModal();
    this.store.dispatch(logout());
  }

  recoverAccountclicked() {

    this.completeProfileForm.addControl("method", new FormControl('wsp', [Validators.required]));
    this.recoverAccount.form = 'send_code';
    this.recoverAccount.message = false;
  }


  getFormValues(): { name: string, surNames: string, dateOfBirth: Date, document: string, type: string, phoneNumber: string, dialCode: string, terms: boolean } {
    
    const formValues = this.completeProfileForm.value

    const {
      name,
      surNames,
      dateOfBirth,
      documentNumber: { document, type },
      phoneNumber: { phoneNumber, dialCode },
      terms
    } = formValues;

    return { name, surNames, dateOfBirth, document, type: type.toUpperCase(), phoneNumber, dialCode, terms };
  }

  sendCode() {

    const { document: documentNumber, phoneNumber: phone } = this.getFormValues();

    if(!documentNumber || !phone) {
      this.swalService.lauchSwal('', 'Por favor revisa el formulario.', 'warning');
      this.completeProfileForm.markAllAsTouched();
      return;
    }

    const method: 'wsp' | 'sms' | 'email' = this.completeProfileForm.controls['method'].value;
    const sendCodeData: GenerateVerificationCode = {
      method,
      documentNumber,
      phone,
    };

    this.authService.generateVerificationCode(sendCodeData).subscribe({
      next: (res: ApiRes) => {
        const { message, body: { userId, email } } = res;
        this.userData = { ...this.userData, secondEmail: email, userId };
        this.completeProfileForm.addControl("code", new FormControl('', [Validators.required, Validators.maxLength(6)]));
        this.swalService.lauchSwal('', message, 'success');
        this.recoverAccount.form = 'recover_account';
      },
      error: () => {
        this.recoverAccount.message = true;
      }
    });
  }

  mergeAccounts() {

    const code = this.completeProfileForm.controls['code'].value;
    const { temporalAccountId, userId } = this.userData;
    const mergeAccounts: MergeAccounts = {
      code,
      temporalAccountId,
      userId
    };

    this.userService.mergeAccounts(mergeAccounts).subscribe();
  }
}