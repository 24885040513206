import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApplicationService, StatesNgrxService, SweetAlertService } from '@services';
import { Beneficiary, BeneficiaryGroup, CellPhone, FamilyGroup, GetBeneficiary, RegExValidate } from '@interfaces/information.interface';
import { InformationService } from '@services';
import { FamilyGroupsServiceService } from 'src/app/roles/services/family-groups-service.service';

@Component({
  selector: 'app-beneficiaries',
  templateUrl: './beneficiaries.component.html',
  styleUrls: ['./beneficiaries.component.scss']
})
export class BeneficiariesComponent implements OnInit{  

  @Input()familyGroup!:FamilyGroup;
  @Input()options=false;
  @Output() beneficiariesRegresarEmmiter: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  @ViewChild('beneficiariesList') beneficiariesList!: TemplateRef<any>;
  @ViewChild('beneficiaryEdit') beneficiaryEdit!: TemplateRef<any>;
  
  public currentView!: TemplateRef<any>;  
  
  public beneficiaries:GetBeneficiary[]=[];
  public beneficiaryForm!: FormGroup;
  public findUserForm!: FormGroup;
  public sendRequestForm!: FormGroup;
  public agregateConfirm = true;
  public solicitudEnviada = false;
  public cellPhones:CellPhone[] = [];
  public edadActual=0;
  public findUser ='';
  public numeroDocumento?:string;

  maxDate: any;

  public regExValidate:RegExValidate[]=[
    {regExp:/^[0-9]{5,12}$/,message:'El número de documento debe tener entre 5 y 12 caracteres y estar compuesto únicamente por números enteros, sin letras, símbolos ni valores decimales.'}
  ]

  public typesOfDocument: any[] = [];

  dataChecks: Array<any> = [
    { id: 1, label: 'Ver', value: 'view' },
    { id: 2, label: 'Crear', value: 'create' },
    { id: 3, label: 'Actualizar', value: 'update' },
    { id: 4, label: 'Eliminar', value: 'delete' },
    { id: 5, label: 'Pedir una cita', value: 'scheduleAppointment' },
  ];

  relationShips = [
    { value: "Spouse/Partner", name: "Esposo/a o Pareja" },
    { value: "Child", name: "Hijo/a" },
    { value: "Mother", name: "Madre" },
    { value: "Father", name: "Padre" },
    { value: "Sibling", name: "Hermano/a" },
    { value: "Grandparent", name: "Abuelo/a" },
    { value: "Grandchild", name: "Nieto/a" },
    { value: "Uncle/Aunt", name: "Tío/a" },
    { value: "Nephew/Niece", name: "Sobrino/a" },
    { value: "Cousin", name: "Primo/a" },
    { value: "Half-Brother/Half-Sister", name: "Medio hermano/a" },
    { value: "Stepbrother/Stepsister", name: "Hermanastro/a" },
    { value: "Stepfather/Stepmother", name: "Padrastro/Madrastra" },
    { value: "Brother-in-law/Sister-in-law", name: "Cuñado/a" },
    { value: "Son-in-law/Daughter-in-law", name: "Yerno/Nuera" },
    { value: "Father-in-law/Mother-in-law", name: "Suegro/a" },
    { value: "Great-grandparent", name: "Bisabuelo/a" },
    { value: "Great-grandchild", name: "Bisnieto/a" },
    { value: "Godson/Goddaughter", name: "Ahijado/a" },
    { value: "Godfather/Godmother", name: "Padrino/Madrina" }
  ];

  constructor (
      private infoService: InformationService,
      private familyGroupsService:FamilyGroupsServiceService,
      private statesNgrxService: StatesNgrxService,
      public appService: ApplicationService, 
      private swalService: SweetAlertService){}

  ngOnInit(): void {   
    
    this.maxDate = this.appService.dateOperations('today');
    
    this.getBeneficiaries();

    setTimeout(() => {
      this.currentView = this.beneficiariesList;      
    }, 200);

  }

  getBeneficiaries(){
    if (this.familyGroup.beneficiaries) {         
      // se agrego esta funcionalidad
      this.beneficiaries = this.familyGroup.beneficiaries;            
    } else {
      // esta era la funcionalidad que funcionaba, esta busqueda requiere el token del usuario
      if (this.familyGroup._id) {
        this.familyGroupsService.getFamilyGroupsDetailsId(this.familyGroup._id).then(resp=>{                    
          this.beneficiaries = resp.body.beneficiaries; 
                     
        }).catch(err=>{
          //console.log(err);          
        })          
      } 
    }
  }

  iniciarFindUserForm(){
    this.numeroDocumento=undefined;
    this.agregateConfirm = false;
    this.solicitudEnviada = false;

    this.findUserForm = new FormGroup({        
      findNumeroDocumento: new FormControl<string|null>(null,Validators.required),
    });

    this.currentView = this.beneficiaryEdit;
  }

  iniciarSendRequestForm(familyGroupId:string,beneficiaryId:string){

    let idUserGlobal='';

    this.statesNgrxService.getDataGlobalUser().subscribe((resp: any) => {
      idUserGlobal = resp._id;
    });

    this.sendRequestForm = new FormGroup({        
      whoResquestId: new FormControl<string>(idUserGlobal,Validators.required),
      beneficiaryId: new FormControl<string>(beneficiaryId,Validators.required),
      familyGroupId: new FormControl<string>(familyGroupId,Validators.required),
      relationship: new FormControl<string|null>(null,Validators.required),
    });
  }

  agregateBeneficiaryConfirm(){
    this.agregateConfirm = true;
    this.iniciarBeneficiaryAgregateForm();
    setTimeout(() => {
      this.hacerScroll('idFormBeneficiary');      
    }, 200);
  }



  async iniciarBeneficiaryAgregateForm(){

      try {
        this.typesOfDocument = this.infoService.getDocumentTypes();
      } catch (error) {
        this.typesOfDocument = [];
      }

      this.beneficiaryForm = new FormGroup({   
        
        tipoDocumento: new FormControl<string|null>(null,Validators.required),
        numeroDocumento: new FormControl<string|null>({value: this.numeroDocumento||null, disabled: true},Validators.required),
        nombres: new FormControl<string|null>(null,Validators.required),
        apellidos: new FormControl<string|null>(null,Validators.required),
        fechaNacimiento: new FormControl(null,Validators.required),
        years: new FormControl<string|null>({value: null, disabled: true}),
        correo: new FormControl(''),
        relationship: new FormControl<string|null>(null,Validators.required),
        telefono: new FormControl('', [Validators.pattern(/^3\d{9,9}/),]),
        checks: new FormControl(this.dataChecks),
        
        // permisos

      }); 

      // this.beneficiaryForm.controls['years'].disable(); 
      this.beneficiaryForm.controls['fechaNacimiento'].valueChanges.subscribe(resp=>{
        this.beneficiaryForm.controls['years'].setValue(this.getYearsToCurrentDate(resp));  
      })

      this.beneficiaryForm.controls['correo'].valueChanges.subscribe((resp) => {
        if (resp==='') {
          this.beneficiaryForm.controls['correo'].setErrors(null);
        }
      });

     
      // this.currentView = this.beneficiaryEdit;

  }

  getYearsToCurrentDate(fecha:string|null):string{

    if (!fecha) {
      return '';      
    }else{
      var hoy = new Date();
      var cumpleanos = new Date(fecha);
      var edad = hoy.getFullYear() - cumpleanos.getFullYear();
      var m = hoy.getMonth() - cumpleanos.getMonth();

      if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
          edad--;
      }  
      this.edadActual = edad;
      return `${edad} Años`;

    }

  }

  regresarBeneficiaries(): void {
    this.numeroDocumento=undefined;
    this.agregateConfirm = false;
    this.findUser ='';
    this.currentView = this.beneficiariesList;
  }


  changeTab(tab: string) {
    this.agregateConfirm = false;
    switch (tab) {
      case 'beneficiariesList':
        this.currentView = this.beneficiariesList;
        break;
      case 'beneficiaryEdit':
        this.currentView = this.beneficiaryEdit;
        break;
    }
  }  

  selectBeneficiary(beneficiary:GetBeneficiary){
    //console.log(beneficiary);   

  }

  searchValueEmmiter(value:string){
    this.agregateConfirm=false;
    this.solicitudEnviada=false;
    this.findUser ='';
    this.familyGroupsService.getFamilyGroupsDetailsDocument(value).then(resp=>{
      if (resp.ok) {
        const beneficiaryId = resp.body.beneficiaryId;
        const familyGroupId = this.familyGroup?._id||'';

        this.iniciarSendRequestForm(familyGroupId,beneficiaryId);
        
        this.findUser ='encontrado';
        this.numeroDocumento='';
        this.agregateConfirm=false;
        this.hacerScroll('idInfoFind');
      }else{
        this.numeroDocumento = value;
        this.findUser ='noEncontrado';
        this.hacerScroll('idInfoFind');
      }
      
      
    }).catch(err=>{
      //console.log(err);          
    });  
    
  }

  searchErrorEmmiter(error:boolean){
    if (error) {
      this.findUser ='error';
      this.numeroDocumento='';
      this.agregateConfirm=false;
      this.hacerScroll('idInfoFind');      
    }
  }

  hacerScroll(id: string): void {
    // Buscar el elemento por su ID
    var elementos = document.querySelectorAll(`#${id}`)[0] as HTMLElement;

    // Realizar el desplazamiento suave al elemento
    elementos.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  getRelationshipName(relationship:string):string{
    if (this.relationShips.length>0) {
      const relacion = this.relationShips.find(f=>f.value === relationship);
      return relacion?.name ||'Beneficiario';
      
    }else{
      return 'Beneficiario'
    }
  }

  convertArrayToObject(dataChecks: Array<any>, dataChecksList: Array<any>): any {
    let output:any = {};
  
    // Iterar sobre la lista completa
    for (let item of dataChecksList) {
      // Establecer en true si el valor está en dataChecks, de lo contrario en false
      output[item.value] = dataChecks.some(check => check.value === item.value);
    }
  
    return output;
  }

  async agregateBeneficiary(form:FormGroup){
    // //console.log(this.convertArrayToObject(this.beneficiaryForm.value.checks,this.dataChecks));
    
    if (form.valid) {
      const beneficiaryAgregate: BeneficiaryGroup = {
        groupId:this.familyGroup?._id||'',
        permissions:this.convertArrayToObject(this.beneficiaryForm.value.checks,this.dataChecks),
        beneficiary:{
          tipoDocumento:this.beneficiaryForm.value.tipoDocumento,
          numeroDocumento:this.numeroDocumento,
          nombres:this.beneficiaryForm.value.nombres,
          apellidos:this.beneficiaryForm.value.apellidos,
          correo:this.beneficiaryForm.value.correo,
          fechaNacimiento:this.beneficiaryForm.value.fechaNacimiento,
          tipoCuenta:'Beneficiary',
          cellPhones:this.cellPhones,
          registerData:{
            method:'form',
            platform:'web',
            registerBy:'yourself',
            beneficiary:{
              createdBy:this.familyGroup?.familyLeader ||'',
              relationship:this.beneficiaryForm.value.relationship,
            }
          }
        }
      };
      
      const result = await Swal.fire({
        title: 'Estás seguro?',
        text: `Se va a Agregar el ${beneficiaryAgregate.beneficiary?.nombres ||''} ${beneficiaryAgregate.beneficiary?.apellidos ||''} a tu grupo familiar`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, agregar!',
        cancelButtonText: 'Cancelar',
      });

      if (result.isConfirmed) {
        
        this.familyGroupsService.createFamilyGroupBeneficiary(beneficiaryAgregate).then(resp=>{
          
          if (resp.ok) {
            this.getBeneficiaries();
            this.currentView = this.beneficiariesList; 
            
            
          }
          
        }).catch(err=>{
          //console.log(err);          
        });        
      }      

    } else {
      this.beneficiaryForm.markAllAsTouched();
      const requiredFields = [
        'tipoDocumento',
        'numeroDocumento',
        'nombres',
        'apellidos',
        'fechaNacimiento',
        'relationship',
      ];

      const swalResult = await this.swalService.lauchSwalAsync(
        '',
        'Debes registrar los campos en color rojo, ya que son obligatorios',
        'warning'
      );
      if (swalResult) {
        setTimeout(() => {
          const scrollTo = requiredFields.find(
            (field) => this.beneficiaryForm.controls[field].invalid
          );
          if (scrollTo) {
            this.hacerScroll(
              `idInput${scrollTo.charAt(0).toUpperCase() + scrollTo.slice(1)}`
            );
          }
        }, 300);
      }
    }    

  }

  deleteBeneficiaryId(beneficiary:GetBeneficiary): void {        
    Swal.fire({
      title: 'Estás seguro?',
      text: `Se va a eliminar ${beneficiary.names || ''} ${beneficiary.lastName || ''} de tu Grupo Familiar`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar Beneficiario!',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        if (beneficiary.beneficiaryId && this.familyGroup._id) {          
          this.familyGroupsService.deleteBeneficiaryId(beneficiary.beneficiaryId,this.familyGroup._id).then(resp=>{
            if (resp.ok) {
              this.getBeneficiaries(); 
              setTimeout(() => {
                this.hacerScroll('pills-tab');
              }, 200);             
            }            
          }).catch(err=>{
            //console.log(err);            
          });          
        }
      }
    });
  }


  async sendRequestFamily(form:FormGroup){

    if (form.valid) {      

      const result = await Swal.fire({
        title: 'Estás seguro?',
        text: `Se va a enviar una solicitud al usuario para que haga parte de tu grupo familiar`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, enviar!',
        cancelButtonText: 'Cancelar',
      });

      if (result.isConfirmed) {
        this.familyGroupsService.sendRequestFamily(this.sendRequestForm.value).then((resp:any)=>{
          if (resp.ok) {
            this.solicitudEnviada=true;
          }
    
        }).catch(e=>{
          //console.log(e);        
        });
      }


      
    } else{
      this.sendRequestForm.markAllAsTouched();
      const requiredFields = [
        'relationship',
      ];

      const swalResult = await this.swalService.lauchSwalAsync(
        '',
        'Debes registrar los campos en color rojo, ya que son obligatorios',
        'warning'
      );
      if (swalResult) {
        setTimeout(() => {
          const scrollTo = requiredFields.find(
            (field) => this.sendRequestForm.controls[field].invalid
          );
          if (scrollTo) {
            this.hacerScroll(
              `idInput${scrollTo.charAt(0).toUpperCase() + scrollTo.slice(1)}`
            );
          }
        }, 300);
      }
      

    }
    
  }

}
