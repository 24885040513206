import { Component, Input, OnInit } from '@angular/core';
import { MenuWeb, Role } from 'src/app/interfaces/auth.interface';
import { Store } from '@ngrx/store';
import { getMenu, getRoles } from 'src/app/pages/session/state/session.selectors';
import { StatesNgrxService } from 'src/app/services/states-ngrx.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Input() rolSelect: Role = {
    rol: '',
    name: '',
    _idData: '',
    avatar: '',
  };

  menuItems: MenuWeb[] = [];
  roles: Role[] = [];
  opcion = '';
  expandedOption: string | null = null;
  isUserProfileExpanded = false;

  constructor(
    private store: Store,
    private statesNgrxService: StatesNgrxService
  ) {}

  ngOnInit(): void {
    this.dispatchSelectors();
  }

  dispatchSelectors() {
    this.store.select(getMenu).subscribe((menu) => {
      this.menuItems = menu;
    });

    this.store.select(getRoles).subscribe((roles) => (this.roles = roles));
  }

  toggleSubMenu(option: string) {
    // Alterna el submenú al hacer click
    this.expandedOption = this.expandedOption === option ? null : option;
  }

  toggleUserProfile() {
    // Alterna la sección del perfil de usuario
    this.isUserProfileExpanded = !this.isUserProfileExpanded;
  }

  changeRol(role: Role) {
    this.statesNgrxService.changeRol(role);
  }

  get getProfileRoleUrl(): string {
    return `/${this.rolSelect.rol}/profile`;
  }

  getProfileRoleName(rol: string): string {
    switch (rol) {
      case 'user':
        return 'Usuario';
      case 'provider':
        return 'Empresa';
      case 'doctor':
        return 'Profesional de salud';
      default:
        return 'Perfil';
    }
  }
}
