import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TabVerticalContentComponentComponent } from '../tab-vertical-content-component/tab-vertical-content-component.component';

@Component({
  selector: 'app-tab-vertical-component',
  templateUrl: './tab-vertical-component.component.html',
  styleUrls: ['./tab-vertical-component.component.scss'],
  standalone: true,
  imports: [CommonModule, TabVerticalContentComponentComponent],
})
export class TabVerticalComponentComponent {

  @Input() tabs!: any[];
  activeTab: number = 0;

  setActiveTab(index: number): void {
    this.activeTab = index;
  }
}
