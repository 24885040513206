import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Card } from 'src/app/interfaces/information.interface';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {
  @Input() cardOptions: Card = {
    imageRoute: '',
    buttonName: '',
    icons: '',
    description: '',
    tittle: ''
  };

  @Output() buttonClicked = new EventEmitter<any>;
}
