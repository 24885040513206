<div [ngClass]="classDiv" style="display: flex;">
    <div style="width: 40px;
                height: 40px;
                min-width: 40px;
                min-height: 40px;
                border: 1px solid #008e64;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: transparent;">
        <span style="font-size: 20px;
        color: #008e64;">{{ number }}</span>
      </div>
      &nbsp;&nbsp;&nbsp;
    <h2>{{ sectionName }}</h2>
</div>
<hr>