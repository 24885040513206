import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProfileService } from '../profile/profile.service';
import { UserRole } from 'src/app/interfaces/auth.interface';
import { getRolSelect } from 'src/app/pages/session/state/session.selectors';
import { Observable, map, switchMap } from 'rxjs';
import { ApiRes } from 'src/app/interfaces/information.interface';


@Injectable({
  providedIn: 'root',
})
export class RolInformationService { 


  constructor(private store: Store, private profileService: ProfileService) {}


  getUserInformation(): Observable<any> {
    return this.store.select(getRolSelect).pipe(
      switchMap((rolSelect: UserRole) => {
        const { _idData, rol } = rolSelect;

        return this.getRoleInformation(_idData, rol);
      })
    );
  }

  private getRoleInformation(idData: string, role: string): Observable<any> {
    return this.profileService.getRoleInformation(idData, role).pipe(
      map((data:ApiRes)=>{
        return {body:data.body,roleInformation:{idData,role}}
      })
    )
  }
  
}
