import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ChangePassword, ChangePasswordData } from '@interfaces/auth.interface';
import { EncryptService, SweetAlertService } from '@services';
import { AuthService } from '../services/auth.service';
import { ApiRes } from '@interfaces/information.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {


  @ViewChild('email') email!: TemplateRef<any>;
  @ViewChild('phone') phone!: TemplateRef<any>;
  @ViewChild('changePassword') changePassword!: TemplateRef<any>;

  public currentView!: TemplateRef<any> | null;
  public changePasswordData!: ChangePasswordData;
  public changePasswordForm!: FormGroup;

  constructor(private sweetAlert: SweetAlertService,
              private authService: AuthService,
              private encryptService: EncryptService,
              public router: Router) {}

  public changeView(view: 'email' | 'phone' | 'changePassword', method: 'email' | 'sms' | 'wsp'): void {

    this.changePasswordData = { ...this.changePasswordData, method };

    if(view === 'phone') {

      this.inicializateForm('phone');
      this.currentView = this.phone;
    }
    else if(view === 'email') {

      this.inicializateForm('email');
      this.currentView = this.email; 
    }
    else this.currentView = this.changePassword;

  }

  private inicializateForm(method: 'email' | 'phone') {

    if(method === 'email') {

      this.changePasswordForm = new FormGroup({
         email: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]),
      });

    } else {

      this.changePasswordForm = new FormGroup({
        documentNumber: new FormControl('', [Validators.required, Validators.pattern(/\d{6,9}/)]),
        phone: new FormControl('', [Validators.required, Validators.pattern(/^3\d{9,9}/)])
     });
    }

  }

  sendCode() {

    if(this.changePasswordForm.invalid) {
      this.sweetAlert.lauchSwal('', 'Por favor revisa el formulario.', 'warning');
      this.changePasswordForm.markAllAsTouched();
      return;
    } 

    this.changePasswordData = { ...this.changePasswordData, ...this.changePasswordForm.value };
    this.authService.generateVerificationCode(this.changePasswordData).subscribe((res: ApiRes) => {
      const { message, body: { userId} } = res;

      this.sweetAlert.lauchSwal('', message, 'success');
      this.changePasswordData = { ...this.changePasswordData, userId };

      this.changePasswordForm = new FormGroup({
        password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(20)]),
        verifyPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(20)]),
        verificationCode: new FormControl('', [Validators.required, Validators.maxLength(8)])
      });

      this.currentView = this.changePassword
    }); 
  } 

  sendNewPassword() {

    if(this.changePasswordForm.invalid) {
      this.sweetAlert.lauchSwal('', 'Por favor revisa el formulario.', 'warning');
      this.changePasswordForm.markAllAsTouched();
      return;
    }

    const { password, verifyPassword, verificationCode } = this.changePasswordForm.value;
    if(password !== verifyPassword) {

      this.sweetAlert.lauchSwal('', 'Las contraseñas no coinciden.', 'warning');
      this.changePasswordForm.get('verifyPassword')?.setErrors({ notMatching: true });
      this.changePasswordForm.markAllAsTouched();
      return;
    }

    const { userId } = this.changePasswordData;

    const data: ChangePassword = { 
      password: this.encryptService.manageActions({ action: 'password', data: password}),
      verificationCode,
      userId
    };

    this.authService.changePassword(data).subscribe(() => {
      this.goToLogin();
    });
  }

  public goToLogin() {
    this.router.navigate(['/auth/login']);
  }

}
