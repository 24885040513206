import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-range-input',
  templateUrl: './range-input.component.html',
  styleUrls: ['./range-input.component.scss'],
})
export class RangeInputComponent {
  @Input() min: number = 0;
  @Input() max: number = 100;
  @Input() step: number = 10;
  @Input() value: number = 0;
  @Input() selectMin: boolean = true;
  @Input() viewUnidades: boolean = true;
  @Input() inputTxt: string = 'Seleciona o registra un valor';
  @Input() alertMin: string = 'El valor registrado es muy pequeño.';
  @Input() alertMax: string = 'El valor registrado es muy grande.';
  @Input() unidades: string = '';
  @Input() backgroundMain: string = '';
  @Input() paddingMain: string = '';
  @Input() colorMain: string = '';
  @Input() borderRadiusMain: string = '0px';

  @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();

  showAlert = false;
  showAdvertencia = false;

  constructor() {}

  get stepValues(): number[] {
    const steps = Math.floor((this.max - this.min) / this.step) + 1;
    return Array.from({ length: steps }, (_, i) => this.min + i * this.step);
  }

  getMarkerPosition(index: number): number {
    const numSteps = this.stepValues.length - 1;
    return (100 / numSteps) * index;
  }

  obtenerParteEntera(numero: number): number {
    return Math.floor(numero);
  }

  obtenerParteDecimal(numero: number): number {
    return Math.round((numero - Math.floor(numero)) * 100);
  }

  showMarker(index: number): boolean {
    return (
      index === 0 || index === this.stepValues.length - 1 || index % 5 === 0
    );
  }

  getUnidad(unidades: string): string {
    if (unidades === 'Kilometros' || 'km' || 'Km' ) {
      return 'km';
    }else if (unidades === 'Metros') {
      return 'm';
    }else {
      return '';
    }
  }

  onShowAdvertencia() {
    if (this.value > this.max) {
      this.showAdvertencia = true;
    } else {
      this.showAdvertencia = false;
    }
  }

  onNumberInputChange(newValue: number): void {
    this.onShowAdvertencia();
    if (!this.selectMin && newValue < this.stepValues[1]) {
      this.value = this.stepValues[1];
      this.showAlert = true;
    } else if (newValue < 0) {
      this.value = 0;
      this.showAlert = true;
    } else {
      this.value = newValue;
      this.showAlert = false;
    }
    this.valueChange.emit(this.value);
  }

  onInputChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const newValue = parseFloat(inputElement.value);

    this.showAlert = false;

    if (!this.selectMin && newValue === this.min) {
      inputElement.value = (this.min + this.step).toString();
      this.value = this.min + this.step;
    } else {
      this.value = newValue;
    }
    this.onShowAdvertencia();
    this.valueChange.emit(this.value);
  }
}
