import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'input[emailValidation]',
})
export class EmailValidationDirective {
  constructor(private el: ElementRef) {}

  @HostListener('blur')
  onBlur() {
    const inputValue: string = this.el.nativeElement.value;
    if (!this.isValidEmail(inputValue)) {
      this.el.nativeElement.classList.add('invalid-email');
    } else {
      this.el.nativeElement.classList.remove('invalid-email');
    }
  }

  private isValidEmail(email: string): boolean {
    const pattern: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }
}
