import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noData'
})
export class NoDataPipe implements PipeTransform {

  transform(value: any, fallbackText: string = ''): string {
    if (value === null || value === undefined || value === '') {
      return fallbackText;
    }
    return value;
  }

}
