<div class="row justify-content-center text-center">
  <div class="col-12 col-md-4">
    <figure class="lottie-container">
      <ng-lottie [options]="options" width="100%" height="100%"></ng-lottie>
    </figure>
    <div class="not-data-message">
      <p>{{ message }}</p>
      <div *ngIf="button == true" class="not-data-button">
        <custom-buttons
          class="button-data"
          [title]="textButton"
          inputBackgroundColor="primary"
          [text]="textButton"
          (click)="buttonClicked.emit()">
        </custom-buttons>
      </div>
    </div>
  </div>
</div>
