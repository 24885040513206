import { Pipe, PipeTransform } from '@angular/core';
import { Translation } from '@interfaces/information.interface';

@Pipe({
  name: 'translateString'
})
export class TranslateStringPipe implements PipeTransform {

  transform(term: string, translations: Translation[]): string {    
    const translationObj = translations.find(obj => obj.term.toLowerCase() === term.toLowerCase());
    return translationObj ? translationObj.translate : term;
  }

}
