import { Component } from '@angular/core';

@Component({
  selector: 'app-info-doctor',
  templateUrl: './info-doctor.component.html',
  styleUrls: ['./info-doctor.component.scss']
})
export class InfoDoctorComponent {

}
