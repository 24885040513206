import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/local-storage.service';

export const rolesGuard: CanActivateFn = (route, state) => {

  const localStorageService = inject(LocalStorageService);
  const sessionData = localStorageService.getItem('session');
  const router = inject(Router);
   
  const rol = JSON.parse(sessionData!)['rol'];
  const data = route.data?.['rol'];

  if(!sessionData) {
    router.navigate(['']);
    return false;
  }

  if(rol === data) return true;
  else {
    router.navigate(['/home']);
    return false;
  }

};
