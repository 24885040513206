import { Pipe, PipeTransform } from '@angular/core';
import { differenceInYears, differenceInMonths, differenceInDays } from 'date-fns';

@Pipe({
  name: 'ageString'
})
export class AgeStringPipe implements PipeTransform {

  transform(startDate: Date | string, endDate: Date | string, unit: 'years' | 'months' | 'days'): string {
    if (!startDate || !endDate) {
      return '';
    }

    const start = typeof startDate === 'string' ? new Date(startDate) : startDate;
    const end = typeof endDate === 'string' ? new Date(endDate) : endDate;

    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
      return '';
    }

    let diff: number;

    switch (unit) {
      case 'years':
        diff = differenceInYears(end, start);
        return `${diff} ${diff === 1 ? 'año' : 'años'}`;
      case 'months':
        diff = differenceInMonths(end, start);
        return `${diff} ${diff === 1 ? 'mes' : 'meses'}`;
      case 'days':
        diff = differenceInDays(end, start);
        return `${diff} ${diff === 1 ? 'día' : 'días'}`;
      default:
        return '';
    }
  }

}
