import { ChangeDetectorRef, Injectable, inject } from '@angular/core';
import { ApiService } from './api.service';
import { firstValueFrom } from 'rxjs';
import { Appointment, AppointmentCase, RescheduleAppointment } from '@interfaces/appointments';

@Injectable({
  providedIn: 'root',
})
export class AppointmentsService {
  // cdr = inject(ChangeDetectorRef)

  constructor(private apiService: ApiService) {}

  getAppointmentsBranchDocument(
    documentNumber: string,
    branchId: string,
    type: string
  ) {
    return this.apiService.getRequest(
      `appointments/branch/${documentNumber}/${branchId}/${type}`
    );
  }

  async postAppointmentsBranch(data: AppointmentCase) {
    let response = await firstValueFrom(
      this.apiService.postRequest(`appointments/branch`, data)
    );
    return response;
  }

  async postAppointmentsBranchPets(data: AppointmentCase) {
    let response = await firstValueFrom(
      this.apiService.postRequest(`appointments/branch/pets`, data)
    );
    return response;
  }

  async getAppointmentsActiveSubservices(idBranch: string) {
    let response = await firstValueFrom(
      this.apiService.getRequest(`appointments/active-subservices/${idBranch}`)
    );
    return response;
  }

  async getTempAppointmentsActiveSubservices(idBranch: string) {
    let response = await firstValueFrom(
      this.apiService.getRequest(`appointments/temporal/${idBranch}`)
    );
    return response;
  }

  async getAppointmentsSchedule(
    subServiceId: string,
    doctorId: string,
    branchId: string,
    date: string
  ) {
    let response = await firstValueFrom(
      this.apiService.getRequest(
        `search/schedule/${subServiceId}/${doctorId}/${branchId}/${date}`
      )
    );
    return response;
  }

  async putAppointmentsReschedule(reschedule: RescheduleAppointment) {
    let response = await firstValueFrom(
      this.apiService.putRequest('appointments', reschedule)
    );
    return response;
  }

  async putAppointmentsCancel(cancelData: any) {
    let response = await firstValueFrom(
      this.apiService.putRequest('appointments/cancel', cancelData)
    );
    return response;
  }
}
