<div class="container_file_image">
  <div id="idDrop" class="image_drop " (dragover)="onDragOver($event)" (drop)="onDrop($event)"
    (dragleave)="onDragleave($event)">
    <img [ngClass]="inputRoundedImage" [src]="inputImageDef" style="pointer-events: none;">
    <div *ngIf="!imageDrop && !errorTipo && !cargandoFile" class="arrastra_title">
      Arrastra una foto aquí
    </div>
    <div *ngIf="!imageDrop && !errorTipo && !cargandoFile" class="arrastra_subTitle">
      - O -
    </div>
    <div *ngIf="!imageDrop && !errorTipo && !cargandoFile" class="card_computador_btn">
      <!-- <button class="btn_computador" (click)="selectImage()">
        <i class="mdi mdi-laptop pr_10"></i>Cargar Imagen
      </button> -->

      <custom-buttons
        text="Cargar Imagen"
        textColor="#fff"
        width="250px"
        type="button"
        [title]="''"
        (click)="selectImage()"
        icon="fa fa-cloud-upload">
      </custom-buttons>

      <!-- <custom-buttons class="btn_computador" (click)="selectImage()" text="Cargar Imagen" textColor="#fff" [title]="''"
        icon="mdi mdi-laptop">
        <i class="mdi mdi-laptop pr_10"></i>
      </custom-buttons> -->
      <input type="file" accept="image/*" (change)="onFileSelected($event)" hidden>
    </div>
    <div *ngIf="imageDrop && !errorTipo && !cargandoFile" class="card_computador_btn">
      <span class="notification_image">Suelta la foto para subirla</span>
    </div>
    <div *ngIf="errorTipo && !imageDrop && !cargandoFile" class="card_computador_btn">
      <span class="notification_image">El tipo de archivo no es valido, tipos validos (jpg, jpeg, png)
        <button class="btn btn-primary ml-2" (click)="imageDrop=false;errorTipo=false"> Ignorar</button>

        <!-- <custom-buttons class="btn btn-primary ml-2" (click)="imageDrop=false;errorTipo=false" text="Ignorar"
          textColor="#fff" [title]="''">
        </custom-buttons> -->
      </span>
    </div>
    <div *ngIf="cargandoFile" class="card_computador_btn">
      <span class="notification_image">Subiendo foto...</span>
    </div>
  </div>

</div>
