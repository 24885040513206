<div class="paginator_container fw_500" [ngStyle]="{'justify-content': justifyContent }">
  <div *ngIf="isSmallScreen===false && paginator_col === true" class="paginator_col mr-2 fz_14" >
    Mostrando del {{ startIndex + 1 }} al
    {{ endIndex >= dataList.length ? dataList.length : endIndex }} de
    {{ dataList.length }} {{ itemName }}
  </div>
  <div class="paginator_select mr-2" *ngIf="paginator_select === true">
    <div class="mr-1 fz_12 align_right">Mostrar</div>
    <select class="form-control" style="width: 45px" [(ngModel)]="itemsPage" (change)="itemsPorPage($event)">
      <option *ngFor="let numOption of numOptions">{{numOption}}</option>
    </select>
    <div *ngIf="isSmallScreen===false" class="ml-1 fz_12 align_left">{{ itemName }} por página</div>
  </div>
  <div class="paginator_col btn_container">
    <button class="btn btn-sm btn-secondary" title="Anterior" (click)="previousItem()">
      <i class="ti ti-angle-left"></i>
    </button>
    <div *ngFor="let page of pagesButtons">
      <button *ngIf="page.page == -1" class="btn btn-sm btn-secondary btn_paginaitor_page" disabled>
        <i class="icon-options"></i>
      </button>
      <button *ngIf="page.page != -1" class="btn btn-sm btn-secondary btn_paginaitor_page"
        [class.active]="page.selected" (click)="setItem(page)">
        {{ page.page }}
      </button>
    </div>
    <button class="btn btn-sm btn-secondary" title="Siguiente" (click)="nextItem()">
      <i class="ti ti-angle-right"></i>
    </button>
  </div>
</div>
