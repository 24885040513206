import { Component, Input, OnInit} from '@angular/core';
import { FamilyGroup } from '@interfaces/information.interface';
import { ModalService, StatesNgrxService } from '@services';
import { FamilyGroupsServiceService } from 'src/app/roles/services/family-groups-service.service';
import { Observable, Subscription, map } from 'rxjs';
import { ModalOptions } from '@interfaces/modal.reusable.interface';
import { FamilyAdminComponent } from './family-admin/family-admin.component';
import { BeneficiariesFamilyComponent } from './beneficiaries-family/beneficiaries-family.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-family-groups',
  templateUrl: './family-groups.component.html',
  styleUrls: ['./family-groups.component.scss']
})
export class FamilyGroupsComponent implements OnInit {

  @Input() inputFamilyGroups:FamilyGroup[]=[];
  @Input() idUser?:string;

  public advisor= false;
  public familyGroups:FamilyGroup[]=[];
  public numFamiliesLeader = 0;
  private subscription: Subscription = new Subscription();
  menssageNotFamily: string = 'No hay grupos familiares registrados, agrega a tus beneficiarios para disfrutar nuestros beneficios'
  isLoading : boolean = true;

  constructor(
    private familyGroupsService:FamilyGroupsServiceService,
    private statesNgrxService:StatesNgrxService,
    private router: Router,
    private modalService: ModalService){}


  ngOnInit(): void {
    if (this.inputFamilyGroups.length==0) {
      this.inicializarGruposFamiliaresCase();
    } else{
      this.familyGroups = this.mapFamilyGroupsAdvisor(this.inputFamilyGroups);

      this.advisor=true;
    }
    this.loadControl();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  inicializarGruposFamiliaresCase(){
    if (this.idUser) {
      this.getFamilyGroupsId(this.idUser);
    } else{
      this.getFamilyGroups();
    }
  }

  loadControl() {
    setTimeout(() => {
      this.isLoading = false;
    }, 1500);
  }

  mapFamilyGroupsAdvisor(groups: any[]): FamilyGroup[] {

    return groups.map(group => ({
      name: group.familyGroupName,
      familyLeader: '',
      beneficiaries: group.beneficiaries,
      avatar: group.familyGroupAvatar,
      groupId: group.familyGroupId,
      isLeader: true,
      numberOfBeneficiaries: group.beneficiaries.length||0,
      // numberOfPets: Array(group.pets).length || 0,
      _id: group.familyGroupId
    }));
  }

  isFamilyLeader(familyGroup: FamilyGroup[]): Observable<FamilyGroup[]> {
    return this.statesNgrxService.getDataGlobalUser().pipe(
      map((resp: any) => {
        if (!resp) {
          //console.log('Error');
          return [];
        }

        const userID = resp._id;
        if (familyGroup.length > 0) {
          return familyGroup.map((f) => ({
            ...f,
            isLeader: f.familyLeader === userID,
          }));
        }

        return [];
      })
    );
  }


  getFamilyGroups(){
    this.familyGroupsService.getFamilyGroups().then(resp=>{
      if(resp.body){

        this.isFamilyLeader(resp.body).subscribe(groups=>{
          groups.forEach(group=>{
            if(group.isLeader){
              this.numFamiliesLeader++;
            }
          });
          this.familyGroups = groups;
        });
      }
    }).catch(err=>{
      //console.log(err);
    });

  }

  getFamilyGroupsId(id:string){
    this.familyGroupsService.getFamilyGroupsUserId(id).then(resp=>{
      console.log(resp);

      if(resp.body){

        this.familyGroups = resp.body;

      }
    }).catch(err=>{
      //console.log(err);
    });

  }

  openModalCreate(){
    const modalContent = FamilyAdminComponent;

    const options: ModalOptions = {
      modalClass: 'modal-dialog modal_md',
      modalClassContent: 'modal-content-adjust',
      modalClassBody: 'modal-body-adjust',
      header: false,
      footer: false,
      dataKeyboard: false,
      static: 'static',
    };

    const instance = this.modalService.openModal(modalContent, options, {
      closeAndUpdate:false
    });

    if(instance.myEvent) {
      const subscription = instance.myEvent.subscribe(() => {
        this.inicializarGruposFamiliaresCase();
      });
      this.subscription.add(subscription);
    }

  }


  openModalUpdate(familyGroup:FamilyGroup){

    const modalContent = FamilyAdminComponent;

    const options: ModalOptions = {
      modalClass: 'modal-dialog modal_md',
      modalClassContent: 'modal-content-adjust',
      modalClassBody: 'modal-body-adjust',
      header: false,
      footer: false,
      dataKeyboard: false,
      static: 'static',
    };

    const instance = this.modalService.openModal(modalContent, options, {
      groupSelected:familyGroup
    });

    if(instance.myEvent) {
      const subscription = instance.myEvent.subscribe(() => {
        this.inicializarGruposFamiliaresCase();
      });
      this.subscription.add(subscription);
    }

  }


  openModalBeneficiaries(familyGroup:FamilyGroup,opt:boolean){

    const modalContent = BeneficiariesFamilyComponent;

    const options: ModalOptions = {
      modalClass: 'modal-dialog modal_xl',
      modalClassContent: 'modal-content-adjust',
      modalClassBody: 'modal-body-adjust',
      header: false,
      footer: false,
      dataKeyboard: false,
      static: 'static',
    };

    const instance = this.modalService.openModal(modalContent, options, {
      groupSelected:familyGroup,options:opt
    });

    if(instance.myEvent) {
      const subscription = instance.myEvent.subscribe(() => {
        this.inicializarGruposFamiliaresCase();
      });
      this.subscription.add(subscription);
    }

  }

  action(infoFamilyGroup: any) {
    if ('condition' in infoFamilyGroup) {
      this.openModalBeneficiaries(infoFamilyGroup.infoFamilyGroup, infoFamilyGroup.condition);
    } else {
      this.openModalUpdate(infoFamilyGroup.infoFamilyGroup);
    }
  }

  onButtonClicked(): void {
    this.openModalCreate();
  }
}
