import {
  Appointment,
  SendTicketAppointment,
  AppointmentActiveSubservice,
  AppointmentCase,
  AppointmentDoctor,
  AppointmentsAvailable,
  UserAppointment,
} from '@interfaces/appointments';
import {
  ApplicationService,
  SocketsService,
  StatesNgrxService,
  SweetAlertService,
} from '@services';
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  ComponentRef,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

import { Branch, Pet } from '@interfaces/information.interface';
import { AnimationOptions } from 'ngx-lottie';

import { UserService } from 'src/app/roles/services/user.service';
import { AppointmentsService } from 'src/app/services/appointments.service';
import { ModalService } from 'src/app/reusable-modal/modal.service';
import { ApiService } from '@services';
import html2canvas from 'html2canvas';
import { CustomCalendarComponent } from 'src/app/custom-calendar/custom-calendar.component';

interface AvailableIncome {
  shiftId: string;
  initialHour: string;
  office: string;
}
@Component({
  selector: 'app-user-citas-view',
  templateUrl: './user-citas-view.component.html',
  styleUrls: ['./user-citas-view.component.scss'],
})
export class UserCitasViewComponent implements OnInit {
  @ViewChild('canvasEl') canvasEl!: ElementRef<HTMLCanvasElement>;

  @Input() idInput = 'idCitasView';
  @Input() pasosInput!: number;
  @Input() branchSelected!: Branch;
  @Input() appointmentType = '';
  @Input() inputSubServiceId?: string;
  @Input() documentNumber!: string;
  @Input() income?: boolean;
  @Input() documentNumberAcudiente!: string;
  @Input() userCase?: UserAppointment;
  @Input() guardianCase?: UserAppointment;
  @Input() petCase?: Pet;
  @Input() petSelected?: Pet;
  @Input() pets?: Pet[];
  @Input() relationshipAcudiente = '';
  @Input() relationshipUser = '';
  @Input() disableIncome = false;
  @Input() user?: any;
  @Input() tempAppointment?: any;
  @Input() appointments: Appointment[] = [];

  @ViewChild(CustomCalendarComponent)
  customCalendarComponentRef!: CustomCalendarComponent;

  onViewDaylicalendar: boolean = false;
  citasForm!: FormGroup;
  dateErrors: Record<string, string> = {};
  activeSubService: AppointmentActiveSubservice[] = [];
  activeSubServiceSelected?: AppointmentActiveSubservice;
  doctors: AppointmentDoctor[] = [];
  doctorSelected?: AppointmentDoctor | null;
  appointmentsAvailable: AppointmentsAvailable[] = [];
  // hoursAvailable: Hour[] = [];
  appointmentsAvailableIncome: AvailableIncome[] = [];
  appointment?: Appointment;
  appointmentCase?: AppointmentCase;
  options: AnimationOptions = {
    path: 'assets/lottie-animations/calendar_edited.json',
  };

  infoPatient: any = {};
  infoAppointment: any = {};
  infoPet: any = {};
  ordenDescuento: boolean = false;

  patient = '';
  globaluserId = '';
  case = '';
  fechaIncomeText = '';
  appoimentDetails: any = null;
  inputData = {
    subServiceId: '',
    doctorId: '',
    branchId: '',
  };
  confirmCita = false;
  incomeSelected?: any;
  errorCita = '';

  endPointCalendar: string | null = null;

  setSchedule: boolean = false;

  constructor(
    public appService: ApplicationService,
    private swalService: SweetAlertService,
    private statesNgrxService: StatesNgrxService,
    private userService: UserService,
    private socket: SocketsService,
    private appointmentsService: AppointmentsService,
    private applicationService: ApplicationService,
    private modalService: ModalService,
    private apiService: ApiService,
  ) {
    this.dateErrors['mesesError'] =
      'La fecha seleccionada no está en el rango correcto';
  }
  public context!: CanvasRenderingContext2D;

  generateImage(htmlNode: HTMLElement): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      try {
        html2canvas(htmlNode, {
          scale: 2, // Incrementa la escala para mejorar la calidad
          // useCORS: true, // Permite cargar imágenes de recursos remotos con CORS
          // logging: true,
          allowTaint: false,
        })
          .then((canvas) => {
            const dataUrl = canvas.toDataURL('image/png', 1.0);
            resolve(dataUrl);
          })
          .catch((error) => {
            // console.error('Error generating image:', error);
            reject(error);
          });
      } catch (error) {
        console.error('Unexpected error:', error);
        reject(error);
      }
    });
  }

  captureTicket(): void {
    try {
      let elem = document.querySelector('.ticket-container') as HTMLElement;
      if (elem) {
        this.generateImage(elem)
          .then((dataUrl) => {
            this.drawImageOnCanvasUser(dataUrl);
          })
          .catch((error) => {
            console.error('Error capturing ticket:', error);
          });
      } else {
        console.error('Element with class .ticket-container not found');
      }
    } catch (error) {
      console.error('Unexpected error during captureTicket:', error);
    }
  }

  drawImageOnCanvasUser(img64: string): void {
    let img = new Image();
    img.src = img64;

    img.addEventListener('load', () => {
      const canvas = this.canvasEl.nativeElement;
      const context = canvas.getContext('2d');

      if (context) {
        // Ajustar el tamaño del canvas al tamaño de la imagen
        canvas.width = img.width;
        canvas.height = img.height;

        // Dibujar la imagen en el canvas
        context.drawImage(img, 0, 0);

        // Obtener la imagen del canvas con alta calidad
        let img64Canvas = canvas.toDataURL('image/jpeg', 1.0); // Calidad máxima

        if (this.infoPatient && this.infoPatient.phone) {
          const formattedDate = this.formatDate(
            this.infoAppointment?.fechaCita
          );
          const data: SendTicketAppointment = {
            caption: `Se ha reservado una cita para el servicio de ${
              this.infoAppointment?.subservice
            } en ${this.infoAppointment?.entity} con direccion ${
              this.infoAppointment?.address
            } ${this.infoAppointment?.neighbor}. ${
              formattedDate
                ? `Para el dia ${formattedDate} a las ${this.formatTime(
                    this.infoAppointment?.hour
                  )}`
                : ''
            }. Recuerda cumplir con las normas de bioseguridad y llegar 15 minutos antes de la cita.`,
            number: this.infoPatient?.phone,
            mediab64: img64Canvas,
          };

          this.userService.sendTicketAppointment(data);
        } else {
          console.error('No hay número de teléfono disponible');
        }
      } else {
        console.error('Contexto de canvas no disponible');
      }
    });
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      console.error(`Invalid date: ${dateString}`);
      return ''; // Devuelve una cadena vacía si la fecha no es válida
    }
    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    };
    const formattedDate = new Intl.DateTimeFormat('es-ES', options).format(
      date
    );
    return formattedDate.replace(' de ', ' de ').replace(' del ', ' del ');
  }

  formatTime(time: string): string {
    if (!time) {
      return '';
    }
    const [hours, minutes] = time.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12 AM
    return `${this.padZero(formattedHours)}:${this.padZero(minutes)} ${period}`;
  }

  private padZero(num: number): string {
    return num.toString().padStart(2, '0');
  }

  /**
   * Valida diversos casos para determinar el tipo de cita médica.
   *
   * @returns {string} - Devuelve un código que representa el tipo de caso médico, que puede ser uno de los siguientes:
   *   - 'case1': Cita para un usuario existente que es mayor de edad.
   *   - 'case2': Cita para un usuario que no existe y es mayor de edad.
   *   - 'case3': Cita para un menor de edad que no existe y no tiene un acudiente registrado.
   *   - 'case4': Cita para un menor de edad que no existe, pero tiene un acudiente registrado.
   *   - 'case5': Cita para un menor de edad que existe, pero no tiene un acudiente registrado.
   *   - 'case6': Cita para un menor de edad que existe y tiene un acudiente registrado.
   *   - 'caseError': Caso de error, se recomienda recargar la página.
   */
  validateCases(): string {
    // Caso 1 - Cita para un usuario existente que es mayor de edad
    if (
      !this.userCase &&
      !this.guardianCase &&
      this.relationshipAcudiente === ''
    ) {
      return 'case1';
    }
    // Caso 2 - Cita para un usuario que no existe y es mayor de edad
    else if (
      this.userCase &&
      !this.guardianCase &&
      this.relationshipAcudiente === ''
    ) {
      return 'case2';
    }
    // Caso 3 - Cita para un menor de edad que no existe y no tiene un acudiente registrado
    else if (
      this.userCase &&
      this.guardianCase &&
      this.relationshipAcudiente === ''
    ) {
      return 'case3';
    }
    // Caso 4 - Cita para un menor de edad que no existe, pero tiene un acudiente registrado
    else if (
      this.userCase &&
      !this.guardianCase &&
      this.relationshipAcudiente !== ''
    ) {
      return 'case4';
    }
    // Caso 5 - Cita para un menor de edad que existe, pero no tiene un acudiente registrado
    else if (
      !this.userCase &&
      this.guardianCase &&
      this.relationshipAcudiente === ''
    ) {
      return 'case5';
    }
    // Caso 6 - Cita para un menor de edad que existe y tiene un acudiente registrado
    else if (
      !this.userCase &&
      !this.guardianCase &&
      this.relationshipAcudiente !== ''
    ) {
      return 'case6';
    }

    // Caso de error, se recomienda recargar la página
    alert('Error: recarga la página');
    return 'caseError';
  }

  validateCasesPet(): string {
    // Caso 1 - El usuario dueño y la mascota no existen.
    if (this.userCase && this.petCase && !this.petSelected) {
      return 'case1';
    } else if (
      !this.userCase &&
      this.petCase &&
      this.pets &&
      this.pets.length > 0
    ) {
      return 'case3';
    } else if (!this.userCase && this.petCase) {
      return 'case2';
    } else if (!this.userCase && !this.petCase && this.petSelected) {
      return 'case4';
    }

    // Caso de error, se recomienda recargar la página
    alert('Error: recarga la página');
    return 'caseError';
  }

  /**
   * Método del ciclo de vida de Angular que se ejecuta después de que Angular haya inicializado todas las directivas de un componente.
   * Se utiliza para realizar inicializaciones adicionales y configuraciones necesarias cuando el componente se crea.
   *
   * Incrementa el contador de pasos de entrada (`pasosInput`) en 1 y luego valida los casos médicos llamando a la función `validateCases`.
   * El resultado se almacena en la propiedad `case` y se muestra en la consola.
   *
   * Inicializa el formulario de usuario llamando al método `iniciarUserForm`.
   * Si se ha seleccionado una sucursal (`branchSelected.branchId` tiene un valor), se establece la propiedad `inputData.branchId` y se obtienen los sub-servicios activos llamando al servicio `getAppointmentsActiveSubservices`.
   * Se suscribe al servicio `statesNgrxService.getDataGlobalUser()` para obtener datos globales del usuario y almacena el ID del usuario en la propiedad `globaluserId`.
   * Llama al servicio `userService.getUserByDocument` para obtener el ID del paciente usando el número de documento.
   * Si hay un número de documento para un acudiente (`documentNumberAcudiente`), llama al servicio `userService.getUserByDocument` para obtener el ID del acudiente y lo almacena en `appoimentDetails.attendant`.
   */
  ngOnInit(): void {
    if (this.tempAppointment) {
      this.income = false;
      this.iniciarTempUserForm1();
    }

    // Incrementa el contador de pasos de entrada en 1
    this.pasosInput = this.pasosInput + 1;

    // Valida los casos médicos y almacena el resultado en la propiedad 'case'
    if (this.appointmentType === 'Veterinary') {
      this.case = this.validateCasesPet();
    } else {
      this.case = this.validateCases();
    }

    // Inicializa el formulario de usuario
    if (!this.tempAppointment) {
      this.iniciarUserForm();
    }

    this.activeSubService = [];
    this.doctors = [];

    // Verifica si se ha seleccionado una sucursal
    if (this.branchSelected.branchId) {
      // Establece la sucursal en 'inputData.branchId'
      this.inputData.branchId = this.branchSelected.branchId;

      // Obtiene los sub-servicios activos llamando al servicio 'getAppointmentsActiveSubservices'

      this.appointmentsService
        .getAppointmentsActiveSubservices(this.branchSelected.branchId)
        .then((resp) => {
          this.activeSubService = [];
          if (resp.body) {
            const nData: AppointmentActiveSubservice[] = [...resp.body];
            if (this.inputSubServiceId) {
              this.activeSubService = nData.filter(
                (s) => s.subServiceId === this.inputSubServiceId
              );
              this.citasForm.patchValue({
                servicio: this.inputSubServiceId,
              });
            } else {
              this.activeSubService = nData;
            }
          }
        });

      if (this.inputSubServiceId && this.activeSubService) {
        const foundSubService = this.activeSubService.find(
          (s) => s.subServiceId === this.inputSubServiceId
        );
        if (foundSubService) {
          this.activeSubService = [foundSubService];
        }
      }

      // Suscribe al servicio 'statesNgrxService.getDataGlobalUser()' para obtener datos globales del usuario
      this.statesNgrxService.getDataGlobalUser().subscribe((resp: any) => {
        this.globaluserId = resp._id;
        this.infoAppointment.nit = resp.roles.find(
          (role: any) => role.rol === 'provider'
        )?.data?.documentNumber;
      });

      // Llama al servicio 'userService.getUserByDocument' para obtener el ID del paciente usando el número de documento
      this.userService.getUserByDocument(this.documentNumber).then((resp) => {
        if (resp.ok) {
          if (resp.body.exists) {
            this.patient = resp.body._id;
            // Asignar datos del paciente a infoPatient
            this.infoPatient.nombres = resp.body.names;
            this.infoPatient.apellidos = resp.body.lastNames;
            this.infoPatient.genero = resp.body.genero;
            this.infoPatient.fechaNacimiento = resp.body.birthDate;
            this.infoPatient.numeroDocumento = resp.body.numeroDocumento;
            this.infoPatient.phone = resp.body.cellPhones[0].phone;
          }
        }
      });

      // Verifica si hay un número de documento para un acudiente y llama al servicio correspondiente
      if (
        this.documentNumberAcudiente &&
        this.documentNumberAcudiente.length > 0
      ) {
        this.userService
          .getUserByDocument(this.documentNumberAcudiente)
          .then((resp) => {
            if (resp.ok) {
              // Almacena el ID del acudiente en 'appoimentDetails.attendant'
              this.appoimentDetails = {
                attendant: resp.body._id,
              };
            }
          });
      }
    }
  }

  checkIfDoctorExists(resp: string): boolean {
    if (this.appointments && this.appointments.length > 0) {
      const doctorFound = this.appointments.some(
        (appointment) => appointment.doctor.doctorId === resp
      );
      return doctorFound;
    }
    return false;
  }

  /**
   * Inicializa el formulario de citas ('citasForm') con los campos de servicio, médico y motivo.
   * Suscribe cambios en el campo 'servicio' para actualizar la lista de médicos y realizar otras acciones relacionadas.
   * Suscribe cambios en el campo 'medico' para actualizar la información del médico seleccionado.
   */
  iniciarUserForm(): void {
    // Crea el formulario de citas
    this.confirmCita = false;
    this.citasForm = new FormGroup({
      servicio: new FormControl<string | null>(null, Validators.required),
      medico: new FormControl<string | null>(null, Validators.required),
      motivo: new FormControl<string | null>(null, Validators.required),
    });

    // Suscribe cambios en el campo 'servicio'
    this.citasForm.controls['servicio'].valueChanges.subscribe((resp) => {
      if (this.setSchedule) return;

      this.inputData.subServiceId = undefined!;
      this.citasForm.controls['medico'].setValue(null);

      this.doctorSelected = null;
      this.endPointCalendar = null;
      this.activeSubServiceSelected = this.activeSubService.find(
        ({ subServiceId }) => subServiceId === resp
      );

      if (this.activeSubServiceSelected) {
        this.infoAppointment.price = this.activeSubServiceSelected.price;
        this.infoAppointment.discountedPrice =
          this.activeSubServiceSelected.discountedPrice;
      } else {
        this.infoAppointment.price = 0;
        this.infoAppointment.discountedPrice = 0;
      }

      this.doctors = [];
      // Actualiza 'inputData.subServiceId' con el valor seleccionado en 'servicio'
      this.inputData.subServiceId = resp;
      // Obtiene la lista de médicos asociados al sub-servicio seleccionado
      setTimeout(() => {
        this.doctors = this.activeSubServiceSelected?.doctors || [];
        // Transforma la lista de médicos a un formato adecuado para su visualización
        this.doctors = this.doctorNames([...this.doctors]);
        // Si 'income' está presente, inicializa la información de ingresos
        if (this.income) {
          try {
            this.iniciarIncome(
              resp,
              this.doctors[0].doctorId || '',
              this.branchSelected.branchId || ''
            )
              .then((resp) => {
                // Almacena la información de ingresos seleccionada
                this.incomeSelected = resp;
              })
              .catch((err) => {});
          } catch (error) {
            this.swalService.lauchSwal(
              'Error',
              'No se encontraron horarios para el dia de hoy',
              'error'
            );
          }
        }
      }, 200);

      // Realiza un desplazamiento suave hacia el campo 'medico' después de un breve retraso
      setTimeout(() => {
        this.hacerScroll('idInputMedico');
      }, 500);
    });

    // Suscribe cambios en el campo 'medico'
    this.citasForm.controls['medico'].valueChanges.subscribe((resp) => {
      const doctorExists = this.checkIfDoctorExists(resp);
      if (doctorExists) {
        this.doctorSelected = null;
        this.swalService.lauchSwal(
          'Médico no permitido',
          'Ya tienes una cita programada con este profesional de la salud. Si deseas continuar, selecciona otro médico.',
          'warning'
        );
      } else {
        // Actualiza 'inputData.doctorId' con el valor seleccionado en 'medico'
        this.inputData.doctorId = resp;
        this.endPointCalendar = resp;

        // Actualiza la información del médico seleccionado
        this.doctorSelected = this.doctors.find((doc) => {
          if (doc.doctorId === resp) {
            this.infoAppointment.doctor = doc.names + ' ' + doc.lastNames;
            return true;
          }
          return false;
        });

        setTimeout(() => {
          this.hacerScroll('idInputMotivo');
        }, 500);
      }
    });
  }

  iniciarTempUserForm1(): void {
    const hasTempAppointment =
      this.tempAppointment &&
      this.tempAppointment.subServiceId &&
      this.tempAppointment.doctorId;

    let { appointmentDate, branchId, doctorId, hour, subServiceId } =
      this.tempAppointment;

    // Crea el formulario de citas
    this.confirmCita = false;
    this.citasForm = new FormGroup({
      servicio: new FormControl<string | null>(null, Validators.required),
      medico: new FormControl<string | null>(null, Validators.required),
      motivo: new FormControl<string | null>(null, Validators.required),
    });

    // Espera 5 segundos antes de setear el servicio
    setTimeout(() => {
      if (hasTempAppointment) {
        this.citasForm.controls['servicio'].setValue(
          this.tempAppointment.subServiceId
        );
        this.citasForm.controls['medico'].setValue(
          this.tempAppointment.doctorId
        );
        this.citasForm.controls['motivo'].setValue(
          this.tempAppointment.appointmentReason
        );

        let hoursAvailable: any[] = [];

        this.inputData.doctorId = this.tempAppointment.doctorId;
        this.endPointCalendar = this.tempAppointment.doctorId;

        this.apiService
          .getRequest(
            `search/schedule/${subServiceId}/${doctorId}/${branchId}/${
              appointmentDate.split('T')[0]
            }`
          )
          .subscribe(async ({ ok, body }) => {
            if (!ok) return;

            body.forEach((_hours: any) => hoursAvailable.push(..._hours.hours));

            let hourAvailable = hoursAvailable.find(
              (_hour) => _hour.hour == hour
            );

            if (hourAvailable?.available) {
              let appoinment = {
                ...hourAvailable,
                date: appointmentDate.split('T')[0],
              };
              this.calendarEvent(appoinment);
            } else {
              console.debug('Select other hour is not available');
              this.doctorSelected = {
                avatar: this.tempAppointment.doctorDetails.avatar,
                cover: this.tempAppointment.doctorDetails.cover,
                doctorId: this.tempAppointment.doctorId,
                doctorNames:
                  this.tempAppointment.doctorDetails.names +
                  ' ' +
                  this.tempAppointment.doctorDetails.lastnames,
                names: this.tempAppointment.doctorDetails.names,
                lastNames: this.tempAppointment.doctorDetails.lastnames,
              };

              setTimeout(() => {
                console.debug(this.customCalendarComponentRef);
                this.customCalendarComponentRef.forceShowDailyHour({
                  date: this.tempAppointment.appointmentDate.split('T')[0],
                  hour: this.tempAppointment.hour,
                });
              }, 1000);
            }
          });

        // `search/schedule/${subServiceId}/${doctorId}/${branchId}/${dayClicked}`
      }
    }, 1000);

    // Suscribe cambios en el campo 'servicio'
    this.citasForm.controls['servicio'].valueChanges.subscribe((resp) => {
      if (this.setSchedule) return;

      this.inputData.subServiceId = undefined!;
      this.citasForm.controls['medico'].setValue(null);

      this.doctorSelected = null;
      this.endPointCalendar = null;
      this.activeSubServiceSelected = this.activeSubService.find(
        ({ subServiceId }) => subServiceId === resp
      );

      if (this.activeSubServiceSelected) {
        this.infoAppointment.price = this.activeSubServiceSelected.price;
        this.infoAppointment.discountedPrice =
          this.activeSubServiceSelected.discountedPrice;
      } else {
        this.infoAppointment.price = 0;
        this.infoAppointment.discountedPrice = 0;
      }

      this.doctors = [];
      // Actualiza 'inputData.subServiceId' con el valor seleccionado en 'servicio'
      this.inputData.subServiceId = resp;

      // Obtiene la lista de médicos asociados al sub-servicio seleccionado
      setTimeout(() => {
        this.doctors = this.activeSubServiceSelected?.doctors || [];
        // Transforma la lista de médicos a un formato adecuado para su visualización
        this.doctors = this.doctorNames([...this.doctors]);
        // Si 'income' está presente, inicializa la información de ingresos

        if (this.income) {
          try {
            this.iniciarIncome(
              resp,
              this.doctors[0].doctorId || '',
              this.branchSelected.branchId || ''
            )
              .then((resp) => {
                // Almacena la información de ingresos seleccionada
                this.incomeSelected = resp;
              })
              .catch((err) => {});
          } catch (error) {
            this.swalService.lauchSwal(
              'Error',
              'No se encontraron horarios para el dia de hoy',
              'error'
            );
          }
        }
      }, 200);

      // Realiza un desplazamiento suave hacia el campo 'medico' después de un breve retraso
      setTimeout(() => {
        this.hacerScroll('idInputMedico');
      }, 500);
    });

    // Suscribe cambios en el campo 'medico'
    this.citasForm.controls['medico'].valueChanges.subscribe((resp) => {
      // Actualiza 'inputData.doctorId' con el valor seleccionado en 'medico'
      this.inputData.doctorId = resp;
      this.endPointCalendar = resp;

      // Actualiza la información del médico seleccionado
      this.doctorSelected = this.doctors.find((doc) => {
        if (doc.doctorId === resp) {
          this.infoAppointment.doctor = doc.names + ' ' + doc.lastNames;
          return true;
        }
        return false;
      });

      setTimeout(() => {
        this.hacerScroll('idInputMotivo');
      }, 500);
    });
  }

  /**
   * Agrega la propiedad 'doctorNames' a cada objeto en la lista 'doctors',
   * que es una combinación de las propiedades 'names' y 'lastNames'.
   *
   * @param {AppointmentDoctor[]} doctors - Lista de objetos 'AppointmentDoctor'.
   * @returns {AppointmentDoctor[]} - Nueva lista con la propiedad 'doctorNames' agregada.
   */
  doctorNames(doctors: AppointmentDoctor[]): AppointmentDoctor[] {
    // Mapea cada objeto 'doctor' en la lista
    return doctors.map((doctor) => ({
      // Crea un nuevo objeto con todas las propiedades del objeto 'doctor' original
      ...doctor,
      // Agrega la propiedad 'doctorNames' que es la combinación de 'names' y 'lastNames'
      doctorNames: `${doctor.names} ${doctor.lastNames}`,
    }));
  }

  /**
   * Valida la fecha ingresada en relación con la fecha actual y un límite en meses.
   *
   * @param {string | null} fecha - Cadena de texto que representa una fecha.
   * @param {number} meses - Número de meses que se utilizan para establecer el límite.
   * @returns {string | null} - La fecha válida si pasa las comprobaciones, de lo contrario, devuelve null.
   */
  validarFecha(fecha: string | null, meses: number): string | null {
    // Si la fecha no está presente, devuelve null
    if (!fecha) {
      return null;
    } else {
      // Obtiene la fecha actual
      const hoy = new Date();
      // Convierte la cadena de texto 'fecha' a un objeto de fecha
      const fechaIngresada = new Date(fecha);
      // Crea una nueva fecha que representa el límite en meses
      const fechaLimite = new Date();
      fechaLimite.setMonth(hoy.getMonth() + meses);
      hoy.setDate(hoy.getDate() - 1);

      // Comprueba si la fecha ingresada está dentro de los límites establecidos
      if (fechaIngresada < hoy || fechaIngresada > fechaLimite) {
        // Establece un error en el control de formulario 'fecha' si la fecha no es válida
        this.citasForm?.controls['fecha'].setErrors({ mesesError: true });
        return null;
      } else {
        // Devuelve la fecha si pasa las comprobaciones
        return fecha;
      }
    }
  }

  /**
   * Realiza un desplazamiento suave hacia un elemento en la página.
   *
   * @param {string} id - Identificador del elemento al que se desea desplazar.
   * @param {number} [margen] - Margen opcional para ajustar la posición del desplazamiento (por defecto: 100).
   */
  hacerScroll(id: string, margen?: number): void {
    // Obtiene el elemento objetivo por su identificador
    const elementoObjetivo = document.getElementById(id);

    // Si el elemento objetivo existe
    if (elementoObjetivo) {
      // Establece el margen superior (por defecto: 100)
      const margenSuperior = margen ? margen : 100;

      // Obtiene la posición del elemento objetivo con respecto a la ventana gráfica
      const posicion = elementoObjetivo.getBoundingClientRect().top;

      // Obtiene el contenedor de desplazamiento (puede ser el modal en este caso)
      const modalUser = document.getElementById('modal_user') as HTMLElement;

      // Calcula la posición a la que se desplazará el contenedor
      const scrollTarget = modalUser.scrollTop + posicion - margenSuperior;

      // Realiza el desplazamiento suave
      modalUser.scrollTo({
        top: scrollTarget,
        behavior: 'smooth',
      });
    }
  }

  getFechaCita(date: any): string {
    const fecha = new Date(date);
    fecha.setDate(fecha.getDate() + 1);
    return fecha.toLocaleDateString('es-CO', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  }

  getFechaIncome(date: any): string {
    const fecha = new Date(date);
    fecha.setDate(fecha.getDate());
    return fecha.toLocaleDateString('es-CO', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  }

  selectOption(value: boolean) {
    this.income = value;
    this.iniciarUserForm();
  }

  channgeOption(ev: any) {
    const value = ev.target.checked;
    this.income = value;
    this.iniciarUserForm();
  }

  convertirHoraAMPM(hora24: string): string {
    console.log('convertirHoraAMPM');

    const partesHora = hora24.split(':');
    const hora = parseInt(partesHora[0]);
    const minutos = parseInt(partesHora[1]);
    const ampm = hora < 12 ? 'AM' : 'PM';
    const hora12 = hora % 12 === 0 ? 12 : hora % 12;
    return `${hora12.toString().padStart(2, '0')}:${minutos
      .toString()
      .padStart(2, '0')} ${ampm}`;
  }

  convertirHora24(hora24: string): string {
    const partesHora = hora24.split(':');
    const hora = parseInt(partesHora[0]);
    const minutos = parseInt(partesHora[1]);
    return `${hora.toString().padStart(2, '0')}:${minutos
      .toString()
      .padStart(2, '0')}`;
  }

  errorNoData() {
    this.citasFormValid().then();
  }

  @Input() show: boolean = false;
  loadingHours: boolean = false;
  hoursData!: any[];

  calculateDuration(): void {
    const selectedHour = this.appointment?.hour;
    const initialHour = selectedHour?.substring(0, 2) + ':00';

    const hourBlock = this.hoursData.find(
      (block) => block.initialHour === initialHour
    );
    if (hourBlock) {
      const hoursArray = hourBlock.hours;
      const duration = 60 / hoursArray.length;
      this.infoAppointment.duration = duration;
    }
  }

  getHours(endPoint: string): void {
    if (!endPoint) return;
    this.show = true;
    this.loadingHours = true;
    this.apiService.getRequest(endPoint).subscribe((rs) => {
      setTimeout(() => {
        this.loadingHours = false;
        if (!rs) return;
        if (rs.ok) this.hoursData = rs.body;
        this.calculateDuration();
      }, 1000);
    });

    // return Array.from(Array(12).keys());
  }

  setDataAppointment(cita: {
    date: string;
    hour: string;
    office: string;
    shiftId: string;
    branchId: string;
  }) {
    if (this.appointmentType === 'Veterinary') {
      this.appointment = {
        appointmentDate: cita.date,
        hour: cita.hour,
        office: cita.office,
        shiftId: cita.shiftId,
        branchId: cita.branchId,
        whoScheduled: this.globaluserId,
        doctorId: this.citasForm.value.medico,
        subServiceId: this.citasForm.value.servicio,
        appointmentReason: this.citasForm.value.motivo,
        platform: 'web',
        patientType: 'pets',
        receiptNumber: this.generateidAppointment(),
      };

      if (this.patient) {
        // this.appointment = { ...this.appointment, patient: this.patient };
        this.appointment = {
          ...this.appointment,
          appointmentDetails: { attendant: this.patient },
        };
      }
      if (this.petSelected) {
        this.appointment = {
          ...this.appointment,
          patient: this.petSelected._id,
        };
      }
    } else {
      this.appointment = {
        appointmentDate: cita.date,
        hour: cita.hour,
        office: cita.office,
        shiftId: cita.shiftId,
        branchId: cita.branchId,
        whoScheduled: this.globaluserId,
        doctorId: this.citasForm.value.medico,
        subServiceId: this.citasForm.value.servicio,
        appointmentReason: this.citasForm.value.motivo,
        platform: 'web',
        patientType: 'members',
        receiptNumber: this.generateidAppointment(),
      };

      if (this.patient) {
        this.appointment = { ...this.appointment, patient: this.patient };
      }
      if (this.appoimentDetails) {
        this.appointment = {
          ...this.appointment,
          appointmentDetails: { attendant: this.appoimentDetails.attendant },
        };
      }
      this.getInfoAppointment();
    }
    // this.getHours(
    //   `search/schedule/${this.inputData.subServiceId}/${this.inputData.doctorId}/${this.inputData.branchId}/${this.appointment.appointmentDate}`
    // );
    this.citasForm.disable();
    this.citasForm.controls['medico'].setValue(this.endPointCalendar);
    this.confirmCita = true;
  }

  iniciarIncome(subServiceId: string, doctorId: string, branchId: string) {
    const nDate = new Date();
    const nDateString = this.applicationService.obtenerFechaAAAAMMDD(nDate);
    const nHourString = this.applicationService.obtenerHoraFormato24(nDate);

    this.fechaIncomeText = this.applicationService.formatearFechayHora(nDate);

    // Retornamos la promesa
    return this.appointmentsService
      .getAppointmentsSchedule(subServiceId, doctorId, branchId, nDateString)
      .then((resp) => {
        if (!resp) {
          this.citasForm.reset();
          return this.swalService.lauchSwal(
            'Error !!',
            `No hay profesionales disponibles para este servicio en la fecha (${nDateString}). Por favor, seleccione otra fecha o servicio. Gracias.`,
            'error'
          );
        }

        if (!resp.ok) {
          this.citasForm.reset();
          return this.swalService.lauchSwal('Error !!', resp.message, 'error');
        }

        if (resp.ok && resp.body[0].hours.length > 0) {
          const nShiftId = resp.body[0].hours[0].shiftId;
          const nOffice = resp.body[0].hours[0].office;
          return {
            shiftId: nShiftId,
            office: nOffice,
            date: nDateString,
            hour: nHourString,
          };
        } else {
          throw new Error('No se encontraron disponibles');
        }
      })
      .catch((err) => {
        // Lanza el error o maneja el caso de error
        console.error(err);
        this.errorCita = err.error.message;
        throw err; // Puede modificar esto según cómo quiera manejar los errores
      });
  }

  calendarEvent(cita: any) {
    this.setSchedule = true;

    this.citasFormValid().then((resp) => {
      if (resp) {
        this.setDataAppointment(cita);

        setTimeout(() => {
          const checkboxElement = document.getElementById(
            'basic_checkbox_orden'
          ) as HTMLInputElement;
          if (checkboxElement) {
            const event = new Event('change');
            checkboxElement.checked = true;
            this.onCheckboxChange({ target: checkboxElement });
          } else {
          }
        }, 0);
      }
    });
  }

  onCheckbox(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.ordenDescuento = inputElement.checked;
  }

  async citasFormValid(): Promise<boolean> {
    if (this.citasForm.invalid) {
      this.citasForm?.markAllAsTouched();
      const requiredFields = ['servicio', 'medico', 'motivo'];
      const swalResult = await this.swalService.lauchSwalAsync(
        '',
        'Debes registrar los datos en rojo para continuar',
        'warning'
      );
      if (swalResult) {
        setTimeout(() => {
          const scrollTo = requiredFields.find(
            (field) => this.citasForm?.controls[field].invalid
          );
          if (scrollTo) {
            this.hacerScroll(
              `idInput${scrollTo.charAt(0).toUpperCase() + scrollTo.slice(1)}`
            );
          }
        }, 300);
      }
      return false;
    } else {
      return true;
    }
  }

  generateidAppointment(): string {
    const array = 'abcdefghijkmnpqrtuvwxyzABCDEFGHJKMNPQRTUVWXYZ012346789';
    let caracter = '';
    for (let i = 0; i < 9; i++) {
      caracter += array.charAt(Math.floor(Math.random() * array.length));
    }
    return caracter + new Date().getMilliseconds();
  }
  // Prueba git

  cancelarCita() {
    this.setSchedule = false;
    this.confirmCita = false;
    this.citasForm.reset();
    this.citasForm.enable();
  }

  getInfoPatient() {
    this.infoPatient.nombres = this.user
      ? this.user.names
      : this.userCase?.nombres;
    this.infoPatient.apellidos = this.user
      ? this.user.lastNames
      : this.userCase?.apellidos;
    this.infoPatient.numeroDocumento = this.documentNumber;

    if (!this.infoPatient.phone) {
      this.infoPatient.phone = this.userCase?.cellPhones?.[0]?.phone;
    }
  }

  getInfoAppointment() {
    this.infoPet = this.petSelected ? this.petSelected : this.petCase;
    this.infoAppointment.fechaCita = this.appointment?.appointmentDate;
    this.infoAppointment.hour = this.appointment?.hour;
    this.infoAppointment.idCita = this.appointment?.receiptNumber;
    this.infoAppointment.motivoCita = this.appointment?.appointmentReason;

    this.infoAppointment.duration = '20 min';

    this.infoAppointment.neighbor =
      this.branchSelected.addressSettings.neighbor;
    this.infoAppointment.municipality =
      this.branchSelected.municipality?.cityName;
    this.infoAppointment.address = this.branchSelected.addressSettings.address;
    this.infoAppointment.entity = this.branchSelected.name;

    if (this.branchSelected.cellPhones) {
      this.infoAppointment.cellphone = this.branchSelected.cellPhones[0].phone;
    }

    this.infoAppointment.subservice =
      this.activeSubServiceSelected?.subServiceName;
    // this.infoAppointment.price = this.activeSubServiceSelected?.price;
    // this.infoAppointment.discountedPrice =
    //   this.activeSubServiceSelected?.discountedPrice;
  }

  async confirmarCita() {
    const result = await Swal.fire({
      title: 'Estás seguro?',
      text: 'Se va a registrar la cita!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, registrar cita!',
      cancelButtonText: 'Cancelar',
    });

    if (result.isConfirmed === true && this.ordenDescuento) {
      this.getInfoAppointment();
      this.captureTicket();
    }

    if (result.isConfirmed && this.appointment) {
      if (this.case === 'case1') {
        this.appointmentCase = {
          action: 'case1',
          appointment: this.appointment,
        };
      } else if (this.case === 'case2' && this.userCase) {
        this.appointmentCase = {
          action: 'case2',
          appointment: this.appointment,
          user: this.userCase,
        };
      } else if (this.case === 'case3') {
        if (this.userCase && this.userCase.registerData) {
          const nUserCase = {
            ...this.userCase,
            registerData: {
              ...this.userCase.registerData,
              beneficiary: {
                relationship: this.relationshipUser,
              },
            },
          };
          this.appointmentCase = {
            action: 'case3',
            appointment: this.appointment,
            user: nUserCase as UserAppointment,
            guardian: this.guardianCase,
          };
        }
      } else if (this.case === 'case4') {
        const nAppointment: Appointment = {
          ...this.appointment,
          appointmentDetails: this.appoimentDetails,
        };
        if (this.userCase && this.userCase.registerData) {
          const nUserCase = {
            ...this.userCase,
            registerData: {
              ...this.userCase.registerData,
              beneficiary: {
                relationship: this.relationshipAcudiente,
              },
            },
          };
          this.appointmentCase = {
            action: 'case4',
            appointment: nAppointment,
            user: nUserCase as UserAppointment,
          };
        }
      } else if (this.case === 'case5') {
        this.appointmentCase = {
          action: 'case5',
          appointment: this.appointment,
          guardian: this.guardianCase,
        };
      } else if (this.case === 'case6') {
        const nAppointment: Appointment = {
          ...this.appointment,
          appointmentDetails: this.appoimentDetails,
        };

        this.appointmentCase = {
          action: 'case6',
          appointment: nAppointment,
        };
      }

      if (this.appointmentCase) {
        this.appointmentCase = this.appService.limpiarObjeto(
          this.appointmentCase
        ) as AppointmentCase;
        this.appointmentsService
          .postAppointmentsBranch(this.appointmentCase)
          .then((resp) => {
            if (resp.ok) {
              // if (this.ordenDescuento) {
              //   let appointmentResponse:AppointmentResponse = {
              //     ...this.appointmentCase!.appointment,
              //     appointmentDetails:undefined
              //   }

              //   if (this.guardianCase) {
              //     appointmentResponse.appointmentDetails={
              //       attendant:{
              //         attendantId:this.guardianCase.registerData.
              //       }

              //     }
              //   }
              // }

              if (this.income) {
                this.socket.emit('activeAppointment', {
                  appointmentId: resp.body._id,
                });
              }
              this.modalService.closeModal();
              this.swalService.lauchSwalTimer('Cita registrada con exito!!');
            }
          })
          .catch((err) => {}).finally;
      }
    }
  }

  async confirmarCitaPets() {
    const result = await Swal.fire({
      title: 'Estás seguro?',
      text: 'Se va a registrar la cita para la mascota!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, registrar cita!',
      cancelButtonText: 'Cancelar',
    });

    if (result.isConfirmed === true && this.ordenDescuento) {
      this.getInfoAppointment();
      this.captureTicket();
    }

    if (result.isConfirmed && this.appointment) {
      if (this.case === 'case1') {
        this.appointmentCase = {
          action: 'case1',
          appointment: this.appointment,
          guardian: this.userCase,
          pet: this.petCase,
        };
      } else if (this.case === 'case2' || this.case === 'case3') {
        this.appointmentCase = {
          action: this.case,
          appointment: this.appointment,
          pet: this.petCase,
        };
      } else if (this.case === 'case4') {
        this.appointmentCase = {
          action: 'case4',
          appointment: this.appointment,
        };
      } else {
      }
    }

    if (this.appointmentCase) {
      this.appointmentCase = this.appService.limpiarObjeto(
        this.appointmentCase
      ) as AppointmentCase;
      this.appointmentsService
        .postAppointmentsBranchPets(this.appointmentCase)
        .then((resp) => {
          if (resp.ok) {
            if (this.income) {
              this.socket.emit('activeAppointment', {
                appointmentId: resp.body._id,
              });
            }
            this.modalService.closeModal();
            this.swalService.lauchSwalTimer('Cita registrada con exito!!');
          }
        })
        .catch((err) => {});
    }
  }

  confirmarIncome() {
    this.citasFormValid().then((resp) => {
      if (resp) {
        if (this.incomeSelected) {
          this.setDataAppointment({
            branchId: this.branchSelected.branchId || '',
            date: this.incomeSelected.date,
            hour: this.incomeSelected.hour,
            office: this.incomeSelected.office,
            shiftId: this.incomeSelected.shiftId,
          });
          if (this.appointmentType === 'Veterinary') {
            this.confirmarCitaPets();
          } else {
            this.confirmarCita();
          }
        }
      }
    });
  }

  onCheckboxChange(event: any) {
    const inputElement = event.target as HTMLInputElement;
    this.ordenDescuento = inputElement.checked;

    // this.ordenDescuento = event.target.checked;

    this.getInfoPatient();
    this.getInfoAppointment();
  }
}
