import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/local-storage.service';

export const redirectionGuard: CanActivateFn = (route, state) => {

  const localStorageService = inject(LocalStorageService); 
  const router = inject(Router);
  const sessionData = localStorageService.getItem('session');
  const rol = JSON.parse(sessionData!)['rol'];

  if (rol === 'user') { 
    router.navigate(['/user/']);
    return true;
  } else if(rol === 'advisor') {
    router.navigate(['/advisor/']);
    return true;
  } else if (rol === 'provider') {
    router.navigate(['/provider']);
    return true
  } else if (rol === 'doctor') {
    router.navigate(['/doctor']);
    return true
  } else {
    router.navigate(['/auth/login']);
    return true;
  }
    
};
