<div class="task__container">
  <h2 class="title">Información de la cita: Historial</h2>
  <div class="task__data">
    <div class="task__data__profile">
      <img src="" alt="dataInfo.nombres" [ngSrc]="'https://www.prevenirexpress.com:7000/avatar/'+ dataInfo.avatarUser" width="200" height="200">
      <span>{{ dataInfo.nombres }}</span>
      <span><strong>Identificación: </strong>{{ dataInfo.numeroDocumento }}</span>
    </div>

    <div class="task__data__info">
      <span class="task__data__info__item" *ngIf="dataInfo.fechaNacimiento">
        <strong>Fecha Nacimiento: </strong>{{dataInfo.fechaNacimiento|date}}
      </span>
      <span class="task__data__info__item" *ngIf="dataInfo.direccion">
        <strong>Dirección: </strong>{{dataInfo.direccion}}
      </span>
      <span class="task__data__info__item" *ngIf="dataInfo.barrio">
        <strong>Barrio: </strong>{{dataInfo.barrio}}
      </span>
      <span class="task__data__info__item" *ngIf="dataInfo.telefono">
        <strong>Teléfono: </strong>{{dataInfo.telefono}}
      </span>
      <span class="task__data__info__item" *ngIf="dataInfo.nombreSubservicio">
        <strong>Cita Para: </strong>{{dataInfo.nombreSubservicio}}
      </span>
      <span class="task__data__info__item" *ngIf="dataInfo.hora">
        <strong>Hora Cita: </strong>{{dataInfo.hora}}
      </span>
      <span class="task__data__info__item" *ngIf="dataInfo.nombresMedico">
        <strong>Médico: </strong>{{dataInfo.nombresMedico}}
      </span>
      <span class="task__data__info__item" *ngIf="dataInfo.motivoCita">
        <strong>Motivo cita: </strong>{{dataInfo.motivoCita}}
      </span>
    </div>
  </div>
</div>
