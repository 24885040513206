import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs/internal/Observable';
import { userData } from 'src/app/auth/state/auth.selectors';
// import { selectRol } from '../session/state/session.actions';
// import { RolSession } from 'src/app/interfaces/auth.interface';
import { ModalService } from 'src/app/reusable-modal/modal.service';

@Component({
  selector: 'app-rol-selector',
  templateUrl: './rol-selector.component.html',
  styleUrls: ['./rol-selector.component.scss']
})
export class RolSelectorComponent implements OnInit {

  userData$: Observable<any> = this.store.pipe(select(userData));

  constructor(private store: Store,
              private modalService: ModalService) {}

  ngOnInit(): void {
    // throw new Error('Method not implemented.');
    // this.openModal();
  }

  initSession() {

  }

  selectRol(rolData: any ) {
    // this.store.dispatch(selectRol({rolData}));
    this.modalService.closeModal();
  }

  // openModal(data:any) {
  //   this.modalService.openModal({data});
  // }

}
