import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ControlContainer, FormControl, FormGroupDirective, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';

import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'form-email',
  templateUrl: './form-email.component.html',
  styleUrls: ['../../form-styles.scss', './form-email.component.scss']
})
export class FormEmailComponent implements OnInit {
  @Input() controlName!: string;
  @Input() placeholder: string = 'Default placeholder';
  @Input() helper: string | undefined = undefined;
  @Input() label: string = 'Default label';
  @Input() required: boolean = true;
  @Input() endPointValidator: string = 'user/check-email/';
  @Input() idInput: string = 'idInput';
  @Input() customErrors: Record<string, string> = {};
  @Input() labelColor!: string;
  @Input() labelFontSize: string = '1rem'


  private regexEmail = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9]){1,}$/

  public validable: boolean = true;
  public initData = ''
  public validating: boolean = false;
  public validEmail: boolean = false;
  public errors: any[] = [];
  public value = '';

  private debouncetime: any

  constructor(private apiService: ApiService, private controlContainer: ControlContainer) { }

  get control(): FormControl {
    const parentFormGroup = this.controlContainer as FormGroupDirective;
    return parentFormGroup.control.get(this.controlName) as FormControl;
  }

  get controlInvalid(): boolean {
    return this.control?.invalid || false;
  }

  get controlTouched(): boolean {
    return this.control?.touched || false;
  }

  get errorKeys(): string[] {
    return this.control?.errors ? Object.keys(this.control.errors) : [];
  }
  ngOnInit(): void {
    if (this.control.value.length > 0) {
      this.value = this.control.value
      this.initData = this.control.value
    }
  }

  ngAfterViewInit(): void {
    let existingValidators = this.control.validator ? [this.control.validator] : []; // Get the existing validators of the control

    existingValidators.push(this.emailExistAsyncValidator()); // Add the custom validator to the existing validator array

    let combinedValidators: ValidatorFn | null = Validators.compose(existingValidators); // Combine the validators into a single ValidatorFn using Validators.compose

    // Assign the combined validators to the FormControl
    this.control.setValidators(combinedValidators);

    this.control.statusChanges.subscribe(() => {

      if (!this.control.errors || !this.control.dirty) return
      // this.errors = [];

      if (this.customErrors) {
        let hasErrors = Object.keys(this.customErrors).filter((err) =>
          Object(this.control.errors).hasOwnProperty(err)
        );
        hasErrors.forEach(
          (error) =>
            this.control.errors &&
            this.control.errors[error] &&
            this.errors.push(this.customErrors[error])
        );
      }

      this.control.errors['required'] && this.errors.push(`El campo es requerido`)
      this.control.errors['email'] && this.errors.push(`El correo electrónico debe ser valida, Ej: nombre@ejemplo.com`)
      this.control.errors['existMail'] && this.errors.push(`El correo ${this.control.value} ya se encuentra registrado`)

    });

    // setTimeout(() => this.control.updateValueAndValidity(), 100);
  }

  /** Async method to validate email if this exist and validate the format  */
  emailExistAsyncValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      this.validEmail = false;
      let emailValue = ''
      this.errors = [];

      if (!control.value) return of(null)

      emailValue = (control.value as string).trim().toLowerCase()

      if (this.initData === emailValue && control.untouched) {
        this.validable = false
        control.markAsTouched();
        this.control.setErrors(null)
        this.control.updateValueAndValidity()
        return of(null)
      }

      this.validable = true

      const emailFormatError = !this.regexEmail.test(emailValue) ? { email: true } : null;

      if (emailFormatError) {
        this.control.setErrors(emailFormatError)
        return of(null)
      }

      this.validating = true

      let data = `${this.endPointValidator}${emailValue}`

      clearTimeout(this.debouncetime)
      this.debouncetime = setTimeout(() => {
        this.apiService.getRequest(data).subscribe((rs: any) => {
          let { body: { existe } } = rs
          this.validating = false
          if (existe && emailValue != this.value) {
            this.control.setErrors({ existMail: true })
            return of(null)
          } else {
            this.validEmail = true
            this.control.setErrors(null)
            return of(null)
          }
        })
      }, 1000)

      return of(null)
    };
  }
}