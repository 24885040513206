import { Injectable } from '@angular/core';
import { ApiRes } from '@interfaces/information.interface';
import { StatesNgrxService, SweetAlertService } from '@services';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs/internal/Subscription';

/**
 * Servicio para la gestión de eventos a través de sockets.
 *
 * @class
 * @@Injectable
 */
@Injectable({
  providedIn: 'root',
})
export class SocketsService {
  private swalOPtions = {
    text: '',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Sí, confirmar',
    cancelButtonText: 'Cancelar',
    confirmButtonColor: 'var(--primary)',
    title: '',
    icon: 'success',
  };

  private subscription: Subscription = new Subscription();

  /**
   * Constructor del servicio SocketsService.
   *
   * @constructor
   * @param {Socket} socket - Instancia de Socket proporcionada por ngx-socket-io.
   */
  constructor(
    private socket: Socket,
    private swalService: SweetAlertService,
    private statesNgrxService: StatesNgrxService
  ) {}

  /**
   * Emite un evento a través del socket.
   *
   * @param {string} evento - Nombre del evento a emitir.
   * @param {any} info - Información a enviar con el evento.
   */
  emit(evento: string, info: any) {
    this.socket.emit(evento, info);
  }

  /**
   * Obtiene un Observable para escuchar un evento específico.
   *
   * @param {string} evento - Nombre del evento a escuchar.
   * @returns {Observable<any>} - Observable que emite datos cuando ocurre el evento.
   */
  getEvent(evento: any) {
    return this.socket.fromEvent(evento);
  }

  /**
   * Escucha eventos de tipo 'error-message' y emite un Observable con los mensajes de error.
   *
   * @returns {Observable<ApiRes>} - Observable que emite mensajes de error recibidos del servidor.
   */
  listenForErrors(): Observable<any> {
    return new Observable<ApiRes>((observer) => {
      this.socket.on('error-message', (errorMessage: any) => {
        observer.next(errorMessage);
      });
    });
  }

  manageSocketAppointments(
    action: 'finalize' | 'cancel' | 'confirm',
    appointmentId: string
  ) {
    if (action === 'cancel') {
      const idSubscription = this.statesNgrxService
        .getDataGlobalUser()
        .subscribe((resp: any) => {
          const whoCancelled = resp._id;
          this.cancelAppointment(appointmentId, whoCancelled);
          this.subscription.add(idSubscription);
        });
    } else if (action === 'confirm') {
      this.confirmAppointment(appointmentId);
    } else {
      this.finalizeAppointment(appointmentId);
    }
  }

  manageSocketTemporalAppointments(
    action: 'finalize' | 'cancel' | 'confirm',
    appointmentId: string,
    reason: string
  ): void {
    if (action === 'cancel') {
      const idSubscription = this.statesNgrxService
        .getDataGlobalUser()
        .subscribe((resp: any) => {
          const whoCancelled = resp._id;
          this.cancelTempAppointment(appointmentId, whoCancelled, reason);
          this.subscription.add(idSubscription);
        });
    }
    else if(action === 'confirm') {
      this.completedTempAppointment(appointmentId);
    }
    // else {
    //   this.finalizeAppointment(appointmentId);
    // }
  }

  public unsubscribe() {
    this.subscription.unsubscribe();
  }

  private async confirmAppointment(appointmentId: string) {
    this.swalOPtions = {
      ...this.swalOPtions,
      title: 'Estás seguro?',
      icon: 'warning',
      text: '¡El paciente entrara al consultorio!',
    };
    const result = await this.swalService.launchConfirmSwal(this.swalOPtions);

    if (result) this.emit('underAttentionAppointment', { appointmentId });
  }

  private async finalizeAppointment(appointmentId: string) {
    this.swalOPtions = {
      ...this.swalOPtions,
      title: 'Cita éxitosa',
      icon: 'success',
      text: '¡Se va a finalizar la cita!',
    };
    const result = await this.swalService.launchConfirmSwal(this.swalOPtions);

    if (result) this.emit('finishAppointment', { appointmentId });
  }

  private async cancelAppointment(appointmentId: string, whoCancelled: string) {
    this.swalOPtions = {
      ...this.swalOPtions,
      title: 'Estás seguro?',
      icon: 'warning',
      text: '¡Se va a Eliminar el ingreso!',
    };
    const result = await this.swalService.launchConfirmSwal(this.swalOPtions);

    if (result) this.emit('cancelAppointment', { appointmentId, whoCancelled });
  }

  private async completedTempAppointment(temporalAppointmentId: string) {
    this.swalOPtions = {
      ...this.swalOPtions,
      title: 'Cita Completada',
      icon: 'success',
      text: '¡Se va a completado la cita!',
    };
    const result = await this.swalService.launchConfirmSwal(this.swalOPtions);

    if (result) this.socket.emit('completedTemporalAppointment', {
      temporalAppointmentId: temporalAppointmentId,
    });
  }

  private async cancelTempAppointment(
    temporalAppointmentId: string,
    authorizedId: string,
    reason: string
  ) {
    this.swalOPtions = {
      ...this.swalOPtions,
      title: 'Estás seguro?',
      icon: 'warning',
      text: '¡Se va a Eliminar el ingreso!',
    };
    const result = await this.swalService.launchConfirmSwal(this.swalOPtions);

    if (result)
      this.socket.emit('cancelledTemporalAppointment', {
        temporalAppointmentId: temporalAppointmentId,
        whoCancelled: authorizedId,
        reason: reason,
      });
  }

  /**
   * Conecta el socket si está desconectado.
   */
  connect() {
    this.socket.connect();
  }

  /**
   * Desconecta el socket si está conectado.
   */
  disconnect(): void {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
}
