<div class="day__container">
  <h2 class="title">Agenda</h2>
  <h4 class="date"><strong>{{dateInfo}}</strong></h4>
  <div class="day__data">
    <ng-container *ngFor="let dayinfo of dataInfo">
      <div class="day__data__item" (click)="hideDataInfo(); showDataInfo($event, dayinfo)" [ngClass]="dayinfo.status.type">
        <img class="day__data__item__image" [ngSrc]="dayinfo.patient.avatar" width="72" height="72" />
        <span class="day__data__item__names ellipsis__text">{{ dayinfo.patient.names }} {{ dayinfo.patient.lastNames}}</span>
        <span class="day__data__item__office ellipsis__text">{{ dayinfo.office }}</span>
        <span class="day__data__item__hour">{{ dayinfo.hour | formatHour }}</span>
        <span class="day__data__item__status">{{ dayinfo.status.type == 'cancelled' ? 'Cancelado' : 'Activa' }}</span>
      </div>
    </ng-container>
  </div>

  <div class="patient__container" #patientDataRef>
    <i class="fa fa-close fa-2x close" (click)="hideDataInfo()" title="Cerrar"></i>
    <div class="patient__data" *ngIf="patientData">
      <img class="patient__data__image" [src]="patientData.image" width="160" height="160" />

      <div class="patient__data__info">
        <div class="patient__data__info__row">
          <span class="patient__data__info__label" id="label-identification">Identificación</span>
          <span class="patient__data__document" aria-labelledby="label-identification">{{ patientData.document }}</span>
        </div>

        <div class="patient__data__info__row">
          <span class="patient__data__info__label" id="label-name">Nombres</span>
          <span class="patient__data__name" aria-labelledby="label-name">{{ patientData.name }}</span>
        </div>

        <div class="patient__data__info__row">
          <span class="patient__data__info__label" id="label-phones">Contacto</span>
          <span class="patient__data__phone" aria-labelledby="label-phones">
            <ng-container *ngFor="let cellphone of patientData.phones">
              <span>{{ cellphone.phone }}</span>
            </ng-container>
          </span>
        </div>

        <div class="patient__data__info__row">
          <span class="patient__data__info__label" id="label-office">Consultorio</span>
          <span class="patient__office" aria-labelledby="label-office">{{ patientData.office }}</span>
        </div>

        <div class="patient__data__info__row">
          <span class="patient__data__info__label" id="label-status">Estado</span>
          <span class="patient__status" aria-labelledby="label-status">Cita {{ patientData.status == 'cancelled' ? 'cancelada' : 'activa' }}</span>
        </div>

        <div class="patient__data__info__row" *ngIf="patientData.status == 'cancelled' && patientData?.reason">
          <span class="patient__data__info__label" id="label-reason">Motivo</span>
          <span class="patient__reason" aria-labelledby="label-reason">{{ patientData.reason }}</span>
        </div>
      </div>

    </div>
  </div>
</div>