import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss']
})
export class PasswordInputComponent {

  @Input() placeholder = '';
  @Input() classInput = '';
  @Input() classDiv = '';
  @Output() inputPassword: EventEmitter<string> = new EventEmitter<string>();

  password?: string;
  passwordVisible: boolean = false;

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }

  digitar(e: any) {
    this.inputPassword.emit(String(e.target.value));
  }

  onPasswordChange(newValue: string) {
    this.inputPassword.emit(newValue);
  }

  paste(e: any) {
    console.log(e);
  }
}
