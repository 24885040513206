import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ver-ayudas',
  templateUrl: './ver-ayudas.component.html',
  styleUrls: ['./ver-ayudas.component.scss']
})
export class VerAyudasComponent {

  @Input() iconSize = '20px'
  @Input() fontSize = '15px'
  @Input() iconClass = 'mdi mdi-airplay'
  @Input() texto = 'Ver Ayuda'

}
