<span class="input__group">
  <div class="box__label">
    <span class="input__label">{{label}}<strong *ngIf="required"> *</strong></span>
    <i class="fa fa-question icon__helper" [title]="helper" *ngIf="helper"></i>
  </div>

  <div class="box__input" id="radio-group">
    <div class="radios__container" [class.bordered]="bordered" [ngClass]="{invalid: control.touched && control.invalid}">
      <span class="radio__item" *ngFor="let radio of data">
        <input type="radio" id="rad_{{radio.id}}" name="radio-group" (click)="checkRadio(radio)" [checked]="isChecked(radio)">
        <label class="ellipsis__text" for="rad_{{radio.id}}" [title]="radio.label">{{radio.label }}</label>
      </span>
      <input type="hidden" [formControl]="control">
    </div>
  </div>

  <div class="box__error" [ngClass]="{visible:errors.length}">
    <ul>
      <li class="box__error__item" *ngFor="let error of errors"><strong>* </strong>{{error}}</li>
    </ul>
  </div>
</span>