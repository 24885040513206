import { Injectable } from '@angular/core';
import { Image } from '@interfaces/information.interface';
import { ApiService } from 'src/app/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private apiService: ApiService) { }
 
  getRoleInformation(idData: string, role: string) {
    return this.apiService.getRequest(`user/${idData}/${role}`);
  }

  getDefaultAvatars(){
    return this.apiService.getRequest('images/default-avatars');
  }

  postImagesAvatar(form:FormData,role:string){    
    return this.apiService.postRequestFile(`images/avatar/${role}`,form);
  }

  putImagesAvatar (data:any,role:string){
    return this.apiService.putRequest(`images/avatar/${role}`,data);
  }

  deleteImagesRol (images:Image[], role:string){
    return this.apiService.putRequest(`images/avatar/delete/${role}`, images);
  }

}