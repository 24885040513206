<div *ngIf="!albumSelected" class="album_container animated fadeIn">
  <div class="album_card pointer mr-2" *ngIf="createAlbum">
    <button class="btn img_album btn_agregar_album" title="Agregar un nuevo álbum"
      [ngStyle]="{ 'width': sizeAlbumPx+'px', 'height': sizeAlbumPx+'px' }">
      <i class="ti ti-plus icon-no-underline"></i>
    </button>
    <div class="album_name">Crear álbum</div>
  </div>

  <div *ngFor="let album of albums" class="album_card pointer">
    <div class="img_container">
      <img class="img_album" [ngStyle]="{ 'width': sizeAlbumPx+'px', 'height': sizeAlbumPx+'px' }"
        [src]="album.cover_photo|getImage" (click)=" selectAlbum(album)"
        [title]="'Abrir álbum '+getNameAlbum(album.name||'Sin nombre')">
      <button *ngIf="optionsAlbum" class="btn btn-circle btn_back btn_back_options btn_align" [attr.album-data]="album"
        (click)="opcionesAlbum(album,$event)" type="button" title="Opciones">
        <i class="ti ti-more-alt i_90"></i>
      </button>

      <!-- <custom-buttons *ngIf="optionsAlbum" class="btn btn-circle btn_back btn_back_options btn_align"
        [attr.album-data]="album" (click)="opcionesAlbum(album,$event)" type="button" text="" textColor="#fff"
        [title]="'Opciones'" icon="ti ti-more-alt">
        <i class="ti ti-more-alt i_90"></i>
      </custom-buttons> -->
      <div class="option_container">
        <!-- <span class="option_container_item">Ocultar álbum</span> -->
        <span class="option_container_item" (click)=" selectAlbum(album)">Abrir el álbum</span>
        <span class="option_container_item" (click)="vaciarAlbum(album)">Vaciar álbum</span>
        <span *ngIf="album.editable" class="option_container_item">Cambiar nombre</span>
        <span *ngIf="album.editable" class="option_container_item">Eliminar álbum</span>
      </div>
    </div>

    <div class="album_name" [ngStyle]="{ 'width': sizeAlbumPx+'px'}" [title]="getNameAlbum(album.name||'Sin nombre')"
      (click)=" selectAlbum(album)">{{getNameAlbum(album.name||'Sin nombre')}}
    </div>
    <span class="album_length" (click)=" selectAlbum(album)">{{album.images.length }} elemento<span
        *ngIf="album.images.length!=1">s</span></span>
  </div>
</div>

<div *ngIf="albumSelected" class="animated fadeIn">
  <div class="album_header">
    <button type="button" class="btn btn-circle btn_back" (click)="regresarAlbums()" title="Regresar"><i
        class="ti ti-arrow-left"></i> </button>

    <!-- <custom-buttons type="button" class="btn btn-circle btn_back" (click)="regresarAlbums()" text="" textColor="#fff"
      [title]="'Regresar'" icon="ti ti-arrow-left">
      <i class="ti ti-arrow-left"></i>
    </custom-buttons> -->
    <div class="album_header_title">{{getNameAlbum(albumSelected.name||'Sin nombre')}}</div>

    <div *ngIf="optionsImages" class="btn_align animated fadeInRight">
      <!-- <span class="fw_400">Opciones </span>      -->
      <button class="icon-options btn_vertical btn-group ml-auto btn btn_back btn_back_options_image"
        [disabled]="optionsImagesView" style="width: 45px;
            height: 30px;" title="Opciones" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>

      <!-- <custom-buttons class="icon-options btn_vertical btn-group ml-auto btn btn_back btn_back_options_image"
        [disabled]="optionsImagesView" style="width: 45px;
          height: 30px;" text="" textColor="#fff" [title]="'Opciones'" data-toggle="dropdown" aria-haspopup="true"
        aria-expanded="false">
      </custom-buttons> -->
      <div class="dropdown-menu dropdown-menu-right icon_options_adjust" style="z-index: 99999999;">
        <span class="dropdown-item pointer" (click)="selectedImages()">Seleccionar imágenes</span>
        <span class="dropdown-item pointer" (click)="vaciarAlbum()">Vaciar álbum</span>
      </div>
    </div>

  </div>
  <span class="album_length"><span *ngIf="imagesSelected.length>0">{{imagesSelected.length}} /</span>
    {{albumSelected.images.length}} elemento<span *ngIf="albumSelected.images.length!=1">s</span></span>
  <div *ngIf="selectImages" class="row select_options animated fadeIn fast">
    <div class="col-4 item_option" title="Seleccionar todas las imágenes" (click)="selectAll()"><i *ngIf="!selectedAll"
        class="mdi mdi-image-multiple icon_all_images"></i><i *ngIf="selectedAll"
        class="mdi mdi-image-filter icon_deselect_images"></i> {{ selectedAll?'Deseleccionar todo':'Seleccionar todo'}}
    </div>
    <div class="col-4 item_option" title="Cancelar la selección" (click)="cancelSelection()"><i
        class="mdi mdi-close icon_close"></i> Cancelar</div>
    <div class="col-4 item_option" [class.item_option_disabled]="imagesSelected.length==0" (click)="deleteSelected()"
      title="Eliminar las imágenes seleccionadas"><i class="fa fa-trash-o icon_delete"></i> Eliminar</div>
  </div>
  <div class="album_container">
    <div *ngFor="let image of albumSelected.images; let i = index" class="image-wrapper">
      <div *ngIf="selectImages" class="image-checkbox-wrapper">
        <input type="checkbox" [checked]="isChecked(image)" [id]="'basic_checkbox_'+image._id"
          class="filled-in image-checkbox" (change)="onCheckboxChange($event, image)">
        <label [for]="'basic_checkbox_'+image._id"></label>
      </div>
      <img [src]="image.path|getImage" [ngStyle]="{ 'height': sizeImagePx+'px' }" [title]="titleImage"
        class="img_images pointer" (click)="selectImage(image,i)">
    </div>

  </div>
</div>
