import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HolidaysService {
  private holidays = [
    { date: "01/01", nextMonday: false, name: "Año Nuevo" },
    { date: "01/06", nextMonday: true, name: "Día de los Reyes Magos" },
    { date: "03/19", nextMonday: true, name: "Día de San José" },
    { daysToSum: -3, nextMonday: false, name: "Jueves Santo" },
    { daysToSum: -2, nextMonday: false, name: "Viernes Santo" },
    { date: "05/01", nextMonday: false, name: "Día del Trabajo" },
    { daysToSum: 40, nextMonday: true, name: "Ascensión del Señor" }, // Ascension 40 days after easter
    { daysToSum: 60, nextMonday: true, name: "Corphus Christi" }, // Corpus christi 60 days after easter
    { daysToSum: 71, nextMonday: true, name: "Sagrado Corazón de Jesús" }, // Sacred Heart 71 days after Easter
    { date: "06/29", nextMonday: true, name: "San Pedro y San Pablo" },
    { date: "07/20", nextMonday: false, name: "Día de la Independencia" },
    { date: "08/07", nextMonday: false, name: "Batalla de Boyacá" },
    { date: "08/15", nextMonday: true, name: "La Asunción de la Virgen" },
    { date: "10/12", nextMonday: true, name: "Día de la Raza" },
    { date: "11/01", nextMonday: true, name: "Todos los Santos" },
    { date: "11/11", nextMonday: true, name: "Independencia de Cartagena" },
    { date: "12/08", nextMonday: false, name: "Día de la Inmaculada Concepción" },
    { date: "12/25", nextMonday: false, name: "Día de Navidad" }
  ];

  /**
   * Applies the two-digit format to a number less than ten
   * @param dataNumber
   * @returns {string} formatted text
   */
  private applyTwoDigits(dataNumber: number) {
    return dataNumber < 10 ? "0" + dataNumber : dataNumber;
  }

  /**
  * Applies the DD/MM/YYYY format to a date
  * @param {Date} date object with date to format
  * @returns {string} formatted date text
  */
  private formatDate(date: Date) {
    return `${this.applyTwoDigits(date.getDate())}/${this.applyTwoDigits(date.getMonth() + 1)}/${date.getFullYear()}`;
  }

  /**
  * Algorithm proposed by Ian Stewart in 2001 to calculate the exact date of Resurrection/Easter Sunday
  * @param {number} year year number
  * @returns {Date} Returns on Easter/Resurrection Sunday
  */
  private getEasterSunday(year: number) {
    let a, b, c, d, e, day;
    a = year % 19;
    b = year % 4;
    c = year % 7;
    d = (19 * a + 24) % 30;
    e = (2 * b + 4 * c + 6 * d + 5) % 7;
    day = 22 + d + e;

    if (day >= 1 && day <= 31) {
      return new Date(`03/${this.applyTwoDigits(day)}/${year}`);
    } else {
      return new Date(`04/${this.applyTwoDigits(day - 31)}/${year}`);
    }
  }

  /**
  * Calculates the next Monday of a given date
 * @param {Date} date date of departure
 * @returns {Date} returns next Monday to the date
  */
  private getNextMonday(date: Date) {
    while (date.getDay() !== 1) date.setDate(date.getDate() + 1);
    return date;
  }

  /**
  * Adds a number of days to a given date
  * @param {string} stringDate date object
  * @param {number} dayToSum number of days to add
  * @returns {Date} returns the new date with the days added
  */
  private sumDay(stringDate: string, dayToSum: number) {
    let date = new Date(stringDate);
    date.setDate(date.getDate() + dayToSum);
    return date;
  }

  /**
   *Calculates and returns the list of holidays for a given year
   * @param {number} year year number
   * @returns {Array} Array with all the holidays of the year
   */
  public getHolidaysByYear(year: number) {
    let holidaysMap: Record<string, any> = {};
    let easterSunday = this.getEasterSunday(year);

    this.holidays.forEach(element => {
      let date;
      if (!element.daysToSum) {
        date = new Date(element.date + "/" + year);
      } else {
        date = this.sumDay(easterSunday.toDateString(), element.daysToSum);
      }

      if (element.nextMonday) date = this.getNextMonday(date);

      const holiday = { date: this.formatDate(date), name: element.name, static: element.nextMonday }
      holidaysMap[holiday.date] = holiday
    });

    return holidaysMap;
  }

  /**
   * Calculates all holidays for a range of years
  * @param {number} initialYear range start year
  * @param {number} finalYear final year of rank
  * @returns {Array} Array with all the holidays of the year
   */
  public getHolidaysByYearInterval(initialYear: number, finalYear: number) {
    let holidaysArray = [];
    for (let i = initialYear; i <= finalYear; i++) {
      let year: { year: any, holidays: any[] } = {
        year: i,
        holidays: []
      };

      let easterSunday = this.getEasterSunday(i);

      this.holidays.forEach(element => {
        let date;
        if (!element.daysToSum) {
          date = new Date(element.date + "/" + i);
        } else {
          date = this.sumDay(easterSunday.toDateString(), element.daysToSum);
        }

        if (element.nextMonday) {
          date = this.getNextMonday(date);
        }
        year.holidays.push({
          date: this.formatDate(date),
          name: element.name,
          static: element.nextMonday
        });
      });
      holidaysArray.push(year);
    }

    return holidaysArray;
  }

  /**
 * Calculate if a specific day is a holiday
 * @param {Date} date Date that is sought to know if it is a holiday or not.
 * @returns {Boolean} Boolean indicating whether or not it is a holiday.
 */
  public isHoliday(date: Date) {
    return !!this.getHolidaysByYear(date.getFullYear())[this.formatDate(date)]
  }

  /**
  * @deprecated
  * Calculates and returns the list of holidays for a given year (old version that returns  array)
  * @param {number} year year number
  * @returns {Array} Array with all the holidays of the year
  */
  public _getHolidaysByYear(year: number) {
    let holidaysArray: any[] = [];
    let easterSunday = this.getEasterSunday(year);

    this.holidays.forEach(element => {
      let date;
      if (!element.daysToSum) {
        date = new Date(element.date + "/" + year);
      } else {
        date = this.sumDay(easterSunday.toDateString(), element.daysToSum);
      }

      if (element.nextMonday) {
        date = this.getNextMonday(date);
      }
      holidaysArray.push({
        date: this.formatDate(date),
        name: element.name,
        static: element.nextMonday
      });
    });

    return holidaysArray;
  }

  /**
 * @deprecated
 * Calculate if a specific day is a holiday (old with array return)
 * @param {Date} date Date that is sought to know if it is a holiday or not.
 * @returns {Boolean} Boolean indicating whether or not it is a holiday.
 */
  public _isHoliday(date: Date) {
    // return !!this.getHolidaysByYear(date.getFullYear()).find((holiday) => holiday.date == this.formatDate(date))
  }
}


