import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-form-section',
  templateUrl: './form-section.component.html'
})
export class FormSectionComponent {

  @Input() number: string = '';
  @Input() sectionName: string = '';
  @Input() classDiv: string = '';

}
