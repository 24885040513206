import { Component, Input } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, FormGroupDirective, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

@Component({
  selector: 'form-password',
  templateUrl: './form-password.component.html',
  styleUrls: ['../../form-styles.scss', './form-password.component.scss']
})
export class FormPasswordComponent {
  @Input() controlName!: string;
  @Input() placeholder: string = 'Default placeholder';
  @Input() helper: string | undefined = undefined;
  @Input() enableHelper: boolean = false;
  @Input() label: string = 'Default label';
  @Input() labelColor!: string;
  @Input() labelFontSize: string = '1rem'
  @Input() showStrengthmeter: boolean = true;
  @Input() required: boolean = true;


  errors: any[] = [];
  strength: any;
  value: any;

  showPassword: boolean = false;

  constructor(private controlContainer: ControlContainer) { }

  get control(): FormControl {
    const parentFormGroup = this.controlContainer as FormGroupDirective;
    return parentFormGroup.control.get(this.controlName) as FormControl;
  }

  get controlInvalid(): boolean {
    return this.control?.invalid || false;
  }

  get controlTouched(): boolean {
    return this.control?.touched || false;
  }

  get errorKeys(): string[] {
    return this.control?.errors ? Object.keys(this.control.errors) : [];
  }

  ngAfterViewInit(): void {
    let existingValidators = this.control.validator ? [this.control.validator] : []; // Get the existing validators of the control

    existingValidators.push(this.passwordStrengthValidator.bind(this)); // Add the custom validator to the existing validator array

    let combinedValidators: ValidatorFn | null = Validators.compose(existingValidators); // Combine the validators into a single ValidatorFn using Validators.compose

    // Assign the combined validators to the FormControl
    this.control.setValidators(combinedValidators);
    this.control.updateValueAndValidity();

    // //console.log(this.disabled);
    this.control.statusChanges.subscribe(() => {
      this.errors = [];
      if (!this.control.errors || !this.control.dirty) return

      this.control.errors['required'] && this.errors.push(`El campo es requerido`)
    });
  }

  private passwordStrengthValidator(control: AbstractControl): { [key: string]: any } | null {
    const passwordValue = control.value;
    if (!passwordValue) {
      this.strength = undefined;
      return null;  // No validation error if the field is empty
    }

    const minLength = 8;
    const minUpperCaseChars = 1;
    const minLowerCaseChars = 1;
    const minDigits = 1;
    const minSpecialChars = 1;

    // Count the different character types
    const upperCaseChars = /[A-Z]/g;
    const lowerCaseChars = /[a-z]/g;
    const digits = /[0-9]/g;
    const specialChars = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/g;

    const isLengthValid = passwordValue.length >= minLength;
    const isUpperCaseValid = (passwordValue.match(upperCaseChars) || []).length >= minUpperCaseChars;
    const isLowerCaseValid = (passwordValue.match(lowerCaseChars) || []).length >= minLowerCaseChars;
    const isDigitsValid = (passwordValue.match(digits) || []).length >= minDigits;
    const isSpecialCharsValid = (passwordValue.match(specialChars) || []).length >= minSpecialChars;

    // Calculate password strength score
    const passwordStrengthScore = (isLengthValid ? 1 : 0) + (isUpperCaseValid ? 1 : 0) + (isLowerCaseValid ? 1 : 0) + (isDigitsValid ? 1 : 0) + (isSpecialCharsValid ? 1 : 0);

    // Assign a label based on the score
    let passwordStrengthLabel: string;
    if (passwordStrengthScore === 0) {
      passwordStrengthLabel = 'Very Weak';
    } else if (passwordStrengthScore === 1) {
      this.strength = { strength: 'weak', label: 'débil' }
    } else if (passwordStrengthScore === 2) {
      this.strength = { strength: 'moderate', label: 'moderada' }
    } else if (passwordStrengthScore === 3) {
      this.strength = { strength: 'strong', label: 'fuerte' }
    } else {
      this.strength = { strength: 'very__strong', label: 'muy fuerte' }
    }

    return null;  // Always return null for this validator
  }
}
