import { Component, Input, OnInit} from '@angular/core';
import { Store } from '@ngrx/store';
import Swal from 'sweetalert2';
import { UserRole } from 'src/app/interfaces/auth.interface';
import { Album, Image } from 'src/app/interfaces/information.interface';
import { getRolSelect } from 'src/app/pages/session/state/session.selectors';
import { ProfileService } from 'src/app/roles/profile/profile.service';
import { StatesNgrxService } from 'src/app/services/states-ngrx.service';
import { RolInformationService } from 'src/app/roles/services/rol-information.service';
import { ModalService } from 'src/app/reusable-modal/modal.service';
import { SweetAlertService } from '@services';

@Component({
  selector: 'app-change-image',
  templateUrl: './change-image.component.html',
  styleUrls: ['./change-image.component.scss']
})
export class ChangeImageComponent implements OnInit {

  @Input()data:any;
  @Input()branchId?:string;
  
  perfilImage="";
  type!:string;
  user!: any;
  role!:string;

  //config
  titleBody='';
  textBody='';
  textAvatares='';

  avatars=[];
  covers=[];
  viewAvatares=true;
  viewAlbums=true;
  viewComputer=true;

  fileComponent?:File;

  imageDataUrl!: string;
  imageDrop = false;
  errorTipo = false;
  cargandoFile=false;
  actualizado=false;
  inputMaxSizeError=12;

  vista='vista1'
  option='illustration';

  constructor(
      private store: Store,
      private swalService: SweetAlertService, 
      private profileService: ProfileService,
      private rolInformationService:RolInformationService,
      private statesNgrxService:StatesNgrxService,
      private modalService:ModalService
    ){
      
    }

  ngOnInit(): void {  

    this.perfilImage = this.data.perfilImage;
    
    this.userSelector();  
    this.getDefaultAvatars()

    this.actualizado=false;

  }

  /**
   * Obtiene avatares predeterminados según el rol del usuario y carga información inicial.
   */
  getDefaultAvatars(){    
    
    this.profileService.getDefaultAvatars().subscribe((resp:any)=>{

      this.rolInformationService.getUserInformation().subscribe((roleInformation: any) => {      
        this.role = roleInformation.roleInformation.role;

        if ( this.role =='user') {
          const { avatars, covers } = resp.body['users']
          this.avatars = avatars;
          this.covers = covers;          
        } else if ( this.role =='provider') {
          const { avatars, covers } = resp.body['companies']
          this.avatars = avatars;
          this.covers = covers;          
        }  else if ( this.role =='doctor') {
          const { avatars, covers } = resp.body['doctors']
          this.avatars = avatars;
          this.covers = covers;          
        }  

      });       
      
    },(error)=>{
      //console.log(error);
      
    })
  }

  /**
   * Actualiza el avatar del usuario después de una confirmación.
   * @param {string} avatar - La ruta del nuevo avatar.
   */
  actualizarAvatar(avatar:string){
    Swal.fire({
      title: 'Estás seguro?',
      text: `Se va a actualizar tu foto`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, actualizar foto!',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {      

        const idAlbum =  this.user.albums.filter((album:Album)=>album.name==this.type)[0]._id; 
        let data : any = new Object();
        data.idAlbum=idAlbum;
        data.type=this.type;
        data.path=avatar;

        let role = '';

        this.statesNgrxService.getDataRolSelect().subscribe((resp:any)=>{
          if (resp.rol == 'user' || resp.rol =='doctor') {
            role = 'user';
            data.idUser = resp._idData;            
          } else if(resp.rol == 'provider'){
            role = 'company';
            data.companyId = resp._idData;            
          }
          // //console.log(resp);          
        });

        this.profileService.putImagesAvatar(data,role).subscribe(response=>{   
          this.perfilImage = response.body.path;          
          this.vista = 'vista1';

          this.statesNgrxService.updateGlobalUser();
          this.actualizado = true;
          // this.statesNgrxService.getDataGlobalUser().subscribe((resp:any)=>{      
          //   if (!resp) {
          //     //TODO: cerrar sesion
          //     return //console.log('Error');              
          //   }        

          // });
          
        },(error)=>{
          //console.log(error);      
        });        
      }
    });
    
  }

  /**
   * Selecciona la modalidad de imagen y actualiza la vista según el tipo seleccionado.
   */
  modalSelector(){
          

        if (this.data.clicked) {
          this.type = this.data.clicked;
          this.perfilImage = this.data.perfilImage || '';
          
          if(this.type=='Profile'){
            this.titleBody = 'Imagen de perfil';
            this.textBody = 'Si añades una foto, otras personas podrán reconocerte y sabrás si has iniciado sesión en tu cuenta';
            this.textAvatares = 'Avatares';
          } else if(this.type=='Branch'){
            this.titleBody = 'Imagen de la Sucursal';
            this.textBody = "Añadir una imagen de tu sucursal es útil para los usuarios, ya que les ayuda a identificar y localizar fácilmente tu establecimiento. Asegúrate de cargar una imagen clara y representativa.";
            this.viewAvatares=false;
            this.viewAlbums=false;
            this.option='computer';
            this.branchId=this.data.branchId;
            this.role = 'branch';
          } else{
            this.titleBody = 'Imagen de portada';
            this.textBody = 'Al cargar una foto de portada, podrás ser reconocido en nuestra plataforma. Personaliza tu experiencia añadiendo una imagen y disfruta de todas las ventajas de estar conectado.';
            this.textAvatares = 'Covers';
          }
  
          const imgPerfil = document.getElementById('imgPerfil');
          if (imgPerfil) {
            imgPerfil.classList.remove('rounded-image');
          }
          if (imgPerfil && this.type=='Profile') {
            imgPerfil.classList.add('rounded-image');
          }          
        }

  }

  /**
   * Maneja la selección del usuario y su rol.
   */
  userSelector() {
    this.modalSelector();

    setTimeout(()=>{  
      this.store.select(getRolSelect).subscribe((rolSelect: UserRole) => {
          const { _idData, rol } = rolSelect;
          this.role = rol;
          
          this.getRoleInformation(_idData, rol);
      });
    },200);   

  }  

  /**
   * Obtiene información específica del rol del usuario.
   * @param {string} idData - ID de datos del usuario.
   * @param {string} role - Rol del usuario.
   */
  getRoleInformation(idData: string, role: string) {
    this.profileService.getRoleInformation(idData, role).subscribe( (roleInformation: any) => {
      this.user = roleInformation.body;
      // //console.log(this.user);
    });  
    
  }

  /**
   * Limpia las propiedades y restablece la vista a su estado original.
   */
  cerrarLimpiar(){
    this.fileComponent= undefined;
    this.imageDataUrl='';
    this.imageDrop = false;
    this.errorTipo = false;
    this.cargandoFile=false;
    this.option='illustration';
    this.modalService.closeModal()
    setTimeout(()=>{
      this.vista='vista1';
    },200)
  }

  /**
 * Comprime una imagen si su tamaño excede un límite especificado.
 * @param {File} file - El archivo de imagen para comprimir.
 * @returns {Promise<File>} Una promesa que se resuelve con el archivo de imagen comprimido.
 */
async compressImage(file: File): Promise<File> {
  const maxFileSize = 3 * 1024 * 1024; // 3MB

  if (file.size <= maxFileSize) {
    return file;
  }

  const img = await this.loadImage(file);
  let scale = 1;
  let compressedBlob: Blob;

  do {
    scale -= 0.1; // Reduzca la escala en cada iteración

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = img.width * scale;
    canvas.height = img.height * scale;

    ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);

    compressedBlob = await new Promise<Blob>(resolve => {
      canvas.toBlob(blob => {
        if (blob) {
          resolve(blob);
        }
      }, file.type);
    });

    // Si la escala es demasiado pequeña y aún no se puede alcanzar el tamaño de archivo deseado, rompe el bucle
    if (scale < 0.1 && compressedBlob.size > maxFileSize) {
      console.error("No se puede comprimir la imagen al tamaño deseado");
      break;
    }
  } while (compressedBlob.size > maxFileSize);

  return new File([compressedBlob], file.name, { type: file.type });
}

  
/**
 * Carga un archivo de imagen y lo convierte en un elemento HTMLImageElement.
 * @param {File} file - El archivo de imagen para cargar.
 * @returns {Promise<HTMLImageElement>} Una promesa que se resuelve con el elemento de imagen cargado.
 */
loadImage(file: File): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = event.target?.result as string;
    };
    reader.readAsDataURL(file);
  });
}


  /**
   * Maneja el evento de arrastrar sobre el área de soltar.
   * @param {DragEvent} event - Evento de arrastrar.
   */
  onDragOver(event: DragEvent) {
    this.imageDrop=true;
    this.errorTipo=false;
    
    event.preventDefault();
    event.stopPropagation();
    var divDrop = document.getElementById('idDrop');
    if (divDrop) {
      divDrop.classList.add('border_drop');
      this.imageDrop=true;
    }
  }

  /**
   * Maneja el evento de dejar de arrastrar fuera del área de soltar.
   * @param {DragEvent} event - Evento de arrastrar.
   */
  onDragleave(event: DragEvent){
    var divDrop = document.getElementById('idDrop');
    if (divDrop) {
      divDrop.classList.remove('border_drop');
      this.imageDrop=false;
    }    
  }

  /**
   * Maneja el evento de soltar un archivo en el área de soltar.
   * @param {DragEvent} event - Evento de soltar.
   */
  onDrop(event: DragEvent) {
    this.errorTipo = false;
  
    event.preventDefault();
    event.stopPropagation();
  
    if (event.dataTransfer) {

      const maxFileSizeError = this.inputMaxSizeError * 1024 * 1024;
      if (event.dataTransfer.files[0].size > maxFileSizeError) {
        this.swalService.lauchSwal('',`la imagen no puede ser mayor a, ${this.inputMaxSizeError} Megabytes`,'error');
        var divDrop = document.getElementById('idDrop');
        if (divDrop) {
          divDrop.classList.remove('border_drop');
          this.imageDrop = false;
        }
        return;
      }

      var divDrop = document.getElementById('idDrop');
      if (divDrop) {
        divDrop.classList.remove('border_drop');
        this.imageDrop = false;
      }
  
      const file = event.dataTransfer.files[0];
      const tipo = file.type;
  
      if (["image/jpeg", "image/jpg", "image/png"].includes(tipo)) {
        this.compressImage(file).then(compressedFile => {
          this.processFile(compressedFile);
          this.fileComponent = compressedFile;
        });
      } else {
        this.errorTipo = true;
      }
    }
  }

  /**
   * Maneja la selección de un archivo mediante un selector de archivos.
   * @param {Event} event - Evento de selección de archivo.
   */
  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
  
    if (input.files) {

      const maxFileSizeError = this.inputMaxSizeError * 1024 * 1024;
      if (input.files[0].size > maxFileSizeError) {
        // //console.log(`la imagen no puede ser mayor a, ${this.inputMaxSizeError} MB` );
        this.swalService.lauchSwal('',`la imagen no puede ser mayor a, ${this.inputMaxSizeError} Megabytes`,'error');
        return;
      }

      const file = input.files[0];
      const tipo = file.type;
  
      if (["image/jpeg", "image/jpg", "image/png"].includes(tipo)) {
        this.compressImage(file).then(compressedFile => {
          this.processFile(compressedFile);
          this.fileComponent = compressedFile;
        });
      } else {
        this.errorTipo = true;
      }
    }
  }

  /**
   * Muestra el selector de archivos para elegir una imagen.
   */
  selectImage() {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = 'image/*';
    inputElement.addEventListener('change', (event: Event) => this.onFileSelected(event));
    inputElement.click();
    
  }

  /**
   * Procesa un archivo para mostrarlo como imagen previa antes de cargarlo.
   * @param {File} file - Archivo de imagen.
   */
  private processFile(file: File) {
    this.cargandoFile = true;
    
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (e.target) {        
        this.imageDataUrl = e.target.result as string;            
      }
    };
    reader.readAsDataURL(file);
    setTimeout(()=>{

      this.cargandoFile = false;
      this.vista ='vista3'
    },1000)
  }

  /**
   * Retorna a una vista específica y realiza una limpieza.
   * @param {string} vista - Vista a la que se debe retornar.
   */
  regresarYLimpiar(vista:string){
    this.fileComponent=undefined;
    this.vista = vista;
    this.imageDataUrl = '';
  }


  /**
   * Maneja el evento de emisión para cerrar y limpiar.
   * @param {boolean} send - Indica si se debe cerrar y limpiar.
   */
  sendEmitter(send:boolean){
    if(send){
      this.cerrarLimpiar();
    } else{
      alert('Error al guardar');
    }
    
  }

  /**
   * Maneja el evento de emisión de un nuevo avatar.
   * @param {string} sendImage - Ruta de la imagen enviada.
   */
  fileSendEmitter(sendImage:string){
    this.perfilImage = sendImage;
  }

  /**
   * Maneja el evento de emisión de selección de imagen.
   * @param {Image} image - Información de la imagen seleccionada.
   */
  imageEmitter(image:Image){
    // //console.log(image);
    this.actualizarAvatar(image.path);

    // this.getImageFromPath(image.path).then((imageBlob: Blob) => {
    //   this.fileComponent = new File([imageBlob], 'img3.jpg', { type: 'image/jpeg' });
    // });
    // this.actualizado=true;
    // this.vista='vista3';
  }

  /**
   * Obtiene una imagen desde una ruta mediante una solicitud HTTP.
   * @param {string} path - Ruta de la imagen.
   * @returns {Promise<Blob>} Promise que resuelve con la imagen como Blob.
   */
  getImageFromPath(path: string): Promise<Blob> {
    return fetch(path).then((response) => response.blob());
  }


}
