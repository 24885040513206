<div class="row tipo_centrar" [ngClass]="tipoClass">
    <div class="col-12 text-justify vertical_align">
        <div style="width: 95%;" >
            Elige los tipos de servicios que tu empresa ofrece. Haz clic en el icono
             <span class="text_bold" style="font-size: x-large;vertical-align: middle;">+</span> para añadir los tipos de servicio que deseas a tu lista.
        </div>
    </div>

    <div class="col-md-10 col-xs-12 mt-4">

        <div class="row horizon_align">

            <div class="col-5 ms-box">
                <div class="text-center tipo_title_options">Tipos de Servicio Disponibles:</div>
                <ul class="ms-list">
                    <ng-container *ngFor="let tipo of listaTipoProveedor">
                        <li class="ms-selected" *ngIf="!tipoProveedorSeleccionados.includes(tipo)"  (click)="agregarSeleccionado(tipo)">
                            <div class="actions">
                                <span class="type_text">{{ tipo.name }}</span>
                                <i class="fa fa-plus agregate" title="Agregar"></i>
                            </div>
                        </li>
                    </ng-container>
                </ul>
            </div>

            <div class="col-2 icon_vertical ms-box">
                <i class="fa fa-long-arrow-right"></i>
                <i class="fa fa-long-arrow-left"></i>

                <!-- <div class="row">
                            
                        </div>
                        <div class="row">
                        </div> -->

            </div>

            <div class="col-5 ms-box">
                <div class="text-center tipo_title_options">Tipos de Servicio Agregados:</div>
                <ul class="ms-list" [id]="idInput">
                    <li class="ms-selected" *ngFor="let tipo of tipoProveedorSeleccionados">


                        <div class="actions">
                            <span class="type_text">{{ tipo.name }}</span>
                            <i class="fa fa-close delete" (click)="eliminarSeleccionado(tipo)" title="Eliminar"></i>
                        </div>
                    </li>
                </ul>

            </div>

        </div>
    </div>

</div>