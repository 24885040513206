import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppointmentState } from './appointment.reducer';

export const appointmentFeatureKey = 'appointment';

const getAppointmentState = createFeatureSelector<AppointmentState>(appointmentFeatureKey);

export const getServiceDetails = createSelector(
    getAppointmentState,
    (state) => state.serviceDetails
);

export const getMunicipalityId = createSelector(
    getAppointmentState,
    (state) => state.municipalityId
);

export const getPending = createSelector(
    getAppointmentState,
    (state) => state.pending
)

export const GetLoginAppointment = createSelector(
    getAppointmentState,
    (state) => state.login
)
