
import { Component, EventEmitter, Input, Output, OnInit, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-card-subservice',
  templateUrl: './card-subservice.component.html',
  styleUrls: ['./card-subservice.component.scss'],
})

export class CardSubservice implements OnInit {

  @Input() caso: string = 'serviceUno' || 'serviceDos' || 'doctors';
  // CASO serviceUno
  @Input() subService: any;
  @Output() emitSubService = new EventEmitter<any>();
  // CASO serviceDos
  @Input() service: any;
  @Input() idService!: string;
  @Input() idCity!: string;
  @Output() changedService = new EventEmitter<any>();
  // CASO doctor
  @Input() doctors: any;
  // CASO familyGroup
  @Input() infoFamilyGroup: any;
  @Output() familyAction = new EventEmitter<any>();

  subServiceImg!: string;
  subServiceImgDefault: string = 'assets/images/default-service.png';

  isTrue: boolean = true;
  isFalse: boolean = false;

  constructor(private cdr: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    //console.log('data del input de la card caso serviceUno', this.subService);
    // console.log('data del input de la card caso serviceDos', this.service);
    //console.log('data del input de la card caso doctors', this.doctors);
    this.subServiceImg = this.subServiceImgDefault;
    this.loadRealImage();

  }

  // CASO  serviceUno:  cuando se usa en el html padre @Input() subService: 
  onEmitDataSubs(subServId: any) {
    this.emitSubService.emit(subServId);
  }
  // CASO serviceDos:   cuando se usa en el html padre @Input() service: 
  changedNewService(subServiceId: string, cityId: string) {
    this.changedService.emit({ subServiceId, cityId });
    console.log('se ejecuto el metodo desde la card');
  }

  actionButton(infoFamilyGroup:any, condition:boolean){
    this.familyAction.emit({infoFamilyGroup, condition});
  }

  actionButtonEdit(infoFamilyGroup:any){
    this.familyAction.emit({infoFamilyGroup});
  }


  loadRealImage() {
    const imagePath = this.subService?.subService?.subserviceImages[0]?.path;
    if (imagePath) {
        const img = new Image();
        img.onload = () => {
            this.subServiceImg = imagePath; // Imagen carga correctamente
        };
        img.onerror = () => {
            this.subServiceImg = this.subServiceImgDefault; // Error en carga, usar por defecto
        };
        img.src = imagePath; // Intentar cargar la imagen
    }
}


}


