import { AfterViewInit, Component, Input, HostListener, ViewChild, ElementRef, SimpleChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective } from '@angular/forms';
import { UserService } from '@services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'form-autocomplete',
  templateUrl: './form-autocomplete.component.html',
  styleUrls: ['../../form-styles.scss'],
})
export class FormAutocompleteComponent implements AfterViewInit, OnDestroy {

  @ViewChild('hidenInputRef') hidenInputRef!: ElementRef;
  @ViewChild('selectorListRef') selectorListRef!: ElementRef;

  @Input() controlName!: string;
  @Input() helper: string | undefined;
  @Input() placeholder: string = 'Autocomplete placeholder';
  @Input() label: string = 'Default autocomplete label';
  @Input() labelColor: string = '';
  @Input() labelView: boolean = true;
  @Input() shadowNone: boolean = false;
  @Input() paddingAdjust: boolean = false;
  @Input() borderRadiusInput: string = '4px'
  @Input() required: boolean = true;
  @Input() idField: string = '_id';
  @Input() valueField: string = 'name';
  @Input() idInput: string = 'idInput';
  @Input() data: any[] = [];
  @Input() sizeSplit = 4;
  @Input() set disabled(_disabled: boolean) {
    _disabled ? this.control.disable() : this.control.enable()
  };
  @Input() reset: boolean = false;

  private resetSubscription: Subscription;


  @Input()
  get dataList(): Record<string, any> { return this._dataList }
  set dataList(_dataList: any) {
    if (typeof _dataList === 'object') { // validate if is object the value entry
      let tmp: Record<string, any> = {}; // Define temporal variable to save record
      Array.from(_dataList).forEach((obj: any) => tmp[obj[this.idField]] = obj)  // Iterate the data for convert to Record
      this._dataList = tmp; // Establishes the new Recod data on variable to use
    } else {
      throw new Error(`Invalid data type to convert. Expected object, got ${typeof _dataList}`);
    }
  }

  @Output() seleccionEmitter: EventEmitter<any> = new EventEmitter<any>();
  


  private _dataList!: Record<string, any>

  errors: any[] = [];
  value: any = '';

  resultList: any[] = [];
  enableSuggestions = false;
  highlightItem = -1;

  selectedItem: boolean = false;
  searching: boolean = false;

  constructor(private controlContainer: ControlContainer,
              private resetService: UserService
    ) {

// Subscribirse al subject creado en el user-service
     this. resetSubscription =   this.resetService.resetObservable$.subscribe({
        next: (reset) => {
          if(reset) {
            this.clearSearch(true);
          }
        }
      })
     }

     

  ngOnDestroy(): void {
    this. resetSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dataList'] && changes['dataList'].currentValue) {
      this.dataList = changes['dataList'].currentValue.map((item: any) => ({ ...item, cantidad: 0 }));
    }
    if (changes['reset'] && changes['reset'].currentValue === true) {
      this.clearSearch(true); 
      this.reset = false; 
      //console.log('estado del reset', this.reset);
    }
  }

  
  /** Set data on control when already exist a value */
  ngOnInit(): void {
    // console.debug(this.dataList);
    if (this.control.value) {
      let item = this.control.value;
      this.selectItem(item);
    }
  }

  /** Listen to the status changes of the control and set the errors */
  ngAfterViewInit(): void {
    this.control.statusChanges.subscribe(() => {
      this.errors = [];
      if (!this.control.errors || !this.control.dirty || this.searching) return

      this.control.errors['required'] && this.errors.push(`El campo es requerido`)
      this.control.errors['minlength'] && this.errors.push(`El campo nó debe tener menos de ${this.control.errors['minlength'].requiredLength} caracteres`)
      this.control.errors['maxlength'] && this.errors.push(`El campo nó debe tener mas de ${this.control.errors['maxlength'].requiredLength} caracteres`)
      this.control.errors['email'] && this.errors.push(`El correo electrónico debe ser una dirección válida`)
      this.control.errors['pattern'] && this.errors.push(`El campo no corresponde a un patrón válido`)
    });
  }

  /** Listen to the keydown events (*Enter, *Escape, *ArrowDown, *ArrowUp) and navigate the list */
  @HostListener('keydown', ['$event'])
  navigateOnList(event: KeyboardEvent) {
    let { code, key, target } = event;
    let elementList = this.selectorListRef.nativeElement as HTMLElement;
    let childs = elementList.childNodes as NodeListOf<HTMLElement>;

    if (key == 'Enter') {
      let itemId = childs[this.highlightItem].getAttribute('data-field-id')
      itemId && this.selectItem(this.dataList[itemId])
      setTimeout(() => this.clearSearch(), 100);
      return
    }

    if (key == 'Escape') this.clearSearch()

    if (elementList && key == 'ArrowDown') {
      if (childs[this.highlightItem]) childs[this.highlightItem].classList.remove('highlight')
      this.highlightItem = this.highlightItem >= childs.length - 2 ? 0 : this.highlightItem + 1;
      if (childs[this.highlightItem]) {
        childs[this.highlightItem].classList.add('highlight')
        childs[this.highlightItem].scrollIntoView({ block: "nearest", behavior: "smooth" })
      }
    }

    if (elementList && key == 'ArrowUp') {
      if (childs[this.highlightItem]) childs[this.highlightItem].classList.remove('highlight')
      this.highlightItem = this.highlightItem <= 0 ? childs.length - 2 : this.highlightItem - 1;
      if (childs[this.highlightItem]) {
        childs[this.highlightItem].classList.add('highlight')
        childs[this.highlightItem].scrollIntoView({ block: "nearest", behavior: "smooth" })
      }
    }

  }

  /**
   * Method to listen to the input event and perform a search in the array
   * @param eventValue Value of the input event to be searched
   */
  onSearch(eventValue: KeyboardEvent): void {
    if (eventValue.key == 'Enter') return

    this.control.markAsDirty()
    this.searching = true;
    this.control.setValue('');
    let target = (eventValue.target as HTMLInputElement);
    this.value = target.value;

    // target.classList.add(...this.hidenInputRef.nativeElement.classList);
    let tmpValue = this.value.toLowerCase().trim();

    this.resultList = [];

    if (tmpValue == '') return

    for (const key in this.dataList) {
      this.dataList[key][this.valueField].toLowerCase().includes(tmpValue) && this.resultList.push(this.dataList[key])
    }

    if (!this.resultList.length) this.setSuggestions()
  }

  /**
   * Return suggestions in case the first search is not effective.
   * Converts the search term to an array of terms to do multiple searches.
   * Create the quantity field with the number of matches as a result of the search.
   * Sort by means of the number field from the highest to the lowest number of matches.
   */
  setSuggestions() {
    this.enableSuggestions = true;
    this.resultList = [];

    this.selectedItem = false;

    let terms = this.splitTextIntoArray(this.value, this.sizeSplit)

    let suggestionsList: any[] = []

    for (const key in this.dataList) {
      terms.some(term => this.dataList[key][this.valueField].toLowerCase().includes(term) && suggestionsList.push(this.dataList[key]))
    }

    this.resultList = this.countMatches(suggestionsList)
  }

  /** Method that listens when a search item is selected */
  selectItem(item: any, initial?: boolean) {
    this.control.setValue(item)
    this.selectedItem = true;
    this.value = item[this.valueField];
    this.searching = false;
    this.resultList = [];
    this.seleccionEmitter.emit(item);
  }


  /** Method to clean the search term and the list of results */
 public clearSearch(force: boolean = false) {
    if (!this.selectedItem || force) {
      this.control.setValue('')
      this.value = ''
      this.seleccionEmitter.emit(null);
    }
    this.searching = false;
    this.resultList = [];
  }

  /**
   * Convierte el array con duplicados en un array con el campo cantidad de repeticiones y lo ordena de mayor a menor
   * @param  {any[]} array con datos duplicados
   */
  countMatches(array: any[]): any[] {
    let coincidences: any = {};

    array.forEach((item: any) => {
      const id = item._id;
      coincidences[id] = coincidences[id] ? coincidences[id] + 1 : 1;
    });

    const miArray2: any[] = [];

    array.forEach((item) => {
      const id = item._id;
      if (!coincidences[id]) return;

      miArray2.push({ ...item, cantidad: coincidences[id] });

      coincidences[id] = 0;
    });

    const sorted = this.sortByQuantity(miArray2);

    return sorted;
  }

  /**
   * Method to convert text into text array with a set length
   * @param  {string} dataText Text to be converted to array
   * @param  {number} largeSplit Length of which text input will be segmented into array elements
   */
  splitTextIntoArray(dataText: string, largeSplit: number): string[] {
    var result = [];

    for (var i = 0; i < dataText.length; i += largeSplit) {
      result.push(dataText.substr(i, largeSplit));
    }

    return result;
  }

  /**
   * Sort the array from largest to smallest
   * @param  {any[]} array List to order by quantity
   */
  sortByQuantity(array: any[]) {
    array.sort((a, b) => b.cantidad - a.cantidad);
    return array;
  }

  objectToRecord(value: any) {
    return {}
  }

  /** Get the control of the form */
  get control(): FormControl {
    const parentFormGroup = this.controlContainer as FormGroupDirective;
    return parentFormGroup.control.get(this.controlName) as FormControl;
  }

  /** Get the control invalid status */
  get controlInvalid(): boolean {
    return this.control?.invalid || false;
  }

  /** Get the control touched status */
  get controlTouched(): boolean {
    return this.control?.touched || false;
  }

  /** Get the control errors */
  get errorKeys(): string[] {
    return this.control?.errors ? Object.keys(this.control.errors) : [];
  }
}
