
<div class="tabs-container">
    <ul class="tabs">
        <li *ngFor="let tab of tabs; let i = index" (click)="setActiveTab(i)" [class.active]="i === activeTab">
            <p [title]="tab.title">
                {{ tab.title }}
            </p>
        </li>
    </ul>
    <div class="tab-content-wrapper">
        <ng-container *ngIf="tabs.length > activeTab">
            <app-tab-vertical-content-component [content]="tabs[activeTab].content"></app-tab-vertical-content-component>
        </ng-container>
    </div>
</div>

