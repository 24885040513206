<main class="container-modal">
  <div class="container-form-family-group">

    <h3 class="title-family-group"> Datos del Nuevo Grupo Familiar</h3>

    <form ngNativeValidate [formGroup]="formfamilyGroup">
      <!-- Nombre del grupo familiar -->
      <form-input helper="" label="Nombre del Grupo Familiar" placeholder="" [controlName]="'nameFamily'"
        [type]="'text'">
      </form-input>

      <h3 class="title-family-group"> Ingresa la identificacion del nuevo beneficiario </h3>
      <!-- Numero de documento -->
      <form-document helper="" label="Tipo Documento" filter="all" [controlName]="'tipoDocumento'"
        placeholder="Seleccione..."></form-document>
    </form>
    <div class="container-button">
      <span *ngIf="userInitial === 'default'">Busca a tu Beneficiario para continuar </span>
      <span *ngIf="userInitial === true">Se ha encontrado a tu beneficiario, !comunícate con el administrador para
        agregarlo¡ </span>
      <span *ngIf="userInitial === false ">No se encontro a tu beneficiario, continua para registrarlo</span>
      <!-- <button class="button-continue" (click)="validateNumId()"> Continuar </button> -->

      <custom-buttons  (click)="validateNumId()" text="Continuar" textColor="#fff" [title]="''">
      </custom-buttons>
    </div>

  </div>
  <div id="sectionForm" *ngIf="userExist === false" class="container-form-beneficiary">
    <h3 class="title-family-group"> Datos del Beneficario </h3>

    <form class="form-beneficiary" ngNativeValidate [formGroup]="formBeneficiary">

      <div class="container-input">
        <!-- Nombres -->
        <form-input helper="" label="Nombres" placeholder="" [controlName]="'nombres'" [type]="'text'">

        </form-input>
        <!-- Apellidos -->
        <form-input class="input-surname" helper="" label="Apellidos" placeholder="" [controlName]="'apellidos'"
          [type]="'text'">
        </form-input>
      </div>

      <div class="container-input">
        <!-- Fecha de Nacimiento -->
        <form-date label="Fecha de nacimiento" [minInput]="minDate" [maxInput]="maxDate"
          controlName="fechaNacimiento"></form-date>
        <!-- Correo -->
        <form-email helper="" label="Correo" placeholder="ejemplo@mail.com" [controlName]="'correo'"></form-email>
      </div>
      <div class="container-input">
        <!-- Contacto -->
        <form-input helper="" label="Número de Contacto" placeholder="" [controlName]="'telefono'"
          [type]="'text'"></form-input>
        <!-- Parentesco -->
        <form-select [id]="'idInputRelationship'" helper="Selecciona un parentesco de la lista" label="Parentesco"
          placeholder="Seleccione..." [dataList]="relationShips" [controlName]="'relationship'" [idField]="'value'"
          [valueField]="'name'">
        </form-select>
      </div>


      <div *ngIf="ageBeneficiary>=18">
        <!-- Checks -->
        <form-checks [controlName]="'checks'" [data]="dataChecks" [required]="false"
          label="Selecciona los permisos que va a tener tu beneficiario" [colClass]="'col-md-4 col-xs-12'">
        </form-checks>

      </div>

    </form>

    

    <custom-buttons (click)="validateAndCreate()" text="Guardar Beneficiario" textColor="#fff" [title]="''">
    </custom-buttons>
  </div>
</main>

