import { Injectable } from '@angular/core';
import introJs from 'intro.js';


interface optionsSteps {
  position?: 'top' | 'bottom' | 'left' | 'right';
  element: string;
  title?: string;
  intro: string;
}

interface funcOptions {
  exitOnOverlayClick?: boolean;
  keyboardNavigation?: boolean;
  disableInteraction?: boolean;
  stepNumbersOfLabel?: string;
  dontShowAgainLabel?: string;
  showStepNumbers?: boolean;
  overlayOpacity?: Number;
  dontShowAgain?: boolean;
  showProgress?: boolean;
  tooltipClass?: string;
  showBullets?: boolean;
  exitOnEsc?: boolean;
  prevLabel?: string;
  nextLabel?: string;
  skipLabel?: string;
  doneLabel?: string;
}

@Injectable({
  providedIn: 'root'
})
export class IntrojsService {
  introJS: any = null;

  launchTour(steps: optionsSteps[], opts: funcOptions = {}) {
    let {
      disableInteraction,
      doneLabel,
      dontShowAgain,
      dontShowAgainLabel,
      exitOnEsc,
      exitOnOverlayClick,
      keyboardNavigation,
      nextLabel,
      overlayOpacity,
      prevLabel,
      showBullets,
      showProgress,
      showStepNumbers,
      stepNumbersOfLabel,
      tooltipClass
    } = opts
    try {
      this.introJS = introJs();
      this.introJS.start();

      this.introJS.setOptions({
        steps,
        exitOnEsc: exitOnEsc || false,
        dontShowAgainLabel: dontShowAgainLabel || 'No mostrar de nuevo',
        exitOnOverlayClick: exitOnOverlayClick || false,
        disableInteraction: disableInteraction || true,
        keyboardNavigation: keyboardNavigation || true,
        stepNumbersOfLabel: stepNumbersOfLabel || 'de',
        tooltipClass: tooltipClass || 'helper__tour',
        showStepNumbers: showStepNumbers || false,
        overlayOpacity: overlayOpacity || 0.5,
        dontShowAgain: dontShowAgain || false,
        showProgress: showProgress || false,
        showBullets: showBullets || false,
        nextLabel: nextLabel || 'siguiente',
        prevLabel: prevLabel || 'anterior',
        doneLabel: doneLabel || 'Salir'
      }).start()
    } catch (e) {
      console.debug('Error on intrijs', e);
    }
  }
}
