<div style="padding: 10px;">
  <div class="tab-content" id="pills-tabContent">
    <ng-container [ngTemplateOutlet]="currentView"></ng-container>
  </div>
</div>

<ng-template #experienceList>
  <div class="animated fadeIn">
    <div class="row">
      <div class="col-12">
        <div class="config_container">
          <div class="config_title mb-2">Experiencia Laboral</div>
          <div *ngIf="getExperienciaTotal(experiences).length>0" class="config_subtitle">Has registrado
            <span class="config_campos_number">{{getExperienciaTotal(experiences)}}</span>
            <span> de Experiencia Laboral.</span>
            <span class="config_update text_default" title="!Click aqui!"
              (click)="iniciarExperienceAgregateForm()">¡Agregar Experiencia!</span>
          </div>
          <div *ngIf="getExperienciaTotal(experiences).length==0" class="config_subtitle">
            <span>Aún no has registrado experiencia laboral</span>
            <span class="config_update text_default" title="!Click aqui!"
              (click)="iniciarExperienceAgregateForm()">¡Agregar Experiencia!</span>
          </div>
        </div>
      </div>
    </div>

    <div class="table-responsive no-wrap table-sm mt-4">
      <table *ngIf="experiences.length>0" class="table vm no-th-brd pro-of-month">
        <thead>
          <tr>
            <th>Empresa</th>
            <th>Cargo</th>
            <th>Municipio</th>
            <th>Tiempo</th>
            <th>Opciones</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let experience of experiences">
            <tr class="pointer row_studies fw_400">

              <td>
                <div class="studies_name" (click)="selectExperience(experience)">{{experience.companyName}}</div>
              </td>
              <td>
                <div class="studies_name" (click)="selectExperience(experience)">{{experience.description}}</div>
              </td>
              <td>
                <div (click)="selectExperience(experience)">{{experience.municipality}}</div>
              </td>
              <td>
                <div (click)="selectExperience(experience)">{{getExperienciaTiempo(experience)}}</div>
              </td>
              <td>
                <div class="actions">
                  <i class="fa fa-edit update" title="Actualizar" (click)="selectExperience(experience)"></i>
                  <i class="fa fa-trash delete" title="Eliminar" (click)="deleteExperience(experience)"></i>
                </div>
              </td>

            </tr>
          </ng-container>
        </tbody>
      </table>
      <table *ngIf="experiences.length==0" class="table vm no-th-brd pro-of-month">
        <thead>
          <tr>
            <th class="no_registrados">No hay registrada Experiencia Laboral</th>
          </tr>
        </thead>
      </table>
    </div>

  </div>
</ng-template>

<ng-template #experienceEdit>
  <div class="animated fadeIn">
    <div class="update_settings_header mb-4">
      <!-- <button type="button" class="btn btn-circle btn_back" title="Regresar" (click)="regresarExperiencias()"><i
          class="ti ti-arrow-left"></i> </button> -->

      <custom-buttons style="padding-right: 10px;" type="button" (click)="regresarExperiencias()" text=""
        textColor="#fff" [title]="'Regresar'" icon="ti ti-arrow-left">
      </custom-buttons>
      <div *ngIf="experienceSelected" class="settings_header_title">Actualizar Experiencia</div>
      <div *ngIf="!experienceSelected" class="settings_header_title">Agregar Experiencia</div>
    </div>

    <form [formGroup]="experienceForm">

      <div class="row">

        <div class="col-12">
          <div class="form-group">
            <form-input [id]="'idInputDescription'" helper="Registre cargo que desempeñó en la empresa" label="Cargo"
              placeholder="ejm: Medico General" [controlName]="'description'">
            </form-input>
          </div>
        </div>
        <div class="col-md-6 col-xs-12">
          <div class="form-group">
            <form-input [id]="'idInputCompanyName'"
              helper="Registre el nombre de la empresa donde trabajo o aún trabaja" label="Empresa"
              placeholder="ejm: Hospital Chapinero" [controlName]="'companyName'">
            </form-input>
          </div>
        </div>
        <div class="col-md-6 col-xs-12">
          <div class="form-group">
            <form-input [id]="'idInputMunicipality'" helper="Registre el municipio o ciudad de la empresa"
              label="Municipio/Ciudad" placeholder="ejm: Bogotá-Cundinamarca" [controlName]="'municipality'">
            </form-input>
          </div>
        </div>
        <div class="col-md-6 col-xs-12">
          <div class="form-group">
            <form-input [id]="'idInputTimeExperience'" helper="Tiempo de experiencia, solo se admite valor numérico"
              label="Tiempo de Experiencia" [type]="'number'" [customErrors]="customErrors"
              placeholder="ejm: 1, 2, 3, 4 ó 5" [controlName]="'timeExperience'">
            </form-input>
          </div>
        </div>

        <div class="col-md-6 col-xs-12">
          <div class="form-group">
            <div class="form-group">
              <form-select [id]="'idInputUnit'" helper="Seleccione una unidad de tiempo de la lista"
                label="Unidad de Tiempo" placeholder="Seleccione..." [dataList]="units" [controlName]="'unit'"
                [idField]="'value'" [valueField]="'name'">
              </form-select>
            </div>
          </div>
        </div>


      </div>

      <div class="settings_btn mt-4">
        <!-- <button *ngIf="!experienceSelected" type="button" (click)="agregateExperience(experienceForm)"
          class="btn btn-success btn-rounded">Agregar Experiencia</button> -->

        <custom-buttons *ngIf="!experienceSelected" type="button" (click)="agregateExperience(experienceForm)"
          text="Agregar Experiencia" textColor="#fff" [title]="''" [rounded]="true">
        </custom-buttons>
        <!-- <button *ngIf="experienceSelected" type="button" (click)="updateExperience(experienceForm)"
          class="btn btn-success btn-rounded">Confirmar Cambios</button> -->

        <custom-buttons *ngIf="experienceSelected" type="button" (click)="updateExperience(experienceForm)"
          text="Confirmar Cambios" textColor="#fff" [title]="''" [rounded]="true">
        </custom-buttons>
      </div>

    </form>

  </div>
</ng-template>
