import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SearchRequest, SearchRequestWithLocation } from '@interfaces/shared-components.interface';
import { FormAutocompleteComponent } from 'src/app/custom-forms/components/form-autocomplete/form-autocomplete.component';
import { AddressSettings, Municipio, PlacesCoordinates, Service } from 'src/app/interfaces/information.interface';
import { InformationService } from 'src/app/services/information.service';
import { SearchInputComponent } from 'src/app/shared-components/search-input/search-input.component';
import { UserService } from '../../services/user.service';
import { getServiceDetails, getMunicipalityId, getPending, GetLoginAppointment } from 'src/app/directory/components/state/appointment.selectors';
import { Store } from '@ngrx/store';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-user-home',
  templateUrl: './user-home.component.html',
  styleUrls: ['./user-home.component.scss']
})
export class UserHomeComponent implements OnInit {
  // TODO: Variables para el caso de user no Logeado solicitando Cita emitUserLogeadoWhitDataSubService()
  dataStore: any;
  dataStoreParse: any;
  idMunicipality: any;
  idSubservice: any;
  pending!: boolean;
  login!: boolean;

  @ViewChild(SearchInputComponent) searchInputComponent!: SearchInputComponent;
  @ViewChild(FormAutocompleteComponent) formAutocompleteComponent!: FormAutocompleteComponent;

  //Varibales para la inicializacion por defecto de services Pasto
  default: boolean = true;
  // Variables del componente de rango
  inputRange = {
    min: 0,
    max: 2000,
    step: 100,
    radius: 500,
  }
  alertMin = 'No puedes buscar servicios en un rango menor a 100 metros';
  isSwitchOn: boolean = false;  // Variable del switch
  //resetTrigger!: boolean; // Variable para resetear el input de los municipios
  coordinates?: PlacesCoordinates;
  prefix!: string;
  lat: number = 0;
  lng: number = 0;
  // Variables para almacenar los datos relacionados con municipio
  municipalities: any[] = []; //  todos los municipios
  municipalitySearch: any;  // Objeto del municipio seleccionado
  municipalitySearchId!: string; // id del municipio seleccionado
  searchTerm!: string
  // Variable para almacenar la data de los servicios que se enviaran  a la cardSubservice
  service!: any;
  subServices!: any[];
  subServicesMobile!: any[];
  subServicesPaginator!: any[];
  subServicesPaginatorMobile!: any[];
  pets!: any[];
  suggestions!: any[];
  // Variables para lo relacionado con la busqueda de servicios
  searchWithLocation: any;
  searchTermFilter: any;
  // Variables form lista de municipios
  municipalityForm!: FormGroup;
  municipalitySelected: any;
  msgResults!: boolean;

  // Variables relacionadas con filtros
  typesFilter: string = 'humans';
  viewFilter: boolean = false;



  constructor(
    public router: Router,
    private infoService: InformationService,
    private resetService: UserService,
    private store: Store,
    private cdr: ChangeDetectorRef,
    private location: Location) {}

  ngOnInit() {
    
    
    this.emitUserLogeadoWhitDataSubService();
    this.iniciarData();
    this.inicializarMunicipalityForm();
    this.viewFilter = false;

    if (this.municipalitySelected === null && this.isSwitchOn && this.isSwitchOn === true) {
      this.isSwitchOn = false;
    }
    this.getDefaultData();

    // No permite navegar ni atras ni adelante con las flechas del navegador, para evitar que el usuario tenga errores de navegacion.
    // history.pushState(null, document.title, location.href);
    // window.addEventListener('popstate', (event) => {
    //   history.pushState(null, document.title, location.href);
    // });

  }

  

  // Obtener la data por defecto de pasto
  getDefaultData() {
    // Obtener los servicios activos por defecto de pasto
    this.municipalitySearchId = '';

    if (!this.municipalitySearchId) {

      this.municipalitySearchId = '63fc1c75cc9aab42aaa3ec45';

      this.infoService.getSubServices('63fc1c75cc9aab42aaa3ec45').then(res => {

        if (res.pets.length > 0 || res.services.length > 0) {
          this.default = true;
          this.msgResults = false;
          //TODO: filtra solo los subservicios con status 'active', los pets no tienen ese filtro mirar como hacer mas adelante  
          const filterServices = res.services.filter((service: Service) => service.subService.status === 'active');
          this.subServices = [...filterServices,...res.pets];
          //  this.subServicesMobile = this.subServices;
          this.subServicesPaginator = this.subServices;
          //   this.subServicesPaginatorMobile = this.subServices;
          //console.log('servicios por defatul de pasto', this.subServices);

          this.cdr.detectChanges();
        }

      })
        .catch(err => {
          //console.log(err);
        })

    }
   
  }

  // Manejar el estado del switch
  onSwitchChange(switchState: boolean): void {
    this.isSwitchOn = switchState;
    //console.log('estado mun seleccionado desde el switch', this.municipalitySelected);


    //console.log('El estado del switch es:', switchState);
    if (this.isSwitchOn === false) {
      //console.log('radio desactivado', 'estado del swicth', this.isSwitchOn);

    }
    if (this.isSwitchOn === true) {
      //console.log('radio activado', 'estado del swicth', this.isSwitchOn);
    }


  }
  // Resetear la busqueda
  resetSearch() {
    //this.reset = true
    this.municipalitySearchId = '';
    this.isSwitchOn = false;
    this.municipalitySelected = " ";
    this.subServices = [];
    this.subServicesMobile = [];
    this.subServicesPaginator = [];
    this.subServicesPaginatorMobile = [];
    this.suggestions = [];
    this.prefix = "";
    this.inputRange = {
      min: 0,
      max: 2000,
      step: 100,
      radius: 500,
    }
    this.lat = 0;
    this.lng = 0;
    this.searchTerm = " ";
    this.searchTermFilter;
    this.resetAutocomplete();
    if (this.viewFilter = true) {
      this.viewFilter = false;
    }
    this.msgResults = false;
    this.getDefaultData();

    //console.log('despues de resetear',)
  }
  // Resetea el input del municipio seleccionado
  resetAutocomplete(): void {
    this.resetService.resetForm();
  }


  // Metodos del componente input Search
  searchErrorEmmiter(e: any) {
    //console.log(e);
  }

  handleValueChange(value: string) {
    //console.log('Valor recibido del hijo:', value);
    // Aquí puedes hacer lo que necesites con el valor
    this.searchTermFilter = value;
    //console.log('valor de searchTermFilter', this.searchTermFilter);

  }



  async searchWithFilter() {

    this.subServices = [];
    this.subServicesMobile = [];
    this.suggestions = [];
    this.subServicesPaginator = [];
    this.subServicesPaginatorMobile = [];

    try {
      if (this.searchTermFilter && this.searchTermFilter.length > 0 && this.municipalitySearchId) {

        const searchWithLocation: SearchRequestWithLocation = {
          municipalityId: this.municipalitySearchId,
          term: this.searchTermFilter,
          filters: {
            price: {
              enabled: false,
              min: 0,
              max: 0,
            },
            provider: true,
            doctor: true,
            services: {
              pets: this.typesFilter == 'pets',
              humans: this.typesFilter == 'humans'
            },
          },
          location: {
            enabled: this.isSwitchOn,
            range: this.inputRange.radius * 1000,
            lat: this.coordinates?.lat ? this.coordinates.lat : null,
            lng: this.coordinates?.lng ? this.coordinates.lng : null
          }
        };

        const searchWithoutFilter: SearchRequest = {
          municipalityId: this.municipalitySearchId,
          term: this.searchTermFilter,
          filters: {
            price: {
              enabled: false,
              min: 0,
              max: 0,
            },
            provider: true,
            doctor: true,
            services: {
              pets: this.typesFilter == 'pets',
              humans: this.typesFilter == 'humans'
            }
          }
        };

        if (this.isSwitchOn && this.searchTermFilter.length >= 1 && this.municipalitySelected._id.length > 0) {
          //console.log('objeto', searchWithLocation);
          this.infoService.search(searchWithLocation).subscribe(async resp => {
          //  console.log('busqueda con localizacion', resp);
            this.processSearchResults(resp);
            //console.log('respuesta despues de la busqueda', resp);
            // Mostrar Alert de que no existen servicios ni sugerencias en el Municipio
            if (resp.body.results.pets.length <= 0 && resp.body.results.services.length <= 0 && resp.body.suggestions.length <= 0) {
              //console.log('ciudad sin servicios');
              const alert = await Swal.fire({
                icon: 'info',
                text: `Pronto tendremos servicios en esta ciudad, por favor busca tu servicio en otra ciudad`,
                confirmButtonColor: '#00ab78',
                confirmButtonText: 'Regresar',
                allowOutsideClick: false,
                //timer: 3500,  
              })

              if (alert.isConfirmed) {
                // Traer la data por defecto cuando resetea la busqueda
                this.resetSearch();
              }
            }


            // Loguear el valor actual después de la búsqueda
            //console.log('municipio seleccionado después de la búsqueda', this.municipalitySelected);
            const valueInputSelect = this.municipalityForm.get('municipality')?.value;
            //console.log('value municipality select después de la búsqueda', valueInputSelect);

            // Reasignar el valor del municipio seleccionado al formulario
            if (this.municipalitySelected) {
              this.municipalityForm.patchValue({
                municipality: this.municipalitySelected
              });
              //console.log('municipio reasignado al input:', this.municipalityForm.get('municipality')?.value);
            }

            this.cdr.detectChanges()
          });

        } else if (!this.isSwitchOn && this.searchTermFilter.length >= 1 && this.municipalitySelected._id.length > 0) {

          this.infoService.search(searchWithoutFilter).subscribe(async resp => {
            this.processSearchResults(resp);
            //console.log('respuesta despues de la busqueda', resp);
            // Mostrar Alert de que no existen servicios ni sugerencias en el Municipio
            if (resp.body.results.pets.length <= 0 && resp.body.results.services.length <= 0 && resp.body.suggestions.length <= 0) {
              //console.log('ciudad sin servicios');
              const alert = await Swal.fire({
                icon: 'info',
                text: `Pronto tendremos servicios en esta ciudad, por favor busca tu servicio en otra ciudad`,
                confirmButtonColor: '#00ab78',
                confirmButtonText: 'Regresar',
                allowOutsideClick: false,
                // timer: 3500,  
              })

              if (alert.isConfirmed) {
                // Traer la data por defecto cuando resetea la busqueda
                this.resetSearch();
              }
            }

            // Loguear el valor actual después de la búsqueda
            //console.log('municipio seleccionado después de la búsqueda', this.municipalitySelected);
            const valueInputSelect = this.municipalityForm.get('municipality')?.value;
            //console.log('value municipality select después de la búsqueda', valueInputSelect);

            // Reasignar el valor del municipio seleccionado al formulario
            if (this.municipalitySelected) {
              this.municipalityForm.patchValue({
                municipality: this.municipalitySelected
              });
              //console.log('municipio reasignado al input:', this.municipalityForm.get('municipality')?.value);
            }

            this.cdr.detectChanges()
          });

        }
      } else {
        throw new Error('Verifica que los campos estén completos');

      }
    } catch (error ) {
      this.resetSearch();
      // this.getDefaultData();
      console.log(error);
      await Swal.fire({
        title: 'Busqueda no realizada',
        text: 'Por favor llena los campos de busqueda',
        icon: 'warning',
        confirmButtonColor: '#00ab78',
        confirmButtonText: 'OK',
        timer: 3500,
      });
    }

  }

  private async processSearchResults(resp: any) {

    this.msgResults = true;
    this.default = false;
    // Pasar la data que se obtiene a la cardSubservice
    this.service = resp;
    // Obtener los subservicios
    if (this.service.body.results.services && this.service.body.results.pets && this.service.body.results.services.length >= 1 && this.service.body.results.pets.length >= 1) {
      // Combinar ambos arrays
      this.subServices = [...this.service.body.results.services, ...this.service.body.results.pets];
      //this.subServicesMobile = [...this.service.body.results.services, ...this.service.body.results.pets];
      this.subServicesPaginator = this.subServices;
      // this.subServicesPaginatorMobile = this.subServices;
    } else if (this.typesFilter === 'humans' && this.service.body.results.services && this.service.body.results.services.length >= 1 && this.service.body.results.pets && this.service.body.results.pets.length === 0) {
      // Caso en que solo existan servicios de humanos
      const activeServices = this.service.body.results.services.filter((service: Service) => service.subService.status === 'active');
      this.subServices = activeServices;
      // this.subServicesMobile = activeServices;
      this.subServicesPaginator = this.subServices;
      //this.subServicesPaginatorMobile = this.subServices;
      //console.log('servicios activos de solo humanos', this.subServices, this.subServicesPaginator);
      //TODO: Mirar si se muestran solo los servicios de status = 'active' en este caso el subservicio esta: status 'unpublished'
    } else if (this.typesFilter === 'pets' && this.service.body.results.services && this.service.body.results.services.length === 0 && this.service.body.results.pets && this.service.body.results.pets.length > 0) {
      // Caso en que solo existan servicios de mascotas
      this.subServices = this.service.body.results.pets;
      // this.subServicesMobile = this.service.body.results.pets;
      this.subServicesPaginator = this.subServices;
      //  this.subServicesPaginatorMobile = this.subServices;
      //console.log('caso de solo pets', this.subServices, this.subServicesPaginator);
    } else if (this.service.body.suggestions && this.service.body.results.services.length === 0 && this.service.body.results.pets.length === 0) {
      // Caso en que no se encuentre ningún servicio con ese término de búsqueda
      this.suggestions = this.service.body.suggestions;
      console.log('data de las sugerencias', this.suggestions);
    }


    //console.log('objeto de la búsqueda', this.isSwitchOn ? 'con localización' : 'por term y mun', this.isSwitchOn ? resp.searchWithLocation : resp.searchWithoutFilter);
    //console.log('id del municipio', this.municipalitySearchId);
    //console.log('término buscado', this.searchTermFilter);
  }


  // ================= Metodos del componente de radio ================ //

  // Muestra el radio de seleccionado y lo pasa a la propiedad inputRadius la cual se visualizara
  onRangeChange(valueChange: number) {
    //console.log(valueChange);
    this.inputRange.radius = valueChange;
    // this.inputRadius = valueChange;
  }


  // =================== Metodos del componente de mapa =============== //

  // Metodo para obtener la Ubicacion en lat y lng 
  placeEmmiter(e: AddressSettings) {
    if (!e) {
      //console.log('null');
      this.coordinates = undefined;
    }
    else if (e.location) {
      this.coordinates = {
        lat: e.location.coordinates[0],
        lng: e.location.coordinates[1]
      }

      //console.log(this.coordinates);
      //console.log(e);
      /*
            if (e.addressDirections !== undefined) {
              this.findMunicipalityByCity(e.addressDirections, this.municipalities);
            } else {
              // Manejar el caso en que e.addressDirections es undefined
              //console.log('Dirección no proporcionada');
            }
      */


    } else {
      this.coordinates = undefined;
      //console.log('sin ubicacion.');
    }
  }

  errorPlaceEmmiter(error: any) {
    if (error.noSearch) {
      //console.log('noSearch');
      this.coordinates = undefined;
    }
  }

  // Metodo para obtener la info de los municipios
  iniciarData() {
    this.infoService
      .getMunicipalities()
      .then((resp: any) => {
        this.municipalities = resp;
        ////console.log(this.municipalities);
      })
      .catch(() => {
        this.municipalities = [];
      });
  }

  // Hace la busqueda de las sugerencias cuando se hace click en ellas.
  onPClick(value: string) {
    this.searchInputComponent.setValueAndSearch(value);
    this.searchTermFilter = value;
    this.searchWithFilter();
  }

  // Metodo para redirrecionar para mostrar detalles del subservicio en el button de la card
  emitDataSubs(subServId: any) {
    let mun = this.municipalitySearchId;
    let sub = subServId.subService._id;
    //console.log(sub);
    
    
    this.router.navigate(['/user/detalles-servicio'], {
      queryParams: { mun, sub },
    });
  
   
  }

  // Metodo de paginacion
  pageChange(event: any) {
    this.subServicesPaginator = event;
  }
  // Para el radio del mapa
  iniciarDataMunicipio(mun: Municipio) {
    if (mun.radius) {
      this.inputRange = {
        min: 0,
        max: (mun.radius / 1000),
        step: (mun.radius / 1000) / 20,
        radius: ((mun.radius / 1000) / 20) * 5,
      }
      this.alertMin = `No puedes buscar servicios en un rango menor a ${(this.inputRange.step * 1000)} metros`;
    } else {
      this.inputRange = {
        min: 0,
        max: (2000 / 1000),
        step: (100 / 1000),
        radius: (500 / 1000),
      }
      this.alertMin = `No puedes buscar servicios en un rango menor a ${(this.inputRange.step * 1000)} metros`;
    }
  }

  // Formulario de la lista de municipios
  inicializarMunicipalityForm() {
    this.municipalityForm = new FormGroup({
      municipality: new FormControl(this.municipalitySelected),
    });

  }

  // Metodo para Obtener el value del municipio del input list
  seleccionEmitter(item: any) {
    this.municipalitySelected = item;
    //console.log('mun seleccionado', this.municipalitySelected);

    if (!this.municipalitySelected && this.isSwitchOn === true) {
      this.isSwitchOn = false;
    }



    else if (this.municipalitySelected) {
      this.iniciarDataMunicipio(this.municipalitySelected);
      this.prefix = this.municipalitySelected.municipality;
      this.lat = this.municipalitySelected.lat;
      this.lng = this.municipalitySelected.lng;
      //console.log('prefijo', this.prefix);
      //console.log('lat', this.lat, 'lng', this.lng);
      // Este id de municipio se pasa al objeto de busqueda
      this.municipalitySearchId = this.municipalitySelected._id;
      //console.log('id del municipio seleccionado:', this.municipalitySearchId);

    }


  }

  // Filtro de servicios
  updateFilter(filter: string) {
    this.typesFilter = filter;
  }

  // ======================================================================================================================================== //

  // Funcion que valida si viene el usuario de obtener el subservicio y quiere sacar cita pero sin estar logeado
  emitUserLogeadoWhitDataSubService() {
    let serviceDetails;
    let municipalityId;
    let pending;
    let login;
    // Obtiene info del estado Global 
    this.store.select(getServiceDetails).subscribe(details => {
      serviceDetails = details;
      //console.log('data del servicio del estado global', serviceDetails);
      this.idSubservice = serviceDetails?.subservice._id

    });
    this.store.select(getMunicipalityId).subscribe(municipality => {
      municipalityId = municipality;
      this.idMunicipality = municipalityId
      //console.log('id del municipio del estado global', this.idMunicipality);
    });

    this.store.select(getPending).subscribe(pendingState => {
      pending = pendingState;
      this.pending = pending;
      //console.log('estado del pendiente global', this.pending);
    });

    this.store.select(GetLoginAppointment).subscribe(loginState => {
      login = loginState;
      this.login = login;
      //console.log('estado del login global', this.login);
    })

    // Verifica si hay detalles de servicio y municipio en el estado ademas si viene en proceso de seleccion de servicio y si esta logeado o no
    if (serviceDetails && municipalityId && pending === true && login === false) {
      // Redirige al detalle del subservicio
      this.router.navigate(['user/informacion/servicio', this.idSubservice, this.idMunicipality]);
    } else {
      //console.log('No hay datos de servicio almacenados en el estado');
    }
  }


}

