<span [id]="idInput" class="input__group">
  <div class="box__label">
    <label class="input__label" for="input_component">{{label}}<strong *ngIf="required"> *</strong></label>
    <i class="fa fa-question icon__helper" [title]="helper" *ngIf="helper">
    </i>
  </div>
  <div class="box__input">
    <input type="text" class="input__form input__form__price" #priceInputRef [disabled]="disablebInput" [value]="priceValue" (input)="setPrice($event)" [placeholder]="placeholder" [ngClass]="{invalid: control.dirty && control.invalid}" />
    <input type="hidden" [formControl]="control">
    <i class="fa fa-close icon__clear" *ngIf="!control?.disabled" [ngClass]="{visible: priceInputRef.value != ''}" (click)="limpiarValue(); priceInputRef.value = ''" title="Limpiar"></i>
  </div>
  <div class="box__error" [ngClass]="{visible:errors.length}">
    <ul>
      <li class="box__error__item" *ngFor="let error of errors"><strong>* </strong>{{error}}</li>
    </ul>
  </div>
</span>
