import { Injectable } from '@angular/core';
import { BeneficiaryGroup, FamilyGroup } from '@interfaces/information.interface';
import { firstValueFrom } from 'rxjs';
import { ApiService } from '@services';

@Injectable({
  providedIn: 'root'
})
export class FamilyGroupsServiceService {

  constructor(private apiService: ApiService) { }

  async getFamilyGroups() {    
    let response = await firstValueFrom(this.apiService.getRequest('family-group'));
    return response;
  }

  async getFamilyGroupsUserId(userId:string) {    
    let response = await firstValueFrom(this.apiService.getRequest(`advisor/family-groups/${userId}`));
    return response;
  }

  async createFamilyGroup(body:FormData) {     
    let response = await firstValueFrom(this.apiService.postRequestFile('family-group',body));
    return response;
  }
  
  async updateFamilyGroup(body:FormData) { 
    let response = await firstValueFrom(this.apiService.putRequestFile('family-group',body));
    return response;
  }


  // get beneficiaries and pets

  async getFamilyGroupsDetailsId(id:string) {    
    let response = await firstValueFrom(this.apiService.getRequest(`family-group/details/${id}`));
    return response;
  }

  async getFamilyGroupsDetailsDocument(document:string) {    
    let response = await firstValueFrom(this.apiService.getRequest(`family-group/request/${document}`));
    return response;
  }

    // beneficiaries

  async createFamilyGroupBeneficiary(body:BeneficiaryGroup) {    
    let response = await firstValueFrom(this.apiService.postRequest('family-group/beneficiary',body));
    return response;
  }

  async deleteBeneficiaryId(beneficiaryId:string,groupId:string) {    
    let response = await firstValueFrom(this.apiService.deleteRequest(`family-group/beneficiary/${beneficiaryId}/${groupId}`));
    return response;
  }


  // Pets
  async createFamilyGroupPet(body:FormData) {    
    let response = await firstValueFrom(this.apiService.postRequestFile('family-group/pet',body));
    return response;
  }

  async updateFamilyGroupPet(body:FormData) { 
    let response = await firstValueFrom(this.apiService.putRequestFile('family-group/pet',body));
    return response;
  }

  async deletePetId(petId:string,groupId:string) {    
    let response = await firstValueFrom(this.apiService.deleteRequest(`family-group/pet/${petId}/${groupId}`));
    return response;
  }

  // Crear grupo familiar y añadir beneficario
  async addGroupFamilyAndBeneficiary(body: any){
    let response = await firstValueFrom(this.apiService.postRequest('family-group/and-benefiry',body));
    return response;;
  }

  async sendRequestFamily(body:any) {    
    let response = await firstValueFrom(this.apiService.postRequestFile('family-group/request',body));
    return response;
  }
  
}
