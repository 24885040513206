<span [id]="idInput" class="input__group">
  <div class="box__label">
    <label *ngIf="labelVisible" [style.color]="labelColor" [style.fontSize]="labelFontSize" class="input__label" for="input_component">{{label}}<strong *ngIf="required"> *</strong></label>
    <i class="fa fa-question icon__helper" [title]="helper" *ngIf="helper"></i>
  </div>
  <div class="box__input">
    <!-- <select #selectRef class="input__form" name="select" [formControl]="control" [ngClass]="{invalid: control.touched && control.invalid}" (blur)="onBlur()" (change)="onSelectChange(selectRef.value, selectRef.options[selectRef.selectedIndex].text)" (click)="validateErrors()"> -->
      <select #selectRef class="input__form" name="select" [formControl]="control" [ngClass]="{invalid: control.touched && control.invalid}" (blur)="onBlur()" (click)="validateErrors()">
      <option [value]="null" selected disabled hidden>{{ placeholder }}</option>
      <!-- <option *ngFor="let dataItem of dataList" [value]="dataItem[idField]" [disabled]="dataItem.disable">{{ dataItem[valueField] }}</option> -->
      <option *ngFor="let dataItem of dataList" [value]="dataItem | dataObject:idField" [disabled]="dataItem.disable">{{dataItem | dataObject:valueField}}</option>
      <!-- valueField -->
    </select>
  </div>
  <div class="box__error" [ngClass]="{visible:errors.length}">
    <ul>
      <li class="box__error__item" *ngFor="let error of errors"><strong>* </strong>{{error}}</li>
    </ul>
  </div>
</span>
