import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';

@Pipe({
  name: 'formatAppointmentDate'
})
export class FormatAppointmentDatePipe implements PipeTransform {

  transform(date: any, hour: any): unknown {

    const newDate = date.split('T')[0];

    // Crear una instancia de fecha completa para el momento de la cita
    const appointmentDateTime = new Date(`${newDate}T${hour}:00.000`);

    // Formatea la fecha en español 
    const formattedDate = format(appointmentDateTime, 'yyyy-MM-dd');
    const formattedTime = format(appointmentDateTime, 'h:mm a'); // Formatear la hora correctamente

    // //console.log(`${formattedDate} ${formattedTime}`);
    return `${formattedDate} ${formattedTime}`;

  }

}
