import { Component } from '@angular/core';

@Component({
  selector: 'dialog-popup',
  templateUrl: './dialog-popup.component.html',
  styleUrls: ['./dialog-popup.component.scss']
})
export class DialogPopupComponent {
  constructor() {

  }
}
