<footer class="footers primary-footer">
  <div class="container">
    <div class="row">
      <div class="col-md-4 col-sm-4">
        <div class="widget clearfix">
          <h4 class="widget-title title-footer">Contacto</h4>
          <div class="newsletter-widget">
            <ul>
              <li>
                <a target="_blank" href="https://api.whatsapp.com/send?phone=573174785093&text=Hola!%20me%20comunico%20desde%20la%20web%20quiero%20saber%20m%C3%A1s%20de%20Prevenir%20Descuentos%20M%C3%A9dicos!"><span class="font-bold">Whatsapp</span> (+57) 317 478 5093
                </a>
              </li>
              <li>
                <a href=""><span class="font-bold">Correo:</span>
                  contactodescuentosmedicos@gmail.com</a>
              </li>
              <li>
                <a href=""><span class="font-bold">Dirección:</span> Pasto - Nariño -
                  Colombia 520002</a>
              </li>
              <li>
                <a href=""><span class="font-bold">Data Universal Numering System (NUMERO REGISTRO
                    INTERNACIONAL):</span>
                  DUNS - 885246680</a>
              </li>
            </ul>
          </div>
          <!-- end newsletter -->
        </div>
        <!-- end widget -->
      </div>
      <!-- end col -->
      <div class="col-md-4 col-sm-4">
        <div class="widget clearfix">
          <h4 class="widget-title ">
            <a class="title-footer" target="_blank" href="https://www.prevenirexpress.com/">Unidades de negocio Grupo Prevenir</a>
          </h4>
          <ul>

              <ul>
                <li>
                  <a target="_blank" href="https://www.prevenirexpress.com/"><span class="font-bold">Prevenir Descuentos médicos</span></a>
                </li>
                <li>
                  <a target="_blank" href="https://www.prevenirexpress.com/"><span class="font-bold">Prevenir Centro Optico</span></a>
                </li>
                <li>
                  <a target="_blank" href="https://www.prevenirexpress.com/"><span class="font-bold">Grupo Prevenir Exequias </span></a>
                </li>
                <li>
                  <a target="_blank" href="https://www.prevenirexpress.com/"><span class="font-bold">Fundacion Prevenir</span></a>
                </li>
                <li>
                  <a target="_blank" href="https://www.prevenirexpress.com/"><span class="font-bold">Prevenir Familiar</span></a>
                </li>
              </ul>

          </ul>
        </div>
        <!-- end widget -->
      </div>
      <!-- end col -->

      <div class="col-md-4 col-sm-4">
        <div class="widget clearfix">
          <h4 class="widget-title title-footer">Redes Sociales</h4>
          <ul class="lista-horizontal social-icons icon-circle list-unstyled list-inline">
            <li title="Facebook Descuentos Medicos">
              <a href="https://www.facebook.com/people/Descuentos-M%C3%A9dicos-App-Nit-900690163-6/100075885914941/" target="_blank">
              <i class="fa fa-facebook i_web"></i>
              </a>
            </li>
            <li title="Instagram Descuentos Medicos ">
              <a href="https://www.instagram.com/prevenir_descuentos_medicos/" target="_blank">
              <i class="fa fa-instagram i_web"></i>
              </a>
            </li>
            <li title="Whatsapp Descuentos Medicos ">
              <a href="https://api.whatsapp.com/send?phone=573174785093&text=Hola!%20me%20comunico%20desde%20la%20web%20quiero%20saber%20m%C3%A1s%20de%20Prevenir%20Descuentos%20M%C3%A9dicos!" target="_blank">
              <i class="fa fa-whatsapp i_web"></i>
              </a>
            </li>
            <li title="YouTube Descuentos Medicos">
              <a href="https://www.youtube.com/channel/UCM2BdEElUZlvSmsG8iTIrmA" target="_blank">
              <i class="fa fa-youtube-play i_web"></i>
              </a>
            </li>
            <li title="TikTok Descuentos Medicos">
              <a href="https://www.tiktok.com/@descuentos_medicos?lang=es" target="_blank">
                <svg class="fa fa-tiktok" width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g id="Icon/Social/tiktok-black" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <path d="M38.0766847,15.8542954 C36.0693906,15.7935177 34.2504839,14.8341149 32.8791434,13.5466056 C32.1316475,12.8317108 31.540171,11.9694126 31.1415066,11.0151329 C30.7426093,10.0603874 30.5453728,9.03391952 30.5619062,8 L24.9731521,8 L24.9731521,28.8295196 C24.9731521,32.3434487 22.8773693,34.4182737 20.2765028,34.4182737 C19.6505623,34.4320127 19.0283477,34.3209362 18.4461858,34.0908659 C17.8640239,33.8612612 17.3337909,33.5175528 16.8862248,33.0797671 C16.4386588,32.6422142 16.0833071,32.1196657 15.8404292,31.5426268 C15.5977841,30.9658208 15.4727358,30.3459348 15.4727358,29.7202272 C15.4727358,29.0940539 15.5977841,28.4746337 15.8404292,27.8978277 C16.0833071,27.3207888 16.4386588,26.7980074 16.8862248,26.3604545 C17.3337909,25.9229017 17.8640239,25.5791933 18.4461858,25.3491229 C19.0283477,25.1192854 19.6505623,25.0084418 20.2765028,25.0219479 C20.7939283,25.0263724 21.3069293,25.1167239 21.794781,25.2902081 L21.794781,19.5985278 C21.2957518,19.4900128 20.7869423,19.436221 20.2765028,19.4380839 C18.2431278,19.4392483 16.2560928,20.0426009 14.5659604,21.1729264 C12.875828,22.303019 11.5587449,23.9090873 10.7814424,25.7878401 C10.003907,27.666593 9.80084889,29.7339663 10.1981162,31.7275214 C10.5953834,33.7217752 11.5748126,35.5530237 13.0129853,36.9904978 C14.4509252,38.4277391 16.2828722,39.4064696 18.277126,39.8028054 C20.2711469,40.1991413 22.3382874,39.9951517 24.2163416,39.2169177 C26.0948616,38.4384508 27.7002312,37.1209021 28.8296253,35.4300711 C29.9592522,33.7397058 30.5619062,31.7522051 30.5619062,29.7188301 L30.5619062,18.8324027 C32.7275484,20.3418321 35.3149087,21.0404263 38.0766847,21.0867664 L38.0766847,15.8542954 Z" id="Fill-1" fill="#000000"></path>
                  </g>
              </svg>
              </a>
            </li>
          </ul>
        </div>
        <!-- end widget -->

      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</footer>
<!-- end primary-footer -->

<footer class="footers secondary-footer">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-sm-6 col-xs-12 py-2">
        <p>Copyright 2024 &copy; <a href="#">Grupo Prevenir Express SAS</a></p>
      </div>

      <div class="col-md-6 col-sm-6 col-xs-12 py-2">
        <ul class="list-inline">
          <li><a href="https://citas.prevenirexpress.com/#/terminos-y-condiciones">Términos & condiciones</a></li>
        </ul>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</footer>
<!-- end second footer -->
