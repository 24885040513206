import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PagesComponent } from './pages.component';
import { redirectionGuard } from './guards/redirection.guard';
import { UserHomeComponent } from '../roles/user/user-home/user-home.component';
import { isLoggedInGuard } from '../auth/guards/is-logged-in.guard';
import { rolesGuard } from './guards/roles.guard';
import { NoAccessAppointment } from '../roles/user/guards/noAcesssAppointment.guard';

const routes: Routes = [
  { 
    path: '',
    component: PagesComponent,
    canActivateChild: [redirectionGuard],
    children: [
      { path: 'home', component: UserHomeComponent, data: {titulo : 'Home'} },
      { path: '', redirectTo: '/home', pathMatch: 'full' }
    ]
  },
  { 
    path: '',
    component: PagesComponent,
    canActivateChild: [isLoggedInGuard],
    canDeactivate: [NoAccessAppointment],
    children: [
      {
        path: 'user', canActivateChild: [rolesGuard], loadChildren: () => import('../roles/user/user.module').then(m => m.UserModule), data: {rol: 'user'}
      }
    ]
  },
  {
    path: '',
    canActivateChild: [isLoggedInGuard],
    component: PagesComponent,
    children: [
      {  
        path: 'provider', canActivateChild: [rolesGuard], loadChildren: () => import('../roles/provider/provider.module').then(m => m.ProviderModule), data: { rol: 'provider' }
      }
    ]
  },
  {
    path: '',
    canActivateChild: [isLoggedInGuard],
    component: PagesComponent,
    children: [
      { 
        path: 'doctor', loadChildren: () => import('../roles/doctor/doctor.module').then(m => m.DoctorModule), data: { rol: 'doctor' }
      }
    ]
  },
  {
    path: '',
    canActivateChild: [isLoggedInGuard],
    component: PagesComponent,
    children: [
      { 
        path: 'advisor', loadChildren: () => import('../roles/advisor/advisor.module').then(m => m.AdvisorModule), data: { rol: 'advisor' }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
 