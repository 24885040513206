import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { InformationService, SweetAlertService } from '@services';
import { catchError, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { LandingPageModule } from 'src/app/landing-page/landing-page.module';
import { ButtonsComponent } from 'src/app/shared-components/buttons/buttons.component';

@Component({
  selector: 'app-cancel-temp',
  standalone: true,
  imports: [CommonModule, LandingPageModule,ButtonsComponent],
  templateUrl: './cancel-temp.component.html',
  styleUrls: ['./cancel-temp.component.scss']
})
export class CancelTempComponent implements OnInit {

  hash!: string;
  requestData: any;
  imgCancel: string = 'assets/images/canceled.png'

  nameUser!:string;
  nameService!:string;
  hour!: string;
  appointmentDate!:string;
  status!:string

  constructor(private routeParam: ActivatedRoute,
              private router: Router,
              private informationService: InformationService,
              private swalService: SweetAlertService,
            ) {}
 
  ngOnInit(): void {
    
    setTimeout(() => {
       this.routeParam.paramMap.subscribe(params => {
        const hash = params.get('hash');
        // Usar servicio para cancelar
        this.getResquestDataCancell(hash);
       }) 
    }, 500);
  }

  getResquestDataCancell(hash:any){
    // Llamar al servicio
    this.informationService.getRequestCancelTemp(hash).pipe(catchError((error: HttpErrorResponse) => {
      // Manejo de error
      let status = error.status;
      if(error.error.ok === false && status === 401){
        this.swalService.lauchSwal(
          'Error de cancelacion',
          'La cita no existe o anteriormente ya fue cancelada',
          'error'
        );
        this.router.navigate(['']);
      }
      // console.error('error', error);
      return of(null);
    })).subscribe((resp) => {
      this.hash = hash;
      this.requestData = resp

      let status = this.requestData?.body?.status;
      // console.log('respuesta:', this.requestData);
      // console.log('hash', hash);

      if(this.requestData?.ok === true && status === 'cancelled'){
        // Estado de la cita
        this.status = this.requestData.body.status;
        // Nombre del user
        this.nameUser = this.requestData.body.nombres;
        // Nombre del service
        this.nameService = this.requestData.body.subService.subserviceName;
        // Formatear Hora
        this.hour = this.formatHourToAMPM(this.requestData.body.hour);
        // Formatear Fecha
        this.appointmentDate = this.formatearFechaISO(this.requestData.body.appointmentDate);
      }

    })

  }

   // Obtener la Hora en formato 12 horas
   formatHourToAMPM(hourString: string): string {
    const [hour, minutes] = hourString.split(':').map(Number); // Convierte a números
    const isPM = hour >= 12;
    const formattedHour = hour % 12 || 12; // Convierte a formato de 12 horas, usando 12 si es mediodía o medianoche
    const amPmSuffix = isPM ? 'PM' : 'AM';

    return `${formattedHour}:${minutes
      .toString()
      .padStart(2, '0')} ${amPmSuffix}`;
  }

   // Formatear la fecha de nacimiento sin librerias :)
   formatearFechaISO(fechaNacimiento: string) {
    // Extrae los componentes de la fecha
    const [fecha] = fechaNacimiento.split('T');
    const [año, mes, día] = fecha.split('-');

    // Construye la fecha en formato 'yyyy-mm-dd'
    const fechaFormateada = `${día}-${mes}-${año}`;

    return fechaFormateada;
  }

  goHome(){
    this.router.navigate(['']);
  }


}
