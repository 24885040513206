
    <div class="card-header">
        <div class="align_card_header">
            <img [src]="company.avatar|getImage" class="img_view" alt="">
            <div>
                <h1 class="name-company">{{company.companySocialProfile.name}}</h1>
                <div class="fw_400 fz_20">{{company.documentNumber}}</div>
            </div>
        </div>
    </div>
    <div class="card-body fz_18">

    <ng-container [ngTemplateOutlet]="view === 'companyView' ? companyView : ApproveView">
    </ng-container>



    <div *ngIf="viewButtons" class="btn_container">
      <!-- <button *ngIf="view==='companyView'" class="btn btn-success fw_500"
        (click)="changeView('ApproveView')">Siguiente</button> -->

      <custom-buttons *ngIf="view==='companyView'" class="fw_500" (click)="changeView('ApproveView')" text="Siguiente"
        textColor="#fff" [title]="''">
      </custom-buttons>
      <!-- <button *ngIf="view==='ApproveView'" class="btn btn-success fw_500" (click)="approveCompany('approved')">Aprobar
        Solicitud</button> -->

      <custom-buttons *ngIf="view==='ApproveView'" class="fw_500" (click)="approveCompany('approved')"
        text="Aprobar Solicitud" textColor="#fff" [title]="''">
      </custom-buttons>
      <!-- <button *ngIf="view==='ApproveView'" class="btn btn-danger fw_500" (click)="approveCompany('denied')">Denegar
        Solicitud</button> -->

      <custom-buttons *ngIf="view==='ApproveView'" class="fw_500" (click)="approveCompany('denied')"
        text="Denegar Solicitud" textColor="#fff" [title]="''" inputBackgroundColor="danger">
      </custom-buttons>
    </div>

    </div>


<ng-template #companyView>

  <div class="row">
    <div class="col-md-4 col-xs-12 mt-2">
      <label class="label_company">Estado:</label>
      <span class="txt_company">{{company.companyStates!.status | noData:'Sin dato'}}</span>
    </div>

    <div class="col-md-4 col-xs-12 mt-2">
      <label class="label_company">Estado de registro:</label>
      <span class="txt_company">{{company.companyStates!.registerState!.state | noData:'Sin dato'}}</span>
    </div>

  </div>

  <hr>

  <div class="row">

    <div class="col-md-4 col-xs-12 mt-2">
      <label class="label_company">Tipo de documento:</label>
      <span class="txt_company">{{company.documentType | noData:'Sin dato'}}</span>
    </div>

    <div class="col-md-4 col-xs-12 mt-2">
      <label class="label_company">Número de documento:</label>
      <span class="txt_company">{{company.documentNumber | noData:'Sin dato'}}</span>
    </div>

    <div class="col-md-4 col-xs-12 mt-2">
      <label class="label_company">Servicios:</label>
      <span class="txt_company">{{servicesArrayTxt}}</span>
    </div>


    <div class="col-md-4 col-xs-12 mt-2">
      <label class="label_company">Ciudad:</label>
      <span class="txt_company">{{company.municipality.cityName | noData:'Sin dato'}}</span>
    </div>


    <div class="col-md-4 col-xs-12 mt-2">
      <label class="label_company">Teléfonos:</label>
      <span class="txt_company">{{cellphonesTxt}}</span>
    </div>

    <div class="col-md-4 col-xs-12 mt-2">
      <label class="label_company">Correo electrónico:</label>
      <span class="txt_company">{{company.companySocialProfile.email | noData:'Sin dato'}}</span>
    </div>

  </div>
</ng-template>

<ng-template #ApproveView>
  <div class="container">
    <div class="alert alert-warning">
      <h3 class="text-warning">
        <i class="fa fa-exclamation-triangle"></i> Advertencia
      </h3>
      <div class="fw_400 fz_18">
        {{userData.nombres }} {{userData.apellidos}}, Esta seguro de aprobar o denegar la solicitud para esta empresa ?
      </div>
    </div>

    <form id="idApproveForm" [formGroup]="approveForm" class="mt-2 animated fadeIn">
      <div class="row">

        <div class="col-xl-6 col-md-6 col-xs-12">
          <div class="form-group">
            <form-multi-textarea label="Motivos la decisión" [idInput]="'idInputMotives'" [helper]="''"
              [txtNoData]="'No hay Motivos Registrados'"
              placeholder="Agrega los motivos de para aprobar o denengar la solicitud" [controlName]="'motives'"
              (arrayEmmiter)="arrayEmmiter($event)">
            </form-multi-textarea>
          </div>
        </div>

        <div class="col-xl-6 col-md-6 col-xs-12">
          <div class="form-group">
            <form-textarea label="Justificar la decisión" [helper]="''" [idInput]="'idInputComments'"
              placeholder="Se deniega la solicitud debido a que no se puede verificar el NIT..."
              [controlName]="'comments'">
            </form-textarea>
          </div>
        </div>
      </div>
    </form>

  </div>
</ng-template>
