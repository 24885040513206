<span [id]="idInput" class="input__group input__multitextarea">
  <div class="box__label">
    <label class="input__label" for="input_component">{{label}}<strong *ngIf="required"> *</strong></label>
    <i class="fa fa-question icon__helper" [title]="helper" *ngIf="helper"></i>
    <span class="array_counter">{{arrayData.length}}/{{limitArray}}</span>
  </div>
  <div class="container_array">
    <div class="box__input">
      <textarea #txAreaRef [formControl]="control" [placeholder]="placeholder"
        [ngClass]="{invalid: control.touched && control.invalid}" name="txArea" rows="2" [maxLength]="400"
        (keydown.enter)="selectedData?confirmUpdate():agregateArray()">
        </textarea>

      <span class="input__btn__container">
        <button *ngIf="!selectedData" class="btn btn-sm fw_500 btn-success"
          [ngClass]="{invisible: txAreaRef.value === ''}" (click)="agregateArray()">Agregar
        </button>

        <button *ngIf="selectedData" class="btn btn-sm fw_500 btn-primary mr-2"
          [ngClass]="{invisible: txAreaRef.value === ''}" (click)="confirmUpdate()">Actualizar
        </button>

        <button *ngIf="selectedData" class="btn btn-sm fw_500 btn-danger"
          [ngClass]="{invisible: txAreaRef.value === ''}" (click)="cancelUpdate()">Cancelar
        </button>

      </span>
      <i class="fa fa-close icon__clear" [ngClass]="{visible: txAreaRef.value != ''}" (click)="control.setValue('')"
        title="Limpiar"></i>
    </div>
    <div class="container_data">
        <ul class="feeds row">
            <li *ngFor="let data of arrayData; let i = index" class="li_border li_align fw_400">
                <span class="li_text">
                {{data}}
              </span>
                <span class="text-muted">
                    <div class="actions">
                      <i class="fa fa-edit update fz_17" title="Actualizar" (click)="selectData(i)"></i>
                      <i class="fa fa-trash delete fz_17" title="Eliminar" (click)="eliminarData(i)"></i>
                    </div>         
                </span>
            </li>
        </ul>
        <div *ngIf="arrayData.length==0" class="no_registrados" style="height: 100%;">
            {{txtNoData}}
        </div>
    </div>
  </div>
  <div class="box__error" [ngClass]="{visible:errors.length}">
    <ul>
      <li class="box__error__item" *ngFor="let error of errors"><strong>* </strong>{{error}}</li>
    </ul>
  </div>
</span>


<!-- <input #inputRef type="text" name="input_component" [placeholder]="placeholder" autocomplete="off" [formControl]="control" [ngClass]="{invalid: control.touched && control.invalid}"> -->
<!-- <label class="input__label" for="txArea">Descripción servicio</label> -->
<!-- <label class="input__label__helper" for="txArea">La descripción debe ser corta y concisa. 400 caracteres max</label> -->
