import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getImage'
})
export class GetImagePipe implements PipeTransform {

  transform(rutaImagen: string|undefined): string {
    // Verificar si la ruta de la imagen está vacía o es null o undefined
    if (!rutaImagen || rutaImagen.trim() === '') {
      // Si no se proporciona ninguna ruta de imagen, retornar la ruta de la imagen por defecto
      return '../../assets/images/icon/not_found.png';
    } else {
      // Si se proporciona una ruta de imagen, retornar la misma ruta
      return rutaImagen;
    }
  }

}
