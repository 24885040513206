import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-message',
  templateUrl: './custom-message.component.html',
  styleUrls: ['./custom-message.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class CustomMessageComponent implements OnInit {
  @Input() titleMessage = '';
  @Input() bodyMessage = '';
  @Input() type: 'warning' | 'success' | 'danger' | 'info' = 'warning';

  borderColor!: string;
  borderContainerColor!: string;
  backgroundColor!: string;
  fontColor!: string;
  iconType!: string;

  ngOnInit(): void {
    this.iniciarTypeMessage();
  }

  iniciarTypeMessage() {
    if (this.type === 'warning') {
      this.borderColor = '#ffc010';
      this.borderContainerColor = '1px solid #ffc010';
      this.backgroundColor = '#fef7db';
      this.fontColor = '#856404';
      this.iconType = 'fa fa-exclamation-circle';
    } else if (this.type === 'success') {
      this.borderColor = 'var(--primary)';
      this.borderContainerColor = '1px solid var(--primary)';
      this.backgroundColor = '#e3fcf7';
      this.fontColor = 'rgb(1 117 83)';
      this.iconType = 'fa fa-check-circle';
    } else if (this.type === 'danger') {
      this.borderColor = '#db3030';
      this.borderContainerColor = '1px solid #db3030';
      this.backgroundColor = '#ffeae5';
      this.fontColor = 'rgb(119 0 0)';
      this.iconType = 'fa fa-warning';
    } else if (this.type === 'info') {
      this.borderColor = 'rgb(103 148 177)';
      this.borderContainerColor = '1px solid rgb(103 148 177)';
      this.backgroundColor = '#deeaf2';
      this.fontColor = '#416a85';
      this.iconType = 'fa fa-info-circle';
    }
  }
}
