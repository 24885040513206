<!-- ============================================================== -->
<!-- Topbar header - style you can find in pages.scss -->
<!-- ============================================================== -->
<header class="topbar">
  <nav class="navbar top-navbar navbar-expand-md navbar-light" >
    <!-- ============================================================== -->
    <!-- Logo -->
    <!-- ============================================================== -->
    <div class="navbar-header">
      <a class="navbar-brand" href="index.html">
        <!-- Logo icon --><b>
          <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
          <!-- Dark Logo icon -->
          <img
            src="./assets/images/logo-icon.png"
            alt="homepage"
            class="dark-logo logo_size animated fadeIn"
          />
          <!-- Light Logo icon -->
          <img
            [src]="iconPath"
            alt="homepage"
            class="light-logo logo_size animated fadeIn"
          />
        </b>
        <!--End Logo icon -->
        <!-- Logo text --><span>
          <!-- dark Logo text -->
          <img
            src="./assets/images/logo-text.png"
            alt="homepage"
            class="dark-logo texto_size animated fadeIn" />
          <!-- Light Logo text -->
          <img
            src="./assets/images/logo-light-text.png"
            class="light-logo texto_size animated fadeIn"
            alt="homepage"
        /></span>
      </a>
    </div>
    <!-- ============================================================== -->
    <!-- End Logo -->
    <!-- ============================================================== -->
    <div class="navbar-collapse">
      <!-- ============================================================== -->
      <!-- toggle and nav items -->
      <!-- ============================================================== -->
      <ul class="navbar-nav mr-auto">
        <li class="nav-item ">
          <!-- <a
            class="nav-link nav-toggler hidden-md-up waves-effect waves-dark"
            href="javascript:void(0)"
            ><i class="ti-menu" ></i
          ></a> -->
        </li>
        <li class="nav-item">
          <!-- <a
            class="nav-link sidebartoggler hidden-sm-down waves-effect waves-dark"
            href="javascript:void(0)"
            ><i class="ti-menu"></i
          ></a> -->
        </li>
        <li class="nav-item hidden-sm-down"></li>
      </ul>
      <!-- ============================================================== -->
      <!-- User profile and search -->
      <!-- ============================================================== -->
      <ul class="navbar-nav my-lg-0">

        <!-- ============================================================== -->
        <!-- Comment -->
        <!-- ============================================================== -->
        <!-- <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle waves-effect waves-dark"
            href=""
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="mdi mdi-message"></i>
            <div class="notify">
              <span class="heartbit"></span> <span class="point"></span>
            </div>
          </a>
          <div
            class="dropdown-menu dropdown-menu-right mailbox animated fadeIn fast"
          >
            <ul>
              <li>
                <div class="drop-title">Notifications</div>
              </li>
              <li>
                <div class="message-center">

                  <a href="#">
                    <div class="btn btn-danger btn-circle">
                      <i class="fa fa-link"></i>
                    </div>
                    <div class="mail-contnet">
                      <h5>Luanch Admin</h5>
                      <span class="mail-desc">Just see the my new admin!</span>
                      <span class="time">9:30 AM</span>
                    </div>
                  </a>

                  <a href="#">
                    <div class="btn btn-success btn-circle">
                      <i class="ti-calendar"></i>
                    </div>
                    <div class="mail-contnet">
                      <h5>Event today</h5>
                      <span class="mail-desc"
                        >Just a reminder that you have event</span
                      >
                      <span class="time">9:10 AM</span>
                    </div>
                  </a>

                  <a href="#">
                    <div class="btn btn-info btn-circle">
                      <i class="ti-settings"></i>
                    </div>
                    <div class="mail-contnet">
                      <h5>Settings</h5>
                      <span class="mail-desc"
                        >You can customize this template as you want</span
                      >
                      <span class="time">9:08 AM</span>
                    </div>
                  </a>

                  <a href="#">
                    <div class="btn btn-primary btn-circle">
                      <i class="ti-user"></i>
                    </div>
                    <div class="mail-contnet">
                      <h5>Pavan kumar</h5>
                      <span class="mail-desc">Just see the my admin!</span>
                      <span class="time">9:02 AM</span>
                    </div>
                  </a>
                </div>
              </li>
              <li>
                <a class="nav-link text-center" href="javascript:void(0);">
                  <strong>Check all notifications</strong>
                  <i class="fa fa-angle-right"></i>
                </a>
              </li>
            </ul>
          </div>
        </li> -->
        <!-- ============================================================== -->
        <!-- End Comment -->
        <!-- ============================================================== -->


        <!-- ============================================================== -->
        <!-- Profile -->
        <!-- ============================================================== -->
        <li class="nav-item dropdown" >
          <a
            class="nav-link dropdown-toggle waves-effect waves-dark"
            href=""
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            ><img
              [src]="rolSelect.avatar|getImage"
              alt="user"
              class="profile-pic"
          /></a>
          <div class="dropdown-menu dropdown-menu-right animated fadeIn fast " style="background-color: #242a33; border-radius: 15px;">
            <ul class="dropdown-user">
              <li>
                <div class="dw-user-box user_box">
                  <div class="u-img">
                    <img [src]="rolSelect.avatar|getImage" alt="user" />
                  </div>
                  <div class="u-text">
                    <h4 class="mb-2  text-white">{{ rolSelect.name}}</h4>
                    <!-- <br> -->
                    <!-- <p class="text-muted">geovanyojeda14@gmail.com</p> -->
                    <custom-buttons
                      text="Ver perfil"
                      title="Permite ver mi perfil"
                      textColor="#fff"
                      inputBackgroundColor="primary"
                      width="100px"
                      type="button"
                      (click)="viewProfile()"
                      >
                    </custom-buttons>
                  </div>
                </div>
              </li>
              <!-- <li role="separator" class="divider"></li> -->
              <!-- <li>
                <a href="#"><i class="ti-user"></i> Mi Perfil</a>
              </li> -->
              <!-- <li>
                <a href="#"><i class="ti-wallet"></i> My Balance</a>
              </li> -->
              <!-- <li>
                <a href="#"><i class="ti-email"></i> Inbox</a>
              </li>
              <li role="separator" class="divider"></li> -->
              <!-- <li>
                <a routerLink="account-settings"
                  ><i class="ti-settings"></i> Opciones</a
                >
              </li> -->
              <li role="separator" class="divider"></li>
              <li class="text-center">
                <custom-buttons
                  text="Cerrar sesion"
                  title="Permite cerrar sesion"
                  textColor="#fff"
                  inputBackgroundColor="danger"
                  icon="fa fa-sign-out"
                  width="250px"
                  type="button"
                  (click)="logout()"
                  >
                </custom-buttons>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</header>
<!-- ============================================================== -->
<!-- End Topbar header -->
<!-- ============================================================== -->
