import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { CellPhone } from '../../../interfaces/information.interface';
import { InformationService } from '@services';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';

@Component({
  selector: 'app-cell-phones-list',
  templateUrl: './cell-phones-list.component.html',
  styleUrls: ['./cell-phones-list.component.scss'],
})
export class CellPhonesListComponent implements OnInit {
  @Input() idContainer = '';
  @Input() classCol = 'col-md-6';
  @Input() txtTitle = '';
  @Input() data!: CellPhone[];
  @Output() cellPhonesEmitter: EventEmitter<CellPhone[]> = new EventEmitter<
    CellPhone[]
  >();

  @Input()helperMovil='Registra el numero de tu telefono movil y selecciona al menos una opcion, puede ser para llamar, para mensajes de Whatsapp o ambas';
  @Input()helperFijo='Selecciona el indicador del telefono fijo y luego registra el numero';

  prefijos = [
    { name: 'Bogota', prefix: '601' },
    { name: 'Nariño', prefix: '602' },
    { name: 'Antioquia', prefix: '604' },
    { name: 'Atlantico', prefix: '605' },
    { name: 'Caldas', prefix: '606' },
    { name: 'Santander', prefix: '607' },
    { name: 'Santander', prefix: '607' },
    { name: 'Amazonas', prefix: '608' },
  ];

  cellPhones: CellPhone[]=[];

  hayMovilesRegistrados!: boolean;
  hayFijosRegistrados!: boolean;

  movilForm!: CellPhone;
  fijoForm!: CellPhone;

  movilSelected?: CellPhone;
  fijoSelected?: CellPhone;

  movilError = '';
  movilSelectError = '';
  fijoError = '';
  prefixError = '';

  constructor(private infoService: InformationService) {}

  ngOnInit(): void {
    this.iniciarData();
  }

  iniciarData() {
    if (this.data) {      
      this.cellPhones = this.data;
    } else{
      this.cellPhones=[];
    }

    this.actualizarEstadoMoviles();
    this.actualizarEstadoFijos();
    this.iniciarFormularioMovil();
    this.iniciarFormularioFijo();
  }

  actualizarEstadoMoviles() {
      this.hayMovilesRegistrados = this.cellPhones.some(
        (cellPhone) => cellPhone.movil
      );      
  }

  actualizarEstadoFijos() {
      this.hayFijosRegistrados = this.cellPhones.some(
        (cellPhone) => !cellPhone.movil
      );
  }

  iniciarFormularioMovil() {
    this.movilForm = {
      phone: '',
      llamadas: true,
      whatsapp: false,
      movil: true,
      prefix: '+57',
    };
  }

  iniciarFormularioFijo() {
    this.fijoForm = {
      phone: '',
      llamadas: true,
      whatsapp: false,
      movil: false,
      prefix: '',
    };
  }

  cambioSelect(e: any) {
    const select = e.target.value;
    if (select.length > 0) {
      this.prefixError=''
      const selectElement = document.getElementById('idPrefixFijo');
      if (selectElement) {
        selectElement.classList.remove('input_error');
      }
    }
  }

  validarLargoMovil(numero: string) {
    const patron = /^3\d{9}$/;
    return patron.test(numero);
  }

  validarLargoFijo(numero: string) {
    return numero.length >= 7;
  }

  registrarFijo(form: CellPhone) {
    const fijo = { ...form };
    this.fijoError = '';
    this.prefixError = '';
    const buscarFijo = this.cellPhones.find((f) => fijo.phone === f.phone);
    const inputElement = document.getElementById('idCellPhoneFijo');
    const selectElement = document.getElementById('idPrefixFijo');
    const idCellsContainer = document.getElementById(this.idContainer);

    if (form.prefix?.length == 0) {
      this.prefixError = 'Debe seleccionar un prefijo!!';
      if (selectElement) {
        selectElement.classList.add('input_error');
      }
    } else if (!this.validarLargoFijo(form.phone)) {
      this.fijoError = 'El número de teléfono debe tener más de 7 caracteres!!';
      if (inputElement) {
        inputElement.classList.add('input_error');
      }
    } else if (buscarFijo && this.fijoSelected?.phone !== form.phone) {
      this.fijoError = 'Este número de teléfono ya está registrado!!';
      if (inputElement) {
        inputElement.classList.add('input_error');
      }
    } else {
      if (inputElement) {
        inputElement.classList.remove('input_error');
      }
      if (selectElement) {
        selectElement.classList.remove('input_error');
      }
      if (idCellsContainer) {
        idCellsContainer.classList.remove('container_error');        
      }
      this.cellPhones.push(fijo);
      this.iniciarFormularioFijo();
      this.actualizarEstadoFijos();
    }
    this.cellPhonesEmitter.emit(this.cellPhones);
  }

  async registrarMovil(form: CellPhone) {

    const movil = { ...form };    
    this.movilError = '';
    this.movilSelectError = '';
    const inputElement = document.getElementById('idCellPhone');
    const labelElement_l = document.getElementById('idLabelCheck_l');
    const labelElement_w = document.getElementById('idLabelCheck_w');
    const idCellsContainer = document.getElementById(this.idContainer);

      const buscarMovil = this.cellPhones.find((m) => movil.phone === m.phone);
  
      if (!this.validarLargoMovil(form.phone)) {
        this.movilError =
          'El número de teléfono debe iniciar por 3 y tener 10 caracteres!!';
        if (inputElement) {
          inputElement.classList.add('input_error');
        }
      } else if (buscarMovil && this.movilSelected?.phone !== form.phone) {
        this.movilError = 'Este número de teléfono ya está registrado!!';
        if (inputElement) {
          inputElement.classList.add('input_error');
        }
      } else {
        if (inputElement) {
          inputElement.classList.remove('input_error');
        }
        if (labelElement_l) {
          labelElement_l.classList.remove('text-danger');
        }
        if (labelElement_w) {
          labelElement_w.classList.remove('text-danger');
        }
        if (idCellsContainer) {
          idCellsContainer.classList.remove('container_error');        
        }

        // const { phone } = movil;

        //TODO: no se esta validando si existe
        const exists = await this.checkWhatsapp(this.movilForm.phone);
        // console.log(exists);
        
        movil.whatsapp = exists;


        this.cellPhones.push(movil);
        this.iniciarFormularioMovil();
        this.actualizarEstadoMoviles();
      }
      this.cellPhonesEmitter.emit(this.cellPhones);
    

  }

  selectMovil(cellPhone: CellPhone) {
    const inputElement = document.getElementById('idCellPhone');
    const labelElement_l = document.getElementById('idLabelCheck_l');
    const labelElement_w = document.getElementById('idLabelCheck_w');
    this.movilError = '';
    this.movilSelectError = '';

    if (inputElement) {
      inputElement.classList.remove('input_error');
    }
    if (labelElement_l) {
      labelElement_l.classList.remove('text-danger');
    }
    if (labelElement_w) {
      labelElement_w.classList.remove('text-danger');
    }

    this.movilSelected = cellPhone;
    this.movilForm = { ...this.movilSelected };
  }

  selectFijo(cellPhone: CellPhone) {
    const inputElement = document.getElementById('idCellPhoneFijo');
    const selectElement = document.getElementById('idPrefixFijo');
    this.fijoError = '';
    this.prefixError = '';

    if (inputElement) {
      inputElement.classList.remove('input_error');
    }

    if (selectElement) {
      selectElement.classList.remove('input_error');
    }

    this.fijoSelected = cellPhone;
    this.fijoForm = { ...this.fijoSelected };
  }

  cancelUpdateMovil() {
    this.movilSelected = undefined;
    this.movilError = '';
    this.movilSelectError = '';
    this.iniciarFormularioMovil();
    const inputElement = document.getElementById('idCellPhone');
    const labelElement_l = document.getElementById('idLabelCheck_l');
    const labelElement_w = document.getElementById('idLabelCheck_w');

    if (inputElement) {
      inputElement.classList.remove('input_error');
    }
    if (labelElement_l) {
      labelElement_l.classList.remove('text-danger');
    }
    if (labelElement_w) {
      labelElement_w.classList.remove('text-danger');
    }
  }

  cancelUpdateFijo() {
    this.fijoSelected = undefined;
    this.fijoError = '';
    this.prefixError = '';
    this.iniciarFormularioFijo();
    const inputElement = document.getElementById('idCellPhoneFijo');
    const selectElement = document.getElementById('idPrefixFijo');

    if (inputElement) {
      inputElement.classList.remove('input_error');
    }
    if (selectElement) {
      selectElement.classList.remove('input_error');
    }
  }

  updateFijo() {
    this.fijoError = '';
    this.prefixError = '';
    const inputElement = document.getElementById('idCellPhoneFijo');
    const selectElement = document.getElementById('idPrefixFijo');

    const buscarFijo = this.cellPhones.find(
      (f) => this.fijoForm.phone === f.phone
    );

    if (this.fijoForm.prefix?.length == 0) {
      this.prefixError = 'Debe seleccionar un prefijo!!';
      if (selectElement) {
        selectElement.classList.add('input_error');
      }
    } else if (!this.validarLargoFijo(this.fijoForm.phone)) {
      this.fijoError = 'El número de teléfono debe tener más de 7 caracteres!!';
      if (inputElement) {
        inputElement.classList.add('input_error');
      }
    } else if (buscarFijo && this.fijoSelected?.phone !== this.fijoForm.phone) {
      this.fijoError = 'Este número de teléfono ya está registrado!!';
      if (inputElement) {
        inputElement.classList.add('input_error');
      }
    } else {
      const selFijo = this.fijoSelected ? this.fijoSelected.phone : '';
      const index = this.cellPhones.findIndex((cell) => cell.phone === selFijo);

      if (index !== -1) {
        this.cellPhones[index] = { ...this.fijoForm };
      }
      if (inputElement) {
        inputElement.classList.remove('input_error');
      }
      if (selectElement) {
        selectElement.classList.remove('input_error');
      }
      this.cancelUpdateFijo();
    }
    this.cellPhonesEmitter.emit(this.cellPhones);
  }

  async updateMovil() {
    this.movilError = '';
    this.movilSelectError = '';
    const inputElement = document.getElementById('idCellPhone');
    const labelElement_l = document.getElementById('idLabelCheck_l');
    const labelElement_w = document.getElementById('idLabelCheck_w');

    const buscarMovil = this.cellPhones.find(
      (m) => this.movilForm.phone === m.phone
    );

    if (!this.validarLargoMovil(this.movilForm.phone)) {
      this.movilError =
        'El número de teléfono debe iniciar por 3 y tener 10 caracteres!!';
      if (inputElement) {
        inputElement.classList.add('input_error');
      }
    } else if (
      buscarMovil &&
      this.movilSelected?.phone !== this.movilForm.phone
    ) {
      this.movilError = 'Este número de teléfono ya está registrado!!';
      if (inputElement) {
        inputElement.classList.add('input_error');
      }
    } else {
      const selMovil = this.movilSelected ? this.movilSelected.phone : '';
      const index = this.cellPhones.findIndex(
        (cell) => cell.phone === selMovil
      );

      const exists = await this.checkWhatsapp(this.movilForm.phone);
      this.movilForm.whatsapp = exists;


      if (index !== -1) {
        this.cellPhones[index] = { ...this.movilForm };
      }

      if (inputElement) {
        inputElement.classList.remove('input_error');
      }
      if (labelElement_l) {
        labelElement_l.classList.remove('text-danger');
      }
      if (labelElement_w) {
        labelElement_w.classList.remove('text-danger');
      }

      this.cancelUpdateMovil();
    }
    this.cellPhonesEmitter.emit(this.cellPhones);
  }

  deletePhone(cellPhone: CellPhone) {
    Swal.fire({
      title: 'Estás seguro?',
      text: `Se va a eliminar el teléfono ${cellPhone.phone} de tu lista de teléfonos`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar teléfono!',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.cellPhones = this.cellPhones.filter(
          (objeto) => objeto.phone !== cellPhone.phone
        );
        this.actualizarEstadoMoviles();
        this.actualizarEstadoFijos();

        this.cellPhonesEmitter.emit(this.cellPhones);
      }
    });
  }

  // Metodo para verificar si un telefono tiene whatsapp o no
  async checkWhatsapp(phone: string): Promise<boolean> {
    return await firstValueFrom(this.infoService.verifyWhatsAppNumber(phone));
  }
}
  