<div *ngFor="let modulo of menuWeb,let m = index" class="module_container" [style.width]="inputWidth + 'px'">
    <div class="module_option" >
        <div class="module_text">Modulo: {{modulo.module}}</div>
        <div>
            <input type="checkbox" [id]="'module_'+modulo.module" class="filled-in" [checked]="modulo.active" (change)="setCheckedModul($event,m)">
            <label class="fw_700" [for]="'module_'+modulo.module">Activar modulo</label>
        </div>
    </div>
    <!-- table -->
    <div class="table-responsive table-sm no-wrap mt-4 animated fadeIn">
        <div class="table-container">
            <table class="table color-table" [class.muted-table]="!modulo.active" *ngFor="let option of modulo.options, let o = index">
                <thead>
                    <tr class="option_title">
                        <th class="option_name_width">{{option.option}}</th>
                        <th>Ruta</th>
                        <th class="option_type_width">Ver</th>
                        <th class="option_type_width">Crear</th>
                        <th class="option_type_width">Editar</th>
                        <th class="option_type_width">Eliminar</th>
                    </tr>
                </thead>
                <tbody class="table-body">
                    <tr *ngFor="let menu of option.subMenu, let mn = index" class="fw_400 animated fadeIn" [class.item_hover]="modulo.active" [class.row_disable]="!modulo.active">
                        <td>
                            <div class="option_name" [title]="menu.name">{{menu.name}}</div>
                        </td>
                        <td>
                            <div class="fw_500" [title]="menu.route">{{menu.route}}</div>
                        </td>
    
                        <td>
                            <input type="checkbox" [id]="'menu_view_'+m+o+mn" [disabled]="!modulo.active"
                            class="filled-in" [checked]="menu.view" (change)="setCheckedOptionView($event,m,o,mn)">
                            <label class="align_label" [for]="'menu_view_'+m+o+mn" title="Permiso Ver"></label>
                        </td>
                        <td>
                            <input type="checkbox" [id]="'menu_create_'+m+o+mn" [disabled]="!modulo.active"class="filled-in" [checked]="menu.permissions.create" (change)="setCheckedOptionPermissions($event,'create',m,o,mn)">
                            <label class="align_label" [for]="'menu_create_'+m+o+mn" title="Permiso Crear"></label>
                        </td>
                        <td>
                            <input type="checkbox" [id]="'menu_update_'+m+o+mn" [disabled]="!modulo.active"class="filled-in" [checked]="menu.permissions.update" (change)="setCheckedOptionPermissions($event,'update',m,o,mn)">
                            <label class="align_label" [for]="'menu_update_'+m+o+mn" title="Permiso Editar"></label>
                        </td>
                        <td>
                            <input type="checkbox" [id]="'menu_delete_'+m+o+mn" [disabled]="!modulo.active"class="filled-in" [checked]="menu.permissions.delete" (change)="setCheckedOptionPermissions($event,'delete',m,o,mn)">
                            <label class="align_label" [for]="'menu_delete_'+m+o+mn" title="Permiso Eliminar"></label>
                        </td>
                       
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>