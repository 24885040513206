import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hourFormat'
})
export class HourFormatPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return '';
    }
    
    // Separar la hora y los minutos
    const [hour, minutes] = value.split(':');
    
    // Convertir la hora a un número entero
    let hourNumber = parseInt(hour);
    
    // Determinar si es AM o PM
    const meridian = hourNumber >= 12 ? 'PM' : 'AM';
    
    // Convertir la hora al formato de 12 horas
    if (hourNumber > 12) {
      hourNumber -= 12;
    } else if (hourNumber === 0) {
      hourNumber = 12;
    }
    
    // Formatear la hora con los minutos y la meridiana
    return `${hourNumber}:${minutes} ${meridian}`;
  }

}
