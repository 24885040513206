import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ControlContainer, FormArray, FormControl, FormGroupDirective, Validators } from '@angular/forms';

@Component({
  selector: 'form-pills',
  templateUrl: './form-pills.component.html',
  styleUrls: ['../../form-styles.scss', './form-pills.component.scss']
})
export class FormPillsComponent implements AfterViewInit {
  @ViewChild('inputPillsRef') inputPillsRef!: ElementRef;
  @Input() controlName!: string;
  @Input() placeholder: string = 'Default placeholder';
  @Input() helper: string | undefined = 'Help default pills';
  @Input() label: string = 'Default label pills';
  @Input() keywordListLimit: number = 10;
  @Input() required: boolean = true;

  keywordList: string[] = ['']

  errors: any[] = [];
  value: any;

  constructor(private controlContainer: ControlContainer) { }

  /** Set data on control when already exist a value */
  ngOnInit(): void {
    if (this.control.value) {
      let itemList = this.control.value;
      this.keywordList = itemList;
    }
  }

  ngAfterViewInit(): void {
    let required = this.control.hasValidator(Validators.required)
    this.control.setValidators([this.validateWordCount]);
    required && this.control.addValidators(Validators.required)
    this.control.updateValueAndValidity();

    this.control.statusChanges.subscribe(() => {
      this.errors = [];
      if (!this.control.errors || !this.control.dirty) return;
      this.control.errors['required'] && this.errors.push(`El campo es requerido`);
    });
  }

  get control(): FormArray {
    const parentFormGroup = this.controlContainer as FormGroupDirective;
    return parentFormGroup.control.get(this.controlName) as FormArray;
  }

  get controlInvalid(): boolean {
    return this.control?.invalid || false;
  }

  get controlTouched(): boolean {
    return this.control?.touched || false;
  }

  get errorKeys(): string[] {
    return this.control?.errors ? Object.keys(this.control.errors) : [];
  }

  addKeyword(event: KeyboardEvent) {
    !this.control.dirty && this.control.markAsDirty()

    let value = (event.target as HTMLInputElement).value;
    let keyEvent = event.key;
    if (value == '') return

    if (keyEvent == ' ' || keyEvent == 'Enter') {
      if (this.keywordList.includes(value.trim())) return

      this.keywordList.push(value.trim());
      (event.target as HTMLInputElement).value = '';
      (event.target as HTMLInputElement).focus()
      this.control.setValue([...this.keywordList]);
    }
  }

  removeKeyword(keyword: string) {
    !this.control.dirty && this.control.markAsDirty();
    this.keywordList = this.keywordList.filter(item => item != keyword);
    this.control.setValue([...this.keywordList]);
  }

  validateWordCount() {
    return (control: FormControl): { [key: string]: any } | null => {
      const words = control.value || [];

      if (words.length !== this.keywordListLimit) {
        return { wordCount: { expected: this.keywordListLimit, actual: words.length } };
      }

      return null;
    };
  }
}
