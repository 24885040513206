<div class="card_imagen_header">
  <div class="title_header fw_500 fz_20 ml-4">Administrar Beneficiarios y Mascotas</div>
  <button type="button" id="idCerrarCitas" class="btn btn-circle btn_back" title="Cerrar"
    (click)="cerrarBeneficiaries()"><i class="ti ti-close"></i>
  </button>

  <!-- <custom-buttons type="button" id="idCerrarCitas" class="btn btn-circle btn_back" (click)="cerrarBeneficiaries()"
    text="" textColor="#fff" [title]="'Cerrar'" icon="ti ti-close">
    <i class="ti ti-close"></i>
  </custom-buttons> -->
</div>

<div class="">
  <div class="card-body">
    <div class="animated fadeIn">

      <app-tabs [tabsInfo]="tabs" [classInput]="'fz_15 mb-4'" (switch)="switch($event)">
      </app-tabs>

      <ng-container *ngIf="groupSelected">

        <ng-container *ngIf="switchPerfil=='beneficiariesComponent'">
          <app-beneficiaries class="animated fadeIn" [familyGroup]="groupSelected" [options]="options">
          </app-beneficiaries>
        </ng-container>

        <ng-container *ngIf="switchPerfil=='petsComponent'">
          <app-pets class="animated fadeIn" [familyGroup]="groupSelected" [options]="options">
          </app-pets>
        </ng-container>

      </ng-container>



    </div>
  </div>
</div>
