import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent {
  
  @Input() data: any[] = [];
  @Input() valueInput='_id';
  @Input() nameInput='name';
  @Input() seleccionarBorrar=false;
  @Output() seleccionEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() vacioEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() noSeleccionadoEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  @Input() placeholder = ' ';
  @Input() labelTxt = '';
  @Input() autocompleteInput = 'off';
  @Input() requiredInput = false;
  @Input() largoSplit = 4;

  termino = '';
  seleccionado?: any;
  verSugerencia = false;

  resultados: any[] = [];

  /**
   * Escucha el evento del input y realiza una busqueda en el array
   * @param  {any} e evento que se escucha del input y se extrae el value
   */
  buscarTermino(e: any) {
    this.seleccionado = undefined;
    this.termino = e.target.value;
    let nData = this.data;
    
    this.noSeleccionadoEmitter.emit(true);
    

    if (this.termino.length > 0) {
      this.termino = this.termino.toUpperCase();
      
      // Asegúrate de que selectedField esté definido y corresponda a una propiedad válida.
      if (this.valueInput && nData[0].hasOwnProperty(this.valueInput)) {
          this.resultados = nData.filter((n) =>
              n[this.valueInput].toUpperCase().includes(this.termino)
          );
      }
      // //console.log(this.resultados);
  } 
    else {
      this.resultados = [];
      this.vacioEmitter.emit(true);
    }

    // quitar if para ver el boton ver sugerencias
    if (this.resultados.length == 0) {
      this.darSugerencias();
    }
  }

  limpiarTermino(){
    this.termino = ''
  }

  /**
   * Retornar sugerencias en caso de que la primera busqueda no sea efectiva.
   * Convierte el termino de busqueda en un array de terminos para hacer varias busquedas.
   * Crea el campo cantidad con el numero de coincidencias como resultado de la busqueda.
   * Ordena por medio del campo cantidad de mayor a menor numero de coincidencias.
   */
  darSugerencias(): any[] {
    this.verSugerencia = true;
    this.resultados = [];
    this.seleccionado = undefined;
    const terminos = this.splitTextIntoArray(this.termino, this.largoSplit);
    let nData = this.data;
    for (const termino of terminos) {
      const nBusqueda = nData.filter((n) =>
        n[this.valueInput].toUpperCase().includes(termino)
      );

      this.resultados = this.resultados.concat(nBusqueda);
    }
    return (this.resultados = this.contarCoincidencias(this.resultados));
  }

  /**
   * Convertir texto en array de textos con un largo establecido
   * @param  {string} text texto que se va a convertir en array
   * @param  {number} largo largo del cual van a ser los elementos del array
   */
  splitTextIntoArray(text: string, largo: number): string[] {
    var result = [];

    for (var i = 0; i < text.length; i += largo) {
      result.push(text.substr(i, largo));
    }

    return result;
  }

  /**
   * Convierte el array con duplicados en un array con el campo cantidad de repeticiones y lo ordena de mayor a menor
   * @param  {Autocomplete[]} array con datos duplicados
   */
  contarCoincidencias(array: any[]): any[] {
    let coincidencias: any = {};

    array.forEach((objeto: any) => {
      const id = objeto._id;
      coincidencias[id] = coincidencias[id] ? coincidencias[id] + 1 : 1;
    });

    const miArray2: any[] = [];

    array.forEach((objeto) => {
      const id = objeto._id;

      if (!coincidencias[id]) {
        return;
      }

      miArray2.push({
        ...objeto,
        cantidad: coincidencias[id],
      });

      coincidencias[id] = 0;
    });
    const ordenados = this.ordenarPorCantidad(miArray2);
    //console.log(ordenados);

    return ordenados;
  }

  /**
   * Ordena el array de mayor a menor cantidad
   * @param  {Autocomplete[]} array con campo cantidad que se va a ordenar
   */
  ordenarPorCantidad(array: any[]) {
    array.sort((a, b) => b.cantidad - a.cantidad);
    return array;
  }

  borrandoTermino(e: any) {
    const nTermino = e.target.value;
    if (nTermino.length >= 4) {
      //console.log(nTermino);
    }
  }

  /**
   * Selecciona el item al que se le da click y lo emite
   * @param  {any} item que se selecciona y se emite
   */
  seleccionarItem(item: any) {
    
    this.seleccionado = item;
    this.termino = item[this.nameInput];
    this.resultados = [];

    this.seleccionEmitter.emit(this.seleccionado);
    this.noSeleccionadoEmitter.emit(false);

    if (this.seleccionarBorrar) {
      this.limpiarTermino();      
    }
  }
}
