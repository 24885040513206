import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-image-loader',
  templateUrl: './image-loader.component.html',
  styleUrls: ['./image-loader.component.scss']
})
export class ImageLoaderComponent {
  @Input() imageUrl!: string;

  @Output() imageEmitter = new EventEmitter<string>();
  loading: boolean = true;
  error: boolean = false;

  constructor() { }

  // Método para manejar el evento de carga de la imagen
  handleImageLoad() {
    this.loading = false; // Indicar que la imagen se ha cargado correctamente
  }

  // Método para manejar el evento de error al cargar la imagen
  handleImageError() {
    this.loading = false; // Indicar que ha ocurrido un error al cargar la imagen
    this.error = true;    // Indicar que se debe mostrar la imagen por defecto
  }

  imgEmitter(){
    this.imageEmitter.emit(this.imageUrl);
  }

}
