import { ReduxBranches } from "@interfaces/shared-components.interface";
import { createAction, props } from "@ngrx/store";

export const setBranches = createAction(
    '[Branches] Set Branches', 
    props<{ branches: ReduxBranches[] }>()
);

export const loadBranches = createAction(
    '[Branches] Load Branches',
    props<{ companyId: string }>()
);

export const selectBranch = createAction(
    '[Branches] Select Branch',
    props<{ branchSelected: ReduxBranches }>()
);
