import { Component,OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '@services';
import { FamilyGroupsServiceService } from 'src/app/roles/services/family-groups-service.service';
import { FamilyGroupService } from 'src/app/services/familyGroupAndBeneficiary.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-family-group-and-pet',
  templateUrl: './family-group-and-pet.component.html',
  styleUrls: ['./family-group-and-pet.component.scss']
})
export class FamilyGroupAndPetComponent implements OnInit {

formFamilyGroupAndPet!: FormGroup;
agePet: any;

genders=[
  {value:'Male',name:'Macho'},
  {value:'Female',name:'Hembra'},
];

sterilizationStates=[
  {value:false,name:'NO'},
  {value:true,name:'SI'},
];

constructor(private familyService: FamilyGroupsServiceService,
            private familyGroupService:FamilyGroupService,
            private modalService: ModalService,
){}

ngOnInit(): void {
  this.initializedFormPets();
}

initializedFormPets(){
this.formFamilyGroupAndPet = new FormGroup({
  nameFamily: new  FormControl('',[Validators.required, Validators.minLength(2)]),
  petName: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(20)]),
  species: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(20)]),
  breed: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(20)]),
  gender: new FormControl<string | null>(null, Validators.required),
  color: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(20)]),
  birth_date: new FormControl('', [Validators.required]),
  age: new FormControl<string|null>({value: null, disabled: true}),
  sterilized:new FormControl<boolean | null>(null, Validators.required)
})

//console.log(this.formFamilyGroupAndPet.value);

 //Para calcular la edad en tiempo real
 this.formFamilyGroupAndPet.get('birth_date')?.valueChanges.subscribe(value => {
  this.formFamilyGroupAndPet.controls['age'].setValue(this.calculateAge(value));
});

}

// Función para calcular la edad sin usar librerías externas
calculateAge(birthdateValue: string): any {
  if (!birthdateValue) {
    return null;
  }
  const birthdate = new Date(birthdateValue);
  const today = new Date();
  let age = today.getFullYear() - birthdate.getFullYear();
  const m = today.getMonth() - birthdate.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
    age--;
  }

  return age;
}

createFamilyGroupAndPet(){

  const {nameFamily, petName, species, breed, gender, color, birth_date, age, sterilized } = this.formFamilyGroupAndPet.value;
  this.agePet = this.formFamilyGroupAndPet.get('age')?.value;
  //console.log(this.agePet);

  if(this.formFamilyGroupAndPet.valid){

  const  newGroupFamilyAndPet = {
    familyGroupName: nameFamily,
    pet: {
      petName,
      species,
      breed,
      gender,
      color,birth_date,
      age: this.agePet,
      sterilized
    }
  }
    // Crea el nuevo grupo familiar y la mascota
  this.familyService.addGroupFamilyAndBeneficiary(newGroupFamilyAndPet).then(() => {
    //console.log('Grupo familiar y Mascota creada correctamente',newGroupFamilyAndPet);
     // Notificar al servicio que el grupo familiar ha sido actualizado
     this.familyGroupService.notifyFamilyGroupUpdated();
     //console.log('se ha actualizado los grupos familiares de las mascostas');
  });
    

  }
}


// Validar el form de Pets
async validateAndCreate() {
    
  if( this.formFamilyGroupAndPet.invalid ){
    //console.log('datos del form Pets', this.formFamilyGroupAndPet.value);
    this.formFamilyGroupAndPet.markAllAsTouched();
    console.error('error en el form Pets');
  }

if(this.formFamilyGroupAndPet.valid) {
  // Abrir una sweet alert de confirmacion para registrar el nuevo grupo familiar y Pet
   let nombres = this.formFamilyGroupAndPet.get('petName')?.value;
   let grupo = this.formFamilyGroupAndPet.get('nameFamily')?.value;
  const  alert = await Swal.fire({
    title: 'Estás seguro?',
    text: `Se va a Agregar  ${ nombres||''} a tu grupo familiar ${grupo || ' '} `,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#00ab78',
    cancelButtonColor: '#e74c3c',
    confirmButtonText: 'Si, agregar!',
    cancelButtonText: 'Cancelar',
  })

  if(alert.isConfirmed){
    // Llamar los metodos  para crear y actualizar la vista de los datos de  los grupos familiares
    this.createFamilyGroupAndPet();
    // Cerrar el modal y ver como se actualiza la lista de los grupos familiares en sub-service-info
    setTimeout(()=> {
        this.modalService.closeModal();
    },400);

  }

  return;
}


}


}
