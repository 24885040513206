import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, Output, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'day-info',
  templateUrl: './day-info.component.html',
  styleUrls: ['./day-info.component.scss']
})
export class DayInfoComponent {
  @ViewChild('patientDataRef', { read: ViewContainerRef }) private patientDataRef!: ViewContainerRef;

  @Input() dataInfo: any
  @Input() dateInfo: any
  @Output() closeTaskInfo = new EventEmitter<boolean>()

  private visible: boolean = false
  patientData: any = null;

  @HostListener('window:click', ['$event'])
  onClick($event: Event) {
    const inside = (<HTMLElement>$event.target).closest('.day__container')
    this.visible && !inside && this.closeTaskInfo.emit()
  }

  ngAfterViewInit() {
    setTimeout(() => this.visible = true, 100);
  }

  showDataInfo(event: Event, data: any) {
    console.debug(data);
    let target = this.patientDataRef.element.nativeElement as HTMLElement;
    let source = (event.target as HTMLElement).closest('.day__data__item') as HTMLElement;

    source.classList.add('selected');

    let { office, patient, status: { type, details } } = data

    this.patientData = {
      name: `${patient.names} ${patient.lastNames}`,
      document: `${patient?.tipoDocumento}. ${patient.documentNumber}`,
      reason: details?.reason || null,
      phones: patient.cellPhones,
      image: patient.avatar,
      office: office,
      status: type
    }
    setTimeout(() => target.classList.add('visible'), 400);
  }

  hideDataInfo() {
    let target = this.patientDataRef.element.nativeElement as HTMLElement;
    let source = document.querySelector('.day__data__item.selected') as HTMLElement;

    source && source.classList.remove('selected');
    target && target.classList.remove('visible');
    this.patientData = null;
  }
}
