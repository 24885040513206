import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SmoothScrollService } from 'src/app/services/smooth-scroll.service';
import { DOCUMENT } from '@angular/common';

 interface AccordionItem {
  title: string;
  description: string;
  isOpen: boolean;
}


@Component({
  selector: 'app-info-user',
  templateUrl: './info-user.component.html',
  styleUrls: ['./info-user.component.scss']
})
export class InfoUserComponent implements OnInit {

  videoUrl: SafeResourceUrl;
  videoVisible: boolean = false;
  buttonVisible: boolean = true;
 

  // Array de Links Header
  links = [
    { name: 'Inicio' ,
      targetId: 'home'
    },
    { name: 'Descargas',
      targetId:'app' 
    },
    { name: 'FAQ',
    targetId:'faq' 
  }
  ]
  // Array de FAQ
  items: AccordionItem[] = [
    {
      title: '¿Cuánto cuesta la inscripción?',
      description: 'La inscripción es GRATIS para el titular y 10 miembros de su familia, además puedes registrar a tus peluditos',
      isOpen: false
    },
    {
      title: '¿Cómo puedo agendar una cita médica?',
      description: 'Gestiona tus citas desde tu celular de manera rápida y eficiente',
      isOpen: false
    },
    {
      title: '¿Cuáles son los beneficios para los afiliados?',
      description: 'Descuentos con los mejores médicos de la ciudad en citas médicas particulares, recordatorios de citas, inscripción de 10 beneficiarios y tus mascotas',
      isOpen: false
    },
    {
      title: '¿Puedo agendar citas para mis mascotas?',
      description: 'Consultas veterinarias incluidas para tus mascotas',
      isOpen: false
    },
    {
      title: '¿Qué beneficios ofrece la DESCUENTOS MÉDICOS App?',
      description: 'Acceso a servicios de salud particular de alta calidad y con tarifas preferenciales',
      isOpen: false
    },
  ];
  // Array contenido cards
  cards = [
    {
      img: 'https://kit.glmedialibrary.com/8707/305.png?tr=w-400',
      title: 'Citas de manera rápida y eficiente',
      description: 'Reduce el tiempo de espera para conseguir citas médicas, permitiendo a los usuarios agendar citas de manera rápida y eficiente'
    },
    {
      img: 'https://kit.glmedialibrary.com/8707/IMAGENESWEB03.png_2?tr=w-400',
      title: 'Tarifas Preferenciales',
      description: 'Ofrece tarifas preferenciales y descuentos en consultas y procedimientos, haciendo los servicios de salud más accesibles '
    },
    {
      img: 'https://kit.glmedialibrary.com/8707/IMAGENESWEB02.png?tr=w-400',
      title: 'Gestionar citas desde tu celular',
      description: 'Permite a los usuarios gestionar sus citas médicas particulares desde su hogar u oficina, evitando desplazamientos innecesarios y pérdida de tiempo'
    }
  ]
  // Array contenido Testimonios
  testimonials = [
    {
      img: 'https://kit.glmedialibrary.com/8707/hombre2.png?tr=w-100?tr=w-300',
      name: 'Andrés Llorente',
      testimony: '“Es una excelente aplicación para toda la familia y en especial para nuestros hijos peluditos. Obtuvimos descuentos en nuestras consultas y pudimos gestionar las citas de manera fácil y rápida”',
      occupation: 'Contador '
    },
    {
      img: 'https://kit.glmedialibrary.com/8707/mujer2.png?tr=w-100?tr=w-300',
      name: 'Ana Santander',
      testimony: '“Estoy muy satisfecho con el servicio de DESCUENTOS MÉDICOS App. Gracias a ellos, pude encontrar un especialista que me brindó la atención que necesitaba. Lo recomiendo”',
      occupation: 'Ingeniera'
    },
    {
      img: 'https://kit.glmedialibrary.com/8707/mujer1.png?tr=w-100?tr=w-300',
      name: 'Sara Solarte',
      testimony: '“¡Esta aplicación de DESCUENTOS MÉDICOS es realmente útil! Me ha ahorrado dinero en mis visitas al médico”',
      occupation: 'Docente'
    }
  ]

  constructor(private router: Router,
   private sanitizer: DomSanitizer,
   private smoothScrollService: SmoothScrollService,
   @Inject(DOCUMENT) private document: Document
   
   ) {

    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/4K98CzmgQYI');
  }

  ngOnInit(): void {
   this.videoVisible = false;
   this.buttonVisible = true;
  
  }

  // Aparecer el video y desaparecer el objeto que invoca el metodo 
  viewVideo(){
    this.videoVisible = !this.videoVisible;
    this.buttonVisible = false;
  }

  // Redirigir a solicitar cita
  redirectAppointment() {
    this.router.navigate(['/directory']);

  }

  // Redirigir a profesional de la salud
  redirectProfessional(){
    this.router.navigate(['/info/doctor']);
  }

  // Hace scroll a la section Download
  scrollDonwload(){
    this.smoothScrollService.scrollToElement('app');
  }


  toggleItem(index: number): void {
    this.items[index].isOpen = !this.items[index].isOpen;

    this.items.forEach((item, i) => {
      if (i !== index) item.isOpen = false;
    });
  }


}
