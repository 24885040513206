import { ControlContainer, FormControl, FormGroupDirective, ValidatorFn, Validators, AsyncValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Observable, of, debounceTime } from 'rxjs';
import * as intlTelInput from 'intl-tel-input';
import { PhoneMaskPipe } from '@formPipes'

import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'form-phone',
  templateUrl: './form-phone.component.html',
  styleUrls: ['../../form-styles.scss', './form-phone.component.scss'],
  providers: [PhoneMaskPipe]
})
export class FormPhoneComponent {
  @ViewChild('phoneInputRef', { static: true }) phoneInputRef!: ElementRef;

  @Input() controlName!: string;
  @Input() helper: string | undefined = undefined;
  @Input() label: string = 'Default label';
  @Input() required: boolean = true;
  @Input() repeated: boolean = true;
  @Input() endPointValidator: string = 'user/check-phone/';

  private entryInput!: intlTelInput.Plugin
  private regexPhone = /[0-9]{10}/

  public validating: boolean = false;
  public validPhone: boolean = false;
  public phoneNumber: string = ''
  public errors: any[] = [];
  public value: any;


  constructor(private apiService: ApiService, private controlContainer: ControlContainer) { }

  ngOnInit(): void {
    let input = (this.phoneInputRef.nativeElement as HTMLInputElement)
    this.entryInput = intlTelInput(input, {
      preferredCountries: ['co', 'ec'],
      onlyCountries: ['ar', 'co', 'ec', 'bo', 'br', 'cl', 'hn', 'mx', 'ni', 'pa', 'py', 'pe', 'uy', 've'],
      placeholderNumberType: 'MOBILE',
      autoPlaceholder: 'polite',
      formatOnDisplay: true,
      separateDialCode: true,
      initialCountry: 'co',
    });

    if (this.control.value) {
      let phoneNumberTmp = this.control.value      

      if (typeof phoneNumberTmp == 'object') {
        this.phoneNumber = `+${phoneNumberTmp['dialCode']}${phoneNumberTmp['phoneNumber']}`        
      }

      if (typeof phoneNumberTmp == 'string') {
        this.phoneNumber = `+${phoneNumberTmp}`        
      }      

      this.entryInput.setNumber(this.phoneNumber);
    }

    input.addEventListener('countrychange', (event: Event) => this.setPhone(event, true))
  }

  get control(): FormControl {
    const parentFormGroup = this.controlContainer as FormGroupDirective;
    return parentFormGroup.control.get(this.controlName) as FormControl;
  }

  get controlInvalid(): boolean {
    return this.control?.invalid || false;
  }

  get controlTouched(): boolean {
    return this.control?.touched || false;
  }

  get errorKeys(): string[] {
    return this.control?.errors ? Object.keys(this.control.errors) : [];
  }

  ngAfterViewInit(): void {
    let existingValidators = this.control.validator ? [this.control.validator] : []; // Get the existing validators of the control

    !this.repeated && existingValidators.push(this.phoneExistAsyncValidator()); // Add the custom validator to the existing validator array

    let combinedValidators: ValidatorFn | null = Validators.compose(existingValidators); // Combine the validators into a single ValidatorFn using Validators.compose

    this.control.setValidators(combinedValidators);
    this.control.updateValueAndValidity();

    this.control.statusChanges.subscribe(() => {
      this.errors = [];
      if (!this.control.errors || !this.control.dirty) return

      this.control.errors['required'] && this.errors.push(`El campo es requerido`)
      this.control.errors['incomplete'] && this.errors.push(`¡Falta un detalle! Completa tu número de teléfono, por favor`)
      this.control.errors['onlyNumbers'] && this.errors.push(`Solo se admiten números`)
      this.control.errors['existPhone'] && this.errors.push(`El correo ${this.control.value} ya se encuentra registrado`)
    });
  }

  onBlur() {
    let tmpPhone = this.phoneNumber.replace(/[^0-9]/g, '')
    if (tmpPhone.length < 10) this.control.setErrors({ incomplete: true });
    if (tmpPhone.length == 10) this.control.setErrors(null);
  }

  setPhone(event: Event, countrychange: Boolean = false) {
    !this.control.dirty && this.control.markAsDirty()
    let { dialCode } = this.entryInput.getSelectedCountryData()

    let { inputType, data: entry, target } = (event as InputEvent)
    let value = (target as HTMLInputElement)?.value || ''
    let regexValidator = /[0-9]/

    // this.control.setValue(`${dialCode}${value.replace(/[^0-9]/g, '')}`)
    if (countrychange) this.control.setValue({ dialCode, phoneNumber: value.replace(/[^0-9]/g, '') });

    if (inputType === 'insertText') {
      let isDigit = regexValidator.test(entry || '')

      if (value.length <= 14 && isDigit) {
        this.phoneNumber = value;

        // this.control.setValue(`${dialCode}${this.phoneNumber.replace(/[^0-9]/g, '')}`)
        this.control.setValue({ dialCode, phoneNumber: this.phoneNumber.replace(/[^0-9]/g, '') });
        return
      }


      (target as HTMLInputElement).value = this.phoneNumber
      !isDigit && this.control.setErrors({ onlyNumbers: true })

    } else {
      this.phoneNumber = value
    }
  }

  phoneExistAsyncValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      this.validPhone = false;

      if (!control.value) return of(null);

      let phoneValue = (control.value as string).trim()

      if (!this.regexPhone.test(phoneValue)) return of(null)

      this.validating = true

      setTimeout(() => {
        this.apiService.getRequest(`${this.endPointValidator}${phoneValue}`).pipe(debounceTime(500)).subscribe((rs: any) => {
          let { body: { existe } } = rs
          this.validating = false
          if (existe) {
            this.control.setErrors({ existPhone: true })
            return of(null)
          } else {
            this.validPhone = true
            this.control.setErrors(null)
            return of(null)
          }
        })
      }, 1000);

      return of(null)
    };
  }
}