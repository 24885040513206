<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<section id="wrapper" class="login-register login-sidebar" style="background-image: url(../../../../../assets/images/background/login-register.jpg); margin-right:30%;">
  <div class="login-box card scroll">
    <router-outlet></router-outlet>
  </div>
</section>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->
