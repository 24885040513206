<div class="login-box card">
  <div class="card-body mt-4">

    <a href="javascript:void(0)" class="text-center db"><img src="../assets/images/logo_black_font.png"
        style="height: 80px;" alt="Home" /><br />
      <!-- <img
          src="../assets/images/logo-text.png"
          alt="Home"/> -->
    </a>

    <div class="text-center mt-5">
      <p>Selecciona un método para recuperar tu contraseña</p>

      <div class="icon-container">
        <div class="icon" (click)="changeView('email', 'email')">
          <img src="../assets/icons/email.png" alt="Email">
          <p>Email</p>
        </div>
        <div class="icon" (click)="changeView('phone', 'wsp')">
          <img src="../assets/icons/wsp.png" alt="WhatsApp">
          <p>WhatsApp</p>
        </div>
        <div class="icon" (click)="changeView('phone', 'sms')">
          <img src="../assets/icons/sms.png" alt="SMS">
          <p>Sms</p>
        </div>
      </div>

      <div class="mt-4" *ngIf="!currentView">
        <p> ¿Ya tienes una cuenta?
          <a (click)="goToLogin();" style="cursor: pointer;" class="text-info m-l-5"><b>Iniciar sesión</b></a>
        </p>
      </div>

    </div>

    <div class="card-body custom-card mt-3" *ngIf="currentView">
      <ng-container [ngTemplateOutlet]="currentView"></ng-container>
    </div>

    <ng-template #email>

      <ng-container *ngTemplateOutlet="method"></ng-container>

      <form [formGroup]="changePasswordForm">

        <form-input helper="" label="Correo" placeholder="ejemplo@mail.com" [controlName]="'email'"
          [type]="'email'"></form-input>
        <ng-container *ngTemplateOutlet="buttons"></ng-container>
      </form>
    </ng-template>

    <ng-template #phone>
      <ng-container *ngTemplateOutlet="method"></ng-container>
 
      <form [formGroup]="changePasswordForm">
        <form-input helper="" label="Numero de Documento" placeholder="Numero de Documento"
          [controlName]="'documentNumber'" [type]="'text'"></form-input>
        <form-input helper="" label="Número de telefono" placeholder="Número de telefono" [controlName]="'phone'"
          [type]="'text'"></form-input>
        <ng-container *ngTemplateOutlet="buttons"></ng-container>
      </form>
      
    </ng-template>

    <ng-template #method>
      <p>Método Seleccionado <strong>{{ changePasswordData.method }}</strong></p>
    </ng-template>

    <ng-template #changePassword>

      <form [formGroup]="changePasswordForm">

        <form-password helper="" label="Contraseña" placeholder="" [controlName]="'password'"></form-password>

        <div class="mt-4">
          <form-password helper="" label="Verificar Contraseña" placeholder=""
            [controlName]="'verifyPassword'"></form-password>
        </div>

        <div class="mt-4">
          <form-input helper="" label="Código Verificación" placeholder="12345678" [controlName]="'verificationCode'"
            [type]="'text'"></form-input>
        </div>

        <div class="text-center mt-4">
          <button type="button" class="btn waves-effect waves-light btn-rounded btn-outline-danger"
            (click)="currentView = null;">Cancelar</button>

          <!-- <custom-buttons class="btn waves-effect waves-light btn-rounded btn-outline-danger animated fadeIn"
            text="Cancelar" textColor="#fff" type="button" [title]="''" (click)="currentView = null;"
            inputBackgroundColor="danger">
          </custom-buttons> -->

          <button type="submit" class="btn waves-effect waves-light btn-rounded btn-outline-success ml-2"
            (click)="sendNewPassword()">Recuperar</button>

          <!-- <custom-buttons class="btn waves-effect waves-light btn-rounded btn-outline-success ml-2 animated fadeIn"
            text="Recuperar" textColor="#fff" type="button" [title]="''" (click)="sendNewPassword()">
          </custom-buttons> -->
        </div>
      </form>

    </ng-template>

    <ng-template #buttons>

      <div class="text-center mt-4">
        <button type="button" class="btn waves-effect waves-light btn-rounded btn-outline-info"
          (click)="goToLogin();">Volver al inicio</button>

        <!-- <custom-buttons class="btn waves-effect waves-light btn-rounded btn-outline-info animated fadeIn"
          text="Volver al inicio" textColor="#fff" type="button" [title]="''" (click)="goToLogin();">
        </custom-buttons> -->

        <button type="submit" class="btn waves-effect waves-light btn-rounded btn-outline-success ml-2"
          (click)="sendCode()">Recuperar</button>

        <!-- <custom-buttons class="btn waves-effect waves-light btn-rounded btn-outline-success ml-2 animated fadeIn"
          text="Recuperar" textColor="#fff" type="submit" [title]="''" (click)="sendCode()">
        </custom-buttons> -->
      </div>
    </ng-template>

  </div>
</div>
