import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatPrice'
})
export class FormatPricePipe implements PipeTransform {

  transform(value: number): string {
    if (!value) {
      return '';
    }
    
    const formattedPrice = value.toLocaleString('es-ES', { minimumFractionDigits: 2 });
      return formattedPrice;
  }

}
