
<div>
  <div class="row">
    <div class="col-12">
      <div class="config_container">
        <div class="container-title">
          <h3 class="animated fadeIn">Empresa&nbsp;</h3>
          <h3 class="title-desktop animated fadeIn">/ Horarios</h3>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-xl-6 col-md-6 col-xs-12">
          <div class="">
            <form class="" id="doctorSelector" [formGroup]="doctorForm" autocomplete="off">
              <form-select helper="Selección de doctor" label="Doctor o especialista" placeholder="Seleccione..." [dataList]="doctorList" idField="employee._id" valueField="name" controlName="doctorControl" (change)="changeDoctor()"></form-select>
            </form>
          </div>
        </div>
        <div class="col-xl-6 col-md-6 col-xs-12">
          <app-branch-select-text (itemEmitter)="changeBranch($event)" id="branchSelector"></app-branch-select-text>
        </div>
      </div>
    </div>
  </div>
</div>


  <div class="calendar  extended">
    <div class="weekdays  extended">
      <span class="weekdays__item" *ngFor="let WeekName of weekDays" [title]="WeekName.name">{{ WeekName.name}}</span>
    </div>

    <div class="days__schedule extended" *ngIf="scheduleList" id="progcalendar">
      <div class="day" [ngClass]="scheduleList.sunday.dayStatus" (click)="addShift(scheduleList.sunday, 'sunday')">
        <div class="task__list" #sundayRef id="sundayRef"></div>
      </div>

      <div class="day" [ngClass]="scheduleList.monday.dayStatus" id="progcalendarday" (click)="addShift(scheduleList.monday, 'monday')">
        <div class="task__list" #mondayRef id="mondayRef"></div>
      </div>

      <div class="day" [ngClass]="scheduleList.tuesday.dayStatus" (click)="addShift(scheduleList.tuesday, 'tuesday')">
        <div class="task__list" #tuesdayRef id="tuesdayRef"></div>
      </div>

      <div class="day" [ngClass]="scheduleList.wednesday.dayStatus" (click)="addShift(scheduleList.wednesday, 'wednesday')">
        <div class="task__list" #wednesdayRef id="wednesdayRef"></div>
      </div>

      <div class="day" [ngClass]="scheduleList.thursday.dayStatus" (click)="addShift(scheduleList.thursday, 'thursday')">
        <div class="task__list" #thursdayRef id="thursdayRef"></div>
      </div>

      <div class="day" [ngClass]="scheduleList.friday.dayStatus" (click)="addShift(scheduleList.friday, 'friday')">
        <div class="task__list" #fridayRef id="fridayRef"></div>
      </div>

      <div class="day" [ngClass]="scheduleList.saturday.dayStatus" (click)="addShift(scheduleList.saturday, 'saturday')">
        <div class="task__list" #saturdayRef id="saturdayRef"></div>
      </div>

      <div class="calendar__loader" [ngClass]="{visible: loader}">
        <h2 class="title__animation">Cargando horarios</h2>
        <ng-lottie [options]="options"></ng-lottie>
      </div>
    </div>

    <div class="range__hours">
      <span class="range__hours__item" *ngFor="let hour of hourRange">{{hour}}</span>
    </div>
  </div>

  <div class="calendar__schedule__legend">
    <span class="calendar__schedule__legend__item" title="Representa un dia de la semana activo">
      <span class="calendar__schedule__legend__item__icon active"></span>
      Día activo
    </span>

    <span class="calendar__schedule__legend__item canceled" title="Representa un dia de la semana inactivo">
      <span class="calendar__schedule__legend__item__icon inactive"></span>
      Día inactivo
    </span>

    <span class="calendar__schedule__legend__item canceled" title="Representa un dia de la semana pausado">
      <span class="calendar__schedule__legend__item__icon paused"></span>
      Día pausado
    </span>

    <span class="calendar__schedule__legend__item" title="Representa un dia de la semana bloquedo">
      <span class="calendar__schedule__legend__item__icon blocked"></span>
      Día bloqueado
    </span>
  </div>
