<div class="alert-container animated fadeInUp fast" 
    [style.background-color]="backgroundColor"
    [style.border]="borderContainerColor"
    [style.color]="fontColor"
    >
    <div class="alert-bar" [style.background-color]="borderColor"></div>
    <div class="align_txt">
        <span *ngIf="titleMessage!=''" class="fw_600"><i class="fz_20 mr-2" [class]="iconType"></i>{{titleMessage}}</span>
        <span *ngIf="bodyMessage!=''" style="margin-left: 25px;"><i *ngIf="titleMessage===''" class="fz_20 mr-2" [class]="iconType"></i>{{bodyMessage}}</span>
    </div>
  </div>
  