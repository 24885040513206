import { Component } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-images-cropper',
  templateUrl: './images-cropper.component.html',
  styleUrls: ['./images-cropper.component.scss']
})
export class ImagesCropperComponent {

  imageChangedEvent: any = '';
  croppedImage: any = '';
  format: string = 'jpeg'; //"png"

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {


    this.croppedImage = event.base64;
    //console.log(this.croppedImage);
    var decoded = atob(
      this.croppedImage.substr(this.format === 'jpeg' ? 23 : 22)
    );
    //console.log('FileSizeCropped: ' + decoded.length);
  }
  imageLoaded(event: any) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  download(): void {
    var a = document.createElement('a'); //Create <a>
    a.href = this.croppedImage; //Image Base64 Goes here
    a.download = 'Image.' + this.format; //File name Here
    a.click(); //Downloaded fil
  }

}
