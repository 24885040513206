<app-landing-header></app-landing-header>
<!-- <div class="preloader" *ngIf="!isloading">
  <div class="loader">
    <div class="loader__figure"></div>
    <p class="loader__label">Descuentos médicos Web</p>
  </div>
</div> -->
<!-- <main class="main animated fadeIn" *ngIf="isloading"> -->
    <main class="main animated fadeIn" >
  <section class="section">
    <div class="container-lottie">
      <img [src]="imgVerify" alt="imgVerify">
    </div>
    <div class="container-info">
      <!-- HUMANS -->
      <p *ngIf="this.requestData?.ok === true && status === 'appointment' && patientType === 'members'">
        Hola<span> {{nameUser}}</span>, tu cita ha sido verificada y agendada correctamente a las<span> {{hour}} </span>del<span> {{appointmentDate}}</span>, recuerda llegar con 15 minutos de anticipacion.
      </p>
      <p *ngIf="this.requestData?.ok === true && status === 'user_exists' && patientType === 'members'">
        Hola<span> {{nameUser}}</span>, tu cita ha sido verificada y agendada correctamente a las<span> {{hour}} </span>del<span> {{appointmentDate}}</span>, recuerda llegar con 15 minutos de anticipacion.
      </p>
      <p *ngIf="this.requestData?.ok === true && status === 'verify' && patientType === 'members'">
        Hola<span> {{nameUser}}</span>, tu cita ha sido verificada y agendada correctamente a las<span> {{hour}} </span>del<span> {{appointmentDate}}</span>, recuerda llegar con 15 minutos de anticipacion.
      </p>
      <!-- PETS -->
      <p *ngIf="this.requestData?.ok === true && status === 'appointment' && patientType === 'pets'">
        Hola<span> {{nameUser}}</span>, tu cita para tu mascota ha sido verificada y agendada correctamente a las<span> {{hour}} </span>del<span> {{appointmentDate}}</span>, recuerda llegar con 15 minutos de anticipacion.
      </p>
      <p *ngIf="this.requestData?.ok === true && status === 'user_exists' && patientType === 'pets'">
        Hola<span> {{nameUser}}</span>, tu cita para tu mascota ha sido verificada y agendada correctamente a las<span> {{hour}} </span>del<span> {{appointmentDate}}</span>, recuerda llegar con 15 minutos de anticipacion.
      </p>
      <p *ngIf="this.requestData?.ok === true && status === 'verify' && patientType === 'pets'">
        Hola<span> {{nameUser}}</span>, tu cita para tu mascota ha sido verificada y agendada correctamente a las<span> {{hour}} </span>del<span> {{appointmentDate}}</span>, recuerda llegar con 15 minutos de anticipacion.
      </p>
      <div>
        <custom-buttons (click)="goHome()" title="Inicio" text="Inicio" textSize="1.2rem" textColor="#fff" icon="ti-arrow-left ml-2" type="button" [inputBackgroundColor]="'primary'"></custom-buttons>
      </div>
    </div>
  </section>
</main>
