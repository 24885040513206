import { Component, EventEmitter, Input, Output,ChangeDetectorRef  } from '@angular/core';
import { RegExValidate } from '@interfaces/information.interface';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent {
  @Input() value: string = '';
  @Input() inputTxtBtn: string = '';
  @Input() extended: boolean = true;
  @Input() pattern: string = '';
  @Input() placeholderInput: string = 'Buscar';
  @Input() microphone: boolean = false;
  // @Input() listBox: boolean = false;
  @Input() visibleShadow: boolean = false;
  @Input() display: string = 'block'; // quita o muestra la lupa, aparace por defecto el button de la lupa
  @Input() borderRadius: string = '15px 0 0 15px'; // radius por defecto del input
  @Input() marginRight: string = '0'; // Margin del icono de limpiar
  @Input() regExValidate: RegExValidate[] = [];

  @Input() inputHeight = '42px';
  @Input() inputShadow = true;

  @Output() searchValueEmmiter: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() searchErrorEmmiter: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() valueChange = new EventEmitter<string>();

  public find = false;
  public errors: string[] = [];

  constructor(private cdr: ChangeDetectorRef) {}

  onInput(event: Event) {
    this.value = (event.target as HTMLInputElement)?.value;
    this.find = false;

    // Restablece el estilo del input a su estado original
    const inputElement = event.target as HTMLInputElement;
    inputElement.classList.remove('invalid');
    inputElement.classList.remove('confirm');
  }

  searchValue() {
    this.errors = [];
    const inputElement = document.activeElement as HTMLInputElement;
    inputElement.classList.remove('invalid');

    if (this.value.length === 0) {
      this.errors.push('No hay ningún valor que buscar');
      // Cambia el color de fondo del input antes de emitir el evento de error
      const inputElement = document.activeElement as HTMLInputElement;
      inputElement.classList.add('invalid');

      this.searchErrorEmmiter.emit(true);
    } else if (this.regExValidate.length > 0) {
      this.regExValidate.forEach((reg) => {
        if (!reg.regExp.test(this.value)) {
          const inputElement = document.activeElement as HTMLInputElement;
          inputElement.classList.add('invalid');
          this.errors.push(reg.message);
        } else {
          this.find = true;
          const inputElement = document.activeElement as HTMLInputElement;
          inputElement.classList.add('confirm');
          this.searchValueEmmiter.emit(this.value);
        }
      });
    } else {
      this.find = true;
      const inputElement = document.activeElement as HTMLInputElement;
      inputElement.classList.add('confirm');
      this.searchValueEmmiter.emit(this.value);
    }
  }

  /*
  // Este método se utiliza para actualizar el valor del input y volver a hacer la búsqueda.
  setValueAndSearch(value: string, executeSearch: boolean = true) {
    this.value = value; // Actualiza el valor del input.
    if (executeSearch) {
      this.searchValue(); // Llama al método de búsqueda solo si executeSearch es true.
    }
    this.cdr.markForCheck();
  }
  */

  // Este método se utiliza para actualizar el valor del input y volver a hacer la búsqueda.
  setValueAndSearch(value: string) {
    this.value = value; // Actualiza el valor del input.
    this.searchValue(); // Llama al método de búsqueda solo si executeSearch es true.
    this.cdr.markForCheck();
  }

  onValueChange(value: string) {
    this.valueChange.emit(value); // Emite el valor hacia el padre
  }
}


