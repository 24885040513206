import { Component, Input, OnInit } from '@angular/core';
import { Tab } from '@interfaces/shared-components.interface';
import { Router } from '@angular/router';
import { Branch, Service } from '@interfaces/information.interface';

@Component({
  selector: 'app-modal-company',
  templateUrl: './modal-company.component.html',
  styleUrls: ['./modal-company.component.scss'],
})
export class ModalCompany implements OnInit {
  @Input() data: any; // Data compartida al modal desde del componente padre

  public activeServices: any[] = [];
  public doctors: any = [];
  public branches: any[] = [];
  public mostrarBackCard: boolean = false;
  public serviceActiveId: any;
  public cityId: any;
  public subservice: any;

  //========= TABS DEL MODAL ================//



  tabs: Tab[] = [
    { tabIcon: 'fa fa-info', tabName: 'Compañia', tabSwitch: 'tab1', tabClass: 'active'},
    { tabIcon: 'fa fa-briefcase',  tabName: 'Servicio', tabSwitch: 'tab2', tabClass: '' },
    { tabIcon: 'fa fa-user',  tabName: 'Doctor',tabSwitch: 'tab3', tabClass: '' },
    { tabIcon: 'fa fa-map', tabName: 'Sucursal', tabSwitch: 'tab4', tabClass: '' },
  ];

  selectedTab: string = 'tab1';

  switchTab(tab: string) {
    this.selectedTab = tab;
  }

  //==========================================//

  constructor(private router: Router) {}

  // Data
  ngOnInit() {
    //console.log('data compartida al modal', this.data);
    // Obtiene los servicios activos osea en status= 'active'
    const activeServices = this.data.activeSubServices.filter((service: Service) => service.subService.status === 'active');
    this.activeServices = activeServices;
    //console.log('data de servicios activos', this.activeServices);

    // Obtiene los doctores y le agrega la propiedad flipped para manejar el flip de la card
    this.doctors = this.data.doctors.map((doctor: any) => ({
      ...doctor,
      flipped: false,
    }));
    //console.log('data de los doctors', this.doctors);
    
    // Obtiene las sucursales
    const activeBranchs = this.data.branches.filter((branch: Branch) => branch.status === 'active');
    this.branches = activeBranchs;
    //console.log('data de las sucursales activas', this.branches);
    this.serviceActiveId = this.data.activeSubServices[0].subService._id;
    //console.log('idServiceActive', this.serviceActiveId);
   // this.cityId = this.data.activeSubServices[0].municipalities[0].cityId;
    this.cityId = this.data.company.municipality.cityId;
    //console.log('idMunicipalityServiceActive', this.cityId);
  }
  
  eventTabThree(index: number = 2){
    this.doctors[index].flipped = false;
  }

  toggleCard(index: number): void {
    this.doctors[index].flipped = !this.doctors[index].flipped;
  }

  // Navega a la ruta 'directory-subservice' con los IDs de municipio y sub-servicio especificados
  //como parámetros de consulta.
  emitDataSubs(serviceId: any, cityId: any) {
    this.router
      .navigate(['/directory/directory-subservice'], {
        queryParams: { mun: cityId, sub: serviceId },
      })
      .then(() => {
        // Recargar la página después de la navegación
        window.location.reload();
      });
  }
}
