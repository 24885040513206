import { Component, Input, Output, EventEmitter,OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Especialidad, SpecialityDoctor } from '@interfaces/information.interface';
import { InformationService } from '@services';
import { UserService } from 'src/app/roles/services/user.service';

@Component({
  selector: 'app-specialty-list',
  templateUrl: './specialty-list.component.html',
  styleUrls: ['./specialty-list.component.scss']
})
export class SpecialtyListComponent implements OnInit {

  @Input()specialties:SpecialityDoctor[]=[];
  @Input()idContainer='idSpecialties';

  @Output() specialtiesEmitter: EventEmitter<SpecialityDoctor[]> = new EventEmitter<SpecialityDoctor[]>();

  especialidades:Especialidad[]=[];
  errorEspecialidad='';

  constructor(private infoService: InformationService,private userService:UserService){}

  ngOnInit(): void {

    this.iniciarEspecialidades();
    
  }

  getEspecialidadDoctor(especialidad:Especialidad):SpecialityDoctor{
    return {
      specialityId : especialidad._id,
      specialityName: especialidad.especialidad
    }
  }
  
  async iniciarEspecialidades(){
    this.infoService.getSpecialities().subscribe( (specialities: Especialidad[]) => this.especialidades = specialities );
  }

  seleccionEmitter(especialidad:Especialidad){
    // //console.log(especialidad);  
    this.errorEspecialidad = '';
    const especialidadDoctor:SpecialityDoctor = this.getEspecialidadDoctor(especialidad);
    
    const registrada = this.specialties.find(e => e.specialityId === especialidadDoctor.specialityId);

    if (registrada) {
      this.errorEspecialidad = `! La especialidad ${registrada.specialityName} ya fue registrada ¡`;      
    } else {
      
      this.userService.createSpeciality(especialidadDoctor).then(resp=>{
        
        if (resp.ok) {
          this.specialties.push(especialidadDoctor);
          this.specialtiesEmitter.emit(this.specialties);            
        }        
      }).catch(err=>{
        //console.log(err);        
      });

    }    

  }


  deleteSpecialty(specialty:SpecialityDoctor){        
    Swal.fire({
      title: 'Estás seguro?',
      text: `Se va a eliminar ${specialty.specialityName} de tu lista de Especialidades`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar Especialidad!',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {

        if (specialty._id) {
          this.userService.deleteSpeciality(specialty._id).then(resp=>{
            if (resp.ok) {
              this.specialties = this.specialties.filter(
                (objeto) => objeto._id !== specialty._id
              );      
              this.specialtiesEmitter.emit(this.specialties);              
            }
            
          }).catch(err=>{
            //console.log(err);            
          });
          
        }        
      }
    });
  }

}
