<div style="padding: 10px;">
  <div class="tab-content" id="pills-tabContent">
    <ng-container [ngTemplateOutlet]="currentView"></ng-container>
  </div>
</div>

<ng-template #studiesList>
  <div class="animated fadeIn">
    <div class="row">
      <div class="col-12">
        <div class="config_container">
          <div class="config_title mb-2">Estudios del Profesional</div>
          <div *ngIf="studies.length>0" class="config_subtitle">Has registrado
            <span class="config_campos_number">{{studies.length}} Estudio<span *ngIf="studies.length>1">s</span></span>.
            <span class="config_update text_default" title="!Click aqui!"
              (click)="iniciarStudiesAgregateForm()">¡Agregar Estudios!</span>
          </div>
          <div *ngIf="studies.length==0" class="config_subtitle">
            <span>Aún no has registrado tus estudios realizados</span>
            <span class="config_update text_default" title="!Click aqui!"
              (click)="iniciarStudiesAgregateForm()">¡Agregar Estudios!</span>
          </div>
        </div>
      </div>
    </div>

    <div class="table-responsive no-wrap table-sm mt-4">
      <table *ngIf="studies.length>0" class="table vm no-th-brd pro-of-month">
        <thead>
          <tr>
            <th>Título Obtenido</th>
            <th>Universidad</th>
            <th>F. Grado</th>
            <th>Opciones</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let study of studies">
            <tr class="pointer row_studies fw_600">

              <td>
                <div class="studies_name" (click)="selectStudy(study)">{{study.studyName}}</div><small
                  class="text-muted">{{study.type|uppercase }}</small>
              </td>
              <td>
                <div class="studies_name" (click)="selectStudy(study)">{{study.university}}</div><small
                  class="text-muted">{{study.municipality}}</small>
              </td>
              <td>
                <div class="date_grade" (click)="selectStudy(study)">{{this.getDate(study.dateGrade) }}</div>
              </td>
              <td>
                <div class="actions">
                  <i class="fa fa-edit update" title="Actualizar" (click)="selectStudy(study)"></i>
                  <i class="fa fa-trash delete" title="Eliminar" (click)="deleteStudy(study)"></i>
                </div>
              </td>

            </tr>
          </ng-container>
        </tbody>
      </table>
      <table *ngIf="studies.length==0" class="table vm no-th-brd pro-of-month">
        <thead>
          <tr>
            <th class="no_registrados">No hay Estudios registrados</th>
          </tr>
        </thead>
      </table>
    </div>

  </div>
</ng-template>

<ng-template #studyEdit>
  <div class="animated fadeIn">
    <div class="update_settings_header mb-4">
      <!-- <button type="button" class="btn btn-circle btn_back" title="Regresar" (click)="regresarStudies()"><i
          class="ti ti-arrow-left"></i> </button> -->

      <custom-buttons type="button" (click)="regresarStudies()" text="" textColor="#fff" [title]="'Regresar'"
        icon="ti ti-arrow-left" style="padding-right: 10px;">
      </custom-buttons>
      <div *ngIf="studiesSelected" class="settings_header_title">Actualizar Estudios</div>
      <div *ngIf="!studiesSelected" class="settings_header_title">Agregar Estudios</div>
    </div>

    <form [formGroup]="studiesForm">

      <div class="row">

        <div class="col-12">
          <div class="form-group">
            <form-input [id]="'idInputStudyName'" helper="Registre el nombre del titulo Conseguido"
              label="Título Obtenido" placeholder="ejm: Medicina" [controlName]="'studyName'">
            </form-input>
          </div>
        </div>

        <div class="col-md-6 col-xs-12">
          <div class="form-group">
            <form-input [id]="'idInputUniversity'" helper="Nombre de la Universidad" label="Universidad"
              placeholder="ejm: Universidad Nacional de Colombia" [controlName]="'university'">
            </form-input>
          </div>
        </div>

        <div class="col-md-6 col-xs-12">
          <div class="form-group">
            <div class="form-group">
              <form-select [id]="'idInputType'" helper="Seleccione un nivel academico" label="Nivel Académico"
                placeholder="Seleccione..." [dataList]="types" [controlName]="'type'" [idField]="'value'"
                [valueField]="'name'">
              </form-select>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-xs-12">
          <div class="form-group">
            <form-input [id]="'idInputMunicipality'"
              helper="Registre la ubicación de la universidad o a qué sede hace referencia" label="Ubicación/Sede"
              placeholder="ejm: Bogotá-Cundinamarca" [controlName]="'municipality'">
            </form-input>
          </div>
        </div>

        <div class="col-md-6 col-xs-12">
          <div class="form-group">
            <form-date [id]="'idInputDateGrade'" label="Fecha del Grado" [maxNow]="true" [controlName]="'dateGrade'">
            </form-date>
          </div>
        </div>
      </div>

      <div class="settings_btn mt-4">
        <!-- <button *ngIf="!studiesSelected" type="button" (click)="agregateStudies(studiesForm)"
          class="btn btn-success btn-rounded">Agregar Estudios</button> -->

        <custom-buttons *ngIf="!studiesSelected" type="button" (click)="agregateStudies(studiesForm)"
          text="Agregar Estudios" textColor="#fff" [title]="''">
        </custom-buttons>

        <!-- <button *ngIf="studiesSelected" type="button" (click)="updateStudies(studiesForm)"
          class="btn btn-success btn-rounded">Confirmar Cambios</button> -->

        <custom-buttons *ngIf="studiesSelected" type="button" (click)="updateStudies(studiesForm)"
          text="Confirmar Cambios" textColor="#fff" [title]="''" [rounded]="true">
        </custom-buttons>
      </div>

    </form>

  </div>
</ng-template>
