import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ControlContainer, FormControl, FormGroupDirective, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { ApiService, InformationService } from '@services';
import { DocumentType } from '@interfaces/information.interface';
import { Observable, of, debounceTime } from 'rxjs';

@Component({
  selector: 'form-document',
  templateUrl: './form-document.component.html',
  styleUrls: ['../../form-styles.scss', './form-document.component.scss']
})
export class FormDocumentComponent implements OnInit {
  @Input() placeholder: string = 'Default placeholder';
  @Input() helper: string | undefined = undefined;
  @Input() label: string = 'Default label';
  @Input() repeated: boolean = false;
  @Input() required: boolean = true;
  @Input() controlName!: string;
  @Input() endPointValidator: string = 'user/check-document/';
  @Input() validateDocument: boolean = true;
  @Input() filter: string = 'all';
  @Input() idInput: string = 'idInput';
  @Input() labelColor!: string;
  @Input() labelFontSize: string = '1rem'

  public documentTypes: Array<DocumentType> = this.filterDocumentTypes();
  public selectorActive: boolean = false
  public validDocument: boolean = false
  public validating: boolean = false
  public errors: any[] = [];
  public value: any = '';
  public initData = ''

  public selectedType: string = 'cc'
  public documentValue: string = ''

  private exampleFormat!: any
  private regexFormat!: any
  private maxLength!: any
  private typeString = false

  constructor(private infoService: InformationService, private apiService: ApiService, private controlContainer: ControlContainer) { }

  get control(): FormControl {
    const parentFormGroup = this.controlContainer as FormGroupDirective;
    return parentFormGroup.control.get(this.controlName) as FormControl;
  }

  get controlInvalid(): boolean {
    return this.control?.invalid || false;
  }

  get controlTouched(): boolean {
    return this.control?.touched || false;
  }

  get errorKeys(): string[] {
    return this.control?.errors ? Object.keys(this.control.errors) : [];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['filter'] && changes['filter'].currentValue !== changes['filter'].previousValue) {
      this.documentTypes = this.filterDocumentTypes();
    }
  }

  filterDocumentTypes(): Array<DocumentType> {

    const documentTypes = this.infoService.getDocumentTypes();

    if (this.filter === 'Minor') return documentTypes.filter((document: DocumentType) => document.type === 'RC' || document.type === 'TI');
    else if (this.filter === 'Adult') return documentTypes.filter((document: DocumentType) => document.type !== 'RC' && document.type !== 'TI');
    else return documentTypes
  }

  ngOnInit() {
    this.value = this.control.value

    let docType = this.documentTypes.find(dt => dt.type.toLowerCase() == this.selectedType.toLowerCase())
    this.exampleFormat = docType?.example
    this.regexFormat = docType?.regex;
    this.maxLength = docType?.maxlength;

    if (!this.value) return

    this.initData = this.control.value

    if (typeof this.value === 'string') {
      this.value = this.control.value.replaceAll(' ', '')
      this.selectedType = this.value.slice(0, 2)
      this.documentValue = this.value.slice(2)
      this.typeString = true
    }

    if (typeof this.value === 'object') {
      this.documentValue = this.value.document.trim()
      this.selectedType = this.value.type.trim()
      this.typeString = false
    }
  }

  ngAfterViewInit(): void {

    let existingValidators = this.control.validator ? [this.control.validator] : []; // Get the existing validators of the control

    !this.repeated && existingValidators.push(this.documentExistAsyncValidator()); // Add the custom validator to the existing validator array

    existingValidators.push(this.validateEntry.bind(this), Validators.maxLength(this.maxLength)); // Add the custom validator to the existing validator array

    let combinedValidators: ValidatorFn | null = Validators.compose(existingValidators); // Combine the validators into a single ValidatorFn using Validators.compose

    this.control.setValidators(combinedValidators);
    // this.control.updateValueAndValidity(); // Is disabled don't  validate to init

    this.control.statusChanges.subscribe(() => {
      if (!this.control.errors || !this.control.dirty) return
      this.control.errors['required'] && this.errors.push(`El campo es requerido`)
      this.control.errors['format'] && this.errors.push(`El formato debe ser ${this.exampleFormat}`)
      this.control.errors['existDocument'] && this.errors.push(`El documento ${this.typeString ? this.control.value : this.control.value.type + ' ' + this.control.value.document} ya se encuentra registrado`)
      // console.debug(this.errors);
    });
  }

  selectType(type: string) {
    this.errors = []
    let _type = type.toLowerCase().trim()
    this.selectedType = type

    let docType = this.documentTypes.find(dt => dt.type.toLowerCase() == _type)
    this.exampleFormat = docType?.example
    this.regexFormat = docType?.regex

    this.control.setValue(`${this.selectedType} ${this.documentValue}`)
    this.control.updateValueAndValidity()
  }

  setEntry(event: Event) {
    !this.control.dirty && this.control.markAsDirty()
    let value = (event.target as HTMLInputElement)?.value?.replaceAll(/[.-]/g, '')

    value = this.selectedType !== 'PA' ? value.replaceAll(/[a-zA-Z]/g, '') : value
    value = value.length > this.maxLength ? value.substring(0, this.maxLength) : value

    this.documentValue = value
    let valueToSet = this.typeString ? `${this.selectedType} ${this.documentValue}` : { type: this.selectedType, document: this.documentValue };

    (event.target as HTMLInputElement).value = value

    this.control.setValue(valueToSet)
    this.control.updateValueAndValidity()
  }

  documentExistAsyncValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      this.validDocument = false;
      let value: any = undefined

      if (!control.value) return of(null) // If value is empty return null and it does not validate

      if (typeof this.control.value === 'string') value = this.control.value.replaceAll(/[.-]/g, '').slice(2)
      if (typeof this.control.value === 'object') value = this.control.value.document.trim()

      this.errors = []

      if (!new RegExp(this.regexFormat, 'i').test(value || '')) {
        this.control.setErrors({ format: true })
        return of(null)
      }

      setTimeout(() => {
        this.validating = true
        let documentValue = `${this.selectedType}/${value}`

        if (!this.validateDocument) {
          this.validDocument = true
          this.validating = false
          this.control.setErrors(null)
          return of(null)
        } else {
          this.apiService.getRequest(`${this.endPointValidator}${documentValue}`).pipe(debounceTime(500)).subscribe((rs: any) => {
            let { body: { existe } } = rs
            this.validating = false
            this.errors = []
            if (existe) {
              this.control.setErrors({ existDocument: true })
              return of(null)
            } else {
              this.validDocument = true
              this.control.setErrors(null)
              return of(null)
            }
          });
        }

        return of(null)
      }, 1000)

      return of(null)
    };
  }

  validateEntry(control: AbstractControl): { [key: string]: any } | null {
    !control.dirty && control.markAsDirty()
    if (!this.control.errors || !this.control.dirty) return null
    if (!this.documentValue) return null;  // No validation error if the field is empty
    return null;  // Always return null for this validator
  }
}
