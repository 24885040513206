import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PlacesCoordinates } from '@interfaces/information.interface';

@Component({
  selector: 'app-map-markers',
  templateUrl: './map-markers.component.html',
  styleUrls: ['./map-markers.component.scss']
})
export class MapMarkersComponent implements OnInit, OnChanges {

  @Input() coordinates!:number[];
  @Input() inputName?:string;

  constructor() { }

  ngOnInit(): void {
    this.initMap();
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes['coordinates'] && !changes['coordinates'].firstChange) {
      this.initMap();      
    }

  }

  initMap(): void {
    const mapProperties = {
      center: { lat: this.coordinates[0], lng: this.coordinates[1] },
      disableDefaultUI: true,
      zoom: 13,
      gestureHandling: 'none', // Bloquear el desplazamiento del mapa
      styles: [
        {
          featureType: 'poi',
          stylers: [{ visibility: 'off' }] // Ocultar puntos de interés
        }
      ]
    };
    const map = new google.maps.Map(document.getElementById('map') as HTMLElement, mapProperties);

    

    // const svgMarker = {
    //   path: "M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
    //   fillColor: "blue",
    //   fillOpacity: 0.6,
    //   strokeWeight: 0,
    //   rotation: 0,
    //   scale: 2,
    //   anchor: new google.maps.Point(0, 30),
    // };

    let marker = new google.maps.Marker({
      position: mapProperties.center,
      map: map,
    });

    const contentString = `
    <div id="content">
      <div id="bodyContent" style="min-height: 50px;
      font-weight: 500;
      display: flex;
      align-items: flex-end;">
        <p>${this.inputName||'Ubicacion'}</p>
      </div>
    </div>`;

    const infowindow = new google.maps.InfoWindow({
      content: contentString
    });

    marker.addListener('click', () => {
      infowindow.open(map, marker);
    });
    
    // marker.addListener('click', () => {
    //   infowindow.open(map, marker);
    // });

     // Desactivar los clics en los marcadores de Google Maps
     google.maps.event.addListener(marker, 'click', function(event:any) {
      event.stop(); // Evitar que se propague el evento
    });
    // if (this.inputName) {
    //   marker.setLabel({
    //     text: this.inputName,
    //     color: 'white', // Color del texto del nombre del marcador
    //     className:'label_marker'
    //   });
    // }
    
    // new google.maps.Marker({
    //   position: mapProperties.center,
    //   map: map,
    //   title: 'Ubicación',
    //   label: {
    //     text: 'Mi ubicación',
    //     color: 'black', // Color del texto del nombre del marcador
    //     fontWeight: 'bold', // Grosor de la fuente del texto del nombre del marcador
    //   },
    //   icon: {
    //     url: 'https://via.placeholder.com/30', // URL de la imagen del marcador
    //     scaledSize: new google.maps.Size(30, 30), // Tamaño de la imagen del marcador
    //   }
    // });
  }
}
