<div [id]="idContainer" class="row container_specialties mb-4">

    <div class="card">
        <div class="card-body">

            <div class="col-12">
                <div class="card_title_specialties">
                    <h4 class="card-title text-center fw_600">Especialidades</h4>
                    <i class="fa fa-question icon__helper ml-2"
                        title="Busca tu especialidad y da clic para agregar, puedes agregar varias especialidades"></i>
                </div>
                <app-autocomplete 
                    [data]="especialidades" 
                    [valueInput]="'especialidad'" 
                    [nameInput]="'especialidad'"
                    [placeholder]="'ejm: MEDICINA GENERAL'"
                    [labelTxt]="'Buscar Especialidades'" [seleccionarBorrar]="true"
                    (seleccionEmitter)="seleccionEmitter($event)">
                </app-autocomplete>

                <div *ngIf="errorEspecialidad.length>0" class="error_especialidad">{{errorEspecialidad}}</div>

                <div class="mt-4" *ngIf="specialties">
                    <h5 class="card-title text-center fw_600">Tus Especialidades Registradas: {{specialties.length}}</h5>
                    <ul *ngIf="specialties.length>0" class="mt-3 specialties_list">
                        <li *ngFor="let specialty of specialties" class="specialties_item">
                            <span class="text-left">
                                {{specialty.specialityName}}
                            </span>
                            <div class="actions">
                                <i class="fa fa-trash delete" title="Eliminar" (click)="deleteSpecialty(specialty)"></i>
                            </div>
                        </li>

                    </ul>
                    <div *ngIf="specialties.length==0" class="no_registrados">
                        No hay Especialidades registradas
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>