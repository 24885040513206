<div class="row " [ngStyle]="{'background': backgroundMain,'padding': paddingMain, 'color': colorMain, 'border-radius': borderRadiusMain}">
    <div class="col-12 mb-2" style="font-size: medium; font-weight: normal;">
        {{ inputTxt }}
    </div>
    <div class="col-md-3 col-xs-12">
        <!-- Input para registrar el valor -->
        <input id="idValueRange" type="number" [min]="min" class="form-control" style="padding: 0.7rem .75rem;" placeholder="Registra el valor" [(ngModel)]="value" (ngModelChange)="onNumberInputChange($event)">
        <span *ngIf="unidades!=''" class="unidad_med">{{unidades}}</span>

    </div>
    <div class="col-md-9 col-xs-12" style="margin-top: 5px;">
        <div class="range-input-container">
            <!-- Rango de valores -->
            <input type="range" [min]="min" [max]="max" [step]="step" [value]="value" list="mydata" (input)="onInputChange($event)" [disabled]="!selectMin && value === min">
            <!-- Datalist para los valores del rango -->
            <datalist id="mydata">
        <option *ngFor="let stepValue of stepValues" [value]="stepValue">{{ stepValue }}</option>
      </datalist>
            <!-- Regla de marcadores -->
            <div class="range-rule">
                <div class="rule-marker" *ngFor="let stepValue of stepValues; let i = index" [style.left.%]="getMarkerPosition(i)">
                    <span *ngIf="showMarker(i)">{{ obtenerParteEntera(stepValue) }}<span *ngIf="obtenerParteDecimal(stepValue)>0">,{{obtenerParteDecimal(stepValue)}}</span><span *ngIf="viewUnidades && getUnidad(unidades)!=''">{{getUnidad(unidades)}}</span></span>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 ">
        <!-- Mostrar alerta si es necesario -->
        <div *ngIf="showAlert" class="alert alert-danger fw_400 mt-2">{{alertMin}}</div>
        <div *ngIf="showAdvertencia" class="alert alert-warning fw_400 mt-2 alert_container">{{alertMax}}
            <button type="button" class="btn btn-warning btn-sm btn-rounded fw_500 alert_btn_align" (click)="showAdvertencia=false"><i class="fa fa-check"></i> Continuar</button>
        </div>
    </div>
</div>