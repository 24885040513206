<span class="input__group">
  <div class="box__label">
    <label class="input__label" for="input_component">{{label}}<strong *ngIf="required"> *</strong></label>
    <i class="fa fa-question icon__helper" [title]="helper" *ngIf="helper"></i>
  </div>
  <div class="box__input">
    <span class="chip__list" [ngClass]="{invalid: control.touched && control.invalid}">
      <span class="chip__list__item" *ngFor="let keyword of keywordList">
        {{ keyword }}
        <i class="fa fa-close chip__list__item__close" [title]="'Eliminar ' + keyword" (click)="removeKeyword(keyword)"></i>
      </span>
      <input #inputPillsRef name="keywords" (keyup)="addKeyword($event)" [placeholder]="placeholder" [ngClass]="{hide: keywordList.length == this.keywordListLimit}">
    </span>
    <label class="input__label__counter" for="keywords">{{ keywordList.length }}/{{ this.keywordListLimit }}</label>
  </div>
  <div class="box__error" [ngClass]="{visible:errors.length}">
    <ul>
      <li class="box__error__item" *ngFor="let error of errors"><strong>* </strong>{{error}}</li>
    </ul>
  </div>
</span>
