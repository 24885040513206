import { createAction, props } from '@ngrx/store';
import { loginData } from '../../interfaces/auth.interface';


// Login Original

export const login = createAction(
  '[Auth] Login',
  props<{ user: string }>()
);
  
export const loginSuccess = createAction( 
  '[Auth] Login Success',
  props<{ body: loginData }>()
);
  
export const loginFailure = createAction(
  '[Auth] Login Failure',
  props<{ error: string }>()
);
 
export const regenerateAuthState = createAction(
  '[Auth] Reload Auth State',
  props<{ body: loginData, token: string }>()
);

// espacios
export const logout = createAction(
  '[Auth] Logout'
); 