import { Component, Input, OnInit } from '@angular/core';
import { Appointment } from '@interfaces/appointments';
import { CellPhone } from '@interfaces/information.interface';
import { ApplicationService, ModalService } from '@services';

@Component({
  selector: 'app-appointment-view',
  templateUrl: './appointment-view.component.html',
  styleUrls: ['./appointment-view.component.scss']
})
export class AppointmentViewComponent implements OnInit {

  @Input()data!:Appointment;
  @Input()viewHeader=true;

  public appointmentSelected!:Appointment;

  edadCita:string='';
  edadActual:string='';
  cellPhones:CellPhone[]=[];

  get getCellPhones(): CellPhone[] {
    //console.log('cells');
    
    return this.appointmentSelected.patient.cellPhones || [];
  }

  constructor( 
    public modalService: ModalService, 
    public applicationService:ApplicationService){}
  
  ngOnInit(): void {

    if (this.data) {
      this.appointmentSelected = this.data;
      
    }

    if (this.appointmentSelected.patient) {
      this.iniciarDataUser();      
    }

    

    //console.log(this.appointmentSelected);
    
  }

  iniciarDataUser(){
    if (this.appointmentSelected.patient.birthday) {      
        this.edadCita = this.getEdadCita(this.appointmentSelected.patient.birthday,this.appointmentSelected.appointmentDate);
        this.edadActual = this.getEdadCita(this.appointmentSelected.patient.birthday,new Date());
        this.cellPhones = this.appointmentSelected.patient.cellPhones || [];
    }  
  }
  
  getEdadCita(inicio:any,fin:any):string{
    const nInicio = new Date(inicio);
    const nFin = new Date(fin);

    //console.log(nInicio);
    //console.log(nFin);
    

    return this.applicationService.calcularDiferenciaFechas(
      nInicio,
      nFin)
  }

  

}
