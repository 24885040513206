import { AfterViewInit, Component, Input } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'form-textarea',
  templateUrl: './form-textarea.component.html',
  styleUrls: ['../../form-styles.scss', './form-textarea.component.scss']
})
export class FormTextareaComponent implements AfterViewInit {
  @Input() controlName!: string;
  @Input() helper: string | undefined = 'Help textarea';
  @Input() placeholder: string = 'textarea placeholder';
  @Input() label: string = 'Default textarea label';
  @Input() labelColor!: string;
  @Input() labelFontSize: string = '1rem'
  @Input() required: boolean = true;
  @Input() idInput: string = 'idInput';
  @Input() borderRadius: string = '0px';
  @Input() fontSize: string = 'normal'

  errors: any[] = [];
  value: any;

  constructor(private controlContainer: ControlContainer) { }

  ngAfterViewInit(): void {
    this.control.statusChanges.subscribe(() => {
      this.errors = [];
      if (!this.control.errors || !this.control.dirty) return

      this.control.errors['required'] && this.errors.push(`El campo es requerido`)
      this.control.errors['minlength'] && this.errors.push(`El campo nó debe tener menos de ${this.control.errors['minlength'].requiredLength} caracteres`)
      this.control.errors['maxlength'] && this.errors.push(`El campo nó debe tener mas de ${this.control.errors['maxlength'].requiredLength} caracteres`)
      this.control.errors['email'] && this.errors.push(`El correo electrónico debe ser una dirección válida`)
      this.control.errors['pattern'] && this.errors.push(`El campo no corresponde a un patrón válido`)
    });
  }

  /** Get the control of the form */
  get control(): FormControl {
    const parentFormGroup = this.controlContainer as FormGroupDirective;
    return parentFormGroup.control.get(this.controlName) as FormControl;
  }

  /** Get the control invalid status */
  get controlInvalid(): boolean {
    return this.control?.invalid || false;
  }

  /** Get the control touched status */
  get controlTouched(): boolean {
    return this.control?.touched || false;
  }

  /** Get the control errors */
  get errorKeys(): string[] {
    return this.control?.errors ? Object.keys(this.control.errors) : [];
  }
}
