import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentage'
})
export class PercentagePipe implements PipeTransform {
    transform(value: number, total: number): number {
        if (!isNaN(value) && !isNaN(total) && total !== 0) {
          const percentage = ((total - value) / total) * 100;
          return Math.round(percentage);
        }
        return 0;
      }
}
