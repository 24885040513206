import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ReduxBranches } from '@interfaces/shared-components.interface';
import { Store } from '@ngrx/store';
import { LocalStorageService } from '@services';
import { selectBranch } from 'src/app/provider-shared/components/state/branches.actions';
import { branches } from 'src/app/provider-shared/components/state/branches.selectors';

@Component({
  selector: 'app-branch-select-text',
  templateUrl: './branch-select-text.component.html',
  styleUrls: ['../../../custom-forms/form-styles.scss', './branch-select-text.component.scss']
})
export class BranchSelectTextComponent {

  @Input() labelItem = 'Sucursal activa';
  @Input() titleItem = 'Click aqui !';
  @Output() itemEmitter: EventEmitter<any> = new EventEmitter<any>();
  
  data!: ReduxBranches[];
  branchSelected = new FormControl();

  constructor(private store: Store,
    private localStorate: LocalStorageService) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.getStoreBranches();      
    }, 500);
  }

  selectBranch(ev: any) {

    const branchId = ev.target.value;
    const branchSelected = this.data.find(branch => branch.branchName === branchId)!;
    this.store.dispatch(selectBranch({ branchSelected }));

    this.itemEmitter.emit(branchSelected);

  }

  getStoreBranches() {
    const branchId = this.localStorate.getItem('branch');

    this.store.select(branches).subscribe((branches: ReduxBranches[]) => {
      if (!branches.length) return

      this.data = branches.map((branch) => ({
        ...branch,
        branchName: `${branch.name}, ${branch.addressSettings.address}, ${branch.municipality?.cityName}`,
        selected: branch.branchId === branchId
      }))

      const branchSelected = this.data.find(branch => branch.branchId === branchId);

      this.branchSelected.setValue(branchSelected);
      this.itemEmitter.emit(branchSelected);
    });
  }
}

