<div class="calendar__loader" [ngClass]="{visible: loader}">
  <h2 class="title__animation">Cargando agenda</h2>
  <ng-lottie [options]="options"></ng-lottie>
</div>

<div class="calendar">
  <div class="month">
    <i class="fa fa-chevron-left month__navigation" (click)="prevMonth()" title="anterior"></i>
    <h4 #currentMonthRef class="month__current"></h4>
    <i class="fa fa-chevron-right month__navigation" (click)="nextMonth()" title="siguiente"></i>
  </div>

  <p #nowDateRef class="now__date"></p>

  <div class="weekdays">
    <span class="weekdays__item" *ngFor="let WeekName of weekDays" [title]="WeekName.name">{{ WeekName.name }}</span>
  </div>
  <div class="days" [ngClass]="{fullSize}" *ngIf="!hourly"></div>

  <div class="calendar__legend">
    <span class="calendar__legend__item" title="Representa una cita activa">
      <i class="fa fa-stethoscope calendar__legend__item__icon"></i>
      Citas activas
    </span>
    <span class="calendar__legend__item canceled" title="Representa una cita cancelada">
      <i class="fa fa-stethoscope calendar__legend__item__icon canceled"></i>
      Citas canceladas
    </span>
  </div>
</div>

<ng-template #tooltipRef></ng-template>
<ng-template #infoDayRef></ng-template>
