import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import Swal from 'sweetalert2';
import { Album, Image } from 'src/app/interfaces/information.interface';
import { ProfileService } from 'src/app/roles/profile/profile.service';
import { StatesNgrxService } from '@services';

@Component({
  selector: 'app-albums',
  templateUrl: './albums.component.html',
  styleUrls: ['./albums.component.scss'],
})
export class AlbumsComponent implements OnInit {
 

  @Output() imageEmitter: EventEmitter<Image> = new EventEmitter<Image>();
  @Output() positionEmitter: EventEmitter<number> = new EventEmitter<number>();
  @Output() albumEmitter: EventEmitter<Album> = new EventEmitter<Album>();
  @Output() regresarEmmiter: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() sizeAlbumPx = 150;
  @Input() sizeImagePx = 150;
  @Input() titleImage = 'Seleccionar imagen';
  @Input() optionsAlbum = false;
  @Input() optionsImages = false;
  @Input() createAlbum = false;
  @Input() albums!: Album[];
  @Input() role!: string;

  @HostListener('window:click') onClick(e: Event) {
    if (this.options) {
      let tmpOptions = document.querySelector('.option_container.active');
      tmpOptions?.classList.remove('active');
      this.options = false;
    }
  }

  albumSelected?: Album;
  imagesSelected:Image[]=[];

  options = false;
  optionsImagesView = false;
  selectImages = false;
  selectedAll = false;
  currentItem: number | null = null;

  roleImage = 'user';

  constructor(private profileService:ProfileService, 
              private statesNgrxService:StatesNgrxService  ){}

  ngOnInit(): void {    
    if (this.role =='provider') {
      this.roleImage ='company'      
    }    

  } 

  /**
   * Cambia el nombre de los albums Profile y Cover, los demas nombres los retorna igual.
   * @param {string} name - Vista a la que se desea cambiar.
   * @returns {string} nuevo nombre de album
   */
  getNameAlbum(name: string): string {
    let nName = name;
    if (name === 'Profile') {
      nName = 'Perfil';
    }
    if (name === 'Cover') {
      nName = 'Portada';
    }

    return nName;
  }

  addImageIdToAlbumImages(album: Album): Album {

    let updatedAlbum = { ...album };  // Esto crea una copia del objeto album

    if (updatedAlbum && updatedAlbum.images) {
        updatedAlbum.images = updatedAlbum.images.map(image => {
            return {
                ...image, 
                imageId: image._id 
            };
        });
    }
    return updatedAlbum; 
}

  /**
   * Selecciona un álbum y emite un evento para indicar que no se debe regresar.
   * @param {Album} album - Álbum seleccionado.
   */
  selectAlbum(album: Album): void {
    // //console.log(album);
    this.imagesSelected=[];
    this.selectImages = false;
    this.regresarEmmiter.emit(false);
    this.albumSelected = this.addImageIdToAlbumImages(album);
    this.albumEmitter.emit(album);
  }

  /**
   * Muestra u oculta las opciones de un álbum al hacer clic en un botón.
   * @param {Album} album - Álbum al que se refieren las opciones.
   * @param {Event} e - Evento de clic que activó las opciones.
   */
  opcionesAlbum(album: Album, e: Event): void {
    // //console.log(album);

    let target = (e.target as HTMLElement).closest('button') as HTMLElement;
    let opts = target.parentElement?.childNodes[3] as HTMLElement;

    if (opts.classList.contains('active')) {
      opts.classList.remove('active');
      setTimeout(() => (this.options = false), 100);
    } else {
      let tmpOptions = document.querySelector('.option_container.active');
      tmpOptions?.classList.remove('active');
      setTimeout(() => {
        opts.classList.add('active');
        this.options = true;
      }, 100);
    }
  }

  /**
   * Emite un evento para notificar la selección de una imagen.
   * @param {Image} image - Imagen seleccionada.
   */
  selectImage(image: Image, i:number): void {    
    this.imageEmitter.emit(image);
    this.positionEmitter.emit(i);
  }

  /**
   * Limpia la selección de álbum y emite un evento para indicar que se debe regresar.
   */
  regresarAlbums(): void {
    this.albumSelected = undefined;
    this.imagesSelected=[];
    this.selectImages = false;
    this.regresarEmmiter.emit(true);
  }


  selectedImages(){
    if (this.albumSelected?.images.length==0) return;
    this.optionsImagesView=true;
    this.selectImages=true;
  }

  onCheckboxChange(event: any, image: Image) {
      if (event.target.checked) {
          // Si el checkbox está marcado, añade la imagen al array imagesSelected
          this.imagesSelected.push(image);
      } else {
          // Si el checkbox no está marcado, quita la imagen del array imagesSelected
          const index = this.imagesSelected.indexOf(image);
          if (index >= 0) {
              this.imagesSelected.splice(index, 1);
          }
      }
      // //console.log(this.imagesSelected); // Puedes borrar esta línea, es solo para verificar que el array se actualiza correctamente
  }

  selectAll(){
    if(!this.albumSelected) return
    if (!this.selectedAll) {
      this.imagesSelected = [...this.albumSelected?.images];
      this.selectedAll = true;
    } else {
      this.imagesSelected = [];
      this.selectedAll = false;
    }
  }
  

  isChecked(image:Image):boolean{
    if(!this.imagesSelected) return false;    
    return Boolean(this.imagesSelected.find(r => image == r ));
  }

  cancelSelection(){
    this.selectImages=false;
    this.optionsImagesView=false;
    this.imagesSelected=[];
    this.selectedAll = false;
  }

  removeSelectedImagesFromAlbum(): void {
    if (this.albumSelected && this.albumSelected.images && this.imagesSelected) {
        this.albumSelected.images = this.albumSelected.images.filter(image => 
            !this.imagesSelected.includes(image) // Elimina las imágenes que están en imagesSelected
        );
    }
}

  async deleteSelected(){
    if (this.imagesSelected.length==0) return;

    const result = await Swal.fire({
      title: 'Estás seguro?',
      text: '¡Se van a eliminar las imágenes seleccionadas, no podrás recuperlas!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!',
      cancelButtonText: 'Cancelar',
    });
    
    if (result.isConfirmed) {   

      this.profileService.deleteImagesRol(this.imagesSelected, this.roleImage).subscribe(resp=>{         
        if (resp.ok) {
          this.statesNgrxService.getDataGlobalUser().subscribe((resp:any)=>{     
            if (!resp) {
              //TODO: cerrar sesion
              return //console.log('Error');              
            }        
            this.statesNgrxService.updateGlobalUser(); 
            this.removeSelectedImagesFromAlbum();
            if (this.albumSelected) {
              this.selectAlbum(this.albumSelected);              
            }
          });
        }else {
          //console.log(resp);  
        }  
      });
    }    
  }

  async vaciarAlbum(album?:Album){

    let nAlbum = album ? album: this.albumSelected;

    if (!nAlbum || nAlbum?.images.length==0) return;

    nAlbum = this.addImageIdToAlbumImages(nAlbum);
    
    if (nAlbum) {      
      const result = await Swal.fire({
        title: 'Estás seguro?',
        text: '¡Se van a eliminar todas las imágenes del álbum, no podrás recuperlas!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar!',
        cancelButtonText: 'Cancelar',
      });
      
      if (result.isConfirmed) {

        this.profileService.deleteImagesRol(nAlbum.images,this.roleImage).subscribe(resp=>{         
          if (resp.ok) {
            this.statesNgrxService.getDataGlobalUser().subscribe((resp:any)=>{     
              if (!resp) {
                //TODO: cerrar sesion
                return //console.log('Error');              
              }        
              this.statesNgrxService.updateGlobalUser(); 
              if (this.albumSelected) {
                this.albumSelected.images=[];
                this.selectAlbum(this.albumSelected);              
              }
            });
          }else {
            //console.log(resp);  
          }  
        });

      }
      
    }
  }

}
