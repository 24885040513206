import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'search-box',
  imports: [CommonModule],
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent {
  @Input() value: string = ''
  @Input() extended: boolean = true
  @Input() pattern: string = '';

  onInput(event: Event) {
    this.value = (event.target as HTMLInputElement)?.value
  }
}
