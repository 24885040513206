import { createReducer, on } from '@ngrx/store';
import { openModal } from './modal.actions';

export const modalStateFeatureKey = 'modalState';

export interface ModalState {
    open: boolean | null;
    data:any;
}

export const initialModalState: ModalState = {
    open: false,
    data:{}
};

export const modalReducer = createReducer(
    initialModalState,
    on(openModal, (state,{data}) => {

        return {
          ...state,
          open: true,
          data
        };
      })
);
