<div class="password-input-container" [class]="classDiv">
  <input type="password" class="pl-2" 
         [type]="passwordVisible ? 'text' : 'password'" 
         [(ngModel)]="password" 
         (ngModelChange)="onPasswordChange($event)"
         [placeholder]="placeholder" 
         [class]="classInput" 
         (keyup)="digitar($event)" 
         autocomplete="off" />
  <button type="button" (click)="togglePasswordVisibility()">
    <i class="fa" [class.fa-eye]="!passwordVisible" [class.fa-eye-slash]="passwordVisible"></i>
  </button>
</div>
