import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponseToRequest } from '@interfaces/information.interface';
import { ApplicationService, InformationService, SweetAlertService } from '@services';

@Component({
  selector: 'app-family-groups-request',
  templateUrl: './family-groups-request.component.html',
  styleUrls: ['./family-groups-request.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule]
})
export class FamilyGroupsRequestComponent implements OnInit {

  public requestData: any;
  public motives = new FormControl('');
  public view = 'buttons';
  public responseRequest: boolean = true;
  public hash!: string;

  constructor(private informationService: InformationService,
              private route: ActivatedRoute,
              private sweetAlert: SweetAlertService) {}


  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
        const hash = params.get('hash');
        this.getRequestData(hash!)
    });
  }

  getRequestData(hash: string) {

    this.informationService.getRequestData(hash).subscribe( (data: any) => {

      this.hash = hash;
      this.requestData = data;
    });
  }

  sendResponse() {

    this.sweetAlert.launchConfirmSwal({
      text: '¿ Estas seguro que deseas tomar esta decisión ?',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, aceptar',
      cancelButtonText: 'No, cancelar',
      confirmButtonColor: 'var(--primary)',
      title: 'Petición a grupo familiar',
      icon: 'question',
    }).then( () => {

      const response: ResponseToRequest = {
        hash: this.hash,
        response: this.responseRequest,
        motives: this.motives.value || ''
      };
   
      this.informationService.sendResponseRequest(response).subscribe();
    });

  }

}
