<!-- Si tab no tiene en el array la propieda TabIcon -->
  <ul 
    class="nav nav-tabs customtab"
    [ngClass]="classInput"
    role="tablist"
    *ngIf="tabsInfo && tabsInfo.length >= 1 &&  !tabsInfo[1]?.tabIcon"
  >
    <li *ngFor="let pi of tabsInfo" class="nav-item">
      <a
        class="nav-link pointer"
        data-toggle="tab"
        role="tab"
        aria-expanded="true"
        [class]="pi.tabClass"
        (click)="tabsSwitch(pi)"
      >
       
        <span class="hidden fw_500">{{ pi.tabName }} </span>
      </a>
    </li>
  </ul>


<!-- Si tab  tiene en el array la propieda TabIcon -->
<ul
class="nav nav-tabs customtab"
[ngClass]="classInput"
role="tablist"
*ngIf="tabsInfo[1]?.tabIcon && tabsInfo.length >= 1"
>
<li *ngFor="let pi of tabsInfo" class="nav-item">
  <a
    class="nav-link pointer"
    data-toggle="tab"
    role="tab"
    aria-expanded="true"
    [class]="pi.tabClass"
    (click)="tabsSwitch(pi)"
  >
    <i [class]="pi.tabIcon"></i>
    <span class="tooltip">{{ pi.tabName }} </span>
  </a>
</li>
</ul> 


