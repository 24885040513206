<div class="container-main">

  <!-- <h2 class="title-desktop animated fadeIn">Consulta nuestros servicios disponibles</h2> -->
  <!-- ================= aparece en desktop ======================= -->
  <div class="container-desktop ">

    <!-- SEARCH AND RESULTS-->
    <div class="search-desktop">
      <div class="container-title">
        <h3 class="animated fadeIn">Usuario&nbsp;</h3>
        <h3 class="title-desktop animated fadeIn">/ Buscar servicios</h3>
      </div>
      <div class="container-search">
        <!-- =========== CONTAINER INPUTS ========== -->
        <div class="container-input">
          <div class="input-speciality">
            <span class="search-desktop-label">Buscar servicio o especialidad de: </span>
            <!-- ========== OPTIONS SERIVCES ================  -->
            <div class="demo-radio-button">
              <input name="group5" type="radio" id="radio_30" class="with-gap radio-col-teal"
                [checked]="'humans' === typesFilter" (change)="updateFilter('humans')">
              <label for="radio_30">Personas</label>
              <input name="group5" type="radio" id="radio_31" class="with-gap radio-col-teal"
                [checked]="'pets' === typesFilter" (change)="updateFilter('pets')">
              <label for="radio_31">Mascotas</label>
            </div>
            <!-- ========== INPUT SPECIALITY ================  -->
              <app-search-input class="input-search" [marginRight]="'-3.5rem'" [borderRadius]="'4px'" [display]="'none'"
                #searchInputComponent (valueChange)="handleValueChange($event)"
                [placeholderInput]="'Ejm: Optometria, Veterinaria...'" [microphone]="false"
                (searchErrorEmmiter)="searchErrorEmmiter($event)">
              </app-search-input>
          </div>
          <!-- ========== INPUT MUN ================  -->
          <div class="input-mun">
            <div class="municipality-select">
              <form style="width: 100%;" [formGroup]="municipalityForm">
                <form-autocomplete label="En donde deseas tu cita o servicio?" [placeholder]="'Ejm: Bogota, Pasto...'"
                  [dataList]="municipalities" [controlName]="'municipality'" [idField]="'_id'"
                  [valueField]="'municipality'" (seleccionEmitter)="seleccionEmitter($event)">
                </form-autocomplete>
              </form>
            </div>
          </div>
          <!-- =========  BUTTON SEARCH ========== -->
          <div>
            <custom-buttons class="button-services  animated fadeIn" *ngIf="this.isSwitchOn === false" text="Buscar servicios"
              textColor="#fff" icon="ti-search ml-2" width="250px" [height]="'40px'" type="button" [title]="''"
              (click)="searchWithFilter()">
            </custom-buttons>
          </div>
        </div>
        <!-- =========== CONTAINER FILTERS ========== -->
        <div class="search-filters   animated fadeIn " *ngIf="this.municipalitySelected">
          <!-- ========== SWITCH ================  -->
          <div class="w-100 h-100" >
            <div  class="search-input-switch-desktop  animated fadeIn">
              <span style="font-weight: 500;" *ngIf="isSwitchOn === false">Buscar servicios cercanos a un
                direccion</span>
              <span *ngIf="isSwitchOn" class="msg-switch"> Estas usando el radio de busqueda </span>
               <!-- ========================= -->
               <div class="switch">
                <label>NO
                    <input type="checkbox"  [(ngModel)]="isSwitchOn"
                    (ngModelChange)="onSwitchChange($event)" ><span class="lever"></span>SI</label>
            </div>

              <!-- ========================= -->
              <!--

                <label class="switch">
                  <input type="checkbox" class="checkbox" [(ngModel)]="isSwitchOn"
                    (ngModelChange)="onSwitchChange($event)">
                  <div class="slider"></div>
                </label>
              -->
              <!-- ========================= -->
            </div>
          </div>
          <!-- =========  BUTTON SEARCH ========== -->

          <!--
            <div class="w-100 h-100 container-button-search">
              <custom-buttons class="animated fadeIn" *ngIf="this.isSwitchOn === false" text="Buscar servicios"
                textColor="#fff" icon="ti-search ml-2" width="250px"  type="button" [title]="''"
                (click)="searchWithFilter()">
              </custom-buttons>
            </div>
          -->
        </div>
      </div>
      <!-- ============ CONTAINER RANGE AND MAP ========= -->
      <div *ngIf="municipalitySelected && isSwitchOn === true" class="container-advanced-search">
        <!-- ========== INPUT RANGE ================  -->
        <div *ngIf="isSwitchOn && this.municipalitySelected" class="range-desktop  animated fadeIn">
          <app-range-input [backgroundMain]="'#ffffff'" [borderRadiusMain]="'10px'" paddingMain="1rem 0rem"
            [min]="inputRange.min" [max]="inputRange.max" [step]="inputRange.step" [value]="inputRange.radius"
            [selectMin]="false" [unidades]="'Kilometros'"
            [inputTxt]="'Registra o selecciona el rango de búsqueda en metros'" [alertMin]="alertMin"
            [alertMax]="'El rango de búsqueda es muy amplio, lo que podría significar que los servicios encontrados esten bastante alejados. ¿Deseas continuar con la búsqueda?'"
            (valueChange)="onRangeChange($event)">
          </app-range-input>
        </div>

        <div class="container-map">
          <span class="animated fadeIn" style="font-weight: 500;">
            Encuentra servicios cercanos ingresando una direccion
          </span>
          <!-- ========= MAPA ====================== -->
          <map-search class="animated fadeIn" placeholder="Escribe una ciudad o direccion"
            [inputRadius]="inputRange.radius" (placeEmmiter)="placeEmmiter($event)"
            (errorPlaceEmmiter)="errorPlaceEmmiter($event)" [borderRadiusInput]="'20px'" [borderRadiusMain]="'10px'"
            [placePrefix]="prefix" [coordinates]="[lat,lng]">
          </map-search>
        </div>
        <div class="button-search-filter">
          <custom-buttons class="animated fadeIn  " text="Buscar servicios" textColor="#fff" icon="ti-search ml-2"
            [title]="''" width="250px"  type="button" (click)="searchWithFilter()">
          </custom-buttons>
        </div>
      </div>
      <!-- ============ CONTAINER RESULTS ========= -->
      <div id="scroll-container" class="results-desktop" *ngIf="this.subServices && this.subServices.length > 0">
        <!-- =========== RESET SEARCH ============ -->
        <div class="reset-search" >

            <span style="font-weight: 500; opacity: 0;"> Buscar otro servicio </span>

          <custom-buttons type="button" *ngIf="this.municipalitySelected || this.default === false"
            text="Borrar Busqueda" textColor="#fff"  icon="ti-close ml-2" width="200px" [inputBackgroundColor]="'danger'"
             type="button" [title]="''" (click)="resetSearch()">
          </custom-buttons>
        </div>

        <!-- ========== MSG RESULTS  ================  -->
        <div
          *ngIf="subServices && subServices.length > 0 && this.msgResults === false && this.default === true; "
          class="msg-results ">
          <span class="msg-span animated fadeIn">
            Servicios disponibles en la ciudad de <span class="font-bold"> Pasto - Nariño </span>
          </span>
        </div>

        <div
          *ngIf="subServices && subServices.length > 0 && this.msgResults === true && this.default === false; else noResults"
          class="msg-results ">
          <span class="msg-span animated fadeIn">
            Se han encontrado <span class="font-bold">{{ subServices.length }} </span>resultados con
            <span class="font-bold">"{{ searchTermFilter }}"</span>
          </span>
        </div>
        <ng-template #noResults class="msg-results ">
          <div class="suggestion-list animated fadeIn" *ngIf="suggestions && suggestions.length > 0  ">
            <span>No se encontraron resultados ten en cuenta estas sugerencias:</span>
            <ul>
              <li *ngFor="let suggestion of suggestions" class="suggestion-list-li">
                <p (click)="onPClick(pContent.innerText)" #pContent>{{ suggestion.suggestion }}</p>
              </li>
            </ul>
          </div>
        </ng-template>
        <!-- ========== CARDS  ================  -->
        <div class="container-cards-desktop animated fadeIn" *ngIf="subServices && subServices.length  > 0">
          <!-- Card -->
          <app-card-subservice *ngFor="let subService of subServicesPaginator" [subService]="subService"  caso="serviceUno"
             (emitSubService)="emitDataSubs($event)">
          </app-card-subservice>
        </div>

        <!-- Pagination -->
        <div *ngIf="subServices && subServices.length  > 0" class="pagination-desktop">
          <app-paginator-custom [dataList]="subServices" [numItems]="9" (pageChange)="pageChange($event)"
            [paginator_select]="false" [paginator_col]="false" justifyContent="center"
            scrollAnchorId="scroll-container">
            >
          </app-paginator-custom>
        </div>

      </div>
    </div>

  </div>


</div>
