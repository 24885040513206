<div class="task__container">
  <h2 class="title">Información de la cita: Historial</h2>
  <div class="task__data">
    <div class="task__data__profile">
      <img [alt]="dataInfo.patient.names" [ngSrc]="dataInfo.patient.avatar" width="200" height="200">
      <span>{{ dataInfo.patient.names }} {{ dataInfo.patient.lastNames }}</span>
      <span><strong>Identificación: </strong>{{ dataInfo.patient.documentNumber }}</span>
    </div>

    <div class="task__data__info">
      <span class="task__data__info__item" *ngIf="dataInfo.patient.birthday">
        <strong>Fecha Nacimiento: </strong>
        <span>{{dataInfo.patient.birthday|date}}</span>
      </span>
      <span class="task__data__info__item" *ngIf="dataInfo.patient.direccion">
        <strong>Dirección: </strong>
        <span>{{dataInfo.patient.direccion}}</span>
      </span>
      <span class="task__data__info__item" *ngIf="dataInfo.patient.barrio">
        <strong>Barrio: </strong>
        <span>{{dataInfo.patient.barrio}}</span>
      </span>
      <span class="task__data__info__item" *ngIf="dataInfo.patient.cellPhones">
        <strong>Teléfonos: </strong>
        <span><ng-container *ngFor="let cellphone of dataInfo.patient.cellPhones">{{cellphone.phone}} </ng-container></span>
      </span>
      <span class="task__data__info__item" *ngIf="dataInfo.subservice.speciality.specialityName">
        <strong>Cita Para: </strong>
        <span>{{dataInfo.subservice.speciality.specialityName}}</span>
      </span>
      <span class="task__data__info__item" *ngIf="dataInfo.hour">
        <strong>Hora Cita: </strong>
        <span>{{dataInfo.hour|formatHour}}</span>
      </span>
      <span class="task__data__info__item" *ngIf="dataInfo.doctor.names">
        <strong>Médico: </strong>
        <span>{{dataInfo.doctor.names}}</span>
      </span>
      <span class="task__data__info__item" *ngIf="dataInfo.appointmentReason">
        <strong>Motivo cita: </strong>
        <span>{{dataInfo.appointmentReason}}</span>
      </span>
    </div>
  </div>
</div>
