import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '@services';

@Component({
  selector: 'app-complete-company-profile',
  templateUrl: './complete-company-profile.component.html',
  styleUrls: ['./complete-company-profile.component.scss']
})
export class CompleteCompanyProfileComponent implements OnInit {

  @Input() data: any;

  constructor(private router: Router,
              private modalService: ModalService) {}
              
  ngOnInit(): void {}

  redirectionPage(page: string) {
    this.router.navigate([`/provider/${page}`]);
    this.modalService.closeModal();
  }

}
