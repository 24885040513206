import { Component, ElementRef, Input, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-view-appointment',
  templateUrl: './view-appointment.component.html',
  styleUrls: ['./view-appointment.component.scss']
})
export class ViewAppointmentComponent implements OnInit, AfterViewInit {
  @Input() animationOptions!: AnimationOptions; // Opciones para animaciones Lottie
  @Input() description!: string; // Descripción a mostrar en el componente
  @Input() enableDynamicHiding: boolean = false; // Bandera para habilitar/deshabilitar la ocultación dinámica

  @ViewChild('contentDiv', { static: true }) contentDiv!: ElementRef; // Referencia a un elemento del DOM
  originalWidth!: number; // Ancho original para comparar en redimensionamiento
  hideHero: boolean = false; // Estado para controlar la visibilidad del contenido

  constructor() { }

  ngOnInit(): void {
    // Eventos iniciales, configuraciones al cargar el componente
  }

  ngAfterViewInit() {
    // Funcionalidad después de que las vistas están inicializadas
    if (this.enableDynamicHiding) {
      this.originalWidth = this.contentDiv.nativeElement.offsetWidth;
      const resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          if (entry.contentRect.width > this.originalWidth) {
            this.hideHero = true; // Ocultar si el nuevo ancho es mayor al original
          } else {
            this.hideHero = false; // Mostrar si el nuevo ancho es menor o igual al original
          }
        }
      });
      resizeObserver.observe(this.contentDiv.nativeElement);
    }
  }
}
