<div class="card card_pet" (click)="petSelectedEmitter.emit(pet)">
    <div class="card-head">

      <div *ngIf="viewOptions" class="btn_align">
        <a href="JavaScript:void(0)"
          class="icon-options-vertical btn_vertical btn-group ml-auto btn btn-circle btn_back btn_back_options"
          title="Opciones" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></a>
        <div class="dropdown-menu dropdown-menu-right icon_options_adjust">
          <a class="dropdown-item" href="javascript:void(0)" (click)="petUpdateEmitter.emit(pet)">Actualizar Mascota</a>
          <a class="dropdown-item" href="javascript:void(0)" (click)="petDeleteEmitter.emit(pet)">Eliminar Mascota</a>
        </div>
      </div>

      <div class="animal-detail">
        <img [src]="pet.avatar|getImage" [alt]="pet.breed" class="animal-img">
        <span class="animal_name">{{pet.petName}}</span>
        <!-- <p> "La aspiradora es mi peor enemigo"</p> -->
      </div>
    </div>
    <div class="card-body">
      <div class="animal-title">
        <!-- <h1>{{pet.breed}}<span class="badge"> {{pet.species}} </span></h1> -->
        <!-- <h1>{{pet.petName}}<span class="badge"> {{pet.species}} </span></h1> -->
      </div>
      <div class="animal-info">
        <!-- <h4>Información</h4> -->
        <ul class="list_info">
          <li class="fw_500 "><span class="labels">Especie:</span> {{ pet.species | noData:'No registrada' }}</li>
          <li class="fw_500 mt-1"><span class="labels">Raza:</span> {{ pet.breed | noData:'No registrada' }}</li>
          <li class="fw_500 mt-1"><span class="labels">Color:</span> {{ pet.color | noData:'No registrada' }}</li>
          <li class="fw_500 mt-1"><span class="labels">Genero:</span> {{ pet.gender | noData:'No registrado' |
            translateString:translateString }}</li>
          <li class="fw_500 mt-1"><span class="labels">Nacimiento:</span> {{ pet.birth_date | date:
            'yyyy-MM-dd':'+0500' }}</li>
          <li class="fw_500 mt-1"><span class="labels">Esterilizado:</span>
            <span *ngIf="pet.sterilized">SI</span>
            <span *ngIf="!pet.sterilized">NO</span>
          </li>
        </ul>
      </div>

    </div>
  </div>