<div class="main" [ngStyle]="{'border-radius': borderRadiusMain}"  >

  <div class="search__box" >
    <input [ngStyle]="{'border-radius': borderRadiusInput}" #inputSearch [id]="idInput" class="search__box__input" type="text" [placeholder]="placeholder" (input)="onInputChange($event)"/>
    <i class="fa fa-close icon__clear" [class.visible]="inputSearch?.value !== ''" (click)="clearInput()" title="Limpiar"></i>
  </div>
  
  <div  #map   class="map_container" [ngStyle]="{'height': mapHeight}"></div>

</div>




