import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-patient-ticket',
  templateUrl: './patient-ticket.component.html',
  styleUrls: ['./patient-ticket.component.scss'],
})
export class PatientTicketComponent {
  @Input() infoPatient: any;
  @Input() infoAppointment: any;

  calculateAge(dateOfBirth: string): number {
    const birthDate = new Date(dateOfBirth);
    const ageDifMs = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  formatTime(time: string): string {
    if (!time) {
      return '';
    }
    const [hours, minutes] = time.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12 AM
    return `${this.padZero(formattedHours)}:${this.padZero(minutes)} ${period}`;
  }

  private padZero(num: number): string {
    return num.toString().padStart(2, '0');
  }
}
