import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Image } from '@interfaces/information.interface';
import { Store } from '@ngrx/store';
import { ModalService } from 'src/app/reusable-modal/modal.service';
import { modalData } from 'src/app/reusable-modal/state/modal.selector';

@Component({
  selector: 'app-images-viewer',
  templateUrl: './images-viewer.component.html',
  styleUrls: ['./images-viewer.component.scss']
})
export class ImagesViewerComponent implements OnInit { 

  @Input() data!:any;

  public position = 0;
  public images:Image[] = [];
  public currentView?:Image;

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'ArrowLeft') {
      this.navigateImages(-1);
    }
    if (event.key === 'ArrowRight') {
      this.navigateImages(1);
    }
  }

  constructor(private store: Store, public modalService:ModalService){}

  ngOnInit(): void {
    this.modalSelector();
  }


  imagesOriginals(images: Image[]): Image[] {
      return images.map(image => ({
          ...image,
          pathOriginals: image.path.replace("/images/avatar/", "/images/originals/")
      }));
  }

  scrollView(){
    if (this.currentView) {
      let imagePrevId = document.getElementById('prev'+this.currentView._id);
      imagePrevId?.scrollIntoView();
    }
  }

  modalSelector(){

      if(this.data?.album?.images) this.images = this.imagesOriginals(this.data.album.images);
      if (this.data.position) {
        this.position = this.data.position;        
      }
      this.currentView = this.images[this.position];
      setTimeout(() => {
        this.scrollView();
      }, 200);
  }


  currentImagePrev(index:number){
    this.position=index;
    this.currentView = this.images[this.position];
  }

  /**
   * Cambia la imagen actual en la vista a la siguiente o anterior.
   * @param change - El cambio en la posición (1 para la siguiente, -1 para la anterior).
   */
  navigateImages(change: number): void {
      let newPosition = this.position + change;
      if (newPosition >= 0 && newPosition < this.images.length) {
          this.position = newPosition;
          this.currentView = this.images[this.position];
      } else {
          if (newPosition < 0) {
              newPosition = this.images.length - 1;
          } else if (newPosition >= this.images.length) {
              newPosition = 0;
          }
          this.position = newPosition;
          this.currentView = this.images[this.position];
      }
      this.scrollView();
  }


}
