<div *ngIf="vista=='vista1'" class="container_image animated zoomIn fast">
  <div class="card_image width_1">
    <div class="card_imagen_header">
      <!-- <button type="button" class="btn btn-circle btn_back i_90" title="Opciones"><i class="ti ti-more-alt"></i> </button> -->
      <div class="title_header ml-2">Cuenta de Descuentos Medicos</div>
      <button type="button" class="btn btn-circle btn_back" title="Cerrar" data-dismiss="modal"
        (click)="cerrarLimpiar();actualizado=false"><i class="ti ti-close"></i> </button>

      <!-- <custom-buttons type="button" class="btn btn-circle btn_back" data-dismiss="modal"
        (click)="cerrarLimpiar();actualizado=false" text="" textColor="#fff" [title]="'Cerrar'" icon="ti ti-close">
        <i class="ti ti-close"></i>
      </custom-buttons> -->
    </div>

    <div class="card_imagen_body">
      <div class="title_body">{{titleBody}}</div>
      <div class="text_body">{{textBody}}</div>
      <div class="image_body">
        <img id="imgPerfil" class="img_perfil" [class.rounded-image]="type=='Profile'" [src]="perfilImage"
          (click)="vista='vista2';actualizado=false">
      </div>
    </div>
    <div *ngIf="actualizado" class="card_imagen_btn">
      <span class="notification_image" style="margin-top: 0px;">Actualizado con exito
        <button class="btn btn-primary ml-2" (click)="actualizado=false"> Confirmar</button>

        <!-- <custom-buttons class="btn btn-primary ml-2" (click)="actualizado=false" text="Confirmar" textColor="#fff"
          [title]="''">
        </custom-buttons> -->
      </span>
    </div>
    <div *ngIf="!actualizado" class="card_imagen_btn text-center">
      <!-- <button class="btn_imagen" (click)="vista='vista2';actualizado=false"><i class="mdi mdi-camera pr_10"></i>Añadir
        imagen de perfil</button> -->
        <custom-buttons
          text="Añadir imagen de perfil"
          textColor="#fff"
          width="300px"
          type="button"
          [title]="''"
          (click)="vista='vista2';actualizado=false"
          icon="fa fa-picture-o">
        </custom-buttons>
      <!-- <custom-buttons class="btn_imagen" (click)="vista='vista2';actualizado=false" text="" textColor="#fff"
        [title]="''" icon="mdi mdi-camera">
        <i class="mdi mdi-camera pr_10"></i>
      </custom-buttons> -->
    </div>

  </div>
</div>

<div *ngIf="vista=='vista2'" class="container_image animated zoomIn fast">
  <div class="card_image width_2">
    <div class="card_imagen_header">
      <button type="button" class="btn btn-circle btn_back" (click)="vista='vista1'" title="Regresar"><i
          class="ti ti-arrow-left"></i> </button>

      <!-- <custom-buttons type="button" class="btn btn-circle btn_back" (click)="vista='vista1'" text="" textColor="#fff"
        [title]="'Regresar'" icon="ti ti-arrow-left">
        <i class="ti ti-arrow-left"></i>
      </custom-buttons> -->
      <div class="title_header">Añadir {{titleBody}}</div>
      <button type="button" class="btn btn-circle btn_back" title="Cerrar" data-dismiss="modal"
        (click)="cerrarLimpiar()"><i class="ti ti-close"></i> </button>

      <!-- <custom-buttons type="button" class="btn btn-circle btn_back" data-dismiss="modal" (click)="cerrarLimpiar()"
        text="" textColor="#fff" [title]="'Cerrar'" icon="ti ti-close">
        <i class="ti ti-close"></i>
      </custom-buttons> -->
    </div>

    <div class="card_imagen_body">

      <div class="container_options">

        <div *ngIf="viewAvatares" class="body_option">
          <button type="button" class="btn btn_option" (click)="option='illustration'"
            [ngClass]="{'btn_active': option=='illustration'}">
            <i *ngIf="type=='Profile'" class="mdi mdi-account-outline icon_size"></i>
            <i *ngIf="type!='Profile'" class="fa fa-image icon_size"></i>
            {{textAvatares}}
          </button>

          <!-- <custom-buttons type="button" class="btn btn_option" (click)="option='illustration'"
            [ngClass]="{'btn_active': option=='illustration'}" text="" textColor="#fff" [title]="''">
            <i *ngIf="type=='Profile'" class="mdi mdi-account-outline icon_size"></i>
            <i *ngIf="type!='Profile'" class="fa fa-image icon_size"></i>
            {{textAvatares}}
          </custom-buttons> -->
          <span *ngIf="option=='illustration'" class="btn_stick"></span>

        </div>

        <div  *ngIf="viewAlbums" class="body_option">
          <button type="button" class="btn btn_option" (click)="option='albums'"
            [ngClass]="{'btn_active': option=='albums'}">
            <i class="mdi mdi-folder-multiple-image icon_size"></i>
            Mis albums

          </button>

          <!-- <custom-buttons type="button" class="btn btn_option" (click)="option='albums'"
            [ngClass]="{'btn_active': option=='albums'}" text="" textColor="#fff" [title]="''">
            <i class="mdi mdi-folder-multiple-image icon_size"></i>
            Mis albums
          </custom-buttons> -->
          <span *ngIf="option=='albums'" class="btn_stick"></span>

        </div>

        <div *ngIf="viewComputer" class="body_option">

          <button type="button" class="btn btn_option" (click)="option='computer'"
            [ngClass]="{'btn_active': option=='computer'}">
            <i class="fa fa-laptop icon_size"></i>
            Del computador
          </button>
          <span *ngIf="option=='computer'" class="btn_stick"></span>

        </div>

      </div>

      <div *ngIf="option=='illustration'" class="illustration_container animated fadeIn fast">
        <div *ngIf="type=='Profile'" class="illustration_body">
          <img *ngFor="let ruta of avatars" [src]="ruta" alt="" class="illustration pointer"
            title="Click para establecer imagen" (click)="actualizarAvatar(ruta)">
        </div>

        <div *ngIf="type!='Profile'" class="illustration_body">
          <img *ngFor="let ruta of covers" [src]="ruta" alt="" class="illustration" title="Click para establecer imagen"
            (click)="actualizarAvatar(ruta)">
        </div>
      </div>

      <!-- Albums -->
      <div *ngIf="option=='albums'" class="illustration_container animated fadeIn fast">
        <app-albums [sizeAlbumPx]="145" (imageEmitter)="imageEmitter($event)" [albums]="user.albums">
        </app-albums>
      </div>

      <div *ngIf="option=='computer'" class="computer_container animated fadeIn fast">
        <div id="idDrop" class="image_drop " (dragover)="onDragOver($event)" (drop)="onDrop($event)"
          (dragleave)="onDragleave($event)">
          <img class="rounded-image" src="../../../assets/images/icon/photo_silhouette.png"
            style="pointer-events: none;">
          <!-- <img *ngIf="!imageDataUrl" class="rounded-image" src="../../../assets/images/icon/photo_silhouette.png"> -->
          <!-- <img *ngIf="imageDataUrl" class="rounded-image" [src]="imageDataUrl" alt=""> -->
          <div *ngIf="!imageDrop && !errorTipo && !cargandoFile" class="arrastra_title">
            Arrastra una foto aquí
          </div>
          <div *ngIf="!imageDrop && !errorTipo && !cargandoFile" class="arrastra_subTitle">
            - O -
          </div>
          <div *ngIf="!imageDrop && !errorTipo && !cargandoFile" class="card_computador_btn">
            <custom-buttons
              text="Cargar Imagen"
              textColor="#fff"
              width="250px"
              type="button"
              [title]="''"
              (click)="selectImage()"
              icon="fa fa-cloud-upload">
            </custom-buttons>
            <!-- <custom-buttons class="btn_computador" (click)="selectImage()" text="" textColor="#fff" [title]="''">
              <i class="mdi mdi-laptop pr_10"></i>Cargar Imagen
            </custom-buttons> -->
            <input type="file" accept="image/*" (change)="onFileSelected($event)" hidden>
          </div>
          <div *ngIf="imageDrop && !errorTipo && !cargandoFile" class="card_computador_btn">
            <span class="notification_image">Suelta la foto para subirla</span>
          </div>
          <div *ngIf="errorTipo && !imageDrop && !cargandoFile" class="card_computador_btn">
            <span class="notification_image">El tipo de archivo no es valido, tipos validos (jpg, jpeg, png)
              <button class="btn btn-primary ml-2" (click)="imageDrop=false;errorTipo=false">Ignorar</button>

              <!-- <custom-buttons class="btn btn-primary ml-2" (click)="imageDrop=false;errorTipo=false" text="Ignorar"
                textColor="#fff" [title]="''">
              </custom-buttons> -->
            </span>
          </div>
          <div *ngIf="cargandoFile" class="card_computador_btn">
            <span class="notification_image">Subiendo foto...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="vista=='vista3'" class="container_image animated zoomIn fast">
  <div class="card_image bg_2 width_2">
    <div class="card_imagen_header">
      <button type="button" class="btn btn-circle btn_back_2 " (click)="regresarYLimpiar('vista2')" title="Regresar"><i
          class="ti ti-arrow-left"></i> </button>

      <div class="title_header">Recortar Imagen</div>
      <button type="button" class="btn btn-circle btn_back_2" title="Cerrar" data-dismiss="modal"
        (click)="cerrarLimpiar()"><i class="ti ti-close"></i> </button>

      <!-- <custom-buttons type="button" class="btn btn-circle btn_back_2" data-dismiss="modal" (click)="cerrarLimpiar()"
        text="" textColor="#fff" [title]="'Cerrar'" icon="ti ti-close">
        <i class="ti ti-close"></i>
      </custom-buttons> -->
    </div>
    <app-image-crop *ngIf="fileComponent" [fileComponent]="fileComponent" [user]="user" [type]="type" [role]="role" [branchId]="branchId"
      (sendEmitter)="sendEmitter($event)" (fileSendEmitter)="fileSendEmitter($event)">
    </app-image-crop>
  </div>
</div>
