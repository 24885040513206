import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Pet, Translation } from '@interfaces/information.interface';

@Component({
  selector: 'app-pet-card',
  templateUrl: './pet-card.component.html',
  styleUrls: ['./pet-card.component.scss']
})
export class PetCardComponent {

  @Input() pet!: Pet;
  @Input() viewOptions=true;

  @Output() petSelectedEmitter= new EventEmitter<Pet>;
  @Output() petUpdateEmitter= new EventEmitter<Pet>;
  @Output() petDeleteEmitter= new EventEmitter<Pet>;

  genders=[
    {value:'Male',name:'Macho'},
    {value:'Female',name:'Hembra'},
  ];

  sterilizationStates=[
    {value:false,name:'NO'},
    {value:true,name:'SI'},
  ];

  translateString:Translation[]=[
    {term:'No registrado',translate:'No registrado'},
    {term:'Male',translate:'Macho'},
    {term:'Female',translate:'Hembra'},
  ]


}
