import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'documentMask'
})
export class DocumentMaskPipe implements PipeTransform {

  transform(value: string, documentType: string): unknown {
    let output = value

    if (value) {
      let _type = documentType.toLowerCase().trim()

      // 1 -CC [0-9]{8-10}  --> Cedula de ciudadania
      // 2 -CE [0-9]{10}      --> Cedula de extranjería
      // 5 -TI [0-9]{10}      --> Tarjeta de identidad
      if (['cc', 'ce', 'ti'].includes(_type)) output = Number(value.replaceAll('.', '')).toLocaleString('es-ES');

      // 3 -PA [a-z]{2}[0-9]{7}
      // 4 -RC [0-9]{2}-[0-9]{8}
      if (_type == 'rc') output = `${value.slice(0, 2)}-${value.slice(2)}`
    }
    return output;
  }
}
