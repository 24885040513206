<div class="ayudas_container">
  <div class="vertical_align">
    <button type="button" class="btn" data-toggle="modal" data-target="#modal-video-tutorial">
      <i [class]="iconClass" [style]="'font-size: '+ iconSize"></i>
    </button>

    <!-- <custom-buttons type="button" class="btn" data-toggle="modal" data-target="#modal-video-tutorial" text=""
      textColor="#fff" [title]="''">
      <i [class]="iconClass" [style]="'font-size: '+ iconSize"></i>
    </custom-buttons> -->
    <p [style]="'font-size: '+ fontSize">{{texto}}</p>

  </div>


</div>
