<i class="fa fa-close close__button" (click)="close()" title="Cerrar configuración "></i>
<h3 class="title__shift">Configuración horario de atención <strong>{{ currentDay.translate }}</strong></h3>

<stepper [stepperList]="stepperList" [initialVisibleSteps]="[1,2,3]" [disableNext]="disableNext" [disablePrev]="disablePrevious" (stepEvent)="changeStep($event)" (finishEvent)="setAllConfiguration()"></stepper>

<ng-template #step1Content>
  <div class="content__shift">
    <form class="service__form" [formGroup]="scheduleNameForm" autocomplete="off">
      <span class="helper__step">Ingresa un nombre para identificar tu consultorio </span>
      <form-input helper="Nombre que permita identificar su consultorio" label="Nombre de consultorio" placeholder="Ej: Consultorio A" [controlName]="'officeName'"></form-input>
    </form>
  </div>
</ng-template>

<ng-template #step2Content>
  <div class="content__shift">
    <form class="service__form" [formGroup]="scheduleTimeForm" autocomplete="off">
      <span class="helper__step">Horario y días de tus actividades o consultas</span>
      
      <form-select helper="Tiempo de duración por cita" label="Duración por cita" placeholder="Tiempo cita" [dataList]="numberOfAppointmentsList" valueField="temporality" controlName="sizeTime"></form-select>

      <div class="content__shift__step hours">
        <form-select helper="Inicio horario de atención" label="Inicio" placeholder="Horario de inicio" [dataList]="startAvailableHours" valueField="hour" controlName="startHourSelector"></form-select>
        <form-select helper="Finalización horario de atención" label="Finalización" placeholder="Horario de finalización" [dataList]="endAvailableHours" valueField="hour" controlName="endHourSelector"></form-select>
        <button class="add__hour__schedule" type="button" title="Agregar horas" (click)="addScheduleHour()">
          Agregar <i class="fa fa-calendar-plus-o more__hours" title="Agregar horas"></i>
        </button>
      </div>

      <form-multiselect [data]="weekDaysList" valueField="translate" labelText="Selecciona los días que necesites copiar el horario" (dataChecks)="changeWeekdays($event)" [show]="hoursListSelected.length > 0"></form-multiselect>

      <div class="generated__hours" *ngIf="hoursListSelected.length; else withoutHours">
        <h3 class="generated__hours__title">Horas establecidas</h3>
        <ng-container *ngFor="let hourItem of hoursListSelected">
          <div class="generated__hours__item">
            <span class="item__hour start">{{ hourItem.start|formatHour }}</span>
            <span class="item__hour start">-</span>
            <span class="item__hour end">{{ hourItem.end|formatHour }}</span>
            <button class="remove__hour__schedule__button" type="button" title="Remover horario" (click)="removeScheduleHour(hourItem.id)">
              <i class="fa fa-trash remove__hour__icon" title="Remover horario"></i>
            </button>
          </div>
        </ng-container>
      </div>

      <ng-template #withoutHours>
        <div class="generated__hours empty">
          <h3 class="generated__hours__title">Horas establecidas</h3>
          <h4 class="empty__text">Nó se han establecido horarios</h4>
        </div>
      </ng-template>
    </form>
  </div>
</ng-template>

<ng-template #step3Content>
  <div class="content__shift">
    <form class="service__form" [formGroup]="scheduleServiceForm" autocomplete="off">
      <span class="helper__step">Selección de subservicios</span>
      <div class="content__shift__step services">
        <!-- <form-select helper="Servicio principal" label="Servicio" placeholder="Servicios..." [dataList]="services" valueField="speciality.specialityName" idField="_id" controlName="branchSelector"></form-select> -->
        <form-multiselect *ngIf="subservices.length" labelText="Selecciona los subservicios que prestaras" [data]="subservices" valueField="subserviceName" idField="_id" (dataChecks)="setSubServices($event)" [show]="subservices.length>0" [adaptable]="true"></form-multiselect>
        <span class="not_subservices" *ngIf="subservices.length == 0">No existen subservicios para prestar</span>
      </div>
    </form>
  </div>
</ng-template>

<div class="schedule__loader" [ngClass]="{visible: loader}">
  <ng-lottie [options]="options"></ng-lottie>
  <h2 class="title__animation">Generando agenda...</h2>
</div>