<main class="container-main">
    <!-- === SWITCH DE DETALLES === -->
    <section class="section-switch">
        <div class="container-switch">
            <!-- === TABS === -->
            <ul class="nav nav-pills ulTabs text-center">
                <li class="nav-item" (click)="changeTab('detallesSubServicio')">
                    <a class="nav-link btn active" id="pills-detallesSubServicio-tab" data-toggle="pill" role="tab"
                        aria-controls="pills-detallesSubServicio" aria-selected="true">
                        <i class="fa fa-calendar"></i> <!-- Icono -->
                        <span class="tab-text">Solicitar Cita</span>
                    </a>
                </li>
                <li class="nav-item" (click)="changeTab('informacion')">
                    <a class="nav-link btn" id="pills-informacion-tab" data-toggle="pill" role="tab"
                        aria-controls="pills-informacion" aria-selected="false">
                        <i class="fa fa-info"></i> <!-- Icono -->
                        <span class="tab-text">Informacion</span>
                    </a>
                </li>
                <li class="nav-item" (click)="changeTab('multimedia')">
                    <a class="nav-link btn" id="pills-multimedia-tab" data-toggle="pill" role="tab"
                        aria-controls="pills-multimedia" aria-selected="false">
                        <i class="fa fa-camera"></i> <!-- Icono -->
                        <span class="tab-text">Multimedia</span>
                    </a>
                </li>
                <li class="nav-item" (click)="changeTab('servicios')">
                    <a class="nav-link btn" id="pills-servicios-tab" data-toggle="pill" role="tab"
                        aria-controls="pills-servicios" aria-selected="false">
                        <i class="fa fa-home"></i> <!-- Icono -->
                        <span class="tab-text">Servicios</span>
                    </a>
                </li>
                <li class="nav-item" (click)="changeTab('profesionales')">
                    <a class="nav-link btn" id="pills-profesionales-tab" data-toggle="pill" role="tab"
                        aria-controls="pills-profesionales" aria-selected="false">
                        <i class="fa fa-user-md"></i> <!-- Icono -->
                        <span class="tab-text">Profesionales</span>
                    </a>
                </li>
                <li class="nav-item" (click)="changeTab('sucursales')">
                    <a class="nav-link btn" id="pills-sucursales-tab" data-toggle="pill" role="tab"
                        aria-controls="pills-sucursales" aria-selected="false">
                        <i class="fa fa-map-pin"></i> <!-- Icono -->
                        <span class="tab-text">Sucursales</span>
                    </a>
                </li>
            </ul>

            <!-- === VISTA ACTUAL ===-->
            <div class="tab-content" id="pills-tabContent">
                <div class="vistas-templates  animated fadeIn">
                    <ng-container [ngTemplateOutlet]="currentView"></ng-container>
                </div>
            </div>
            <!-- =================== -->
        </div>
    </section>

    <!-- === VISTAS === -->
    <!-- == DETALLES CITA == -->
    <ng-template class="vistas-templates" #detallesSubServicio>
        <div class="tab-solicitar-cita">
            <!--STEPPER NO LOGEADO -->
            <stepper *ngIf="loginMoment === false" [stepperList]="stepperListCitaTemp" [disableNext]="disableNext"
                [disablePrev]="disablePrev" [initialVisibleSteps]="initialStepsSessionNotLogged" [buttonFixed]="true"
                (stepEvent)="onStepEventTemp($event)" (finishEvent)="sendAppointmentTemp()" [bordered]="false">
            </stepper>
            <!--STEPPER LOGEADO -->
            <stepper *ngIf="loginMoment === true" [stepperList]="stepperListCitaLogeado" [disableNext]="disableNext"
                [disablePrev]="disablePrev" [initialVisibleSteps]="initialStepsSessionLogged" [buttonFixed]="true"
                (stepEvent)=" onStepEventLogeado($event)" (finishEvent)="sendAppointment()" [bordered]="false">
            </stepper>
            <!--Motivo de la cita -->
            <ng-template #motivoCita>
                <section class="container-step-motivo">
                    <app-view-appointment [animationOptions]="optionsCita" [description]="textCita">
                        <div class="container-cita">
                            <span class="label_cita no-select">Datos de la<span> cita</span></span>
                            <form [formGroup]="reasonAppointmentForm" class="w-100">
                                <form-textarea class="reasonAppointment" [required]="validTextArea" labelColor="#fff"
                                    labelFontSize="0.8rem" label="Motivo de cita" helper=""
                                    placeholder="Escribir el motivo de tu cita..." [controlName]="'appointMotive'"
                                    [borderRadius]="'12px'" [fontSize]="'0.8rem'">
                                </form-textarea>
                                <!-- <p class="note no-select">Nota: No es obligatario llenar este campo</p> -->
                                <form-select [id]="'idInputBranch'" label="Sucursal" labelColor="#fff"
                                    labelFontSize="0.8rem" placeholder="Seleccione la dirección..."
                                    [dataList]="branchesSelect" [controlName]="'branch'" [idField]="'value'"
                                    [valueField]="'direccion'">
                                </form-select>
                                <form-select *ngIf="!isSingleDoctorLogged" [id]="'idInputDoctor'" label="Doctor"
                                    labelColor="#fff" labelFontSize="0.8rem" placeholder="Seleccione el profesional..."
                                    [dataList]="doctorsSelect" [controlName]="'doctor'" [idField]="'value'"
                                    [valueField]="'name'">
                                </form-select>
                            </form>

                        </div>
                    </app-view-appointment>
                </section>
            </ng-template>
            <!-- Horario -->
            <ng-template #citaHorario>
                <section class="container-step-horario">
                    <app-view-appointment [animationOptions]="optionsCalendario" [description]="textCalendario">
                        <div class="container-calendar">
                            <custom-calendar class="animated fadeIn " [inputData]="inputData" [height]="'410px'"
                                [endPointSchedules]="this.inputData.doctorId" (payloadData)="onPayloadData($event)"
                                (errorNoData)="onErrorNoData($event)">
                            </custom-calendar>
                            <div class="container-hour-select">
                                <p *ngIf="this.selectCalendar === true"> Fecha: <span> {{payload.date }} </span></p>
                                <p *ngIf="this.selectCalendar === true"> Hora: <span> {{payload.hour | hourFormat}}
                                    </span></p>
                            </div>
                        </div>
                    </app-view-appointment>
                </section>
            </ng-template>
            <!--Paciente o Temp-->
            <ng-template #citaPaciente>
                <section class="container-step-paciente">
                    <app-view-appointment [animationOptions]="optionsPaciente" [description]="this.textPaciente">
                        <div class="container-verificar">
                            <!-- Temp -->
                            <div *ngIf="loginMoment === false " class="section-verificar">
                                <span *ngIf="this.specialityOfHumans === true" class="label_cita no-select">Datos
                                    del<span> paciente</span></span>
                                <span *ngIf="this.specialityOfHumans ===  false" class="label_cita no-select">Datos
                                    del<span> propietario</span></span>
                                <form ngNativeValidate [formGroup]="formTemp"
                                    class="form-horizontal form-material animated fadeIn form-temp">
                                    <!-- Nombres -->
                                    <form-input label="Nombres" labelColor="#fff" labelFontSize="0.8rem"
                                        placeholder="Escribe los nombres" [controlName]="'nombresTemp'" [type]="'text'">
                                    </form-input>
                                    <!-- Apellidos -->
                                    <form-input label="Apellidos" labelColor="#fff" labelFontSize="0.8rem"
                                        placeholder="Escribe los apellidos" [controlName]="'apellidosTemp'"
                                        [type]="'text'">
                                    </form-input>
                                    <!-- Documento -->
                                    <form-document label="Tipo Documento" [validateDocument]="false" labelColor="#fff"
                                        labelFontSize="0.8rem" [controlName]="'tipoDocumentoTemp'"
                                        placeholder="Seleccione..." filter="all">
                                    </form-document>
                                    <!-- Telefono-->
                                    <form-input label="Telefono" labelColor="#fff" labelFontSize="0.8rem"
                                        [controlName]="'telefonoTemp'" placeholder="Escribe el número..."
                                        [type]="'text'">
                                    </form-input>
                                    <div class="checkbox checkbox-success">
                                        <input id="checkbox-signup" type="checkbox" class="chk-col-teal material-inputs"
                                            formControlName="terms">
                                        <label for="checkbox-signup" class="label-terminos"> Acepto los <a href="/terms"
                                                target="_blank"> Términos y condiciones </a></label>
                                    </div>
                                </form>
                            </div>
                            <!-- Paciente -->
                            <!-- titulos -->
                            <div class="container-label"
                                *ngIf="viewContentPage === false && specialityOfHumans === true && specialityOfPets === false && loginMoment === true">
                                <span class="label_family_group no-select">
                                    Selecciona <span>el Paciente</span>
                                </span>

                            </div>
                            <div class="container-label"
                                *ngIf="viewContentPage === false && specialityOfHumans === false && specialityOfPets === true && loginMoment === true">
                                <span class="label_family_group no-select">
                                    Selecciona <span>la Mascota</span>
                                </span>
                            </div>
                            <!--Container front-->
                            <div class="container-front" *ngIf="!viewContentPage  && (loginMoment === true )">
                                <!--Card paciente logeado-->
                                <!--user Logeado -->
                                <article
                                    *ngIf="dataUserLogeado && specialityOfHumans === true && specialityOfPets === false"
                                    [ngClass]="{'card-user animated fadeIn': true, 'selected': dataUserLogeado.userId === selectPacienteID}"
                                    (click)="getIdPaciente(dataUserLogeado.userId, dataUserLogeado.names, dataUserLogeado.lastnames, dataUserLogeado.cellPhones?.[0]?.phone, dataUserLogeado.gender, dataUserLogeado.documentNumber)">
                                    <div class="card-user_container_img">
                                        <img [src]="dataUserLogeado.avatar|getImage" [alt]="dataUserLogeado.names">
                                    </div>
                                    <div class="card-user_container_info">

                                        <span class="no-select ">{{dataUserLogeado.names}}
                                            {{dataUserLogeado.lastnames}}</span>
                                        <span class="no-select">Identificacion</span>
                                        <span class="no-select">{{dataUserLogeado.documentNumber}}</span>
                                    </div>
                                </article>
                                <!--Grupos Familiares o de Mascotas-->
                                <div *ngIf="  specialityOfHumans === true && specialityOfPets === false"
                                    class="container-label">
                                    <span class="label_family_group no-select animated fadeIn">Tus grupos
                                        <span>familiares </span></span>
                                </div>

                                <div *ngIf=" specialityOfHumans === false && specialityOfPets === true"
                                    class="container-label">
                                    <span class="label_family_group no-select animated fadeIn">Tus grupos de<span>
                                            Mascotas </span></span>
                                </div>
                                <!--Grupos de personas-->
                                <div *ngIf="specialityOfHumans === true && specialityOfPets === false"
                                    class="container-group-family animated fadeIn">

                                    <div *ngFor="let familyGroup of dataFamilyGroups; let i = index" class="w-100">
                                        <!-- Card grupo familiar personas -->
                                        <article *ngIf="!familyGroup.flipped && this.NotFoundGroupsFamily === false"
                                            class="card-family-group animated fadeIn">
                                            <p class="card-family-group_name no-select"> {{familyGroup.familyGroupName}}
                                            </p>
                                            <button (click)="selectFamilyGroup(familyGroup)"
                                                class="view-beneficiary">Ir</button>
                                        </article>
                                    </div>
                                    <!-- Añadir un grupo familiar para beneficiarios -->
                                    <article *ngIf="specialityOfHumans === true && userMoreLeader === false"
                                        (click)="addFamilyGroupAndBeneficiary()" class=" card-add animated fadeIn">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="icon-plus">
                                            <path
                                                d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                                        </svg>
                                        <span>Añadir</span>
                                    </article>

                                </div>
                                <!--Grupos de mascotas-->
                                <div *ngIf="specialityOfHumans === false && specialityOfPets === true"
                                    class="container-group-family animated fadeIn">
                                    <div *ngFor="let familyGroup of dataPetsGroup; let i = index" class="w-100">
                                        <!-- Card grupo familiar mascotas -->
                                        <article *ngIf="!familyGroup.flipped && this.NotFoundGroupsFamily === false "
                                            class="card-family-group animated fadeIn">
                                            <p class="card-family-group_name"> {{familyGroup.familyGroupName}} </p>
                                            <button (click)="selectFamilyGroup(familyGroup)"
                                                class="view-beneficiary">Mascotas</button>
                                        </article>
                                    </div>
                                    <!-- Añadir un grupo familiar para  mascotas -->
                                    <article
                                        *ngIf="specialityOfPets === true && userMoreLeader === false && dataFamilyGroupsAndPets.familyGroups.length === 0 "
                                        (click)="addFamilyGroupAndPet()" class=" card-add animated fadeIn">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="icon-plus">
                                            <path
                                                d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                                        </svg>
                                        <span>Añadir</span>
                                    </article>
                                </div>
                            </div>
                            <!--Container back-->
                            <div class="container-back"
                                *ngIf="(viewContentPage === true && selectedFamilyGroup) && (loginMoment === true ) ">
                                <div class="container_beneficiaries_pets">
                                    <!-- CARD BENEFICIRIES -->
                                    <article
                                        *ngFor="let beneficirie of selectedFamilyGroup?.beneficiaries; let i = index"
                                        [ngClass]="{'card-user animated fadeIn': true, 'selected': beneficirie.beneficiaryId === selectPacienteID}"
                                        (click)="getIdPaciente(beneficirie.beneficiaryId, beneficirie.names, beneficirie.lastName, beneficirie.cellPhones?.[0]?.phone, beneficirie.gender, beneficirie.documentNumber)">
                                        <div class="card-user_container_img">
                                            <img [src]="beneficirie.avatar|getImage" alt=" ">
                                        </div>
                                        <div class="card-user_container_info">
                                            <span class="no-select">{{beneficirie.names}}
                                                {{beneficirie.lastName}}</span>
                                            <span class="no-select">Identificacion</span>
                                            <span class="no-select">{{beneficirie.documentNumber}}</span>
                                        </div>
                                    </article>

                                    <!-- <article
                                        *ngFor="let beneficirie of selectedFamilyGroup?.beneficiaries; let  i = index"
                                        [ngClass]="{'card-user animated fadeIn': true, 'selected': beneficirie.beneficiaryId === selectPacienteID}"
                                        (click)="getIdPaciente(beneficirie.beneficiaryId, beneficirie.names, beneficirie.lastName, beneficirie.cellPhones?.[0]?.phone, beneficirie.gender)">
                                        <div class="card-user_container_img">
                                            <img [src]="beneficirie.avatar|getImage" alt=" ">
                                        </div>
                                        <div class="card-user_container_info">
                                            <span class="no-select">{{beneficirie.names}} {{beneficirie.lastName}}
                                            </span>
                                            <span class="no-select">Identificacion</span>
                                            <span class="no-select">{{beneficirie.documentNumber}}</span>
                                        </div>
                                    </article> -->
                                    <!-- CARD PETS -->
                                    <article *ngFor="let pet of selectedFamilyGroup?.pets; let  i = index"
                                        [ngClass]="{'card-user animated fadeIn': true, 'selected': pet._id === selectPacienteID}"
                                        (click)="getIdMascota(pet._id, pet?.petName, surnamePet, pet?.species, pet?.gender, pet?.breed, pet?.birth_date, dataUserLogeado?.names, dataUserLogeado?.lastnames, dataUserLogeado?.documentNumber, dataUserLogeado?.cellPhones[0]?.phone)">
                                        <div class="card-user_container_img">
                                            <img [src]="pet.avatar| getImage" alt=" ">
                                        </div>
                                        <div class="card-user_container_info">
                                            <span class="no-select">{{pet.petName}}</span>
                                            <span class="no-select">Especie</span>
                                            <span class="no-select">{{pet.species}}</span>
                                        </div>
                                    </article>
                                </div>
                                <button (click)=" viewContent()" class="view-home"> Cancelar </button>
                            </div>
                        </div>
                    </app-view-appointment>
                </section>
            </ng-template>
            <!--Resumen -->
            <ng-template *ngIf="loginMoment === true" #citaResumen>
                <section class="container-step-resumen">
                    <app-view-appointment [animationOptions]="optionsResumen" [description]="textResumen">
                        <p class="label_resumen"> Resumen </p>
                        <div class="container_resumen">

                            <div class="section_resumen">
                                <div class="item_resumen">
                                    <span>Sucursal:</span>
                                    <span>{{this.appointmentResumen.sucursal}} </span>
                                </div>
                                <div class="item_resumen">
                                    <span class="">Hora:</span>
                                    <span>{{this.appointmentResumen.hora}} </span>
                                </div>
                            </div>

                            <div class="section_resumen">
                                <div class="item_resumen">
                                    <span>Consultorio:</span>
                                    <span>{{this.appointmentResumen.consultorio}}</span>
                                </div>
                                <div class="item_resumen">
                                    <span class="">Duracion Aprox:</span>
                                    <span>30m</span>
                                </div>
                            </div>

                            <div class="section_resumen">
                                <div class="item_resumen">
                                    <span>Direccion:</span>
                                    <span>{{this.appointmentResumen.direccion}}</span>
                                </div>
                                <div class="item_resumen">
                                    <span class="">Telefono:</span>
                                    <span>{{this.appointmentResumen.telefonos}}</span>
                                </div>
                            </div>

                            <div class="section_resumen">
                                <div class="item_resumen">
                                    <span>Barrio:</span>
                                    <span>{{this.appointmentResumen.barrio}}</span>
                                </div>
                                <div class="item_resumen">
                                    <span class="">Medico:</span>
                                    <span>{{this.appointmentResumen.medico.nombres}}
                                        {{this.appointmentResumen.medico.apellidos}}</span>
                                </div>
                            </div>

                            <div class="section_resumen">
                                <div class="item_resumen">
                                    <span>Fecha:</span>
                                    <span>{{this.appointmentResumen.fecha}}</span>
                                </div>
                                <div class="item_resumen">
                                    <span class="">Paciente:</span>
                                    <span>{{this.appointmentResumen.paciente.nombres}} {{
                                        this.appointmentResumen.paciente.apellidos}}</span>
                                </div>
                            </div>

                        </div>
                    </app-view-appointment>
                </section>
            </ng-template>
        </div>
    </ng-template>
    <!-- == IMFORMACION == -->
    <ng-template class="vistas-templates" #informacion>
        <div class="tab-informacion animated fadeIn">

            <div class="card">
                <div class="card-header content-informacion">
                    <h2>Metas</h2>
                </div>
                <div class="card-body">
                    <p> {{infoDetailsCompany.company.companySocialProfile.companyGoals}}</p>
                </div>
            </div>

            <div class="card">
                <div class="card-header content-informacion">
                    <h2>Misión</h2>
                </div>
                <div class="card-body">
                    <p>{{infoDetailsCompany.company.companySocialProfile.companyMission}}</p>
                </div>
            </div>

            <div class="card">
                <div class="card-header content-informacion">
                    <h2>Visión</h2>
                </div>
                <div class="card-body">
                    <p> {{infoDetailsCompany.company.companySocialProfile.companyVision}} </p>
                </div>
            </div>

        </div>
    </ng-template>
    <!-- == MULTIMEDIA == -->
    <ng-template class="vistas-templates" #multimedia>
        <div class="tab-multimedia animated fadeIn">
            <app-albums [sizeAlbumPx]="250" [sizeImagePx]="200" [albums]="albumsCompany"
                (albumEmitter)="albumEmitter($event)" (positionEmitter)="positionEmitter($event)"
                (regresarEmmiter)="regresarEmmiter($event)">
            </app-albums>
        </div>
    </ng-template>
    <!-- == SERVICIOS == -->
    <ng-template class="vistas-templates" #servicios>
        <div class="tab-servicios animated fadeIn">
            <!-- == CARDS SERVICIOS == -->
            <app-card-subservice *ngFor="let service of activeSubServices" [service]="service.subService"
                caso="serviceDos" [idCity]="cityId" [idService]="service.subService._id"
                (changedService)="emitDataSubs(service.subService._id, cityId)"></app-card-subservice>
        </div>
    </ng-template>
    <!-- == PROFESIONALES == -->
    <ng-template class="vistas-templates" #profesionales>
        <div class="tab-profesionales animated fadeIn">
            <app-card-subservice *ngFor="let doctor of doctors" [doctors]="doctor" caso="doctors">
            </app-card-subservice>
        </div>
    </ng-template>
    <!-- == SUCURSALES == -->
    <ng-template class="vistas-templates" #sucursales>
        <div class="tab-sucursales animated fadeIn">
            <article *ngFor="let branch of branches" class="card-sucursal">
                <figure>
                    <img [src]="branch.avatar" [alt]="branch.name">
                </figure>
                <div class="datos-sucursal">
                    <h1>{{branch.name}}</h1>
                    <h2>{{branch.municipality.cityName}}</h2>
                </div>
                <div class="contacto-sucursal">
                    <div *ngIf="branch.cellPhones && branch.cellPhones[0].whatsapp === true" class="container-celular">
                        <h2>Contacto</h2>
                        <span>{{ ( branch.cellPhones[0].prefix + '-' + branch.cellPhones[0].phone) || 'No Registra'}}
                        </span>
                    </div>
                    <div *ngIf="branch.cellPhones && branch.cellPhones[1] && branch.cellPhones[1].whatsapp === false && branch.cellPhones[1].movil === false"
                        class="container-telefono">
                        <h2>Contacto 2</h2>
                        <span>{{(branch.cellPhones[1].prefix + '-' + branch.cellPhones[1].phone ) || 'No Registra' }}
                        </span>
                    </div>
                    <div class="container-direccion">
                        <h2>Dirección </h2>
                        <span>{{branch.addressSettings.address}} </span>
                    </div>
                </div>
            </article>
        </div>
    </ng-template>
    <!-- === SECTION EMPRESA ===-->
    <section class="section-company" [class.custom-height]="isDetallesSubServicioActive">
        <div class="container-company" [title]="infoBasicCompany.name">
            <div class="container-imagenes">
                <img class="cover" [src]="infoBasicCompany.cover" [alt]="infoBasicCompany.name">
                <img class="avatar" [src]="infoBasicCompany.avatar" [alt]="infoBasicCompany.name">
            </div>
            <div class="container-entidad">
                <h3>{{infoBasicCompany.name}}</h3>
                <p>Empresa Verificada</p>
                <p>{{infoDetailsCompany.company.municipality.cityName}}</p>
            </div>
            <hr style="width: 87%; margin-top: 0.5rem;">
            <div class="container-datos">
                <div class="datos-company">
                    <div class="container-email">
                        <p>Correo Electronico</p>
                        <p>{{infoDetailsCompany.company.companySocialProfile.email || 'No Registra'}} </p>
                    </div>
                </div>
                <p style="font-weight: 600;">Redes sociales</p>
                <div class="container-redes">
                    <div
                        *ngFor="let item of infoDetailsCompany.company.companySocialProfile.socialMedia; let i = index">
                        <a [href]="item.url" target="_blank">
                            <i [class]="item.icon"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Ticket, pasar a display block para   poder mirar la img y corrobar los datos -->
     <div class="visual-orden-descuento" *ngIf="!specialityOfPets && specialityOfHumans">
         <ng-container>
              <app-patient-ticket  [infoPatient]="infoPatient" [infoAppointment]="infoAppointment"></app-patient-ticket>
              <canvas #canvasEl style="display:none;"></canvas>
          </ng-container>
    </div>
    <div class="visual-orden-descuento" *ngIf="specialityOfPets  && !specialityOfHumans ">
        <ng-container   >
            <app-pet-ticket [infoPatient]="infoPatient" [infoPet]="infoPet" [infoAppointment]="infoAppointment"></app-pet-ticket>
            <canvas #canvasPets style="display:none;"></canvas>
        </ng-container>
    </div>
</main>