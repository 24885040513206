<div *ngIf="viewHeader" class="card_imagen_header">
  <div class="title_header fw_500 fz_20 ml-4">Ver cita</div>
  <button type="button" id="idCerrarCitas" class="btn btn-circle btn_back" title="Cerrar"
    (click)="modalService.closeModal()"><i class="ti ti-close"></i> </button>

  <!-- <custom-buttons type="button" id="idCerrarCitas" class="btn btn-circle btn_back" text="" textColor="#fff"
    [title]="'Cerrar'" (click)="modalService.closeModal()" icon="ti ti-close">
    <i class="ti ti-close"></i>
  </custom-buttons> -->
</div>
<div class="config_content">

  <div class="row settings_row">

    <div class="col-md-4 col-xs-12"><strong class="fw_600">Fecha de la cita</strong>
      <p class="fw_400">{{appointmentSelected.appointmentDate | date}}</p>
    </div>
    <div class="col-md-4 col-xs-12"><strong class="fw_600">Hora de la cita</strong>
      <p class="fw_400">{{appointmentSelected.hour | hourFormat }}</p>
    </div>
    <div class="col-md-4 col-xs-12"><strong class="fw_600">Estado de la cita</strong>
      <br>

      <span *ngIf="appointmentSelected.status.type=='success'" 
        class="label label_active fz_14 fw_500">Atentida</span>
      <span *ngIf="appointmentSelected.status.type=='inactive'"
        class="label label_inactive fz_14 fw_500">Inactiva</span>
      <span *ngIf="appointmentSelected.status.type=='cancelled'"
        class="label label_danger fz_14 fw_500">Cancelada</span>
      <span *ngIf="appointmentSelected.status.type=='in_progress'"
        class="label label_active fz_14 fw_500">En curso</span>

    </div>

    <div class="col-md-8 col-xs-12 "><strong class="fw_600">Dirección de la Cita</strong>
      <p class="fw_400">
        {{appointmentSelected.branch?.address}}
        {{appointmentSelected.branch?.neighbor}}
      </p>
    </div>
    <div class="col-md-4 col-xs-12"> <strong class="fw_600">Ubicación</strong>
      <br>
      <p class="fw_400">{{appointmentSelected.branch.municipality.cityName}}</p>
    </div>
  </div>

  <div *ngIf="appointmentSelected.patient">
    <hr>
    <div class="row settings_row">
      <div class="col-md-4 col-xs-12"><strong class="fw_600">Número de Documento</strong>
        <p class="fw_400">{{appointmentSelected.patient.documentNumber}}</p>
      </div>
      <div class="col-md-4 col-xs-12"><strong class="fw_600">Nombre Completo</strong>
        <p class="fw_400">{{appointmentSelected.patient.names}} {{appointmentSelected.patient.lastNames}}</p>
      </div>
  
      <div class="col-md-4 col-xs-12"><strong class="fw_600">Género</strong>
        <p class="fw_400">{{appointmentSelected.patient.gender}}</p>
      </div>
    </div>
    <div class="row settings_row">
      <div class="col-md-4 col-xs-12 "><strong class="fw_600">Fecha de Nacimiento</strong>
        <p class="fw_400">{{appointmentSelected.patient.birthday | date}}</p>
      </div>
      <div class="col-md-4 col-xs-12"><strong class="fw_600">Edad a la fecha de cita</strong>
        <p class="fw_400">{{edadCita}}</p>
      </div>
      <div class="col-md-4 col-xs-12"><strong class="fw_600">Edad a la fecha actual</strong>
        <p class="fw_400">{{edadActual}}</p>
      </div>
  
      <div class="col-md-4 col-xs-12"> <strong class="fw_600">Teléfonos</strong>
        <br>
        <ng-container *ngIf="cellPhones.length>0">
          <div class="fw_400" *ngFor="let cellPhone of cellPhones">{{cellPhone.prefix}}-{{cellPhone.phone}}</div>
        </ng-container>
        <ng-container *ngIf="cellPhones.length==0">
          <p class="fw_400">No hay teléfonos registrados</p>
        </ng-container>
      </div>
  
    </div>
  </div>



</div>
