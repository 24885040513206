import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Tab } from '@interfaces/shared-components.interface';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class TabsComponent {

  @Input() tabsInfo: Tab [] =[]; 
  @Input() classInput='';
  @Output() switch = new EventEmitter<string>();

  tabsSwitch(tab:Tab){
    const nTab = tab.tabSwitch || '';
    this.switch.emit(String(nTab));
  }

}
