<div class="container mb-4">
  <p class="mt-4 fw_400">Actualmente, no eres visible para los usuarios. <br> Para mejorar tu visibilidad, por favor completa los siguientes pasos.</p>
  <!-- <br> -->
  <ng-container *ngIf="!data.steps.createServices.complete && data.steps.createServices.enable">
    <div class="row">
      <!-- <div class="col">
        <h4> Paso 1: SubServicios</h4>
      </div> -->
      <div class="col"><ng-container *ngTemplateOutlet="button; context: { textButton: 'Paso 1: Crear sub-servicios', redirection: 'servicios', iconsButton: 'fa fa-server'}"></ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!data.steps.createStaff.complete && data.steps.createStaff.enable">
    <div class="row">
      <!-- <div class="col">
        <h4> Paso 2: Crear Personal</h4>
      </div> -->
      <div class="col"><ng-container *ngTemplateOutlet="button; context: { textButton: 'Paso 2: Crear personal', redirection: 'personal' , iconsButton: 'fa fa-users'}"></ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!data.steps.createSchedules.complete && data.steps.createSchedules.enable">
    <div class="row">
      <!-- <div class="col">
        <h4> Paso 3: Crear Horarios</h4>
      </div> -->
      <div class="col"><ng-container
          *ngTemplateOutlet="button; context: { textButton:' Paso 3: Crear horarios ',redirection: 'servicios/horarios' , iconsButton: 'fa fa-calendar'}"></ng-container></div>
    </div>
  </ng-container>
  <!-- <ng-container *ngIf="!data.steps.createServices.complete && data.steps.createServices.enable">Crear Servicios</ng-container> -->
  <!-- <ng-container *ngIf="!data.steps.createServices.complete && data.steps.createServices.enable">Crear Servicios</ng-container> -->
</div>

<ng-template #button let-redirection="redirection" let-textButton="textButton" let-iconsButton="iconsButton">
  <!-- <button type="button" class="btn waves-effect waves-light btn-rounded btn-success mt-1"
    (click)="redirectionPage(redirection)">Ir</button> -->
  <div class="text-center">
    <custom-buttons
      type="button"
      class="mt-1"
      [icon]="iconsButton"
      (click)="redirectionPage(redirection)"
      [text]="textButton"
      textColor="#fff"
      [title]="''"
      [rounded]="true"
       width="300px">
    </custom-buttons>
  </div>

</ng-template>
