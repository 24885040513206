import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { ApplicationService } from './application.service';
import { EncryptService } from './encrypt.service';
import { Store, select } from '@ngrx/store';
import { logout, regenerateAuthState } from '../auth/state/auth.actions';
import { reloadState, reloadStateWithStorage, selectRol } from '../pages/session/state/session.actions';
import { InformationService } from './information.service';
import { Role, loginData } from '../interfaces/auth.interface';
import { memberId, userData } from '../auth/state/auth.selectors';
import { dataId, getRolSelect } from '../pages/session/state/session.selectors';
import { RolSession } from '@interfaces/session.interface';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SweetAlertService } from './sweet-alert.service';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class StatesNgrxService {

  // Crear una propiedad privada para almacenar el valor actual de memberId
  private _memberId: string | null = null;
  private _dataId: string | null = null;

  // Crear un Observable público para memberId
  memberId$: Observable<string> = this.store.pipe(select(memberId));
  dataId$: Observable<string> = this.store.pipe(select(dataId));

  constructor(
    private store: Store,
    private localStorage: LocalStorageService,
    private encryptService: EncryptService,
    private applicationService: ApplicationService,
    private infoService:InformationService,
    private router: Router,
    private swalService: SweetAlertService
  ) {

      // Suscribirse al memberId$ para actualizar el valor privado _memberId cuando cambie el estado
      this.memberId$.subscribe((id) => {
        this._memberId = id;
      });

      this.dataId$.subscribe((id) => {
        this._dataId = id;
      });
  }
 
  // Proporcionar un getter para obtener el memberId sincrónicamente
   get memberIdValue(): string {
    if (this._memberId === null) {
      throw new Error('memberId has not been set!');
    }
    return this._memberId;
  }

  // Proporcionar un getter para obtener el dataId sincrónicamente
  get dataIdValue(): string {
    if (this._dataId === null) {
      throw new Error('dataId has not been set!');
    }
    return this._dataId;
  }

  getMemberId(){
    return this.store.select(memberId);
  }
 
  getDataGlobalUser(){
    return this.store.select(userData);
  }

  getDataRolSelect(){
    return this.store.select(getRolSelect);
  }
    
  initState() {
    
    const dataUser = this.localStorage.getItem('usdt');
    const token = this.localStorage.getItem('tkn');
    const session = this.localStorage.getItem('session');

    if (dataUser && token) {
      const anio = this.applicationService.getDate();
      const key = token + anio;

      const dataDecrypt = JSON.parse(
        this.encryptService.manageActions({ action: 'symmetricDecryption', data: dataUser, key })
      );

      this.store.dispatch(regenerateAuthState({ body: dataDecrypt, token }));
      if (session) {

        this.store.dispatch(
          reloadState({
            sessionData: dataDecrypt,
            rolSession: JSON.parse(session)
          })
        );
        // setTimeout( () => this.router.navigate(['/home']), v200 );
      }
    }
  }

  updateGlobalUserAndSetRol(rolSession: RolSession) {
    this.store
    .pipe(select(userData), first())
    .subscribe(({ _id }) => {

      this.infoService.getInfoUser(_id).subscribe( (body: loginData) => {
        this.store.dispatch(reloadStateWithStorage({ sessionData: body, rolSession }))
        this.swalService.lauchSwal('', 'Cambio de rol éxitoso.', 'success');
      });
    });
  }
 
  updateGlobalUser() {

    const session = this.localStorage.getItem('session');
    if(session) {

      this.store
      .pipe(select(userData), first())
      .subscribe(({ _id }) => {

        this.infoService.getInfoUser(_id).subscribe( (body: loginData) => {
          this.store.dispatch(reloadStateWithStorage({ sessionData: body, rolSession: JSON.parse(session) }))
        });
      });

    } else{
      this.swalService.lauchSwal('', 'La sesión ha expirado por favor vuelve a iniciar sesión.', 'warning');
      this.store.dispatch(logout());
    }

  }

  changeRol({ rol, _idData }: Role) {

    const rolSession: RolSession = { rol, _idData };

    this.store
      .pipe(select(userData), first())
      .subscribe((sessionData) => { 

        this.store.dispatch(selectRol({ sessionData, rolSession }));
        this.updateGlobalUser();
      }
    );

    setTimeout( () => this.router.navigate(['/home']), 200 );
  }
  
}
