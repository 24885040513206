import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReusableModalComponent } from './modal/reusable-modal.component';
import { StoreModule } from '@ngrx/store';
import { modalReducer, modalStateFeatureKey } from './state/modal.reducers';
import { PhotosPreVisualizerComponent } from './photos-pre-visualizer/photos-pre-visualizer.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImagesCropperComponent } from './images-cropper/images-cropper.component';
import { ModalAnchorDirective } from './modal-anchor.directive';
import { EffectsModule } from '@ngrx/effects';
import { ModalEffects } from './state/modal.effects';

@NgModule({
  declarations: [ReusableModalComponent, PhotosPreVisualizerComponent, ImagesCropperComponent, ModalAnchorDirective],
  imports: [
    CommonModule,
    StoreModule.forFeature(modalStateFeatureKey, modalReducer), 
    EffectsModule.forFeature([ModalEffects]),
    ImageCropperModule
  ],
  exports: [ReusableModalComponent, PhotosPreVisualizerComponent, ImagesCropperComponent]
})
export class ReusableModalModule { }
