import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentFilter } from './documentFilter.pipe';
import { CapitalizeFirstPipe } from './capitalize-first.pipe';
import { CurrencyFormatPipe } from './currency-format.pipe';
import { FormatAppointmentDatePipe } from './format-appointment-date.pipe';
import { PercentagePipe } from './percentage.pipe';
import { CapitalizeExceptFirstPipe } from './capitalize-Except-First.pipe';
import { FormatDatePipe } from './format-date.pipe';
import { NoDataPipe } from './no-data.pipe';
import { HourFormatPipe } from './hour-format.pipe';
import { FormatPricePipe } from './format-price.pipe';
import { FormatDateAMPMPipe } from './format-date-ampm.pipe';
import { GetImagePipe } from './get-image.pipe';
import { TranslateStringPipe } from './translate-string.pipe';
import { AgeStringPipe } from './age-string.pipe';

@NgModule({
  declarations: [
    DocumentFilter,
    CapitalizeFirstPipe,
    CurrencyFormatPipe,
    PercentagePipe,
    CapitalizeExceptFirstPipe,
    FormatAppointmentDatePipe,
    FormatDatePipe,
    NoDataPipe,
    HourFormatPipe,
    FormatPricePipe,
    FormatDateAMPMPipe,
    GetImagePipe,
    TranslateStringPipe,
    AgeStringPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DocumentFilter,
    CapitalizeFirstPipe,
    CurrencyFormatPipe,
    PercentagePipe,
    CapitalizeExceptFirstPipe,
    FormatAppointmentDatePipe,
    FormatDatePipe,
    NoDataPipe,
    HourFormatPipe,
    FormatPricePipe,
    FormatDateAMPMPipe,
    GetImagePipe,
    TranslateStringPipe,
    AgeStringPipe
  ]
})
export class PipesModule { }
