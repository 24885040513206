import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  /**
   * Servicio para interactuar con el almacenamiento local del navegador.
   */

  constructor() {}

  /**
   * Obtiene el valor asociado a una clave en el almacenamiento local.
   *
   * @param key - La clave del valor a obtener.
   * @returns El valor asociado a la clave, o null si no se encuentra.
   */
  getItem(key: string): string | null {
    return localStorage.getItem(key);
  }

  /**
   * Obtiene el valor asociado a una clave en el almacenamiento local y loo retorno como un objeto.
   *
   * @param key - La clave del valor a obtener.
   * @returns El valor asociado a la clave, o null si no se encuentra.
   */
  getItemObj(key: string): any | null {
    var storedItem = localStorage.getItem(key);
    if (storedItem) {
      return JSON.parse(storedItem);
    } else {
      return null;
    }
  }

  /**
   * Almacena un valor asociado a una clave en el almacenamiento local.
   *
   * @param key - La clave del valor a almacenar.
   * @param value - El valor a almacenar.
   * @returns void
   */
  setItem(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  /**
   * Almacena un valor asociado a una clave en el almacenamiento local.
   *
   * @param key - La clave del valor a almacenar.
   * @param value - El valor a almacenar.
   * @returns void
   */
  setItemObj(key: string, value: string): void {
    return localStorage.setItem(key, JSON.stringify(value));
  }

  /**
   * Elimina el valor asociado a una clave del almacenamiento local.
   *
   * @param key - La clave del valor a eliminar.
   * @returns void
   */
  removeItem(key: string) {
    localStorage.removeItem(key);
  }

  /**
   * Elimina todos los valores almacenados en el almacenamiento local.
   *
   * @returns void
   */
  clear() {
    localStorage.clear();
  }

  setTokenAndUserData(encryptData: string, token: string) {
    localStorage.setItem('usdt', encryptData);
    localStorage.setItem('tkn', token);
  }
}
