<div class="multi-select">
  <div class="selected" *ngIf="labelText">
    {{ labelText }}
    <!-- Selecciona los subservicios que prestaras -->
  </div>

  <div *ngIf="searchable" class="search__container" title="Buscar...">
    <input type="text" placeholder="Buscar..." title="Buscar...">
    <i class="fa fa-search icon__search" title="Buscar..."></i>
  </div>

  <div class="options" [ngClass]="{adaptable}">
    <ng-container *ngFor="let item of data">
      <label class="item__check">
        <input type="checkbox" value="item[idField] | item[valueField]" (change)="toggleCheck(item)" [disabled]="item.disabled" [checked]="item.checked">
        <span class="checkmark" [ngClass]="{disabled:item.disabled}"></span>
        <p class="text__item" [ngClass]="{disabled:item.disabled}" [title]="item[valueField]">{{item[valueField]}}</p>
      </label>
    </ng-container>
  </div>
</div>