<div *ngIf="caso === 'serviceUno'" (click)="onEmitDataSubs(subService)" id="card-1" class="card"
    [title]="subService.subService.subserviceName">
    <div class="avatar">
        <img [src]="subServiceImg" [alt]="subService?.subService?.subserviceName || 'Default Image'" />

    </div>
    <div class="header">
        <h2>{{subService.subService.subserviceName | capitalizeExceptFirst }}</h2>
        <div class="speciality">{{ subService.subService.speciality.specialityName}}</div>
        <div class="company">
            <p>{{ subService.company.companySocialProfile.name | capitalizeExceptFirst }}</p>
        </div>
    </div>

    <hr class="style4">


    <div class="desc">
        <div class="section-price-oferta">
            <span>Precio Oferta</span>
            <span>{{ subService.subService.discountedPrice | currencyFormat }}</span>
        </div>
        <div class="section-price">
            <span>Descuento</span>
            <span> {{subService.subService.discountedPrice | percentage : subService.subService.price }}%</span>
        </div>
        <div class="section-price">
            <span>Precio Normal</span>
            <span class="tachado">{{ subService.subService.price | currencyFormat }}</span>
        </div>
    </div>
</div>

<div *ngIf="caso === 'serviceDos'" (click)="changedNewService(idCity, idService)" id="card-1" class="card"
    [title]="service.subserviceName">
    <div class="avatar">
        <img [src]="service.subserviceImages[0].path || subServiceImgDefault" [alt]="service.subserviceName" />

    </div>
    <div class="header">
        <h2>{{service.subserviceName | capitalizeExceptFirst }}</h2>
        <div class="speciality">{{ service.speciality.specialityName}}</div>
        <div class="company"> </div>
    </div>


    <hr class="style4">


    <div class="desc">
        <div class="section-price-oferta">
            <span>Precio Oferta</span>
            <span>{{ service.discountedPrice | currencyFormat }}</span>
        </div>
        <div class="section-price">
            <span>Descuento</span>
            <span> {{service.discountedPrice | percentage : service.price }}%</span>
        </div>
        <div class="section-price">
            <span>Precio Normal</span>
            <span class="tachado">{{ service.price | currencyFormat }}</span>
        </div>
    </div>
</div>

<div *ngIf="caso === 'doctors'" id="card-1" class="card" [title]="doctors.fullName">
    <div class="avatar">
        <img [src]="doctors.avatar" [alt]="doctors.fullName" />
    </div>
    <div class="header">
        <h2>{{ doctors.fullName | capitalizeExceptFirst }}</h2>
        <div *ngIf="doctors.specialities && doctors.specialities[0].specialityName" class="speciality">{{
            doctors.specialities[0].specialityName }}</div>
        <div class="profesional"> Profesional </div>
    </div>
    <div class="body-doctor">
        <p class="title-body">Social</p>
        <div *ngIf="doctors.soccialAccounts && doctors.soccialAccounts.length > 0" class="socials">
            <div *ngFor="let item of doctors.soccialAccounts">
                <a [href]="item.url" target="_blank">
                    <i [class]="item.icon"> </i>
                </a>
            </div>
        </div>
        <p class="no-registra" *ngIf="!doctors.soccialAccounts || doctors.soccialAccounts.length === 0 ">No Registra</p>
    </div>
</div>

<div *ngIf="caso === 'familyGroup'" id="card-1" class="card" [title]="infoFamilyGroup.name">
    <div class="avatar">
        <img [src]="infoFamilyGroup.avatar" [alt]="infoFamilyGroup.name" />
    </div>
    <div class="container text-center mt-4">
        <h3 class="m-b-10 text_ellipsis" [title]="infoFamilyGroup.name">{{infoFamilyGroup.name}}</h3>
        <h6 class="text-muted">Integrantes: <strong>{{infoFamilyGroup.numberOfBeneficiaries}}</strong></h6>
        <h6 class="text-muted">Mascotas: <strong>{{infoFamilyGroup.numberOfPets}}</strong></h6>
    </div>
    <div class="actions action-button">
        <custom-buttons *ngIf="infoFamilyGroup.isLeader" class="button-data" title="Ver" inputBackgroundColor="primary"
            text="Ver" type="button" (click)="actionButton(infoFamilyGroup,true)">
        </custom-buttons>
        <custom-buttons *ngIf="!infoFamilyGroup.isLeader" class="button-data" title="Ver" inputBackgroundColor="primary"
            text="Ver" type="button" (click)="actionButton(infoFamilyGroup, false)">
        </custom-buttons>
        <custom-buttons *ngIf="infoFamilyGroup.isLeader" class="button-data" title="Editar"
            inputBackgroundColor="secondary" text="Editar" type="button" (click)="actionButtonEdit(infoFamilyGroup)">
        </custom-buttons>
    </div>
</div>
