<span class="input__group">
  <div class="box__label">
    <label class="input__label" for="input_component">{{label}}<strong *ngIf="required"> *</strong></label>
    <i class="fa fa-question icon__helper" [title]="helper" *ngIf="helper">
    </i>
  </div>
  <div class="box__input">
    <input type="tel" class="input__form__phone" #phoneInputRef [value]="phoneNumber | phoneMask" (input)="setPhone($event)" (blur)="onBlur()" placeholder="(300) 000-0000"  [ngClass]="{invalid: control.dirty && control.invalid}"/>
    <input type="hidden" [formControl]="control">
    <i class="fa fa-close icon__clear" *ngIf="!control?.disabled" [ngClass]="{visible: phoneInputRef.value != ''}" (click)="control.setValue(''); phoneInputRef.value = ''" title="Limpiar"></i>
    <span class="loader__icon" *ngIf="validating"></span>
    <span class="fa fa-check valid__check__icon input__icon" *ngIf="validPhone"></span>
  </div>
  <div class="box__error" [ngClass]="{visible:errors.length}">
    <ul>
      <li class="box__error__item" *ngFor="let error of errors"><strong>* </strong>{{error}}</li>
    </ul>
  </div>
</span>