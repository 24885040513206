import { Component, EventEmitter, HostBinding, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'form-multiselect',
  templateUrl: './form-multiselect.component.html',
  styleUrls: ['./form-multiselect.component.scss']
})
export class FormMultiselectComponent implements OnChanges {
  @Output() dataChecks = new EventEmitter()

  @Input() valueField: string = 'label'
  @Input() collapsable: boolean = true
  @Input() searchable: boolean = false
  @Input() adaptable: boolean = false
  @Input({ required: true }) data: any
  @Input() selected: string[] = []
  @Input() idField: string = 'id';
  @Input() show: boolean = false
  @Input() labelText!: string

  @HostBinding('style.height') @Input() sizeHeight: string = "0"

  private checkList: Record<string, any> = {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['show'] && this.collapsable) {
      let { currentValue } = changes['show']
      this.sizeHeight = currentValue ? this.adaptable ? 'auto' : '150px' : '0';
    }
  }

  toggleCheck(item: any) {
    this.checkList[item[this.idField]]
      ? delete this.checkList[item[this.idField]]
      : this.checkList[item[this.idField]] = item

    this.dataChecks.emit(this.checkList)
  }

  setDefault() {

  }
}
