<div class="text-center">
    <div class="row">
        <ng-container *ngIf="(userData$ | async) as userData">
            <div class="col-4" *ngFor="let rol of userData.roles" style="cursor: pointer;" (click)="selectRol(rol)">
                <div class="card">
                    <p>rol: {{ rol.rol }}</p>
                    <p>nombre: {{ rol.nombre }}</p>
                </div>
            </div>
        </ng-container>
    </div>
</div>