<router-outlet></router-outlet>

<!-- <footer></footer> -->
<!-- TODO: icono whatsapp -->
<!-- 
  <a href="https://api.whatsapp.com/send?phone=573174785093&text=Hola!%20me%20comunico%20desde%20la%20web%20quiero%20saber%20m%C3%A1s%20de%20Prevenir%20Descuentos%20M%C3%A9dicos!"
  class="btn-wsp" target="_blank">
  <i style="font-size: 35px; margin-top:12px;" class="fa fa-whatsapp icono"></i>
</a>

--> 