<div class="modal fade modalFotos" id="photo-previsualizer" data-backdrop="static" data-keyboard="false"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" style="background: transparent;">
    <div class="modal-content" style="background: transparent;">
      <div class="modal-body" style="background: transparent;">

        <div class="card" style="background: transparent;">
          <div class="card-body" style="background: transparent;">
            <div id="carouselExampleIndicators000xxss" class="carousel slide" data-ride="carousel">
              <div class="carousel-inner" role="listbox">
                <div class="row">
                  <div class="col-10"></div>
                  <div class="col-2">
                    <button type="button" class="btn btn-rounded waves-effect waves-light btn-danger"
                      data-dismiss="modal"><i class="fa fa-times"></i></button>

                    <!-- <custom-buttons type="button"
                      class="btn btn-rounded waves-effect waves-light btn-danger animated fadeIn" data-dismiss="modal"
                      text="" textColor="#fff" [title]="''" icon="fa fa-times">
                      <i class="fa fa-times"></i>
                    </custom-buttons> -->
                  </div>
                </div>
                <div class="carousel-item text-center active">
                  <a class="">
                    <img src="../assets/images/users/5.jpg" class="mk8" />
                    <br />
                    <br />
                    <div class="text-center">
                      <div class="btn-group dropup btn-group" role="group">
                        <button type="button" class="btn btn-rounded btn-success"><i class="fa fa-check"></i>
                          Seleccionar portada</button>

                        <!-- <custom-buttons type="button" class="btn btn-rounded btn-success animated fadeIn"
                          text="Seleccionar portada" textColor="#fff" [title]="''" icon="fa fa-check">
                        </custom-buttons> -->

                        <button type="button" class="btn btn-rounded btn-danger"> <i class="ti-trash"></i> Eliminar
                        </button>

                        <!-- <custom-buttons type="button" class="btn btn-rounded btn-danger animated fadeIn" text="Eliminar"
                          textColor="#fff" [title]="''" icon="ti-trash">
                        </custom-buttons> -->
                      </div>
                    </div>
                  </a>
                </div>

              </div>
              <a class="carousel-control-prev mk9" href="#carouselExampleIndicators000xxss" role="button"
                data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span> <span
                  class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next mk9" href="#carouselExampleIndicators000xxss" role="button"
                data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span> <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>
