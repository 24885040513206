import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatHour'
})
export class FormatHourPipe implements PipeTransform {

  transform(value: string): unknown {
    let hour = Number(value.split(':')[0])
    let minutes = value.split(':')[1]
    return `${hour <= 12 ? hour : hour - 12}:${minutes} ${hour <= 12 ? 'AM' : 'PM'}`;
  }

}
