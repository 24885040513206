import { Pipe, PipeTransform } from '@angular/core';
import { format, formatInTimeZone } from 'date-fns-tz';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {

  
  transform(date: any, formatDate: string): unknown {
    const timeZone = 'UTC';

    // Utilizar `formatInTimeZone` para convertir y formatear la fecha en una sola operación
    const formattedDate = formatInTimeZone(new Date(date), timeZone, formatDate);

    return formattedDate;
  }

}