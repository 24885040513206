import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FamilyGroup } from '@interfaces/information.interface';
import { ModalService } from '@services';
import { StatesNgrxService } from '../../../../services/states-ngrx.service';
import { SweetAlertService } from '../../../../services/sweet-alert.service';
import { FamilyGroupsServiceService } from '../../../../roles/services/family-groups-service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-family-admin',
  templateUrl: './family-admin.component.html',
  styleUrls: ['./family-admin.component.scss']
})
export class FamilyAdminComponent implements OnInit {

  @Input() data: any;
  @Input() closeAndUpdate = true;

  @Output() myEvent = new EventEmitter<boolean>();

  public groupSelected?:FamilyGroup;
  public groupCreated?:FamilyGroup;
  public groupForm!: FormGroup;

  fileComponent?:File;
  fileImageCrop?:File;

  imageUrls:string[]=[];
  noImages = false;

  constructor(
    public modalService: ModalService,
    private statesNgrxService:StatesNgrxService,
    private swalService: SweetAlertService,
    private familyGroupsService:FamilyGroupsServiceService,){}

  ngOnInit(): void {
    
    
    if (this.data.groupSelected) {
      this.groupSelected = this.data.groupSelected; 
      if (this.groupSelected!.avatar) {
        this.imageUrls.push(this.groupSelected!.avatar);        
      } 
      this.inicializarUpdateGroupsForm(this.groupSelected)    
    } else{
      this.iniciarGroupsAgregateForm();
    }

    if (this.data.closeAndUpdate === false) {
      this.closeAndUpdate = false;
    }
  }

  async iniciarGroupsAgregateForm() {
    this.groupForm = new FormGroup({
      name: new FormControl<string | null>(null, Validators.required),
    });
  }

  async inicializarUpdateGroupsForm(group:any) {     
    this.groupForm = new FormGroup({
      name: new FormControl<string | null>(group.name || null,Validators.required),
      groupId: new FormControl<string | null>(group._id || null,Validators.required),
    });
  }

  /**
   * Realiza un desplazamiento suave hasta el elemento con el ID especificado.
   *
   * @param {string} id - El ID del elemento al que se realizará el desplazamiento.
   * @returns {void}
   */
  hacerScroll(id: string): void {
    // Buscar el elemento por su ID
    var elementos = document.querySelectorAll(`#${id}`)[0] as HTMLElement;

    // Realizar el desplazamiento suave al elemento
    elementos.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

    /**
   * Maneja la emisión de la carga de una imagen.
   *
   * @param {File} file - El objeto File de la imagen cargada.
   * @returns {void}
   */
    uploadedImageEmmiter(file: File): void {
      // Almacena el objeto File de la imagen cargada
      this.fileComponent = file;
    }

    /**
   * Maneja la emisión de regreso indicando si se debe regresar al estado anterior.
   *
   * @param {boolean} regresar - Indica si se debe regresar al estado anterior.
   * @returns {void}
   */
  regresarEmitter(regresar: boolean): void {
    // Si se debe regresar, limpia el objeto File almacenado
    if (regresar) {
      this.fileComponent = undefined;
    }
  }

  /**
   * Maneja la emisión de la imagen recortada.
   * - Agrega la imagen al array de archivos.
   * - Crea URLs de objeto para cada archivo en el array.
   * - Limpia el objeto File almacenado.
   * - Actualiza la bandera de ausencia de imágenes.
   * - Establece el estado del control 'images' del formulario como 'valid'.
   *
   * @param {File} file - El objeto File de la imagen recortada.
   * @returns {void}
   */
  cropperImageEmmiter(file: File): void {
    if (file && file instanceof File) {
      this.fileImageCrop = file;
      
      // Crea URLs de objeto para cada archivo en el array
     this.imageUrls.push(URL.createObjectURL(file));     

      // Limpia el objeto File almacenado
      this.fileComponent = undefined;

      // Actualiza la bandera de ausencia de imágenes
      this.noImages = false;
      
    }
  }

    /**
   * Elimina una imagen de la lista de archivos y sus URLs asociadas.
   *
   * @param {number} i - Índice de la imagen en la lista.
   * @returns {void}
   */
    quitarImagenLista(i: number): void {
      // Elimina la imagen de la lista de archivos y sus URLs asociadas
      this.imageUrls.splice(i, 1);
      this.fileImageCrop = undefined;
    }

    
  async agregateFamilyGroup(form: FormGroup) {
   let userID = '';   
    const formData = new FormData();

    this.statesNgrxService.getDataGlobalUser().subscribe((resp:any)=>{                   
      if (!resp) {
        return //console.log('Error');                
      } 
      userID = resp._id;
    });
    
    if (form.valid) {
      formData.append('familyLeader',userID);
      formData.append('name', this.groupForm.value.name);
      if (this.fileImageCrop) {
        formData.append('avatar', this.fileImageCrop);        
      }

      const result = await Swal.fire({
        title: 'Estás seguro?',
        text: `Se va a Agregar el grupo familiar ${this.groupForm.value.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, agregar!',
        cancelButtonText: 'Cancelar',
      });

      if (result.isConfirmed) {
        
        this.familyGroupsService.createFamilyGroup(formData).then((resp:any)=>{          
          if (resp.ok) {
            this.myEvent.emit(true);

            if (this.closeAndUpdate) {
              this.modalService.closeModal();    
              
            } else{
              const elements = document.querySelectorAll('.modal_md');
              if (elements) {
                elements.forEach(element => {
                    // Remover la clase .modal_md
                    element.classList.remove('modal_md');
                    // Agregar la clase .modal_xl
                    element.classList.add('modal_xl');
                });                
              }
              this.groupCreated = resp.body;
            }
            
          }else{
            //console.log(resp);
          }
          
        }).catch(err=>{
          //console.log(err);          
        });
        
      }
    } else {
      this.groupForm.markAllAsTouched();
      const requiredFields = [
        'name',
      ];

      const swalResult = await this.swalService.lauchSwalAsync(
        '',
        'Debes registrar los campos en color rojo, ya que son obligatorios',
        'warning'
      );
      if (swalResult) {
        setTimeout(() => {
          const scrollTo = requiredFields.find(
            (field) => this.groupForm.controls[field].invalid
          );
          if (scrollTo) {
            this.hacerScroll(
              `idInput${scrollTo.charAt(0).toUpperCase() + scrollTo.slice(1)}`
            );
          }
        }, 300);
      }
    }
  }

  cerrarFamilyAdmin(){
    this.myEvent.emit(true);    
    this.modalService.closeModal();
  }

  async updateFamilyGroup(form: FormGroup){    
     const formData = new FormData();
     
     if (form.valid) {
       formData.append('groupId',this.groupForm.value.groupId);
       formData.append('name', this.groupForm.value.name);
       if (this.fileImageCrop) {
         formData.append('avatar', this.fileImageCrop);        
       }
 
       const result = await Swal.fire({
         title: 'Estás seguro?',
         text: `Se va a Actualizar el grupo familiar ${this.groupSelected?.name}`,
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Si, actualizar!',
         cancelButtonText: 'Cancelar',
       });
 
       if (result.isConfirmed) {
         
         this.familyGroupsService.updateFamilyGroup(formData).then((resp:any)=>{
          
          if (resp.ok) {
            this.myEvent.emit(true);
            this.modalService.closeModal();            
          }else{
            //console.log(resp);
          }
          
         }).catch(err=>{
           //console.log(err);          
         });
         
       }
     } else {
       this.groupForm.markAllAsTouched();
       const requiredFields = [
         'name',
       ];
 
       const swalResult = await this.swalService.lauchSwalAsync(
         '',
         'Debes registrar los campos en color rojo, ya que son obligatorios',
         'warning'
       );
       if (swalResult) {
         setTimeout(() => {
           const scrollTo = requiredFields.find(
             (field) => this.groupForm.controls[field].invalid
           );
           if (scrollTo) {
             this.hacerScroll(
               `idInput${scrollTo.charAt(0).toUpperCase() + scrollTo.slice(1)}`
             );
           }
         }, 300);
       }
     }
    
  }
  

}
