import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SweetAlertService } from '@services';

@Component({
  selector: 'app-file-image-input',
  templateUrl: './file-image-input.component.html',
  styleUrls: ['./file-image-input.component.scss']
})
export class FileImageInputComponent {

  //tamaño de la imagen por defecto 'rounded_image_100','rounded_image_200'
  @Input() inputRoundedImage='rounded_image_100';
  @Input() inputImageDef='../../../assets/images/icon/photo_silhouette.png';
  @Input() inputProcesarImagen = false;
  @Input() inputMaxSizeFile = 3;
  @Input() inputMaxSizeError = 12;

  @Output() uploadedImageEmmiter: EventEmitter<File> = new EventEmitter<File>();

  fileComponent?:File;

  cargandoFile=false;
  imageDrop=false;
  errorTipo=false;


  constructor(private swalService: SweetAlertService){}

  /**
 * Comprime una imagen si su tamaño excede un límite especificado.
 * @param {File} file - El archivo de imagen para comprimir.
 * @returns {Promise<File>} Una promesa que se resuelve con el archivo de imagen comprimido.
 */
async compressImage(file: File, maxSize:number): Promise<File> {
  const maxFileSize = maxSize * 1024 * 1024; // 3MB

  if (file.size <= maxFileSize) {
    return file;
  }

  const img = await this.loadImage(file);
  let scale = 1;
  let compressedBlob: Blob;

  do {
    scale -= 0.1; // Reduzca la escala en cada iteración

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = img.width * scale;
    canvas.height = img.height * scale;

    ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);

    compressedBlob = await new Promise<Blob>(resolve => {
      canvas.toBlob(blob => {
        if (blob) {
          resolve(blob);
        }
      }, file.type);
    });

    // Si la escala es demasiado pequeña y aún no se puede alcanzar el tamaño de archivo deseado, rompe el bucle
    if (scale < 0.1 && compressedBlob.size > maxFileSize) {
      console.error("No se puede comprimir la imagen al tamaño deseado");
      break;
    }
  } while (compressedBlob.size > maxFileSize);

  return new File([compressedBlob], file.name, { type: file.type });

}


 /**
   * Carga un archivo de imagen y lo convierte en un elemento HTMLImageElement.
   * @param {File} file - El archivo de imagen para cargar.
   * @returns {Promise<HTMLImageElement>} Una promesa que se resuelve con el elemento de imagen cargado.
   */
 loadImage(file: File): Promise<HTMLImageElement> {
  
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = event.target?.result as string;
    };
    reader.readAsDataURL(file);
  });
}

   /**
   * Maneja el evento de arrastrar sobre el área de soltar.
   * @param {DragEvent} event - Evento de arrastrar.
   */
   onDragOver(event: DragEvent) {
    this.imageDrop=true;
    this.errorTipo=false;
    
    event.preventDefault();
    event.stopPropagation();
    var divDrop = document.getElementById('idDrop');
    if (divDrop) {
      divDrop.classList.add('border_drop');
      this.imageDrop=true;
    }
  }

  /**
   * Maneja el evento de dejar de arrastrar fuera del área de soltar.
   * @param {DragEvent} event - Evento de arrastrar.
   */
  onDragleave(event: DragEvent){
    var divDrop = document.getElementById('idDrop');
    if (divDrop) {
      divDrop.classList.remove('border_drop');
      this.imageDrop=false;
    }    
  }

  /**
   * Maneja el evento de soltar un archivo en el área de soltar.
   * @param {DragEvent} event - Evento de soltar.
   */
  onDrop(event: DragEvent) {
    this.errorTipo=false;
    
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer) {   
      
      const maxFileSizeError = this.inputMaxSizeError * 1024 * 1024;
      if (event.dataTransfer.files[0].size > maxFileSizeError) {
        this.swalService.lauchSwal('',`la imagen no puede ser mayor a, ${this.inputMaxSizeError} Megabytes`,'error');
        var divDrop = document.getElementById('idDrop');
        if (divDrop) {
          divDrop.classList.remove('border_drop');
          this.imageDrop = false;
        }
        return;
      }

      var divDrop = document.getElementById('idDrop');
      if (divDrop) {
        divDrop.classList.remove('border_drop');
        this.imageDrop=false;
      }

      const tipo = event.dataTransfer.files[0].type
      if(["image/jpeg","image/jpg","image/png"].includes(tipo)){
        this.fileComponent = event.dataTransfer.files[0];
        // //console.log(this.fileComponent);

        if (this.inputProcesarImagen) {
          this.compressImage(this.fileComponent,this.inputMaxSizeFile).then(compressedFile => {
            this.uploadedImageEmmiter.emit(compressedFile);
          });
        } else{
          this.uploadedImageEmmiter.emit(this.fileComponent);
        }

        
      } else{
        this.errorTipo=true;
      }
    }
  }


   /**
   * Muestra el selector de archivos para elegir una imagen.
   */
   selectImage() {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = 'image/*';
    inputElement.addEventListener('change', (event: Event) => this.onFileSelected(event));
    inputElement.click();
    
  }

  /**
   * Maneja la selección de un archivo mediante un selector de archivos.
   * @param {Event} event - Evento de selección de archivo.
   */
  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files) { 

      const maxFileSizeError = this.inputMaxSizeError * 1024 * 1024;
      if (input.files[0].size > maxFileSizeError) {
        this.swalService.lauchSwal('',`la imagen no puede ser mayor a, ${this.inputMaxSizeError} Megabytes`,'error');
        return;
      }
      
      const tipo = input.files[0].type
      if(["image/jpeg","image/jpg","image/png"].includes(tipo)){
        this.fileComponent = input.files[0];
        // //console.log(this.fileComponent);

        if (this.inputProcesarImagen) {
          this.compressImage(this.fileComponent,this.inputMaxSizeFile).then(compressedFile => {
            this.uploadedImageEmmiter.emit(compressedFile);
          });
        } else{
          this.uploadedImageEmmiter.emit(this.fileComponent);
        }      

      } else{
        this.errorTipo=true;
      }          
    }    
  }

}
