import { Component, Input } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  options: AnimationOptions = { path: 'assets/lottie-animations/loader.json' };
  @Input() message: string = '';
}
