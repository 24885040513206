<div class="calendar__loader" [ngClass]="{visible: loader}">
  <h2 class="title__animation">Cargando agenda</h2>
  <ng-lottie [options]="options"></ng-lottie>
</div>

<div class="calendar" [ngClass]="{extended: extended||hourly}">
  <div class="month">
    <i class="fa fa-chevron-left month__navigation" (click)="prevMonth()" title="anterior"></i>
    <h4 #currentMonthRef class="month__current"></h4>
    <i class="fa fa-chevron-right month__navigation" (click)="nextMonth()" title="siguiente"></i>
  </div>

  <p #nowDateRef class="now__date"></p>

  <div class="weekdays" [ngClass]="{extended: extended||hourly}">
    <span class="weekdays__item" *ngFor="let WeekName of weekdays" [title]="WeekName.name">{{ extended ? WeekName.name: WeekName.abbreviations.letter}}</span>
  </div>
  <div class="days" [ngClass]="{fullSize}" *ngIf="!hourly" [ngClass]="{extended}"></div>
</div>

<ng-template #dailyRef></ng-template>
