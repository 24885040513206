<div class="login-box m-auto">
  <div class="card-body">
    <form [formGroup]="loginForm" class="form-horizontal form-material animated fadeInDown" id="loginform" (ngSubmit)="login(loginForm)">

      <a href="/" class="text-center db">

        <!-- <img
      src="../../../assets/images/logo-icon.png"
      alt="Home" /><br /><img
      src="../../../assets/images/logo-text.png"
      alt="Home"/> -->
        <img [src]="iconPath" alt="logo" class="img-logo-auth">
      </a>

      <div class="form-group m-t-40">
        <div class="col-xs-12">
          <input formControlName="correo" class="form-control px-2" emailValidation type="email" placeholder="Correo electronico" [class.is-invalid]="isCampoInvalidoLogin('correo')" />
          <span class="text-danger" *ngIf="isCampoInvalidoLogin('correo')">
            El correo electronico es Requerido y debe ser un correo valido
          </span>

        </div>
      </div>

      <div class="col-xs-12">
        <app-password-input [placeholder]="'Contraseña'" [classDiv]="'col-xs-12'" [classInput]="'form-control'" (inputPassword)="inputPasswordLogin('contrasena',$event)">
        </app-password-input>
        <span class="text-danger" *ngIf="isCampoInvalidoLogin('contrasena')">
          Las contraseña es obligatoria
        </span>
      </div>

      <div class="form-group row mt-4">
        <div class="col-md-12">
          <div class="checkbox checkbox-primary pull-left p-t-0">
            <input id="checkbox-signup" type="checkbox" class="filled-in chk-col-light-blue" />
            <label for="checkbox-signup"> Recuérdame </label>
          </div>
          <a href="javascript:void(0)" id="to-recover" class="text_defaultnm selector pull-right" (click)="router.navigate(['/auth/forgot-password'])"><i class="fa fa-lock m-r-5"></i> Olvidé mi contraseña?</a>
        </div>
      </div>

      <div class="form-group text-center m-t-20">
        <div class="col-xs-12">

        <custom-buttons

          text="Iniciar sesion"
          title="Permite loguearte"
          textColor="#fff"
          icon="fa fa-sign-in"
          width="250px"
          type="submit"
        >
        </custom-buttons>

        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 m-t-10 text-center">

          <hr>
          <p class="text-center">O Puedes Iniciar sesión con Google</p>
          <div class="social">
            <asl-google-signin-button type='icon' size='large'></asl-google-signin-button>
            <!-- <a
              href="javascript:void(0)"
              class="btn btn-googleplus"
              data-toggle="tooltip"
              title="Login with Google"
            >
              <i aria-hidden="true" class="fa fa-google-plus"></i>
            </a> -->
          </div>
        </div>
      </div>
      <div class="form-group m-b-0 mt-2">
        <div class="col-sm-12 text-center">
          ¿Aún No tienes una cuenta?
          <br />
          <a (click)="router.navigate(['/auth/register'])" class=" selector"><b class="text_defaultnm">Registrarme Ahora</b></a>
        </div>
      </div>
    </form>

  </div>
</div>
