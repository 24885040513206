import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ApplicationService } from '@services';

export interface PageItem {
  page: number;
  selected: boolean;
}

@Component({
  selector: 'app-paginator-custom',
  templateUrl: './paginator-custom.component.html',
  styleUrls: ['./paginator-custom.component.scss'],
})
export class PaginatorCustomComponent implements OnInit, OnChanges {
  @Input() dataList: any[] = [];
  @Input() itemName = 'Items';
  @Input() sortColumn: any;
  @Input() numItems?: number;
  @Input() numOptions = [5, 10, 20, 50];
  @Input() paginator_select: boolean = true; // muestra por defecto el div paginator_select
  @Input() paginator_col: boolean = true; // muestra por defecto el div paginator_col
  @Input() justifyContent: string = 'space-between';

  @Output() pageChange = new EventEmitter<any[]>();

  @Input() scrollAnchorId!: string;

  @ViewChild('currentPageButton', { static: false }) currentPageButton!: ElementRef;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isSmallScreen = window.innerWidth <= 768;
  }

  itemsPage = this.numOptions[0];
  startIndex = 0;
  endIndex = 0;
  pagesButtons: PageItem[] = [];

  pages: PageItem[] = [{ page: 1, selected: true }];

  lastDirection: 'next' | 'previous' = 'next';

  isSmallScreen: boolean = false;

  constructor(private applicationService: ApplicationService) {}

  ngOnInit(): void {
    this.initiValues();
    this.isSmallScreen = window.innerWidth <= 768;
    // console.log(this.isSmallScreen);
  }

  initiValues() {
    if (this.numItems && !this.numOptions.includes(this.numItems)) {
      this.numOptions.push(this.numItems);
      this.numOptions = this.numOptions.sort((a, b) => a - b);
      this.itemsPage = this.numItems;
    }
    this.pages = this.inicializarItemsPage(this.dataList);
    this.pagesButtons = [...this.getVisiblePages()];
    this.emitCurrentPageList();
  }

  ngOnChanges(changes: SimpleChanges) {
    const dataListChange = changes['dataList'];
    const sortColumn = changes['sortColumn'];

    if (dataListChange && dataListChange.currentValue) {
      this.initiValues();
    }

    if (sortColumn && sortColumn.currentValue) {
      const newValue = sortColumn.currentValue;

      if (newValue.order === 'ASC') {
        this.dataList = this.applicationService.orderByASC(this.dataList, newValue.column);
        this.pages = this.inicializarItemsPage(this.dataList);
        this.pagesButtons = [...this.getVisiblePages()];
        this.emitCurrentPageList();
      } else {
        this.dataList = this.applicationService.orderByDESC(this.dataList, newValue.column);
        this.pages = this.inicializarItemsPage(this.dataList);
        this.pagesButtons = [...this.getVisiblePages()];
        this.emitCurrentPageList();
      }
    }
  }

  itemsPorPage(event: any) {
    this.itemsPage = Number(event.target.value);
    this.pages = this.inicializarItemsPage(this.dataList);
    this.pagesButtons = [...this.getVisiblePages()];
    this.emitCurrentPageList();
  }

  getVisiblePages(): PageItem[] {
    const currentIndex = this.pages.findIndex((page) => page.selected);

    if (currentIndex === -1) {
      return [];
    }

    const visiblePages: PageItem[] = [];
    const totalPages = this.pages.length;

    // Agrega la página actual y hasta 4 páginas hacia atrás y hacia adelante
    for (let i = currentIndex - 2; i <= currentIndex + 2; i++) {
      if (i >= 0 && i < totalPages) {
        visiblePages.push(this.pages[i]);
      }
    }

    // Agrega puntos suspensivos si es necesario
    if (visiblePages.length > 0) {
      if (visiblePages[0].page > 1) {
        visiblePages.unshift({ page: -1, selected: false });
      }
      if (visiblePages[visiblePages.length - 1].page < totalPages) {
        visiblePages.push({ page: -1, selected: false });
      }
    }

    return visiblePages;
  }

  inicializarItemsPage(data: any[]): PageItem[] {
    const pages: PageItem[] = [];

    for (let i = 0; i < data.length; i += this.itemsPage) {
      const isSelected = i === 0;

      const pageItem: PageItem = {
        page: pages.length + 1,
        selected: isSelected,
      };

      pages.push(pageItem);
    }

    return pages;
  }

  setItem(page: PageItem) {
    this.pages.forEach((item) => {
      item.selected = false;
    });
    page.selected = true;

    // Emite la lista correspondiente al cambiar la página
    this.pagesButtons = [...this.getVisiblePages()];
    this.emitCurrentPageList();
    this.scrollToAnchor();
  }

  nextItem() {
    const currentIndex = this.pages.findIndex((page) => page.selected);

    if (currentIndex !== -1) {
      if (currentIndex < this.pages.length - 3) {
        // Avanzar a la página siguiente si no estamos cerca de los puntos suspensivos
        this.setPage(currentIndex + 1);
      } else if (currentIndex === this.pages.length - 3) {
        // Mostrar los puntos suspensivos como seleccionados
        this.setPage(currentIndex + 1);
      } else if (currentIndex === this.pages.length - 2) {
        // Avanzar a la última página si estamos en los puntos suspensivos
        this.setPage(currentIndex + 1);
      }
    }
  }

  previousItem() {
    const currentIndex = this.pages.findIndex((page) => page.selected);

    if (currentIndex !== -1) {
      if (currentIndex === 0) {
        // Si estamos en la primera página, no hacemos nada
        return;
      } else if (currentIndex === 1) {
        // Retroceder a la página anterior si estamos en la segunda página
        this.setPage(currentIndex - 1);
      } else if (currentIndex > 1 && currentIndex < this.pages.length - 2) {
        // Retroceder a la página anterior si no estamos cerca de los puntos suspensivos
        this.setPage(currentIndex - 1);
      } else if (currentIndex === this.pages.length - 2) {
        // Retroceder a los puntos suspensivos si estamos en la última página
        this.setPage(currentIndex - 1);
      } else if (currentIndex === this.pages.length - 1) {
        // Retroceder a la página anterior si estamos en los puntos suspensivos
        this.setPage(currentIndex - 1);
      }
    }
  }

  private setPage(index: number) {
    this.pages.forEach((item) => (item.selected = false));
    this.pages[index].selected = true;
    this.pagesButtons = [...this.getVisiblePages()];
    this.emitCurrentPageList();
    this.scrollToAnchor();
  }

  private emitCurrentPageList() {
    // Obtén la página actualmente seleccionada
    const selectedPage = this.pages.find((page) => page.selected);

    // Calcula el índice de la página
    const pageIndex = selectedPage ? selectedPage.page - 1 : 0;

    // Calcula el rango de elementos a mostrar en la página actual
    this.startIndex = pageIndex * this.itemsPage;
    this.endIndex = this.startIndex + this.itemsPage;

    // Filtra la lista de datos para obtener los elementos de la página actual
    const currentPageList = this.dataList.slice(this.startIndex, this.endIndex);

    // Emite la lista correspondiente a la página actual
    this.pageChange.emit(currentPageList);
  }

  private scrollToAnchor(): void {
    if (this.scrollAnchorId) {
      const element = document.getElementById(this.scrollAnchorId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
}
