<span class="input__group">
  <div class="box__label">
    <label class="input__label" for="input_component">{{label}}<strong *ngIf="required"> *</strong></label>
    <i class="fa fa-question icon__helper" [title]="helper" *ngIf="helper"></i>
  </div>
  <div class="box__input">
    <div class="checks__container row" [ngClass]="{invalid: control.touched && control.invalid, bordered}">
      <span class="check__input" [ngClass]="colClass" *ngFor="let itemCheck of data">
        <input type="checkbox" class="input__form__checkbox" [id]="itemCheck.value+'chk'" [value]="itemCheck.value" (change)="setChecked($event, itemCheck)" />
        <label [for]="itemCheck.value+'chk'" class="check__label">{{ itemCheck.label }}</label>
        <div *ngIf="itemCheck.description" class="check__description">
          {{ itemCheck.description }}
        </div>
        <div *ngIf="isCheckedMap[itemCheck.value]">
          <div *ngIf="itemCheck.hasRange" class="dual-range-container">
            <div class="range-track"></div>
            <input type="range" [(ngModel)]="rangeValues[itemCheck.value].min" (input)="onRangeInput(itemCheck.value)"
              class="range-slider" [min]="itemCheck.minRange || 0" [max]="itemCheck.maxRange || 120">
            <input type="range" [(ngModel)]="rangeValues[itemCheck.value].max" (input)="onRangeInput(itemCheck.value)"
              class="range-slider" [min]="itemCheck.minRange || 0" [max]="itemCheck.maxRange || 120">
          </div>

          <div *ngIf="itemCheck.hasRange" class="range-view">
            <input type="number" [value]="rangeValues[itemCheck.value].min" (input)="handleMinInput($event, itemCheck)"
              class="range-min-input" [min]="itemCheck.minRange ?? 0" [max]="rangeValues[itemCheck.value].max" />

            <input type="number" [value]="rangeValues[itemCheck.value].max" (input)="handleMaxInput($event, itemCheck)"
              class="range-max-input" [min]="rangeValues[itemCheck.value].min" [max]="itemCheck.maxRange ?? 120" />
          </div>
        </div>
      </span>
    </div>
  </div>
  <div class="box__error" [ngClass]="{visible:errors.length}">
    <ul>
      <li class="box__error__item" *ngFor="let error of errors"><strong>* </strong>{{error}}</li>
    </ul>
  </div>
</span>
