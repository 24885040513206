import { Component } from '@angular/core';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent {

  // Array de Links Header
  links = [
    { name: 'Inicio' ,
      targetId: 'home'
    },
    { name: 'Descarga',
      targetId:'app' 
    },
    { name: 'FAQ',
    targetId:'faq' 
  }
]

  volverArriba() {
    let posicion = window.pageYOffset;
    const intervalId = setInterval(() => {
      window.scrollTo({ top: posicion, behavior: 'auto' });
      if (posicion > 0) {
        posicion -= 40; // ajusta este número para cambiar la velocidad de la animación
      } else {
        clearInterval(intervalId);
      }
    }, 16); // este número está relacionado con la frecuencia de actualización de los navegadores (60Hz = 16.67ms por fotograma)
  }

}
