import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';
import { reloadState, selectRol } from 'src/app/pages/session/state/session.actions';
import { loadBranches, selectBranch, setBranches } from './branches.actions';
import { CompanyService } from 'src/app/roles/services/company.service';
import { ApiRes } from '@interfaces/information.interface';
import { SweetAlertService } from 'src/app/services/sweet-alert.service';

@Injectable()
export class BranchesEffects {

    constructor(private actions$: Actions, private companyService: CompanyService, private swalService: SweetAlertService) {}

    loadBranches$ = createEffect(() => this.actions$.pipe(
        ofType(selectRol, reloadState),
        mergeMap((action) => {

            if (action.rolSession.rol === 'provider') {
                // Aquí se retorna la acción loadBranches
                return [ loadBranches({ companyId: action.rolSession._idData } )];
            } else {
                return []; // No se retorna ninguna acción si no es 'provider'
            }
        })
    ));

    loadBranchesEffect$ = createEffect(() => this.actions$.pipe(
        ofType(loadBranches),
        mergeMap((action) => 
            this.companyService.getActviveBranches(action.companyId)
                .pipe( 
                    map((response: ApiRes) => { 

                        localStorage.removeItem('branch');

                        if (response.ok && response?.body && response.body.branches) {
                            const branches = response.body.branches;

                            return setBranches({ branches });
                        } else {
                            // Emitir una alerta si la respuesta no es válida
                            this.swalService.lauchSwal('', 'Error al cargar las sucursales. Por favor, intente de nuevo o ponte en contacto con el administrador. ', 'warning');
                            // Opcionalmente, podrías retornar una acción de error aquí
                            return { type: '[Branches] Load Error' }; 
                        }
                    })
                )
        )
    ));

    selectBranchEffect$ = createEffect(() => this.actions$.pipe(
        ofType(setBranches),
        mergeMap((action) => {

            const branches = action.branches;
            const branchId = localStorage.getItem('branch');

            let branchSelected; 

            if(branchId) {
                branchSelected = branches.find(branch => branch.branchId === branchId );
            } else {
                branchSelected = branches[0];
            } 

            return [selectBranch({ branchSelected: branchSelected! })]
        })
    ));  
    
}