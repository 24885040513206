import { createAction, props } from '@ngrx/store';

export const storeAppointmentDetails = createAction(
  '[Appointment] Store Details',
  props<{ serviceDetails: any; municipalityId: string, pending: boolean, login: boolean }>()
);

export const reloadAppointmentDetailsFromStorage = createAction(
  '[Appointment] Reload Details From Storage',
  props<{ serviceDetails: any; municipalityId: string, pending: boolean, login: boolean }>()
);


export const clearAppointmentDetails = createAction('[Appointment] Clear Details');