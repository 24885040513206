
import { createReducer, on } from '@ngrx/store';
import { storeAppointmentDetails, clearAppointmentDetails, reloadAppointmentDetailsFromStorage } from './appointment.actions';
import { AppointmentState } from '@interfaces/information.interface'; 


export const initialState: AppointmentState = {
  serviceDetails: null,
  municipalityId: '',
  pending: false,
  login: false
  
};


export const appointmentReducer = createReducer(
  initialState,
  on(storeAppointmentDetails, (state, { serviceDetails, municipalityId, pending, login }) => {
    const newState = {
      ...state,
      serviceDetails,
      municipalityId,
      pending,
      login
    };
    localStorage.setItem('appointmentDetails', JSON.stringify(newState));
    return newState;
  }),
  on(clearAppointmentDetails, () => initialState),
  on(reloadAppointmentDetailsFromStorage, (state, { serviceDetails, municipalityId, pending, login }) => ({
    ...state,
    serviceDetails,
    municipalityId,
    pending,
    login
  }))
);



export { AppointmentState };




