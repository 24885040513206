import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { getToken } from '../auth/state/auth.selectors';

@Injectable({
  providedIn: 'root'
})
export class SelectorsNgrxService {

  constructor(private store: Store) { }

  getSelectorToken(){
    return this.store.select(getToken);
  }
}
