import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/local-storage.service';

export const isLoggedInGuard: CanActivateFn = () => {
    
  const localStorageService = inject(LocalStorageService);
  const router = inject(Router);
   
  const user = localStorageService.getItem('usdt');
  
  if(!user){
    router.navigate(['/auth/login']);
    return false;
  } else return true;

};