import { Component, Input } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'form-radios',
  templateUrl: './form-radios.component.html',
  styleUrls: ['../../form-styles.scss', './form-radios.component.scss']
})
export class FormRadiosComponent {
  @Input({ required: true }) controlName!: string;
  @Input({ required: true }) data: any[] = []
  @Input() placeholder: string = 'Default placeholder';
  @Input() helper: string | undefined;
  @Input() bordered: boolean = !false;
  @Input() required: boolean = true;
  @Input() label: string = '';

  errors: any[] = [];
  value: any;

  constructor(private controlContainer: ControlContainer) { }

  ngOnInit() {
    console.debug('here');
    if (this.control.value) {
      this.value = this.control.value
      this.control.updateValueAndValidity()
    }
  }

  public checkRadio(radio: any) {
    this.value = radio
    this.control.setValue(radio)
    console.debug('touched', this.control.touched);
    !this.control.touched && this.control.markAsTouched()
    console.debug('dirty', this.control.dirty);
    !this.control.dirty && this.control.markAsDirty()
  }

  public isChecked(radio: any) {
    return JSON.stringify(radio) === JSON.stringify(this.value)
  }

  get control(): FormControl {
    const parentFormGroup = this.controlContainer as FormGroupDirective;
    parentFormGroup.statusChanges?.subscribe(rs => {
      this.errors = [];
      if (!this.control.errors || !this.control.touched) return
      this.control.errors['required'] && this.errors.push(`El campo es requerido`)
    })
    return parentFormGroup.control.get(this.controlName) as FormControl;
  }

  get controlInvalid(): boolean {
    return this.control.invalid || false;
  }

  get errorKeys(): string[] {
    return this.control.errors ? Object.keys(this.control.errors) : [];
  }
}