import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AddressSettings } from '@interfaces/information.interface';

interface City {
  center: google.maps.LatLngLiteral;
}

interface PlaceInit {
  prefix: string;
  lat: number;
  lng: number;
  zoom: number;
}

@Component({
  selector: 'map-search',
  templateUrl: './map-search.component.html',
  styleUrls: ['./map-search.component.scss'],
})
export class MapSearchComponent implements OnInit, OnChanges {
  @Input() showMap: boolean = true;
  @Input() placeholder: string = 'Introduce una direccion';
  @Input() placePrefix: string = '';
  @Input() idInput: string = 'idInputPlace';
  @Input() inputRadius: number = 0;
  @Input() coordinates?: number[]; // Pasto default coordinates: lat:1.21361, lng:-77.28111
  @Input() dataPlaceInit?: AddressSettings;
  @Input() dataPlaceChange?: AddressSettings;
  @Input() mapHeight: string = '400px'; // Valor predeterminado de 400px
  @Input() borderRadiusInput: string = '0px '; // border radius del buscador
  @Input() borderRadiusMain: string = '0px '; // border radius del div Main
  
  
  


  @Output() placeEmmiter: EventEmitter<AddressSettings> =
    new EventEmitter<AddressSettings>();
  @Output() errorPlaceEmmiter: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('map', { static: false }) mapElement!: ElementRef;
  @ViewChild('inputSearch', { static: false }) inputSearchElement!: ElementRef;

  private initialMapProperties: any;
  private cityCircle: google.maps.Circle | undefined;
  private map: any | undefined;
  private marker: google.maps.Marker | undefined;
  private placesService!: google.maps.places.PlacesService | undefined;

  constructor() {
    setTimeout(() => {
      if (this.coordinates) {
        this.inputSearchElement.nativeElement.value = this.getPlacePrefix(
          this.placePrefix
        );
        this.initialMapProperties = {
          center: { lat: this.coordinates[0], lng: this.coordinates[1] },
          mapTypeControl: false,
          zoom: 14,
        };
      } else if (this.dataPlaceInit && this.dataPlaceInit.location) {
        this.inputSearchElement.nativeElement.value =
          this.dataPlaceInit.address;
        const coordinates = this.dataPlaceInit!.location.coordinates;
        this.initialMapProperties = {
          center: { lat: coordinates[0], lng: coordinates[1] },
          mapTypeControl: false,
          zoom: 16,
        };
      }
    }, 100);
  }
  ngOnInit(): void {
    this.inputRadius = this.inputRadius * 1000;
    
  }

  
  // TODO: no esta funcionando como quiero pero me coloca el valor en el input, modificar la busqueda
  
 /*
  updateSearch(newPrefix: string) {
    // Actualiza la lógica de búsqueda aquí basada en el nuevo valor de placePrefix
    // Por ejemplo, podrías querer establecer el valor del input y luego simular un evento de búsqueda
    if (this.inputSearchElement) {
      this.inputSearchElement.nativeElement.value = this.getPlacePrefix(newPrefix);
      // Aquí puedes agregar cualquier lógica adicional para realizar la búsqueda en el mapa
      // basada en el nuevo valor de placePrefix.
    }
  }
*/
  ngOnChanges(changes: SimpleChanges) {
// TODO:  no esta funcionando como quiero pero me coloca el valor en el input, modificar la busqueda

// ========================================== //
 /*   if (changes['placePrefix'] && !changes['placePrefix'].firstChange) {
      this.updateSearch(changes['placePrefix'].currentValue);
    }
 */
// ========================================== // 

    if (changes['inputRadius'] && !changes['inputRadius'].firstChange) {
      this.inputRadius = this.inputRadius * 1000;
      this.onInputRadiusChange();
    }
    if (changes['dataPlaceChange'] && !changes['dataPlaceChange'].firstChange) {
       //console.log(this.dataPlaceChange);
      const latLng = {
        lat: this.dataPlaceChange!.location?.coordinates[0],
        lng: this.dataPlaceChange!.location?.coordinates[1],
      };
      this.map.setCenter(latLng);
      this.map.setZoom(13);
      const prefix = this.getPlacePrefix(this.dataPlaceChange?.address || '');
      this.inputSearchElement.nativeElement.value = prefix;
      this.placePrefix = prefix;
      this.initialMapProperties = {
        center: latLng,
        mapTypeControl: false,
        zoom: 13,
      };
    }
  }

  ngAfterViewInit() {
   
    this.inicializarMapa();
    
  }

 
  

  onInputRadiusChange(): void {
    // Verificar si hay un círculo en el mapa
    if (this.cityCircle) {
      // Actualizar el radio del círculo
      this.cityCircle.setRadius(this.inputRadius);
    }
  }

  inicializarMapa() {
    if (this.inputSearchElement) {
      setTimeout(() => {
        let mapElement = this.mapElement.nativeElement;
        let inputElement = this.inputSearchElement.nativeElement;
        this.initMap(mapElement, inputElement);
      }, 100);
    }
  }



  onInputChange(e: any) {
    const nValue = e.target.value;
    if (nValue.length < this.placePrefix.length) {
      this.inputSearchElement.nativeElement.value = this.getPlacePrefix(
        this.placePrefix
      );
    }
  }
  clearInput() {
    this.inputSearchElement.nativeElement.value = this.getPlacePrefix(
      this.placePrefix
    );
    this.marker?.setVisible(false);
    this.cityCircle?.setVisible(false);
    this.dataPlaceInit = undefined;
    //console.log(this.coordinates);
    
    this.placeEmmiter.emit(undefined);
  }

  getPlacePrefix(placePrefix: string): string {

    const nPrefix = placePrefix?.replace(/\s+/g, ' ').trim() + ' '; // Eliminar espacios adicionales y recortar los espacios al principio y al final
    if (nPrefix == ' ') {
      return '';
    } else if (nPrefix.includes('-')) {
      if (nPrefix.startsWith('Colombia')) {
        return nPrefix.replace(' -', ',');
      } else {
        return 'Colombia, ' + nPrefix.replace(' -', ',');
      }
    } else {
      if (nPrefix.startsWith('Colombia')) {
        return nPrefix;
      } else {
        return 'Colombia, ' + nPrefix;
      }
    }
  }

  getAddressSettings(place: any): AddressSettings {
    return {
      address: place.name || '',
      addressDirections: place.formatted_address || '',
      country:
        this.obtenerValorPorTipo(place.address_components, 'country')
          ?.long_name || '',
      country_code:
        this.obtenerValorPorTipo(place.address_components, 'postal_code')
          ?.long_name || '',
      location: {
        coordinates: [
          place.geometry.location.lat(),
          place.geometry.location.lng(),
        ],
      },
    };
  }

  getAddressSettingsClick(place: any): AddressSettings {
    return {
      address: place.formatted_address || '',
      addressDirections: place.formatted_address || '',
      country:
        this.obtenerValorPorTipo(place.address_components, 'country')
          ?.long_name || '',
      country_code:
        this.obtenerValorPorTipo(place.address_components, 'postal_code')
          ?.long_name || '',
      location: {
        coordinates: [
          place.geometry.location.lat(),
          place.geometry.location.lng(),
        ],
      },
    };
  }

  obtenerValorPorTipo(objeto: any[], tipoBuscado: string) {
    // Iterar sobre el arreglo de objetos
    for (let i = 0; i < objeto.length; i++) {
      // Verificar si el tipo buscado está presente en el arreglo de tipos del objeto actual
      if (objeto[i].types.includes(tipoBuscado)) {
        // Si coincide, retornar el objeto actual
        return objeto[i];
      }
    }
    // Si no se encuentra el tipo buscado, retornar null o un mensaje de error, según prefieras
    return null;
  }

  initMap(htmlElementMap: HTMLElement, inputElement: HTMLInputElement): void {
    this.map = new google.maps.Map(htmlElementMap, this.initialMapProperties);
    const autocomplete = new google.maps.places.Autocomplete(inputElement);

    const biasInputElement = document.getElementById(
      'use-location-bias'
    ) as HTMLInputElement;
    const strictBoundsInputElement = document.getElementById(
      'use-strict-bounds'
    ) as HTMLInputElement;

    autocomplete.bindTo('bounds', this.map);

    const infowindow = new google.maps.InfoWindow();
    const infowindowContent: any = document.getElementById(
      'infowindow-content'
    ) as HTMLElement;

    infowindow.setContent(infowindowContent);

    this.marker = new google.maps.Marker({
      map: this.map,
      anchorPoint: new google.maps.Point(0, -29),
    });

    if (this.dataPlaceInit && this.dataPlaceInit.location) {
      const coordinates = this.dataPlaceInit!.location.coordinates;
      this.marker?.setPosition({ lat: coordinates[0], lng: coordinates[1] });
      this.marker?.setVisible(true);
      this.cityCircle?.setVisible(true);
    }

    autocomplete.addListener('place_changed', () => {
      infowindow.close();
      this.marker?.setVisible(false);

      const place = autocomplete.getPlace();

      if (!place.geometry || !place.geometry.location) {
        this.errorPlaceEmmiter.emit({ noSearch: true });
        return;
      }

      

      this.placeEmmiter.emit(this.getAddressSettings(place));

      if (place.geometry.viewport) {
        this.map.fitBounds(place.geometry.viewport);
      } else {
        this.map.setCenter(place.geometry.location);
        this.map.setZoom(17);
      }

      this.map.setZoom(15);
      this.marker?.setPosition(place.geometry.location);
      this.marker?.setVisible(true);
      this.cityCircle?.setVisible(true);
      this.manejarCirculo(place.geometry.location);
    });

    function setupClickListener(id: any, types: any) {
      const radioButton = document.getElementById(id) as HTMLInputElement;
    }

    // setupClickListener('changetype-all', []);
    // setupClickListener('changetype-address', ['address']);
    // setupClickListener('changetype-establishment', ['establishment']);
    // setupClickListener('changetype-geocode', ['geocode']);
    // setupClickListener('changetype-cities', ['(cities)']);
    // setupClickListener('changetype-regions', ['(regions)']);

    this.map.addListener('click', (event: google.maps.MapMouseEvent) => {
      const clickedLatLng = event.latLng?.toJSON();
      if (clickedLatLng) {
        this.reverseGeocodeLatLng(clickedLatLng, (addressSettings) => {
          if (addressSettings) {
            this.inputSearchElement.nativeElement.value =
              addressSettings.address;
            if (event.latLng) {
              this.manejarCirculo(event.latLng);
            }
            // //console.log(addressSettings);
            this.placeEmmiter.emit(addressSettings);
          } else {
            console.error('No se pudieron obtener los datos de la dirección.');
          }
        });
      }
    });
  }

  manejarCirculo(latLng: google.maps.LatLng) {
    // this.map.setZoom(15);
    this.map.setCenter(latLng);
    this.map.panTo(latLng, { animate: true });
    this.marker?.setPosition(latLng);
    this.marker?.setVisible(true);
    this.cityCircle?.setVisible(true);

    if (this.cityCircle) {
      this.cityCircle.setValues({
        center: { lat: latLng.lat(), lng: latLng.lng() },
        radius: this.inputRadius,
      });
    } else {
      this.cityCircle = new google.maps.Circle({
        strokeColor: '#00AB78',
        strokeOpacity: 0.6,
        strokeWeight: 2,
        fillColor: '#90f6d7',
        fillOpacity: 0.2,
        map: this.map,
        center: { lat: latLng.lat(), lng: latLng.lng() },
        radius: this.inputRadius,
      });

      this.cityCircle.addListener(
        'click',
        (event: google.maps.MapMouseEvent, circle = this.cityCircle) => {
          const clickedLatLng = event.latLng?.toJSON();
          if (clickedLatLng) {
            this.reverseGeocodeLatLng(clickedLatLng, (addressSettings) => {
              if (addressSettings) {
                this.inputSearchElement.nativeElement.value =
                  addressSettings.address;

                // this.map.setZoom(15);
                // this.map.setCenter(latLng);
                this.map.panTo(event.latLng, { animate: true });
                this.marker?.setPosition(event.latLng);
                this.marker?.setVisible(true);
                this.cityCircle?.setVisible(true);

                circle &&
                  circle.setValues({
                    center: {
                      lat: addressSettings.location?.coordinates[0],
                      lng: addressSettings.location?.coordinates[1],
                    },
                    radius: this.inputRadius,
                  });
                this.placeEmmiter.emit(addressSettings);
              } else {
                // Maneja la falta de resultados o errores
                console.error(
                  'No se pudieron obtener los datos de la dirección.'
                );
              }
            });
          }
        }
      );
    }
  }

  reverseGeocodeLatLng(
    latlng: google.maps.LatLngLiteral,
    callback: (addressSettings: AddressSettings | null) => void
  ) {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results && results[0]) {
          const addressSettings = this.getAddressSettingsClick(results[0]);
          callback(addressSettings);
        } else {
          console.error(
            'No se encontraron resultados de geocodificación inversa.'
          );
          callback(null);
        }
      } else {
        console.error('Error en la geocodificación inversa:', status);
        callback(null);
      }
    });
  }
}
