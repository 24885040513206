import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { loginSuccess } from '../../../auth/state/auth.actions';
import { initSession } from './session.actions';


@Injectable()
export class SessionEffects {

  initSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginSuccess),
      map(({ body } ) => {
        return initSession({ sessionData: body })
      })
    )
  );

  constructor (private actions$: Actions) {}
}
