import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FormAutocompleteComponent } from './components/form-autocomplete/form-autocomplete.component';
import { FormTextareaComponent } from './components/form-textarea/form-textarea.component';
import { FormSelectComponent } from './components/form-select/form-select.component';
import { FormInputComponent } from './components/form-input/form-input.component';
import { FormPillsComponent } from './components/form-pills/form-pills.component';
import { FormSectionComponent } from './components/form-section/form-section.component';
import { FormDateComponent } from './components/form-date/form-date.component';
import { FormChecksComponent } from './components/form-checks/form-checks.component';
import { FormRadiosComponent } from './components/form-radios/form-radios.component';
import { FormPasswordComponent } from './components/form-password/form-password.component';
import { FormEmailComponent } from './components/form-email/form-email.component';
import { FormPhoneComponent } from './components/form-phone/form-phone.component';

import { FormDocumentComponent } from './components/form-document/form-document.component';
import { FormMultiselectComponent } from './components/form-multiselect/form-multiselect.component';
import { FormInputSymbolComponent } from './components/form-input-symbol/form-input-symbol.component';

import { DocumentMaskPipe } from './pipes/document-mask.pipe';
import { DataObjectPipe, PhoneMaskPipe } from '@formPipes';
import { Loader } from '@googlemaps/js-api-loader';
import { FormMultiTextareaComponent } from './components/form-multi-textarea/form-multi-textarea.component';

const componentList = [
  FormAutocompleteComponent,
  FormChecksComponent,
  FormDateComponent,
  FormEmailComponent,
  FormInputComponent,
  FormMultiselectComponent,
  FormPasswordComponent,
  FormPillsComponent,
  FormRadiosComponent,
  FormSelectComponent,
  FormTextareaComponent,
  FormSectionComponent,
  FormPhoneComponent,
  FormDocumentComponent,
  FormInputSymbolComponent,
  FormMultiTextareaComponent
];

const pipeList = [
  DocumentMaskPipe,
  DataObjectPipe,
  PhoneMaskPipe
]

@NgModule({
  declarations: [...componentList, ...pipeList, DocumentMaskPipe],
  exports: [...componentList],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: 'AIzaSyCS4whU4i8bVv01nqk1LZr48ABXCEkv2p8',
        libraries: ['places']
      })
    }
  ]
})
export class CustomFormModule { }
