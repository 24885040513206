import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import {tap } from 'rxjs/operators';
import { ModalService } from '@services';
import { closeModal } from './modal.actions';

@Injectable()
export class ModalEffects {

  closeModal$ = createEffect(() =>
  this.actions$.pipe(
    ofType(closeModal),
    tap(() => {
      this.modalService.closeModal(); // Llama al método de cierre del modal
    })
   ), 
   { dispatch: false } 
  );

  constructor(
    private actions$: Actions,
    private modalService: ModalService
  ) {}
}
