import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { logout } from '../../auth/state/auth.actions';
import { Role } from 'src/app/interfaces/auth.interface';
// import { getRolSelect } from 'src/app/pages/session/state/session.selectors';
import { ApplicationService, SocketsService } from '@services';
import { Subscription } from 'rxjs/internal/Subscription';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

const production = environment.production;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() rolSelect: Role = {
    rol: '',
    name: '',
    _idData: '',
    avatar: '',
  };

  iconPath= production ? '../../../../../assets/images/logo-light-icon.png':'../../../../../assets/images/logo-light-icon-dev.png';

  private notificationsSubscription!: Subscription;

  constructor(private store: Store,
              private socket: SocketsService,
              private appService: ApplicationService,
              private router: Router) {}

  ngOnInit(): void {
    this.socket.connect();
    // this.getRole();
  }
  
  ngOnDestroy(): void {
    if (this.notificationsSubscription) {
      this.notificationsSubscription.unsubscribe();
    }

    // if (this.errorSubscription) {
    //   this.errorSubscription.unsubscribe();
    // }

    this.socket.disconnect();
  }

  logout() {
    this.store.dispatch(logout());
  }

  getRole() {

    // user doctor branch
    // this.notificationsSubscription = this.store.select(getRolSelect).subscribe( (role: Role) => {
    //   //console.log(role);

    //   const { _idData, rol } = role;
    //   this.socket.emit('joinInboxRoom', { referenceId: _idData, referenceType: rol });
    //   this.getInboxNotifications();
    // });
  }

  getInboxNotifications() {
    this.notificationsSubscription = this.socket.getEvent('getInbox').subscribe( (inboxNotifications: any) => {
      //console.log('aquiii toy', inboxNotifications);
    });
  }

  viewProfile() {

    const route = this.appService.redirection('profile');
    if(!route) this.logout();

    this.router.navigate([route]);
  }

}
