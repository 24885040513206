<div class="modal-content fadeOut" [ngClass]="options.modalClassContent">
  <div class="card_modal_header" *ngIf="options.header">
    <div class="fz_20 fw_500" id="myLargeModalLabel">{{ options.tittle }}</div>

    <button type="button" id="idModalClose" class="btn btn-circle btn_back" title="Cerrar" (click)="closeModal()"><i
        class="ti ti-close"></i> </button>

    <!-- <custom-buttons type="button" id="idModalClose" class="btn btn-circle btn_back animated fadeIn" [title]="'Cerrar'"
      textColor="#fff" (click)="closeModal()" icon="ti ti-close">
      <i class="ti ti-close"></i>
    </custom-buttons> -->
  </div>
  <div id="idModalReusable" class="modal-body">
    <ng-template appModalAnchor></ng-template>
  </div>

  <div class="modal-footer" *ngIf="options.footer">

  </div>
</div>
