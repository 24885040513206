import { createReducer, on } from '@ngrx/store';
import { login, loginSuccess, loginFailure, regenerateAuthState,logout } from './auth.actions';
import { LocalStorageService } from '../../services/local-storage.service';
import { EncryptService } from '../../services/encrypt.service';
import { ApplicationService } from '../../services/application.service';

const encryptService = new EncryptService();
const localStorageService = new LocalStorageService();
const applicationService = new ApplicationService();

export const authStateFeatureKey = 'authState';
// export const initialAuthState: {
//   user: User | null;
//   error: string | null;
//   isLoading: boolean;
// } = {
//     user: null,
//     error: null,
//     isLoading: false
// };

export interface AuthState {
  user: string | null;
  error: string | null;
  isLoading: boolean | null;
  loggedIn: boolean | null;
  userData:any | null;
  token: string | null
}

export const initialAuthState: AuthState = {
  user: null,
  error: null,
  isLoading: false,
  loggedIn: false,
  userData: null,
  token: null
};

export const authReducer = createReducer(
  initialAuthState,
  on(logout,(state) => {
    localStorageService.clear();
    return {
      ...state,
      user: null,
      error: null,
      isLoading: false,
      loggedIn: false,
      userData: null,
      token: null
    };
  }),
  on(login, (state) => ({ ...state, isLoading: true })),
  on(loginSuccess, (state, { body }) => {

    // Despachar acción para establecer datos encriptados en el Local Storage
    const token = body['token']; 
    const anio = applicationService.getDate(); 
    const key = token + anio;

    const encryptedData = encryptService.manageActions({ action: 'symmetricEncryption', data: JSON.stringify(body), key });
    localStorageService.setItem('usdt', encryptedData);
    localStorageService.setItem('tkn', token);

    return {
      ...state,
      userData: body,
      error: null,
      isLoading: false,
      loggedIn: true,
      token: token
    };
  }),
  on(loginFailure, (state, { error }) => ({
      ...state,
      user: null,
      error,
      isLoading: false,
    }
  )),
  on(regenerateAuthState, (state, { body, token }) => ({
      ...state,
      userData: body,
      error: null,
      isLoading: false,
      loggedIn: true,
      token
    })
  )
);