import { Component, OnInit } from '@angular/core';
import { FamilyGroupsServiceService } from 'src/app/roles/services/family-groups-service.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Permissions } from '@interfaces/information.interface';
import { ApplicationService, InformationService, ModalService } from '@services';
import Swal from 'sweetalert2';
import { FamilyGroupService } from 'src/app/services/familyGroupAndBeneficiary.service';



@Component({
  selector: 'app-family-group-and-beneficiary',
  templateUrl: './family-group-and-beneficiary.component.html',
  styleUrls: ['./family-group-and-beneficiary.component.scss']
})
export class FamilyGroupAndBeneficiaryComponent  {

  

  // Array de los check de los permisos
  dataChecks: Array<any> = [
    { id: 1, label: 'Ver', value: 'view' },
    { id: 2, label: 'Crear', value: 'create' },
    { id: 3, label: 'Actualizar', value: 'update' },
    { id: 4, label: 'Eliminar', value: 'delete' },
    { id: 5, label: 'Pedir una cita', value: 'scheduleAppointment' },
  ];



  // Data del input form-select
  relationShips = [
    { value: "Spouse/Partner", name: "Esposo/a o Pareja" },
    { value: "Child", name: "Hijo/a" },
    { value: "Mother", name: "Madre" },
    { value: "Father", name: "Padre" },
    { value: "Sibling", name: "Hermano/a" },
    { value: "Grandparent", name: "Abuelo/a" },
    { value: "Grandchild", name: "Nieto/a" },
    { value: "Uncle/Aunt", name: "Tío/a" },
    { value: "Nephew/Niece", name: "Sobrino/a" },
    { value: "Cousin", name: "Primo/a" },
    { value: "Half-Brother/Half-Sister", name: "Medio hermano/a" },
    { value: "Stepbrother/Stepsister", name: "Hermanastro/a" },
    { value: "Stepfather/Stepmother", name: "Padrastro/Madrastra" },
    { value: "Brother-in-law/Sister-in-law", name: "Cuñado/a" },
    { value: "Son-in-law/Daughter-in-law", name: "Yerno/Nuera" },
    { value: "Father-in-law/Mother-in-law", name: "Suegro/a" },
    { value: "Great-grandparent", name: "Bisabuelo/a" },
    { value: "Great-grandchild", name: "Bisnieto/a" },
    { value: "Godson/Goddaughter", name: "Ahijado/a" },
    { value: "Godfather/Godmother", name: "Padrino/Madrina" }
  ]
  formfamilyGroup!: FormGroup;
  formBeneficiary!: FormGroup;
  familyGroupName: string = ''
  permissions: Permissions = {
    create: false,
    delete: false,
    update: false,
    view: false,
    scheduleAppointment: false
  }
  beneficiary: any = {
    correo: " ",
    numeroDocumento: " ",
    tipoCuenta: 'Beneficiary',
    tipoDocumento: " ",
    nombres: " ",
    apellidos: " ",
    fechaNacimiento: " ",
  }
  cellPhones!: any;
  key: any;
  ageBeneficiary = 0;
  userExist!: boolean | null;
  userInitial!: boolean | 'default';
  maxDate: any;
  minDate: any;
  


  constructor(private familyService: FamilyGroupsServiceService,
    private infoService: InformationService,
    public appService: ApplicationService,
    private modalService: ModalService,
    private familyGroupService:FamilyGroupService,
    
  ) { }


  ngOnInit(): void {
    this.maxDate = this.appService.dateOperations('subtract', 'years', 18);
    this.minDate = this.appService.dateOperations('subtract', 'years', 100);
    this.initializedFormFamilyGroup();
    this.initializedFormBeneficiary();
    //Obtener el id del user logeado del localStorage
    this.key = localStorage.getItem('session');
    const keyParser = JSON.parse(this.key);
    this.key = keyParser;
    ////console.log(this.key)
  }

  // Form para el nombre del grupo familiar
  initializedFormFamilyGroup() {
    // Variable estado del mensaje inicial
    this.userInitial = 'default';

    this.formfamilyGroup = new FormGroup({
      nameFamily: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(20)]),
      tipoDocumento: new FormControl('', [Validators.required]),
    });
  }

  // Form para los datos del beneficiario
  initializedFormBeneficiary() {
    this.formBeneficiary = new FormGroup({
      nombres: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(20)]),
      apellidos: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(20)]),
      fechaNacimiento: new FormControl('', [Validators.required]),
    //  tipoDocumento: new FormControl('', [Validators.required]),
    //  numeroDocumento: new FormControl('', [Validators.required, Validators.pattern(/\d{6,9}/)]),
      telefono: new FormControl('', [Validators.required, Validators.pattern(/^3\d{9,9}/)]),
      correo: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]),
      relationship: new FormControl<string | null>(null, Validators.required),
      checks: new FormControl(this.dataChecks),

    })



    //console.log(this.formBeneficiary.value);

    //Para calcular la edad en tiempo real
    this.formBeneficiary.get('fechaNacimiento')?.valueChanges.subscribe(value => {
      if (value) {
        this.ageBeneficiary = this.calculateAge(value);
        //console.log("La edad actual es:", this.ageBeneficiary);
      }
    });

    // Verifica  si tiene whatsapp  
    let telefono = this.formBeneficiary.get('telefono');
    telefono?.valueChanges.subscribe((number) => {

      if (telefono?.valid) this.infoService.verifyWhatsAppNumber(number).subscribe((existe: boolean) => {
        this.cellPhones = [{
          phone: number,
          llamadas: true,
          whatsapp: existe,
          prefix: '+57',
          confirmadoWsp: 0,
          confirmadoLLamadas: 0
        }]
      })

    })

  }

  //  Validar el form de datos y numero documento
  validateNumId(){
    
    if(this.formfamilyGroup.invalid){
      console.error('error en el form familygroup')
      this.formfamilyGroup.markAllAsTouched();
    }
    if(this.formfamilyGroup.valid  ){
      let { tipoDocumento: { document, type } } = this.formfamilyGroup.value
      //console.log(document);
      // Hace la verificacion si esta registrado o no el beneficiario
      this.verifyDocumenttwo(document);
    }
  }  

  // Convertir el array de los checks en objeto
  convertArrayToObject(dataChecks: Array<any>, dataChecksList: Array<any>): any {
    let output: any = {};

    // Iterar
    for (let item of dataChecksList) {
      // Establecer en true si el valor está en dataChecks, de lo contrario en false
      output[item.value] = dataChecks.some(check => check.value === item.value);
    }

    return output;
  }

  // Función para calcular la edad sin usar librerías externas
  calculateAge(birthdateValue: string): any {
    if (!birthdateValue) {
      return null;
    }
    const birthdate = new Date(birthdateValue);
    const today = new Date();
    let age = today.getFullYear() - birthdate.getFullYear();
    const m = today.getMonth() - birthdate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
      age--;
    }

    return age;
  }

  // Verifica si el numero de documento esta en algun grupo familiar o registrado
  verifyDocumenttwo(document:string){
    this.userExist = null;
    this.familyService.getFamilyGroupsDetailsDocument(document).then(resp=>{
        console.log('respuesta', resp);
        if (!resp.ok) { 
            this.userExist = false;
            this.userInitial = false;
        } else { 
            this.userExist = true;
            this.userInitial = true;
        }
        //console.log(this.userExist); 
        
    }).catch(err=>{
        //console.log(err); // Manejo de errores en caso de falla en la solicitud.         
    });  
}

 // Metodo para crear grupo familiar y add beneficiario
 createFamilyGroupAndBeneficiry() {
  // //console.log('datos de los forms',this.formfamilyGroup.value, this.formBeneficiary.value);
  // //console.log('permisos y cellPhones',this.cellPhones, this.permissions);

  const { nombres, apellidos, fechaNacimiento,  correo, relationship } = this.formBeneficiary.value;
  const {nameFamily, tipoDocumento:{document,type}} = this.formfamilyGroup.value;

  if (this.ageBeneficiary >= 18) {
    const newGroupFamilyAndBeneficiary = {
      familyGroupName: nameFamily,
      beneficiary: {
        permissions: this.convertArrayToObject(this.formBeneficiary.value.checks, this.dataChecks),
        beneficiary: {
          correo: correo,
          numeroDocumento: document,
          tipoCuenta: 'Beneficiary',
          tipoDocumento: type.toUpperCase(),
          nombres: nombres,
          apellidos: apellidos,
          fechaNacimiento: fechaNacimiento,
          cellPhones: this.cellPhones,
          registerData: {
            method: 'form',
            platform: 'web',
            registerBy: 'yourself',
            beneficiary: {
              createdBy: this.key._idData,
              relationship: relationship
            }
          }
        }
      },
    }

    //console.log(newGroupFamilyAndBeneficiary);
    // Metodo que crea el grupo familiar con  los beneficiarios
    this.familyService.addGroupFamilyAndBeneficiary(newGroupFamilyAndBeneficiary).then(() => {
      // Notificar al servicio que el grupo familiar ha sido actualizado para que en el padre se rendericen el nuevo grupo
      this.familyGroupService.notifyFamilyGroupUpdated();
    })
    //console.log('grupo familiar creado correctamente con mayor de edad');
  }

  if (this.ageBeneficiary < 18) {
    const newGroupFamilyAndBeneficiary = {
      familyGroupName: nameFamily,
      beneficiary: {
        permissions: this.permissions,
        beneficiary: {
          correo: correo,
          numeroDocumento: document,
          tipoCuenta: 'Beneficiary',
          tipoDocumento: type.toUpperCase(),
          nombres: nombres,
          apellidos: apellidos,
          fechaNacimiento: fechaNacimiento,
          cellPhones: this.cellPhones,
          registerData: {
            method: 'form',
            platform: 'web',
            registerBy: 'yourself',
            beneficiary: {
              createdBy: this.key._idData,
              relationship: relationship
            }
          }
        }
      },
    }

    //console.log(newGroupFamilyAndBeneficiary);
    // Metodo que crea el grupo familiar con  los beneficiarios
    this.familyService.addGroupFamilyAndBeneficiary(newGroupFamilyAndBeneficiary).then(() => {
      // Notificar al servicio que el grupo familiar ha sido actualizado
      this.familyGroupService.notifyFamilyGroupUpdated();
    })
    //console.log('grupo familiar creado correctamente con menor edad');
  }

}

// Validar el form de beneficario 
async validateAndCreate() {
    
  if( this.formBeneficiary.invalid ){
    //console.log('datos del form beneficiario', this.formBeneficiary.value);
    this.formBeneficiary.markAllAsTouched();
    console.error('error en el form beneficiario');
  }

if(this.formBeneficiary.valid) {
  // Abrir una sweet alert de confirmacion para registrar el nuevo grupo familiar y beneficiario
   let nombres = this.formBeneficiary.get('nombres')?.value;
   let apellidos = this.formBeneficiary.get('apellidos')?.value;
   let grupo = this.formfamilyGroup.get('nameFamily')?.value;
  const  alert = await Swal.fire({
    title: 'Estás seguro?',
    text: `Se va a Agregar  ${ nombres||''} ${ apellidos ||''} a tu grupo familiar ${grupo || ' '} `,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#00ab78',
    cancelButtonColor: '#e74c3c',
    confirmButtonText: 'Si, agregar!',
    cancelButtonText: 'Cancelar',
  })

  if(alert.isConfirmed){
    // Llamar los metodos del  sub-service.info para actualizars los datos de  los grupos familiares
    this.createFamilyGroupAndBeneficiry();
    // Cerrar el modal y ver como se actualiza la lista de los grupos familiares en sub-service-info
    setTimeout(()=> {
        this.modalService.closeModal();
    },400);

  }

  return;
}


}



}
