import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FamilyGroupService {

  private familyGroupUpdated = new Subject<void>();

  // Observable al que se pueden suscribir los componentes
  familyGroupUpdated$ = this.familyGroupUpdated.asObservable();

  // Método para emitir el evento
  notifyFamilyGroupUpdated() {
    this.familyGroupUpdated.next();
  }
}
