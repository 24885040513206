{{ requestData?.message }}

<ng-container *ngIf="view === 'buttons'; else motivesTemplate">

    <div class="text-center">
        <button (click)="sendResponse()">Aceptar</button>
        <button (click)="view = 'motives'; responseRequest = false;">Rechazar</button>
    </div>
</ng-container>


<ng-template #motivesTemplate>

    <div class="text-center">

        <textarea name=""  placeholder="motives" [formControl]="motives"></textarea>
        <br>
        <button (click)="view = 'buttons'; responseRequest = true;">Cancelar</button>
        <button (click)="sendResponse()">Enviar Respuesta</button>
    </div>
</ng-template>

