<div class="container">

    <ng-container *ngIf="recoverAccount.form === 'complete_profile'">

        <form [formGroup]="completeProfileForm" (ngSubmit)="submitForm()">

            <h2 class="title">Completar perfil</h2>

            <p>Completa tu información de perfil. Esto facilitará tu experiencia con nosotros. Esta información nos ayuda a ofrecerte una atención personalizada y eficiente, adaptada a tus necesidades.</p>

            <form-input helper="" label="Nombres" placeholder="" [controlName]="'name'" [type]="'text'"></form-input>
            <form-input helper="" label="Apellidos" placeholder="" [controlName]="'surNames'" [type]="'text'" ></form-input>
            <form-document helper="" label="Tipo Documento" filter="Adult" [controlName]="'documentNumber'" placeholder="Seleccione..."></form-document>
            <form-date label="Fecha de nacimiento" [minInput]="minDate" [maxInput]="maxDate" controlName="dateOfBirth"></form-date>
            <form-phone [controlName]="'phoneNumber'" label="Número de Contacto"></form-phone>

            <div class="checkbox checkbox-success mt-3">
                <input id="checkbox-signup" type="checkbox" class="chk-col-teal material-inputs" formControlName="terms">
                <label for="checkbox-signup" class=""> Acepto los <a style="cursor: pointer; color: #06d79c" (click)="recoverAccount.form = 'terms'"> Terminos y condiciones </a></label>
            </div>

            <ng-container *ngIf="recoverAccount.message">
                <p class="pt-3">Hay una cuenta registrada con este número de documento. Si piensas que eres tu, puedes <a style="cursor: pointer; color: #06d79c" (click)="recoverAccountclicked()">recuperar la cuenta</a> .Si no estas segur@ puede comunicarse con un <a href="https://api.whatsapp.com/send?phone=573174785093&text=Hola!%20quiero%20recuperar%20mi%20cuenta." target="_blank">asesor.</a></p>
            </ng-container>

            <div class="container-button text-center pt-4 mb-3">
                <custom-buttons title="" type="button" text="No Registrar" (click)="logout()" [inputBackgroundColor]="'danger'" [rounded]="true"></custom-buttons>
                <custom-buttons title="" text="Registrar" type="submit" [rounded]="true"></custom-buttons>
            </div>
        </form>

    </ng-container>

    <ng-container *ngIf="recoverAccount.form === 'send_code'">

        <form [formGroup]="completeProfileForm" (ngSubmit)="sendCode()">

            <h2 class="title">Recuperar Cuenta</h2>
            <p>Por favor, verifique que su número de documento y teléfono coincidan. Se enviará un código de verificación para confirmar su identidad.</p>


            <label class="input__label" for="input_component">Método</label>
            <div style= "display: flex;">

                <div class="col-md-3">
                    <input type="radio" formControlName="method" class="with-gap material-inputs" id="radio_3" value="wsp">
                    <label for="radio_3">WhatsApp</label>
                  </div>
                  <div class="col-md-3">
                    <input type="radio" formControlName="method" id="radio_4" class="with-gap material-inputs" value="sms">
                    <label for="radio_4">Sms</label>
                  </div>
            </div>


            <form-document helper="" label="Tipo Documento" filter="Adult" [controlName]="'documentNumber'" placeholder="Seleccione..."></form-document>
            <form-phone [controlName]="'phoneNumber'" label="Número de Contacto"></form-phone>

            <ng-container *ngIf="recoverAccount.message">
                <p class="pt-3">El código de verificación es incorrecto. Si piensas que es un error y quieres recuperar tu cuenta puedes comunicarse con un <a href="https://api.whatsapp.com/send?phone=573174785093&text=Hola!%20quiero%20recuperar%20mi%20cuenta." target="_blank">asesor.</a></p>
            </ng-container>

            <div class="container-button text-center pt-4 mb-3">
                <custom-buttons title="" type="button" text="Volver" (click)="recoverAccount.form = 'complete_profile'; this.recoverAccount.message = true;" [inputBackgroundColor]="'danger'" [rounded]="true"></custom-buttons>
                <custom-buttons title="" text="Enviar Código" type="submit" [rounded]="true"></custom-buttons>
            </div>

        </form>

    </ng-container>

    <ng-container *ngIf="recoverAccount.form === 'recover_account'">

        <h2 class="title">Recuperar Cuenta</h2>
        <p>Queremos informarte que a partir de ahora, tu correo de Gmail ({{ userData.googleEmail }}) se utilizará como el correo principal para iniciar sesión. El correo que la cuenta utiliza actualmente quedará como tu correo de recuperación de cuenta.</p>

        <form [formGroup]="completeProfileForm" (ngSubmit)="mergeAccounts()">

            <form-input helper="" label="Código de verificación" placeholder="" [controlName]="'code'" [type]="'text'"></form-input>

            <div class="container-button text-center pt-4 mb-3">
                <custom-buttons title="" type="button" text="Cancelar" (click)="logout()" [inputBackgroundColor]="'danger'" [rounded]="true"></custom-buttons>
                <custom-buttons title="" text="Aceptar" type="submit" [rounded]="true"></custom-buttons>
            </div>
        </form>

    </ng-container>
</div>

<ng-container *ngIf="recoverAccount.form === 'terms'">
    <app-terms-and-conditions [showButton]="true" (emitClick)="recoverAccount.form = 'complete_profile'"></app-terms-and-conditions>
</ng-container>
