import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'documentFilter'
})
export class DocumentFilter implements PipeTransform {

  transform(documents: any[], filter: string): any[] {
    if (!documents || !filter) {
      return documents;
    }

    if (filter === 'menor') {
      return documents.filter(document => document.tipo === 'RC' || document.tipo === 'TI');
    } else if (filter === 'mayor') {
      return documents.filter(document => document.tipo !== 'RC' && document.tipo !== 'TI');
    } else if (filter === 'todos') {
      return documents;
    }

    return [];
  }

}
