<span [id]="idInput" class="input__group">
  <div class="box__label">
    <label class="input__label" for="input_component" [style.color]="labelColor" [style.fontSize]="labelFontSize" >{{label}}<strong *ngIf="required"> *</strong></label>
    <i class="fa fa-question icon__helper" [title]="helper" *ngIf="helper">
    </i>
  </div>
  <div class="box__input">
    <div class="selector" [ngClass]="{'disabled': control.disabled}" (click)="control.disabled ? null: selectorActive=!selectorActive">
      <span class="selector__label">{{selectedType|uppercase}}</span>
      <span class="selector__arrow__icon" [ngClass]="selectorActive?'up':'down'"></span>
    </div>
    <ul class="list__data" [ngClass]="{inactive:!selectorActive}">
      <ng-container *ngFor="let docType of documentTypes">
        <li class="list__data__item" [attr.type-value]="docType.documentTypeId" (click)="selectType(docType.type); selectorActive=false">
          <span>{{docType.type}}</span>
          <span>{{docType.name}}</span>
        </li>
      </ng-container>
    </ul>
    <!-- <input type="tel" class="input__form__phone" #phoneInputRef [value]="phoneNumber | phoneMask" (input)="setPhone($event)" placeholder="(300) 000-0000" />  -->
    <input #inputRef class="input__form" type="text" name="input_component" [value]="documentValue|uppercase|documentMask:selectedType" (input)="setEntry($event)" [placeholder]="placeholder" autocomplete="off" [ngClass]="{invalid: control.touched && control.invalid}" [disabled]="control.disabled">
    <input type="hidden" [formControl]="control">
    <i class="fa fa-close icon__clear" *ngIf="!control?.disabled" [ngClass]="{visible: inputRef.value != ''}" (click)="control.setValue(''); inputRef.value = ''" title="Limpiar"></i>
    <span class="loader__icon" *ngIf="validating"></span>
    <span class="fa fa-check valid__check__icon input__icon" *ngIf="validDocument"></span>
  </div>
  <div class="box__error" [ngClass]="{visible:errors.length}">
    <ul>
      <li class="box__error__item" *ngFor="let error of errors"><strong>* </strong>{{error}}</li>
    </ul>
  </div>
</span>
