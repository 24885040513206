import {
  ControlContainer,
  FormArray,
  FormGroupDirective,
} from '@angular/forms';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'form-checks',
  styleUrls: ['../../form-styles.scss', './form-checks.component.scss'],
  templateUrl: './form-checks.component.html',
})
export class FormChecksComponent {
  @Input() controlName!: string;
  @Input() data: Array<{
    id: string;
    label: string;
    value: string;
    description?: string;
    hasRange?: boolean;
    minRange?: number;
    maxRange?: number;
    only?: boolean;
  }> = [];
  @Input() placeholder: string = 'Default placeholder';
  @Input() label: string = 'Default label';
  @Input() helper: string | undefined;
  @Input() required: boolean = true;
  @Input() type: string = 'text';
  @Input() bordered: boolean = false;
  @Input() colClass: string = '';

  itemList: Array<{ id: string; label: string; value: string }> = [];
  errors: any[] = [];
  // value: any;
  isCheckedMap: { [key: string]: boolean } = {};
  rangeValues: { [key: string]: { min: number; max: number } } = {};

  constructor(private controlContainer: ControlContainer) {}

  /** Set data on control when already exist a value */
  ngOnInit(): void {
    if (this.control.value && this.control.value.length > 0) {
      this.itemList = this.data.filter((item) =>
        this.control.value.some((val: any) =>
          this.compareObjectChecks(val, item)
        )
      );
    }
  }

  get control(): FormArray {
    const parentFormGroup = this.controlContainer as FormGroupDirective;
    return parentFormGroup.control.get(this.controlName) as FormArray;
  }

  get controlInvalid(): boolean {
    return this.control?.invalid || false;
  }

  get controlTouched(): boolean {
    return this.control?.touched || false;
  }

  get errorKeys(): string[] {
    return this.control?.errors ? Object.keys(this.control.errors) : [];
  }

  ngAfterViewInit(): void {
    this.checkItems();

    this.control.statusChanges.subscribe(() => {
      this.errors = [];
      // if (!this.control.errors || !this.control.dirty) return
      if (!this.control.errors) return;
      console.debug(this.control.errors);

      this.control.errors['required'] &&
        this.errors.push(`Es necesario seleccionar al menos una opción`);
    });
  }

  setChecked(event: any, item: any) {
    const isChecked = (event.target as HTMLInputElement).checked;

    // Si el checkbox seleccionado tiene `only: true` y está marcado
    if (item.only && isChecked) {
      this.data.forEach((otherItem) => {
        if (otherItem.only && otherItem.value !== item.value) {
          this.isCheckedMap[otherItem.value] = false;
          const otherCheckbox = document.getElementById(`${otherItem.value}chk`) as HTMLInputElement;
          if (otherCheckbox) {
            otherCheckbox.checked = false;
          }
          this.itemList = this.itemList.filter((_item) => _item.value !== otherItem.value);
          delete this.rangeValues[otherItem.value];
        }
      });
    }

    // Actualizar el mapa de ítems seleccionados
    this.isCheckedMap[item.value] = isChecked;

    if (isChecked) {
      // Añadir el ítem a itemList
      let itemToAdd = { ...item };

      // Si el ítem tiene `hasRange`, añadir los valores de rango
      if (item.hasRange) {
        this.rangeValues[item.value] = {
          min: item.minRange !== undefined ? item.minRange : 0,
          max: item.maxRange !== undefined ? item.maxRange : 120,
        };
        itemToAdd.range = this.rangeValues[item.value];
      }
      this.itemList = [...this.itemList, itemToAdd];
    } else {
      // Eliminar el ítem de itemList
      this.itemList = this.itemList.filter((_item) => _item.value !== item.value);
      delete this.rangeValues[item.value];
    }

    // Determinar si los datos incluyen ítems con `only` o `hasRange`
    const hasSpecialItems = this.data.some(d => d.only || d.hasRange);

    if (hasSpecialItems) {
      // Si hay ítems especiales, actualizamos el control con información detallada
      const updatedFormValue = this.data.map((d) => ({
        value: d.value,
        isChecked: this.isCheckedMap[d.value] || false,
        range: this.rangeValues[d.value] || null,
      }));
      this.control.setValue(updatedFormValue);
    } else {
      // Si son datos simples, actualizamos el control con la lista de ítems seleccionados
      this.control.setValue(this.itemList);
    }
  }

  onRangeInput(itemValue: string): void {
    const itemCheck = this.data.find((item) => item.value === itemValue);
    if (!itemCheck) return;

    if (
      this.rangeValues[itemValue].min == null ||
      this.rangeValues[itemValue].min < (itemCheck.minRange ?? 0)
    ) {
      this.rangeValues[itemValue].min = itemCheck.minRange ?? 0;
    }

    if (this.rangeValues[itemValue].min < (itemCheck.minRange ?? 0)) {
      this.rangeValues[itemValue].min = itemCheck.minRange ?? 0;
    }

    if (
      this.rangeValues[itemValue].max == null ||
      this.rangeValues[itemValue].max > (itemCheck.maxRange ?? 120)
    ) {
      this.rangeValues[itemValue].max = itemCheck.maxRange ?? 120;
    }

    if (this.rangeValues[itemValue].max > (itemCheck.maxRange ?? 120)) {
      this.rangeValues[itemValue].max = itemCheck.maxRange ?? 120;
    }

    if (this.rangeValues[itemValue].min > this.rangeValues[itemValue].max) {
      this.rangeValues[itemValue].min = this.rangeValues[itemValue].max;
    }
    if (this.rangeValues[itemValue].max < this.rangeValues[itemValue].min + 1) {
      this.rangeValues[itemValue].max = this.rangeValues[itemValue].min + 1;
    }

    this.emitFilterValues();
  }

  // Manejo del input para el valor mínimo
  handleMinInput(event: any, itemCheck: any) {
    let value = event.target.value;
    const maxRange =
      this.rangeValues[itemCheck.value]?.max || itemCheck.maxRange || 120;
    const minRange = itemCheck.minRange ?? 0;

    // Quitar cualquier símbolo negativo si el rango es positivo
    if (minRange >= 0 && value.includes('-')) {
      value = value.replace('-', '');
    }

    // No permitir el valor '0' seguido de otro número
    if (value.startsWith('0') && value.length > 1) {
      value = value.slice(1);
    }

    // Si el valor supera el máximo permitido, lo ajustamos
    if (value > maxRange) {
      value = maxRange - 1;
    }

    // Si el valor es menor que el mínimo, lo ajustamos
    if (value < minRange) {
      value = minRange;
    }

    // Limitar a tres dígitos
    if (value.length > 3) {
      value = value.slice(0, 3);
    }

    // Actualizar el valor en el rango
    this.rangeValues[itemCheck.value].min = value;
    event.target.value = value;
    this.emitFilterValues();
  }

  // Manejo del input para el valor máximo
  handleMaxInput(event: any, itemCheck: any) {
    let value = event.target.value;
    const minRange =
      this.rangeValues[itemCheck.value]?.min || itemCheck.minRange || 0;
    const maxRange = itemCheck.maxRange ?? 120;

    if (minRange >= 0 && value.includes('-')) {
      value = value.replace('-', '');
    }

    if (value.startsWith('0') && value.length > 1) {
      value = value.slice(1);
    }

    if (value > maxRange) {
      value = maxRange;
    }

    if (value < minRange) {
      value = minRange + 1;
    }

    if (value.length > 3) {
      value = value.slice(0, 3);
    }

    this.rangeValues[itemCheck.value].max = value;
    event.target.value = value;
    this.emitFilterValues();
  }

  private emitFilterValues(): void {
    const updatedValues = this.data.map((item) => ({
      value: item.value,
      isChecked: this.isCheckedMap[item.value] || false,
      range: this.rangeValues[item.value] || { min: 0, max: 120 },
    }));
    this.control.setValue(updatedValues);
  }

  private checkItems() {
    this.itemList.forEach(
      (item) =>
        ((
          document.getElementById(`${item.value}chk`) as HTMLInputElement
        ).checked = true)
    );
  }

  private compareObjectChecks(firstObj: any, secondObj: any) {
    return JSON.stringify(firstObj) === JSON.stringify(secondObj);
  }
}
