import { createReducer, on } from "@ngrx/store";
// import { RolSession } from "src/app/interfaces/auth.interface";
import { reloadState, initSession, selectRol, reloadStateWithStorage, serviceType } from "./session.actions";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { SessionService } from "../session.service";
import { RolSession } from "src/app/interfaces/session.interface";
import { ApplicationService } from '../../../services/application.service';
import { EncryptService } from '../../../services/encrypt.service';

const localStorageService = new LocalStorageService();
const sessionService = new SessionService();
const applicationService = new ApplicationService();
const encryptService = new EncryptService();

export const sessionStateFeatureKey = 'sessionState';

export interface SessionState {
    rolSelect: any | null,
    roles: any | null,
    menu: any | null,
    typeOfProviver: 'Laboratory' | 'Services' | 'Imaging' | 'Veterinary' | undefined | null;
}

export const initialSessionState: SessionState = {
    rolSelect: null,
    roles: null,
    menu: [],
    typeOfProviver: undefined
};

export const sessionReducer = createReducer( 
    initialSessionState,
    on(initSession, (state, {sessionData }) => {

      const { tipoCuenta, _id: _idData } = sessionData;
      const rol = tipoCuenta === 'Advisor' ? 'advisor' : 'user';
      const { rolSelect, roles, menu, typeOfProviver } = sessionService.rolGenerator(sessionData, { rol, _idData });

      const rolSession: RolSession =  { rol: rolSelect.rol, _idData: rolSelect._idData };
      localStorageService.setItem('session', JSON.stringify(rolSession));

      return {
        ...state,
        rolSelect,
        roles,
        menu,
        typeOfProviver
      };
    }),
    on(reloadState, (state, { sessionData, rolSession }) => {
      
      const { rolSelect, roles, menu, typeOfProviver } = sessionService.rolGenerator(sessionData, rolSession);

      return {
        ...state,
        rolSelect,
        roles,
        menu,
        typeOfProviver
      };
    }),
    on(selectRol, (state, { sessionData, rolSession }) => {
      
      localStorageService.setItem('session', JSON.stringify(rolSession));
      const { rolSelect, roles, menu, typeOfProviver } = sessionService.rolGenerator(sessionData, rolSession);

      return {
        ...state,
        rolSelect,
        roles,
        menu,
        typeOfProviver
      };
    }),
    on(reloadStateWithStorage, (state, { sessionData, rolSession }) => {

      const { rolSelect, roles, menu, typeOfProviver } = sessionService.rolGenerator(sessionData, rolSession);

      const token = sessionData['token'];
      const anio = applicationService.getDate(); 
      const key = token + anio;
  
      const encryptedData = encryptService.manageActions({ action: 'symmetricEncryption', data: JSON.stringify(sessionData), key });
      localStorageService.setItem('usdt', encryptedData);
      localStorageService.setItem('tkn', token);

      return {
        ...state,
        rolSelect,
        roles,
        menu,
        typeOfProviver
      };
    }),
    on(serviceType, (state, { typeOfProviver }) => {

        return {
            ... state,
            typeOfProviver
        }
    })
);