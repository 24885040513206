import { Component, OnInit, Input ,ViewChild,TemplateRef} from '@angular/core';
import Swal from 'sweetalert2';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FamilyGroup, Pet, Translation } from '@interfaces/information.interface';
import { StatesNgrxService, SweetAlertService } from '@services';
import { FamilyGroupsServiceService } from 'src/app/roles/services/family-groups-service.service';

@Component({
  selector: 'app-pets',
  templateUrl: './pets.component.html',
  styleUrls: ['./pets.component.scss'],
})
export class PetsComponent implements OnInit {
  @Input() familyGroup!: FamilyGroup;
  @Input() options = false;

  @ViewChild('petsList') petsList!: TemplateRef<any>;
  @ViewChild('petsAgregate') petsAgregate!: TemplateRef<any>;

  public petForm!: FormGroup;

  public currentView!: TemplateRef<any>;

  pets: Pet[] = [];
  petSelected?:Pet;
  iconMale = false;

  translateString:Translation[]=[
    {term:'No registrado',translate:'No registrado'},
    {term:'Male',translate:'Macho'},
    {term:'Female',translate:'Hembra'},
  ]

  fileComponent?:File;
  selectedFile?:File;

  imageDataUrl!: string;
  imageDrop = false;
  errorTipo = false;
  cargandoFile=false;
  actualizado=false;

  genders=[
    {value:'Male',name:'Macho'},
    {value:'Female',name:'Hembra'},
  ];

  sterilizationStates=[
    {value:false,name:'NO'},
    {value:true,name:'SI'},
  ];

  vista='selectImage';


  constructor(
    private swalService: SweetAlertService, 
    private familyGroupsService:FamilyGroupsServiceService,
    private statesNgrxService:StatesNgrxService){}

  ngOnInit(): void {

    this.getPets();

    setTimeout(() => {
      this.currentView = this.petsList;
      
    }, 200);
  }

  getPets(){
    
    if (this.familyGroup) {
      if (this.familyGroup._id) {
        this.familyGroupsService.getFamilyGroupsDetailsId(this.familyGroup._id).then(resp=>{                   
          this.pets = resp.body.pets;  
        }).catch(err=>{
          //console.log(err);          
        })          
      }       
    }
  }
  
  async iniciarPetAgregateForm() {

    this.petSelected = undefined;
    this.imageDataUrl = '';

    this.petForm = new FormGroup({           
      petName: new FormControl<string|null>(null, Validators.required),
      species: new FormControl<string|null>(null, Validators.required),
      breed: new FormControl<string|null>(null, Validators.required),
      gender: new FormControl<string|null>(null, Validators.required),
      color: new FormControl<string|null>(null, Validators.required),
      sterilized: new FormControl<boolean>(false, Validators.required),
      birth_date: new FormControl(null, Validators.required),
      age: new FormControl<number|null>({value: null, disabled: true}),
    }); 

    this.petForm.controls['birth_date'].valueChanges.subscribe(resp=>{
      this.petForm.controls['age'].setValue(this.getYearsToCurrentDate(resp));  
    })

    this.currentView = this.petsAgregate;

  }



  async inicializarPetForm(pet: Pet) {    
       
    this.petForm = new FormGroup({
      petName: new FormControl<string|null>(pet.petName || null,Validators.required),
      species: new FormControl<string|null>(pet.species || null),
      breed: new FormControl<string|null>(pet.breed || null),
      gender: new FormControl<string|null>(pet.gender || null),
      color: new FormControl<string|null>(pet.color || null),
      sterilized: new FormControl<boolean>(pet.sterilized|| false),// seleccionar de lista
      birth_date: new FormControl( pet.birth_date? this.getDate(String(pet.birth_date)):null),
      age: new FormControl<number|null>({value: pet.age || null, disabled: true}),
    });

    this.petForm.controls['birth_date'].valueChanges.subscribe(resp=>{
      this.petForm.controls['age'].setValue(this.getYearsToCurrentDate(resp));  
    })

  }

  getYearsToCurrentDate(fecha:string|null):number|null{

    if (!fecha) {
      return null;      
    }else{
      var hoy = new Date();
      var cumpleanos = new Date(fecha);
      var edad = hoy.getFullYear() - cumpleanos.getFullYear();
      var m = hoy.getMonth() - cumpleanos.getMonth();

      if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
          edad--;
      }  
      return edad;
    }

  }

  getDate(date: string): string | null {
    return date ? date.slice(0, 10) : null;
  }

  

  hacerScroll(id: string): void {
    // Buscar el elemento por su ID
    var elementos = document.querySelectorAll(`#${id}`)[0] as HTMLElement;

    // Realizar el desplazamiento suave al elemento
    elementos.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }


  /**
   * Selecciona un Pet y emite un evento para indicar que no se debe regresar.
   * @param {pet} Pet - pet family seleccionado.
   */
  selectFamilyPet(pet: Pet ): void {

    if (pet.avatar) {
      this.imageDataUrl=pet.avatar;      
    }
    
    this.inicializarPetForm(pet);
    setTimeout(() => {
      this.currentView = this.petsAgregate;      
      this.petSelected = pet;      
    }, 500);
  }

  /**
   * Limpia la selección de group y emite un evento para indicar que se debe regresar.
   */
  regresarPets(): void {
    this.petSelected = undefined;
    this.currentView = this.petsList;
  }  


  // Images

  /**
   * Maneja el evento de arrastrar sobre el área de soltar.
   * @param {DragEvent} event - Evento de arrastrar.
   */
  onDragOver(event: DragEvent) {
    this.imageDrop=true;
    this.errorTipo=false;
    
    event.preventDefault();
    event.stopPropagation();
    var divDrop = document.getElementById('idDrop');
    if (divDrop) {
      divDrop.classList.add('border_drop');
      this.imageDrop=true;
    }
  }

  /**
   * Maneja el evento de dejar de arrastrar fuera del área de soltar.
   * @param {DragEvent} event - Evento de arrastrar.
   */
  onDragleave(event: DragEvent){
    var divDrop = document.getElementById('idDrop');
    if (divDrop) {
      divDrop.classList.remove('border_drop');
      this.imageDrop=false;
    }    
  }

  /**
   * Maneja el evento de soltar un archivo en el área de soltar.
   * @param {DragEvent} event - Evento de soltar.
   */
  onDrop(event: DragEvent) {
    this.errorTipo=false;
    
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer) {      

      var divDrop = document.getElementById('idDrop');
      if (divDrop) {
        divDrop.classList.remove('border_drop');
        this.imageDrop=false;
      }

      const tipo =event.dataTransfer.files[0].type
      if(["image/jpeg","image/jpg","image/png"].includes(tipo)){
        this.processFile(event.dataTransfer.files[0]);
        this.fileComponent = event.dataTransfer.files[0];
      } else{
        this.errorTipo=true;
      }
    }
  }

  /**
   * Maneja la selección de un archivo mediante un selector de archivos.
   * @param {Event} event - Evento de selección de archivo.
   */
  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files) { 
      
      const tipo = input.files[0].type
      if(["image/jpeg","image/jpg","image/png"].includes(tipo)){
        this.fileComponent = input.files[0];
        this.processFile(input.files[0]);  

      } else{
        this.errorTipo=true;
      }          
    }    
  }

  /**
   * Muestra el selector de archivos para elegir una imagen.
   */
  selectImage() {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = 'image/*';
    inputElement.addEventListener('change', (event: Event) => this.onFileSelected(event));
    inputElement.click();
    
  }

  /**
   * Retorna a una vista específica y realiza una limpieza.
   * @param {string} vista - Vista a la que se debe retornar.
   */
  regresarYLimpiar(vista:string){
    this.fileComponent=undefined;
    this.vista = vista;
    this.imageDataUrl = '';
  }

  /**
   * Retorna a una vista específica y realiza una limpieza.
   * @param {string} vista - Vista a la que se debe retornar.
   */
  regresar(vista:string){
    this.fileComponent=undefined;
    this.vista = vista;
    this.imageDataUrl = this.petSelected?.avatar||'';
  }



  /**
   * Procesa un archivo para mostrarlo como imagen previa antes de cargarlo.
   * @param {File} file - Archivo de imagen.
   */
  private processFile(file: File) {
    this.cargandoFile = true;
    
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (e.target) {        
        this.imageDataUrl = e.target.result as string;            
      }
    };
    reader.readAsDataURL(file);
    setTimeout(()=>{

      this.cargandoFile = false;
      this.vista ='cropImage'
    },1000)
  }

  cropperImageEmmiter(file:File){
    // //console.log(file);     

    if (file) {
      
      this.selectedFile = file;      
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target) {        
          this.imageDataUrl = e.target.result as string;            
        }
      };
      reader.readAsDataURL(file);
  
      this.vista='selectImage';
      setTimeout(() => {
        this.hacerScroll('idInputFile');
      }, 200);
    } 

  }


  //Formularios
  async agregatePetGroup(form: FormGroup) {
    let userID = '';

    const formData = new FormData();

    this.statesNgrxService.getDataGlobalUser().subscribe((resp:any)=>{                   
      if (!resp) {
        return //console.log('Error');                
      } 
      userID = resp._id;
    });

    
    if (form.valid) {
      formData.append('ownerId',userID);
      formData.append('groupId',this.familyGroup._id||'');
      formData.append('petName', this.petForm.value.petName);

      if (this.petForm.value.species) {
        formData.append('species', this.petForm.value.species);        
      }
      if (this.petForm.value.breed) {
        formData.append('breed', this.petForm.value.breed);        
      }
      if (this.petForm.value.gender) {
        formData.append('gender', this.petForm.value.gender);        
      }
      if (this.petForm.value.color) {
        formData.append('color', this.petForm.value.color);        
      }
      if (this.petForm.value.birth_date) {
        formData.append('birth_date', this.petForm.value.birth_date);        
      }
      if (this.petForm.value.age) {
        formData.append('age', this.petForm.value.age);        
      }
      if (this.petForm.value.sterilized) {
        formData.append('sterilized', this.petForm.value.sterilized);        
      }
      if (this.selectedFile) {
        formData.append('avatar', this.selectedFile);        
      }

      const result = await Swal.fire({
        title: 'Estás seguro?',
        text: `Se va a Agregar ${this.petForm.value.petName} a tus Mascotas`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, agregar!',
        cancelButtonText: 'Cancelar',
      });

      if (result.isConfirmed) {
        
        this.familyGroupsService.createFamilyGroupPet(formData).then((resp:any)=>{          
          if (resp.ok) {
            this.getPets();
            this.currentView = this.petsList;  
            this.hacerScroll('idFormPet');          
          }else{
            //console.log(resp);
          }

        }).catch(err=>{
          //console.log(err);          
        });
        
      }
    } else {
      this.petForm.markAllAsTouched();
      const requiredFields = [
        'petName',
        'species',
        'breed',
        'gender',
        'color',
        'sterilized',
        'birth_date',
      ];

      const swalResult = await this.swalService.lauchSwalAsync(
        '',
        'Debes registrar los campos en color rojo, ya que son obligatorios',
        'warning'
      );
      if (swalResult) {
        setTimeout(() => {
          const scrollTo = requiredFields.find(
            (field) => this.petForm.controls[field].invalid
          );
          if (scrollTo) {
            this.hacerScroll(
              `idInput${scrollTo.charAt(0).toUpperCase() + scrollTo.slice(1)}`
            );
          }
        }, 300);
      }
    }
  }


  async updatePetGroup(form: FormGroup) {   
    this.hacerScroll('idFormPet');     
    if (this.petSelected) {       
      const formData = new FormData();      
      if (form.valid) {
        formData.append('groupId',this.familyGroup._id||'');
        formData.append('petId',this.petSelected._id||'');        
        formData.append('petName', this.petForm.value.petName);

        if (this.petForm.value.species) {
          formData.append('species', this.petForm.value.species ||'');          
        }
        if (this.petForm.value.breed) {
          formData.append('breed', this.petForm.value.breed ||'');          
        }
        if (this.petForm.value.gender) {
          formData.append('gender', this.petForm.value.gender ||'');          
        }
        if (this.petForm.value.color) {
          formData.append('color', this.petForm.value.color ||'');          
        }
        if (this.petForm.value.birth_date) {
          formData.append('birth_date', this.petForm.value.birth_date ||'');          
        }
        // if (this.petForm.value.age) {
        //   formData.append('age', this.petForm.value.age ||'');          
        // }
        if (this.petForm.value.sterilized) {
          formData.append('sterilized', this.petForm.value.sterilized ||'');          
        }
        if (this.selectedFile) {          
          formData.append('avatar', this.selectedFile);        
        }

        const result = await Swal.fire({
          title: 'Estás seguro?',
          text: `Se va a Actualizar la información de ${this.petForm.value.petName}`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, actualizar!',
          cancelButtonText: 'Cancelar',
        });

        if (result.isConfirmed) {          
          this.familyGroupsService.updateFamilyGroupPet(formData).then((resp:any)=>{            
            if (resp.ok) {
              this.getPets();
              this.currentView = this.petsList;  
              this.hacerScroll('idFormPet');             
            }else{
              //console.log(resp);
            }
            
          }).catch(err=>{
            //console.log(err);          
          });
          
        }
      } else {
        this.petForm.markAllAsTouched();
        const requiredFields = [
          'petName',
          'species',
          'breed',
          'gender',
          'color',
          'sterilized',
          'birth_date',
        ];

        const swalResult = await this.swalService.lauchSwalAsync(
          '',
          'Debes registrar los campos en color rojo, ya que son obligatorios',
          'warning'
        );
        if (swalResult) {
          setTimeout(() => {
            const scrollTo = requiredFields.find(
              (field) => this.petForm.controls[field].invalid
            );
            if (scrollTo) {
              this.hacerScroll(
                `idInput${scrollTo.charAt(0).toUpperCase() + scrollTo.slice(1)}`
              );
            }
          }, 300);
        }
      }
    }
  } 


  deletePetId(pet:Pet): void {    
    Swal.fire({
      title: 'Estás seguro?',
      text: `Se va a eliminar a ${pet.petName} de tu Grupo Familiar`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar mascota!',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        if (pet._id && this.familyGroup._id) {
          this.familyGroupsService.deletePetId(pet._id,this.familyGroup._id).then(resp=>{
            if (resp.ok) {
              this.getPets(); 
              setTimeout(() => {
                this.hacerScroll('pills-tab');
              }, 200);             
            }            
          }).catch(err=>{
            //console.log(err);            
          });          
        }
      }
    });
  }

  
}
