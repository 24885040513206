<div class="animated fadeIn mt-4">
  <app-form-section [number]="pasosInput + ''" [sectionName]="'Datos de la Cita'"></app-form-section>

  <ng-container *ngIf="tempAppointment; else normalFlow">
    <div *ngIf="income == undefined" [id]="idInput" class="row mt-4">
      <div class="col-xl-6 col-md-6 col-xs-12" title="click aqui!" (click)="selectOption(false)">
        <div class="card_income">
          <div class="align_icon">
            <i class="font_income_icon mdi mdi-calendar-check"></i>
          </div>
          <div class="card_income_title">Solicitar Cita</div>
          <p class="card_income_body">
            Por favor, selecciona una cita disponible en la agenda del médico.
          </p>
        </div>
      </div>

      <div class="col-xl-6 col-md-6 col-xs-12" title="click aqui!" (click)="selectOption(true)"
        [class.no-pointer]="disableIncome">
        <div class="card_income" [class.income_disable]="disableIncome">
          <div class="align_icon">
            <i class="font_income_icon mdi mdi-calendar-remove"></i>
          </div>
          <div class="card_income_title">Registrar Ingreso</div>
          <p class="card_income_body">
            Los ingresos no requieren disponibilidad en la agenda del médico,
            pero se registran con la fecha y hora actuales.
          </p>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #normalFlow>
    <div *ngIf="income == undefined" [id]="idInput" class="row mt-4">
      <div class="col-xl-6 col-md-6 col-xs-12" title="click aqui!" (click)="selectOption(false)">
        <div class="card_income">
          <div class="align_icon">
            <i class="font_income_icon mdi mdi-calendar-check"></i>
          </div>
          <div class="card_income_title">Solicitar Cita</div>
          <p class="card_income_body">
            Por favor, selecciona una cita disponible en la agenda del médico.
          </p>
        </div>
      </div>

      <div class="col-xl-6 col-md-6 col-xs-12" title="click aqui!" (click)="selectOption(true)"
        [class.no-pointer]="disableIncome">
        <div class="card_income" [class.income_disable]="disableIncome">
          <div class="align_icon">
            <i class="font_income_icon mdi mdi-calendar-remove"></i>
          </div>
          <div class="card_income_title">Registrar Ingreso</div>
          <p class="card_income_body">
            Los ingresos no requieren disponibilidad en la agenda del médico,
            pero se registran con la fecha y hora actuales.
          </p>
        </div>
      </div>
    </div>
  </ng-template>

  <div *ngIf="income != undefined">
    <div class="mb-4 pointer" (click)="income = undefined">
      <custom-buttons type="button" class="mr-3 animated fadeIn" textColor="#fff" [title]="'Regresar'"
        icon="ti ti-arrow-left"></custom-buttons>
      <span class="fw_500 fz_20">{{
        income ? "Registrar Ingreso" : "Solicitar Cita"
        }}</span>
    </div>

    <div class="mb-4">
      <div class="config_container">
        <div class="config_subtitle branch_selected_title mb-2">
          Sucursal, {{ branchSelected.branchName }}
        </div>
      </div>
    </div>

    <div class="row">
      <div *ngIf="activeSubService.length === 0" class="col-12">
        <app-custom-message [type]="'warning'" [titleMessage]="'Advertencia!!'"
          [bodyMessage]="'la sucursal seleccionada no tiene servicios activos'">
        </app-custom-message>
      </div>

      <div id="idColCitaUser" class="col-md-5 col-xs-12">
        <form *ngIf="activeSubService.length > 0" class="mt-2 animated fadeIn" id="idCitaUser" [formGroup]="citasForm">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <form-select [id]="'idInputServicio'" helper="Selecciona un servicio de la lista" label="Servicio"
                  placeholder="Seleccione..." [dataList]="activeSubService" [controlName]="'servicio'"
                  [idField]="'subServiceId'" [valueField]="'subServiceName'"></form-select>
              </div>
            </div>

            <div class="col-12" *ngIf="doctors.length > 0">
              <div class="form-group">
                <form-select [id]="'idInputMedico'" helper="Selecciona un médico de la lista" label="Médico"
                  placeholder="Seleccione..." [dataList]="doctors" [controlName]="'medico'" [idField]="'doctorId'"
                  [valueField]="'doctorNames'"></form-select>
              </div>
            </div>

            <div class="col-12" *ngIf="doctors.length > 0">
              <div class="form-group">
                <form-input [id]="'idInputMotivo'" label="Motivo de Consulta"
                  placeholder="Ejm: dolor persistente en mi ojo izquierdo" [controlName]="'motivo'"></form-input>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div *ngIf="income && activeSubService.length > 0" class="col-md-7 col-xs-12">
        <div class="confirm_cita animated fadeInRight">
          <ng-lottie [options]="options" style="height: 170px"></ng-lottie>
          <div class="title_confirm">Confirmación</div>
          <div *ngIf="errorCita == ''" class="subtitle_confirm">
            ¿Estas seguro que deseas registrar el ingreso?
            <div style="color: rgb(158, 157, 157)">{{ fechaIncomeText }}</div>
          </div>

          <div *ngIf="errorCita != ''" class="subtitle_confirm" style="color: rgb(255, 78, 78)">
            {{ errorCita }}
          </div>
          <!-- *ngIf="case !== 'case6' && case !== 'case5' && case !== 'case3'"  -->
          <div class="image-checkbox-wrapper">
            <input type="checkbox" [checked]="ordenDescuento" [id]="'basic_checkbox_orden'"
              class="material-inputs chk-col-teal" (change)="onCheckboxChange($event)" />
            <label [for]="'basic_checkbox_orden'">Enviar Orden de descuento por whatsapp al paciente.</label>
          </div>

          <div class="settings_btn mt-4 mb-4">
            <button class="btn btn-danger mr-4" (click)="cancelarCita()">
              Cancelar Ingreso
            </button>
            <button class="btn btn-success" [disabled]="errorCita != ''" (click)="confirmarIncome()">
              Confirmar Ingreso
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="!income && activeSubService.length > 0" class="col-md-7 col-xs-12 settings_btn">
        <ng-container *ngIf="confirmCita == false">
          <div *ngIf="doctorSelected" class="animated fadeIn" [ngClass]="{ show: onViewDaylicalendar }">
            <custom-calendar [inputData]="inputData" [endPointSchedules]="endPointCalendar"
              (payloadData)="calendarEvent($event)" (errorNoData)="errorNoData()"
              (onDayliView)="onViewDaylicalendar = $event"></custom-calendar>
          </div>
        </ng-container>

        <ng-container *ngIf="confirmCita == true">
          <div class="confirm_cita animated fadeInRight">
            <ng-lottie [options]="options" style="height: 170px"></ng-lottie>
            <div class="title_confirm">Confirmación</div>
            <div class="subtitle_confirm" *ngIf="appointment && appointment.appointmentDate && appointment.hour">
              ¿Estas seguro que deseas registrar la cita?
              <div style="color: rgb(158, 157, 157)">
                {{ appointment.appointmentDate }}, a las {{ appointment.hour }}<span *ngIf="appointment.office">en el
                  consultorio {{ appointment.office }}</span>
              </div>
            </div>

            <div>
              <!-- <button (click)="captureTicket()">Capturar Ticket</button> -->
              <div class="image-checkbox-wrapper">
                <input type="checkbox" [checked]="ordenDescuento" [id]="'basic_checkbox_orden'"
                  class="material-inputs chk-col-teal" (change)="onCheckboxChange($event)" />
                <label [for]="'basic_checkbox_orden'">Enviar Orden de descuento por whatsapp al paciente.</label>
              </div>
            </div>

            <div *ngIf="appointmentType !== 'Veterinary'" class="settings_btn mt-4 mb-4">
              <custom-buttons class="mr-4" (click)="cancelarCita()" [text]="'Cancelar Cita'"
                [inputBackgroundColor]="'danger'" [title]="'Cancelar'"></custom-buttons>
              <custom-buttons (click)="confirmarCita()" [text]="'Confirmar Cita'"
                [title]="'Confirmar'"></custom-buttons>
            </div>

            <div *ngIf="appointmentType === 'Veterinary'" class="settings_btn mt-4 mb-4">
              <custom-buttons class="mr-4" (click)="cancelarCita()" [text]="'Cancelar Cita'"
                [inputBackgroundColor]="'danger'" [title]="'Cancelar'"></custom-buttons>
              <custom-buttons (click)="confirmarCitaPets()" [text]="'Confirmar Cita Mascota'"
                [title]="'Confirmar'"></custom-buttons>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div *ngIf="ordenDescuento">
    <div class="visual-orden-descuento mt-4">
      <!-- <app-form-section [number]="pasosInput + ''" [sectionName]="'Orden de Descuento'"></app-form-section> -->
      <div class="align_center mt-4">
        <app-pet-ticket *ngIf="appointmentType === 'Veterinary'" [infoPatient]="infoPatient" [infoPet]="infoPet"
          [infoAppointment]="infoAppointment"></app-pet-ticket>

        <app-patient-ticket *ngIf="appointmentType !== 'Veterinary'" [infoPatient]="infoPatient"
          [infoAppointment]="infoAppointment"></app-patient-ticket>

        <canvas #canvasEl style="display: none"></canvas>
      </div>
    </div>
  </div>

</div>
