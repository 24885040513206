import { Component ,Input, OnInit, Output,ViewChild,TemplateRef,EventEmitter} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Experience } from '@interfaces/information.interface';
import { StatesNgrxService, SweetAlertService } from '@services';
import { UserService } from 'src/app/roles/services/user.service';


@Component({
  selector: 'app-experience-list',
  templateUrl: './experience-list.component.html',
  styleUrls: ['./experience-list.component.scss'],
})
export class ExperienceListComponent implements OnInit {
  @Input() experiences: Experience[] = [];

  @Output() experienceEmmiter: EventEmitter<Experience[]> = new EventEmitter<Experience[]>();
  @Output() regresarEmmiter: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('experienceList') experienceList!: TemplateRef<any>;
  @ViewChild('experienceEdit') experienceEdit!: TemplateRef<any>;

  public currentView!: TemplateRef<any>;
  public experienceSelected?: Experience;
  public experienceForm!: FormGroup;
  public customErrors: Record<string, string> = {};

  units = [
    { value: 'years', name: 'Años' },
    { value: 'months', name: 'Meses' },
    { value: 'days', name: 'Dias' },
  ];

  constructor(private swalService: SweetAlertService,
              private userService:UserService,
              private statesNgrxService:StatesNgrxService) {

    this.customErrors['menorUno'] =
      'El campo  no puede ser menor o igual a cero';
  }

  ngOnInit(): void {

    setTimeout(() => {
      this.currentView = this.experienceList;
    }, 200);

    // //console.log(this.experiences);
  }

  changeTab(tab: string) {
    switch (tab) {
      case 'experienceList':
        this.currentView = this.experienceList;
        break;
      case 'experienceEdit':
        this.currentView = this.experienceEdit;
        break;
    }
  }

  /**
   * Selecciona una experience y emite un evento para indicar que no se debe regresar.
   * @param {experience} experience - experience seleccionada.
   */
  selectExperience(experience: Experience): void {
    // //console.log(study);

    this.inicializarExperienceForm(experience);

    setTimeout(() => {
      this.regresarEmmiter.emit(false);
      this.currentView = this.experienceEdit;
      this.experienceSelected = experience;
    }, 100);
  }

  convertirExperienciasADias(experiences: Experience[]): number {
    let totalDays = 0;

    for (const experience of experiences) {
      if (experience.timeExperience && experience.timeExperience > 0) {
        if (experience.unit === 'days') {
          totalDays += experience.timeExperience * 1;
        } else if (experience.unit === 'months') {
          totalDays += experience.timeExperience * 30; // Suponiendo 30 días por mes
        } else if (experience.unit === 'years') {
          totalDays += experience.timeExperience * 365; // Suponiendo 365 días por año
        }
      }
    }

    return totalDays;
  }

  getExperienciaTotal(experiences: Experience[]): string {
    const totalDays = this.convertirExperienciasADias(experiences);

    // Calcular años, meses y días a partir de los días
    let totalYears = 0;
    let totalMonths = 0;
    let totalRemainingDays = totalDays;

    // Calcular años completos
    totalYears = Math.floor(totalRemainingDays / 365);
    totalRemainingDays -= totalYears * 365;

    // Calcular meses completos
    totalMonths = Math.floor(totalRemainingDays / 30);
    totalRemainingDays -= totalMonths * 30;

    // Construir la cadena de experiencia total
    let experienciaTotal = '';
    if (totalYears > 0) {
      experienciaTotal += `${totalYears} año${totalYears > 1 ? 's' : ''}`;
    }
    if (totalMonths > 0) {
      experienciaTotal += ` ${totalMonths} mes${totalMonths > 1 ? 'es' : ''}`;
    }
    if (totalRemainingDays > 0) {
      experienciaTotal += ` ${totalRemainingDays} día${
        totalRemainingDays > 1 ? 's' : ''
      }`;
    }

    return experienciaTotal.trim();
  }

  /**
   * Limpia la selección de branch y emite un evento para indicar que se debe regresar.
   */
  regresarExperiencias(): void {
    this.experienceSelected = undefined;
    this.regresarEmmiter.emit(true);
    this.currentView = this.experienceList;
  }  

  async iniciarExperienceAgregateForm() {
    this.experienceForm = new FormGroup({
      description: new FormControl<string | null>(null, Validators.required),
      companyName: new FormControl<string | null>(null, Validators.required),
      municipality: new FormControl<string | null>(null, Validators.required),
      timeExperience: new FormControl<number | null>(null, Validators.required),
      unit: new FormControl<string | null>(null, Validators.required),
    });

    this.currentView = this.experienceEdit;
  }

  async inicializarExperienceForm(experience: Experience) {
    this.experienceForm = new FormGroup({
      description: new FormControl<string | null>(
        experience.description || null,
        Validators.required
      ),
      companyName: new FormControl<string | null>(
        experience.companyName || null,
        Validators.required
      ),
      municipality: new FormControl<string | null>(
        experience.municipality || null,
        Validators.required
      ),
      timeExperience: new FormControl<number | null>(
        experience.timeExperience || null,
        Validators.required
      ),
      unit: new FormControl<string | null>(
        experience.unit || null,
        Validators.required
      ),
    });
  }

  hacerScroll(id: string) {
    var elementos = document.querySelectorAll(`#${id}`);
    const margenTop = 120;

    if (elementos.length > 0) {
      var elementoObjetivo = elementos[0];
      var posicion = elementoObjetivo.getBoundingClientRect();

      window.scrollTo({
        top: window.pageYOffset + posicion.top - margenTop,
        behavior: 'smooth',
      });
    }
  }

  getExperienciaTiempo(experience: Experience): string {
    let nExperiencia = '0 días';
    if (experience.unit == 'days') {
      nExperiencia = `${experience.timeExperience} día`;
      if (experience.timeExperience != 1) {
        nExperiencia = nExperiencia + 's';
      }
    } else if (experience.unit == 'months') {
      nExperiencia = `${experience.timeExperience} mes`;
      if (experience.timeExperience != 1) {
        nExperiencia = nExperiencia + 'es';
      }
    } else {
      nExperiencia = `${experience.timeExperience} año`;
      if (experience.timeExperience != 1) {
        nExperiencia = nExperiencia + 's';
      }
    }
    return nExperiencia;
  }

  

  async agregateExperience(form: FormGroup) {
    if (this.experienceForm.controls['timeExperience'].value < 1) {
      this.experienceForm.controls['timeExperience'].setErrors({
        menorUno: true,
      });
    }

    if (form.valid) {
      const experienceAgregate: Experience = {
        companyName: this.experienceForm.value.companyName,
        description: this.experienceForm.value.description,
        municipality: this.experienceForm.value.municipality,
        timeExperience: this.experienceForm.value.timeExperience,
        unit: this.experienceForm.value.unit,
      };

      const result = await Swal.fire({
        title: 'Estás seguro?',
        text: `Se va a agregar ${experienceAgregate.description} en la empresa ${experienceAgregate.companyName} a su experiencia laboral`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, agregar!',
        cancelButtonText: 'Cancelar',
      });

      if (result.isConfirmed) {
        
        this.userService.createExperience(experienceAgregate).then(resp=>{          
          if (resp.ok) {
            this.statesNgrxService.getDataGlobalUser().subscribe((resp:any)=>{                   
              if (!resp) {
                return //console.log('Error');                
              }        
              this.statesNgrxService.updateGlobalUser();
              this.currentView = this.experienceList;  
            });
            setTimeout(() => {
              this.experienceEmmiter.emit(this.experiences);
              this.hacerScroll('pills-tab');
            }, 200);   
          }
          
        }).catch(err=>{
          //console.log(err);          
        });
      }
    } else {
      this.experienceForm.markAllAsTouched();
      const requiredFields = [
        'description',
        'companyName',
        'municipality',
        'timeExperience',
        'unit',
      ];

      const swalResult = await this.swalService.lauchSwalAsync(
        '',
        'Debes registrar los campos en color rojo, ya que son obligatorios',
        'warning'
      );
      if (swalResult) {
        setTimeout(() => {
          const scrollTo = requiredFields.find(
            (field) => this.experienceForm.controls[field].invalid
          );
          if (scrollTo) {
            this.hacerScroll(
              `idInput${scrollTo.charAt(0).toUpperCase() + scrollTo.slice(1)}`
            );
          }
        }, 300);
      }
    }
  }


  async updateExperience( form: FormGroup) {
    if (this.experienceSelected) {      
      if (this.experienceForm.controls['timeExperience'].value < 1) {
        this.experienceForm.controls['timeExperience'].setErrors({
          menorUno: true,
        });
      }
      if (form.valid) {
        const experienceAgregate: Experience = {
          experiencieId:this.experienceSelected._id,
          companyName: this.experienceForm.value.companyName,
          description: this.experienceForm.value.description,
          municipality: this.experienceForm.value.municipality,
          timeExperience: this.experienceForm.value.timeExperience,
          unit: this.experienceForm.value.unit,
        };

        const result = await Swal.fire({
          title: 'Estás seguro?',
          text: `Se va a actualizar ${experienceAgregate.description}`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, actualizar!',
          cancelButtonText: 'Cancelar',
        });

        if (result.isConfirmed) {
          
          this.userService.updateExperience(experienceAgregate).then(resp=>{                 
            if (resp.ok) {
              //console.log(resp);
              
              this.statesNgrxService.getDataGlobalUser().subscribe((resp:any)=>{                   
                if (!resp) {
                  return //console.log('Error');                
                }        
                this.statesNgrxService.updateGlobalUser();
                this.currentView = this.experienceList;  
                setTimeout(() => {
                  this.experienceEmmiter.emit(this.experiences);
                  this.hacerScroll('pills-tab');
                }, 200);   
              });
            }
            
          }).catch(err=>{
            //console.log(err);          
          });
        }
      } else {
        this.experienceForm.markAllAsTouched();
        const requiredFields = [
          'description',
          'companyName',
          'municipality',
          'timeExperience',
          'unit',
        ];

        const swalResult = await this.swalService.lauchSwalAsync(
          '',
          'Debes registrar los campos en color rojo, ya que son obligatorios',
          'warning'
        );
        if (swalResult) {
          setTimeout(() => {
            const scrollTo = requiredFields.find(
              (field) => this.experienceForm.controls[field].invalid
            );
            if (scrollTo) {
              this.hacerScroll(
                `idInput${scrollTo.charAt(0).toUpperCase() + scrollTo.slice(1)}`
              );
            }
          }, 300);
        }
      }

    }
  }

  deleteExperience(experience: Experience): void {
    Swal.fire({
      title: 'Estás seguro?',
      text: `Se va a eliminar ${experience.description} en la empresa ${experience.companyName} de su experiencia laboral`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar Experiencia!',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        
        const _id= experience._id ||'';

        this.userService.deleteExperience(_id)
        .then(resp=>{
          if (resp.ok) {
            this.statesNgrxService.getDataGlobalUser().subscribe((resp:any)=>{                   
              if (!resp) {
                return //console.log('Error');                
              }        
              this.statesNgrxService.updateGlobalUser(); 
            });
            setTimeout(() => {
              this.experienceEmmiter.emit(this.experiences);
              this.hacerScroll('pills-tab');
            }, 200);              
          }
        })
        .catch(err=>{
          //console.log(err);          
        })
      }
    });
  }
  
}
