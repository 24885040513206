<span class="input__group">
  <div class="box__label">
    <label class="input__label" [style.color]="labelColor" [style.fontSize]="labelFontSize" for="input_component">{{label}} {{ strength?.label }}</label>
    <i class="fa fa-question icon__helper" [title]="helper" *ngIf="helper"></i>
  </div>
  <div class="box__input">
    <input #inputRef class="input__form" [type]="showPassword?'text':'password'" name="input_component" [placeholder]="placeholder" autocomplete="off" [formControl]="control" [ngClass]="{invalid: control.touched && control.invalid}">
    <i class="fa fa-close icon__clear" *ngIf="!control.disabled" [ngClass]="{visible: inputRef.value != ''}" (click)="control.setValue('')" title="Limpiar"></i>
    <i class="fa icon__password" [ngClass]="{'fa-eye-slash': showPassword,'fa-eye': !showPassword,visible: inputRef.value != ''}" (click)="showPassword=!showPassword" [title]="showPassword?'Ocultar contraseña':'Ver contraseña'"></i>
    <div class="strengthmeter" *ngIf="showStrengthmeter" >
      <span class="strengthmeter__measure" [ngClass]="strength?.strength"></span>
      <span class="strengthmeter__measure" [ngClass]="strength?.strength !== 'weak' ? strength?.strength : ''"></span>
      <span class="strengthmeter__measure" [ngClass]="strength?.strength.includes( 'strong'||'very__strong') ? strength?.strength : ''"></span>
      <span class="strengthmeter__measure" [ngClass]="strength?.strength === 'very__strong' ? 'very__strong' : ''"></span>
      <span class="strengthmeter__label">contraseña {{ strength?.label }}</span>
    </div>
  </div>
  <div class="box__error" [ngClass]="{visible:errors.length}">
    <ul>
      <li class="box__error__item" *ngFor="let error of errors"><strong>* </strong>{{error}}</li>
    </ul>
  </div>
</span>