import { AfterViewInit, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';


@Component({
  selector: 'task-info',
  templateUrl: './task-info.component.html',
  styleUrls: ['./task-info.component.scss']
})
export class TaskInfoComponent implements AfterViewInit {
  @Input() dataInfo: any
  @Output() closeTaskInfo = new EventEmitter<boolean>()

  private visible: boolean = false

  @HostListener('window:click', ['$event'])
  onClick($event: Event) {
    const inside = (<HTMLElement>$event.target).closest('.task__container')
    this.visible && !inside && this.closeTaskInfo.emit()
  }

  ngAfterViewInit() {
    setTimeout(() => this.visible = true, 100);
  }
}
