<button class="btn btn-block custom-button "
  [ngStyle]="{
    'color': textColor,
    'font-size': textSize,
    'width': width,
    'height': height,
    'background-color': backgroundColor,
    'border-radius': rounded ? '25px' : '13px',
    'margin': '2px'
  }"
  [type]="type"
  [disabled]="disabled || isLoading"
  [title]="title"
  >

  <ng-container *ngIf="!isLoading; else loading">
    {{ text }}
    <ng-container *ngIf="icon">
      <i class="{{ icon }}"
      [ngStyle]="{
        'top': '5px',
        'color': textColor,
        'font-size': fontSizeIcon ? fontSizeIcon : '0.95em' ,
        'margin-top': '3px',
        'margin-buttom': '5px'

      }"></i>
    </ng-container>
  </ng-container>
  <ng-template #loading>
    <i class="fa fa-spinner fa-spin text-white"></i>
  </ng-template>
</button>
