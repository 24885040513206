import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TabVerticalComponentComponent } from '../tab-vertical-component/tab-vertical-component.component';

@Component({
  selector: 'app-tab-vertical-content-component',
  templateUrl: './tab-vertical-content-component.component.html',
  styleUrls: ['./tab-vertical-content-component.component.scss'],
  standalone: true,
  imports: [CommonModule, TabVerticalComponentComponent],
})
export class TabVerticalContentComponentComponent {
  @Input() content: any;
}
