import { createAction, props } from "@ngrx/store";
import { loginData } from "src/app/interfaces/auth.interface";
import { RolSession } from "src/app/interfaces/session.interface";

export const initSession = createAction(
    '[Session] Init Session', 
    props<{ sessionData: loginData }>()
); 

export const selectRol = createAction(
    '[Session] Select Rol', 
    props<{ sessionData: loginData, rolSession: RolSession }>()
); 

export const reloadState = createAction(  
    '[Session] Reload Session State',
    props<{ sessionData: loginData, rolSession: RolSession }>()
);

export const reloadStateWithStorage = createAction(
    '[Session] Reload State And Set Storage', 
    props<{ sessionData: loginData, rolSession: RolSession }>()
); 

export const serviceType = createAction(
    '[Session] Set serviceType',
    props<{ typeOfProviver: 'Laboratory' | 'Services' | 'Imaging' | 'Veterinary' | null }>()
);