<main class="container-modal">
  <div class="container-form">
    <form [formGroup]="formFamilyGroupAndPet" class="form-form">
      <h3 class="title-family-group"> Datos del Nuevo Grupo Familiar</h3>


      <!-- Nombre grupo familiar -->
      <form-input helper="" label="Nombre del Grupo Familiar" placeholder="" [controlName]="'nameFamily'"
        [type]="'text'">
      </form-input>

      <h3 class="title-family-group"> Datos de la Mascota </h3>

      <div class="container-input">
        <!-- Nombre de la mascota  -->
        <form-input helper="" label="Nombre de la Mascota" placeholder="Ejm: Lucas" [controlName]="'petName'"
          [type]="'text'">
        </form-input>

        <!-- Especie  -->
        <form-input helper="" label="Especie de la Mascota" placeholder="Ejm: Perro, Gato, Pez, etc... "
          [controlName]="'species'" [type]="'text'">
        </form-input>

      </div>
      <div class="container-input">
        <!-- Raza -->
        <form-input helper="" label="Raza" placeholder="Ejm: Bulldog, Persa, etc..." [controlName]="'breed'"
          [type]="'text'">
        </form-input>
        <!-- Genero -->
        <form-select helper="Selecciona un genero..." label="Genero" placeholder="Seleccione..." [dataList]="genders"
          [controlName]="'gender'" [idField]="'value'" [valueField]="'name'">
        </form-select>
      </div>
      <div class="container-input">
        <!-- Color -->
        <form-input helper="" label="Color de la Mascota" placeholder="Ejm: Bulldog, Persa, etc..."
          [controlName]="'color'" [type]="'text'">
        </form-input>
        <!-- Estirilizado? -->
        <form-select helper="Selecciona un genero..." label="Fue Esterilizado/a" placeholder="Seleccione..."
          [dataList]="sterilizationStates" [controlName]="'sterilized'" [idField]="'value'" [valueField]="'name'">
        </form-select>
      </div>
      <div class="container-input">
        <!-- Fecha de Nacimiento-->
        <form-date label="Fecha de Nacimiento" [required]="false" [maxNow]="true" [controlName]="'birth_date'">
        </form-date>
        <!-- Edad Actual-->
        <form-input helper="" [type]="'number'" [required]="false" label="Edad Actual" placeholder="Edad en años"
          [controlName]="'age'">
        </form-input>
      </div>
      <button class="button-continue" (click)="validateAndCreate()"> Crear</button>

      <!-- <custom-buttons class="button-continue" (click)="validateAndCreate()" text="Crear" textColor="#fff" [title]="''">
      </custom-buttons> -->

    </form>
  </div>

</main>
