import { Injectable } from '@angular/core';
import { ApiRes } from '@interfaces/information.interface';
import { ApiService } from '@services';
import { Observable, firstValueFrom, from, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdvisorService {

  constructor(private apiService: ApiService) { }

  async getApprovedCompanies() {
    let response = await firstValueFrom(this.apiService.getRequest(`advisor/approved-companies`));
    return response;
  }

  async postApprovedCompanies(body: any) {
    let response = await firstValueFrom(this.apiService.postRequest(`advisor/approved-companies`, body));
    return response;
  }

  async getInfoMemberCompany(numDoc: string, type: string) {
    let response = await firstValueFrom(this.apiService.getRequest(`advisor/info/${numDoc}/${type}`));
    return response;
  }

  // Peticion GET para obtener banners
  getBanner() {
    return this.apiService.getRequest(`advisor/banner-package`).pipe(
      map((response: any) => {
        return response.body
      })
    )
  }

  // Peticion POST para enviar banner
  async uploadBanner(formData: FormData) {

    let response = await firstValueFrom(this.apiService.postRequestFile('advisor/banner', formData));
    return response;

  }

  // Peticion PUT para actualizar el orden de los banners
  async updateImagePositions(updateInfo: any){
    let response = await firstValueFrom(this.apiService.putRequest('advisor/sort-banners', updateInfo));
    return response;
  }

  // Peticion DELETE para eliminar banner
  deleteBanner(idBanner: number, idGroup: number) {
    return this.apiService.deleteRequest(`advisor/banner/${idBanner}/${idGroup}`).pipe(
      map((response: any) => {
        return response
      })
    );
  }

  // Post Campaña
  async uploadCampaign(data: any) {
    let response = await firstValueFrom(this.apiService.postRequestFile('advisor/campaing', data));
    return response;
  }

  // Get Campaña
  async getCampaign() {
    let response = await firstValueFrom(this.apiService.getRequest(`advisor/campaings`));
    return response;
  }

  // Put Campaña
  async updateCampaing(updateInfo: any) {
    let response = await firstValueFrom(this.apiService.putRequest('advisor/campaing', updateInfo));
    return response;
  }

  // Get userTest para campaña
  async getUserTest() {
    let response = await firstValueFrom(this.apiService.getRequest(`advisor/test-users`));
    return response;
  }

  // Post userTest para campaña
  async uploadUserTest(idUser: any) {
    let response = await firstValueFrom(this.apiService.postRequest(`advisor/test-user/${idUser}`, ''));
    return response;
  }

  // Post Envio de Campañas
  async sendCampaignTest(campaing: any) {
    let response = await firstValueFrom(this.apiService.postRequest('advisor/send-campaing', campaing));
    return response;
  }

  // Delete Campaña
  async deleteCampaign(idCampaign: any) {
    let response = await firstValueFrom(this.apiService.deleteRequest(`advisor/campaing/${idCampaign}`));
    return response;
  }

  // Delete usuario de prueba
  async deleteUserTest(idUser: any) {
    let response = await firstValueFrom(this.apiService.deleteRequest(`advisor/test-users/${idUser}`));
    return response;
  }
}
