import { Component, OnInit } from '@angular/core';
import { StatesNgrxService } from './services/states-ngrx.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'descuentosMedicosSocialWeb2.0';

  constructor(private states: StatesNgrxService) {}

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.states.initState();
  }

}
