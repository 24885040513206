import { Component, Input, AfterViewInit, ViewEncapsulation  } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['../../form-styles.scss'],
})
export class FormInputComponent implements AfterViewInit {
  @Input() controlName!: string;
  @Input() placeholder: string = 'Default placeholder';
  @Input() helper: string | undefined = undefined;
  @Input() label: string = 'Default label';
  @Input() labelColor!: string;
  @Input() labelFontSize: string = '1rem'
  @Input() required: boolean = true;
  @Input() type: string = 'text';
  @Input() idInput: string = 'idInput';
  @Input() customErrors: Record<string, string> = {};
  // @Input() disabled: boolean = true;

  // @Input() name: string = 'Default name';
  // @Input() id: string = 'defaultId';
  // @Input() type: string = 'text';

  errors: any[] = [];
  value: any;
  disable: boolean = false;

  constructor(private controlContainer: ControlContainer) {}

  get control(): FormControl {
    const parentFormGroup = this.controlContainer as FormGroupDirective;
    return parentFormGroup.control.get(this.controlName) as FormControl;
  }

  get controlInvalid(): boolean {
    return this.control?.invalid || false;
  }

  get controlTouched(): boolean {
    return this.control?.touched || false;
  }

  get errorKeys(): string[] {
    return this.control?.errors ? Object.keys(this.control.errors) : [];
  }

  ngAfterViewInit(): void {
    this.control.statusChanges.subscribe(() => {
      this.errors = [];
      if (!this.control.errors || !this.control.dirty) return;

      if (this.customErrors) {
        let hasErrors = Object.keys(this.customErrors).filter((err) =>
          Object(this.control.errors).hasOwnProperty(err)
        );
        hasErrors.forEach(
          (error) =>
            this.control.errors &&
            this.control.errors[error] &&
            this.errors.push(this.customErrors[error])
        );
      }

      this.control.errors['required'] &&
        this.errors.push(`El campo es requerido`);
      this.control.errors['minlength'] && this.errors.push(
          `El campo nó debe tener menos de ${this.control.errors['minlength'].requiredLength} caracteres`
        );
      this.control.errors['maxlength'] && this.errors.push(
          `El campo nó debe tener mas de ${this.control.errors['maxlength'].requiredLength} caracteres`
        );
      this.control.errors['email'] && this.errors.push(`El correo electrónico debe ser una dirección válida`);
      this.control.errors['pattern'] && this.errors.push(`El campo no corresponde a un patrón válido`);
    });
  }

  onBlur() {
    this.control.markAsDirty()
    this.control.updateValueAndValidity();
  }

}
