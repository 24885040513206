import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';

import { DashboardComponent } from './dashboard/dashboard.component';
import { ProgressComponent } from './progress/progress.component';
import { Grafica1Component } from './grafica1/grafica1.component';
import { PagesComponent } from './pages.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { PagesRoutingModule } from './pages.routing.module';
import { RolSelectorComponent } from './rol-selector/rol-selector.component';
import { ReusableModalModule } from '../reusable-modal/reusable-modal.module';
import { StoreModule } from '@ngrx/store';
import { sessionReducer, sessionStateFeatureKey } from './session/state/session.reducers';
import { EffectsModule } from '@ngrx/effects';
import { SessionEffects } from './session/state/session.effects';
import { DirectivesModule } from '../directives/directives.module';
import { branchesReducer, branchesStateFeatureKey } from '../provider-shared/components/state/branches.reducer';
import { BranchesEffects } from '../provider-shared/components/state/branches.effects';

@NgModule({
  declarations: [
    DashboardComponent,
    ProgressComponent,
    Grafica1Component,
    PagesComponent,
    AccountSettingsComponent,
    RolSelectorComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    RouterModule,
    PagesRoutingModule,
    ReusableModalModule,
    DirectivesModule,
    StoreModule.forFeature(branchesStateFeatureKey, branchesReducer),
    StoreModule.forFeature(sessionStateFeatureKey, sessionReducer), 
    EffectsModule.forFeature([SessionEffects, BranchesEffects])
  ],
  exports: [ReusableModalModule],
})
export class PagesModule {}
