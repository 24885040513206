import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuWeb } from '@interfaces/auth.interface';

@Component({
  selector: 'app-permissions-manager',
  templateUrl: './permissions-manager.component.html',
  styleUrls: ['./permissions-manager.component.scss']
})
export class PermissionsManagerComponent {

  @Input()menuWeb: MenuWeb[] = [];
  @Input()inputWidth= 400;

  @Output() menuWebEmitter = new EventEmitter<MenuWeb[]>;

  setCheckedModul(event:any,m:number){
    let isChecked = (event.target as HTMLInputElement).checked
    this.menuWeb[m].active=isChecked;
    this.menuWebEmitter.emit(this.menuWeb);
  }

  setCheckedOptionView(event:any,m:number,o:number,mn:number){
    let isChecked = (event.target as HTMLInputElement).checked
    this.menuWeb[m].options[o].subMenu[mn].view=isChecked;
    this.menuWebEmitter.emit(this.menuWeb);
    
  }

  setCheckedOptionPermissions(event: any, permissionName: string, m: number, o: number, mn: number) {
      let isChecked = (event.target as HTMLInputElement).checked;
      
      switch (permissionName) {
          case 'create':
              this.menuWeb[m].options[o].subMenu[mn].permissions.create = isChecked;
              break;
          case 'update':
              this.menuWeb[m].options[o].subMenu[mn].permissions.update = isChecked;
              break;
          case 'delete':
              this.menuWeb[m].options[o].subMenu[mn].permissions.delete = isChecked;
              break;
          default:
              console.error('Nombre de permiso no válido:', permissionName);
              return; // Salimos de la función si el nombre de permiso no es válido
      }
  
      this.menuWebEmitter.emit(this.menuWeb);
  }

}
