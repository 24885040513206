import { Pipe, PipeTransform } from '@angular/core';
import { get } from "lodash";

@Pipe({
  name: 'dataObject'
})
export class DataObjectPipe implements PipeTransform {

  transform(obj: Object, keyArgs: string) {
    return get(obj, keyArgs);
  }
}
