import { ChangeDetectionStrategy, Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-tables',
  templateUrl: './tables.component.html',
  styleUrls: ['./tables.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class TablesComponent implements OnInit {
  @Input() headers: string[] = [];
  @Input() data: any[] = [];
  @Input() actions: any[] = [];
  @Input() status: any[] = [];

  sortColumn: string = '';
  sortDirection: boolean = true;

  showAvatarColumn: boolean = false;
  showStatusColumn: boolean = false;
  showActionsColumn: boolean = false;

  ngOnInit(): void {
    this.showAvatarColumn = this.data.some(row => row.tabAvatar);
    this.showStatusColumn = this.data.some(row => row.tabStatusColor || row.tabStatus);
    this.showActionsColumn = this.data.some(row => row.tabActions && row.tabActions.length > 0);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] && !changes['data'].isFirstChange()) {
      this.updateTableData(changes['data'].currentValue);
    }
  }
  updateTableData(data: any[]) {
    // Actualiza los datos de la tabla si es necesario
    this.data = [...data];
  }
  sortData(header: string) {
    if (this.sortColumn === header) {
      this.sortDirection = !this.sortDirection;
    } else {
      this.sortDirection = true;
      this.sortColumn = header;
    }

    this.data.sort((a, b) => {
      const valueA = a[header];
      const valueB = b[header];

      let comparison = 0;
      if (valueA > valueB) {
        comparison = 1;
      } else if (valueA < valueB) {
        comparison = -1;
      }

      return this.sortDirection ? comparison : -comparison;
    });
  }

  executeAction(action: any, rowData: any) {
    if (action && action.callback) {
      action.callback(rowData);
    }
  }
}
