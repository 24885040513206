import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HolidaysService } from '@services';

import { TaskInfoComponent } from './task-info/task-info.component';
import { NgOptimizedImage } from '@angular/common'

import { CustomCalendarExtendedComponent } from './custom-calendar-extended.component';
import { CustomTooltipDirective } from './directives/custom-tooltip.directive';
import { DialogPopupComponent } from './dialog-popup/dialog-popup.component';
import { FormatHourPipe } from './pipes/format-hour.pipe';

import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { DayInfoComponent } from './day-info/day-info.component';

const componentList = [
  CustomCalendarExtendedComponent,
  CustomTooltipDirective,
  TaskInfoComponent
]

export function playerFactory() {
  return player;
}

@NgModule({
  imports: [
    CommonModule,
    NgOptimizedImage,
    LottieModule.forRoot({ player: playerFactory })
  ],
  declarations: [...componentList, DialogPopupComponent, FormatHourPipe, DayInfoComponent],
  exports: [...componentList],
  providers: [HolidaysService, BrowserModule]
})
export class CustomCalendarExtendedModule { }
