import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import Swal from 'sweetalert2';
import { ProfileService } from 'src/app/roles/profile/profile.service';
import { StatesNgrxService } from 'src/app/services/states-ngrx.service';

@Component({
  selector: 'app-image-crop',
  templateUrl: './image-crop.component.html',
  styleUrls: ['./image-crop.component.scss']
})
export class ImageCropComponent implements OnInit {
  
  @Input() set fileComponent(_fileComponent: File) {
    this._fileComponent = _fileComponent;
  }

  get fileComponent(): File {
    return this._fileComponent;
  }

  private _fileComponent!: File;

  @Input()user!: any;
  @Input()type!: string;
  @Input()role!: string;
  @Input()aspectInput?:number;
  @Input()branchId?:string;
  @Input()viewHeader = false;
  @Input()minHeight = true;


  //config
  @Input()postFile = true;

  
  @Output() sendEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() regresarEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() fileSendEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() cropperImageEmmiter: EventEmitter<File> = new EventEmitter<File>();
  
  selectedFile: File | null = null;
  
  imageChangedEvent: any = '';
  croppedImage: any = '';
  aspectRatio!:number;
  profile = true;
  

  width='';

  albums:any[]=[];

  constructor(private render: Renderer2,
              private sanitizer: DomSanitizer, 
              private profileService:ProfileService,
              private statesNgrxService:StatesNgrxService) {}

  ngOnInit(): void {
    if (this.user) {
      this.albums = this.user.albums;      
    }    
    this.imageChangedEvent=this.fileComponent;
            
    this.setProperties();
  }

  ngAfterViewInit() {
    setTimeout(()=>{

      const { width } = document.querySelector('img.ngx-ic-source-image') as HTMLImageElement;

      let container =  document.querySelector('image-cropper') as HTMLElement;
      let container2 =  document.querySelector('image-cropper-cover') as HTMLElement;
      if (container) {
        container.style.width = `${width+10}px`;        
      } 

      if(container2) {
        container2.style.width = `${width+10}px`;
      }

    },200)
    

  }
  
  setProperties(){
    if (this.type == 'Profile' || this.type == 'Branch') {

      this.aspectRatio = 4 / 4;
      
      if (this.aspectInput) {
        this.aspectRatio = this.aspectInput;        
      }

      // document.getElementsByClassName("ngx-ic-cropper")[0]
      // //console.log(document.querySelector('div.ngx-ic-cropper'))

      setTimeout(() => {
        let cropperContainer = document.querySelector('div.ngx-ic-cropper') as HTMLElement
        // //console.log(cropperContainer);
  
        if (cropperContainer) {
          this.render.setStyle(cropperContainer, 'border', '2px solid #fff');
          this.render.setStyle(cropperContainer, 'border-radius', '50%');
          this.render.setStyle(cropperContainer, 'outline', 'rgb(0 0 0 / 30%) solid 100vw');
        }        
      }, 100);

      

    } else{

      setTimeout(() => {
        let cropperContainer = document.querySelector('div.ngx-ic-cropper') as HTMLElement
        // //console.log(cropperContainer);
  
        if (cropperContainer) {
          this.render.setStyle(cropperContainer, 'border', '2px solid #fff');
          this.render.setStyle(cropperContainer, 'outline', 'rgb(0 0 0 / 30%) solid 100vw')
        }        
      }, 100);


      this.aspectRatio = 4 / 2;
      this.profile = false;

      if (this.aspectInput) {
        this.aspectRatio = this.aspectInput;        
      }
      
    }
    
  }


/**
 * Comprime una imagen si su tamaño excede un límite especificado.
 * @param {File} file - El archivo de imagen para comprimir.
 * @returns {Promise<File>} Una promesa que se resuelve con el archivo de imagen comprimido.
 */
async compressImage(file: File): Promise<File> {
  const maxFileSize = 3 * 1024 * 1024; // 3MB

  if (file.size <= maxFileSize) {
    return file;
  }

  const img = await this.loadImage(file);
  let scale = 1;
  let compressedBlob: Blob;

  do {
    scale -= 0.1; // Reduzca la escala en cada iteración

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = img.width * scale;
    canvas.height = img.height * scale;

    ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);

    compressedBlob = await new Promise<Blob>(resolve => {
      canvas.toBlob(blob => {
        if (blob) {
          resolve(blob);
        }
      }, file.type);
    });

    // Si la escala es demasiado pequeña y aún no se puede alcanzar el tamaño de archivo deseado, rompe el bucle
    if (scale < 0.1 && compressedBlob.size > maxFileSize) {
      console.error("No se puede comprimir la imagen al tamaño deseado");
      break;
    }
  } while (compressedBlob.size > maxFileSize);

  return new File([compressedBlob], file.name, { type: file.type });

}

  
  /**
   * Carga un archivo de imagen y lo convierte en un elemento HTMLImageElement.
   * @param {File} file - El archivo de imagen para cargar.
   * @returns {Promise<HTMLImageElement>} Una promesa que se resuelve con el elemento de imagen cargado.
   */
  loadImage(file: File): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = event.target?.result as string;
      };
      reader.readAsDataURL(file);
    });
  }

  imageCropped(event: ImageCroppedEvent) {
    
    if (event.objectUrl) {
      this.croppedImage = this.sanitizer.bypassSecurityTrustUrl( event.objectUrl );
      // //console.log(this.croppedImage);      
    }
    if (event.blob) {
      // //console.log(event.blob);      
      const nFile = new File([event.blob], 'imageName.jpeg', { type: 'image/jpeg'}); // Ajusta el tipo de imagen si es necesario
      this.compressImage(nFile).then(compressedFile => {
        this.selectedFile  = compressedFile;
      });      
    }
  }

  cropperEmmit(){
    // this.cropperImageEmmiter.emit(this.selectedFile||undefined);
    if (this.selectedFile) {
      this.compressImage(this.selectedFile).then(compressedFile => {
        this.cropperImageEmmiter.emit(compressedFile);      
      }); 
    }
    this.cropperImageEmmiter.emit(undefined);
  }

  sendFile(): void {    
    Swal.fire({
      title: 'Estás seguro?',
      text: `Se va a actualizar tu foto`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, actualizar foto!',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        if (this.selectedFile) {
          const formData = new FormData();
  
          // Determinar el rol
          let role = '';          

          if (this.type === 'Branch') {
            role = 'branch';
            formData.append('branchId', this.branchId!);
          } else {
            if (this.role === 'user' || this.role === 'doctor') {
              role = 'user';
              formData.append('idUser', this.user._id);
            } else if (this.role === 'provider') {
              role = 'company';
              formData.append('idCompany', this.user._id);
            }
            // Verificar y obtener el idAlbum
            if (this.albums && this.albums.length > 0) {
              const album = this.albums.find((album) => album.name === this.type);
              if (album && album._id) {
                const idAlbum = album._id;
                formData.append('idAlbum', idAlbum);
              } else {
                console.error('No se encontró un álbum con el tipo especificado');
                return;
              }
            } else {
              console.error('No hay álbumes disponibles');
              return;
            }
          } 
  
          formData.append('avatar', this.selectedFile);
          formData.append('type', this.type);
  
          this.profileService.postImagesAvatar(formData, role).subscribe(
            (response: any) => {
              this.fileSendEmitter.emit(response.body.filePath);
  
              this.statesNgrxService.getDataGlobalUser().subscribe((resp: any) => {
                if (!resp) {
                  console.error('Error al obtener datos del usuario global');
                  return;
                }
                this.statesNgrxService.updateGlobalUser();
              });
  
              this.sendEmitter.emit(true);
              this.croppedImage = '';
            },
            (error) => {
              console.error('Error al enviar la imagen:', error);
              this.sendEmitter.emit(false);
            }
          );
        }
      }
    });
  }
  
  regresar(){
    this.regresarEmitter.emit(true);
  }

}
