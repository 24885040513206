import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// Enable production mode if the environment is set to production
if (environment.production) {
  // Check if the window object exists to prevent issues in server-side rendering
  if (typeof window !== 'undefined' && window) {
    window.console.log = function() {};
  }
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));