import { Component } from '@angular/core';

@Component({
  selector: 'app-photos-pre-visualizer',
  templateUrl: './photos-pre-visualizer.component.html',
  styleUrls: ['./photos-pre-visualizer.component.scss']
})
export class PhotosPreVisualizerComponent {

}
