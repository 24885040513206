<div class="container-relative">
  <div class="background-absolute"></div>
  <!-- 
<ng-lottie class="lottie" [options]="options"> </ng-lottie>
 -->
  <div class="container-absolute">
    <div class="container-modal">
      <h2 class="tittle">{{ data.company.companySocialProfile.name }}</h2>

      <!-- tabs-->

      <app-tabs class="tabs" [tabsInfo]="tabs" (switch)="switchTab($event)"></app-tabs>
      <!--===========TAB 1=============== -->
      <div class="container-tab-1" *ngIf="selectedTab === 'tab1'">
        <div class="tab-1 animated fadeIn">
          <div class="tab-1-content">
            <div class="tab-1-content-mision">
              <i class="fa fa-start"></i>
              <h3>Mision:</h3>
              <p>
                {{ data.company.companySocialProfile.companyMission }}
              </p>
            </div>
            <div class="tab-1-content-vision">
              <h3>Vision:</h3>
              <p>
                {{ data.company.companySocialProfile.companyVision }}
              </p>
            </div>
            <div class="tab-1-content-goals">
              <h3>Metas:</h3>
              <p>
                {{ data.company.companySocialProfile.companyGoals }}
              </p>
            </div>
         
            <div class="tab-1-red-social">

              <div *ngFor="let item of data.company.companySocialProfile.socialMedia">
                <a [href]="item.url" target="_blank">
                  <i [class]="item.icon"> </i>
                </a>
              </div>
            

            </div>
          </div>
        </div>
      </div>
      <!--===========TAB 2=============== -->
      <div class="container-tab-2" *ngIf="selectedTab === 'tab2'">
        <div class="tab-2 animated fadeIn">
          <div class="container-content-card">
            <div class="card-activeService" *ngFor="let objeto of activeServices">
              <div class="container-card-activeService-flex">
                <div class="container-img-activeService">
                  <img class="container-img-activeService-avatar" [src]="objeto.subService.subserviceImages[0].path"
                    alt="Servicio" />
                </div>

                <div class="container-data-activeService">
                  <div class="container-data-activeService-text">
                    <p class="name-text">
                      {{ objeto.subService.subserviceName }}
                    </p>
                    <div class="container-data-activeService-text-price">
                      <div class="container-price-gap">
                        <span class="text-price-before">Precio Antes:</span>
                        <span class="number-price-before">{{ objeto.subService.price | currencyFormat }}
                        </span>
                      </div>
                      <div class="container-price-gap">
                        <span class="text-price-after">Precio Ahora:</span>
                        <span class="number-price-after">{{objeto.subService.discountedPrice | currencyFormat}}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="container-data-activeService-relleno">
                    <div>
                      <p class="text-warning font-medium">
                        <i class="fa fa-star"> </i>
                        <i class="fa fa-star"> </i>
                        <i class="fa fa-star"> </i>
                        <i class="fa fa-star"> </i>
                        <i class="fa fa-star"> </i>
                      </p>
                    </div>
                    <div>
                      <!--  Aqui redirigir y aplicar un metodo para cargar este servicio-->
                      <button class="btn btn-info" (click)="
                          emitDataSubs(
                            objeto.subService._id,
                            cityId
                          )
                        ">
                        Detalles
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--===========TAB 3=============== -->
      <div (click)="eventTabThree()" class="container-tab-3" *ngIf="selectedTab === 'tab3'">
        <div class="tab-3 animated fadeIn">
          <div class="container-content-card">
            <div class="card-doctor" *ngFor="let doctor of doctors; let i = index">
              <!-- ======== Front Card doctor ============ -->
              <div *ngIf="!doctor.flipped">
                <div class="container-card-doctor-flex animated fadeIn">
                  <div class="container-img-doctor">
                    <!--Cambiar img por la de la Api -->
                    <img class="container-img-doctor-avatar" [src]="doctor.avatar" alt="avatar" />
                  </div>
                  <div class="container-data-doctor">
                    <div class="container-data-doctor-text">
                      <div class="name-doctor">
                        <span>Nombre:</span>
                        <span class="name-text">{{ doctor.fullName }}</span>
                      </div>
                      <div class="speciality-doctor">
                        <div class="speciality-doctor-text">
                          <span>Especialidad:</span>
                          <span class="name-text">
                            {{
                            doctor.specialities &&
                            doctor.specialities.length > 0
                            ? (doctor.specialities[0].specialityName
                            | capitalizeExceptFirst)
                            : "No Registra"
                            }}
                          </span>
                        </div>
                        <div>
                          <button class="button-plus btn btn-light" (click)="toggleCard(i)">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="icon-plus">
                              <path
                                d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- ======== Back Card doctor ============ -->
              <div *ngIf="doctor.flipped">
                <div class="container-card-doctor-back animated fadeIn">
                  <div class="container-data-back">
                    <div class="study">
                      <span class="name-text">Estudios:</span>
                      <!--Cuando no hay data -->
                      <div *ngIf="!doctor.studies || doctor.studies.length === 0">
                        <ul>
                          <li>
                            <span class="study-text">No hay estudios registrados.</span>
                          </li>
                        </ul>
                      </div>
                      <div *ngIf="doctor.studies && doctor.studies.length > 0">
                        <div *ngFor="let study of doctor.studies">
                          <ul>
                            <li>
                              <span class="study-text">{{ study.studyName }} en</span>
                              <span class="study-text">
                                {{ study.university }} de</span>
                              <span class="study-text">
                                {{ study.municipality }}.
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div class="xp">
                      <span class="name-text">Experiencia:</span>
                      <!-- Cuando no hay data -->
                      <div *ngIf="
                          !doctor.expereience || doctor.expereience.length === 0
                        ">
                        <ul>
                          <li>
                            <span class="study-text">
                              No hay experiencia registrada</span>
                          </li>
                        </ul>
                      </div>
                      <div *ngIf="
                          doctor.expereience && doctor.expereience.length > 0
                        ">
                        <div *ngFor="let expereience of doctor.expereience">
                          <ul>
                            <li>
                              <!-- Recordatorio: estos dato no existen, colocar la propiedad que da la api -->
                              <span class="study-text">{{ expereience.expereience }}
                              </span>
                              <span class="study-text">ejemplo</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="container-buttoms">
                    <div class="buttom-close">
                      <button class="button-plus btn btn-light" (click)="toggleCard(i)">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="icon-close">
                          <path
                            d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" />
                        </svg>
                      </button>
                    </div>
                    <div class="buttom-social"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--===========TAB 4=============== -->
      <div class="container-tab-4" *ngIf="selectedTab === 'tab4'">
        <div class="tab-4 animated fadeIn">
          <div class="tab-4-content">
            <div class="main" *ngFor="let branch of branches">
              <div class="main-title">
                <span class="content-branch-name">{{ branch.name }} </span>
              </div>
              <div class="content-branch">
                <div class="content-branch-img">
                  <!--  Cambiar por la img de la Api-->
                  <img [src]="branch.avatar" alt="Sucursal" />
                </div>
                <div class="content-branch-text">
                  <div class="content-branch-text-info">
                    <div>
                      <span class="branch-title">Direccion:</span>
                    </div>
                    <div class="content-branch-text-info-address">
                      <span class="branch-text">{{ branch.address }} </span>
                      <span class="branch-text">{{ branch.municipality.cityName }}
                      </span>
                    </div>
                    <!--  
                    <span>{{branch.status}} </span>
                  -->
                  </div>
                  <div class="content-branch-text-contact">
                    <div class="phone">
                      <div class="phone-celular">
                        <span class="branch-title">Celular:</span>
                      </div>
                      <div>
                        <span class="branch-text">{{ branch.cellPhones[0].prefix }}
                        </span>
                        <span class="branch-text">{{ branch.cellPhones[0].phone }}
                        </span>
                      </div>
                    </div>
                    <div class="phone-tel">
                      <div>
                        <span class="branch-title">Telefono:</span>
                      </div>
                      <div *ngIf="branch.cellPhones[1]">
                        <span class="branch-text">{{ branch.cellPhones[1].prefix }}
                        </span>
                        <span class="branch-text">{{ branch.cellPhones[1].phone }}
                        </span>
                      </div>
                      <div *ngIf="!branch.cellPhones[1]">
                        <span class="branch-text">
                          No registra
                        </span>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>