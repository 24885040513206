<article class="article">
<div class="hero" [class.hide]="hideHero" >
    <!-- Img o Animacion -->
    <ng-lottie class="lottie" [options]="animationOptions"> </ng-lottie>
    <!-- Description del hero -->
     <p class="no-select">{{description}}</p>
</div>
<div #contentDiv  class="content">
     <!-- Aquí se proyectará el contenido dinámico osea el html del componente padre -->
     <ng-content></ng-content>
</div>
</article>
