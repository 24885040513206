<div >
  <label class="label_autocomplete">{{labelTxt}}
    <div class="input-wrapper">
      <input
        type="text"
        class="form-control form-control-line input_form"
        [placeholder]="placeholder"
        [required]="requiredInput"
        (keyup)="buscarTermino($event)"
        (keyup.backspace)="borrandoTermino($event)"
        [(ngModel)]="termino"
      />
      <i *ngIf="termino.length>0" class="fa fa-close clear-icon" (click)="limpiarTermino()" title="Limpiar"></i>
    </div>
  </label>

  <div
    *ngIf="termino.length > 0 && !seleccionado"
    class="table-responsive table-sm table-fixed table-hover border" 
  >
    <table class="table">
      <thead *ngIf="resultados.length == 0">
        <tr>
          <th>
            No hay resultados para: <strong>{{ termino }}</strong>
          </th>
          <th (click)="darSugerencias()">
            <!-- <button
              class="btn waves-effect btn-sm waves-light btn-info"
              type="button"
            >
              Ver Sugerencias
            </button> -->
            <tr
              ngIf="verSugerencia"
              class="selector"
              *ngFor="let resultado of resultados"
            >
              <td (click)="seleccionarItem(resultado)" [title]="nameInput">{{ resultado[nameInput]}}</td>
            </tr>
          </th>
        </tr>
      </thead>
      <tbody *ngIf="resultados.length > 0">
        <tr class="selector fw_400" *ngFor="let resultado of resultados">
          <td (click)="seleccionarItem(resultado)" [title]="nameInput">{{ resultado[nameInput]}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
