import { createFeatureSelector, createSelector } from "@ngrx/store";
import { BranchesState, branchesStateFeatureKey } from "./branches.reducer";

const branchesState = createFeatureSelector<BranchesState>(branchesStateFeatureKey);

export const companyId = createSelector(
  branchesState,
  (state) => state.companyId
); 

export const branches = createSelector(
    branchesState,
    (state) => state.branches
); 
    
export const branchSelected = createSelector(
  branchesState,
  (state) => state.branchSelected
); 
  
