import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CustomCalendarComponent } from './custom-calendar.component';
import { DailyComponent } from './daily/daily.component';
import { TaskInfoComponent } from './task-info/task-info.component';
import { NgOptimizedImage } from '@angular/common'

import { CustomTooltipDirective } from './directives/custom-tooltip.directive';
import { FormatHourPipe } from './pipes/format-hour.pipe';
import { HolidaysService } from '@services';

import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

const componentList = [
  CustomCalendarComponent,
  CustomTooltipDirective,
  TaskInfoComponent,
  DailyComponent
]

export function playerFactory() {
  return player;
}

@NgModule({
  imports: [
    CommonModule,
    NgOptimizedImage,
    LottieModule.forRoot({ player: playerFactory })
  ],
  declarations: [...componentList, FormatHourPipe],
  exports: [...componentList, FormatHourPipe],
  providers: [HolidaysService, BrowserModule]
})
export class CustomCalendarModule { }
