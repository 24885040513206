import { createFeatureSelector, createSelector } from "@ngrx/store";
import { authStateFeatureKey, initialAuthState } from './auth.reducers';

const authState = createFeatureSelector<typeof initialAuthState>(authStateFeatureKey);

export const userData = createSelector(
    authState,
    (initialAuthState) => initialAuthState.userData
); 

export const getToken = createSelector(
    authState,
    (initialAuthState) => initialAuthState.token
);

export const memberId = createSelector(
    authState,
    (initialAuthState) => initialAuthState.userData?._id 
);