import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneMask'
})
export class PhoneMaskPipe implements PipeTransform {

  transform(value: string): unknown {
    if (!value) return value

    let onlyNumbers = value.replace(/[^0-9]/g, '');
    let countryCode = onlyNumbers.split('').reduceRight((acc, char) => acc + char, '').slice(10).split('').reverse().join('')
    let phone = onlyNumbers.slice(-10)

    // return `+${countryCode} (${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`;
    return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`;
  }
}
