import { Component, OnInit, AfterViewInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingPageModule } from "../../landing-page/landing-page.module";
import { AnimationOptions, LottieModule } from 'ngx-lottie';
import { ButtonsComponent } from "../../shared-components/buttons/buttons.component";
import { Router } from '@angular/router';

@Component({
  selector: 'app-appointment-in-progress',
  standalone: true,
  imports: [CommonModule, LandingPageModule, LottieModule, ButtonsComponent],
  templateUrl: './appointment-in-progress.component.html',
  styleUrls: ['./appointment-in-progress.component.scss']
})
export class AppointmentInProgressComponent implements OnInit {

 
  img = 'assets/images/confirmed.png'

  isLoading: boolean = false;

  constructor(private router: Router) {
    
  }

  ngOnInit(): void {
      this.isLoading = true;
  }

  
  goHome(){
    this.router.navigate(['']);
  }

}
