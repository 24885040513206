import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, firstValueFrom, map, switchMap } from 'rxjs';
import { ApiRes, Especialidad, ResponseToRequest } from '../interfaces/information.interface';
import { SearchRequest } from '@interfaces/shared-components.interface';
import { SweetAlertService } from '@services';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { typeOfProviver } from '../pages/session/state/session.selectors';
import { has } from 'lodash';

// Cargar archivos JSON para tipos de documento, género y estado civil
const typesOfDocument = require('../../assets/json/type-of-document.json');
const gender = require('../../assets/json/gender.json');
const martialStatus = require('../../assets/json/martial-status.json');

@Injectable({
  providedIn: 'root',
})
export class InformationService {
  constructor(private apiService: ApiService,
              private sweetAlert: SweetAlertService,
              private router: Router,
              private store: Store
  ) {}

  /**
   * Obtiene las especialidades.
   */
  getSpecialities(): Observable<Especialidad[]> {
    
    return this.store.select(typeOfProviver).pipe(
      switchMap((typeOfProviver: any) => {
        return this.apiService.getRequest('information/specialities').pipe(
          map((apiRes: ApiRes) => {
            let { body: especialities } = apiRes;
  
            if (typeOfProviver) {

              let condition: any;
              if (typeOfProviver === 'Services') {
                condition = (item: Especialidad) => item._id.toString() !== '640405ef0c0fa4df0711692f';
              } else {
                condition = (item: Especialidad) => item._id.toString() === '640405ef0c0fa4df0711692f';
              }
              especialities = especialities.filter((item: Especialidad) => condition(item));
            }
            return especialities;
          })
        );
      })
    );
  }



  /**
   * Obtiene la llave pública para encriptar.
   * @returns {Promise<string>} - Promesa que resuelve con la llave pública.
   */
  async getPublicKey() {
    let response = await firstValueFrom(
      this.apiService.getRequest('information/public-key')
    );
    return response.body.key;
  }

  /**
   * Obtiene las municipalidades.
   * @returns {Promise<any>} - Promesa que resuelve con las municipalidades.
   */
  async getMunicipalities() {
    let response = await firstValueFrom(
      this.apiService.getRequest('information/municipalities')
    );
    return response.body;
  }

  /**
   * Obtiene la información del usuario.
   * @param {string} idUser - ID del usuario.
   * @returns {Observable<any>} - Observable que emite la información del usuario.
   */
  getInfoUser(idUser: string) {
    return this.apiService.getRequest(`user/info/${idUser}`).pipe(
      map((response: any) => {
        // //console.log(response);

        return response.body;
      })
    );
  }

  /**
   * Obtiene la información del usuario.
   * @param {string} idCompany - ID del usuario.
   * @returns {Observable<any>} - Observable que emite la información del usuario.
   */
  getInfoCompany(idCompany: string) {
    return this.apiService.getRequest(`company/${idCompany}`).pipe(
      map((response: any) => {
        return response.body[0];
      })
    );
  }

  /**
   * Obtiene la información de un servicio.
   * @param {string} idCompany - ID del usuario.
   * @returns {Observable<any>} - Observable que emite la información del usuario.
   */
  async getSubServices(idMunic: string) {
    let response = await firstValueFrom(
      this.apiService.getRequest(`search/subservices/${idMunic}/0`)
    );
    return response.body;
  }

  /**
   * Obtiene la información de un servicio.
   * @param {string} idCompany - ID del usuario.
   * @returns {Observable<any>} - Observable que emite la información del usuario.
   */
  async getSubServiceDetail(idMunic: string, idSubserv: string) {
    let response = await firstValueFrom(
      this.apiService.getRequest(
        `search/subservice-detail/${idSubserv}/${idMunic}`
      )
    );
    return response.body;
  }
  /**
   * Obtiene la información de un servicio.
   * @param {string} idCompany - ID del usuario.
   * @returns {Observable<any>} - Observable que emite la información del usuario.
   */
  getInfoSubService(idCompany: string) {
    return this.apiService.getRequest(`company/${idCompany}`).pipe(
      map((response: any) => {
        return response.body[0];
      })
    );
  }

  /**
   * Obtiene los tipos de documento.
   * @returns {Array} - Arreglo de tipos de documento.
   */
  getDocumentTypes() {
    return typesOfDocument['typesOfDocument'] ?? [];
  }

  /**
   * Obtiene los géneros.
   * @returns {Array} - Arreglo de géneros.
   */
  getGenders() {
    return gender['gender'] ?? [];
  }

  /**
   * Obtiene los estados civiles.
   * @returns {Array} - Arreglo de estados civiles.
   */
  getMartialStatus() {
    return martialStatus['martialStatus'] ?? [];
  }

  /**
   * Verifica si un número de WhatsApp está asociado a una cuenta.
   * @param {string} number - Número de teléfono a verificar.
   * @returns {Observable<boolean>} - Observable que emite `true` si el número está asociado a una cuenta, de lo contrario `false`.
   */
  verifyWhatsAppNumber(number: string) {
    return this.apiService
      .getRequest(`information/verify-number/${number}`)
      .pipe(
        map((res: ApiRes) => {
          return res.body.existe;
        })
      );
  }

  /**
   * Realiza una búsqueda según un término y filtros.
   * @param {SearchRequest} body - Cuerpo de la solicitud de búsqueda.
   * @returns {Observable<any>} - Observable que emite los resultados de la búsqueda.
   */
 search(body: SearchRequest): Observable<any> {    
    return this.apiService.postRequest('search/by-term-and-filters', body);
  }

  // Obtener info detallada de una empresa:
  getDetailInfoCompany(id: string) {
    return this.apiService.getRequest(`company/details/${id}`).pipe(
      map((response: any) => {
        return response.body;
      })
    );
  }

  // Obtener información de la solicitud a traves del hash
  getRequestData(hash: string) {
    return this.apiService.getRequest(`family-group/request-info/${hash}`)
               .pipe( map( (apiRes: ApiRes) => {
                  const { body, ok, message } = apiRes;

                  if(!ok) {
                    this.sweetAlert.lauchSwal('', message, 'warning');
                    this.router.navigate(['']);
                  }
                  return body;
                }
              ));
  }

  // Enviar respuesta de la solicitud a grupo familiar
  sendResponseRequest(data: ResponseToRequest) {
    return this.apiService.postRequest('family-group/response-request', data)
               .pipe( map( (apiRes: ApiRes) => {
                  const { message, ok } = apiRes;
                  if(ok) {
                    this.sweetAlert.lauchSwal('', message, 'success');
                    this.router.navigate(['']);
                  }
                  return ok;
               } 
            ));
  }

  // Verificar cita Temp
  getRequestTemp(hash:string){
    return this.apiService.getRequest(`verify/ok/${hash}`)
            .pipe(map ((apiRes: ApiRes) => {
              const {body, ok, message} = apiRes;

              // if(!ok) {
              //   this.sweetAlert.lauchSwal(" ", message, 'warning');
              //   this.router.navigate(['']);
              // }
              return apiRes;
            }))
  }

  // Cancelar cita temp en el caso2
  getRequestCancelTemp(hash:string){
    return this.apiService.getRequest(`verify/cancell/${hash}`)
            .pipe(map ((apiRes: ApiRes) => {
              const {body, ok, message} = apiRes;

              // if(!ok) {
              //   this.sweetAlert.lauchSwal(" ", message, 'warning');
              //   this.router.navigate(['']);
              // }
              return apiRes;
            }))
  }
}
