<span [id]="idInput" class="input__group">
  <div class="box__label">
    <label class="input__label" [style.color]="labelColor" [style.fontSize]="labelFontSize" for="input_component">{{label}}<strong *ngIf="required"> *</strong></label>
    <i class="fa fa-question icon__helper" [title]="helper" *ngIf="helper"></i>
  </div>
  <div class="box__input">
    <input #inputRef class="input__form" [type]="type" name="input_component" [placeholder]="placeholder" autocomplete="off" [formControl]="control" [ngClass]="{invalid: control.touched && control.invalid}" (blur)="onBlur()">
    <i class="fa fa-close icon__clear" *ngIf="!control.disabled" [ngClass]="{visible: inputRef.value != ''}" (click)="control.setValue('')" title="Limpiar"></i>
  </div>
  <div class="box__error" [ngClass]="{visible:errors.length}">
    <ul>
      <li class="box__error__item" *ngFor="let error of errors"><strong>* </strong>{{error}}</li>
    </ul>
  </div>
</span>
