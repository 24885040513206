import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'formatDateAMPM'
})
export class FormatDateAMPMPipe implements PipeTransform {

  transform(value: any): string {
    if (!value) return '';
    
    const datePipe = new DatePipe('en-US');
    const formattedDate = datePipe.transform(value, 'yyyy-MM-dd hh:mm a');
    
    return formattedDate || '';
  }

}
