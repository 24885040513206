import { Injectable,Renderer2, RendererFactory2, NgZone } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ReusableModalComponent } from './modal/reusable-modal.component';
import { ModalOptions } from '@interfaces/modal.reusable.interface';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  modalRef: BsModalRef | null = null;
  
  renderer: Renderer2;
  widthSubject = new Subject<number>();
  width$ = this.widthSubject.asObservable();

  constructor( private modalService: BsModalService,rendererFactory: RendererFactory2, private ngZone: NgZone ) {
    this.renderer = rendererFactory.createRenderer(null, null);
   }

  public openModal(component: any, options?: ModalOptions, data?: any) {

    // Verificar si ya existe un modal abierto
    if (this.modalRef) {
      return; // Si ya existe, simplemente no hacemos nada (o cerramos el actual, según la lógica deseada)
    }

    this.modalRef = this.modalService.show(ReusableModalComponent, { 
      class: options?.modalClass, 
      keyboard: options?.dataKeyboard ? true : false, 
      backdrop: options?.static === 'static' ? 'static' : true 
    });

    const componentInstance = this.modalRef.content.loadDynamicComponent(component, options, data);

    // Suscribirse al evento onHidden para limpiar la referencia cuando el modal se cierra
    this.modalRef.onHidden?.subscribe(() => this.modalRef = null);

    return componentInstance;
  }

  closeModal() {
    this.modalRef?.content.closeModal();

    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = null; // limpiar la referencia al cerrar
    }
  }

  observeModalWidth(modalId: string) {
    const modalElement = document.getElementById(modalId);
    if (modalElement) {
      const resizeObserver = new ResizeObserver(entries => {
        this.ngZone.run(() => {
          for (let entry of entries) {
            const width = entry.contentRect.width;
            this.widthSubject.next(width);
          }
        });
      });

      resizeObserver.observe(modalElement);
    }
  }

}
