<app-landing-header></app-landing-header>
<div class="preloader" *ngIf="isLoading == false">
    <div class="loader">
      <div class="loader__figure"></div>
      <p class="loader__label">Descuentos médicos Web</p>
    </div>
  </div>
<main class="main animated fadeIn" *ngIf="isLoading == true">
    <section class="section">
        <div class="container-img">
            <img [src]="img" alt="Img">
        </div>
        <div class="container-message">
            <p>Tu cita esta en proceso de <span>verificacion</span>, te llegara un enlace de verificacion a tu numero de telefono o whatsapp.</p>
            <div>
                <custom-buttons (click)="goHome()" title="Inicio" text="Inicio" textSize="1.2rem" textColor="#fff" icon="ti-arrow-left ml-2" type="button" [inputBackgroundColor]="'primary'" ></custom-buttons>
            </div>
        </div>

    </section>
</main>
