<div class="modal fade modalFotos" id="images-cropper" data-backdrop="static" data-keyboard="false" aria-hidden="true">
  <div class="modal-dialog modal-full-width" style="background: transparent;">
    <div class="modal-content" style="background: transparent;">
      <div class="modal-body" style="background: transparent;">
        <div class="card" style="background: transparent;">
          <div class="card-body" style="background: transparent;">

            <div class="text-center row">
              <div class="col-6">
                <h5 class="text-center text-white">Recorte</h5>

                <!-- <img *ngIf="!imageChangedEvent" class="img-modal-imagen" [src]="apiUrl + '/avatar/'+ (user.fotos | fotosFavoritas: 'portada')" /> -->
                <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="false"
                  [aspectRatio]="16 / 16" [resizeToWidth]="800" [imageQuality]="50" format="jpeg"
                  (imageCropped)="imageCropped($event)" (cropperReady)="cropperReady()"
                  (loadImageFailed)="loadImageFailed()"></image-cropper>
              </div>
              <div class="col-6 text-center">
                <div class="img-container text-center">
                  <h5 class="text-center text-white">Imagen recortada</h5>
                  <img *ngIf="croppedImage" class="img-modal-recorte" [src]="croppedImage" />
                  <!-- <img *ngIf="!croppedImage" class="img-modal-imagen" [src]="apiUrl + '/avatar/'+ (user.fotos | fotosFavoritas: 'portada')"/> -->

                </div>
              </div>
              <div class="col-12"><br><br><br><br><br><br><br><br><br>
                <input style="display: none;" id="file-upload-med" class="text-center" type="file" name="image"
                  (change)="fileChangeEvent($event)" />
                <br>
                <label type="submit" for="file-upload-med" class="btn btn-rounded btn-info">Cargar Imagen</label>
                <div class="text-center">
                  <div class="btn-group dropup btn-group" role="group">
                    <button type="button" class="btn btn-rounded btn-info"><i class="fa fa-check"></i> Guardar
                      Imagen</button>


                    <!-- <custom-buttons type="button" class="btn btn-rounded btn-info animated fadeIn" text="Guardar
                    Imagen" icon="fa fa-check" textColor="#fff" [title]="''">
                      <i class="fa fa-check"></i>
                    </custom-buttons> -->

                    <!-- <button type="button" class="btn btn-rounded btn-info"><i class="fa fa-check"></i> Guardar Imagen</button> -->
                    <button type="button" class="btn btn-rounded waves-effect waves-light btn-danger"
                      data-dismiss="modal">Cerrar <i class="fa fa-times"></i></button>

                    <!-- <custom-buttons type="button"
                      class="btn btn-rounded waves-effect waves-light btn-danger animated fadeIn" data-dismiss="modal"
                      text="Cerrar" icon="fa fa-times" textColor="#fff" [title]="''">
                      <i class="fa fa-times"></i>
                    </custom-buttons> -->
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
