<div class="container_image bg_2 width_2 animated zoomIn fast">
        <div class="card_imagen_header">
            <!-- <button type="button" class="btn btn-circle btn_back_2 i_90" title="Opciones"><i class="ti ti-more-alt"></i> </button> -->
            <div class="title_header ml-2 fw_500">Visor de Imagenes</div>
            <button type="button" class="btn btn-circle btn_back_2" title="Cerrar" data-dismiss="modal" (click)="modalService.closeModal()"><i class="ti ti-close"></i> </button>
        </div>            
        <div *ngIf="currentView" class="image_body">
            <img id="imgPerfil" class="img_perfil" [src]="currentView.pathOriginals"> 
            <button *ngIf="images.length>1" (click)="navigateImages(-1)" class="btn_control_image navigate-button left" title="Imagen anterior"><i class="mdi mdi-arrow-left"></i></button>            
            <button *ngIf="images.length>1" (click)="navigateImages(1)" class="btn_control_image navigate-button right" title="Imagen siguiente"><i class="mdi mdi-arrow-right"></i></button>   
            <div *ngIf="images.length>1" class="preview_container">
                <img 
                    *ngFor="let image of images;let i = index" 
                    [id]="'prev'+image._id"
                    [src]="image.path" 
                    alt="" 
                    class="preview_image" 
                    [class.preview_image_active]="image===currentView"
                    (click)="currentImagePrev(i)">
            </div>         
        </div>
          
</div>