import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { SmoothScrollService } from 'src/app/services/smooth-scroll.service';
import { environment } from 'src/environments/environment';

const production = environment.production;

@Component({
  selector: 'app-landing-header',
  templateUrl: './landing-header.component.html',
  styleUrls: ['./landing-header.component.scss']
})
export class LandingHeaderComponent implements OnInit {

  @Input() externalLinks : any[] = []; // Array de los enlaces
  login: boolean = false;
  linkNull: boolean = true;


  iconPath= production ? '../../../../../assets/images/logo-light-icon.png':'../../../../../assets/images/logo-light-icon-dev.png';

  constructor(private smoothScrollService: SmoothScrollService,
              private authService: AuthService
  ){
    this.authService.authChange.subscribe(isAuthenticated => {
      this.login = isAuthenticated;
    })
  }

  ngOnInit(): void {
    this.linkNull === true
    if(this.externalLinks.length > 0){
      this.linkNull = false;
    }
    this.authService.checkAuthentication();
  }

  onLinkClick(targetId: string): void {
    this.smoothScrollService.scrollToElement(targetId);
  }

}
