import { ElementRef, Inject, Injectable, Renderer2, RendererFactory2, inject } from '@angular/core';
import { Album } from '@interfaces/information.interface';
import { format, add, sub } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  private _render!: Renderer2;

  constructor() { }

  /**
   * Obtiene el año actual.
   *
   * @returns El año actual en formato numérico.
   */
  getDate() {
    //console.log('getDate');
    const date = new Date();
    const year = date.getFullYear();

    return year;
  }

  formDataConsole(formData:FormData){
    formData.forEach((value, key) => {
      console.log(`${key}: ${value}`);
    });
  }

  formatDate(date: Date) {
    //console.log('formatDate');
    return format(new Date(date), 'yyyy-MM-dd');
  }

  convertirHoraAMPM(hora24: string): string {
    //console.log('convertirHoraAMPM');
    const partesHora = hora24.split(":");
    const hora = parseInt(partesHora[0]);
    const minutos = parseInt(partesHora[1]);
    const ampm = hora < 12 ? "AM" : "PM";
    const hora12 = hora % 12 === 0 ? 12 : hora % 12;
    return `${hora12.toString().padStart(2, "0")}:${minutos.toString().padStart(2, "0")} ${ampm}`;
  }

  convertISODateToLocalFormat(isoDate: string) {
    //console.log('convertISODateToLocalFormat');
    return isoDate.split('T')[0];
  }

  calcularEdad(inicio: Date, fin: Date): number {
    //console.log('calcularEdad');
    const diferenciaEnMilisegundos = fin.getTime() - inicio.getTime();
    const edadEnMilisegundos = new Date(diferenciaEnMilisegundos).getTime();

    // Calcular la edad en años
    const edad = Math.abs(new Date(edadEnMilisegundos).getUTCFullYear() - 1970);

    return edad;
}

calcularDiferenciaFechas(inicio: Date, fin: Date): string {
    // Calcular la diferencia en milisegundos
    //console.log('calcularDiferenciaFechas');

    let diferenciaMilisegundos = fin.getTime() - inicio.getTime();

    // Convertir la diferencia en milisegundos a segundos, minutos, horas, días y años
    let segundosEnMilisegundos = 1000;
    let minutosEnMilisegundos = segundosEnMilisegundos * 60;
    let horasEnMilisegundos = minutosEnMilisegundos * 60;
    let diasEnMilisegundos = horasEnMilisegundos * 24;
    let añosEnMilisegundos = diasEnMilisegundos * 365.25; // Aproximadamente 365.25 días por año para tener en cuenta los años bisiestos

    let años = Math.floor(diferenciaMilisegundos / añosEnMilisegundos);
    let diasRestantes = diferenciaMilisegundos % añosEnMilisegundos;

    // Calcular meses
    let meses = 0;
    let diasEnMesActual = 0;
    for (let i = 0; i < 12; i++) {
        let diasEnEsteMes = new Date(fin.getFullYear(), i + 1, 0).getDate();
        if (diasRestantes >= diasEnEsteMes * diasEnMilisegundos) {
            meses++;
            diasRestantes -= diasEnEsteMes * diasEnMilisegundos;
            diasEnMesActual = diasEnEsteMes;
        } else {
            break;
        }
    }

    // Calcular días
    let dias = Math.floor(diasRestantes / diasEnMilisegundos);

    // Construir la cadena de resultado
    let resultado = '';
    if (años > 0) {
        resultado += `${años} Año${años > 1 ? 's' : ''} `;
    }
    if (meses > 0) {
        resultado += `${meses} Mes${meses > 1 ? 'es' : ''} `;
    }
    if (dias > 0) {
        resultado += `${dias} Día${dias > 1 ? 's' : ''}`;
    }

    return resultado.trim();
}

  getYearsToCurrentDate(fecha: string | null): string {
    //console.log('getYearsToCurrentDate');
    if (!fecha) {
      return '';
    } else {
      var hoy = new Date();
      var cumpleanos = new Date(fecha);
      var edad = hoy.getFullYear() - cumpleanos.getFullYear();
      var m = hoy.getMonth() - cumpleanos.getMonth();

      if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
        edad--;
      }
      return `${edad} Años`;
    }
  }


  formatearFechayHora(fecha: Date): string {
    //console.log('formatearFechayHora');
    fecha = new Date();
    const meses = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'];
    const dia = fecha.getDate();
    const mes = meses[fecha.getMonth()];
    const año = fecha.getFullYear();
    let hora = fecha.getHours();
    const minuto = fecha.getMinutes();
    const ampm = hora >= 12 ? 'PM' : 'AM';

    hora = hora % 12;
    hora = hora ? hora : 12; // El '0' se convierte en '12'
    const minutosFormateados = minuto < 10 ? '0' + minuto : minuto;

    return `${dia} de ${mes} de ${año}, a las ${hora}:${minutosFormateados} ${ampm}`;
  }

  obtenerFechaAAAAMMDD(fecha: Date): string {
    //console.log('obtenerFechaAAAAMMDD');
    fecha = new Date();
    const año = fecha.getFullYear();
    const mes = ('0' + (fecha.getMonth() + 1)).slice(-2); // +1 porque los meses empiezan en 0
    const dia = ('0' + fecha.getDate()).slice(-2);

    return `${año}-${mes}-${dia}`;
  }

  obtenerHoraFormato24(fecha: Date) {
    //console.log('obtenerHoraFormato24');
    const hora = fecha.getHours();
    const minutos = ('0' + fecha.getMinutes()).slice(-2); // Asegura dos dígitos para los minutos

    return `${hora}:${minutos}`;
  }

  obtenerFechaHoraFormatoAMPM(fechaISO: string) {
    //console.log('obtenerFechaHoraFormatoAMPM');
    // Convierte la cadena de fecha en un objeto Date
    const date = new Date(fechaISO);

    // Obtiene los componentes de fecha y hora
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

    // Formatea la fecha con el formato deseado
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;

    return formattedDate;
  }

  /**
   * Realiza operaciones en fechas como obtener la fecha actual, agregar días/meses/años o restar días/meses/años a la fecha actual.
   *
   * @param operation - La operación que se desea realizar ('today' para obtener la fecha actual, 'add' para agregar y 'subtract' para restar).
   * @param time - El intervalo de tiempo a agregar/restar ('days', 'months' o 'years').
   * @param value - El valor numérico a agregar/restar.
   * @returns La fecha resultante en formato 'yyyy-MM-dd'.
   * @throws Si se proporciona una operación incorrecta, si falta alguno de los argumentos necesarios o si ocurre algún error interno.
  */
  dateOperations(operation: 'today' | 'add' | 'subtract', time?: 'days' | 'months' | 'years', value?: number): string {

    const currentDate = new Date();

    switch (operation) {
      case 'today':
        return format(currentDate, 'yyyy-MM-dd');

      case 'add':
        if (time && value) {
          return format(add(currentDate, { [time]: value }), 'yyyy-MM-dd');
        }
        throw new Error('Both time and value must be provided for the add operation.');

      case 'subtract':
        if (time && value) {
          return format(sub(currentDate, { [time]: value }), 'yyyy-MM-dd');
        }
        throw new Error('Both time and value must be provided for the subtract operation.');

      default:
        throw new Error('Invalid operation.');
    }
  }

  getAlbumImageUrl(image:string):Album{
    //console.log('getAlbumImageUrl');
    return {
      images:[
        {path:image}
      ]
    };
  }

  /**
   * Recibe un objeto y retorna una copia sin los campos en null.
   *
   * @returns { Record<string, any> } - Nuevo objeto sin los campos en null
   */
  limpiarObjeto(obj: Record<string, any>): Record<string, any> {

    for (const propiedad in obj) {
      if (obj[propiedad] === null || obj[propiedad] === undefined) {
        delete obj[propiedad];
      } else if (typeof obj[propiedad] === 'object') {
        this.limpiarObjeto(obj[propiedad]);
      }
    }
    return obj;
  }

  public setRenderer(renderer: Renderer2): void {
    //console.log('setRenderer');
    this._render = renderer;
  }

  public createElementByRender(options: IRenderElementOptions): HTMLElement {
    //console.log('createElementByRender');
    const { type, classes, content, clickAction, attributes } = options

    let tmpElement = this._render.createElement(type)
    if (content) {
      let tmpTextElement = this._render.createText(content)
      this._render.appendChild(tmpElement, tmpTextElement)
    }
    classes?.length && classes.forEach(cls => this._render.addClass(tmpElement, cls))
    // attrType && attrValue && this._render.setAttribute(tmpElement, attrType, attrValue)
    if (attributes) {
      for (let attrName in attributes) {
        if (attributes.hasOwnProperty(attrName)) {
          let attrValue = attributes[attrName];
          attrValue && this._render.setAttribute(tmpElement, attrName, attrValue);
        }
      }
    }

    clickAction && this._render.listen(tmpElement, 'click', (event: Event) => clickAction.apply(event))
    return tmpElement
  }

  orderByASC<T>(array: T[], property: string): T[] {
    //console.log('orderByASC');
    return array.slice().sort((a, b) => {
      const propA = this.getProperty(a, property);
      const propB = this.getProperty(b, property);

      if (propA < propB) {
        return -1;
      } else if (propA > propB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  orderByDESC<T>(array: T[], property: string): T[] {
    //console.log('orderByDESC');
    return array.slice().sort((a, b) => {
      const propA = this.getProperty(a, property);
      const propB = this.getProperty(b, property);

      if (propA > propB) {
        return -1;
      } else if (propA < propB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  getProperty(obj: any, path: string): any {
    //console.log('getProperty');
    const parts = path.split('.');
    let current = obj;

    for (const part of parts) {
      if (current.hasOwnProperty(part)) {
        current = current[part];
      } else {
        return undefined;
      }
    }

    return current;
  }

  /**
   * Calcula el porcentaje de descuento a partir del precio original y el precio descontado.
   *
   * @param {number} originalPrice - El precio original antes del descuento.
   * @param {number} discountedPrice - El precio después del descuento.
   * @returns {number} - El porcentaje de descuento calculado.
   */
  getDiscountPercentage(originalPrice: number,discountedPrice: number): number {    
    // Calcula el porcentaje de descuento y redondea el resultado a dos decimales
    const discountPercentage =
      ((originalPrice - discountedPrice) / originalPrice) * 100;
    return Math.round(discountPercentage * 100) / 100;
  }

  /**
   * Calcula el porcentaje de descuento a partir del precio original y el precio descontado.
   *
   * @param {number} price - El precio en formato numerico.
   */
  getPriceFormat(price: number): string {    
    return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(price);
  }

  fechaEsHoy(nDate:Date):boolean {    
    const newDate = new Date();
    // const fechaCita = nDate.split("T")[0];
    const fechaCita = nDate;
  
    const citaDate = new Date(fechaCita);  
    const newDateString = newDate.toISOString().split('T')[0];
    const newDateParametro = citaDate.toISOString().split('T')[0];
  
    if (newDateString === newDateParametro) {
      return true;
    } else{
      return false
    }
  
  }


  redirection(route: string): string {

    const session = localStorage.getItem('session');
    if(!session) return '';

    const sessionJson: { rol: string } = JSON.parse(session);
    const { rol } = sessionJson;

    if(rol === 'doctor') {
      return `doctor/${route}`;
    } else if (rol === 'user') {
      return `user/${route}`;
    } else {
      return `provider/${route}`
    }

  }


}



interface IRenderElementOptions {
  // attrType?: string;
  // attrValue?: string;
  attributes?: any;
  directives?: IDirectiveOpts[];
  classes?: string[];
  clickAction?: Function;
  content?: any;
  type: string;
}

interface IDirectiveOpts {
  directiveClass: any,
  entries?: any[]
}
