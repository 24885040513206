import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective } from '@angular/forms';
import { SweetAlertService } from '@services';


@Component({
  selector: 'form-multi-textarea',
  templateUrl: './form-multi-textarea.component.html',
  styleUrls: ['../../form-styles.scss', './form-multi-textarea.component.scss'],
})
export class FormMultiTextareaComponent implements AfterViewInit {
  @Input() controlName!: string;
  @Input() helper: string | undefined = 'Help textarea';
  @Input() placeholder: string = 'textarea placeholder';
  @Input() label: string = 'Default textarea label';
  @Input() txtNoData: string = 'Default text';
  @Input() limitArray: number = 10;
  @Input() required: boolean = true;
  @Input() idInput: string = 'idInput';

  @Output() arrayEmmiter = new EventEmitter<string[]>();

  @ViewChild('txAreaRef') txAreaRef: any;

  arrayData: string[] = [];
  selectedData: any;
  errors: any[] = [];
  value: any;

  constructor(
    private controlContainer: ControlContainer,
    private swalService: SweetAlertService
  ) {}

  ngAfterViewInit(): void {
    this.control.statusChanges.subscribe(() => {
      this.errors = [];
      if (!this.control.errors || !this.control.dirty) return;

      this.control.errors['minData'] &&
        this.errors.push(`Se debe agregar al menos un registro`);
      this.control.errors['minlength'] &&
        this.errors.push(
          `El campo nó debe tener menos de ${this.control.errors['minlength'].requiredLength} caracteres`
        );
      this.control.errors['maxlength'] &&
        this.errors.push(
          `El campo nó debe tener mas de ${this.control.errors['maxlength'].requiredLength} caracteres`
        );
      this.control.errors['email'] &&
        this.errors.push(`El correo electrónico debe ser una dirección válida`);
      this.control.errors['pattern'] &&
        this.errors.push(`El campo no corresponde a un patrón válido`);
    });
  }

  /** Get the control of the form */
  get control(): FormControl {
    const parentFormGroup = this.controlContainer as FormGroupDirective;
    return parentFormGroup.control.get(this.controlName) as FormControl;
  }

  /** Get the control invalid status */
  get controlInvalid(): boolean {
    return this.control?.invalid || false;
  }

  /** Get the control touched status */
  get controlTouched(): boolean {
    return this.control?.touched || false;
  }

  /** Get the control errors */
  get errorKeys(): string[] {
    return this.control?.errors ? Object.keys(this.control.errors) : [];
  }

  agregateArray() {
    if (this.arrayData.length >= this.limitArray) {
      setTimeout(() => {
        return this.swalService.lauchSwal(
          '',
          `Ya se han agregado ${this.limitArray} registros, elimine uno si desea agregar un registro nuevo !!`,
          'warning'
        );
      }, 200);
    } else {
      const texto = this.txAreaRef.nativeElement.value.trim();
      if (texto) {
        this.arrayData.push(texto);
        this.arrayEmmiter.emit(this.arrayData);
        setTimeout(() => {
          this.txAreaRef.nativeElement.value = '';
        }, 100);
      }
    }
  }

  selectData(index:number){
    this.selectedData = {
      index: index,
      data: this.arrayData[index]
    } 
    this.txAreaRef.nativeElement.value = this.selectedData.data;
  }

  

  cancelUpdate(){
    this.selectedData = undefined;
    this.txAreaRef.nativeElement.value = '';
  }

  confirmUpdate(){
    this.arrayData[this.selectedData.index] = this.txAreaRef.nativeElement.value;
    this.selectedData = undefined;
    setTimeout(() => {
      this.txAreaRef.nativeElement.value = '';
    }, 100);
  }

  eliminarData(index: number) {
    if (index > -1) {
      this.arrayData.splice(index, 1);
      this.arrayEmmiter.emit(this.arrayData);
      setTimeout(() => {
        this.txAreaRef.nativeElement.value = '';        
      }, 100);
    }
  }


}
