import { Injectable } from '@angular/core';
import { firstValueFrom, map } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { ApiRes, Branch, Company, CreateService } from '../../interfaces/information.interface';
import { EncryptService, SweetAlertService } from '@services';
import { ClinicRegisterData } from '@interfaces/appointments';
import { parseISO, getYear } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private apiService: ApiService,
              private swal: SweetAlertService,
              private encryptService: EncryptService) { }

  createCompany(body:Company) {
    return this.apiService.postRequest('company', body);
  }

  checkCompanyStatus() {
    return this.apiService.getRequest('company/aproved-status');
  }

  async updateSettingsCompany(body:Company) {

    let response = await firstValueFrom(this.apiService.putRequest('company',body));
    return response;
  }

  async createBranch(body:Branch) {

    let response = await firstValueFrom(this.apiService.postRequest('company/branch',body));
    return response;
  }

  async updateSettingsBranch(body:Branch) {

    let response = await firstValueFrom(this.apiService.putRequest('company/branch',body));
    return response;
  }

  async deleteBranch(idBranch:string) {

    let response = await firstValueFrom(this.apiService.deleteRequest(`company/branch/${idBranch}`));
    return response;
  }


  // Metodo para activar una empresa y asociar el rol provider al usuario creador.
  activeCompany() {
    return this.apiService.getRequest('user/company-activate');
  }

  // Metodo para obtener las sucurasles activas
  getActviveBranches(companyId: string) {
    return this.apiService.getRequest(`employee/branches/${companyId}`);
  }

  // Provider services
  async createProviderService(createService:CreateService) {

    let response = await firstValueFrom(this.apiService.postRequest('service',createService));
    return response;
  }

  async getCompanyServices(idCompany:string) {

    let response = await firstValueFrom(this.apiService.getRequest(`service/company/${idCompany}`));
    return response;
  }

  async updateProviderService(updateService:CreateService) {

    let response = await firstValueFrom(this.apiService.putRequest('service',updateService));
    return response;
  }


  async deleteProviderService(serviceID:string) {

    let response = await firstValueFrom(this.apiService.deleteRequest(`service/${serviceID}`));
    return response;
  }

  // Provider sub-services

  async createProviderSubService(formData:FormData) {

    let response = await firstValueFrom(this.apiService.postRequestFile('service/sub-service',formData));
    return response;
  }

  async updateProviderSubService(updateSubService:any) {

    let response = await firstValueFrom(this.apiService.putRequest('service/sub-service',updateSubService));
    return response;
  }

  async updateProviderSubServiceImages(formData:FormData) {

    let response = await firstValueFrom(this.apiService.putRequestFile('service/add/sub-services/images',formData));
    return response;
  }

  async deleteProviderSubServiceImages(body:any) {

    let response = await firstValueFrom(this.apiService.putRequest(`service/delete/sub-services/images`,body));
    return response;
  }

  
  async deleteProviderSubService(idSubService:string) {

    let response = await firstValueFrom(this.apiService.deleteRequest(`service/sub-service/${idSubService}`));
    return response;
  }

  // sub service
  async getSubServiceDetail(subServiceId:string, municipalityId:string) {

    let response = await firstValueFrom(this.apiService.getRequest(`search/subservice-detail/${subServiceId}/${municipalityId}`));
    return response;
  }

  getPatientByDocument(document: string, companyId: string) {
    return this.apiService.getRequest(`employee/manage-patients/${document}/${companyId}`);
  }

  getClinicRegisters(patientId: string, companyId: string) {
    return this.apiService.getRequest(`clinic-register/employee-registers/${patientId}/${companyId}`).pipe(
      map((apiRes: ApiRes) => {

        const { ok, message, body } = apiRes;
        if(!ok) return this.swal.lauchSwal('', message, 'warning');

        return body;
      })
    );
  }

  private getVersion(clinicRegisterId: string, patientId: string) {
    return this.apiService.getRequest(`clinic-register/employee/${clinicRegisterId}/${patientId}`).pipe(
      map((apiRes: ApiRes) => {
        const { body, message, ok } = apiRes;

        if(!ok) this.swal.lauchSwal('', message, 'warning');
        return body;
      })
    );
  }

  public getClinicRegister({ clinicRegister, patient: { patientId }, doctor: { doctorId }, type, clinicRegisterId }: ClinicRegisterData) {

    return this.getVersion(clinicRegisterId, patientId).pipe(
      map(({version: iv, createdAt}: any) => {

        // Parsea la fecha ISO a un objeto Date
        const date = parseISO(createdAt);

        // Obtiene el año de la fecha
        const anio = getYear(date);

        const key = this.encryptService.manageActions({ action: 'generateKey', data: { patientId, doctorId, type, anio } });
        const decryptData = this.encryptService.manageActions({ action: 'symmetricDecryptionByIv', data: clinicRegister, key, iv });
  
        return decryptData;
      })
    );
  }

}
