import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/local-storage.service';

export const loggedRedirectionGuard: CanActivateFn = (_route, _state) => {

  const localStorageService = inject(LocalStorageService);
  const user = localStorageService.getItem('usdt');
  const router = inject(Router);
  
  if(user) {
    router.navigate(['/home']);
    return false;
  } else {
    return true;
  } 
};
