<!-- 
  <div class="stepper">
    <ng-container *ngFor="let step of stepperList">
      <div class="stepper__item" [attr.data-step]="step.index" id="step_idx_{{step.index}}" [class.active]="step.index==current" >
        <span *ngIf="step.index==current" class="stepper__item__label text-center animated fadeIn">{{ step.label }}</span>
        <span class="stepper__item__index">{{ step.index }}</span>
      </div>
    </ng-container>
  </div>
  
  <div class="content">
    <div class="content__section" [style.border]="!bordered&&'none'">
      <ng-container *ngIf="currentStep" [ngTemplateOutlet]="currentStep.contentTemplate"></ng-container>
    </div>
  </div>
  
  <div class="actions">
    <button class="prev" (click)="prevStep()" [disabled]="disablePrev">{{prevButtonText}}</button>
    <button class="next" (click)="nextStep()" [disabled]="disableNext">{{nextButtonText}}</button>
  </div>
 
-->

<div class="stepper">
  <ng-container *ngFor="let step of getVisibleSteps()">
    <div class="stepper__item" [attr.data-step]="step.index" id="step_idx_{{step.index}}" [class.active]="step.index==current" >
      <span *ngIf="step.index==current" class="stepper__item__label text-center animated fadeIn">{{ step.label }}</span>
      <span class="stepper__item__index">{{ step.index }}</span>
    </div>
  </ng-container>
</div>

<div class="content">
  <div class="content__section" [style.border]="!bordered&&'none'">
    <ng-container *ngIf="currentStep" [ngTemplateOutlet]="currentStep.contentTemplate"></ng-container>
  </div>
</div>

<div class="actions" *ngIf="!buttonFixed">
  <button class="prev" (click)="prevStep()" [disabled]="disablePrev">{{prevButtonText}}</button>
  <button class="next" (click)="nextStep()" [disabled]="disableNext">{{nextButtonText}}</button>
</div>
<div class="actionsFixed" *ngIf="buttonFixed">
  <button class="prevFixed" (click)="prevStep()" [disabled]="disablePrev">{{prevButtonText}}</button>
  <button class="nextFixed" (click)="nextStep()" [disabled]="disableNext">{{nextButtonText}}</button>
</div>




