import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';

import { ModalService } from 'src/app/reusable-modal/modal.service';

import { ApiService, SweetAlertService } from '@services';
import { isEmpty, get, forEach } from 'lodash'
import { format, parse } from 'date-fns';
import { IStepper } from '../stepper/stepper.component';
import { IPillGroup } from '../pill-group-list/pill-group-list.component';
import { Subject, combineLatest, combineLatestAll, filter, forkJoin, merge, of, switchMap, takeUntil, tap } from 'rxjs';
import { AnimationOptions } from 'ngx-lottie';

interface IHour {
  start: number;
  end: number;
  numberOfAppointments: number;
  subServices: string[];
}

interface IShift {
  branch: string;
  office: string;
  hours: IHour[];
}

interface ISchedule {
  day: string;
  doctorId: string;
  shift: IShift | any;
}

@Component({
  selector: 'schedule-setter',
  templateUrl: './schedule-setter.component.html',
  styleUrls: ['./schedule-setter.component.scss']
})
export class ScheduleSetterComponent {
  @ViewChild('step1Content') step1Content!: TemplateRef<any>;
  @ViewChild('step2Content') step2Content!: TemplateRef<any>;
  @ViewChild('step3Content') step3Content!: TemplateRef<any>;

  @Input() data: any

  @Output() onCloseScheduleSetter = new EventEmitter<boolean>();

  private destroy$ = new Subject<void>();

  options: AnimationOptions = { path: 'assets/lottie-animations/calendar_edited.json' };
  loader: boolean = false;

  scheduleNameForm!: FormGroup
  scheduleTimeForm!: FormGroup
  scheduleServiceForm!: FormGroup

  branchsData: any[] = []
  hoursData: any[] = []

  startAvailableHours: any[] = []
  endAvailableHours: any[] = []

  selectedStartHour: any
  selectedEndHour: any
  selectedBranch: any

  scheduleList: Record<string, any> = {}
  availableHours: any[] = []
  rangeHours: any[] = []

  hoursListSelected: Array<any> = []
  serviceSelected: boolean = false
  subservices: any[] = []
  services: any[] = []

  currentDay: any
  daySelected!: string
  dayListSelected!: any
  branchSelected!: string

  disableNext: boolean = true
  disablePrevious: boolean = true

  subServicesUrlApi = 'service/sub-services'

  endPointShifts = 'schedule/shift'

  stepperList: IStepper[] = []

  weekDays: Record<string, any> = {
    "sunday": { "translate": "Domingo" },
    "monday": { "translate": "Lunes" },
    "tuesday": { "translate": "Martes" },
    "wednesday": { "translate": "Miércoles" },
    "thursday": { "translate": "Jueves" },
    "friday": { "translate": "Viernes" },
    "saturday": { "translate": "Sábado" }
  }

  weekDaysList: Array<any> = [
    {
      "id": "sunday",
      "checked": false,
      "disabled": false,
      "translate": "Domingo"
    },
    {
      "id": "monday",
      "checked": false,
      "disabled": false,
      "translate": "Lunes"
    },
    {
      "id": "tuesday",
      "checked": false,
      "disabled": false,
      "translate": "Martes"
    },
    {
      "id": "wednesday",
      "checked": false,
      "disabled": false,
      "translate": "Miércoles"
    },
    {
      "id": "thursday",
      "checked": false,
      "disabled": false,
      "translate": "Jueves"
    },
    {
      "id": "friday",
      "checked": false,
      "disabled": false,
      "translate": "Viernes"
    },
    {
      "id": "saturday",
      "checked": false,
      "disabled": false,
      "translate": "Sábado"
    }
  ]

  nameError = true
  startTimeError = true
  endTimeError = true
  ServiceError = true
  subserviceError = true

  numberOfAppointmentsList: any[] = []

  weekdaySelector = false

  scheduleSetup!: ISchedule

  constructor(
    private sweetAlert: SweetAlertService,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    if (!this.data) return
    let { branchId, day, doctorId, rangeHours, scheduleList, services, shifts } = this.data

    this.scheduleSetup = { day, doctorId, shift: { hours: [] } }

    // this.subservices = subservices
    // this.availableHours = this.getAvailableHours(shifts)
    this.branchSelected = branchId
    this.daySelected = day
    this.currentDay = this.weekDays[day]
    this.scheduleList = scheduleList
    this.rangeHours = rangeHours
    this.services = services

    this.startAvailableHours = []
    this.endAvailableHours = []

    this.numberOfAppointmentsList = [
      { id: 1, temporality: '60 minutos' },
      { id: 2, temporality: '30 minutos' },
      { id: 3, temporality: '20 minutos' },
      { id: 4, temporality: '15 minutos' },
      { id: 5, temporality: '10 minutos' }
    ]

    this.getSubservices();

    this.weekDaysList = this.weekDaysList.map(weekday => {
      if (weekday.id == day) weekday.checked = true
      return weekday
    })

    this.rangeHours = this.rangeHours.slice(0, -1);
    
    console.debug(this.rangeHours.length);

    this.rangeHours.forEach(rh => {
      let lapseTime = `${rh.split(':')[0]}:59 ${rh.split(' ')[1]}`
      this.startAvailableHours.push({ hour: rh, id: parse(rh, 'hh:mm aa', new Date()).getHours(), disable: false, setted: false })
      this.endAvailableHours.push({ hour: lapseTime, id: parse(rh, 'hh:mm aa', new Date()).getHours() + 1, disable: true, setted: false })
    })

    this.getAvailableHours(shifts)

    this.scheduleNameForm = this.formBuilder.group({ officeName: [null, Validators.required] })

    this.scheduleTimeForm = this.formBuilder.group({
      sizeTime: null,
      startHourSelector: [null],
      endHourSelector: [null]
    })

    this.scheduleServiceForm = this.formBuilder.group({
      branchSelector: [null, Validators.required],
      subbranchSelector: [null, Validators.required]
    })

    this.scheduleTimeForm.controls['sizeTime'].setValue(3)
    this.scheduleTimeForm.controls['startHourSelector'].setValue(null)
    // this.scheduleTimeForm.controls['branchSelector'].disable()

    this.validateDataErrors()
    this.toggleStatusWeekday(day)
  }

  ngAfterViewInit() {
    this.stepperList = [
      {
        index: 1,
        label: 'Información consultorio',
        contentTemplate: this.step1Content
      },
      {
        index: 2,
        label: 'Selección horario',
        contentTemplate: this.step2Content
      },
      {
        index: 3,
        label: 'Servicios',
        contentTemplate: this.step3Content
      }
    ]

    this.cdr.detectChanges()
  }

  private validateDataErrors() {
    let startHourControl = this.scheduleTimeForm.controls['startHourSelector'];
    let endHourControl = this.scheduleTimeForm.controls['endHourSelector'];

    merge(
      this.scheduleNameForm.statusChanges.pipe(
        tap((status) => this.disableNext = status == 'INVALID')
      ),
      startHourControl.valueChanges.pipe(
        tap(startHour => {
          this.setAvailableHours(startHour, undefined)
          this.validateHoursByDay({ startHour })
        })
      ),
      endHourControl.valueChanges.pipe(
        tap(endHour => {
          if (endHour) {
            this.validateHoursByDay({ endHour })
            this.scheduleServiceForm.controls['branchSelector'].enable()
            this.selectedEndHour = endHour
          }
        })
      ),
      combineLatest([startHourControl.valueChanges, endHourControl.valueChanges]).pipe(
        tap(([startHour, endHour]) => {
          this.disableNext = !endHour || !startHour || !(this.hoursListSelected.length > 0);
        })
      )
    ).pipe(takeUntil(this.destroy$)).subscribe();
  }

  private getAvailableHours(shifts: any[]): void {
    let temp: any[] = []
    this.rangeHours.forEach(rh => temp.push({ hour: rh, id: rh.split(':')[0], disable: false }))
    shifts.length && shifts.forEach(shift => [...shift.hours].forEach(({ start, end }) => this.setAvailableHours(start, end, true)))
  }

  public addScheduleHour() {
    let hourSelected = this.scheduleTimeForm.value

    this.hoursListSelected.push({
      start: `${hourSelected.startHourSelector}:00`,
      end: `${hourSelected.endHourSelector}:00`,
      id: `${hourSelected.startHourSelector}-${hourSelected.endHourSelector}`
    })

    this.scheduleTimeForm.controls['startHourSelector'].setValue(null)
    this.scheduleTimeForm.controls['endHourSelector'].setValue(null)
    this.scheduleTimeForm.controls['endHourSelector'].disable()

    this.setAvailableHours(hourSelected.startHourSelector, hourSelected.endHourSelector, true)
    this.disableNext = this.hoursListSelected.length == 0
  }

  public removeScheduleHour(identificator: string) {
    this.hoursListSelected = this.hoursListSelected.filter(hour => hour.id != identificator)
    this.setAvailableHours(identificator.split('-')[0], identificator.split('-')[1], true, false)
    this.disableNext = this.hoursListSelected.length == 0
  }

  private getSubservices() {
    if (!this.services.length) return

    let observables = this.services.map(serv => this.apiService.getRequest(`${this.subServicesUrlApi}/${serv._id}`))

    forkJoin(observables).subscribe({
      next: (obs: any[]) => {
        obs.forEach(({ ok, body }) => ok && this.subservices.push(...body))
      },
      error: (error) => {
        this.subservices = []
      }
    })
  }

  private setAvailableHours(startTime: string | undefined, endTime: string | undefined, setted: boolean = false, disabled: boolean = true) {
    let tmpStart: string
    let tmpEnd: string
    let istart: number
    let iend: number

    let startTimeTemp: any[] = [...this.startAvailableHours]
    let endTimeTemp: any[] = [...this.endAvailableHours]

    if (setted && startTime && endTime) {
      tmpStart = this.getNormalHour(startTime)
      tmpEnd = this.getNormalHour(endTime)

      istart = startTimeTemp.findIndex((r: any) => r.hour == tmpStart)
      iend = startTimeTemp.findIndex((r: any) => r.hour == tmpEnd)

      for (let i = istart; i < iend; i++) {
        startTimeTemp[i].disable = disabled
        startTimeTemp[i].setted = setted
      }

      for (let i = istart; i < iend; i++) {
        endTimeTemp[i].disable = disabled
        endTimeTemp[i].setted = setted
      }

      this.startAvailableHours = [...startTimeTemp]
      this.endAvailableHours = [...endTimeTemp]
    }

    if (!setted && startTime) {
      this.selectedStartHour = startTime

      endTimeTemp.forEach(recordTime => {
        if (!recordTime.setted) recordTime.disable = false
      })

      istart = endTimeTemp.findIndex((r: any) => r.id == Number(startTime))

      for (let i = 0; i <= istart; i++) endTimeTemp[i].disable = true

      this.endAvailableHours = [...endTimeTemp]

      this.scheduleTimeForm.controls['endHourSelector'].setValue(null)
      this.scheduleTimeForm.controls['endHourSelector'].enable()

      this.scheduleServiceForm.controls['branchSelector'].disable()
      this.scheduleServiceForm.controls['branchSelector'].setValue(null)
    }
  }

  private validateHoursByDay({ startHour, endHour }: any) {
    let tmpScheduleList: Record<string, any> = Object.assign(this.scheduleList)
    let weekDaysListFiltered = this.weekDaysList.filter(day => day.id != this.daySelected)

    if (startHour) {
      weekDaysListFiltered.forEach(wd => {
        let daySchedule = tmpScheduleList[wd.id]

        if (daySchedule.shifts.length > 0) {
          daySchedule.shifts.forEach((shift: any) => {
            let { hours } = shift

            hours.find((hrs: any) => {
              let { start, end, hourStatus } = hrs

              if (startHour >= start && startHour < end) {
                this.toggleStatusWeekday(wd.id)
                return true;
              } else {
                this.toggleStatusWeekday(wd.id, false)
                return false;
              }
            });
          });
        }
      });
    }

    if (this.selectedStartHour && endHour) {
      weekDaysListFiltered.forEach(wd => {
        let daySchedule = tmpScheduleList[wd.id]

        if (daySchedule.shifts.length > 0) {
          daySchedule.shifts.forEach((shift: any) => {
            let { hours } = shift

            hours.find((hrs: any) => {
              let { start, end, hourStatus } = hrs

              if (endHour > start && endHour <= end) {
                this.toggleStatusWeekday(wd.id)
                return true;
              } else {
                this.toggleStatusWeekday(wd.id, false)
                return false;
              }
            });
          });
        }
      });
    }
  }

  private getNormalHour(hour: string | number) {
    return format(parse(`${hour}:00`, 'HH:mm', new Date()), 'hh:mm a')
  }

  private toggleStatusWeekday(day: string, disabled = true) {
    this.weekDaysList = this.weekDaysList.map(weekday => {
      if (weekday.id == day) weekday.disabled = disabled
      return weekday
    })
  }

  public setSubServices(subServices: any) {
    this.scheduleServiceForm.controls['subbranchSelector'].setValue(subServices)
    this.disableNext = isEmpty(subServices)
  }

  changeWeekdays(_event: any) {
    this.dayListSelected = _event;
    let _days = Object.keys(this.dayListSelected)

    this.weekDaysList = this.weekDaysList.map(weekday => {
      if (weekday.id != this.daySelected) weekday.checked = _days.includes(weekday.id)
      return weekday
    });
  }

  changeStep(event: any) {
    this.disableNext = event.type === 'prev' ? false : true
    this.disablePrevious = event.type === 'prev' && event.index === 1
    this.validateDataErrors()
  }

  setAllConfiguration() {
    this.loader = true

    let { companyId, doctorId, subservices, services, rangeHours, shifts, day } = this.data
    let subServicesSelected = this.scheduleServiceForm.controls['subbranchSelector'].value
    let office = this.scheduleNameForm.controls['officeName'].value
    let sizeTime = this.scheduleTimeForm.controls['sizeTime'].value

    let scheduleList: any[] = []
    let _hours: Array<any> = []

    this.hoursListSelected.map((_hour) => {
      let tmp = { start: `${_hour.start.split(':')[0]}`, end: `${_hour.end.split(':')[0]}` }
      _hours.push(tmp)
    })

    let templateSchedule: any = {
      day,
      doctorId,
      shift: {
        branchId: this.branchSelected,
        numberOfAppointments: sizeTime || 3,
        companyId,
        office,
        hours: []
      }
    }

    let _days = [...Object.keys(this.dayListSelected || []), this.daySelected]

    _days.forEach((_day) => {
      let tmpSchedule = Object.assign({}, templateSchedule)
      let tmpHours: any = []

      _hours.forEach(_hrs => {
        let { start, end } = _hrs
        tmpHours.push({
          subServices: Object.keys(subServicesSelected),
          start,
          end
        })
      })

      tmpSchedule.day = _day
      tmpSchedule.shift.hours = tmpHours

      scheduleList.push(tmpSchedule)
    });

    // console.debug(scheduleList);

    let observables = scheduleList.map(schedule => this.apiService.postRequest(`${this.endPointShifts}`, schedule))

    forkJoin(observables).subscribe({
      next: () => {
        setTimeout(() => {
          this.loader = false
          this.sweetAlert.lauchSwalAsync('Agenda creada', 'Se generó correctamente la agenda', 'info').then(() => this.close())
        }, 2000)
      },
      error: (error) => {
        setTimeout(() => {
          this.loader = false
          this.sweetAlert.lauchSwalAsync('Hubo un error creando la agenda', error, 'error').then(() => this.close())
        }, 2000)
      }
    })
  }

  close() {
    this.modalService.closeModal()
    this.onCloseScheduleSetter.emit()
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}