import { createAction, props } from '@ngrx/store';

export const openModal = createAction(
  '[Reusable Modal] Open Modal',
   props<{ data: any }>()
);

export const closeModal = createAction(
  '[Reusable Modal] Close Modal'
);

