import { Component, ViewChild } from '@angular/core';
import { ModalAnchorDirective } from '../modal-anchor.directive';
import { ModalOptions } from '@interfaces/modal.reusable.interface';
import { Store } from '@ngrx/store';
import { closeModal } from '../state/modal.actions';

@Component({
  selector: 'app-reusable-modal',
  templateUrl: './reusable-modal.component.html',
  styleUrls: ['./reusable-modal.component.scss']
})
export class ReusableModalComponent {

  options: ModalOptions = {
    dataKeyboard: true,
    static: null,
    tittle: '',
    modalClass: 'modal-dialog',
    modalClassContent:'modal-content',
    modalClassBody:'modal-body',
    header:false,
    footer:false
  };

  @ViewChild(ModalAnchorDirective, { static: true })modalContent!: ModalAnchorDirective;
  constructor(private store: Store) {}

  public loadDynamicComponent(component: any, options?: ModalOptions, data?: any) {
    
    if(options) this.options = options;
    const componentFactory =  this.modalContent.viewContainerRef.createComponent(component);

    const componentInstance: any = componentFactory.instance;
    
    if (data) {
      componentInstance.data = data; // Asegúrate de que el componente tiene una propiedad 'inputData'
    } 

    return componentInstance;
  }

  public closeModal() {
    this.store.dispatch(closeModal());
    setTimeout(() =>this.modalContent.viewContainerRef.clear(), 200);
  }

}
