<span [id]="idInput" class="input__group input__textarea">
  <div class="box__label">
    <label class="input__label" [style.color]="labelColor" [style.fontSize]="labelFontSize" for="input_component">{{label}}<strong *ngIf="required"> *</strong></label>
    <i class="fa fa-question icon__helper" [title]="helper" *ngIf="helper"></i>
  </div>
  <div class="box__input">
    <textarea #txAreaRef [formControl]="control" [placeholder]="placeholder"  [style.border-radius]="borderRadius" [style.font-size]="fontSize" [ngClass]="{invalid: control.touched && control.invalid}" name="txArea" rows="2" [maxLength]="400"></textarea>
    <label class="input__label__counter" for="txArea">{{ txAreaRef.value.length }}/400</label>
    <i class="fa fa-close icon__clear" [ngClass]="{visible: txAreaRef.value != ''}" (click)="control.setValue('')" title="Limpiar"></i>
  </div>
  <div class="box__error" [ngClass]="{visible:errors.length}">
    <ul>
      <li class="box__error__item" *ngFor="let error of errors"><strong>* </strong>{{error}}</li>
    </ul>
  </div>
</span>


<!-- <input #inputRef type="text" name="input_component" [placeholder]="placeholder" autocomplete="off" [formControl]="control" [ngClass]="{invalid: control.touched && control.invalid}"> -->
<!-- <label class="input__label" for="txArea">Descripción servicio</label> -->
<!-- <label class="input__label__helper" for="txArea">La descripción debe ser corta y concisa. 400 caracteres max</label> -->