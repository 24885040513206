<app-landing-header></app-landing-header>
<main *ngIf="this.status === 'cancelled'" class="main">
    <section class="section">
        <div class="container-img">
            <img [src]="imgCancel" alt="imgcancell">
        </div>
        <div class="container-message">
            <p>Hola<span> {{nameUser}} </span> tu cita de<span> {{nameService}} </span> para el<span> {{appointmentDate}} </span>a las<span> {{hour}} </span>ha sido cancelada correctamente</p>
            <div>
                <custom-buttons (click)="goHome()" title="Inicio" text="Inicio" textSize="1.2rem" textColor="#fff" icon="ti-arrow-left ml-2" type="button" [inputBackgroundColor]="'danger'" ></custom-buttons>
            </div>
        </div>
    </section>
</main>