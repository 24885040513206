<div class="box__input">
  <i *ngIf="inputRef.value != ''" class="ti ti-search icon__search pointer"></i>
  <!-- <i *ngIf="microphone" class="mdi mdi-microphone icon__microphone" title="Buscar por voz"></i> -->
  <input
    type="text"
    class="input__form"
    #inputRef id="idInputSearch"
    [(ngModel)]="value"
    (input)="onInput($event)"
    (keyup.enter)="searchValue()"
    (ngModelChange)="onValueChange($event)"
    [placeholder]="placeholderInput"
    [pattern]="pattern"
    [ngClass]="{extended,full:inputRef.value != '', pd_left_48:inputRef.value != ''}"
    [class.shadow_input]="inputShadow"
    [ngStyle]="{'border-radius': borderRadius}"
    [style.height]="inputHeight"
    >

  <i
    class="fa fa-close  icon__clear"
    [ngStyle]="{'margin-right': marginRight}"
    [ngClass]="{visible: inputRef.value != '',right_125:microphone}"
    (click)="value=''; inputRef.value = ''"
    title="Limpiar">
  </i>
  <!-- <span *ngIf="microphone" class="vl" [ngClass]="{visible: inputRef.value != ''}"></span> -->
  <button [class.shadow_input]="inputShadow" [ngStyle]="{'display': display}" [style.height]="inputHeight"
    class="btn btn_search" title="Buscar" (click)="searchValue()" type="button">{{inputTxtBtn}}<i
      class="ti ti-search icon_padding fz_20"></i></button>

  <button *ngIf="microphone" class="btn btn_microphone" type="button" title="Haz búsquedas por voz"><i
      class="mdi mdi-microphone"></i></button>
  <div class="box__error" [ngClass]="{visible:errors.length}">
    <ul>
      <li class="box__error__item" *ngFor="let error of errors"><strong>* </strong>{{error}}</li>
    </ul>
  </div>
</div>
