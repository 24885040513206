import {  Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Branch, CellPhone, Municipio, Pet, Translation } from '@interfaces/information.interface';
import { ApplicationService, InformationService, SweetAlertService, UserService } from '@services';
import { AppointmentsService } from 'src/app/services/appointments.service';

import { Appointment, UserAppointment } from '@interfaces/appointments';
import { ModalService } from 'src/app/reusable-modal/modal.service';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { typeOfProviver } from 'src/app/pages/session/state/session.selectors';

@Component({
  selector: 'app-appointments-search',
  templateUrl: './appointments-search.component.html',
  styleUrls: ['./appointments-search.component.scss'],
})
export class AppointmentsSearchComponent implements OnInit {
  @Input() data: any;

  @ViewChild('userExists') userExists!: TemplateRef<any>;
  @ViewChild('userNotExists') userNotExists!: TemplateRef<any>;
  @ViewChild('acudienteFormView') acudienteFormView!: TemplateRef<any>;
  @ViewChild('acudienteExistView') acudienteExistView!: TemplateRef<any>;
  @ViewChild('userCitasView') userCitasView!: TemplateRef<any>;
  @ViewChild('petsFormView') petsFormView!: TemplateRef<any>;
  @ViewChild('petsExistView') petsExistView!: TemplateRef<any>;

  //datos de inicio
  documentNumber!: string;
  inputSubServiceId?: string;
  // branchId!: string;
  branchSelected!: Branch;
  viewHeader = true;
  // income = false;
  type!: any;
  disableIncome = false;

  modalSubsctiption!: Subscription;

  public currentView!: TemplateRef<any>;
  public currentAcudienteView!: TemplateRef<any>;
  public currentPetsView!: TemplateRef<any>;

  appointments: any[] = [];
  tempAppointments: any[] = [];
  tempAppointment: any[] = [];
  documentNumberAcudiente!: string;
  pasos = 1;
  // caseAppointment = '';

  userForm?: FormGroup;
  acudienteForm?: FormGroup;
  acudienteExistForm?: FormGroup;
  petForm?: FormGroup;

  public edadActual!: number;
  public edadActualAcudiente!: number | null;
  public user?: any;
  public acudiente?: any;
  public pets?: Pet[] = [];
  public petSelected?: Pet;
  public cellPhones: CellPhone[] = [];
  public cellPhonesAcudiente: CellPhone[] = [];
  public typesOfDocument: any[] = [];
  public genders: any[] = [];
  public martialStatus: any[] = [];
  public municipalities: any[] = [];

  public userConfirmar = false;
  public userExisteConfirmar = false;
  public acudienteConfirmar = false;
  public acudienteExisteConfirmar = false;
  public petsConfirmar = false;
  public petExisteConfirmar = false;
  public viewAcudiente = false;
  public viewPets = false;
  public viewCitas = false;

  public errorDocument = '';
  public errorAcudienteEdad = '';
  public customErrors: Record<string, string> = {};
  public customErrorsAcudiente: Record<string, string> = {};
  public selectedRelationShips: string = '';

  public makeNewAppointment: boolean = false;

  // datos para validacion de casos
  // appointmentType = 'User';
  userCase?: UserAppointment;
  guardianCase?: UserAppointment;
  petCase?: Pet;
  relationshipAcudiente = '';
  relationshipUser = '';

  fechaHoy = '';

  filteredTypesOfDocument: any[] = [];

  relationShips = [
    { value: 'Spouse/Partner', name: 'Esposo/a o Pareja' },
    { value: 'Child', name: 'Hijo/a' },
    { value: 'Mother', name: 'Madre' },
    { value: 'Father', name: 'Padre' },
    { value: 'Sibling', name: 'Hermano/a' },
    { value: 'Grandparent', name: 'Abuelo/a' },
    { value: 'Grandchild', name: 'Nieto/a' },
    { value: 'Uncle/Aunt', name: 'Tío/a' },
    { value: 'Nephew/Niece', name: 'Sobrino/a' },
    { value: 'Cousin', name: 'Primo/a' },
    { value: 'Half-Brother/Half-Sister', name: 'Medio hermano/a' },
    { value: 'Stepbrother/Stepsister', name: 'Hermanastro/a' },
    { value: 'Stepfather/Stepmother', name: 'Padrastro/Madrastra' },
    { value: 'Brother-in-law/Sister-in-law', name: 'Cuñado/a' },
    { value: 'Son-in-law/Daughter-in-law', name: 'Yerno/Nuera' },
    { value: 'Father-in-law/Mother-in-law', name: 'Suegro/a' },
    { value: 'Great-grandparent', name: 'Bisabuelo/a' },
    { value: 'Great-grandchild', name: 'Bisnieto/a' },
    { value: 'Godson/Goddaughter', name: 'Ahijado/a' },
    { value: 'Godfather/Godmother', name: 'Padrino/Madrina' },
  ];

  gendersPets = [
    { value: 'Male', name: 'Macho' },
    { value: 'Female', name: 'Hembra' },
  ];

  translateString: Translation[] = [
    { term: 'No registrado', translate: 'No registrado' },
    { term: 'Male', translate: 'Macho' },
    { term: 'Female', translate: 'Hembra' },
  ];

  sterilizationStates = [
    { value: false, name: 'NO' },
    { value: true, name: 'SI' },
  ];

  inputColumns = {
    pet: true,
    profesional: true,
    sucursales: false,
    direccion: false,
    fecha: true,
    servicio: true,
    opciones: true,
    estados: false,
  };

  constructor(
    private appointmentsService: AppointmentsService,
    private userService: UserService,
    public appService: ApplicationService,
    private infoService: InformationService,
    private swalService: SweetAlertService,
    public modalService: ModalService,
    private store: Store
  ) {
    this.customErrors['menorEdad'] = 'El Acudiente debe ser mayor de 18 años';
    this.customErrors['correoExiste'] =
      'Este correo ya se encuentra registrado';
    this.customErrors['correoAcudiente'] =
      'Los correos del usuario y del acudiente no pueden ser iguales';
  }

  ngOnInit(): void {
    this.fechaHoy = this.appService.dateOperations('today');
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.modalSelector();
    }, 200);
  }

  waitForSeconds(seconds: number): Promise<boolean> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, seconds);
    });
  }

  async iniciarPetForm() {
    this.petForm = new FormGroup({
      petName: new FormControl<string | null>(null, Validators.required),
      species: new FormControl<string | null>(null, Validators.required),
      breed: new FormControl<string | null>(null, Validators.required),
      gender: new FormControl<string | null>(null, Validators.required),
      color: new FormControl<string | null>(null, Validators.required),
      sterilized: new FormControl<boolean>(false, Validators.required),
      birth_date: new FormControl(null, Validators.required),
      age: new FormControl<number | null>({ value: null, disabled: true }),
    });

    this.petForm?.controls['birth_date'].valueChanges.subscribe((resp) => {
      this.petForm?.controls['age'].setValue(
        this.getYearsToCurrentDatePets(resp)
      );
    });
  }

  async iniciarUserForm() {
    try {
      this.typesOfDocument = await this.infoService.getDocumentTypes();
      this.genders = await this.infoService.getGenders();
    } catch (error) {
      this.typesOfDocument = [];
      this.genders = [];
    }

    this.infoService
      .getMunicipalities()
      .then((resp) => {
        this.municipalities = resp;
      })
      .catch((e) => {
        this.municipalities = [];
      });

    this.userForm = new FormGroup({
      numeroDocumento: new FormControl<string | null>(
        { value: this.documentNumber || null, disabled: true },
        Validators.required
      ),
      tipoDocumento: new FormControl<string | null>(null, Validators.required),
      fechaNacimiento: new FormControl(null, Validators.required),
      years: new FormControl<string | null>({ value: null, disabled: true }),
      nombres: new FormControl<string | null>(null, Validators.required),
      apellidos: new FormControl<string | null>(null, Validators.required),
      correo: new FormControl(''),
      telefono: new FormControl('', [
        Validators.required,
        Validators.pattern(/^3\d{9,9}/),
      ]),
      genero: new FormControl<string | null>(null),
      municipio: new FormControl<Municipio | null>(null, Validators.required),
      direccion: new FormControl<string | null>(null),
      barrio: new FormControl<string | null>(null),
    });

    this.userForm.controls['correo'].valueChanges.subscribe((resp) => {
      if (resp === '') {
        this.userForm?.controls['correo'].setErrors(null);
      }
    });

    this.userForm.controls['fechaNacimiento'].valueChanges.subscribe((resp) => {
      const age = this.calcularEdad(resp);
      this.userForm?.controls['years'].setValue(
        this.getYearsToCurrentDate(resp)
      );
      this.filteredTypesOfDocument = this.filterDocumentsByAge(age);
      if (
        !this.userForm?.controls['tipoDocumento'].value ||
        !this.filteredTypesOfDocument.find(
          (doc) => doc.type === this.userForm?.controls['tipoDocumento'].value
        )
      ) {
        this.userForm?.controls['tipoDocumento'].setValue(null);
      }
    });

    let telefono = this.userForm.controls['telefono'];
    telefono.valueChanges.subscribe((number) => {
      if (telefono.valid)
        this.infoService
          .verifyWhatsAppNumber(number)
          .subscribe((existe: boolean) => {
            this.cellPhones = [
              {
                phone: number,
                llamadas: true,
                whatsapp: existe,
                prefix: '+57',
                confirmadoWsp: 0,
                confirmadoLLamadas: 0,
              },
            ];
          });
    });

    // Inicializa la lista filtrada
    this.filteredTypesOfDocument = this.filterDocumentsByAge(
      this.calcularEdad(this.userForm.get('fechaNacimiento')?.value)
    );
  }

  async iniciarAcudienteExistForm() {
    this.relationshipAcudiente = '';
    this.acudienteExistForm = new FormGroup({
      relationship: new FormControl<string | null>(null, Validators.required),
    });
  }

  async iniciarAcudienteForm(numeroDocumento: string) {
    try {
      this.municipalities = await this.infoService.getMunicipalities();
      this.typesOfDocument = await this.infoService.getDocumentTypes();
      this.genders = await this.infoService.getGenders();
    } catch (error) {
      this.municipalities = [];
      this.typesOfDocument = [];
      this.genders = [];
    }

    this.acudienteForm = new FormGroup({
      // seccion 1 basicos
      numeroDocumentoAc: new FormControl<string | null>(
        { value: numeroDocumento || null, disabled: true },
        Validators.required
      ),
      numeroDocumentoAcForm: new FormControl<string>(
        numeroDocumento,
        Validators.required
      ),
      fechaNacimientoAc: new FormControl(null, Validators.required),
      yearsAc: new FormControl<string | null>({ value: null, disabled: true }),
      tipoDocumentoAc: new FormControl<string | null>(
        null,
        Validators.required
      ),
      nombresAc: new FormControl<string | null>(null, Validators.required),
      apellidosAc: new FormControl<string | null>(null, Validators.required),
      telefonoAc: new FormControl('', [
        Validators.required,
        Validators.pattern(/^3\d{9,9}/),
      ]),
      correoAc: new FormControl(''),
      generoAc: new FormControl<string | null>(null),
      direccionAc: new FormControl<string | null>(null),
      barrioAc: new FormControl<string | null>(null),
      municipioAc: new FormControl<Municipio | null>(null, Validators.required),

      relationship: new FormControl<string | null>(null, Validators.required),
    });

    this.acudienteForm.controls['fechaNacimientoAc'].valueChanges.subscribe(
      (resp) => {
        this.acudienteForm?.controls['yearsAc'].setValue(
          this.getYearsToCurrentDateAcudiente(resp)
        );
      }
    );

    this.acudienteForm.controls['relationship'].valueChanges.subscribe(
      (resp) => {
        this.relationshipUser = resp;
      }
    );

    this.acudienteForm.controls['correoAc'].valueChanges.subscribe((resp) => {
      if (resp === '') {
        this.acudienteForm?.controls['correoAc'].setErrors(null);
      } else if (
        resp !== '' &&
        resp === this.userForm?.controls['correo'].value
      ) {
        this.acudienteForm?.controls['correoAc'].setErrors({
          correoAcudiente: true,
        });
        this.acudienteForm?.controls['correoAc'].markAsTouched();
      }
    });

    let telefono = this.acudienteForm.controls['telefonoAc'];
    telefono.valueChanges.subscribe((number) => {
      if (telefono?.valid)
        this.infoService
          .verifyWhatsAppNumber(number)
          .subscribe((existe: boolean) => {
            this.cellPhonesAcudiente = [
              {
                phone: number,
                llamadas: true,
                whatsapp: existe,
                prefix: '+57',
                confirmadoWsp: 0,
                confirmadoLLamadas: 0,
              },
            ];
          });
    });
    setTimeout(() => {
      this.hacerScroll('idFormAcudiente');
    }, 200);
  }

  //
  calcularEdad(fechaNacimiento: string): number {
    const fechaNac = new Date(fechaNacimiento);
    if (isNaN(fechaNac.getTime())) {
      console.error('La fecha de nacimiento no es válida.');
      return 0;
    }

    const fechaActual = new Date();
    const diferenciaTiempo = fechaActual.getTime() - fechaNac.getTime();
    const edadEnAnios = Math.floor(diferenciaTiempo / 31536000000); // 1 año = 31536000000 milisegundos

    if (edadEnAnios < 0) {
      return 0;
    }
    return edadEnAnios;
  }

  filterDocumentsByAge(age: number): any[] {
    let filteredDocs = this.typesOfDocument;
    if (age < 18) {
      filteredDocs = this.typesOfDocument.filter(
        (doc) => doc.type !== 'CC' && doc.type !== 'CE'
      );
    }
    return filteredDocs.map((doc) => ({
      ...doc,
      displayValue: `${doc.type} - ${doc.name}`,
    }));
  }

  getYearsToCurrentDate(fecha: string | null): string {
    if (!fecha) {
      return '';
    } else {
      var hoy = new Date();
      var cumpleanos = new Date(fecha);
      var edad = hoy.getFullYear() - cumpleanos.getFullYear();
      var m = hoy.getMonth() - cumpleanos.getMonth();

      if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
        edad--;
      }
      this.edadActual = edad;
      if (this.edadActual < 18 && this.type !== 'Veterinary') {
        this.viewAcudiente = true;
        this.pasos = 2;
        this.currentAcudienteView = null!;
      } else {
        this.viewAcudiente = false;
        this.pasos = 1;
      }
      return `${edad} Años`;
    }
  }

  getYearsToCurrentDateAcudiente(fecha: string | null): string {
    if (!fecha) {
      return '';
    } else {
      var hoy = new Date();
      var cumpleanos = new Date(fecha);
      var edad = hoy.getFullYear() - cumpleanos.getFullYear();
      var m = hoy.getMonth() - cumpleanos.getMonth();

      if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
        edad--;
      }
      this.edadActualAcudiente = edad;
      if (this.edadActualAcudiente < 18) {
        this.pasos = 2;
        this.errorAcudienteEdad = 'El acudiente debe ser mayor de 18 años.';
        this.acudienteForm?.controls['fechaNacimientoAc'].setErrors({
          menorEdad: true,
        });
      }
      return `${edad} Años`;
    }
  }

  getYearsToCurrentDatePets(fecha: string | null): number | null {
    if (!fecha) {
      return null;
    } else {
      var hoy = new Date();
      var cumpleanos = new Date(fecha);
      var edad = hoy.getFullYear() - cumpleanos.getFullYear();
      var m = hoy.getMonth() - cumpleanos.getMonth();

      if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
        edad--;
      }
      return edad;
    }
  }

  getMunicipioMember(municipio: Municipio | undefined): any {
    if (municipio) {
      return {
        cityId: municipio._id,
        cityName: municipio.municipality,
      };
    } else {
      return undefined;
    }
  }

  async iniciarTempUserForm() {
    try {
      this.typesOfDocument = await this.infoService.getDocumentTypes();
      this.genders = await this.infoService.getGenders();
    } catch (error) {
      this.typesOfDocument = [];
      this.genders = [];
    }

    this.infoService
      .getMunicipalities()
      .then((resp) => {
        this.municipalities = resp;
      })
      .catch((e) => {
        this.municipalities = [];
      });

    // Inicialización del formulario con datos de this.data, sin setear el campo municipio ni dirección
    this.userForm = new FormGroup({
      numeroDocumento: new FormControl<string | null>(
        {
          value: this.data?.tempAppointment?.numeroDocumento || null,
          disabled: true,
        },
        Validators.required
      ),
      tipoDocumento: new FormControl<string | null>(
        this.data?.tempAppointment?.tipoDocumento || null,
        Validators.required
      ),
      fechaNacimiento: new FormControl(
        this.data?.tempAppointment?.patientDetails?.[0]?.birthdate?.[0] || null,
        Validators.required
      ),
      years: new FormControl<string | null>({ value: null, disabled: true }),
      nombres: new FormControl<string | null>(
        this.data?.tempAppointment?.nombres || null,
        Validators.required
      ),
      apellidos: new FormControl<string | null>(
        this.data?.tempAppointment?.apellidos || null,
        Validators.required
      ),
      correo: new FormControl(''),
      telefono: new FormControl(this.data?.tempAppointment?.telefono || '', [
        Validators.required,
        Validators.pattern(/^3\d{9,9}/),
      ]),
      genero: new FormControl<string | null>(
        this.data?.tempAppointment?.patientDetails?.[0]?.gender?.[0] || null
      ),
      municipio: new FormControl<Municipio | null>(null, Validators.required), // Municipio queda sin setear
      direccion: new FormControl<string | null>(null), // Dirección queda sin setear
      barrio: new FormControl<string | null>(
        this.data?.branchSelected?.addressSettings?.neighbor || null
      ),
    });

    // Lógica de observadores en los controles
    this.userForm.controls['correo'].valueChanges.subscribe((resp) => {
      if (resp === '') {
        this.userForm?.controls['correo'].setErrors(null);
      }
    });

    this.userForm.controls['fechaNacimiento'].valueChanges.subscribe((resp) => {
      const age = this.calcularEdad(resp);
      this.userForm?.controls['years'].setValue(
        this.getYearsToCurrentDate(resp)
      );
      this.filteredTypesOfDocument = this.filterDocumentsByAge(age);
      if (
        !this.userForm?.controls['tipoDocumento'].value ||
        !this.filteredTypesOfDocument.find(
          (doc) => doc.type === this.userForm?.controls['tipoDocumento'].value
        )
      ) {
        this.userForm?.controls['tipoDocumento'].setValue(null);
      }
    });

    let telefono = this.userForm.controls['telefono'];
    telefono.valueChanges.subscribe((number) => {
      if (telefono.valid)
        this.infoService
          .verifyWhatsAppNumber(number)
          .subscribe((existe: boolean) => {
            this.cellPhones = [
              {
                phone: number,
                llamadas: true,
                whatsapp: existe,
                prefix: '+57',
                confirmadoWsp: 0,
                confirmadoLLamadas: 0,
              },
            ];
          });
    });

    // Inicializa la lista filtrada
    this.filteredTypesOfDocument = this.filterDocumentsByAge(
      this.calcularEdad(this.userForm.get('fechaNacimiento')?.value)
    );
  }

  modalSelector() {
    if (this.data.viewHeader == false) {
      this.viewHeader = false;
    }
    if (this.data.inputSubServiceId) {
      this.inputSubServiceId = this.data.inputSubServiceId;
    }
    if (this.data.type) {
      this.type = this.data.type;
    }
    if (this.data.disableIncome) {
      this.disableIncome = this.data.disableIncome;
    }

    this.documentNumber = this.data.documentNumber;
    // this.branchId = this.data.branchId;
    this.branchSelected = this.data.branchSelected;
    // this.income = this.data.income;

    this.store.select(typeOfProviver).subscribe((type) => {
      if (!this.type) {
        this.type = type;
      }

      if (
        this.data.documentNumber &&
        this.data.branchSelected.branchId &&
        this.type
      ) {
        this.limpiarDatos();
        this.appointmentsService
          .getAppointmentsBranchDocument(
            this.documentNumber,
            this.data.branchSelected.branchId,
            this.type
          )
          .subscribe((resp: any) => {
            this.tempAppointments = resp?.body?.temporalAppointments;
            this.appointments = resp.body.appointments;
            this.currentAcudienteView = null!;
            this.currentPetsView = null!;
            this.userConfirmar = false;
            this.userExisteConfirmar = false;
            this.acudienteConfirmar = false;
            this.acudienteExisteConfirmar = false;
            this.viewAcudiente = false;
            this.viewPets = false;
            this.viewCitas = false;
            this.relationshipAcudiente = '';
            this.userCase = undefined;
            const exists = resp.body.exists;

            if (
              this.data.tempAppointment &&
              this.data.tempAppointment.patientDetails.patientId == null
            ) {
              this.tempAppointment = this.data.tempAppointment;
              this.user = null;
              this.pets = [];
              this.acudiente = null;
              this.iniciarTempUserForm();
              this.currentView = this.userNotExists;
            } else if (exists) {
              this.tempAppointment = this.data.tempAppointment;
              this.user = resp.body.user;
              this.pets = resp.body.pets || [];
              this.edadActual = this.calcularEdad(resp.body.user.birthDate);
              this.currentView = this.userExists;
            } else {
              this.user = null;
              this.pets = [];
              this.acudiente = null;
              this.iniciarUserForm();
              this.currentView = this.userNotExists;
            }
          });
      }
    });
  }

  hacerScroll(id: string, margen?: number) {
    const elementoObjetivo = document.getElementById(id);

    if (elementoObjetivo) {
      const margenSuperior = margen ? margen : 100;
      const posicion = elementoObjetivo.getBoundingClientRect().top;
      const modalUser = document.getElementById('modal_user') as HTMLElement;
      const scrollTarget = modalUser.scrollTop + posicion - margenSuperior;

      if (!modalUser) return;

      modalUser.scrollTo({
        top: scrollTarget,
        behavior: 'smooth',
      });
    }
  }

  searchValueEmmiter(numeroDocumento: string) {
    this.errorDocument = '';
    if (this.documentNumber == numeroDocumento) {
      this.errorDocument =
        'El número de documento del Acudiente debe ser diferente al del Paciente.';
      this.currentAcudienteView = null!;
    } else {
      this.userService.getUserByDocument(numeroDocumento).then((resp: any) => {
        if (resp.body.exists) {
          this.acudiente = resp.body;

          this.edadActualAcudiente = this.calcularEdad(resp.body.birthDate);
          this.iniciarAcudienteExistForm();
          this.currentAcudienteView = this.acudienteExistView;
          this.documentNumberAcudiente = numeroDocumento;
          setTimeout(() => {
            this.hacerScroll('idAcudienteExist');
          }, 200);
        } else {
          this.acudienteForm = null!;
          this.currentAcudienteView = this.acudienteFormView;
          this.iniciarAcudienteForm(numeroDocumento);
          setTimeout(() => {
            this.hacerScroll('idFormAcudiente');
          }, 200);
        }
      });
    }
  }

  confirmarUserExiste() {
    if (this.type === 'Veterinary') {
      this.iniciarPetForm();
      //TODO: viewPets
      // this.appointmentType = 'Veterinary';
      this.userExisteConfirmar = true;
      this.viewPets = true;
      if (this.pets?.length === 0) {
        this.currentPetsView = this.petsFormView;
      } else {
        this.currentPetsView = this.petsExistView;
      }

      setTimeout(() => {
        this.hacerScroll('idBuscarPets');
      }, 200);
    } else if (this.edadActual !== null && this.edadActual !== undefined) {
      if (this.edadActual < 18) {
        // Incluye el caso cuando edadActual es 0
        this.viewAcudiente = true;
        this.userExisteConfirmar = true;
        this.viewCitas = false;
      } else {
        // Mayores o igual a 18
        this.viewCitas = true;
        this.userExisteConfirmar = true;
        setTimeout(() => {
          this.hacerScroll('idCitasView', 170);
        }, 300);
      }
    } else {
      console.error('edad no está definida');
    }
  }

  cancelarUserExiste() {
    this.userExisteConfirmar = false;
    this.viewCitas = false;
    this.viewPets = false;
    this.viewAcudiente = false;
    this.currentAcudienteView = null!;
    this.currentPetsView = null!;
    this.relationshipAcudiente = '';

    this.disabledSecundaryButton();
  }

  disabledSecundaryButton(): void {
    this.cancelarPetForm();
    this.cancelarPetSelected();
    this.cancelarAcudienteExiste();
    this.cancelarAcudiente();
  }

  async confirmarUser(form: FormGroup) {
    if (!this.userForm?.controls['municipio'].value) {
      this.userForm?.controls['municipio'].setErrors({ invalid: true });
    }

    if (form.valid) {
      this.userCase = {
        apellidos: this.userForm?.value.apellidos,
        nombres: this.userForm?.value.nombres,
        fechaNacimiento: this.userForm?.value.fechaNacimiento,
        numeroDocumento: this.documentNumber,
        tipoDocumento: this.userForm?.value.tipoDocumento,
        correo: this.userForm?.value.correo,
        cellPhones: this.cellPhones || [],
        genero: this.userForm?.value.genero,
        municipio: this.getMunicipioMember(this.userForm?.value.municipio),
        direccion: this.userForm?.value.direccion,
        barrio: this.userForm?.value.barrio,
        tipoCuenta: 'Usuario',
        registerData: {
          method: 'form',
          platform: 'web',
          registerBy: 'yourself',
        },
      };

      if (this.userCase.correo === null) {
        delete this.userCase.correo;
      }

      if (this.type === 'Veterinary') {
        //TODO: viewPets
        // this.appointmentType = 'Veterinary';
        this.userConfirmar = true;
        this.viewPets = true;
        this.currentPetsView = this.petsFormView;
        this.userForm?.disable();
        this.iniciarPetForm();
        setTimeout(() => {
          this.hacerScroll('idBuscarPets');
        }, 200);
      } else {
        this.userConfirmar = true;
        this.userForm?.disable();
        this.confirmarUserExiste();
      }
      setTimeout(() => {
        this.hacerScroll('idBuscarAcudiente');
      }, 200);
    } else {
      this.userForm?.markAllAsTouched();
      const requiredFields = [
        'numeroDocumento',
        'fechaNacimiento',
        'tipoDocumento',
        'nombres',
        'apellidos',
        'municipio',
        'telefono',
      ];

      if (!this.userForm?.controls['municipio'].value) {
        this.userForm?.controls['municipio'].setErrors({ invalid: true });
      }

      const swalResult = await this.swalService.lauchSwalAsync(
        '',
        'Debes registrar los campos en color rojo, ya que son obligatorios.',
        'warning'
      );
      if (swalResult) {
        setTimeout(() => {
          const scrollTo = requiredFields.find(
            (field) => this.userForm?.controls[field].invalid
          );
          if (scrollTo) {
            this.hacerScroll(
              `idInput${scrollTo.charAt(0).toUpperCase() + scrollTo.slice(1)}`
            );
          }
        }, 300);
      }
    }
  }

  limpiarDatos() {
    this.userConfirmar = false;
    this.acudienteConfirmar = false;
    this.currentAcudienteView = null!;
    this.currentPetsView = null!;
    this.currentView = null!;
    this.viewCitas = false;
    this.relationshipAcudiente = '';
    this.relationshipUser = '';
  }

  cancelarUser() {
    this.userConfirmar = false;
    this.currentAcudienteView = null!;
    this.currentPetsView = null!;
    this.userForm?.enable();
    this.userForm?.get('numeroDocumento')?.disable();
    this.userForm?.get('years')?.disable();
    this.viewCitas = false;
    this.relationshipAcudiente = '';
    setTimeout(() => {
      this.hacerScroll('idUserForm');
    }, 200);

    this.disabledSecundaryButton();
  }

  cancelarAcudiente() {
    this.acudienteConfirmar = false;
    this.acudienteForm?.enable();
    this.acudienteForm?.get('numeroDocumentoAc')?.disable();
    this.acudienteForm?.get('yearsAc')?.disable();
    this.viewCitas = false;
    this.relationshipAcudiente = '';
    this.guardianCase = undefined;
    setTimeout(() => {
      this.hacerScroll('idFormAcudiente');
    }, 200);
  }

  async confirmarAcudiente(form: FormGroup) {
    const ncorreoAc = this.acudienteForm?.controls['correoAc'].value;
    const nCorreo = this.userForm?.controls['correo'].value;

    if (!this.acudienteForm?.controls['municipioAc'].value) {
      this.acudienteForm?.controls['municipioAc'].setErrors({ invalid: true });
    }

    if (ncorreoAc !== '' && ncorreoAc === nCorreo) {
      this.acudienteForm?.controls['correoAc'].setErrors({
        correoAcudiente: true,
      });
      this.acudienteForm?.controls['correoAc'].markAllAsTouched();
    }

    if (form.valid) {
      const nForm = {
        apellidos: this.acudienteForm?.value.apellidosAc,
        nombres: this.acudienteForm?.value.nombresAc,
        fechaNacimiento: this.acudienteForm?.value.fechaNacimientoAc,
        numeroDocumento: this.acudienteForm?.value.numeroDocumentoAcForm,
        tipoDocumento: this.acudienteForm?.value.tipoDocumentoAc,
        correo: this.acudienteForm?.value.correoAc,
        cellPhones: this.cellPhonesAcudiente || [],
        genero: this.acudienteForm?.value.generoAc,
        municipio: this.getMunicipioMember(
          this.acudienteForm?.value.municipioAc
        ),
        direccion: this.acudienteForm?.value.direccionAc,
        barrio: this.acudienteForm?.value.barrioAc,
        tipoCuenta: 'Usuario',
        registerData: {
          method: 'form',
          platform: 'web',
          registerBy: 'yourself',
        },
      };

      this.acudienteConfirmar = true;
      this.pasos = 2;
      this.acudienteForm?.disable();
      this.confirmarUserExiste();
      this.viewCitas = true;
      this.guardianCase = nForm;

      if (this.guardianCase.correo === null) {
        delete this.guardianCase.correo;
      }
    } else {
      this.acudienteForm?.markAllAsTouched();
      const requiredFields = [
        'numeroDocumentoAc',
        'fechaNacimientoAc',
        'tipoDocumentoAc',
        'nombresAc',
        'apellidosAc',
        'relationship',
        'telefonoAc',
        'correoAc',
        'municipioAc',
      ];

      const swalResult = await this.swalService.lauchSwalAsync(
        '',
        'Debes registrar los campos en color rojo, ya que son obligatorios',
        'warning'
      );
      if (swalResult) {
        setTimeout(() => {
          const scrollTo = requiredFields.find(
            (field) => this.acudienteForm?.controls[field].invalid
          );
          if (scrollTo) {
            this.hacerScroll(
              `idInput${scrollTo.charAt(0).toUpperCase() + scrollTo.slice(1)}`
            );
          }
        }, 300);
      }
    }

    setTimeout(() => {
      this.hacerScroll('idCitasView', 200);
    }, 200);
  }

  async confirmarAcudienteExiste() {
    if (this.acudienteExistForm?.valid) {
      this.pasos = 2;
      this.viewCitas = true;
      this.guardianCase = undefined;
      this.acudienteExisteConfirmar = true;
      this.relationshipAcudiente = this.acudienteExistForm?.value.relationship;

      this.acudienteExistForm?.disable();
    } else {
      this.acudienteExistForm?.markAllAsTouched();
      const requiredFields = ['relationship'];
      const swalResult = await this.swalService.lauchSwalAsync(
        '',
        'Debes seleccionar cuál es la relación del acudiente con el paciente',
        'warning'
      );
      if (swalResult) {
        setTimeout(() => {
          const scrollTo = requiredFields.find(
            (field) => this.acudienteForm?.controls[field].invalid
          );
          if (scrollTo) {
            this.hacerScroll(
              `idInput${scrollTo.charAt(0).toUpperCase() + scrollTo.slice(1)}`
            );
          }
        }, 300);
      }
    }

    setTimeout(() => {
      this.hacerScroll('idCitasView', 200);
    }, 200);
  }

  cancelarAcudienteExiste() {
    this.viewCitas = false;
    this.acudienteExisteConfirmar = false;
    this.currentAcudienteView = null!;
    this.relationshipAcudiente = '';
    this.relationshipUser = '';
  }

  async confirmarPetForm() {
    if (this.petForm!.valid) {
      this.petCase = {
        petName: this.petForm?.value.petName,
        species: this.petForm?.value.species,
        breed: this.petForm?.value.breed,
        gender: this.petForm?.value.gender,
        color: this.petForm?.value.color,
        sterilized: this.petForm?.value.sterilized,
        birth_date: this.petForm?.value.birth_date,
      };

      this.pasos = 2;
      this.petForm?.disable();
      this.petsConfirmar = true;
      this.viewCitas = true;

      setTimeout(() => {
        this.hacerScroll('idCitasView', 170);
      }, 300);
    } else {
      this.petForm!.markAllAsTouched();
      const requiredFields = [
        'petName',
        'species',
        'breed',
        'gender',
        'color',
        'sterilized',
        'birth_date',
      ];

      const swalResult = await this.swalService.lauchSwalAsync(
        '',
        'Debes registrar los campos en color rojo, ya que son obligatorios',
        'warning'
      );
      if (swalResult) {
        setTimeout(() => {
          const scrollTo = requiredFields.find(
            (field) => this.petForm!.controls[field].invalid
          );
          if (scrollTo) {
            this.hacerScroll(
              `idInput${scrollTo.charAt(0).toUpperCase() + scrollTo.slice(1)}`
            );
          }
        }, 300);
      }
    }
  }

  cancelarPetForm() {
    this.petForm?.enable();
    this.viewCitas = false;
    this.petsConfirmar = false;
  }

  petSelectedEmitter(pet: Pet) {
    this.petSelected = pet;
  }

  async confirmarPetexiste() {
    this.pasos = 2;
    this.viewCitas = true;
    this.petCase = undefined;
    this.petExisteConfirmar = true;

    setTimeout(() => {
      this.hacerScroll('idCitasView', 170);
    }, 300);
  }

  cancelarPetSelected() {
    this.petSelected = undefined;
    this.petExisteConfirmar = false;
    this.viewCitas = false;
    setTimeout(() => {
      this.hacerScroll('idPetsList');
    }, 400);
  }

  resetData() {
    // Reinicia los formularios
    this.userForm?.reset();
    this.acudienteForm?.reset();
    this.acudienteExistForm?.reset();
    this.petForm?.reset();
  }

  closeModalAndReset() {
    this.resetData();
    this.modalService.closeModal();
  }

  nextNewAppointment() {
    this.makeNewAppointment = true;
  }
}
