import { AfterViewInit, ChangeDetectorRef, Component, ComponentRef, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output, Renderer2, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { DailyComponent } from './daily/daily.component';
import { ApiService, HolidaysService } from '@services';
import { AnimationOptions } from 'ngx-lottie';

import { addDays, format, getDay, isBefore, parse, startOfDay, } from 'date-fns';
import { es } from 'date-fns/locale';

@Component({
  selector: 'custom-calendar',
  templateUrl: './custom-calendar.component.html',
  styleUrls: ['./custom-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomCalendarComponent implements AfterViewInit {
  @ViewChild('dailyRef', { read: ViewContainerRef }) private dailyRef!: ViewContainerRef;
  @ViewChild('currentMonthRef', { read: ElementRef }) private currentMonthRef!: ElementRef;
  @ViewChild('nowDateRef', { read: ElementRef }) private nowDateRef!: ElementRef;

  @Input() blockNextDays: boolean = false;
  @Input() blockPrevDays: boolean = true;
  @Input() extended: boolean = false;
  @Input() showNow: boolean = true;
  @Input() hourly: boolean = false;
  @Input() range: boolean = false;
  @Input() inputData: any;

  @Input() set endPointSchedules(doctorId: string | null) {
    if (doctorId) {
      this.loader = true
      this._endPointSchedules = `schedule/doctor/${doctorId}`
      setTimeout(() => this.renderCalendar(), 500);
    }
  }

  @Output() date = new EventEmitter();
  @Output() payloadData = new EventEmitter();
  @Output() errorNoData = new EventEmitter();
  @Output() onDayliView = new EventEmitter<boolean>();

  @HostBinding('style.width') private width = '50%'
  @HostBinding('style.maxwidth') private maxwidth = '290px'
  // @HostBinding('style.minwidth') private minwidth = '290px'
  @HostBinding('style.height') @Input() height: string = '410px';


  loader: boolean = false
  fullSize: boolean = false
  options: AnimationOptions = { path: 'assets/lottie-animations/calendar_edited.json' };

  weekdays = [
    {
      "name": "Domingo",
      "engName": "sunday",
      "abbreviations": {
        "simply": "Dom",
        "letter": "D"
      }
    },
    {
      "name": "Lunes",
      "engName": "monday",
      "abbreviations": {
        "simply": "Lun",
        "letter": "L"
      }
    },
    {
      "name": "Martes",
      "engName": "tuesday",
      "abbreviations": {
        "simply": "Mar",
        "letter": "M"
      }
    },
    {
      "name": "Miércoles",
      "engName": "wednesday",
      "abbreviations": {
        "simply": "Mié",
        "letter": "M"
      }
    },
    {
      "name": "Jueves",
      "engName": "thursday",
      "abbreviations": {
        "simply": "Jue",
        "letter": "J"
      }
    },
    {
      "name": "Viernes",
      "engName": "friday",
      "abbreviations": {
        "simply": "Vie",
        "letter": "V"
      }
    },
    {
      "name": "Sábado",
      "engName": "saturday",
      "abbreviations": {
        "simply": "Sáb",
        "letter": "S"
      }
    }
  ]

  private months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  private optionsDate: any = { weekday: "long", month: "long", day: "numeric" };
  private holidayList: Record<string, any> = {}
  private firstDayIndexCurrentMonth: any
  private lastDayIndexCurrentMonth: any
  private lastDayPreviousMonth: any
  private lastDayCurrentMonth: any
  private dateNow = new Date();
  private nextDays: any

  private dailyView!: ComponentRef<DailyComponent>
  private dailyOnClick: boolean = true

  private _endPointSchedules: string | null = null;

  constructor(private _el: ElementRef, private _holidayservice: HolidaysService, private _render: Renderer2, private _cdr: ChangeDetectorRef, private apiService: ApiService) { }

  ngOnInit() {
    this.holidayList = this._holidayservice.getHolidaysByYear(this.dateNow.getFullYear())
  }

  ngAfterViewInit(): void {
    this.renderCalendar();
    // this.width = this.extended ? '100%' : '314px'
  }

  @HostListener('click', ['$event']) _(event: PointerEvent) {
    if (!this.extended) {
      let _selectedDays = this._el.nativeElement.querySelectorAll(".day.selected");
      let _days = [...this._el.nativeElement.querySelectorAll(".day")];
      let _target = (<HTMLElement>event.target);

      if (!_target.classList.contains('day') || _target.getAttribute('disabled')) return

      if (!_selectedDays.length) {
        _target.classList.add('selected', 'start');
      } else if (_selectedDays.length == 1 && this.range) {
        _target.classList.add('selected', 'end');
        let _idxFirst = _days.findIndex((_d: HTMLElement) => _d.classList.contains('selected'));
        _days.slice(_idxFirst, +_target.innerHTML).forEach(_d => _d.classList.toggle('range'));
      } else {
        _days.forEach(_d => _d.classList.remove('selected', 'range', 'start', 'end'));
        _target.classList.add('selected', 'start');
      }

      this.dailyOnClick && this.showDailyView(event);
    }
  }

  public prevMonth() {
    this.dateNow.setMonth(this.dateNow.getMonth() - 1);
    this.renderCalendar();
  }

  public nextMonth() {
    this.dateNow.setMonth(this.dateNow.getMonth() + 1);
    this.renderCalendar();
  }

  private async renderCalendar() {
    this.clearDayViewer()
    let el = <HTMLElement>this._el.nativeElement;
    let monthTitle = <HTMLElement>this.currentMonthRef.nativeElement;
    let fullDateTitle = <HTMLElement>this.nowDateRef.nativeElement;

    this.lastDayCurrentMonth = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth() + 1, 0).getDate();
    this.lastDayPreviousMonth = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), 0).getDate();
    this.firstDayIndexCurrentMonth = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(),).getDay();
    this.lastDayIndexCurrentMonth = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth() + 1,).getDay();
    this.nextDays = 7 - this.lastDayIndexCurrentMonth;

    monthTitle.innerHTML = `${this.months[this.dateNow.getMonth()]} ${this.dateNow.getFullYear()}`;

    if (this.showNow) fullDateTitle.innerHTML = new Date().toLocaleDateString("es-ES", this.optionsDate);

    let monthDays = el.querySelector(".days");
    let _days = await this.generateDays();

    if (monthDays) {
      monthDays.innerHTML = ''
      monthDays && _days && _days.forEach(dayEl => monthDays?.appendChild(dayEl))
    }

    if (_days.length > 35) this.fullSize = true

    this.loader = false
    this._cdr.detectChanges();
  }

  private async generateDays(): Promise<HTMLElement[]> {
    let dateRef = startOfDay(this.dateNow);
    let nowDate = startOfDay(new Date());
    let tmpDays: HTMLElement[] = []

    let [month, day] = format(dateRef, 'dd/MM/yyyy').split('/').slice(1);
    let tmpPartialDate = `${month}/${day}`;
    let tmpNowDayRef = new Date().getDate();
    let tmpNowMonthRef = new Date().getMonth();

    // Previous month days
    for (let dayLastMonth = this.firstDayIndexCurrentMonth; dayLastMonth > 0; dayLastMonth--) {
      let lastMonthDay = this.lastDayPreviousMonth - dayLastMonth + 1
      let lasMonthPartialDate = `${Number(tmpPartialDate.split('/')[0]) - 1}/${tmpPartialDate.split('/')[1]}`
      let dateKey = `${lastMonthDay < 10 ? "0" + lastMonthDay : lastMonthDay}/${lasMonthPartialDate}`
      let isHoliday = this.holidayList[dateKey]
      let dateItemDay = parse(`${dateKey}`, 'dd/MM/yyyy', new Date())
      let attributes: any = { title: isHoliday ? isHoliday.name : undefined, "data-date": dateItemDay.toISOString().split('T')[0] }
      attributes.disabled = this.blockPrevDays && isBefore(dateItemDay, nowDate)
      tmpDays.push(this.createRenderElement({ type: 'button', classes: ['day', 'previous'], content: this.lastDayPreviousMonth - dayLastMonth + 1, attributes }));
    }

    const appoinmentsList = await this.getAppoinmentsMonth()

    let weekdaystmp = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]

    // Current month days
    for (let indexDay = 1; indexDay <= this.lastDayCurrentMonth; indexDay++) {
      let dateKey = `${indexDay < 10 ? "0" + indexDay : indexDay}/${tmpPartialDate}`
      let appoinments = appoinmentsList[dateKey]
      let isHoliday = this.holidayList[dateKey]
      let tmpClasses = ['day']
      let dateItemDay = parse(`${dateKey}`, 'dd/MM/yyyy', new Date(), { locale: es })
      let attributes: any = { title: isHoliday ? isHoliday.name : undefined, "data-date": dateItemDay.toISOString().split('T')[0] }
      let statusDay: any = appoinmentsList[weekdaystmp[getDay(dateItemDay)]]

      tmpClasses.push(statusDay['dayStatus'])

      if (statusDay['dayStatus'] === 'inactive', !isHoliday) attributes.title = 'Sin citas disponibles'

      attributes.disabled = this.blockPrevDays && isBefore(dateItemDay, nowDate)

      indexDay === tmpNowDayRef && tmpNowMonthRef === this.dateNow.getMonth() && tmpClasses.push('today')
      isHoliday && tmpClasses.push('holiday')

      let elementDay = this.createRenderElement({ type: 'div', classes: tmpClasses, content: indexDay, attributes })

      tmpDays.push(elementDay)
    }

    // Next month days
    for (let dayNextMonth = 1; dayNextMonth <= this.nextDays; dayNextMonth++) {
      let nextMonthDay = dayNextMonth
      let nextMonth = Number(tmpPartialDate.split('/')[0])
      let lasMonthPartialDate = `${nextMonth < 12 ? nextMonth + 1 : nextMonth}/${tmpPartialDate.split('/')[1]}`
      let dateKey = `${nextMonthDay < 10 ? "0" + nextMonthDay : nextMonthDay}/${lasMonthPartialDate}`
      let isHoliday = this.holidayList[dateKey]
      let dateItemDay = parse(`${dateKey}`, 'dd/MM/yyyy', new Date())
      let attributes: any = { title: isHoliday ? isHoliday.name : undefined, "data-date": dateItemDay.toISOString().split('T')[0] }
      attributes.disabled = this.blockPrevDays && isBefore(dateItemDay, addDays(nowDate, -1))

      tmpDays.push(this.createRenderElement({ type: 'div', classes: ['day', 'next'], content: dayNextMonth, attributes }));
    }

    return tmpDays;
  }

  private async getAppoinmentsMonth(): Promise<Record<string, any[]>> {
    let appoinments: Record<string, any[]> = {}

    return new Promise((resolve, reject) => {
      if (!this._endPointSchedules) return resolve({})

      this.apiService.getRequest(this._endPointSchedules).subscribe(({ body, ok }) => {
        !ok && resolve({})
        this.weekdays.forEach(({ engName }) => appoinments[engName] = body[0][engName])
        resolve(appoinments)
      })
    })
  }


  private showDailyView(_event: PointerEvent) {
    let _target = _event.target as HTMLElement
    let dayClicked = _target.getAttribute('data-date')
    let dayClickedFormated = parse(`${dayClicked}`, 'yyyy-MM-dd', new Date()).toLocaleDateString("es-ES", this.optionsDate)

    if (_target.classList.contains('inactive')) return

    if (!this.inputData) {
      this.errorNoData.emit('datos incompletos');
      return;
    }

    let { subServiceId, doctorId, branchId } = this.inputData;

    if (!subServiceId || !doctorId || !branchId) {
      this.errorNoData.emit('datos incompletos');
      return;
    }

    // Limpiar la vista del día si ya existe una vista de DailyComponent activa
    if (this.dailyView) {
      this.clearDayViewer();
    }

    setTimeout(() => {
      if (!this.extended) {
        let { x, y } = { x: _event.offsetX, y: _event.offsetY };
        this.dailyView = this.dailyRef.createComponent(DailyComponent);
        this.dailyView.instance.init = { x, y, date: dayClicked, day: dayClickedFormated };
        this.dailyView.instance.getHours(`search/schedule/${subServiceId}/${doctorId}/${branchId}/${dayClicked}`);
        this.dailyView.instance.data.subscribe((rs: any) => {
          this.payloadData.emit({ ...rs, date: dayClicked });
          this.clearDayViewer();
        });
        this.dailyView.instance.closeEvent.subscribe(() => {
          this.closeDailyComponent();
        });
      }
    }, this.dailyView ? 500 : 10);
  }

  private closeDailyComponent() {
    this.clearDayViewer();  // Cierra y limpia la instancia de DailyComponent
  }

  private clearDayViewer(resize: boolean = true): void {
    this.dailyRef?.clear();
    if (resize && !this.extended) {
      this.maxwidth = '290px';
      this.width = '50%'//'284px'}
    }
  }


  private createRenderElement(options: IRenderElementOptions): HTMLElement {
    const { type, classes, content, clickAction, attributes, directives } = options

    let tmpElement = this._render.createElement(type)
    if (content) {
      let tmpTextElement = this._render.createText(content)
      this._render.appendChild(tmpElement, tmpTextElement)
    }
    classes?.length && classes.forEach(cls => this._render.addClass(tmpElement, cls))
    // attrType && attrValue && this._render.setAttribute(tmpElement, attrType, attrValue)
    if (attributes) {
      for (let attrName in attributes) {
        if (attributes.hasOwnProperty(attrName)) {
          let attrValue = attributes[attrName];
          attrValue && this._render.setAttribute(tmpElement, attrName, attrValue);
        }
      }
    }

    clickAction && this._render.listen(tmpElement, 'click', (event: Event) => clickAction.apply(event))
    return tmpElement
  }

  public forceShowDailyHour(data: any) {
    let { date, hour } = data

    let { subServiceId, doctorId, branchId } = this.inputData;
    let _selectedDays = this._el.nativeElement.querySelectorAll(".day.selected");
    let _days = [...this._el.nativeElement.querySelectorAll(".day")];
    
    if (_selectedDays.length) _selectedDays[0].classList.remove("selected");
    
    let dayClicked = date
    let dayClickedFormated = parse(`${dayClicked}`, 'yyyy-MM-dd', new Date()).toLocaleDateString("es-ES", this.optionsDate)

    // Limpiar la vista del día si ya existe una vista de DailyComponent activa
    if (this.dailyView) {
      this.clearDayViewer();
    }

    setTimeout(() => {
      if (!this.extended) {
        let { x, y } = { x: 0, y: 0 };
        this.dailyView = this.dailyRef.createComponent(DailyComponent);
        this.dailyView.instance.init = { x, y, date: dayClicked, day: dayClickedFormated };
        this.dailyView.instance.getHours(`search/schedule/${subServiceId}/${doctorId}/${branchId}/${dayClicked}`);
        this.dailyView.instance.data.subscribe((rs: any) => {
          this.payloadData.emit({ ...rs, date: dayClicked });
          this.clearDayViewer();
        });
        this.dailyView.instance.closeEvent.subscribe(() => {
          this.closeDailyComponent();
        });
      }
    }, this.dailyView ? 500 : 10);
  }
}

interface IRenderElementOptions {
  // attrType?: string;
  // attrValue?: string;
  attributes?: any;
  directives?: IDirectiveOpts[];
  classes?: string[];
  clickAction?: Function;
  content?: any;
  type: string;
}

interface IDirectiveOpts {
  directiveClass: any,
  entries?: any[]
}
