import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-form-select-text',
  templateUrl: './form-select-text.component.html',
  styleUrls: ['./form-select-text.component.scss']
})
export class FormSelectTextComponent implements OnInit {

  @Input()data:any[]=[];
  @Input()valueItem='_id';
  @Input()nameItem='name';
  @Input()titleItem='Click aqui !';

  @Output() itemEmitter: EventEmitter<any> = new EventEmitter<any>();

  itemSelected?:any;

  ngOnInit(): void {    

    setTimeout(() => {      
      this.iniciarData(this.data);
    }, 300);
  }

  iniciarData(data:any[]){
    if (data.length>0) {
      this.itemSelected = data[0];
      this.itemEmitter.emit(this.itemSelected);      
    }
  }

  itemEmitt(event:any){
    const item = event.target.value    
    this.itemSelected = this.data.find(f=>f[this.valueItem]==item);
    this.itemEmitter.emit(this.itemSelected);    
  }

}
