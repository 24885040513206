import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumbersOnlyDirective } from './numbers-only.directive';
import { EmailValidationDirective } from './email-validation.directive';

@NgModule({
  declarations: [NumbersOnlyDirective, EmailValidationDirective ],
  imports: [CommonModule],
  exports: [NumbersOnlyDirective, EmailValidationDirective],
})
export class DirectivesModule {}
